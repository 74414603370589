import { countryList } from '../components/user/resources';
import HowToInvestIcon from '../assets/images/menu/how-to-invest.svg';
import YourGoalsIcon from '../assets/images/menu/your-goals.svg';
import AboutYouIcon from '../assets/images/menu/about-you.svg';
import IdCheckIcon from '../assets/images/menu/id-check.svg';

export const cachedCountries = new Map();
export const cachedGetToKnowYouImages = new Map();

export const loadCountryFlags = (): void => {
  countryList.forEach((country) => {
    const img = new Image();
    img.src = country.icon;
    cachedCountries.set(country.code, img);
  });
};
export const loadCanadaFlag = (): void => {
  const img = new Image();
  const canadaFlag = countryList.filter((c) => c.code === 'CA')[0];
  img.src = canadaFlag.icon;
  cachedCountries.set(canadaFlag.code, img);
};
export const loadGetToKnowYouImages = (): void => {
  const img1 = new Image();
  img1.src = HowToInvestIcon;
  cachedGetToKnowYouImages.set('YourGoalsIcon', img1);

  const img2 = new Image();
  img2.src = YourGoalsIcon;
  cachedGetToKnowYouImages.set('HowToInvest', img2);

  const img3 = new Image();
  img3.src = AboutYouIcon;
  cachedGetToKnowYouImages.set('AboutYouIcon', img3);

  const img4 = new Image();
  img4.src = IdCheckIcon;
  cachedGetToKnowYouImages.set('IdCheckIcon', img4);
};
