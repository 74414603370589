import { Box, Skeleton } from '@mui/material';
import { useContextTheme } from '../../providers/contextThemeProvider';

export const GoalSkeleton = () => {
  const { colors } = useContextTheme();

  return (
    <Box
      padding="16px"
      border={`1px solid ${colors.portfolioCardBorder}`}
      borderRadius="4px"
      sx={{
        background: colors.baseComponentBackground,
      }}
    >
      <Box display="flex" justifyContent="space-between" marginBottom="15px">
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <Skeleton width={100} height={16} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Skeleton width={100} height={16} />
          <Skeleton width={60} height={12} />
        </Box>
      </Box>
      <Box>
        <Skeleton width="100%" height={35} />
        <Box display="flex" justifyContent="space-between" marginTop="5px">
          <Skeleton width={80} height={12} />
          <Skeleton width={60} height={12} />
        </Box>
      </Box>
    </Box>
  );
};
