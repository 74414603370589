import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { generateAddressString } from '../../../utils/commonMethods';
import OvForm from '../../common/wrappers/ovForm';
import { PhysicalAddress } from '../address/resources';
import { UPDATE_USER } from '../graphql';
import UpdatePhysicalAddressWizard from '../address/updatePhysicalAddressWizard';
import Name from '../name';
import FlowModal from '../../common/wrappers/modals/ovFlowModal';
import Phone from '../../signUp/phone';
import Email from '../../signUp/email';
import DropdownInput from '../../common/inputs/dropdownInput';
import { allTrustedContactPersonRelations, TrustedContactPersonRelations } from '../../account/resources';
import { TrustedContactperson } from '../../../utils/commonGraphql';

export interface UserState {
  id?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  primaryEmail?: string,
  physicalAddress?: Partial<PhysicalAddress>,
  sin?: string,
  employmentStatus?: string,
  studentAreaOfStudy?: string,
  jobTitle?: string,
  dateOfBirth?: Date,
  companyType?: string,
  trustedContactPerson?: TrustedContactperson,
}

export interface Props {
  user: UserState,
  onContinue?: () => void,
}

const style = {
  container: {
    marginBottom: '32px',
    minHeight: '200px',
    maxHeight: '550px',
  },
};
export interface TrustedContactPersonObject {
  id?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  name?: string,
  phone?: string,
  relation?: string,
  primaryEmail?: string,
  physicalAddress?: Partial<PhysicalAddress>,
  sin?: string,
}
const TrustedContactPersonDetails = ({
  user, onContinue,
}: Props): JSX.Element => {
  const [currentModalStep, setCurrentModalStep] = useState(0);
  const { t } = useTranslation(['base', 'account', 'user']);
  const [loading, setLoading] = useState(false);
  const getFirstNameFormat = (name: string): string => {
    const nameItems = name.split(' ');
    const firstName = nameItems[0];
    return firstName;
  };

  const getMiddleNameFormat = (name: string): string => {
    const nameItems = name.split(' ');
    const middleName = (nameItems.length > 0) ? nameItems[1] : '';
    return middleName;
  };

  const getLastNameFormat = (name: string): string => {
    const nameItems = name.split(' ');
    const lastName = (nameItems.length > 1) ? nameItems[2] : '';
    return lastName;
  };

  const [trustedContactPerson, setTrustedContactPerson] = useState<TrustedContactPersonObject>({
    ...user.trustedContactPerson,
    firstName: getFirstNameFormat(user.trustedContactPerson?.name || ''),
    middleName: getMiddleNameFormat(user.trustedContactPerson?.name || ''),
    lastName: getLastNameFormat(user.trustedContactPerson?.name || ''),
    primaryEmail: user.trustedContactPerson?.email ?? '',
  });
  const [updateTrustedContactInformation] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        trustedContactPerson: {
          name: `${trustedContactPerson?.firstName ?? ''} ${trustedContactPerson?.middleName ?? ''} ${trustedContactPerson?.lastName ?? ''}`,
          email: trustedContactPerson.primaryEmail ?? undefined,
          phone: trustedContactPerson.phone ?? undefined,
          physicalAddress: trustedContactPerson.physicalAddress ? {
            unitNumber: trustedContactPerson.physicalAddress.unitNumber,
            houseNumber: trustedContactPerson.physicalAddress.houseNumber,
            streetName: trustedContactPerson.physicalAddress.streetName,
            neighborhood: trustedContactPerson.physicalAddress.neighborhood,
            city: trustedContactPerson.physicalAddress.city,
            province: trustedContactPerson.physicalAddress.province,
            country: trustedContactPerson.physicalAddress.country,
            postal: trustedContactPerson.physicalAddress.postal,
          } : undefined,
          relation: trustedContactPerson.relation ?? undefined,
        },
      },
    },
    onCompleted: () => {
      setLoading(false);
      if (onContinue) {
        onContinue();
      }
    },
    onError: () => {
      setLoading(false);
    },
  });
  // eslint-disable-next-line
  const relationshipOptions: any[] = allTrustedContactPersonRelations.map((x) => ({ imageUrl: '', text: x.title, value: x.key }));
  const [openStepModal, setOpenStepModal] = useState(false);
  const openModal = (step: number): void => {
    setCurrentModalStep(step);
    setOpenStepModal(true);
  };
  const modalSteps: JSX.Element[] = [
    <Name key="TrustedContactpersonName" user={trustedContactPerson} updateUserState={setTrustedContactPerson} onContinue={() => setOpenStepModal(false)} title={t('user:trustedContactPerson.name')} subtitle="" />,
    <UpdatePhysicalAddressWizard key="TrustedContactpersonAddress" user={trustedContactPerson} updateUserState={setTrustedContactPerson} showStepper={false} shouldSaveUpdate={false} onContinue={() => setOpenStepModal(false)} title={t('account:beneficiary.address', { firstName: trustedContactPerson.firstName })} subtitle={t('account:beneficiary.trustedContactAddressSubtitle')} />,
    <Email user={trustedContactPerson} onContinue={() => setOpenStepModal(false)} updateUserState={setTrustedContactPerson} texts={{ title: t('user:trustedContactPerson.emailModal.title'), subTitle: t('user:trustedContactPerson.emailModal.subTitle') }} />,
    <Phone key="phone" user={trustedContactPerson} onContinue={() => setOpenStepModal(false)} updateUserState={setTrustedContactPerson} texts={{ title: t('user:trustedContactPerson.phoneNumberModal.title'), subTitle: t('user:trustedContactPerson.phoneNumberModal.subTitle') }} />,
  ];
  const getParsedAddress = (): string => {
    if (trustedContactPerson.physicalAddress) return generateAddressString(trustedContactPerson.physicalAddress);
    return '';
  };
  const nextStep = (): void => {
    setLoading(true);
    updateTrustedContactInformation();
  };
  return (
    <OvForm
      hasButton={false}
      loading={loading}
      submitButton={false}
      suffixButton={{
        onClick: () => nextStep(),
        text: t('base:button.continue'),
        variant: 'primary-large',
        dataTestId: 'add-trusted-contact',
      }}
    >
      <Box display="flex" flexDirection="column" style={style.container}>
        <Typography variant="headingMain" textAlign="left" style={{ marginBottom: '20px' }}>{t('user:trustedContactPerson.title')}</Typography>
        <Typography variant="captionSectionerMedium">
          {t('user:trustedContactPerson.subTitle')}
        </Typography>
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.fullName')}</Typography>
        <TextField
          data-testid="name-input"
          id="standard-basic"
          fullWidth
          variant="standard"
          sx={{ marginBottom: 2 }}
          value={`${trustedContactPerson?.firstName ?? ''} ${trustedContactPerson?.middleName ?? ''} ${trustedContactPerson?.lastName ?? ''}`}
          inputProps={{
            readOnly: true,
            onClick: () => { openModal(0); },
          }}
        />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.relationship')}</Typography>
        <DropdownInput
          dataTestId="relationship-dropdown"
          onChange={(e) => {
            setTrustedContactPerson({
              ...trustedContactPerson,
              relation: e.target.value as TrustedContactPersonRelations,
            });
          }}
          options={relationshipOptions}
          value={trustedContactPerson?.relation ?? ''}
        />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: '16px' }}>{t('user:trustedContactPerson.primaryEmail')}</Typography>
        <TextField
          data-testid="email-input"
          id="standard-basic"
          fullWidth
          variant="standard"
          sx={{ marginBottom: 2 }}
          value={trustedContactPerson?.primaryEmail}
          inputProps={{
            readOnly: true,
            onClick: () => { openModal(2); },
          }}
        />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('user:trustedContactPerson.phone')}</Typography>
        <TextField
          data-testid="phone-input"
          id="standard-basic"
          fullWidth
          variant="standard"
          sx={{ marginBottom: 2 }}
          value={trustedContactPerson?.phone}
          inputProps={{
            readOnly: true,
            onClick: () => { openModal(3); },
          }}
        />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.address')}</Typography>
        <TextField
          data-testid="address-input"
          id="standard-basic"
          fullWidth
          variant="standard"
          sx={{ marginBottom: 2 }}
          value={getParsedAddress()}
          inputProps={{ readOnly: true, onClick: () => { openModal(1); } }}
        />
      </Box>
      {openStepModal ? (
        <FlowModal
          open={openStepModal}
          goBack={() => setOpenStepModal(false)}
          onClose={() => setOpenStepModal(false)}
          showCloseButton
          component={(
            modalSteps[currentModalStep]
          )} />
      ) : null}
    </OvForm>
  );
};

TrustedContactPersonDetails.defaultProps = {
  onContinue: undefined,
};

export default TrustedContactPersonDetails;
