import { useMutation } from '@apollo/client';
import {
  Typography,
  List,
  Box,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { userGenderVar } from '../../utils/localVariables';
import ButtonListItem from '../common/lists/buttonListItem';
import OvForm from '../common/wrappers/ovForm';
import { UPDATE_USER } from './graphql';
import { genderList } from './resources';

export interface UserState {
  gender?: string,
}

interface Props {
  beneficiaryName?: string,
  user: UserState,
  shouldSaveUpdate?: boolean,
  updateUserState?: (att: UserState) => void,
  onContinue?: () => void,
}

const Gender = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'account']);
  const [loading, setLoading] = useState(false);

  const [updateGender] = useMutation(UPDATE_USER);

  const handleAnalytics = async (item: string): Promise<void> => {
    if (item === genderList[0].value) {
      sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryGenderMaleButtonSelect).then();
    }
    if (item === genderList[1].value) {
      sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryGenderFemaleButtonSelect).then();
    }
  };

  const createBeneficiary = async (item: Record<string, string>): Promise<void> => {
    setLoading(true);
    await handleAnalytics(item.value);
    if (props.shouldSaveUpdate) {
      updateGender({
        variables: {
          input: { gender: item.value },
        },
        onCompleted: () => {
          userGenderVar(item.value);
          setLoading(false);
          if (props.onContinue) props.onContinue();
        },
        onError: () => setLoading(false),
      });
    } else if (updateUserState) {
      updateUserState({ ...user, gender: item.value });
      if (props.onContinue) props.onContinue();
    }
  };

  return (
    <OvForm loading={loading} hasButton={false}>
      <Typography variant="heading2">{t('account:beneficiary.gender.title', { firstName: props.beneficiaryName })}</Typography>
      <Typography variant="paragraph3">{t('account:beneficiary.gender.subtitle')}</Typography>
      <List sx={{ maxHeight: '250px', overflowY: 'auto' }}>
        {genderList.map((item: Record<string, string>) => (
          <Box key={item.name}>
            <ButtonListItem
              key={item.name}
              text={item.name}
              onClick={() => {
                if (item.value) createBeneficiary(item);
              }}
              isSelected={(user.gender && item.value === user.gender) || false}
            />
            <Box mb={1} />
          </Box>
        ))}
      </List>
    </OvForm>
  );
};

Gender.defaultProps = {
  beneficiaryName: undefined,
  shouldSaveUpdate: undefined,
  onContinue: undefined,
  updateUserState: undefined,
};

export default Gender;
