/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import {
  Box,
} from '@mui/material';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_BANK_CONNECTION } from './graphql';
import Information from './information';
import FeedbackModal from '../common/wrappers/modals/ovFeedbackModal';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import { FETCH_MY_BANK_ACCOUNTS } from '../transfers/graphql';
import { FlinksErrorCodes } from './resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import ManualBankAccount from './manualBankAccount';
import { UserContext } from '../../providers/userContextProvider';

interface FlinksProps {
  open: boolean,
  onClose: () => void,
  eventListener?: (info: any) => void,
  bankAccounts: { id: string; name: string; bankAccountNumber: string; }[],
  retrievedBank: (bank: { id: string; name: string; bankAccountNumber: string; }) => void,
}

const Flinks = ({
  open, onClose, eventListener, bankAccounts, retrievedBank,
}: FlinksProps): JSX.Element | null => {
  const [init] = useState(true);
  const { userContext } = useContext(UserContext);
  const [credentials, setCredentials] = useState<{ loginId?: string, bankAccountRefId?: string }>({});
  const [step, setStep] = useState<number>(1);
  const bankAccountsRef = useRef<{ id: string; name: string; bankAccountNumber: string; }[]>([]);
  bankAccountsRef.current = bankAccounts;
  const [createBankConnectionMutation] = useMutation(CREATE_BANK_CONNECTION, {
    variables: {
      input: {
        loginId: credentials.loginId,
        bankAccountRefId: credentials.bankAccountRefId?.trim() ? credentials.bankAccountRefId.trim() : undefined,
      },
    },
  });
  const [fetchMyBankAccounts] = useLazyQuery(FETCH_MY_BANK_ACCOUNTS, {
    variables: {},
    nextFetchPolicy: 'standby',
  });
  const fetchBanksAtInterval = async (): Promise<void> => {
    const interval = setInterval(async () => {
      const result = await fetchMyBankAccounts().then();
      const newBankAccounts = result.data?.me.user.bankAccounts ?? [];
      const distinctBankAccounts = newBankAccounts.filter((e: { id: string; name: string; bankAccountNumber: string; }) => !bankAccountsRef.current.some((old) => e.id === old.id));
      if (distinctBankAccounts.length > 0) {
        retrievedBank(distinctBankAccounts[distinctBankAccounts.length - 1]);
        clearInterval(interval);
        onClose();
      }
    }, 5000);
    setTimeout(() => {
      onClose();
      clearInterval(interval);
    }, 300000);
  };
  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (FlinksErrorCodes.includes(e.data.flinksCode)) {
        sendAnalyticsEvent(ovAnalyticsEvents.flinksBankAccountError).then();
      }
      if (eventListener) eventListener(e.data);
      if (e.data.step && e.data.step.toLowerCase() === 'redirect') {
        setCredentials({
          loginId: e.data.loginId ?? '',
          bankAccountRefId: e.data.accountId,
        });
        setStep(4);
        fetchBanksAtInterval().then();
      }
    });
  }, [init]);

  useEffect(() => {
    if (open) {
      if (!userContext.availableFeatureFlags?.includes('AUTOMATED_BANK_ACCOUNT_LINKING')
        && userContext.availableFeatureFlags?.includes('MANUALLY_ADD_BANK_ACCOUNT')) {
        setStep(3);
      } else {
        setStep(1);
      }
    }
  }, [open, userContext.availableFeatureFlags]);

  useEffect(() => {
    if (credentials.loginId?.trim()) createBankConnectionMutation().then();
  }, [credentials.loginId]);

  const switchStep = ():JSX.Element => {
    const externalIframeUrl = userContext.organization?.externalProvider?.bankingConnectorProvider.clientIframeUrl;
    let element: JSX.Element;
    if (step === 1) {
      element = (<Information onContinue={() => setStep(2)} onManualyAddBankAccount={() => setStep(3)} />);
    } else if (step === 2) {
      element = (
        <Box sx={{ height: '600px !important' }}>
          <iframe
            title="ov-flinks"
            style={{ height: '100%', width: '100%', marginLeft: '-15px' }}
            frameBorder="0"
            src={externalIframeUrl || process.env.REACT_APP_FLINKS_URL}
          />
        </Box>
      );
    } else if (step === 3) {
      element = (
        <Box sx={{ height: '600px !important' }}>
          <ManualBankAccount retrievedBank={retrievedBank} onContinue={onClose} onContinueToFlinks={() => setStep(2)} />
        </Box>
      );
    } else {
      element = (
        <Box sx={{ height: '600px !important' }}>
          <OvLoadingIndicator />
        </Box>
      );
    }
    return element;
  };

  if (open) {
    return (
      <FeedbackModal
        open={open}
        onClose={onClose}
        showCloseButton
        component={switchStep()}
      />
    );
  }
  return null;
};

Flinks.defaultProps = {
  eventListener: undefined,
};

export default Flinks;
