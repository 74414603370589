import { gql } from '@apollo/client';

export const CREATE_BANK_CONNECTION = gql`
  mutation createBankConnection($input: CreateBankConnectionInput!) {
    createBankConnection(input: $input) {
      bankConnection {
        id
        loginId
        bankAccounts {
          id
          name
          institutionNumber
          transitNumber
          bankAccountNumber
        }
      }
    }
  }
`;
