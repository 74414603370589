import { colors } from '../../theme/colors';

export const styles = {
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 25px 0 25px',
  },
  menuIcon: {
    color: colors.ovBlack,
    position: 'relative',
    cursor: 'pointer',
  },
  logo: {
    height: '25px',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '398px',
    bgcolor: colors.ovWhite,
    borderRadius: '6px',
    height: '615px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.07)',
  },
  closeBox: {
    textAligh: 'center',
    background: colors.backButtonBackground,
    borderRadius: '3.42105px',
    width: '26px',
    height: '26px',
    top: '-6px',
    marginTop: '18px',
    marginLeft: '24px',
    marginRight: '24px',
    marginBottom: '22px',
    cursor: 'pointer',
  },
  subTitleText: {
    marginBottom: 0,
    color: colors.neutral30,
  },
  inputText: {
    maxWidth: '300px',
    width: '300px',
    wordWrap: 'break-word' as const,
    position: 'relative' as const,
    top: '-20px',
  },
  editText: {
    cursor: 'pointer',
    position: 'relative' as const,
    top: '-20px',
    color: colors.link,
  },
  inputBox: {
    marginBottom: '30px',
    marginTop: '33px',
    display: 'flex',
    justifyContent: 'space-between',
    background: colors.transparent,
  },
};
