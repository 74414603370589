/* eslint-disable  no-nested-ternary */

import * as React from 'react';
import {
  Box,
  Typography,
  Divider,
} from '@mui/material';
import RoundedCheckbox from '../controllers/roundedCheckbox';
import { colors } from '../../../theme/colors';
import OvRadioButton from '../controllers/ovRadioButton';
import SquareCheckbox from '../controllers/squareCheckbox';

interface GeneralListItemProps {
  prefixIcon?: string,
  prefixIconStyle?: {
    width?: number,
    height?: number,
  },
  text: string,
  suffixPercent?: number,
  checked?: boolean,
  hasRadioField?: boolean,
  hasSquareCheckbox?: boolean,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  spaceAroundContent?: number,
  hideDivider?: boolean,
}

const GeneralListItem = ({ ...props }: GeneralListItemProps): JSX.Element => (
  <>
    <Box data-testid="generalListItem" display="flex" flexDirection="row" justifyContent="space-between" onClick={props.onClick} style={{ marginTop: props.spaceAroundContent }}>
      <Box display="flex" flexDirection="row" alignItems="center">
        {props.prefixIcon ? <img src={props.prefixIcon} alt="icon" width={props.prefixIconStyle?.width} height={props.prefixIconStyle?.height} style={{ marginTop: 2 }} /> : null}
        <Typography variant="bodySub2" ml={props.prefixIcon ? 1.2 : 0}>{ props.text }</Typography>
      </Box>
      { props.suffixPercent ? <Typography variant="bodySub1" color={colors.success}>{ `${props.suffixPercent}%` }</Typography> : props.hasRadioField ? <OvRadioButton checked={props.checked} /> : props.hasSquareCheckbox ? <SquareCheckbox checked={props.checked} /> : <RoundedCheckbox checked={props.checked} indeterminate={!props.checked} /> }
    </Box>
    {
      props.hideDivider ? undefined : (<Divider style={{ marginTop: props.spaceAroundContent }} />)
    }
  </>
);

GeneralListItem.defaultProps = {
  prefixIcon: null,
  prefixIconStyle: null,
  suffixPercent: null,
  onClick: null,
  checked: false,
  spaceAroundContent: 10,
  hasRadioField: undefined,
  hasSquareCheckbox: undefined,
  hideDivider: false,
};

export default GeneralListItem;
