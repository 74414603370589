import { useRef, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import TrackingBar from '../../common/headers/trackingBar';
import WithStepWizard, { StepProps } from '../../common/wrappers/withStepWizard';
import Assumptions from './assumptions';
import Citizenship from './citizenship';
import CountryOfTaxResidence from './countryOfTaxResidence';
import ForeignTax, { ForeignTaxInformation } from './foreignTax';
import ForeignTaxNumber from './foreignTaxNumber';

const AssumptionsStep = WithStepWizard(Assumptions);
const CitizenshipStep = WithStepWizard(Citizenship);
const CountryOfTaxResidenceStep = WithStepWizard(CountryOfTaxResidence);
const ForeignTaxStep = WithStepWizard(ForeignTax);
const ForeignTaxNumberStep = WithStepWizard(ForeignTaxNumber);

export interface UserState {
  incompleteFields?: string[],
  citizenships?: string[],
  foreignTaxInformation?: ForeignTaxInformation[],
  powerOfAttorneyGranted?: boolean,
  isMemberOfIiroc?: boolean,
  isOfficerOfPublicCompany?: boolean,
  isOwnerOfPublicCompany?: boolean,
  politicallyExposedDomesticPerson?: boolean,
  headOfInternationalOrganization?: boolean,
  politicallyExposedForeignPerson?: boolean,
  closeAssociateOfPEP?: boolean,
  countryOfTaxResidence?: string,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  goBack?: () => void,
  stepProps?: StepProps,
  stepTitle?: string,
}

export interface AlternativeSteps {
  isCitizenshipStepRequired?: boolean,
  isForeignTaxStepRequired?: boolean,
}

const CompleteAssumptionsWizard = ({
  user, updateUserState, onContinue, goBack, stepProps, stepTitle,
} : Props): JSX.Element => {
  const absoluteTotalSteps = 13;
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const alternativeSteps = useRef<AlternativeSteps>({});
  const setAlternativeSteps = (att: AlternativeSteps): void => {
    alternativeSteps.current = { ...alternativeSteps.current, ...att };
  };

  const steps: JSX.Element[] = [
    <AssumptionsStep stepProps={{ absoluteTotalSteps, currentStep: 11 }} stepName="Assumptions" user={user} updateUserState={updateUserState} setAlternativeSteps={setAlternativeSteps} goBackAction={goBack} action={onContinue} stepTitle={stepTitle} key="1" />,
    <CitizenshipStep stepProps={{ absoluteTotalSteps, currentStep: 11 }} stepName="Citizenship" user={user} updateUserState={updateUserState} alternativeSteps={alternativeSteps.current} action={onContinue} stepTitle={stepTitle} key="2" />,
    <CountryOfTaxResidenceStep stepProps={{ absoluteTotalSteps, currentStep: 11 }} stepName="CountryOfTaxResidence" user={user} updateUserState={updateUserState} goBackToNamedStep={() => (alternativeSteps.current.isCitizenshipStepRequired ? 'Citizenship' : 'Assumptions')} stepTitle={stepTitle} key="3" />,
    <ForeignTaxStep stepProps={{ absoluteTotalSteps, currentStep: 11 }} stepName="ForeignTax" user={user} updateUserState={updateUserState} goBackToNamedStep={() => 'CountryOfTaxResidence'} stepTitle={stepTitle} key="4" />,
    <ForeignTaxNumberStep stepProps={{ absoluteTotalSteps, currentStep: 11 }} stepName="ForeignTaxNumber" user={user} updateUserState={updateUserState} action={onContinue} stepTitle={stepTitle} key="5" />,
  ];

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      className="ov-step-wizard"
      transitions={{}}
      nav={(
        <TrackingBar steps={steps} baselineStepProps={stepProps} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

CompleteAssumptionsWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  stepProps: undefined,
  stepTitle: undefined,
};

export default CompleteAssumptionsWizard;
