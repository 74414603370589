import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UPDATE_USER } from '../graphql';
import SimpleInput from '../../common/inputs/simpleInput';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  employerName?: string,
  incompleteFields?: string[],
}

export interface ValidateState {
  firstNameIsValid?: boolean,
  lastNameIsValid?: boolean,
  middleNameIsValid?: boolean,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  title?: string,
  subtitle?: string,
  onContinue?: () => void,
  shouldSaveUpdate?: boolean,
  analyticsEvent?: string,
}

const EmployerName = ({
  user, updateUserState, title, subtitle, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);

  const onEmployerNameUpdated = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'EMPLOYER_NAME_UPDATED' });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };
  const [updateEmployerName] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        employerName: user.employerName?.trim(),
      },
    },
    onCompleted: onEmployerNameUpdated,
    onError: () => setLoading(false),
  });

  const onContinueClick = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    setLoading(true);
    updateEmployerName().then();
  };

  const clearEmployerName = (): void => {
    updateUserState({ ...user, employerName: '' });
  };

  return (
    <OvForm onSubmit={onContinueClick} loading={loading} disableButton={!user.employerName}>
      <Typography variant="heading2" style={{ marginBottom: 16 }}>{title ?? t('user:employement.employerName.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{subtitle ?? t('user:employement.employerName.subTitle')}</Typography>
      <SimpleInput
        testId="firstName-input"
        label={t('user:employement.employerName.inputLabel')}
        value={user.employerName ?? ''}
        style={{ marginBottom: 16 }}
        required
        onChange={(event) => updateUserState({ ...user, employerName: event.target.value })}
        data-testid="employerName-input"
        onClear={clearEmployerName}
      />
    </OvForm>
  );
};

EmployerName.defaultProps = {
  title: undefined,
  subtitle: undefined,
  onContinue: undefined,
  shouldSaveUpdate: false,
  analyticsEvent: undefined,
};

export default EmployerName;
