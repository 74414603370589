import {
  Typography,
  List,
  TextField,
  InputAdornment,
} from '@mui/material';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import { Affiliation } from './createAccountAuxiliaryWizard';

export interface UserState {
  affiliation?: Affiliation,
}

interface Props {
  beneficiaryName?: string,
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
}

const BeneficiaryAllocation = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'account']);
  const [loading, setLoading] = useState(false);

  const onContinueClick = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();
    setLoading(true);
    await sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryAllocationSelect).then();
    if (props.onContinue) props.onContinue();
  };

  return (
    <OvForm loading={loading} onSubmit={onContinueClick} hasButton>
      <Typography variant="heading2">{t('account:beneficiary.allocation.title', { firstName: props.beneficiaryName })}</Typography>
      <Typography variant="paragraph3">{t('account:beneficiary.allocation.subtitle')}</Typography>
      <List sx={{ maxHeight: '250px', overflowY: 'auto' }}>
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.allocation')}</Typography>
        <TextField
          fullWidth
          data-testid="relation-user-allocation"
          value={user.affiliation?.allocation ?? ''}
          variant="standard"
          onChange={(e) => {
            updateUserState({ ...user, affiliation: { ...user.affiliation, allocation: Number(e.target.value) } });
          }}
          error={(Number(user.affiliation?.allocation) > 100)}
          id="allocation"
          type="number"
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, inputProps: { min: 0, max: 100 } }}
        />
      </List>
    </OvForm>
  );
};

BeneficiaryAllocation.defaultProps = {
  beneficiaryName: undefined,
  onContinue: undefined,
};

export default BeneficiaryAllocation;
