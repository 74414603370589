import { gql } from '@apollo/client';

export const FETCH_ALL_INCOMPLETE_FORM_AGREEMENT = gql`
  query fetchFormAgreements {
    me { 
      user {
        allIncompleteFormAgreements {
          type
          minVersion
          digitalSignatureEnabled
          scheduledTransfer {
            id
          }
        }
      }
    }
  }
`;

export const SIGN_PAD_AGREMETNS = gql`
  mutation signFormAgreements($input: SignFormAgreementsInput!) {
    signFormAgreements(input: $input) {
      formAgreements {
        id
        type
        signedAt
      }
    }
  }
`;

export const FETCH_TRANSFERS = gql`
  query fetchTransfers($input: FetchTransfersInput!) {
    fetchTransfers(input: $input) {
      transfers {
        id
        type
        source
        state
        createdAt
        amountCents
      }
      totalCount
    }
  }
`;

export const CREATE_DEPOSIT_TRANSFER = gql`
  mutation createDepositTransfer($input: CreateDepositTransferInput!) {
    createDepositTransfer(input: $input) {
      transfer {
        id
        state
        failReason
      }
    }
  }
`;

export const WITHDRAW_ALL_FROM_SUBACCOUNT = gql`
  mutation withdrawAllFromSubAccount($input: WithdrawAllSubAccountInput!) {
    withdrawAllFromSubAccount(input: $input) {
      transfer {
        id
        state
        failReason
      }
    }
  }
`;

export const CREATE_WITHDRAW_TRANSFER = gql`
  mutation createWithdrawTransfer($input: CreateWithdrawTransferInput!) {
    createWithdrawTransfer(input: $input) {
      transfer {
        id
        state
        failReason
      }
    }
  }
`;

export const CREATE_SCHEDULED_WITHDRAW_TRANSFER = gql`
  mutation createScheduledWithdrawTransfer($input: CreateScheduledWithdrawTransferInput!) {
    createScheduledWithdrawTransfer(input: $input) {
      scheduledTransfer {
        id
        state
      }
    }
  }
`;

export const CREATE_SCHEDULED_DEPOSIT_TRANSFER = gql`
  mutation createScheduledDepositTransfer($input: CreateScheduledDepositTransferInput!) {
    createScheduledDepositTransfer(input: $input) {
      scheduledTransfer {
        id
        state
      }
    }
  }
`;

export const TRANSFER_BETWEEN_SUBACCOUNTS = gql`
  mutation transferBetweenSubAccounts($input: TransferBetweenSubAccountsInput!) {
    transferBetweenSubAccounts(input: $input) {
      transfer {
        id
        state
      }
    }
  }
`;

export const FULL_TRANSFER_BETWEEN_SUBACCOUNTS = gql`
  mutation transferAllBetweenSubAccounts($input: TransferAllBetweenSubAccountsInput!) {
    transferAllBetweenSubAccounts(input: $input) {
      transfer {
        id
        state
      }
    }
  }
`;

export const EXTERNAL_TRANSFER = gql`
  mutation createExternalTransfer($input: CreateExternalTransferInput!) {
    createExternalTransfer(input: $input) {
      manualProcess {
        id
        state
      }
    }
  }
`;

export const FETCH_INSTITUTIONS = gql`
  query fetchInstitutions($input: FetchInstitutionsInput!) {
    fetchInstitutions(input: $input) {
      institutions {
        id
        name
      }
      totalCount
    }
  }
`;

export const CALCULATE_WITHDRAWAL_FEES = gql`
  query calculateWithdrawFees($input: CalculateWithdrawFeesInput!) {
    calculateWithdrawFees(input: $input) {
      withdrawFees {
        type
        amountCents
      }
    }
  }
`;
export const FETCH_SCHEDULED_DEPOSITS = gql`
  query fetchScheduledTransfers($input: FetchScheduledTransfersInput!) {
    fetchScheduledTransfers(input: $input) {
      scheduledTransfers {
        id
        amountCents
        scheduledDate
        subAccount {
          id
        }
      }
      totalCount
    }
  }
`;
export const FETCH_MY_BANK_ACCOUNTS = gql`
query ME{
  me {
    user {
      bankAccounts {
        id
        name
        bankAccountNumber
      }
    }
  }
}
`;
export const FETCH_MY_GOALS = gql`
query fetchGoals {
  me {
    user {
      goals {
        id
        type
        name
        state
        subAccounts{
          id
          name
          theme {
            id
            name
          }
          statistics {
           id
           moneyAvailableCents
           marketValueCents
           availableFundsToWithdrawCents
           cashOnHoldToWithdrawCents
          }
          state
          account {
            id
            state
          }
        }
      }
    }
  }
}
`;
export const FETCH_SUB_ACCOUNT_DETAIL = gql`
query fetchSubAccount ($subAccountId: ObjectID!) {
fetchSubAccount(subAccountId: $subAccountId) {
  subAccount {
    id
    state
    name
    statistics {
      id
      moneyAvailableCents,
      marketValueCents,
      availableFundsToWithdrawCents,
      cashOnHoldToWithdrawCents,
    }
    theme {
      id
      key
      translatedName {
        en
        fr
      }
    }
    hasTradingSchedule
    account {
      id,
      type,
      user {
        id
      }
    },
  }
}
}
`;
export const FETCH_SUBACCOUNTS_AVAILABLE_FUNDS = gql`
query fetchSubAccounts {
  me {
    user {
      subAccounts{
        id
        name
        theme {
          id
          key
          translatedName {
            en
            fr
          }
        }
        state
        goal {
          id
        }
        statistics {
          id
          availableFundsToWithdrawCents
        }
        account {
          type
          user { id }
          affiliations {
            id
            type
            user { id }
          }
        }
      }
    }
  }
}
`;
export const FETCH_SUB_ACCOUNT_NET_CONTRIBUTIONS = gql`
query fetchSubAccount ($subAccountId: ObjectID!) {
  fetchSubAccount(subAccountId: $subAccountId) {
    subAccount {
      id
      netContributionsHistoryCents {
        amountCents
      }
    }
  }
}
`;

export interface GoalResponse {
  id: string,
  name: string,
  type: string,
  state: string,
  subAccounts: {
    id: string,
    state: string,
    name: string,
    theme: {
      name: string,
    },
    statistics: {
      moneyAvailableCents: number,
      marketValueCents: number,
      availableFundsToWithdrawCents: number,
      cashOnHoldToWithdrawCents: number,
    },
    account: {
      id: string,
      state: string,
    },
  }[],
}
