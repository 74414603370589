import { Box, BoxProps, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { Colors, useContextTheme } from '../../providers/contextThemeProvider';
import { formatMoneyValue, formatPercentValue } from '../../utils/commonMethods';
import { MemberTag } from '../goal/memberTag';

interface Props {
  boxProps?: BoxProps,
  accountType: string,
  themeKey: string,
  themeName: string,
  themeIconKey?: string,
  marketValueCents: number,
  simpleReturnAmount: number,
  simpleReturnPercent: number,
  members?: {
    name?: string,
    index: number,
  }[],
  onClick?: () => void,
}

const styles = (colors: Colors) => ({
  coreText: {
    color: colors.portfolioCardThemeText,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    fontSize: '12px',
    paddingTop: '2px',
  },
  nameText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
});

const PortfolioCard = ({
  boxProps, themeName, accountType, marketValueCents, simpleReturnAmount, simpleReturnPercent, onClick, members,
}: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const style = styles(colors);
  return (
    <Box
      {...boxProps}
      onClick={onClick}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row' as const,
        alignItems: 'start',
        justifyContent: 'space-between',
        padding: '12px',
        border: `1px solid ${colors.portfolioCardBorder}`,
        borderRadius: '10px',
        backgroundColor: colors.baseComponentBackground,
        cursor: 'pointer',
        transition: 'all 0.2s',
        boxShadow: '0 .1rem 1rem rgba(0, 102, 245, 0.03)',
        '&:hover': {
          boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      {members && !isEmpty(members) && (
        <Box sx={{ position: 'absolute', top: '-13px', height: 0 }}>
          {members.map((member) => (
            <MemberTag key={member.index} sequenceNumber={member.index} name={member.name ?? ''} />
          ))}
        </Box>
      )}
      <Box sx={{ maxWidth: '55%' }}>
        <Box>
          <Typography variant="heading4" sx={style.nameText}>{accountType}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="row">
            <Typography variant="captionDescriptorMedium" sx={style.coreText}>{themeName}</Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Typography fontWeight={600} fontSize="18px">{formatMoneyValue(marketValueCents)}</Typography>
          <Typography color={(simpleReturnAmount) >= 0 ? 'primary' : 'error'} fontWeight={500} fontSize="12px">
            {`${formatMoneyValue(simpleReturnAmount)} ${`(${formatPercentValue(simpleReturnPercent)})`}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

PortfolioCard.defaultProps = {
  boxProps: undefined,
  onClick: undefined,
  themeIconKey: undefined,
  members: undefined,
};

export default PortfolioCard;
