import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import WithStepWizard from '../../components/common/wrappers/withStepWizard';
import {
  Email, EmailVerification, Phone, PhoneVerification,
} from '../../components/signUp';
import { TokenResponse } from '../../components/signUp/graphql';
import { GET_USER_INITIAL_STATUS } from '../../utils/localVariables';
import { UserContext } from '../../providers/userContextProvider';
import { CREATE_USER } from './graphql';
import TrackingBar from '../../components/common/headers/trackingBar';
import { getBackendLanguage } from '../../assets/i18n/config';
import BlockSignUpModal from '../../components/common/modals/blockSignUpModal';
import { isFeatureEnabled } from '../../components/account/resources';
import { useContextTheme } from '../../providers/contextThemeProvider';

const EmailStep = WithStepWizard(Email);
const EmailVerificationStep = WithStepWizard(EmailVerification);
const PhoneStep = WithStepWizard(Phone);
const PhoneVerificationStep = WithStepWizard(PhoneVerification);

interface UserState {
  id?: string,
  primaryEmail?: string,
  phone?: string,
  phoneFromPartner?: string,
  verificationToken?: string,
  token?: TokenResponse,
}

const SignInPage = (): JSX.Element => {
  const { colors } = useContextTheme();
  const { userContext } = useContext(UserContext);
  const [blockSignUp, setBlockSignUp] = useState(false);
  const [state, updateState] = useState({
    loading: false,
    SW: {} as StepWizardProps,
  });
  const [user, updateUser] = useState<UserState>({
    id: userContext.userId || '',
    primaryEmail: userContext.primaryEmail || '',
    phone: '',
    verificationToken: '',
    token: {},
  });

  const updateModalState = (att: boolean): void => setBlockSignUp(att);
  const updateUserState = (att: Partial<typeof user>): void => updateUser({ ...user, ...att });
  const setLoading = (loading: boolean): void => updateState({ ...state, loading });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });

  const setUserId = (data: { createUser: { user: { id: string } } }): void => {
    setLoading(false);
    updateUser({ ...user, id: data.createUser.user.id });
    console.log({ event: 'CREATED_USER', userId: data.createUser.user.id });
  };

  const [createUserMutation] = useMutation(CREATE_USER, {
    variables: {
      input: {
        primaryEmail: user.primaryEmail,
        language: getBackendLanguage() === 'fr' ? 'FRENCH' : 'ENGLISH',
        timezone: 'America/Edmonton',
        partnerId: userContext.partnerId ?? undefined,
        partnerUserId: userContext.partnerUserId ?? undefined,
        organizationId: userContext.organizationId ?? undefined,
      },
    },
    onCompleted: setUserId,
    onError: () => setLoading(false),
  });

  const { data } = useQuery(GET_USER_INITIAL_STATUS);

  useEffect(() => {
    if (!data.isSignUpComplete) {
      if (userContext.organizationSettings?.blockClientSignUp || isFeatureEnabled('BLOCK_CLIENT_SIGN_UP')) {
        setBlockSignUp(true);
      } else {
        console.log({ event: 'CREATING_USER' });
        createUserMutation().then();
        updateState((prev) => ({ ...prev, loading: true }));
      }
    }
  }, [data.isSignUpComplete, createUserMutation, userContext.organizationSettings?.blockClientSignUp]);

  const shouldSkipNameStep = (): boolean => !!userContext.primaryEmail;
  return (
    <Box
      sx={{
        backgroundColor: colors.baseComponentBackground,
        borderRadius: '8px',
        border: `1px solid ${colors.portfolioCardBorder}`,
        padding: '16px',
      }}
    >
      <StepWizard
        instance={setInstance}
        isLazyMount
        initialStep={shouldSkipNameStep() ? 2 : 1}
        transitions={{}}
        className="ov-step-wizard"
        nav={(
          <TrackingBar steps={[{}, {}, {}, {}]} showStepper={false} />
        )}
      >
        <EmailStep stepName="email" user={user} updateUserState={updateUserState} displayPrivacyPolicy />
        <EmailVerificationStep stepName="emailVerification" updateModalState={updateModalState} user={user} updateUserState={updateUserState} isLoading={state.loading} displayPrivacyPolicy={shouldSkipNameStep()} displayHelpLink />
        <PhoneStep stepName="phone" user={user} updateUserState={updateUserState} displayHelpLink />
        <PhoneVerificationStep stepName="phoneVerification" user={user} updateUserState={updateUserState} displayHelpLink />
      </StepWizard>
      <BlockSignUpModal open={blockSignUp} onClose={() => setBlockSignUp(false)} onButtonClick={() => setBlockSignUp(false)} />
    </Box>
  );
};

export default SignInPage;
