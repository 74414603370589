import { useLazyQuery } from '@apollo/client';
import {
  Box, Typography,
} from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FETCH_GOAL_SUGGESTED_HOLDINGS } from './graphql';
import { ThemeAttributes } from './resources';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import OvForm from '../common/wrappers/ovForm';
import {
  ExpectedPrimaryAssetClassHoldings,
  HoldingResponse,
  PrimaryAssetClassHoldings,
} from '../holdings/resources';
import HoldingsList from '../holdings/holdingsList';
import { translateBackend } from '../../assets/i18n/config';
import { sendAnalyticsEvent } from '../../utils/firebase';
import HoldingsList2 from '../holdings/holdingsList2';
import { availableFeatureFlagsVar } from '../../utils/localVariables';

export interface SubAccountRef {
  themeId?: string,
  financialProductId?: string,
  financialProductDisclaimer?: string,
}

interface Props {
  goalId: string,
  showLoading: boolean,
  setSubAccountRef: (att: SubAccountRef) => void,
  setHasPortfolioDisclaimer?: (value: boolean) => void,
  selectedTheme?: ThemeAttributes,
  onContinue?: () => void,
  analyticsEvent?: string,
}

const ThemeDescription = ({
  goalId, setSubAccountRef, setHasPortfolioDisclaimer, selectedTheme, onContinue, showLoading, analyticsEvent,
}: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const [fetchingHoldings, setFetchingHoldings] = useState(true);
  const [holdings, setHoldings] = useState<HoldingResponse[]>([]);
  const [assetClassHoldings, setAssetClassHoldings] = useState<PrimaryAssetClassHoldings[]>([]);
  const onFetchAvailableThemesCompleted = (data: {
    fetchGoal: { goal: {
      suggestedFinancialProduct: {
      id: string,
      translatedPortfolioDisclaimer?: { en?: string, fr?: string },
      children: HoldingResponse[],
      expectedPrimaryAssetClassHoldings: ExpectedPrimaryAssetClassHoldings[]
    } } }
  }): void => {
    console.log({ event: 'SUGGESTED_HOLDINGS_FETCHED', financialProductId: data.fetchGoal.goal.suggestedFinancialProduct.id });
    const financialProductDisclaimer = translateBackend(data.fetchGoal.goal.suggestedFinancialProduct.translatedPortfolioDisclaimer);
    setSubAccountRef({
      financialProductId: data.fetchGoal.goal.suggestedFinancialProduct.id,
      financialProductDisclaimer,
    });
    setHoldings(data.fetchGoal.goal.suggestedFinancialProduct.children);
    const formattedPrimaryAssetClassHoldings = data.fetchGoal.goal.suggestedFinancialProduct.expectedPrimaryAssetClassHoldings.map((item) => ({
      assetClass: item.assetClass,
      percentage: item.percentage,
      totalCents: 0,
      secondaryAssetClassHoldings: item.expectedSecondaryAssetClassHoldings.map((expectedSecondaryAssetClassHolding) => ({
        assetClass: expectedSecondaryAssetClassHolding.assetClass,
        percentage: expectedSecondaryAssetClassHolding.percentage,
        totalCents: 0,
        securityHoldings: expectedSecondaryAssetClassHolding.expectedSecurityHoldings.map((expectedSecurityHolding) => ({
          percentage: expectedSecurityHolding.percentage,
          totalCents: 0,
          quantity: 0,
          financialProduct: expectedSecurityHolding.financialProduct,
        })),
      })),
    }));
    setAssetClassHoldings([...formattedPrimaryAssetClassHoldings]);
    setFetchingHoldings(false);
    if (setHasPortfolioDisclaimer) {
      setHasPortfolioDisclaimer(!!financialProductDisclaimer.trim());
    }
  };

  const [fetchSuggestedHoldings] = useLazyQuery(FETCH_GOAL_SUGGESTED_HOLDINGS, {
    onCompleted: onFetchAvailableThemesCompleted,
    onError: () => setFetchingHoldings(false),
    nextFetchPolicy: 'standby',
    variables: { goalId, themeId: selectedTheme?.id },
  });
  useEffect(() => {
    if (holdings.length === 0) {
      setFetchingHoldings(true);
      fetchSuggestedHoldings().then();
    }
  }, [holdings, fetchSuggestedHoldings]);

  const confirmTheme = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.stopPropagation();
    event?.preventDefault();
    if (analyticsEvent) sendAnalyticsEvent(analyticsEvent).then();
    if (onContinue) onContinue();
  };

  if (fetchingHoldings) {
    return <OvLoadingIndicator />;
  }

  return (
    <OvForm onSubmit={confirmTheme} loading={showLoading} buttonText={t('account:theme.confirmThemeButton')}>
      <Typography variant="heading2" style={{ textAlign: 'left' }}>{t('account:theme.breakdown.title')}</Typography>
      <Typography variant="paragraph3" style={{ textAlign: 'left' }}>
        {t('account:theme.breakdown.subtitleOne')}
        <b>{selectedTheme?.name}</b>
        {t('account:theme.breakdown.subtitleTwo')}
      </Typography>
      {selectedTheme?.iconPath
        ? (
          <Box display="flex" flexDirection="row" justifyContent="center" marginBottom="16px">
            <img src={selectedTheme.iconPath} alt="icon" />
          </Box>
        )
        : null}
      <Typography variant="paragraph3" style={{ marginBottom: '16px' }}>{selectedTheme?.description}</Typography>
      <Typography mb={1} variant="bodyMain1">{t('account:theme.targetHoldingsTitle')}</Typography>
      <Box sx={{ mb: 1.5 }}>
        {
          availableFeatureFlagsVar().includes('ASSET_CLASSES') ? (
            <HoldingsList2
              primaryAssetClassHoldings={assetClassHoldings}
              holdings={[]}
              listStyle={{ border: 'none' }}
            />
          ) : (
            <HoldingsList
              holdings={holdings}
              listClassName="theme-description-list"
            />
          )
        }
      </Box>
    </OvForm>
  );
};

ThemeDescription.defaultProps = {
  onContinue: undefined,
  selectedTheme: undefined,
  setHasPortfolioDisclaimer: undefined,
  analyticsEvent: undefined,
};

export default ThemeDescription;
