import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';
import OvForm from '../common/wrappers/ovForm';
import { colors } from '../../theme/colors';
import {
  GoalRiskLevels, goalRiskLevels, GoalTimeHorizons, goalTimeHorizons,
} from '../goal/resources';
import { SubAccount } from './suitabilityReviewWizard';
import { allAccountTypes } from '../account/resources';

interface GoalProps {
  id: string,
  name: string,
  riskQuestion1: GoalRiskLevels,
  timeHorizon: GoalTimeHorizons,
  subAccounts?: SubAccount[]
}

export interface UserState {
  goals?: GoalProps[],
}

export interface Props {
  user: UserState,
  onContinue?: () => void,
  loading?: boolean,
}

const style = {
  container: {
    marginBottom: '32px',
    minHeight: '200px',
    maxHeight: '550px',
    overflowY: 'auto' as const,
  },
};

const ConfirmSubAccountTransitions = ({
  user, onContinue, loading,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'suitabilityReview']);

  /* eslint-disable consistent-return */
  const subAccountsToChange = (subAccounts: SubAccount[]): SubAccount[] => subAccounts.filter((a) => (a.acceptableFinancialProduct === false));
  const subAccountElement = (subAccount: SubAccount, goal: GoalProps, index: number): JSX.Element => (
    <Box key={`sub-account-element-${index}`}>
      <Box style={{ backgroundColor: colors.neutral80, marginBottom: '16px' }}>
        <Box style={{ padding: '16px', borderBottom: `2px solid ${colors.neutral70}` }}>
          <Typography variant="heading4" textAlign="left" sx={{ fontWeight: '500' }}>{subAccount.name || allAccountTypes.find((a) => a.key === subAccount?.account?.type)?.title}</Typography>
        </Box>
        <Box style={{ padding: '16px' }}>
          <Typography variant="heading4" textAlign="left" sx={{ fontWeight: '500' }}>
            {`${t('suitabilityReview:confirmSubAccountChanges.from')} ${subAccount?.financialProduct?.name ?? ''} ${t('suitabilityReview:confirmSubAccountChanges.to')} ${subAccount?.suggestedFinancialProduct?.name ?? ''}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const itemElement = (goal: GoalProps, index: number): JSX.Element => (
    <Box key={index}>
      <Box display="flex" justifyContent="start">
        <Typography variant="heading4" textAlign="left" style={{ marginBottom: '10px', color: colors.neutral30, fontWeight: '500' }}>{goal.name}</Typography>
      </Box>
      <Box style={{ backgroundColor: colors.neutral80, marginBottom: '16px' }}>
        <Box style={{ padding: '16px', borderBottom: `2px solid ${colors.neutral70}` }}>
          <Typography variant="heading4" textAlign="left" sx={{ fontWeight: '500' }}>{goalRiskLevels.find((riskLevel) => riskLevel.key === goal.riskQuestion1)?.description ?? ''}</Typography>
        </Box>
        <Box style={{ padding: '16px' }}>
          <Typography variant="heading4" textAlign="left" sx={{ fontWeight: '500' }}>{goalTimeHorizons.find((timeHorizon) => timeHorizon.key === goal.timeHorizon)?.description ?? ''}</Typography>
        </Box>
      </Box>
      {(subAccountsToChange(goal?.subAccounts || []).length > 0) && (
        <Box key={`sub_${index}`} sx={{ marginLeft: '10px' }}>
          <Box display="flex" justifyContent="start">
            <Typography variant="heading4" textAlign="left" style={{ marginBottom: '10px', color: colors.neutral30, fontWeight: '500' }}>{t('suitabilityReview:confirmSubAccountChanges.heading')}</Typography>
          </Box>
          {subAccountsToChange(goal?.subAccounts || []).map((subAccount: SubAccount, subIndex) => subAccountElement(subAccount, goal, subIndex))}
        </Box>
      )}
    </Box>
  );

  const sendChangesToServer = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    if (onContinue) {
      onContinue();
    }
  };

  return (
    <OvForm
      hasButton
      buttonText={t('suitabilityReview:confirmSubAccountChanges.confirmButton')}
      onSubmit={sendChangesToServer}
      loading={loading}
    >
      <Box display="flex" flexDirection="column" style={style.container}>
        <Typography variant="headingMain" textAlign="left" style={{ marginBottom: '20px' }}>{t('suitabilityReview:confirmSubAccountChanges.title')}</Typography>
        <Typography variant="subtitle1" textAlign="left" style={{ marginBottom: '20px' }}>{t('suitabilityReview:confirmSubAccountChanges.subTitle')}</Typography>
        {user.goals?.map((goal, index) => (subAccountsToChange(goal?.subAccounts || []).length > 0) && itemElement(goal, index))}
      </Box>
    </OvForm>
  );
};

ConfirmSubAccountTransitions.defaultProps = {
  onContinue: undefined,
  loading: false,
};

export default ConfirmSubAccountTransitions;
