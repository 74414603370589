/* eslint-disable prefer-arrow-callback */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  react-hooks/exhaustive-deps */

import {
  Input,
  Box,
  MenuItem,
  Select,
  InputAdornment,
  Typography,
  Stack,
  InputLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CanadaFlag from '../../../assets/images/flags/CA.svg';
import { cachedCountries } from '../../../utils/imageFactory';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface Props {
  label: string,
  value?: string,
  error: boolean,
  onContinue?: () => void,
  onChange?: (value: string) => void,
  onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  style?: React.CSSProperties,
  testId?: string,
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<string>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="###-###-####"
        isNumericString
      />
    );
  },
);

const PhoneInput = ({ ...props }: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const [value, setValue] = useState(props.value ?? '');
  const [country] = React.useState(CanadaFlag);
  useEffect(() => {
    setTimeout(() => {
      const input = document.getElementById('phone-input-field');
      if (input) input.focus();
    }, 800);
  });

  return (
    <Stack style={props.style}>
      <InputLabel className="blackInputLabel">{ props.label }</InputLabel>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          marginBottom: '20px',
        }}
      >
        <Select
          variant="standard"
          value={country}
          style={{ marginRight: '10px' }}
          IconComponent={KeyboardArrowDownIcon}
          autoWidth
        >
          <MenuItem value={country}>
            <img src={cachedCountries.get('CA').src} alt="Canada" width={20} />
          </MenuItem>
        </Select>
        <Input
          id="phone-input-field"
          data-testid={props.testId ?? 'phone-input'}
          fullWidth
          style={{ padding: '0px' }}
          required
          error={props.error}
          value={value.replace('+1', '')}
          inputComponent={NumberFormatCustom as any}
          inputProps={{
            inputMode: 'numeric',
            style: {
              outline: 'none',
              borderColor: colors.ovBlack,
            },
          }}
          startAdornment={(
            <InputAdornment
              position="start"
            >
              <Typography variant="countryCode" color={colors.ovBlack}>+1</Typography>
            </InputAdornment>
          )}
          onChange={(event) => {
            setValue(event.target.value);
            if (props.onChange) props.onChange(`+1${event.target.value}`);
          }}
        />
      </Box>
    </Stack>
  );
};

PhoneInput.defaultProps = {
  value: '',
  onContinue: undefined,
  onChange: undefined,
  onClear: undefined,
  style: undefined,
  testId: undefined,
};

export default PhoneInput;
