import { useMutation } from '@apollo/client';
import {
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, FormEvent, useState } from 'react';
import { UPDATE_USER } from '../graphql';
import SimpleInput from '../../common/inputs/simpleInput';
import { InputType } from '../../common/inputs/utils/inputType';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  financialFixedAssetsCents?: number,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
}

const ValueOfSavingsAndInvestment = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(user.financialFixedAssetsCents ? (user.financialFixedAssetsCents / 100).toFixed(2) : '');
  const updateFinancialFixedAssetCentsOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'FINANCIAL_LIQUID_ASSET_CENTS_UPDATED', financialFixedAssetsCents: Math.round(Number(value) * 100) });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };

  const [updateFinancialFixedAssetsCents] = useMutation(UPDATE_USER, {
    variables: {
      input: { financialFixedAssetsCents: Math.round(Number(value) * 100) },
    },
    onCompleted: updateFinancialFixedAssetCentsOnCompleted,
    onError: () => setLoading(false),
  });

  const onContinueClick = async (event : FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingEnterFixedAssetsContinue).then();
    setLoading(true);
    updateFinancialFixedAssetsCents().then();
  };

  const updateState = (event: ChangeEvent<HTMLTextAreaElement|HTMLInputElement>): void => {
    let valueOfSavings;
    if (Number(event.target.value) > 90000000000000) {
      valueOfSavings = '90000000000000';
    } else {
      valueOfSavings = event.target.value;
    }
    setValue(valueOfSavings);
    updateUserState({ ...user, financialFixedAssetsCents: Math.round(Number(valueOfSavings) * 100) });
  };

  return (
    <OvForm loading={loading} onSubmit={onContinueClick}>
      <Typography variant="heading2">{t('user:howToInvest.assets.title')}</Typography>
      <Typography variant="paragraph3">{t('user:howToInvest.assets.subTitle')}</Typography>
      <SimpleInput onClear={() => setValue('')} inputProps={{ inputMode: 'numeric' }} testId="value-input" label={t('user:howToInvest.annualIncome.valuePrompt')} inputType={InputType.CURRENCY} value={value} onChange={updateState} required />
    </OvForm>
  );
};

ValueOfSavingsAndInvestment.defaultProps = {
  onContinue: undefined,
};

export default ValueOfSavingsAndInvestment;
