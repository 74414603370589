import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { CSSProperties } from 'react';
import OvButton from '../buttons/ovButton';
import { colors } from '../../../theme/colors';

interface Props {
  hasButton?: boolean,
  suffixButton?: {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => unknown,
    text: string,
    variant: 'primary-large' | 'secondary-large',
    dataTestId: string,
    type?: 'text',
  },
  children?: React.ReactNode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit?: (event: any) => unknown,
  buttonText?: string,
  buttonVariant?: string,
  loading?: boolean,
  disableButton?: boolean,
  submitButton?: boolean,
  formsStyle?: CSSProperties,
  suffixElement?: JSX.Element,
}

const OvForm = ({
  hasButton,
  children,
  onSubmit,
  loading, buttonText, disableButton, submitButton, suffixButton, buttonVariant, formsStyle, suffixElement,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'signUp']);
  const isDisabled = loading ? true : disableButton;

  return (
    <form
      autoComplete="off"
      onSubmit={onSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        height: '100%',
        ...formsStyle,
      }}
    >
      <div style={{ flex: '1 0 auto' }}>
        {children}
      </div>
      {
        hasButton
        && (
          <div>
            <OvButton
              disabled={isDisabled}
              loading={loading || false}
              text={buttonText ?? t('base:button.continue')}
              type={submitButton ? 'submit' : 'button'}
              variant={buttonVariant as 'primary-large' | 'secondary-large'}
              data-testid="continue-button"
              onClick={!submitButton ? onSubmit : undefined}
            />
          </div>
        )
      }
      {
        suffixButton
        && (
          suffixButton.type === 'text' ? (
            <Box style={{ marginBottom: 20, marginTop: 16 }}>
              <Typography
                variant="heading5"
                onClick={suffixButton.onClick}
                style={{ textAlign: 'center', cursor: 'pointer', color: colors.primary }}
                data-testid={suffixButton.dataTestId}
              >
                {suffixButton.text}
              </Typography>
            </Box>
          ) : (
            <Button
              style={{ marginTop: 8 }}
              variant={suffixButton.variant}
              onClick={suffixButton.onClick}
              type="button"
              disabled={isDisabled}
              data-testid={suffixButton.dataTestId}>
              {suffixButton.text}
            </Button>
          )
        )
      }
      {suffixElement}
    </form>
  );
};

OvForm.defaultProps = {
  hasButton: true,
  children: undefined,
  onSubmit: undefined,
  loading: false,
  buttonText: undefined,
  disableButton: false,
  submitButton: true,
  suffixButton: undefined,
  buttonVariant: 'primary-large',
  formsStyle: undefined,
  suffixElement: undefined,
};

export default OvForm;
