import { useContext } from 'react';
import { UserContext } from '../../providers/userContextProvider';
import Dashboard from '../dashboard';
import GoalDetails from '../goalDetails';
import SubAccountDetails from '../subAccountDetails';
import AccountDetails from '../accountsDetails';
import Affiliates from '../affiliates';
import DocumentsPage from '../documents';

// TODO: react-router is not working when embedded in iframe
// const Home = (): JSX.Element => (
//   <Suspense fallback={<OvLoadingIndicator />}>
//     <Routes>
//       <Route path="/" element={<Dashboard />} />
//       <Route path="/goals/:goalId" element={<GoalDetails />} />
//       <Route path="/goals/:goalId/portfolios/:subAccountId" element={<SubAccountDetails />} />
//       <Route path="*" element={<Dashboard />} />
//     </Routes>
//   </Suspense>
// );

// For now, I'm defining our own navigate and useParams to set to and get from sessionStorage (utils/router.ts)

const routes: { path: string, component: JSX.Element }[] = [
  { path: '/', component: <Dashboard /> },
  { path: '/goal', component: <GoalDetails /> },
  { path: '/goal/portfolio', component: <SubAccountDetails /> },
  { path: '/account', component: <AccountDetails /> },
  { path: '/account/affiliate', component: <Affiliates /> },
  { path: '/documents', component: <DocumentsPage /> },
];

const Home = (): JSX.Element => {
  const { userContext } = useContext(UserContext);
  const page = routes.find((r) => r.path === userContext.path)?.component ?? <Dashboard />;
  return page;
};

export default Home;
