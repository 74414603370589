/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import max from 'lodash/max';
import min from 'lodash/min';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import monteCarlo from '@onevesthq/ov-monte-carlo';
import { Line } from 'react-chartjs-2';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Lottie from 'react-lottie';
import YourGoalsIcon from '../../assets/images/menu/target-icon.svg';
import HandlerIcon from '../../assets/images/menu/projection-handler.svg';
import animationData from '../../assets/images/loading_graph.json';
import OvForm from '../common/wrappers/ovForm';
import { styles } from './styles';
import SimpleInput from '../common/inputs/simpleInput';
import { InputType } from '../common/inputs/utils/inputType';
import { Goal } from '../../utils/commonGraphql';
import { GoalRef } from '../goal/createGoalWizard';
import FeedbackModal from '../common/wrappers/modals/ovFeedbackModal';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { useContextTheme } from '../../providers/contextThemeProvider';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export interface ProjectionProps {
  onContinue?: () => void,
  currentGoal?: Goal | GoalRef,
}
const Projection = ({
  currentGoal, ...props
}: ProjectionProps): JSX.Element => {
  const { t } = useTranslation(['base', 'user', 'goal', 'projection']);
  const { colors } = useContextTheme();
  const DEFAULT_RATE_OF_RETURN = (((currentGoal?.suggestedFinancialProduct?.forecastedRateOfReturn || 0.07) / 100) + 1);
  const DEFAULT_DEVIATION = ((currentGoal?.suggestedFinancialProduct?.forecastedStandardDeviation || 1.05) / 100);
  const DEFAULT_YEARS = 30;
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [showMonthlyInvestment, setShowMonthlyInvestment] = useState(false);
  const [showInitialInvestment, setShowInitialInvestment] = useState(false);
  const [showPlusSign, setShowPlusSign] = useState(false);
  const [labels, setLabels] = useState(['']);
  const [maxYVal, setmaxYVal] = useState(0);
  const [minVal, setminVal] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [currentYear, setCurrentYear] = useState(DEFAULT_YEARS);
  const [simulationGraphData, setSimulationGraphData] = useState<any>({});
  const [lessLikelyLowerBound, setLessLikelyLowerBound] = useState('');
  const [lessLikelyUpperBound, setLessLikelyUpperBound] = useState('');
  const [likelyLowerBound, setLikelyLowerBound] = useState('');
  const [likelyUpperBound, setLikelyUpperBound] = useState('');
  const [initialInvestment, setInitialInvestment] = useState(1000);
  const [monthlyInvestment, setMonthlyInvestment] = useState(10);
  const [goalCompletionYears, setGoalCompletionYears] = useState(0);
  const loaded = useRef<boolean>(false);
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  useEffect(() => {
    let target = 0;
    let initialAmount = 0;
    let monthlyAmount = 0;
    if (currentGoal?.targetAmountCents) {
      target = currentGoal.targetAmountCents / 100;
      initialAmount = Math.round((target / 10) / 100) * 100;
      monthlyAmount = initialAmount / 100;
      setInitialInvestment(initialAmount > 100 ? initialAmount : 100);
      setMonthlyInvestment(monthlyAmount > 1 ? monthlyAmount : 1);
    }
  }, [currentGoal]);
  useEffect(() => {
    const labelsYears: string[] = [];
    const runMonteCarlo = async (): Promise<any> => {
      const simulationData = await monteCarlo({
        baseValue: initialInvestment,
        monthlyContribution: monthlyInvestment,
        rateOfReturn: DEFAULT_RATE_OF_RETURN,
        deviation: DEFAULT_DEVIATION,
        years: DEFAULT_YEARS,
        timesRun: 4000,
      });
      // eslint-disable-next-line
      for (let i = 0; i < DEFAULT_YEARS; i = i + 1) {
        labelsYears.push(i.toString());
      }
      loaded.current = true;
      setLabels(labelsYears);
      setSimulationGraphData(simulationData);
      const lllb = `$${formatter.format(Number(max(simulationData[10]) || 0))}`;
      const llub = `$${formatter.format(Number(max(simulationData[90]) || 0))}`;
      const llb = `$${formatter.format(Number(max(simulationData[25]) || 0))}`;
      const lub = `$${formatter.format(Number(max(simulationData[75]) || 0))}`;
      setLessLikelyLowerBound(lllb);
      setLessLikelyUpperBound(llub);
      setLikelyLowerBound(llb);
      setLikelyUpperBound(lub);
      setmaxYVal(Number(max(simulationData[90])));
      setminVal(Number(min(simulationData[10])));
      let sourceVal = 0;
      if (currentGoal?.targetAmountCents) {
        sourceVal = currentGoal.targetAmountCents / 100;
      }
      // calculated closest goal completion year
      const closest = simulationData[90].reduce((prev, curr) => ((Math.abs(curr - sourceVal) < Math.abs(prev - sourceVal) ? curr : prev)));
      setGoalCompletionYears(simulationData[90].indexOf(closest) + 1);
      setShowPlusSign(closest >= simulationData[90][simulationData[90].length - 1]);
      setLoading(false);
    };
    if (!loaded.current) {
      runMonteCarlo();
    }
  }, [loaded,
    initialInvestment,
    monthlyInvestment,
    formatter,
    currentGoal?.targetAmountCents,
    currentGoal?.suggestedFinancialProduct?.forecastedRateOfReturn,
    currentGoal?.suggestedFinancialProduct?.forecastedStandardDeviation,
    DEFAULT_RATE_OF_RETURN,
    DEFAULT_DEVIATION,
  ]);
  const updateDimensions = (): void => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const externalTooltipHandler = (context: any): void => {
    // Tooltip Element
    const { tooltip } = context;
    const lllb = `$${formatter.format(Number(tooltip.dataPoints[0].raw) || 0)}`;
    const llub = `$${formatter.format(Number(tooltip.dataPoints[4].raw) || 0)}`;
    const llb = `$${formatter.format(Number(tooltip.dataPoints[1].raw) || 0)}`;
    const lub = `$${formatter.format(Number(tooltip.dataPoints[3].raw) || 0)}`;
    setLessLikelyLowerBound(lllb);
    setLessLikelyUpperBound(llub);
    setLikelyLowerBound(llb);
    setLikelyUpperBound(lub);
  };
  /* eslint-disable no-underscore-dangle */
  const tooltipLine = {
    id: 'tooltipLine',
    afterDatasetsDraw: (chart: any) => {
      const ProjectionHandlerIcon = new Image();
      ProjectionHandlerIcon.src = HandlerIcon;
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx3 = chart.ctx;
        ctx3.hovered = true;
        const el = document.getElementById('chart');
        if (el) {
          el.style.cursor = 'pointer';
        }
        ctx3.save();
        const activePoint = chart.tooltip._active[0];
        ctx3.beginPath();
        ctx3.fillStyle = colors.black;
        if (activePoint.index > 27) {
          ctx3.fillRect(activePoint.element.x - 50, chart.chartArea.top - 10, 60, 20);
        } else {
          ctx3.fillRect(activePoint.element.x - 30, chart.chartArea.top - 10, 60, 20);
        }
        ctx3.font = '18px';
        ctx3.fillStyle = colors.neutralWhite;
        if (activePoint.index > 27) {
          ctx3.fillText(`Year ${activePoint.index + 1}`, activePoint.element.x - 45, chart.chartArea.top + 5);
        } else {
          ctx3.fillText(`Year ${activePoint.index + 1}`, activePoint.element.x - 20, chart.chartArea.top + 5);
        }
        ctx3.stroke();
        setCurrentYear(activePoint.index + 1);
        ctx3.beginPath();
        ctx3.setLineDash([5, 10]);
        ctx3.moveTo(activePoint.element.x, chart.chartArea.top + 5);
        ctx3.lineTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineWidth = 1.5;
        ctx3.strokeStyle = colors.secondary;
        ctx3.stroke();
        ctx3.restore();
        ctx3.beginPath();
        ctx3.setLineDash([5, 10]);
        ctx3.moveTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx3.lineWidth = 1.5;
        ctx3.strokeStyle = colors.secondary;
        ctx3.stroke();
        ctx3.beginPath();
        if (activePoint.element.x) {
          ctx3.drawImage(ProjectionHandlerIcon, (activePoint.element.x - 37), (chart.chartArea.bottom - 30));
        }
        ctx3.stroke();
        ctx3.restore();
      }
    },
  };
  const INDEX = 'index';
  const NEAREST = 'nearest';
  const options = {
    events: ['click' as const, 'touchstart' as const, 'touchmove' as const],
    animation: {
      duration: 0,
    },
    interaction: {
      intersect: false,
      mode: 'index' as typeof INDEX,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        enabled: false,
        position: 'nearest' as typeof NEAREST,
        external: externalTooltipHandler,
      },
    },
    hover: {
      intersect: false,
      mode: 'point' as const,
    },
    tooltips: {
      intersect: false,
      mode: 'index',
    },
    responsive: true,
    elements: { point: { radius: 0 }, line: { fill: false } },
    scales: {
      y: {
        offset: true,
        gridLines: { display: false, drawBorder: false },
        ticks: {
          display: true,
          minVal: minVal || 0,
          maxVal: maxYVal,
          autoSkip: true,
          fontColor: colors.neutral20,
          maxTicksLimit: 10,
          callback: (tickValue: any) => (`$${formatter.format(tickValue)}`),
        },
        grid: {
          display: true,
          drawBorder: false,
        },
      },
      x: {
        display: false,
        gridLines: { display: false },
        ticks: {
          display: false,
        },
        scaleShowLabels: false,
        grid: {
          display: false,
        },
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        data: simulationGraphData[10],
        borderColor: colors.projectionBackgroundLight,
        backgroundColor: colors.baseComponentBackground,
        pointColor: colors.pointColor,
        borderWidth: 1,
        fill: true,
        pointRadius: 0,
        pointStyle: 'none',
        order: 1,
      },
      {
        data: simulationGraphData[25],
        borderColor: colors.projectionBackgroundLight,
        backgroundColor: colors.projectionBackgroundLight,
        pointColor: colors.pointColor,
        borderWidth: 3,
        fill: true,
        pointRadius: 0,
        pointStyle: 'none',
        order: 2,
      },
      {
        data: simulationGraphData[50],
        borderColor: colors.neutralWhite,
        pointColor: colors.neutralWhite,
        borderWidth: 3,
        fill: false,
        pointRadius: 0,
        pointStyle: 'circle',
        order: 3,
      },
      {
        data: simulationGraphData[75],
        borderColor: colors.projectionBackgroundLight,
        backgroundColor: colors.projectionBackgroundDark,
        pointColor: colors.pointColor,
        borderWidth: 3,
        fill: true,
        pointRadius: 0,
        pointStyle: 'none',
        order: 4,
      },
      {
        data: simulationGraphData[90],
        borderColor: colors.projectionBackgroundLight,
        backgroundColor: colors.projectionBackgroundLight,
        pointColor: colors.pointColor,
        fill: 'origin',
        tension: 0.01,
        borderWidth: 3,
        pointRadius: 0,
        pointStyle: 'none',
        order: 5,
      },
    ],
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const handleAmountChange = (value: string): void => {
    loaded.current = false;
    setMonthlyInvestment(parseInt(value, 10));
  };
  const handleInitialAmountChange = (value: string): void => {
    loaded.current = false;
    setInitialInvestment(parseInt(value, 10));
  };

  if (showDetails) {
    return (
      <FeedbackModal
        open={showDetails}
        onClose={() => {
          setShowDetails(false);
        }}
        component={(
          <Box sx={styles.infoModalContent}>
            <Typography variant="heading3" sx={styles.headingText}>
              {t('projection:infoModal.title')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('projection:infoModal.subHeading')}
            </Typography>
            <Box style={styles.infoList}>
              <ol>
                <li style={{ marginBottom: '10px' }}>
                  {t('projection:infoModal.listItemContribution')}
                </li>
                <li style={{ marginBottom: '10px' }}>
                  {t('projection:infoModal.listItemReturn')}
                </li>
              </ol>
            </Box>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('projection:infoModal.acknowledgment')}
            </Typography>
            <Box sx={styles.gotItButton}>
              <Button
                variant="primary-large"
                onClick={() => {
                  setShowDetails(false);
                }}
                sx={{ marginBottom: '5px' }}>
                {t('base:button.gotIt')}
              </Button>
            </Box>
          </Box>
        )}
      />
    );
  }

  if (showMonthlyInvestment) {
    return (
      <FeedbackModal
        open={showMonthlyInvestment}
        onClose={() => {
          setShowMonthlyInvestment(false);
        }}
        component={(
          <Box>
            <Typography variant="heading3" sx={styles.headingText}>
              {t('projection:monthlyInvestment')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('projection:monthlyInvestmentMessage')}
            </Typography>
            <Box sx={styles.gotItButton}>
              <Button
                variant="primary-large"
                onClick={() => {
                  setShowMonthlyInvestment(false);
                }}
                sx={{ marginBottom: '5px' }}>
                {t('base:button.gotIt')}
              </Button>
            </Box>
          </Box>
        )}
      />
    );
  }
  if (showInitialInvestment) {
    return (
      <FeedbackModal
        open={showInitialInvestment}
        onClose={() => {
          setShowInitialInvestment(false);
        }}
        component={(
          <Box>
            <Typography variant="heading3" sx={styles.headingText}>
              {t('projection:initialInvestment')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('projection:initialInvestmentMessage')}
            </Typography>
            <Box sx={styles.gotItButton}>
              <Button
                variant="primary-large"
                onClick={() => {
                  setShowInitialInvestment(false);
                }}
                sx={{ marginBottom: '5px' }}>
                {t('base:button.gotIt')}
              </Button>
            </Box>
          </Box>
        )}
      />
    );
  }
  return (
    <OvForm
      onSubmit={(e) => {
        e.preventDefault();
        sendAnalyticsEvent(ovAnalyticsEvents.addGoalPortfolioProjectionContinue).then();
        if (props.onContinue) props.onContinue();
      }}
      loading={false}
    >
      <Typography variant="headingMain" style={styles.title}>{t('projection:title')}</Typography>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="captionDescriptorMedium" style={styles.completionText}>{t('projection:likelyText')}</Typography>
          <Box style={styles.yearWrapper}>
            <img src={YourGoalsIcon} alt="icon" width={40} style={styles.goalIcon} />
            <Typography variant="captionSectionerMedium" width={styles.yearText}>
              {goalCompletionYears}
              {showPlusSign && (
                '+'
              )}
              <span style={{ marginLeft: '5px' }}>{t('projection:years')}</span>
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            data-testid="info-wrapper"
            style={styles.infoWrapper}
            onClick={() => {
              sendAnalyticsEvent(ovAnalyticsEvents.addGoalForecastDescriptionSelect).then();
              setShowDetails(true);
            }}
          >
            <InfoRoundedIcon style={styles.infoIcon} />
            <Typography
              variant="paragraph2"
              style={styles.infoText}
            >
              {t('projection:infoText')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography variant="paragraph2" width={styles.byYearText}>
        {t('projection:byYear')}
        <span style={{ marginLeft: '5px' }}>{currentYear}</span>
      </Typography>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={styles.likelyBox} />
            <Typography variant="captionSectionerMedium" sx={styles.likelyText}>{t('projection:likely')}</Typography>
          </Box>
          <Typography variant="captionSectionerMedium" sx={styles.projectionValue}>
            {likelyLowerBound}
            -
            {likelyUpperBound}
          </Typography>
        </Box>
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={styles.lessLikelyBox} />
            <Typography variant="captionSectionerMedium" sx={styles.likelyText}>{t('projection:lessLikely')}</Typography>
          </Box>
          <Typography variant="captionSectionerMedium" sx={styles.projectionValue} style={{ fontWeight: 500 }}>
            {lessLikelyLowerBound}
            -
            {lessLikelyUpperBound}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ height: '250px', width: '100%' }}>
        {loading ? (
          <Lottie
            options={defaultOptions}
            height={220}
            width="100%"
          />
        ) : <Line options={options} plugins={[tooltipLine]} data={data} id="chart" height={styles.chart.height} style={styles.chart} />}
      </Box>
      <Typography variant="captionDescriptorMedium" sx={styles.adjustDesc}>{t('projection:adjustDecs')}</Typography>
      <Box sx={styles.bottomWrapper}>
        <Box sx={{ paddingRight: '10px' }}>
          <Box sx={{ display: 'flex', margin: 0 }}>
            <Typography variant="captionSectionerMedium" sx={styles.likelyText}>{t('projection:initialInvestment')}</Typography>
            <InfoRoundedIcon style={styles.bottomInfoIcon} onClick={() => setShowInitialInvestment(true)} />
          </Box>
          <SimpleInput
            testId="initialInvestment"
            style={{ height: '50px', marginTop: '-12px' }}
            value={initialInvestment.toString()}
            onChange={(e) => handleInitialAmountChange(e.target.value)}
            inputType={InputType.CURRENCY}
            inputProps={{
              inputprops: { value: initialInvestment },
              onBlur: () => sendAnalyticsEvent(ovAnalyticsEvents.addGoalInitialInvestmentSlider).then(),
            }}
            onClear={() => setInitialInvestment(0)}
            label=""
          />
        </Box>
        <Box sx={{ paddingRight: '10px' }}>
          <Box sx={{ display: 'flex', margin: 0 }}>
            <Typography variant="captionSectionerMedium" sx={styles.likelyText}>{t('projection:monthlyInvestment')}</Typography>
            <InfoRoundedIcon style={styles.bottomInfoIcon} onClick={() => setShowMonthlyInvestment(true)} />
          </Box>
          <SimpleInput
            testId="monthlyInvestment"
            style={{ height: '50px', marginTop: '-12px' }}
            value={monthlyInvestment.toString()}
            onChange={(e) => handleAmountChange(e.target.value)}
            inputType={InputType.CURRENCY}
            inputProps={{
              inputprops: { value: monthlyInvestment },
              onBlur: () => sendAnalyticsEvent(ovAnalyticsEvents.addGoalMonthlyInvestmentSlider).then(),
            }}
            onClear={() => setMonthlyInvestment(0)}
            label=""
          />
        </Box>
      </Box>
    </OvForm>
  );
};

Projection.defaultProps = {
  onContinue: undefined,
  currentGoal: undefined,
};

export default Projection;
