import { gql } from '@apollo/client';

export const START_EMAIL_PASSWORDLESS_AUTH = gql`
  mutation startPasswordlessAuth($input: StartPasswordlessAuthInput!) {
    startPasswordlessAuth(input: $input) {
      userEmail
      message
    }
  }
`;

export const CONFIRM_EMAIL_PASSWORDLESS_AUTH = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token {
        accessToken
        expiresIn
        mfaToken
        oobCode
        refreshToken
      }
    }
  }
`;

export const START_PHONE_MFA = gql`
  mutation startMultifactorEnrollment($input: StartMultifactorEnrollmentInput!) {
    startMultifactorEnrollment(input: $input) {
      oobCode
      message
    }
  }
`;

export const START_PHONE_MFA_CHALLENGE = gql`
mutation startMultifactorChallenge($input: StartMultifactorChallengeInput!) {
  startMultifactorChallenge(input: $input) {
    oobCode
  }
}
`;

export const CONFIRM_PHONE_MFA = gql`
  mutation verifyMultifactorToken($input: VerifyMultifactorTokenInput!) {
    verifyMultifactorToken(input: $input) {
      token {
        accessToken
        expiresIn
        mfaToken
        oobCode
        refreshToken
      }
    }
  }
`;

export const START_PASSWORDLESS_UPDATE = gql`
  mutation startPasswordlessUpdate($input: StartPasswordlessUpdateInput!) {
    startPasswordlessUpdate(input: $input) {
      username
      oobCode
    }
  }
`;

export const VERIFY_AND_UPDATE_PASSWORDLESS = gql`
  mutation verifyAndUpdatePasswordless($input: VerifyAndUpdatePasswordlessInput!) {
    verifyAndUpdatePasswordless(input: $input) {
      username
      mfaToken
    }
  }
`;

export interface TokenResponse {
  accessToken?: string,
  expiresIn?: number,
  mfaToken?: string,
  oobCode?: string,
  refreshToken?: string,
}
