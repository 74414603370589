import {
  Box,
  BoxProps, IconButton,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { DetailedHTMLProps } from 'react';
import { colors } from '../../theme/colors';
import ArrowDownRightRed from '../../assets/images/outline/arrow-down-right-red.svg';
import ArrowUpRightGreen from '../../assets/images/outline/arrow-up-right-green.svg';
import { formatMoneyValue, formatPercentValue, formatReturnAmount } from '../../utils/commonMethods';
import informationIcon from '../../assets/images/information.svg';

const defineArrowIcon = (amount: number): string => (amount >= 0 ? ArrowUpRightGreen : ArrowDownRightRed);
const defineColor = (amount: number): string => (amount < 0 ? colors.error : colors.success);
const defineGainSymbol = (percent: number, showSymbol = true): string => {
  if (showSymbol && percent > 0) return '+';
  if (showSymbol && percent < 0) return '';
  return '';
};
const defineReturnPercent = (percent?: number, showSymbol = true): string => (percent !== undefined ? ` (${defineGainSymbol(percent, showSymbol)}${formatPercentValue(percent, 2)})` : '');

interface Props {
  boxProps?: BoxProps,
  title?: string,
  marketValueCents: number,
  returnAmount: number,
  returnPercent?: number,
  displayEndText?: boolean,
  subtitleProp?: TypographyProps,
  arrowIconProp?: DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  component?: string,
  onInfoClicked?: () => void,
}

const BalanceAndReturnValues = ({
  boxProps, title, marketValueCents, returnAmount, returnPercent, displayEndText, subtitleProp, arrowIconProp, component, onInfoClicked,
}: Props): JSX.Element => {
  const { t } = useTranslation(['statistics']);

  const defineEndText = (): string => {
    if (displayEndText && returnAmount > 0) return ` ${t('statistics:balanceAndReturnValues.endTextGain')}`;
    if (displayEndText && returnAmount < 0) return ` ${t('statistics:balanceAndReturnValues.endTextLoss')}`;
    return '';
  };

  return (
    <Box
      style={{
        width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', marginLeft: '32px',
      }}
      {...boxProps}>
      {title
        ? (
          <Box display="flex">
            <Typography variant={component === 'home' ? 'heading5' : 'heading2'} style={{ marginBottom: (component === 'home') ? '0px' : '10px' }}>{title}</Typography>
            { onInfoClicked ? (<IconButton style={{ marginLeft: '10px', padding: '0px' }} onClick={onInfoClicked}><img src={informationIcon} alt="information-icon" /></IconButton>) : undefined }
          </Box>
        ) : null}
      <Typography variant="headingMain" {...subtitleProp}>{formatMoneyValue(marketValueCents)}</Typography>
      {(marketValueCents || marketValueCents !== 0) ? (
        <Box style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '1px',
        }}>
          <img src={defineArrowIcon(returnAmount)} alt="sign-icon" style={{ width: '19.14px', margin: '0px 4.78505px' }} {...arrowIconProp} />
          <Box width="4.5px" />
          <Typography variant="paragraph2" style={{ color: defineColor(returnAmount) }}>
            {`${formatReturnAmount(returnAmount, '$')}${defineReturnPercent(returnPercent, !displayEndText)}${defineEndText()}`}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

BalanceAndReturnValues.defaultProps = {
  boxProps: undefined,
  title: undefined,
  returnPercent: undefined,
  displayEndText: false,
  subtitleProp: undefined,
  arrowIconProp: undefined,
  component: undefined,
  onInfoClicked: undefined,
};

export default BalanceAndReturnValues;
