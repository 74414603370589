/* eslint-disable  react/jsx-no-duplicate-props */
import {
  Box, Modal,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  FormEvent, useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StaticDatePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DropdownInput from '../../common/inputs/dropdownInput';
import OvForm from '../../common/wrappers/ovForm';
import {
  CREATE_DEPOSIT_TRANSFER,
  CREATE_SCHEDULED_DEPOSIT_TRANSFER,
  FETCH_ALL_INCOMPLETE_FORM_AGREEMENT,
  FETCH_MY_BANK_ACCOUNTS,
  FETCH_SUB_ACCOUNT_NET_CONTRIBUTIONS,
  SIGN_PAD_AGREMETNS,
} from '../graphql';
import Flinks from '../../flinks/flinks';
import { UserContext } from '../../../providers/userContextProvider';
import { InputType } from '../../common/inputs/utils/inputType';
import SimpleInput from '../../common/inputs/simpleInput';
import PortfolioDropdown from '../../common/controllers/portfolioDropdown';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import Disclaimer from '../../common/disclaimer';
import { getBackendLanguage, getLocale } from '../../../assets/i18n/config';
import { generateUniqueRequestId } from '../../../utils/generic';
import { AccountTypes, FeatureFlagTypes } from '../../account/resources';
import PADLink from './pADLink';
import GoalDropDown from '../../common/controllers/goalDropdown';
import { SubAccount } from '../../../utils/commonGraphql';
import { AllIncompleteFormAgreement } from '../../documents/incompleteAgreements';

dayjs.extend(utc);

interface Props {
  onClose: () => void,
  selectedGoalId?: string,
  selectedSubAccountId?: string,
  onSuccessTransfer?: () => void,
  hideToastMessage?: boolean,
  analyticsEvent?: string,
  showOnlySelectedGoalAsOption?: boolean,
  showOnlySelectedSubAccountAsOption?: boolean,
}

const BankAccountDeposit = ({
  onClose,
  onSuccessTransfer,
  selectedGoalId,
  selectedSubAccountId,
  hideToastMessage,
  analyticsEvent,
  showOnlySelectedGoalAsOption,
  showOnlySelectedSubAccountAsOption,
}: Props): JSX.Element => {
  const nextDay = new Date().setDate(new Date().getDate() + 1);
  const { t } = useTranslation(['transfer']);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [showFlinks, setShowFlinks] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<{ id: string; name: string; bankAccountNumber: string; }[]>([]);
  const bankAccountsRef = useRef(bankAccounts);
  const [from, setFrom] = useState('');
  const [subAccountContributionsAmount, setSubAccountContributionsAmount] = useState(0);
  const [toGoal, setToGoal] = useState(selectedGoalId);
  const [toPortfolio, setToPortfolio] = useState(selectedSubAccountId);
  const [goalSubAccounts, setGoalSubAccounts] = useState<SubAccount[] | undefined>(undefined);
  const [amount, setAmount] = useState('');
  const [schedule, setSchedule] = useState('ONE_TIME');
  const [starting, setStarting] = useState('Now');
  const [scheduledDate, setScheduledDate] = useState(dayjs(nextDay).format('YYYY-MM-DD'));
  const [tempDate, setTempDate] = useState('');
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const { userContext, setUserContext } = useContext(UserContext);
  const availableFeatureFlags = userContext?.availableFeatureFlags || [];
  const isMounted = useRef(false);
  const { showToast } = useGlobalToast();

  const minInitialDepositCents = userContext.organization?.minInitialDepositCents ?? 100;
  const minInvestAmount = minInitialDepositCents / 100;
  const hasContributedEnough = (minInitialDepositCents - subAccountContributionsAmount) <= 0;

  const setBankAccountData = (data: {
    me: {
      user: {
        bankAccounts: {
          id: string,
          name: string,
          bankAccountNumber: string,
        }[]
      },
    }
  }): void => {
    setBankAccounts(data.me.user.bankAccounts);
    const banks = data.me.user.bankAccounts;
    setFrom(banks.length > 0 ? banks[banks.length - 1].id : '');
  };

  const setDepositTransferData = (data: { createDepositTransfer: { transfer: { id: string, state: string, failReason: string, } } }): void => {
    const { id, state, failReason } = data.createDepositTransfer.transfer;
    console.log({
      event: 'TRANSFER_CREATED', id, state, failReason,
    });
    sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.investPageConfirmBankAccount).then();
    if (!hideToastMessage) {
      showToast({ message: t('toastMessages:investmentMadeSuccess.h2'), severity: 'success', title: t('toastMessages:investmentMadeSuccess.h1') });
    }
    setLoading(false);
    if (onSuccessTransfer) onSuccessTransfer();
    onClose();
  };

  const setScheduledDepositTransferData = (data: { createScheduledDepositTransfer: { scheduledTransfer: { id: string, state: string } } }): void => {
    const { id, state } = data.createScheduledDepositTransfer.scheduledTransfer;
    console.log({ event: 'SCHEDULED_TRANSFER_CREATED', id, state });
    sendAnalyticsEvent(ovAnalyticsEvents.investPageConfirmBankAccount).then();
    if (!hideToastMessage) {
      showToast({ message: t('toastMessages:investmentMadeSuccess.h2'), severity: 'success', title: t('toastMessages:investmentMadeSuccess.h1') });
    }
    setLoading(false);
    onClose();
  };

  const [fetchMyBankAccounts] = useLazyQuery(FETCH_MY_BANK_ACCOUNTS, {
    variables: {},
    onCompleted: setBankAccountData,
    nextFetchPolicy: 'no-cache',
  });

  const [fetchSelectedSubAccountContributions] = useLazyQuery(FETCH_SUB_ACCOUNT_NET_CONTRIBUTIONS, {
    variables: {
      subAccountId: toPortfolio,
    },
    onCompleted(data) {
      const netContributions = data.fetchSubAccount.subAccount.netContributionsHistoryCents;
      const totalContributionsAmountCents = netContributions.reduce((acc: number, cur: { amountCents: number }) => acc + cur.amountCents, 0);
      setSubAccountContributionsAmount(totalContributionsAmountCents);
    },
  });

  const [createScheduledDepositTransferMutation] = useMutation(CREATE_SCHEDULED_DEPOSIT_TRANSFER, {
    variables: {
      input: {
        amountCents: Math.round(Number(amount) * 100),
        subAccountId: toPortfolio,
        bankAccountId: from,
        frequency: schedule,
        scheduledDate,
        requestId: generateUniqueRequestId(),
      },
    },
    onCompleted: (e) => setScheduledDepositTransferData(e),
  });

  const [createDepositTransferMutation] = useMutation(CREATE_DEPOSIT_TRANSFER, {
    variables: {
      input: {
        amountCents: Math.round(Number(amount) * 100),
        subAccountId: toPortfolio,
        bankAccountId: from,
        requestId: generateUniqueRequestId(),
      },
    },
    onCompleted: (e) => setDepositTransferData(e),
    onError: () => setLoading(false),
  });

  const [fetchAllIncompleteAgreement] = useLazyQuery(FETCH_ALL_INCOMPLETE_FORM_AGREEMENT, {
    variables: {
      input: {},
    },
  });
  const [signPadAgreement] = useMutation(SIGN_PAD_AGREMETNS, {
    variables: {},
  });

  useEffect(() => {
    if (
      from
      && toGoal
      && toPortfolio
      && amount
      && (Number(amount) > 0)
      && schedule && starting
      && (hasContributedEnough || minInvestAmount <= (Number(amount) || 0))
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [from, toGoal, toPortfolio, amount, schedule, starting, hasContributedEnough, minInvestAmount]);

  useEffect(() => {
    if (!isMounted.current) {
      setUserContext({ ...userContext, pageName: 'deposit-page' });
      fetchMyBankAccounts().then();
      let goalId = selectedGoalId;
      if (!selectedGoalId && userContext.goals && userContext.goals.length === 1) {
        goalId = userContext.goals[0].id;
        setToGoal(goalId);
      }
      const subAccounts = userContext?.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalId);
      if (subAccounts && subAccounts.length === 1) {
        setToPortfolio(subAccounts[0].id);
      }
      isMounted.current = true;
    }
  }, [userContext, setUserContext, selectedGoalId, fetchMyBankAccounts]);

  const makeDeposit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setLoading(true);
    if (schedule === 'ONE_TIME' && starting.toLowerCase() === 'now') {
      createDepositTransferMutation().then();
    } else {
      createScheduledDepositTransferMutation().then(async () => {
        const response = await fetchAllIncompleteAgreement();
        const agreements: AllIncompleteFormAgreement[] = response.data.me.user.allIncompleteFormAgreements;
        const padAgreement = agreements.find((agreement) => agreement.digitalSignatureEnabled && !!agreement.scheduledTransfer && agreement.type === 'PAD_AGREEMENT');
        if (padAgreement) {
          signPadAgreement({
            variables: {
              input: {
                scheduledTransferId: padAgreement.scheduledTransfer ? padAgreement.scheduledTransfer.id : undefined,
                formAgreements: [{
                  type: padAgreement.type,
                  version: padAgreement.minVersion,
                }],
                language: getBackendLanguage().toUpperCase(),
              },
            },
          }).then();
        }
      });
    }
  };

  const confirmDate = (): void => {
    setScheduledDate(tempDate);
    setOpenCalendar(false);
  };

  const formatDateForUI = (): string => {
    if (scheduledDate) {
      return ` (${dayjs(scheduledDate).format('D MMM YYYY')})`;
    }

    return '';
  };

  useEffect(() => {
    bankAccountsRef.current = bankAccounts;
  }, [bankAccounts]);

  useEffect(() => {
    if (toPortfolio) {
      fetchSelectedSubAccountContributions();
    }
  }, [toPortfolio, fetchSelectedSubAccountContributions]);

  const setDate = (value: string): void => {
    setStarting(value === 'Now' ? value : 'Future Date');
    if (value === 'Now') {
      sendAnalyticsEvent(ovAnalyticsEvents.investPageStartDateSelect).then();
      setScheduledDate(dayjs(nextDay).format('YYYY-MM-DD'));
    } else {
      setOpenCalendar(true);
    }
  };

  const onGoalChanged = (goalid: string): void => {
    setToGoal(goalid);
    const subAccounts = userContext?.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalid);
    if (subAccounts && subAccounts.length === 1) {
      setToPortfolio(subAccounts[0].id);
    }
  };

  const handleAmountChange = (value: string): void => {
    setAmount(value);
  };

  const onScheduleChanged = (value: string): void => {
    setSchedule(value);
    if (value !== 'ONE_TIME' && starting === 'Now') {
      setStarting('Future Date');
    }
  };

  const determineNewBankSelectJourney = (): void => {
    sendAnalyticsEvent(ovAnalyticsEvents.investPageNewBankAccountSelect).then();
    if (userContext.path === '/') {
      sendAnalyticsEvent(ovAnalyticsEvents.homePageInvestSelectToInvestPageNewBankAccountSelect).then();
    } else if (userContext.path === '/goal') {
      sendAnalyticsEvent(ovAnalyticsEvents.goalPageInvestSelectToInvestPageNewBankAccountSelect).then();
    } else if (userContext.path === '/account') {
      sendAnalyticsEvent(ovAnalyticsEvents.accountPageInvestSelectToInvestPageNewBankAccountSelect).then();
    }
  };

  const removeAddbankAccountOption = !availableFeatureFlags?.includes(FeatureFlagTypes.AUTOMATED_BANK_ACCOUNT_LINKING)
    && !availableFeatureFlags?.includes(FeatureFlagTypes.MANUALLY_ADD_BANK_ACCOUNT);
  return (
    <>
      <OvForm buttonText={t('transfer:bankAccountDeposit.submitButton')} onSubmit={(e) => makeDeposit(e)} loading={loading} disableButton={disableButton}>
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('transfer:bankAccountDeposit.from')}</Typography>
        <DropdownInput
          dataTestId="from-dropdown"
          onChange={(e) => {
            setFrom(e.target.value);
          }}
          onItemClicked={() => {
            sendAnalyticsEvent(ovAnalyticsEvents.investPageBankAccountSelect).then();
          }}
          options={bankAccounts.map((bankAccount) => (
            { imageUrl: '', text: `${bankAccount.name} ${bankAccount.bankAccountNumber}`, value: bankAccount.id }
          ))}
          value={from}
          suffixLink={!removeAddbankAccountOption ? {
            text: t('transfer:flinksAddBankAccount'),
            onChange: () => {
              determineNewBankSelectJourney();
              setShowFlinks(true);
            },
          } : undefined}
        />
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:bankAccountDeposit.toGoal')}</Typography>
        <GoalDropDown
          dataTestId="toGoal-dropdown"
          selectedGoalId={selectedGoalId ?? ''}
          value={toGoal ?? ''}
          onGoalChanged={(e) => {
            onGoalChanged(e);
          }}
          setSubAccounts={(values) => {
            setGoalSubAccounts(values);
          }}
          showOnlySelectedGoalAsOption={showOnlySelectedGoalAsOption}
        />
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:bankAccountDeposit.toPortfolio')}</Typography>
        <PortfolioDropdown
          forDeposit
          onChange={(e) => {
            sendAnalyticsEvent(ovAnalyticsEvents.investPagePortfolioSelect).then();
            setToPortfolio(e.id);
          }}
          subAccounts={goalSubAccounts}
          value={toPortfolio ?? ''}
          dataTestId="toPortfolio-dropdown"
          accountTypesToHide={[
            AccountTypes.LIRA, AccountTypes.LRSP, AccountTypes.RLSP, AccountTypes.LIF,
            AccountTypes.RLIF, AccountTypes.LRIF, AccountTypes.PRIF, AccountTypes.RRIF, AccountTypes.RIF_SPOUSAL,
          ]}
          goalId={toGoal ?? ''}
          selectedSubAccountId={selectedSubAccountId}
          showOnlySelectedSubAccountAsOption={showOnlySelectedSubAccountAsOption}
        />
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:bankAccountDeposit.amount')}</Typography>
        <SimpleInput
          testId="amount"
          style={{ height: '50px', marginTop: '-12px' }}
          value={amount}
          onChange={(e) => {
            handleAmountChange(e.target.value);
          }}
          inputType={InputType.CURRENCY}
          inputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputprops: { value: amount, onBlur: () => sendAnalyticsEvent(ovAnalyticsEvents.investPageAmountSelect).then() },
            onBlur: () => sendAnalyticsEvent(ovAnalyticsEvents.investPageAmountSelect).then(),
          }}
          onClear={() => setAmount('')}
          label=""
        />
        {toPortfolio && !hasContributedEnough && minInvestAmount > (Number(amount) || 0) && <Typography variant="captionSectionerMedium" data-testid="error-min-initial-deposit" style={{ marginBottom: 0 }}>{t('transfer:errorMinInvestment', { minAmount: minInvestAmount.toFixed(2) })}</Typography>}
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:bankAccountDeposit.schedule')}</Typography>
        <DropdownInput
          onChange={(e) => onScheduleChanged(e.target.value)}
          options={[
            { imageUrl: '', text: t('transfer:bankAccountDeposit.scheduleOptions.justThisOnce'), value: 'ONE_TIME' },
            { imageUrl: '', text: t('transfer:bankAccountDeposit.scheduleOptions.weekly'), value: 'WEEKLY' },
            { imageUrl: '', text: t('transfer:bankAccountDeposit.scheduleOptions.biWeekly'), value: 'BI_WEEKLY' },
            { imageUrl: '', text: t('transfer:bankAccountDeposit.scheduleOptions.monthly'), value: 'MONTHLY' },
          ]}
          value={schedule}
        />
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:bankAccountDeposit.starting')}</Typography>
        <DropdownInput
          onItemClicked={(value) => setDate(value)}
          options={schedule === 'ONE_TIME' ? [
            { imageUrl: '', text: t('transfer:bankAccountDeposit.startingOptions.now'), value: 'Now' },
            { imageUrl: '', text: `${t('transfer:bankAccountDeposit.startingOptions.futureDate')} ${formatDateForUI()}`, value: 'Future Date' },
          ] : [{ imageUrl: '', text: `${t('transfer:bankAccountDeposit.startingOptions.futureDate')} ${formatDateForUI()}`, value: 'Future Date' }]}
          value={starting}
        />
        <Box style={{ marginTop: 15, marginBottom: 36 }}>
          <Disclaimer text={t('transfer:bankAccountDeposit.disclaimer')} />
        </Box>
        {starting === 'Future Date' && userContext.availableFeatureFlags?.includes('PAD_AGREEMENTS')
          && (<PADLink />)}
        <Modal
          open={openCalendar}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClose={() => {
            setOpenCalendar(false);
          }}
        >
          <Box display="flex" flexDirection="column" position="relative">
            <LocalizationProvider locale={getLocale()} dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                label="yyyy-mm-dd"
                open
                displayStaticWrapperAs="mobile"
                value={Date.parse(dayjs(scheduledDate).local().format('YYYY-MM-DD'))}
                minDate={nextDay}
                onChange={(newValue) => {
                  sendAnalyticsEvent(ovAnalyticsEvents.investPageStartDateSelect).then();
                  setTempDate(dayjs(newValue).utc().format('YYYY-MM-DD'));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ marginBottom: '32px' }}
                    variant="standard"
                    {...params}
                    fullWidth
                  />
                )}
                mask="____-__-__"
              />
            </LocalizationProvider>
            <Box position="absolute" right="35px" bottom="35px" display="flex">
              <Typography variant="captionSectionerMedium" onClick={() => setOpenCalendar(false)} style={{ marginBottom: 0, marginRight: '15px', cursor: 'pointer' }}>{t('transfer:bankAccountDeposit.calendar.cancel')}</Typography>
              <Typography variant="captionSectionerMedium" onClick={() => confirmDate()} style={{ marginBottom: 0, cursor: 'pointer' }}>{t('transfer:bankAccountDeposit.calendar.confirm')}</Typography>
            </Box>
          </Box>
        </Modal>
      </OvForm>
      <Flinks
        onClose={() => setShowFlinks(false)}
        open={showFlinks}
        bankAccounts={bankAccounts}
        retrievedBank={(bank) => {
          setBankAccounts((prevState) => [...prevState, bank]);
          setFrom(bank.id);
        }}
      />
    </>
  );
};

BankAccountDeposit.defaultProps = {
  selectedGoalId: '',
  selectedSubAccountId: '',
  onSuccessTransfer: undefined,
  hideToastMessage: false,
  analyticsEvent: undefined,
  showOnlySelectedGoalAsOption: false,
  showOnlySelectedSubAccountAsOption: false,
};

export default BankAccountDeposit;
