import { colors } from '../../theme/colors';

export const styles = {
  cageWrapper: {
    width: '100%',
    display: 'inline-grid',
    justifyContent: 'center',
    textAlign: 'center',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '13.27px',
  },
  deepLink: {
    marginTop: '13px',
    width: '155px',
    height: '36px',
  },
  firstInvestmentText: {
    color: colors.primary,
  },
  pendingText: {
    color: colors.neutral30,
    marginTop: '11px',
  },
  pendingIcon: {
    color: colors.neutral30,
    marginRight: '4.33px',
    fontSize: '14px',
    top: '2px',
    position: 'relative',
  },
  listStyles: {
    borderBottom: 1,
    borderColor: 'divider',
    display: 'flex',
    justifyContent: 'center',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '75%',
    bgcolor: colors.ovWhite,
    borderRadius: '5px',
    maxHeight: '80%',
    overflowY: 'auto',
    ':focus-visible': {
      outline: 'none !important',
    },
  },
  addItem: {
    position: 'relative',
    background: colors.baseComponentBackground,
    border: '1px dashed #D3DEEA',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 10px rgb(234 236 239 / 25%)',
    height: '70px',
    width: '100%',
    left: '0px',
    top: '0px',
    borderRadius: '6px',
    color: colors.secondary,
  },
  addIcon: {
    position: 'relative',
    top: '5px',
    marginRight: '5px',
    color: '#2B4763',
  },
  iconBar: {
    justifyContent: 'center',
    display: 'flex',
    height: '30px',
    marginBottom: '16px',
  },
  investButton: {
    height: '38px',
    width: '149px',
    position: 'relative',
    cursor: 'pointer',
  },
  withdrawButton: {
    height: '38px',
    width: '149px',
    position: 'relative',
    cursor: 'pointer',
    margin: '0px 0px 0px 16px !important',
  },
};
