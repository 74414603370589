import {
  isIE,
  isChrome,
  isSafari,
  isOpera,
  isFirefox,
  isAndroid,
  isIOS,
  isWindows,
  isMacOs,
  isMobile,
  isTablet,
  isDesktop,
} from 'react-device-detect';
import axios from 'axios';
import { ipAddressVar } from './localVariables';

const getBrowser = (): string => {
  let browser = 'Others';
  if (isIE) {
    browser = 'Internet Explorer';
  } else if (isChrome) {
    browser = 'Google Chrome';
  } else if (isSafari) {
    browser = 'Safari';
  } else if (isOpera) {
    browser = 'Opera';
  } else if (isFirefox) {
    browser = 'Firefox';
  } else {
    browser = 'Others';
  }
  return browser;
};

const getOS = (): string => {
  let os = 'Others';
  if (isAndroid) {
    os = 'Android';
  } else if (isIOS) {
    os = 'ios';
  } else if (isWindows) {
    os = 'Windows';
  } else if (isMacOs) {
    os = 'Mac';
  }
  return os;
};

const getDevice = (): string => {
  let device = 'Others';
  if (isMobile) {
    device = 'Phone';
  } else if (isTablet) {
    device = 'Tablet';
  } else if (isDesktop) {
    device = 'Computer';
  }
  return device;
};

const getClientIpAddress = async (): Promise<string> => {
  if (ipAddressVar()) {
    return ipAddressVar();
  }
  const response = await axios.get('https://api.ipify.org/?format=json');
  ipAddressVar(response.data.ip);
  return response.data.ip;
};

const waitForFontLoad = async (font: string, timeout = 1000, interval = 10) : Promise<boolean | void> => new Promise((resolve, reject) => {
  const poller = setInterval(async () => {
    try {
      await document.fonts.load(font);
    } catch (err) {
      reject(err);
    }
    if (document.fonts.check(font)) {
      clearInterval(poller);
      resolve(true);
    }
  }, interval);
  setTimeout(() => clearInterval(poller), timeout);
});

const getOrganizationIdFromURL = (): string | null => {
  if (typeof window !== 'undefined') {
    return (new URLSearchParams(window.location.search)).get('organizationId');
  }
  return null;
};

const getOrganizationIdFromWebServer = (): string | null => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    return (window as any).__partnerUserContext?.organizationId || (window as any).__organizationContext?.organizationId;
  }
  return null;
};

export const getOrganizationIdFromContext = (): string | null => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    const organizationIdVariable = (window as any).__organizationContext?.organizationIdVariable;
    if (!organizationIdVariable) return null;
    const organizationId = process.env[organizationIdVariable] || null;
    return organizationId;
  }
  return null;
};

const getThemeFromURL = (): string | null => {
  if (typeof window !== 'undefined') {
    return (new URLSearchParams(window.location.search)).get('theme');
  }
  return null;
};

const getThemeFromWebServer = (): string | null => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    return (window as any).__partnerUserContext?.organizationKey;
  }
  return null;
};

const getThemeFromContext = (): string | null => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    return (window as any).__organizationContext?.organizationKey;
  }
  return null;
};

const getTheme = (): string | null => {
  let theme = getThemeFromURL();
  if (!theme) {
    theme = getThemeFromWebServer();
  }
  if (!theme) {
    theme = getThemeFromContext();
  }
  return theme;
};

const getOrganizationId = (): string | null => {
  let organizationId = getOrganizationIdFromURL();
  if (!organizationId) {
    organizationId = getOrganizationIdFromWebServer();
  }
  if (!organizationId) {
    organizationId = getOrganizationIdFromContext();
  }
  return organizationId;
};

const getOrganizationKeyFromURL = (): string | null => {
  if (typeof window !== 'undefined') {
    return (new URLSearchParams(window.location.search)).get('organizationKey');
  }
  return null;
};

export const getOrganizationKeyFromContext = (): string | null => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    const organizationKey = (window as any).__organizationContext?.organizationKey || null;
    return organizationKey;
  }
  return null;
};

const getOrganizationKey = (): string | null => {
  let organizationKey = getOrganizationKeyFromURL();
  if (!organizationKey) {
    organizationKey = getOrganizationKeyFromContext();
  }
  return organizationKey;
};

const getRedirectUri = (): string => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
    const contextRedirectUri = (window as any).__organizationContext?.redirectUri || null;
    if (contextRedirectUri) {
      return contextRedirectUri;
    }
  }
  let redirectUri = process.env.REACT_APP_BASE_PATH || '';
  const organizationKey = getOrganizationKeyFromURL();
  if (organizationKey) {
    redirectUri = `${redirectUri}?organizationKey=${organizationKey}`;
  } else {
    const organizationId = getOrganizationIdFromURL();
    if (organizationId) {
      redirectUri = `${redirectUri}?organizationId=${organizationId}`;
    } else {
      redirectUri = window.location.origin;
    }
  }
  return redirectUri;
};

const parseJwt = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  } catch {
    return {};
  }
};

const getWindowInnerHeight = (): number => {
  if (typeof window !== 'undefined') {
    return window.innerHeight;
  }
  return 0;
};

export {
  getBrowser,
  getOS,
  getDevice,
  getClientIpAddress,
  waitForFontLoad,
  getTheme,
  getOrganizationIdFromURL,
  getWindowInnerHeight,
  getOrganizationId,
  getOrganizationKey,
  getRedirectUri,
  parseJwt,
};
