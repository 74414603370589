import { getTheme } from '../utils/platformUtils';

const theme = getTheme();
const colorTheme: { [key: string]: { [key: string]: string| Record<string, string> } } = {
  rise: {
    primary: '#4D54DA',
    primary20: '#444AC2',
    primary80: '#555DF2',
    secondary: '#09247C',
    secondary20: '#071D63',
    secondary80: '#9DA7CB',
    success: '#49D186',
    success20: '#42BD79',
    success80: '#A4E8C3',
    warning: '#FFCC33',
    warning20: '#E6B82E',
    warning80: '#FFE699',
    error: '#E00000',
    error20: '#A00000',
    error80: '#F08080',
    neutralBlack: '#283038',
    neutral90: '#F2F5FB',
    neutral80: '#DADDE2',
    neutral70: '#AAADB0',
    neutral30: '#6A7075',
    neutral20: '#40474D',
    neutral10: '#2B333A',
    disabledBackground: '#E1E6EF',
    disabledText: '#AAADB0',
    loading: '#555DF2',
    loadingBackground: '#a1a4e0',
    buttonItemBackground: '#E2E6FA',
    buttonItemHoverBorder: '#555DF2',
    radio: '#6A7075',
    link: '#555DF2',
    marketValueChartColor1: '#09247CB2',
    marketValueChartColor2: '#09247C00',
    marketValueChartOutlineColor: '#09247C',
  },
  vanguard: {
    primary: '#2371a9',
    primary20: '#7ac4f9',
    primary80: '#0d4a76',
    secondary: '#3b8764',
    secondary20: '#62d9a2',
    secondary80: '#0a6e40',
    success: '#3b8764',
    success20: '#62d9a2',
    success80: '#0a6e40',
    loading: '#2371a9',
    loadingBackground: '#7ac4f9',
    buttonItemBackground: '#dff2e9',
    buttonItemHoverBorder: '#3b8764',
    radio: '#2371a9',
    marketValueChartColor1: '#629B81B2',
    marketValueChartColor2: '#629B8100',
    marketValueChartOutlineColor: '#3B8764',
  },
  nuula: {
    primary: '#3446DA',
    loading: '#3446DA',
    radio: '#3446DA',
    error: '#FB002E',
    error20: '#FB002E',
    error80: '#FB002E',
    secondary: '#636363',
    loadingBackground: '#F5F6FA',
    buttonItemBackground: '#E2E6FA',
    buttonItemHoverBorder: '#555DF2',
    link: '#882BFF',
    progressBarColor: '#882BFF',
    infoIconColor: '#882BFF',
    marketValueChartColor1: '#3446DAB2',
    marketValueChartColor2: '#3446DA00',
    marketValueChartOutlineColor: '#636363',
  },
  manzil: {
    primary: '#619274',
    body: '#EAEDF4',
    baseText: '#3D405B',
    progressBarColor: '#81B29A',
    success: '#81B29A',
    success20: '#81B29A',
    success30: '#81B29A',
    success70: '#81B29A',
    success80: '#81B29A',
    error: '#E07A5F',
    error20: '#E07A5F',
    error30: '#E07A5F',
    error70: '#E07A5F',
    error80: '#E07A5F',
    goalsTabPanelBackground: '#EAEDF4',
    accountsTabPanelBackground: '#EAEDF4',
    holdingsTabPanelBackground: '#EAEDF4',
    applicationCard: '#F2F3F9',
    trackingBarBackground: 'transparent',
    chartDark: 'transparent !important',
    chartLight: 'transparent !important',
    ovFlowModalBackground: '#F2F3F9',
    depositPageMinWidth600Background: '#F2F3F9',
    depositPageMaxWidth600Background: '#F2F3F9',
    latestTransfersBackground: '#F2F3F9',
    holdingListBackground: '#F2F3F9',
    baseComponentBackground: '#F2F3F9',
    addPortfolioButtonBackground: '#F2F3F9',
    addPortfolioButtonColor: '#164280',
    pinInputBackgroundColor: '#FFFFFF',
    toggleSwitchBGColor: '#81B29A',
    goalProgressBar: '#619274',
  },
  desjardins: {
    primary: '#00874e',
    loading: '#00874e',
    radio: '#00874e',
    error: '#E05256',
    error20: '#E05256',
    error80: '#E05256',
    secondary: '#00874e',
    loadingBackground: '#8EC9A9',
    link: '#00874e',
    progressBarColor: '#414A55',
    infoIconColor: '#00874e',
    body: '#f2f2f2',
    neutral30: '#4F5963',
    trackingText: '#4F5963',
    trackingBarBackground: 'transparent',
    chartDark: 'transparent !important',
    chartLight: 'transparent !important',
    addPortfolioButtonColor: '#00874e',
    buttonItemBackground: '#E6E7E8',
    buttonItemHoverBorder: '#E6E7E8',
    marketValueChartOutlineColor: '#00874E',
  },
  atb: {
    primary: '#005eb7',
    loading: '#005eb7',
    radio: '#005eb7',
    error: '#e34948',
    error20: '#e34948',
    error80: '#e34948',
    secondary: '#005eb7',
    loadingBackground: '#63666A',
    link: '#005eb7',
    progressBarColor: '#005eb7',
    infoIconColor: '#005eb7',
    body: '#ffffff',
    neutral30: '#636769',
    trackingText: '#636769',
    trackingBarBackground: 'transparent',
    chartDark: 'transparent !important',
    chartLight: 'transparent !important',
    addPortfolioButtonColor: '#005eb7',
    buttonItemBackground: '#f5f5f5',
    buttonItemHoverBorder: '#f5f5f5',
    marketValueChartOutlineColor: '#005EB7',
  },
  pmam: {
    primary: '#29ADE4',
    radio: '#29ADE4',
    error: '#B33727',
    error20: '#B33727',
    error30: '#B33727',
    error70: '#B33727',
    error80: '#B33727',
    progressBarColor: '#07526D',
    loading: '#29ADE4',
    loadingBackground: '#928C82',
    buttonItemBackground: '#FFFFFF',
    exitButtonIconColour: '#29ADE4',
    link: '#29ADE4',
    toggleSwitchBGColor: '#29ADE4',
    infoIconColor: '#29ADE4',
    buttonItemHoverBorder: '#B9B9B8',
    autoInvestmentSummaryCard: '#29ADE4',
    addPortfolioButtonColor: '#B9B9B8',
    marketValueChartColor1: '#157BA5B2',
    marketValueChartColor2: '#157BA500',
    marketValueChartOutlineColor: '#157BA5',
    holdingsGraph: {
      1: '#29ADE4',
      2: '#928C82',
      3: '#07526D',
      4: '#F4B21E',
      5: '#072B3D',
      6: '#7EE6FC',
      7: '#157BA5',
      8: '#FFE363',
      9: '#C59618',
      10: '#997611',
      11: '#FD9883',
      12: '#E95442',
      13: '#B33727',
      14: '#7F1D0F',
      15: '#B9B9B8',
      16: '#6B6459',
      17: '#423E3B',
    },
  },
  peoplestrust: {
    primary: '#7BA0C4',
    error: '#C34B4C',
    loadingBackground: '#928c82',
    progressBarColor: '#605987',
    body: '#ffffff',
    trackingBarText: '#CAC6C6',
    buttonItemBackground: '#ffffff',
  },
  simplii: {
    primary: '#df0088',
    radio: '#df0088',
    loading: '#df0088',
    exitButtonIconColour: '#df0088',
    link: '#df0088',
    toggleSwitchBGColor: '#df0088',
    infoIconColor: '#df0088',
    autoInvestmentSummaryCard: '#df0088',
    progressBarColor: '#df0088',
    addPortfolioButtonColor: '#df0088',
    marketValueChartOutlineColor: '#df0088',
    buttonItemHoverBorder: '#df0088',
    body: '#ffffff',
    baseText: '#121418',
    buttonItemBackground: '#ffffff',
    holdingsGraph: {
      1: '#b8f00a',
      2: '#becf1c',
      3: '#cab033',
      4: '#d39241',
      5: '#de7255',
      6: '#e74e6b',
      7: '#f0327c',
      8: '#fc0f90',
    },
  },
};

export const ovColors = {
  ovBlack: '#1A2B3B',
  ovWhite: '#FFFFFF',
  primary: '#2CA58D',
  primary20: '#1A6355',
  primary30: '#238471',
  primary70: '#56B7A4',
  primary80: '#D5EDE8',
  secondary: '#2B4763',
  secondary20: '#1A2B3B',
  secondary30: '#22394F',
  secondary70: '#8091A1',
  secondary80: '#D5DAE0',
  secondaryGradient: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), rgba(44, 165, 141, 0.9)',
  success: '#2CA58D',
  success20: '#1A6355',
  success30: '#238471',
  success70: '#56B7A4',
  success80: '#D5EDE8',
  warning: '#F8D87B',
  warning20: '#D8A100',
  warning30: '#F8C530',
  warning70: '#FFE69C',
  warning80: '#FFEFBF',
  accentOne: '#EBA183',
  accentOne20: '#8D614F',
  accentOne30: '#BC8169',
  accentOne70: '#EFB49C',
  accentOne80: '#F7D9CD',
  accentTwoDefault: '#AF586A',
  accentTwo20: '#693540',
  accentTwo30: '#8C4655',
  accentTwo70: '#BF7988',
  accentTwo80: '#EFDEE1',
  error: '#E65C64',
  error20: '#8A373C',
  error30: '#B84A50',
  error70: '#EB7D83',
  error80: '#FADEE0',
  purple: '#624F6D',
  purple20: '#3B2F41',
  purple30: '#4E3F57',
  purple70: '#81728A',
  purple80: '#C0B9C5',
  neutralWhite: '#FFFFFF',
  neutralBlack: '#1A2B3B',
  black: '#000000',
  blue: '#2C3FCF',
  gray: '#9AA0B3',
  gray80: '#80808080',
  neutral10: '#F0F4F8',
  neutral20: '#667380',
  neutral30: '#AAB4BF',
  neutral70: '#D3DEEA',
  neutral80: '#F0F4F8',
  neutral90: '#F6F8F7',
  transparent: '#FFFFFF00',
  grayBackground: '#FAFCFF',
  buttonItemBorder: '#DAE4ED66',
  buttonItemBackground: '#F8F1EF',
  buttonItemHoverBorder: '#EBA183',
  chartDark: '#1A2027',
  chartLight: '#FFFFFF',
  agreementText: '#566B80',
  portfolioCardThemeText: '#6F869D',
  sinCard: '#EAF6F3',
  checkboxColor: '#C3D3E233',
  dividerColor: '#DAE4ED4D',
  pointColor: 'rgba(43, 69, 99, 0.6)',
  backButtonBackground: 'rgba(240, 244, 248, 0.5)',
  portfolioCardBorder: '#0000000D',
  progressTextColor: '#9BA6B0',
  progressBarColor: '#566B80',
  infoIconColor: '#2CA58D',
  autoInvestmentSummaryCard: '#E3F1EF',
  autoInvestmentSummaryHeader: '#2D4863',
  autoInvestmentSummaryBody: '#2D4863CC',
  targetAmountMessage: 'rgba(86, 107, 128, 0.5)',
  carousellButtons: 'rgba(255, 255, 255, 0)',
  greyBackground: '#FAFDFF',
  helpSubTitle: '#111C28',
  disabledBackground: '#D3DEEA',
  disabledText: '#AAB4BF',
  loading: '#2CA58D',
  loadingBackground: '#D5EDE8',
  projectionBackgroundLight: '#D5EDE8',
  projectionBackgroundDark: '#96D2C6',
  radio: '#2CA58D',
  link: '#2CA58D',
  underlineColor: '#00FF00',
  infoBackground: '#FAFAFA',
  inputBorder: '#F6F7F9',
  projectionBoundaries: '#5BCDB7',
  transparentShadow: 'rgba(0, 0, 0, 0.07)',
  body: '#FBFDFF',
  baseText: '#1A2B3B',
  menuBar: '#FFFFFF',
  goalsTabPanelBackground: '#FAFCFF',
  accountsTabPanelBackground: '#FAFCFF',
  holdingsTabPanelBackground: '#FAFCFF',
  applicationCard: '#FFF',
  trackingText: '#AAB4BF',
  trackingBarBackground: '#FFF',
  ovFlowModalBackground: '#FFF',
  depositPageMinWidth600Background: '#FAFDFF',
  depositPageMaxWidth600Background: '#FFFFFF',
  latestTransfersBackground: 'transparent',
  holdingListBackground: '#FAFCFF',
  baseComponentBackground: '#FFF',
  addPortfolioButtonBackground: '#FDFEFE',
  emptyPieChartColor: '#D5DAE0',
  addPortfolioButtonColor: '#2CA58D',
  accountDashedDividerColor: '#DDD9D9',
  pinInputBackgroundColor: '#F0F4F8',
  exitButtonIconColour: '#2CA58D',
  toggleSwitchBGColor: '#2CA58D',
  secondaryAssetClassBackPanel: '#F9FAFC',
  holdingsGraph: {
    1: '#1A6355',
    2: '#238471',
    3: '#2CA58D',
    4: '#56B7A4',
    5: '#8091A1',
    6: '#2B4763',
    7: '#22394F',
    8: '#1A2B3B',
    9: '#1A2B3B',
    10: '#667380',
    11: '#AAB4BF',
    12: '#D3DEEA',
    13: '#F0F4F8',
    14: '#EBA183',
    15: '#8D614F',
    16: '#BC8169',
    17: '#EFB49C',
    18: '#E65C64',
    19: '#8A373C',
    20: '#B84A50',
    21: '#EB7D83',
  },
  marketValueChartColor1: '#2B4563B2',
  marketValueChartColor2: '#2B456300',
  marketValueChartOutlineColor: '#2B4763',
  scrollBarThumbBackgroundColor: '#00000057',
  goalProgressBar: '#F0F4F8',
};

export const colors = {
  ...ovColors,
  ...(colorTheme[theme || ''] || {}),
};
