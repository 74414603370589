import { FormEvent, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import OvForm from '../common/wrappers/ovForm';
import SimpleInput from '../common/inputs/simpleInput';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import PrivacyPolicyLink from './privacyPolicyLink';
import { UPDATE_USER } from '../user/graphql';

export interface UserState {
  primaryEmail?: string,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  displayPrivacyPolicy?: boolean,
  texts?: { title?: string, subTitle?: string },
  shouldSaveUpdate?: boolean,
  analyticsEvent?: string,
}

const Email = ({
  user,
  updateUserState,
  shouldSaveUpdate = false,
  analyticsEvent,
  ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'signUp']);
  const [valid, setValid] = useState(true);
  const checkFieldValidity = async (): Promise<boolean> => {
    const isValid = await yup.string().email().isValid(user.primaryEmail);
    setValid(isValid);
    return isValid;
  };
  const onEmailUpdated = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'EMAIL_UPDATED' });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    if (props.onContinue) props.onContinue();
  };

  const [updateEmail] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        primaryEmail: user.primaryEmail,
      },
    },
    onCompleted: onEmailUpdated,
  });

  const onContinueClick = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.onBoardingEnterEmailContinue).then();
    if (!await checkFieldValidity()) return;
    if (shouldSaveUpdate) {
      updateEmail().then();
    } else if (props.onContinue) props.onContinue();
  };

  const onClear = (): void => {
    updateUserState({ ...user, primaryEmail: '' });
    setValid(true);
  };

  return (
    <OvForm onSubmit={onContinueClick} loading={false}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: '1 0 auto' }}>
          <Typography variant="heading2" style={{ marginBottom: 16 }}>{props.texts?.title ?? t('signUp:email.title')}</Typography>
          <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{props.texts?.subTitle ?? t('signUp:email.subTitle')}</Typography>
          <SimpleInput
            label={t('signUp:email.label')}
            value={user.primaryEmail || ''}
            subtitle={valid ? '' : t('signUp:email.invalidMsg')}
            error={!valid}
            inputProps={{ type: 'email', autoComplete: 'off' }}
            required
            style={{ marginBottom: 16 }}
            onChange={(event) => updateUserState({ ...user, primaryEmail: event.target.value })}
            testId="email-input"
            onClear={() => onClear()}
          />
        </div>
        {props.displayPrivacyPolicy ? <PrivacyPolicyLink /> : null}
      </div>
    </OvForm>
  );
};

Email.defaultProps = {
  onContinue: undefined,
  displayPrivacyPolicy: false,
  texts: undefined,
  shouldSaveUpdate: false,
  analyticsEvent: undefined,
};

export default Email;
