import { Typography, List } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../graphql';
import GeneralListItem from '../../common/lists/generalListItem';
import OvForm from '../../common/wrappers/ovForm';
import SimpleInput from '../../common/inputs/simpleInput';
import { pmamSourceOfWealth } from '../resources';

export interface UserState {
  sourceOfWealth?: string[],
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  cached?: boolean,
  title?: string,
  subTitle?: string,
  analyticsEvent?: string,
}

const SourceOfWealth = ({
  user, updateUserState, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const [clickedValues, setClickedValues] = useState<string[]>([]);
  const [sourceOfWealthOtherDescription, setSourceOfWealthOtherDescription] = useState<string>('');
  const handleOptionClick = (key: string): void => {
    if (clickedValues.includes(key)) {
      setClickedValues(clickedValues.filter((item) => item !== key));
    } else {
      setClickedValues((prevState) => ([...prevState, key]));
    }
  };
  const updateSourceOfWealthOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };
  const [updateSourceOfWealth] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        sourceOfWealth: user.sourceOfWealth,
        sourceOfWealthOtherDescription: clickedValues.includes('OTHER') ? sourceOfWealthOtherDescription : undefined,
      },
    },
    onCompleted: updateSourceOfWealthOnCompleted,
    onError: () => setLoading(false),
  });
  const onContinueClick = (): void => {
    setLoading(true);
    updateSourceOfWealth().then();
  };
  return (
    <OvForm loading={loading} onSubmit={onContinueClick} disableButton={user.sourceOfWealth?.length === 0} submitButton={false}>
      <Typography variant="heading2">{t('user:howToInvest.sourceOfWealth.title')}</Typography>
      <Typography variant="paragraph3">{t('user:howToInvest.sourceOfWealth.subTitle')}</Typography>
      <List className="on-boarding-list">
        {pmamSourceOfWealth.sort((a, b) => {
          if (a.key === 'OTHER') return 1;

          return a.translatedName.localeCompare(b.translatedName);
        }).map((value: {key: string, translatedName: string}) => (
          <GeneralListItem
            text={value.translatedName}
            key={value.key}
            hasSquareCheckbox
            checked={clickedValues.includes(value.key)}
            onClick={() => {
              if (value) {
                handleOptionClick(value.key);
                updateUserState({ ...user, sourceOfWealth: [...clickedValues, value.key] });
              }
            }}
          />
        ))}
        {
          clickedValues.includes('OTHER') ? (
            <SimpleInput
              testId="other-option-description-input"
              label=""
              value={sourceOfWealthOtherDescription}
              style={{ marginBottom: 60 }}
              subtitle=""
              required
              onChange={(event) => setSourceOfWealthOtherDescription(event.target.value)}
              data-testid="other-option-description-input"
              onClear={() => setSourceOfWealthOtherDescription('')}
              inputProps={{
                autoFocus: clickedValues.includes('OTHER'),
              }}
            />
          ) : undefined
        }
      </List>
    </OvForm>
  );
};

SourceOfWealth.defaultProps = {
  onContinue: undefined,
  cached: false,
  title: undefined,
  subTitle: undefined,
  analyticsEvent: undefined,
};

export default SourceOfWealth;
