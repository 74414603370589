import StepWizard from 'react-step-wizard';
import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import WithStepWizard from '../common/wrappers/withStepWizard';
import ImportantInformation from './importantInformation';
import { decideNextStep, RetirementAccountType } from '../checker';
import Deposit from '../transfers/deposit';
import AnnualPaymentInstructions from './annualPaymentInstructions';
import Confirmation from './confirmation';
import FinishRRIFAccountSetup from './finishRrifAccountSetup';
import { ovAnalyticsEvents } from '../../utils/firebase';
import { navigate } from '../../utils/router';
import { UserContext } from '../../providers/userContextProvider';
import { getFromLocalStorage } from '../../utils/localStorage';
import { FETCH_TRANSFERS } from '../transfers/graphql';
import { hideExistingPortfolioTransfer } from '../../utils/accountUtil';

const FinishRRIFAccountSetupStep = WithStepWizard(FinishRRIFAccountSetup);
const ImportantInformationStep = WithStepWizard(ImportantInformation);
const DepositStep = WithStepWizard(Deposit);
const AnnualPaymentInstructionsStep = WithStepWizard(AnnualPaymentInstructions);
const ConfirmationStep = WithStepWizard(Confirmation);

interface Props {
  journey: number[],
  updateJourney: (data: number[]) => void,
  goToNamedStep?: (step: string) => void,
  uncompletedRrifAccounts: RetirementAccountType[];
}

const RRIFOnboardingWizard = ({
  uncompletedRrifAccounts, ...props
}: Props): JSX.Element => {
  const client = useApolloClient();
  const { userContext, setUserContext } = useContext(UserContext);

  const getSubAccountToSendTo = (account: RetirementAccountType): string|undefined => {
    if (account.subAccounts.length > 0) return account.subAccounts[0].id;
    return undefined;
  };

  const getGoalToSendTo = (account: RetirementAccountType): string|undefined => {
    if (account.subAccounts.length > 0) return account.subAccounts[0].goal.id;
    return undefined;
  };

  const proceedToNext = (): void => decideNextStep(3, props.journey, props.updateJourney, props.goToNamedStep);
  const fromOnboarding = getFromLocalStorage('from-onboarding') === 'true';
  const getPremise = (account: RetirementAccountType): 'onboarding'|'no-payment-instruction'|'no-transaction' => {
    let rrifPremise: 'onboarding' | 'no-payment-instruction' | 'no-transaction' = 'onboarding';
    const { netContributionCents, pendingContributionCents } = account.statistics;
    const hasNoTransfer: boolean = (netContributionCents === 0 && pendingContributionCents === 0);
    const hasNoScheduledIncomeTransfer = !account.scheduledIncomeFundTransfer;
    if (fromOnboarding && hasNoTransfer) {
      rrifPremise = 'onboarding';
    } else if (hasNoTransfer) {
      rrifPremise = 'no-transaction';
    } else if (hasNoScheduledIncomeTransfer) {
      rrifPremise = 'no-payment-instruction';
    }
    return rrifPremise;
  };

  const showDepositStep = (account: RetirementAccountType): boolean => {
    const { netContributionCents, pendingContributionCents } = account.statistics;
    return (netContributionCents === 0 && pendingContributionCents === 0);
  };

  const showPaymentInstruction = (account: RetirementAccountType): boolean => !account.scheduledIncomeFundTransfer;
  const hasMultipleIncompleteAccounts = (): boolean => uncompletedRrifAccounts.length > 1;
  const isLastIncompleteAccount = (index: number): boolean => (uncompletedRrifAccounts.length - 1) === index;
  const shouldOpenSpecificStep = userContext.checkRrifInformationStep?.open;

  const steps: JSX.Element[] = uncompletedRrifAccounts.reduce((acc, account, index) => [
    ...acc,
    ...(!shouldOpenSpecificStep && !fromOnboarding && uncompletedRrifAccounts.length === 1 ? [<FinishRRIFAccountSetupStep stepName="rrif-finish-setup" key="rrif-finish-setup" uncompletedRrifAccount={account} premise={getPremise(account)} continueToNamedStep={() => (getPremise(account) === 'no-transaction' ? 'rrif-transaction' : 'payment-instruction')} />] : []),
    ...((!shouldOpenSpecificStep && fromOnboarding && uncompletedRrifAccounts.length === 1) || uncompletedRrifAccounts.length > 1 ? [<ImportantInformationStep isMultipleRrifFlow={hasMultipleIncompleteAccounts()} uncompletedRrifAccount={account} stepName="rrif-important-information" key="rrif-important-information" />] : []),
    ...(showDepositStep(account) ? [
      <DepositStep
        stepName="rrif-transaction"
        key="rrif-transaction"
        hideToastMessage
        hideBankAccountDeposit
        hideExistingPortfolioDeposit={hideExistingPortfolioTransfer(userContext.subAccounts ?? [])}
        goBackToNamedStep={() => (getPremise(account) === 'no-transaction' ? 'rrif-finish-setup' : 'rrif-important-information')}
        analyticsEvents={{
          existingPortfolioDeposit: ovAnalyticsEvents.rrifMakeAnInvestmentPageContinueButtonSelect,
          externalAccountDeposit: ovAnalyticsEvents.rrifMakeAnInvestmentPageContinueButtonSelect,
        }}
        selectedGoalId={getGoalToSendTo(account)}
        selectedSubAccountId={getSubAccountToSendTo(account)}
        preSelectedAccountType={account.type}
        initialStep={3}
        showOnlySelectedSubAccountAsOption
        showOnlySelectedGoalAsOption
      />,
    ] : []),
    ...(showPaymentInstruction(account) ? [
      <AnnualPaymentInstructionsStep account={account} width="100%" key="payment-instruction" stepName="payment-instruction" goBackToNamedStep={() => (getPremise(account) === 'no-payment-instruction' ? 'rrif-finish-setup' : 'rrif-transaction')} />,
    ] : []),
    <ConfirmationStep
      key="confirmation"
      stepName="confirmation"
      action={(callback: () => void) => {
        if (hasMultipleIncompleteAccounts()) {
          if (isLastIncompleteAccount(index)) {
            proceedToNext();
          } else {
            callback();
          }
        } else {
          proceedToNext();
          // if the rrif checker is opened manually, the ID and the state open: boolean is stored in the user context checkRrifInformationStep property
          // first we check if there is one opened manually, if not we check if there is an incomplete RRIF account the system flagged
          const open = userContext.checkRrifInformationStep?.open;
          const accountId = userContext.checkRrifInformationStep?.accountId;
          const foundId = open ? accountId : account.id;
          if (foundId) {
            setUserContext({
              ...userContext,
              checkRrifInformationStep: {
                accountId: undefined,
                open: false,
              },
            });
            navigate(setUserContext, '/account', {
              accountType: account.type,
              accountId: foundId,
            });
            client.refetchQueries({ include: [FETCH_TRANSFERS] }).then();
          }
        }
      }}
    />,
  ], [] as JSX.Element[]);
  return (
    <StepWizard
      isLazyMount
      transitions={{}}
      className="ov-step-wizard"
    >
      {steps}
    </StepWizard>
  );
};

RRIFOnboardingWizard.defaultProps = {
  goToNamedStep: undefined,
};

export default RRIFOnboardingWizard;
