/* eslint-disable no-lonely-if */

import { useMutation } from '@apollo/client';
import {
  Typography,
  List,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonListItem from '../../common/lists/buttonListItem';
import { UPDATE_USER } from '../graphql';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  totalDebtCents?: number,
  hasDebt?: boolean,
  annualDebtPaymentsCents?: number,
  incompleteFields?: string[],
}
interface DebtOption {
  option: string,
  value: string
}
interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
}

const Debt = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const updateNoDebtOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'NO_DEBT_UPDATED', totalDebtCent: 0, annualDebtPaymentsCents: 0 });
    updateUserState({
      ...user,
      incompleteFields: data.updateUser.user.incompleteFields,
    });
    if (props.onContinue) props.onContinue();
  };

  const [updateNoDebtStatus] = useMutation(UPDATE_USER, {
    variables: {
      input: { totalDebtCents: 0, annualDebtPaymentsCents: 0 },
    },
    onCompleted: updateNoDebtOnCompleted,
  });

  const updateUserStateAsync = (hasDebt: boolean): Promise<void> => Promise.resolve(updateUserState({
    ...user,
    hasDebt,
    totalDebtCents: hasDebt ? (user.totalDebtCents ?? 0) : 0,
    annualDebtPaymentsCents: hasDebt ? (user.annualDebtPaymentsCents ?? 0) : 0,
  }));

  const onContinueClick = async (value: string): Promise<void> => {
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectDebt).then();
    if (value.toLowerCase() === 'no') {
      await updateUserStateAsync(false);
      updateNoDebtStatus().then();
    } else {
      await updateUserStateAsync(true);
      if (props.onContinue) props.onContinue();
    }
  };

  const debtOptions: DebtOption[] = [
    {
      option: t('user:howToInvest.debt.no'),
      value: 'No',
    },
    {
      option: t('user:howToInvest.debt.yes'),
      value: 'Yes',
    },
  ];
  return (
    <OvForm loading={false} hasButton={false}>
      <Typography variant="heading2">{t('user:howToInvest.debt.title')}</Typography>
      <Typography variant="paragraph3">{t('user:howToInvest.debt.subTitle')}</Typography>
      <List sx={{ maxHeight: '250px', overflowY: 'auto' }}>
        {debtOptions.map((item: DebtOption) => (
          <Box key={item.value}>
            <ButtonListItem
              key={item.value}
              text={item.option}
              onClick={() => {
                if (item.value) onContinueClick(item.value);
              }}
              isSelected={(user.annualDebtPaymentsCents === null && user.totalDebtCents === null) ? undefined : (item.value === 'No' && user.annualDebtPaymentsCents === 0 && user.totalDebtCents === 0) || (item.value === 'Yes' && (user.annualDebtPaymentsCents !== 0 || user.totalDebtCents !== 0)) || undefined}
            />
            <Box mb={1} />
          </Box>
        ))}
      </List>
    </OvForm>
  );
};

Debt.defaultProps = {
  onContinue: undefined,
};

export default Debt;
