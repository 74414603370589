import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInput, { DropdownOption } from '../inputs/dropdownInput';
import { Account, getSubAccountName, SubAccount } from '../../../utils/commonGraphql';
import CreateSubAccountWizard from '../../account/createSubAccountWizard';
import FlowModal from '../wrappers/modals/ovFlowModal';
import { UserContext } from '../../../providers/userContextProvider';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { AccountTypes, AffiliationTypes } from '../../account/resources';
import { userIdVar } from '../../../utils/localVariables';
import { Affiliation } from '../../../pages/affiliates';

interface PortfolioDropdownProps {
  onChange: (e: SubAccount) => void,
  value: string,
  subAccounts?: SubAccount[],
  canCreateSubAccount?: boolean,
  dataTestId: string,
  goalId?: string,
  excludedSubAccountId?: string,
  forDeposit?: boolean,
  accountTypesToHide?: AccountTypes[],
  selectedSubAccountId?: string,
  showOnlySelectedSubAccountAsOption?: boolean,
}

const PortfolioDropDown = ({
  subAccounts,
  onChange,
  value,
  dataTestId,
  goalId,
  canCreateSubAccount = true,
  forDeposit = false,
  excludedSubAccountId,
  accountTypesToHide,
  selectedSubAccountId,
  showOnlySelectedSubAccountAsOption,
}: PortfolioDropdownProps): JSX.Element => {
  const [addSubAccount, setAddSubAccount] = useState(false);
  const { userContext } = useContext(UserContext);
  const { t } = useTranslation(['goal', 'transfer', 'base']);
  const { showToast } = useGlobalToast();
  const assignSubAccount = (): void => {
    const foundSubAccounts = userContext.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalId) ?? [];
    if (foundSubAccounts.length === 1) {
      onChange(foundSubAccounts[0]);
    }
  };

  const getUserAccess = (account?: Account): boolean => {
    if (!account) return true;
    const authorizedUser = account?.affiliations?.filter((x: Affiliation) => (
      x.type !== null && [
        AffiliationTypes.AUTHORIZED_INDIVIDUAL, AffiliationTypes.JOINT,
      ].includes(x.type as AffiliationTypes)));
    const isAuthorizedUser = authorizedUser?.map((x: Affiliation) => x.user?.id).includes(userIdVar());
    return account?.user?.id === userIdVar() || (isAuthorizedUser ?? false);
  };

  const optionList = (): DropdownOption[] => {
    if (subAccounts) {
      return (subAccounts)
        .filter((item) => item.id !== excludedSubAccountId)
        .filter((item) => !forDeposit || item.allowClientDeposits === true)
        .filter((item) => item.state === 'AWAITING_APPROVAL' || item.state === 'ACTIVE')
        .filter((item) => !accountTypesToHide?.includes(item.account?.type as AccountTypes))
        .filter((item) => getUserAccess(item?.account))
        .filter((item) => (showOnlySelectedSubAccountAsOption && selectedSubAccountId ? item.id === selectedSubAccountId : true))
        .map((subAccount) => (
          { imageUrl: '', text: getSubAccountName(subAccount), value: subAccount.id }
        ));
    }

    if (userContext?.subAccounts) {
      return (userContext.subAccounts
        .filter((item) => (item?.goal?.id ?? '') === goalId && item.id !== excludedSubAccountId))
        .filter((item) => !forDeposit || item.allowClientDeposits === true)
        .filter((item) => item.state === 'AWAITING_APPROVAL' || item.state === 'ACTIVE')
        .filter((item) => !accountTypesToHide?.includes(item.account?.type as AccountTypes))
        .filter((item) => (showOnlySelectedSubAccountAsOption && selectedSubAccountId ? item.id === selectedSubAccountId : true))
        .filter((item) => getUserAccess(item?.account))
        .map((subAccount) => (
          { imageUrl: '', text: getSubAccountName(subAccount), value: subAccount.id }
        ));
    }

    return [];
  };

  return (
    <>
      <DropdownInput
        dataTestId={dataTestId}
        onChange={(e) => {
          const foundSubAccount = userContext.subAccounts?.find((item) => item.id === e.target.value);
          if (foundSubAccount) {
            onChange(foundSubAccount);
          }
        }}
        options={optionList()}
        value={value}
        suffixLink={(canCreateSubAccount && !subAccounts && (userContext?.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalId).length === 0)) ? { text: t('base:portfolioDropdown.createPortfolio'), onChange: () => setAddSubAccount(true) } : undefined}
      />
      <FlowModal
        goBack={() => setAddSubAccount(false)}
        open={addSubAccount}
        showCloseButton={false}
        maintainModal
        component={(
          <CreateSubAccountWizard
            stepTitle={t('goal:goalDetails.addPortfolioWizardHeader')}
            goalId={goalId ?? ''}
            goBack={() => setAddSubAccount(false)}
            onContinue={() => {
              showToast({ message: t('toastMessages:openPortfolioSuccess.h2'), severity: 'success', title: t('toastMessages:openPortfolioSuccess.h1') });
              assignSubAccount();
              setAddSubAccount(false);
            }}
          />
        )}
      />
    </>
  );
};

PortfolioDropDown.defaultProps = {
  goalId: undefined,
  subAccounts: undefined,
  canCreateSubAccount: true,
  forDeposit: false,
  excludedSubAccountId: undefined,
  accountTypesToHide: [],
  selectedSubAccountId: undefined,
  showOnlySelectedSubAccountAsOption: false,
};

export default PortfolioDropDown;
