import { gql } from '@apollo/client';
import { PhysicalAddress } from '../user/address/resources';

export const BASIC_PERSONAL_INFO = gql`
  query BasicMe {
    me {
      user {
        id
        incompleteFields
        firstName
        middleName
        lastName
        primaryEmail
        language
        phone 
        employmentStatus
        studentAreaOfStudy
        jobTitle
        physicalAddress {
          city
          province
          streetName
          postal
          unitNumber
          houseNumber
          neighborhood
          country
        }
      }
    }
  }
`;

export const BASIC_ADDRESS_INFO = gql`
  query BasicMe {
    me {
      user {
        id
        dateOfBirth
        inProvinceSince
        physicalAddress {
          city
          province
          streetName
          postal
          unitNumber
          houseNumber
          neighborhood
          country
        }
      }
    }
  }
`;

export interface PersonalDetailsResponse {
  id: string,
  incompleteFields: string[],
  firstName?: string,
  middleName?: string,
  lastName?: string,
  language?: string,
  primaryEmail?: string,
  phone?: string,
  studentAreaOfStudy?: string,
  employmentStatus: string,
  jobTitle: string,
  physicalAddress: PhysicalAddress,
}
