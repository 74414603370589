/* eslint-disable no-promise-executor-return */
import { useContext, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import WithStepWizard, { StepProps } from '../../common/wrappers/withStepWizard';
import { PhysicalAddress } from './resources';
import GoogleAddress from './googleAddress';
import UserAddress from './userAddress';
import TrackingBar from '../../common/headers/trackingBar';
import IncompleteAgreements from '../../documents/incompleteAgreements';
import { INCOMPLETE_FORM_AGREEMENT } from '../../suitability-review/graphql';
import { UserContext } from '../../../providers/userContextProvider';
import { sendAnalyticsEvent } from '../../../utils/firebase';

const UserAddressStep = WithStepWizard(UserAddress);
const GoogleAddressStep = WithStepWizard(GoogleAddress);
const IncompleteAgreementsStep = WithStepWizard(IncompleteAgreements);

export interface UserState {
  incompleteFields?: string[],
  physicalAddress?: Partial<PhysicalAddress>,
}

const styles = {
  closeBox: {
    display: 'flex',
    justifyContent: 'right',
    width: '100%',
    padding: '15px 15px 0 15px',
  },
  button: {
    width: '40px',
  },
};
interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  goBack?: () => void,
  title?: string,
  subtitle?: string,
  stepProps?: StepProps,
  stepTitle?: string,
  showStepper?: boolean,
  shouldSaveUpdate?: boolean,
  showAgreements?: boolean,
  hideNav?: boolean,
  analytictsEvent?: string,
  // This updates the employerCompanyAddress instead of physicalAddress
  updateEmployerAddress?: boolean,
}
const defineInitialStatus = (user: UserState): number => {
  if (user.physicalAddress) {
    return 2;
  }
  return 1;
};
const UpdatePhysicalAddressWizard = ({
  user,
  updateUserState,
  onContinue,
  goBack, showStepper, shouldSaveUpdate = true, showAgreements, hideNav, updateEmployerAddress, ...props
} : Props): JSX.Element => {
  const { t } = useTranslation(['user']);
  const [loading, setLoading] = useState(false);
  const { userContext, setUserContext } = useContext(UserContext);
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const [addressState, setAddressState] = useState({
    address: '',
    error: '',
    open: false,
  });
  const handleClose = (): void => {
    setAddressState({
      address: '',
      error: '',
      open: false,
    });
  };
  const [getIncompleteFormAgreement] = useLazyQuery(INCOMPLETE_FORM_AGREEMENT, {
    variables: {
      input: {},
    },
    nextFetchPolicy: 'standby',
  });

  const onUserAddressUpdate = async (callback: () => void): Promise<void> => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const agreements = await getIncompleteFormAgreement();
    setLoading(false);
    if (props.analytictsEvent) {
      sendAnalyticsEvent(props.analytictsEvent).then();
    }
    if (agreements.data.me.user.allIncompleteFormAgreements.length > 0) {
      setUserContext({
        ...userContext,
        allIncompleteFormAgreements: agreements.data.me.user.allIncompleteFormAgreements,
        accounts: agreements.data.me.user.accounts.filter((g: { state: string }) => !['CANCELED', 'INACTIVE'].includes(g.state ?? '')),
        subAccounts: agreements.data.me.user.subAccounts.filter((g: { state: string; }) => g.state !== 'INACTIVE'),
      });
      callback();
    } else if (onContinue) onContinue();
  };

  const steps: JSX.Element[] = [
    <GoogleAddressStep key="SelectedUserAddress" stepTitle={props.stepTitle} user={user} updateUserState={updateUserState} title={props.title} subtitle={props.subtitle} goBackAction={goBack} setAddressState={setAddressState} />,
    <UserAddressStep
      analyticsEvent={props.analytictsEvent}
      key="UserAddress"
      stepTitle={props.stepTitle}
      user={user}
      updateUserState={updateUserState}
      action={showAgreements ? onUserAddressUpdate : onContinue}
      title={props.title}
      subtitle={props.subtitle}
      shouldSaveUpdate={shouldSaveUpdate}
      setAddressState={setAddressState}
      forceLoading={loading}
      updateEmployerAddress={updateEmployerAddress}
    />,
    ...(showAgreements ? [
      <IncompleteAgreementsStep stepTitle={t('user:agreements.stepWizardTitle')} stepName="agreements" action={onContinue} key="agreements" />,
    ] : []),
  ];

  const openComingSoonTab = (): void => {
    if (window) {
      window.open('https://cdn.forms-content.sg-form.com/c582abf1-5183-11ed-9ed7-96a630b9fab9', '_blank');
    }
  };
  const addressErrorDialog = (): JSX.Element => (
    <Dialog onClose={handleClose} open={addressState.open} style={{ padding: '15px' }}>
      <Box sx={styles.closeBox}>
        <IconButton onClick={handleClose} style={styles.button}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle><Typography id="modal-modal-title" variant="heading3">{t('user:address.dialog.title')}</Typography></DialogTitle>
      <DialogContent>
        <Typography id="modal-modal-description" variant="paragraph3">{t('user:address.dialog.message')}</Typography>
        <Button variant="text-large" data-testid="continue-button" onClick={handleClose}>{t('user:address.dialog.backButton')}</Button>
        <Button onClick={openComingSoonTab} variant="primary-large" data-testid="continue-button">{t('user:address.dialog.joinButton')}</Button>
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      {addressErrorDialog()}
      <StepWizard
        instance={setInstance}
        isLazyMount
        initialStep={defineInitialStatus(user)}
        className="ov-step-wizard"
        transitions={{}}
        nav={hideNav ? undefined : (
          <TrackingBar steps={steps} baselineStepProps={props.stepProps} showStepper={showStepper} />
        )}
      >
        {steps}
      </StepWizard>
    </>
  );
};

UpdatePhysicalAddressWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  title: undefined,
  subtitle: undefined,
  stepProps: undefined,
  stepTitle: undefined,
  showStepper: true,
  shouldSaveUpdate: true,
  showAgreements: false,
  hideNav: false,
  analytictsEvent: undefined,
  updateEmployerAddress: false,
};

export default UpdatePhysicalAddressWizard;
