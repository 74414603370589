import { colors } from '../../theme/colors';

export const portfolioPerformanceStyles = {
  listItemWrapper: {
    background: colors.ovWhite,
    border: '1px solid rgba(218, 228, 237, 0.4)',
    boxShadow: '0px 4px 10px rgba(234, 236, 239, 0.25)',
    borderRadius: '6px',
    padding: '10px',
    height: '80px',
    width: '96%',
  },
  cageWrapper: {
    width: '100%',
    display: 'inline-grid',
    justifyContent: 'center',
    textAlign: 'center',
  },
  infoCage: {
    width: '100%',
    height: '137px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
  },
  moneyWeightCage: {
    marginBottom: '46px',
    marginTop: '14px',
    display: 'grid',
  },
  descriptionBox: {
    padding: '12px 36px 0px 36px',
  },
  buttonBox: {
    padding: '14px 36px 14px 36px',
  },
};

export const historyChartStyles = {
  root: {
    flexGrow: 1,
    left: 0,
    right: 0,
    marginLeft: '-0px',
    position: 'relative' as const,
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  chart: {
    height: '300px',
    maxHeight: '300px',
    position: 'relative' as const,
  },
  tooltip: {
    position: 'fixed',
    width: '100%',
    maxWidth: '490px',
    display: 'flex',
    justifyContent: 'center',
  },
  iconStyle: {
    position: 'relative',
    top: '20px',
    marginRight: '5px',
  },
  titleStyle: {
    color: '#2B4763',
    paddingBottom: '0 !important',
    position: 'relative',
    top: '15px',
  },
  tooltipCage: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '132px',
    height: '170px',
    width: '100%',
    background: 'white',
    left: 0,
    right: 0,
  },
  periodGrid: {
    maxWidth: '600px',
  },
  loading: {
    width: '30px',
    color: colors.secondary,
    height: '336px',
  },
};
