/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, Button } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Markup } from 'interweave';
import useMediaQuery from '@mui/material/useMediaQuery';
import { colors } from '../../theme/colors';
import TaxLossHarvesting from '../../assets/images/tax-loss-harvesting.svg';
import FlowModal from '../common/wrappers/modals/ovFlowModal';
import EnableTLH from '../account/taxLossHarvesting/enableTLH';
import { UPDATE_ACCOUNT } from '../account/graphql';
import CloseButton from '../common/buttons/closeButton';
import { formatReturnAmount } from '../../utils/commonMethods';
import DisableTLH from '../account/taxLossHarvesting/disableTLH';

interface Props {
  accountId?: string,
  isEnabled: boolean,
  netContributionCents: number,
  creditCents: number,
  onComplete: () => void,
}

const styles = {
  outerBox: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'start',
    justifyContent: 'center',
    padding: '15px 16px 20px 16px',
    backgroundColor: colors.autoInvestmentSummaryCard,
    borderRadius: '6px',
    cursor: 'pointer',
  },
  targetMessage: {
    textAlign: 'left',
    marginTop: '12px',
    borderBottom: '1px dashed #DDD9D9',
    paddingBottom: '12px',
  },
  headerText: {
    color: colors.autoInvestmentSummaryHeader,
    margin: 0,
  },
  bodyText: {
    color: colors.autoInvestmentSummaryBody,
    margin: 0,
  },
  enableAutoInvestButton: {
    backgroundColor: colors.baseComponentBackground,
    height: '60px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    border: '1px solid rgba(10, 46, 101, 0.09)',
    borderRadius: '4px',
    boxShadow: '3px 6px 10px rgba(0, 102, 245, 0.03)',
    chevronIcon: {
      color: colors.ovBlack,
    },
    box: {
      image: {
        marginRight: '18px',
      },
      display: 'flex',
      flexDirection: 'row' as const,
      alignItems: 'center',
    },
  },
};

const EnableTLHButton = ({
  accountId, isEnabled, netContributionCents, creditCents, onComplete,
}: Props): JSX.Element => {
  const matches = useMediaQuery('(max-width:600px)');
  const MIN_NET_DEPOSIT = 1000000;
  const { t } = useTranslation(['base']);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [updateAccountMutation] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: () => {
      setLoading(false);
      setOpenModal(false);
      onComplete();
    },
  });

  const tlhCard = (): JSX.Element => (
    <Box
      style={styles.outerBox}
      onClick={() => setOpenModal(true)}
    >
      <Typography variant="heading5" style={styles.headerText}>
        {t('base:enabledTLHButton.header')}
      </Typography>
      <Box mb={1} />
      <Typography variant="paragraph3" style={styles.bodyText}>
        {
          netContributionCents < MIN_NET_DEPOSIT ? t('base:enabledTLHButton.textTwo') : <Markup content={t('base:enabledTLHButton.textOne', { credit: formatReturnAmount(creditCents, '$') })} />
        }
      </Typography>
    </Box>
  );

  const enableTLH = (): JSX.Element => (
    <Button
      style={styles.enableAutoInvestButton}
      onClick={() => setOpenModal(true)}
    >
      <Box style={styles.enableAutoInvestButton.box}>
        <img src={TaxLossHarvesting} alt="tax-loss-harvesting" style={styles.enableAutoInvestButton.box.image} />
        <Typography variant="heading5" style={styles.headerText}>
          {t('base:enableTLHButton.text')}
        </Typography>
      </Box>
      <ChevronRightRoundedIcon style={styles.enableAutoInvestButton.chevronIcon} />
    </Button>
  );

  if (openModal) {
    return (
      <FlowModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        showCloseButton={false}
        component={(
          <Box height="100%" width={matches ? undefined : '480px'}>
            <Box>
              <CloseButton onClick={() => setOpenModal(false)} />
            </Box>
            {
              isEnabled ? (
                <DisableTLH
                  loading={loading}
                  setDisableTLH={(value) => {
                    if (value) {
                      setLoading(true);
                      updateAccountMutation({
                        variables: {
                          input: {
                            accountId,
                            tlhEnabled: false,
                          },
                        },
                      }).then();
                    } else {
                      setOpenModal(false);
                    }
                  }}
                />
              ) : (
                <EnableTLH
                  loading={loading}
                  setEnableTLH={(value) => {
                    if (value) {
                      setLoading(true);
                      updateAccountMutation({
                        variables: {
                          input: {
                            accountId,
                            tlhEnabled: value,
                          },
                        },
                      }).then();
                    } else {
                      setOpenModal(false);
                    }
                  }}
                />
              )
            }
          </Box>
        )}
      />
    );
  }
  return (
    <>
      {isEnabled ? tlhCard() : enableTLH()}
      <Box display="flex" flexDirection="row" justifyContent="left" sx={styles.targetMessage}>
        <Typography variant="captionSectionerBold" color={colors.targetAmountMessage}>
          <Markup content={t('base:enableTLHButton.suffixNote')} />
        </Typography>
      </Box>
    </>
  );
};

EnableTLHButton.defaultProps = {
  accountId: undefined,
};

export default EnableTLHButton;
