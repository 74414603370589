import * as React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';

interface OvButtonProps {
  text: string,
  loading: boolean,
}

const OvButton = ({ loading, text, ...props }: ButtonProps & OvButtonProps): JSX.Element => (
  <Button {...props} data-testid="continue-button">
    {
      loading ? <CircularProgress className="loader" data-testid="loading-element" thickness={6} /> : text
    }
  </Button>
);

export default OvButton;
