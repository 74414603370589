import { gql } from '@apollo/client';

export const ME = gql`
  query Me($includeAllHouseholds: Boolean){
    me {
      user {
        id
        firstName
        lastName
        households {
          id
          relationships {
            user {
              id
            }
            accessType
          }
        }
        statistics(includeAllHouseholds: $includeAllHouseholds) {
          id
          marketValueCents
          moneyAvailableCents
          netContributionCents
          pendingContributionCents
          simpleReturnAmount
          simpleReturnPercent
          timeWeightedReturn
          moneyWeightedReturn
          availableFundsToWithdrawCents
        }
      }
    }
  }
`;

export const FETCH_MY_GOALS = gql`
  query fetchGoals($startDate: Date, $forUserId: ObjectID, $householdViewType: HouseholdViewTypes) {
    fetchGoals(input:{
      filter: {
        state: ACTIVE
        householdViewType: $householdViewType
      }
      pagination: {
        perPage: 1000
      }
    }) {
      goals {
        id
        type
        name
        state
        targetAmountCents
        householdClientGroup {
          id
          name
          relationships {
            user { id firstName }
            accessType
          }
        }
        statistics(forUserId: $forUserId) {
          id
          marketValueCents
          simpleReturnAmount(startDate : $startDate)
          simpleReturnPercent(startDate : $startDate)
        }
        subAccounts{
          id
          name
          state
          account {
            id
            type
            state
            user {
              id
            }
            affiliations {
              id
              type
              user { id }
            }
          }
          theme {
            id
            translatedName {
              en
              fr
            }
          }
          statistics {
            id
            availableFundsToWithdrawCents
          }
          allowClientDeposits
        }
      }
    }
  }
`;

export const ACCOUNTS_STATS = gql`
  query fetchAccounts($startDate: Date, $householdViewType: HouseholdViewTypes) {
    fetchAccounts(input: {
      filter: {
        states: [ACTIVE,REQUESTED,FROZEN,FAILED,INITIATED,READY]
        householdViewType: $householdViewType
      }
      pagination: {
        perPage: 1000
      }
    }) {
      accounts {
        id
        type
        user {
          id
          firstName
        }
        householdClientGroup {
          id
          name
          relationships {
            user {
              id
              firstName
            }
          }
        }
        state
        user { id firstName lastName }
        statistics {
          id
          marketValueCents
          simpleReturnAmount(startDate : $startDate)
          simpleReturnPercent(startDate : $startDate)
        }
        subAccounts {
          id
          state
          goal {
            id
            state
          }
        }
      }
    }
  }
`;

export const FETCH_MY_HOLDINGS = gql`
query ME($includeAllHouseholds: Boolean){
  me {
    user {
      statistics(includeAllHouseholds: $includeAllHouseholds) {
        id
        marketValueCents
        primaryAssetClassHoldings {
           assetClass {
            id
            key
            name
            description
            translatedName {
              en
              fr
            }
            translatedDescription {
              en
              fr
            }
          }
          percentage
          totalCents
          secondaryAssetClassHoldings {
            assetClass {
              id
              key
              name
              description
              translatedName {
                en
                fr
              }
              translatedDescription {
                en
                fr
              }
            }
            percentage
            totalCents
          securityHoldings {
            financialProduct {
              id
              ticker
              translatedPortfolioDescription { en fr}
              translatedName { en fr }
            }
            percentage
            totalCents
            quantity
          }
        }
        }
        holdings {
          totalCents
          financialProduct {
            primaryAssetClass {
              key
              translatedName { en fr }
              translatedDescription { en fr }
            }
            secondaryAssetClass {
              key
              translatedName { en fr }
              translatedDescription { en fr }
            }
          }
        }
      }
    }
  }
}
`;
