/* eslint-disable no-promise-executor-return */
/* eslint-disable  react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useTranslation } from 'react-i18next';
import WithStepWizard, { StepProps } from '../../common/wrappers/withStepWizard';
import TrackingBar from '../../common/headers/trackingBar';
import ConfirmSpousalAffiliateDetails from '../../account/ConfirmSpousalAffiliateDetails';
import UpdatePhysicalAddressWizard from '../address/updatePhysicalAddressWizard';
import UpdateEmploymentStatusWizard from '../employment/updateEmployementStatusWizard';
import DateOfBirth from '../dateOfBirth';
import Name from '../name';
import Sin from '../sin';
import { PhysicalAddress } from '../address/resources';
import Email from '../../signUp/email';

const SpousalNameStep = WithStepWizard(Name);
const SpousalEmailStep = WithStepWizard(Email);
const SpousePhysicalAddressWizardStep = WithStepWizard(UpdatePhysicalAddressWizard);
const SpouseEmploymentStatusWizardStep = WithStepWizard(UpdateEmploymentStatusWizard);
const ConfirmSpousalAffiliateDetailsStep = WithStepWizard(ConfirmSpousalAffiliateDetails);
const SpouseDateOfBirthStep = WithStepWizard(DateOfBirth);
const SpouseSinStep = WithStepWizard(Sin);
export interface UserState {
  spouseFirstName?: string,
  spouseMiddleName?: string,
  spouseLastName?: string,
  spouseCompanyType?: string,
  spouseJobTitle?: string,
  spouseDateOfBirth?: Date,
  spouseSin?: string,
  spouseEmail?: string,
  spouseAddress?: Partial<PhysicalAddress>,
  spouseEmploymentType?: string,
}

interface Props {
  user: UserState,
  onContinue?: () => void,
  goBack?: () => void,
  updateUserState: (att: UserState) => void,
  title?: string,
  subtitle?: string,
  stepProps?: StepProps,
  stepTitle?: string,
  showStepper?: boolean,
  shouldSaveUpdate?: boolean,
  hideNav?: boolean,
}

const SpouseDetailsWizard = ({
  user,
  onContinue,
  updateUserState,
  goBack, showStepper, hideNav, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const [spouseUser, updateSpouseUser] = useState<{
    firstName?: string,
    middleName?: string,
    lastName?: string,
    companyType?: string,
    jobTitle?: string,
    dateOfBirth?: Date,
    sin?: string,
    primaryEmail?: string,
    physicalAddress?: Partial<PhysicalAddress>,
    employmentType?: string,
    employmentStatus?: string,
  }>({
    firstName: user.spouseFirstName,
    middleName: user.spouseMiddleName,
    lastName: user.spouseLastName,
    companyType: user.spouseCompanyType,
    jobTitle: user.spouseJobTitle,
    dateOfBirth: user.spouseDateOfBirth,
    sin: user.spouseSin,
    primaryEmail: user.spouseEmail,
    physicalAddress: user.spouseAddress,
    employmentType: user.spouseEmploymentType,
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });

  useEffect(() => {
    updateUserState({
      ...user,
      spouseFirstName: spouseUser.firstName,
      spouseMiddleName: spouseUser.middleName,
      spouseLastName: spouseUser.lastName,
      spouseCompanyType: spouseUser.companyType,
      spouseJobTitle: spouseUser.jobTitle,
      spouseDateOfBirth: spouseUser.dateOfBirth,
      spouseSin: spouseUser.sin,
      spouseEmail: spouseUser.primaryEmail,
      spouseAddress: spouseUser.physicalAddress,
      spouseEmploymentType: spouseUser.employmentType,
    });
  }, [spouseUser]);

  const steps: JSX.Element[] = [<SpousalNameStep
    // stepTitle={stepTitle}
    stepName="rrifSpousalName"
    continueToNamedStep={() => (isEditMode ? 'rrifSpousalConfirmStep' : 'rrifSpousalEmail')}
    key="rrifSpousalName"
    user={spouseUser}
    updateUserState={updateSpouseUser}
    title={t('rrifSteps.titles.spouseName.title')}
    subtitle={t('rrifSteps.titles.spouseName.subTitle')}
    goBackAction={goBack}
  />,
    <SpousalEmailStep
      texts={{
        title: t('account:rrifSteps.titles.spouseEmail.title', { name: spouseUser.firstName }),
        subTitle: t('account:rrifSteps.titles.spouseEmail.subTitle'),
      }}
      continueToNamedStep={() => (isEditMode ? 'rrifSpousalConfirmStep' : 'rrifSpousalPhysicalAddress')}
      stepName="rrifSpousalEmail"
      key="rrifSpousalEmail"
      updateUserState={updateSpouseUser}
      user={spouseUser}
  />,
    <SpousePhysicalAddressWizardStep continueToNamedStep={() => (isEditMode ? 'rrifSpousalConfirmStep' : 'rrifSpousalDOB')} stepName="rrifSpousalPhysicalAddress" key="rrifSpousalPhysicalAddress" hideNav stepProps={{ absoluteTotalSteps: 6, currentStep: 3 }} title={t('rrifSteps.titles.spouseAddress.title', { spouseFirstName: spouseUser.firstName })} subtitle={t('rrifSteps.titles.spouseAddress.subTitle')} user={spouseUser} updateUserState={updateSpouseUser} showStepper={false} shouldSaveUpdate={false} />,
    <SpouseDateOfBirthStep stepName="rrifSpousalDOB" key="rrifSpousalDOB" continueToNamedStep={() => (isEditMode ? 'rrifSpousalConfirmStep' : 'rrifSpousalSin')} title={t('rrifSteps.titles.spouseDOB.title', { spouseFirstName: spouseUser.firstName })} subtitle={t('rrifSteps.titles.spouseDOB.subTitle')} user={spouseUser} updateUserState={updateSpouseUser} shouldSaveUpdate={false} />,
    <SpouseSinStep stepName="rrifSpousalSin" key="rrifSpousalSin" title={t('rrifSteps.titles.spouseSIN.title', { spouseFirstName: spouseUser.firstName })} user={spouseUser} updateUserState={updateSpouseUser} shouldSaveUpdate={false} />,
    <SpouseEmploymentStatusWizardStep
      stepName="rrifSpousalEmploymentStatus"
      key="rrifSpousalEmploymentStatus"
      titles={{
        employmentInfo: { title: t('rrifSteps.titles.spouseEmploymentInfo.title', { spouseFirstName: spouseUser.firstName }), subTitle: t('rrifSteps.titles.spouseEmploymentInfo.subTitle') },
        industry: { title: t(`rrifSteps.titles.spouseEmploymentIndustry.${spouseUser.employmentStatus === 'RETIRED' ? 'titleForRetiered' : 'title'}`, { spouseFirstName: spouseUser.firstName }), subTitle: t('rrifSteps.titles.spouseEmploymentIndustry.subTitle') },
        role: { title: t(`rrifSteps.titles.spouseEmploymentRole.${spouseUser.employmentStatus === 'RETIRED' ? 'titleForRetiered' : 'title'}`, { spouseFirstName: spouseUser.firstName }), subTitle: t('rrifSteps.titles.spouseEmploymentRole.subTitle') },
        fieldOfStudy: { title: t('rrifSteps.titles.spouseEmploymentFieldOfStudy.title', { spouseFirstName: spouseUser.firstName }), subTitle: t('rrifSteps.titles.spouseEmploymentFieldOfStudy.subTitle') },
      }}
      isSpousalOnly
      user={spouseUser}
      updateUserState={updateSpouseUser}
      hideNav
      shouldSaveUpdate={false}
      onContinueStepUnEmployed="rrifSpousalConfirmStep"
  />,
    <ConfirmSpousalAffiliateDetailsStep isSpousalOnly stepName="rrifSpousalConfirmStep" key="rrifSpousalConfirmStep" user={spouseUser} onEditMode={setIsEditMode} action={onContinue} />,
  ];

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      // initialStep={defineInitialStatus(user)}
      className="ov-step-wizard"
      transitions={{}}
      nav={hideNav ? undefined : (
        <TrackingBar steps={steps} baselineStepProps={props.stepProps} showStepper={showStepper} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

SpouseDetailsWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  title: undefined,
  subtitle: undefined,
  stepProps: undefined,
  stepTitle: undefined,
  showStepper: true,
  shouldSaveUpdate: true,
  hideNav: false,
};

export default SpouseDetailsWizard;
