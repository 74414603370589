import { Typography, Link } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { translatedUrl } from '../../assets/i18n/config';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { UserContext } from '../../providers/userContextProvider';

const PrivacyPolicyLink = (): JSX.Element => {
  const { t } = useTranslation(['signUp']);
  const { colors } = useContextTheme();
  const { userContext } = useContext(UserContext);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {['picton', 'pmam'].includes(userContext.organizationKey ?? '') ? (
        <Typography variant="captionDescriptorMedium" className="button-disclaimer-responsive">
          {t('signUp:pictonPrivacyPolicy.text')}
          <Link href={translatedUrl('https://www.pictonmahoney.com/assets/html/legal-terms-of-use-wealth.html', 'https://www.pictonmahoney.com/assets/html/legal-terms-of-use-wealth.html')} style={{ color: colors.link }} underline="always" target="_blank">{t('signUp:pictonPrivacyPolicy.pictonLink')}</Link>
          {t('signUp:pictonPrivacyPolicy.and')}
          <Link href={translatedUrl('https://www.onevest.com/legal/terms-and-conditions', 'https://www.onevest.com/fr-ca/legalites/modalites')} style={{ color: colors.link }} underline="always" target="_blank">{t('signUp:pictonPrivacyPolicy.onevestLink')}</Link>
          {t('signUp:pictonPrivacyPolicy.termsAndCondition')}
          <Link href={translatedUrl('https://www.onevest.com/legal/privacy-policy', 'https://www.onevest.com/fr-ca/legalites/politique-protection-renseignments-personnels')} style={{ color: colors.link }} underline="always" target="_blank">{t('signUp:pictonPrivacyPolicy.link')}</Link>
        </Typography>
      ) : (
        <Typography variant="captionDescriptorMedium" className="button-disclaimer-responsive">
          {t('signUp:privacyPolicy.text')}
          <Link href={translatedUrl('https://www.onevest.com/legal/terms-and-conditions', 'https://www.onevest.com/fr-ca/legalites/modalites')} style={{ color: colors.link }} underline="always" target="_blank">{t('signUp:privacyPolicy.termsAndConditionLink')}</Link>
          {t('signUp:privacyPolicy.and')}
          <Link href={translatedUrl('https://www.onevest.com/legal/privacy-policy', 'https://www.onevest.com/fr-ca/legalites/politique-protection-renseignments-personnels')} style={{ color: colors.link }} underline="always" target="_blank">{t('signUp:privacyPolicy.link')}</Link>
        </Typography>
      )}
    </>
  );
};

export default PrivacyPolicyLink;
