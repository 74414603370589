import {
  FormEvent, useContext,
  useEffect, useRef,
  useState,
} from 'react';
import {
  Typography,
  Box,
  TextField,
  Modal,
} from '@mui/material';
import * as React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StaticDatePicker } from '@mui/lab';
import OvForm from '../common/wrappers/ovForm';
import {
  CREATE_SCHEDULED_DEPOSIT_TRANSFER,
  FETCH_ALL_INCOMPLETE_FORM_AGREEMENT,
  FETCH_MY_BANK_ACCOUNTS,
  SIGN_PAD_AGREMETNS,
} from '../transfers/graphql';
import DropdownInput from '../common/inputs/dropdownInput';
import Flinks from '../flinks/flinks';
import { CurrencyFormatter } from '../common/inputs/utils/formatters';
import { UPDATE_SCHEDULED_TRANSFER } from './graphql';
import { UserContext } from '../../providers/userContextProvider';
import PortfolioDropdown from '../common/controllers/portfolioDropdown';
import Disclaimer from '../common/disclaimer';
import { getBackendLanguage, getLocale } from '../../assets/i18n/config';
import { generateUniqueRequestId } from '../../utils/generic';
import PADLink from '../transfers/deposit/pADLink';
import { FeatureFlagTypes } from '../account/resources';
import GoalDropDown from '../common/controllers/goalDropdown';
import { SubAccount } from '../../utils/commonGraphql';
import { AllIncompleteFormAgreement } from '../documents/incompleteAgreements';

dayjs.extend(utc);

interface ScheduledTransfer {
  amountCents: number
  frequency: string
  id: string,
  type: string,
  scheduledDate: string,
  state: string,
  bankAccount: {
    id: string,
  },
  subAccount: {
    id: string,
    theme: {
      key: string,
      translatedName: {
        en: string,
        fr: string,
      },
    },
    account: {
      id: string,
      type: string,
    },
  }
}

interface Props {
  goToNamedStep?: (step: string) => void,
  editableScheduledTransfer?: ScheduledTransfer,
  fetchAutoInvestment: () => void,
  subAccountId?: string,
  goalId?: string,
}

const style = {
  outerBox: {
    boxSizing: 'border-box',
    padding: '18px 0px 18px 0px',
    width: '415px',
    '@media (max-width:600px)': {
      width: 'fit-content',
      height: '95%',
    },
  },
};

const AddAutoInvestmentForm = ({
  goToNamedStep,
  editableScheduledTransfer,
  fetchAutoInvestment,
  subAccountId,
  goalId,
}: Props): JSX.Element => {
  const nextDay = new Date().setDate(new Date().getDate() + 1);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['autoInvestment', 'base']);
  const [disableForm, setDisableForm] = useState(true);
  const [from, setFrom] = useState('');
  const [amount, setAmount] = useState('');
  const [showFlinks, setShowFlinks] = useState(false);
  const [schedule, setSchedule] = useState('MONTHLY');
  const [starting, setStarting] = useState('Tomorrow');
  const [scheduledDate, setScheduledDate] = useState(dayjs(nextDay).format('YYYY-MM-DD'));
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [toGoal, setToGoal] = useState(goalId);
  const [portfolio, setPortfolio] = useState(subAccountId);
  const [tempDate, setTempDate] = useState('');
  const [bankAccounts, setBankAccounts] = useState<{ id: string; name: string; bankAccountNumber: string; }[]>([]);
  const bankAccountsRef = useRef(bankAccounts);
  const { userContext } = useContext(UserContext);
  const [goalSubAccounts, setGoalSubAccounts] = useState<SubAccount[] | undefined>(undefined);
  const setDate = (value: string): void => {
    setStarting(value === 'Tomorrow' ? value : 'Future Date');
    if (value === 'Tomorrow') {
      setScheduledDate(dayjs(nextDay).format('YYYY-MM-DD'));
    } else {
      setOpenCalendar(true);
    }
  };
  const formatDateForUI = (): string => {
    if (scheduledDate) {
      return ` (${dayjs(scheduledDate).format('D MMM YYYY')})`;
    }

    return '';
  };
  const setBankAccountData = (data: {
    me: {
      user: {
        bankAccounts: {
          id: string,
          name: string,
          bankAccountNumber: string,
        }[]
      },
    }
  }): void => {
    setLoading(false);
    setBankAccounts((prevState) => [...prevState, ...data.me.user.bankAccounts]);
    const banks = data.me.user.bankAccounts;
    setFrom(banks.length > 0 ? banks[banks.length - 1].id : '');
  };
  const [fetchMyBankAccounts] = useLazyQuery(FETCH_MY_BANK_ACCOUNTS, {
    variables: {},
    onCompleted: setBankAccountData,
    nextFetchPolicy: 'standby',
  });
  const setScheduledDepositTransferData = (): void => {
    setLoading(false);
    if (goToNamedStep) goToNamedStep('your-auto-investment');
    fetchAutoInvestment();
  };
  const [fetchAllIncompleteAgreement] = useLazyQuery(FETCH_ALL_INCOMPLETE_FORM_AGREEMENT, {
    variables: {
      input: {},
    },
  });
  const [createScheduledDepositTransferMutation] = useMutation(CREATE_SCHEDULED_DEPOSIT_TRANSFER, {
    variables: {
      input: {
        amountCents: Math.round(Number(amount) * 100),
        subAccountId: portfolio,
        bankAccountId: from,
        frequency: schedule,
        scheduledDate,
        requestId: generateUniqueRequestId(),
      },
    },
    onCompleted: () => setScheduledDepositTransferData(),
  });
  const [updateScheduledTransferMutation] = useMutation(UPDATE_SCHEDULED_TRANSFER, {
    variables: {
      input: {
        scheduledTransferId: editableScheduledTransfer?.id,
        amountCents: Math.round(Number(amount) * 100),
        bankAccountId: from,
        frequency: schedule,
        scheduledDate,
      },
    },
    onCompleted: () => setScheduledDepositTransferData(),
  });
  const [signPadAgreement] = useMutation(SIGN_PAD_AGREMETNS, {
    variables: {},
  });
  const initDeposit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setLoading(true);
    if (editableScheduledTransfer) {
      updateScheduledTransferMutation().then();
    } else {
      createScheduledDepositTransferMutation().then(async () => {
        const response = await fetchAllIncompleteAgreement();
        const agreements: AllIncompleteFormAgreement[] = response.data.me.user.allIncompleteFormAgreements;
        const padAgreement = agreements.find((agreement) => agreement.digitalSignatureEnabled && !!agreement.scheduledTransfer && agreement.type === 'PAD_AGREEMENT');
        if (padAgreement) {
          signPadAgreement({
            variables: {
              input: {
                scheduledTransferId: padAgreement.scheduledTransfer ? padAgreement.scheduledTransfer.id : undefined,
                formAgreements: [{
                  type: padAgreement.type,
                  version: padAgreement.minVersion,
                }],
                language: getBackendLanguage().toUpperCase(),
              },
            },
          }).then();
        }
      });
    }
  };

  useEffect(() => {
    bankAccountsRef.current = bankAccounts;
  }, [bankAccounts]);
  const confirmDate = (): void => {
    setScheduledDate(tempDate);
    setOpenCalendar(false);
  };
  useEffect(() => {
    if (from && portfolio && amount) {
      setDisableForm(false);
    } else {
      setDisableForm(true);
    }
  }, [from, portfolio, amount]);
  useEffect(() => {
    fetchMyBankAccounts().then();
    if (editableScheduledTransfer) {
      setScheduledDate(dayjs(editableScheduledTransfer.scheduledDate).local().format('YYYY-MM-DD'));
      setFrom(editableScheduledTransfer.bankAccount.id);
      setPortfolio(editableScheduledTransfer.subAccount.id);
      setAmount((editableScheduledTransfer.amountCents / 100).toString());
      setSchedule(editableScheduledTransfer.frequency);
      setStarting('Future Date');
    }
  }, [editableScheduledTransfer, fetchMyBankAccounts]);
  if (showFlinks) {
    return (
      <Flinks
        onClose={() => setShowFlinks(false)}
        open={showFlinks}
        bankAccounts={bankAccounts}
        retrievedBank={(bank) => {
          setBankAccounts((prevState) => [...prevState, bank]);
          setFrom(bank.id);
        }}
      />
    );
  }
  const availableFeatureFlags = userContext?.availableFeatureFlags || [];
  const removeAddbankAccountOption = !availableFeatureFlags?.includes(FeatureFlagTypes.AUTOMATED_BANK_ACCOUNT_LINKING)
    && !availableFeatureFlags?.includes(FeatureFlagTypes.MANUALLY_ADD_BANK_ACCOUNT);
  return (
    <Box sx={style.outerBox}>
      <OvForm onSubmit={initDeposit} loading={loading} disableButton={disableForm}>
        <Typography variant="heading2" style={{ marginBottom: 16 }}>{t('autoInvestment:addAutoInvestmentFormStep.title')}</Typography>
        <Typography variant="paragraph2" style={{ marginBottom: 16 }}>{t('autoInvestment:addAutoInvestmentFormStep.subtitle')}</Typography>
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('autoInvestment:from')}</Typography>
        <DropdownInput
          dataTestId="from-dropdown"
          onChange={(e) => setFrom(e.target.value)}
          options={bankAccounts.map((bankAccount) => (
            { imageUrl: '', text: `${bankAccount.name} ${bankAccount.bankAccountNumber}`, value: bankAccount.id }
          ))}
          value={from}
          suffixLink={!removeAddbankAccountOption ? { text: t('transfer:flinksAddBankAccount'), onChange: () => setShowFlinks(true) } : undefined}
        />
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('autoInvestment:toGoal')}</Typography>
        <GoalDropDown
          dataTestId="toGoal-dropdown"
          selectedGoalId=""
          value={toGoal ?? ''}
          onGoalChanged={(e) => {
            setToGoal(e);
          }}
          setSubAccounts={(values) => {
            setGoalSubAccounts(values);
          }}
        />
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('autoInvestment:toPortfolio')}</Typography>
        <PortfolioDropdown
          forDeposit
          onChange={(e) => setPortfolio(e.id)}
          value={portfolio ?? ''}
          dataTestId="portfolio-dropdown"
          goalId={toGoal ?? ''}
          subAccounts={goalSubAccounts}
        />
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('autoInvestment:amount')}</Typography>
        <TextField
          data-testid="amount"
          id="standard-basic"
          fullWidth
          variant="standard"
          sx={{ marginBottom: 0 }}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: CurrencyFormatter as any,
          }}
        />
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('autoInvestment:schedule')}</Typography>
        <DropdownInput
          onChange={(e) => setSchedule(e.target.value)}
          options={[
            { imageUrl: '', text: t('autoInvestment:scheduleOptions.weekly'), value: 'WEEKLY' },
            { imageUrl: '', text: t('autoInvestment:scheduleOptions.biWeekly'), value: 'BI_WEEKLY' },
            { imageUrl: '', text: t('autoInvestment:scheduleOptions.monthly'), value: 'MONTHLY' },
          ]}
          value={schedule}
        />
        <Box style={{ marginBottom: 24 }} />
        <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('autoInvestment:starting')}</Typography>
        <DropdownInput
          onItemClicked={setDate}
          options={[
            { imageUrl: '', text: t('autoInvestment:startingOptions.tomorrow'), value: 'Tomorrow' },
            { imageUrl: '', text: `${t('autoInvestment:startingOptions.futureDate')} ${formatDateForUI()}`, value: 'Future Date' },
          ]}
          value={starting}
        />
        <Box style={{ marginBottom: 20 }} />
        <Disclaimer text={t('base:transferDisclaimer.text')} />
        <Box style={{ marginBottom: 32 }} />
        {['WEEKLY', 'BI_WEEKLY', 'MONTHLY'].includes(schedule)
          && userContext.availableFeatureFlags?.includes('PAD_AGREEMENTS')
          && (<PADLink />)}
      </OvForm>
      <Modal
        open={openCalendar}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClose={() => {
          setOpenCalendar(false);
        }}
      >
        <Box display="flex" flexDirection="column" position="relative">
          <LocalizationProvider locale={getLocale()} dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              label="yyyy-mm-dd"
              open
              displayStaticWrapperAs="mobile"
              value={Date.parse(dayjs(scheduledDate).local().format('YYYY-MM-DD'))}
              minDate={nextDay}
              onChange={(newValue) => setTempDate(dayjs(newValue).utc().format('YYYY-MM-DD'))}
              renderInput={(params) => (
                <TextField
                  sx={{ marginBottom: '32px' }}
                  variant="standard"
                  {...params}
                  fullWidth
                />
              )}
              mask="____-__-__"
            />
          </LocalizationProvider>
          <Box position="absolute" right="35px" bottom="35px" display="flex">
            <Typography variant="captionSectionerMedium" onClick={() => setOpenCalendar(false)} style={{ marginBottom: 0, marginRight: '15px', cursor: 'pointer' }}>{t('transfer:bankAccountDeposit.calendar.cancel')}</Typography>
            <Typography variant="captionSectionerMedium" onClick={() => confirmDate()} style={{ marginBottom: 0, cursor: 'pointer' }}>{t('transfer:bankAccountDeposit.calendar.confirm')}</Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

AddAutoInvestmentForm.defaultProps = {
  goToNamedStep: undefined,
  editableScheduledTransfer: undefined,
  subAccountId: '',
  goalId: '',
};

export default AddAutoInvestmentForm;
