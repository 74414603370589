import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  logEvent, Analytics, initializeAnalytics, setUserId,
} from 'firebase/analytics';
import {
  getBrowser,
  getDevice,
  getOS,
  getClientIpAddress,
  getOrganizationId,
} from './platformUtils';
import {
  userIdVar,
  ipAddressVar,
  isEmbedded,
} from './localVariables';
import { isLocalEnv } from './commonMethods';

interface postToGoogleRestApiProp {
  measurementId: string,
  browser: string,
  device: string,
  os: string,
  ipAddress: string,
  userId: string,
  organizationId: string,
  event: string,
  origin: string,
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_ANALYTICS_API_KEY,
  authDomain: process.env.REACT_APP_ANALYTICS_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ANALYTICS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ANALYTICS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ANALYTICS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ANALYTICS_APP_ID,
  measurementId: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID,
};

let app: FirebaseApp;
let analytics: Analytics;

const postToGoogleRestApi = (payload: postToGoogleRestApiProp): void => {
  fetch(
    `https://www.google-analytics.com/g/collect?v=2&tid=${payload.measurementId}&gtm=45je33d0&_p=1378190840&_dbg=1&_fid=c-SnBhi_Z8rQPC51xfC-Xv&cid=496293087.1678979546&ul=en-gb&sr=1440x900&uaa=arm&uab=64&uafvl=Chromium%3B110.0.5481.100%7CNot%2520A(Brand%3B24.0.0.0%7CGoogle%2520Chrome%3B110.0.5481.100&uamb=0&uam=&uap=macOS&uapv=12.5.0&uaw=0&_s=3&sid=1678979546&sct=1&seg=1&dl=${payload.origin}&dt=OneVest&en=${payload.event}&_ee=1&ep.origin=firebase&ep.Browser=${payload.browser}&ep.Device=${payload.device}&ep.OS=${payload.os}&ep.User_ID=${payload.userId}&ep.IP_Address=${payload.ipAddress}&ep.Organization_ID=${payload.organizationId}&_et=24822`,
    {
      method: 'POST',
      mode: 'no-cors',
      referrerPolicy: 'no-referrer',
      headers: {
        authority: 'www.google-analytics.com',
        origin: payload.origin,
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'no-cors',
        'sec-fetch-site': 'cross-site',
      },
    },
  ).then();
};

const sendAnalyticsEvent = async (eventName: string): Promise<void> => {
  if (!isLocalEnv) {
    await getClientIpAddress();
    const firebaseMetaData = {
      Browser: getBrowser(),
      Device: getDevice(),
      OS: getOS(),
      User_ID: userIdVar(),
      IP_Address: ipAddressVar(),
      Organization_ID: getOrganizationId(),
    };
    try {
      logEvent(
        analytics,
        eventName,
        {
          ...firebaseMetaData,
        },
      );
      if (isEmbedded()) {
        postToGoogleRestApi({
          measurementId: firebaseConfig?.measurementId ?? '',
          browser: firebaseMetaData.Browser,
          device: firebaseMetaData.Device,
          os: firebaseMetaData.OS,
          ipAddress: firebaseMetaData.IP_Address,
          userId: firebaseMetaData.User_ID,
          organizationId: firebaseMetaData.Organization_ID ?? '',
          event: eventName,
          origin: window.location.href,
        });
        window.parent.postMessage({ origin: 'ONEVEST_ANALYTICS', eventName, ...firebaseMetaData }, '*');
      }
    } catch (e) {
      console.log({ event: 'ERROR_LOGGING' });
    }
  }
};

const initFirebase = (): void => {
  if (!isLocalEnv) {
    app = initializeApp(firebaseConfig);
    analytics = initializeAnalytics(app, { config: { cookie_flags: 'max-age=7200;secure;samesite=none' } });
  }
};

const setAnalyticsUserId = (userId: string): void => {
  if (!isLocalEnv) {
    setUserId(analytics, userId);
  }
};

export { initFirebase, sendAnalyticsEvent, setAnalyticsUserId };

export const ovAnalyticsEvents = {
  onBoardingEnterEmailContinue: 'on_boarding_enter_email_continue',
  onBoardingIdentityContinue: 'on_boarding_identity_continue',
  onBoardingSelectAccountType: 'on_boarding_select_account_type',
  onBoardingReviewAgreementsView: 'on_boarding_review_agreements_view',
  onBoardingReviewAgreementsConfirm: 'on_boarding_review_agreements_confirm',
  onBoardingSelectGoalType: 'on_boarding_select_goal_type',
  onBoardingSelectGoalRiskTolerance: 'on_boarding_select_goal_risk_tolerance',
  onBoardingEnterTargetAmount: 'on_boarding_enter_target_amount',
  onBoardingSelectTimeHorizon: 'on_boarding_select_time_horizon',
  onBoardingEnterEmailVerification: 'on_boarding_enter_email_verification',
  onBoardingEnterPhoneContinue: 'on_boarding_enter_phone_continue',
  onBoardingEnterPhoneVerification: 'on_boarding_enter_phone_verification',
  onBoardingEnterAddressContinue: 'on_boarding_enter_address_continue',
  onBoardingAssumptionsContinue: 'on_boarding_assumptions_continue',
  onBoardingEnterDOBContinue: 'on_boarding_enter_DOB_continue',
  onBoardingEnterFieldOfStudy: 'on_boarding_enter_field_of_study',
  onBoardingEnterRoleAtCompany: 'on_boarding_enter_role_at_company',
  onBoardingSelectEmploymentStatus: 'on_boarding_select_employment_status',
  onBoardingSelectAnnualIncome: 'on_boarding_select_annual_income',
  onBoardingSelectDebt: 'on_boarding_select_debt',
  onBoardingSelectMaritalStatus: 'on_boarding_select_marital_status',
  onBoardingEnterAnnualDebtPayments: 'on_boarding_enter_annual_debt_payments',
  onBoardingEnterTotalDebt: 'on_boarding_enter_total_debt',
  onBoardingEnterFixedAssetsContinue: 'on_boarding_enter_fixed_assets_continue',
  onBoardingEnterLiquidAssetsContinue: 'on_boarding_enter_liquid_assets_continue',
  onBoardingNameContinue: 'on_boarding_name_continue',
  onBoardingInvestmentKnowledge: 'on_boarding_investment_knowledge',
  onBoardingSelectCareAboutMost: 'on_boarding_select_care_about_most',
  onBoardingSelectInvestmentLoss: 'on_boarding_select_investment_loss',
  onBoardingEnterSINContinue: 'on_boarding_enter_SIN_continue',
  homePageChart1WSelect: 'home_page_Chart_1W_select',
  homePageChart1MSelect: 'home_page_chart_1M_select',
  homePageChart3MSelect: 'home_page_chart_3M_select',
  homePageChart1YSelect: 'home_page_chart_1Y_select',
  homePageChartAllSelect: 'home_page_chart_ALL_select',
  homePageGoalsTabSelect: 'home_page_goals_tab_select',
  homePageAccountsTabSelect: 'home_page_accounts_tab_select',
  homePageHoldingsTabSelect: 'home_page_holdings_tab_select',
  homePageGoal: 'home_page_goal',
  accountPageInvestSelect: 'account_page_invest_select',
  accountPageWithdrawSelect: 'account_page_withdraw_select',
  accountPagePortfolioSelect: 'account_page_portfolio_select',
  accountPageAddNewPortfolioSelect: 'account_page_new_portfolio_select',
  accountPageEnableAutoInvestmentSelect: 'account_page_enable_auto_investment',
  accountPageViewAutoInvestmentSelect: 'account_page_view_auto_investments',
  accountPageViewAllTransactionsSelect: 'account_page_view_all_transactions',
  accountPageCloseAccountSelect: 'account_page_close_account_select',
  accountPageCloseAccountContinueSelect: 'account_page_close_account_continue',
  accountPageCloseAccountConfirmSelect: 'account_page_close_account_confirm',
  homePageInvestSelect: 'home_page_invest_select',
  goalPageInvestSelect: 'goal_page_invest_select',
  goalPageWithdrawSelect: 'goal_page_withdraw_select',
  goalPagePortfolioSelect: 'goal_page_portfolio_select',
  goalPageAddNewPortfolioSelect: 'goal_page_add_new_portfolio_select',
  goalPageAddTargetAmountSelect: 'goal_page_add_target_amount_select',
  goalPageInvestmentHistorySelect: 'goal_page_investment_history_select',
  goalPageEditGoalSelect: 'goal_page_edit_goal_select',
  goalPageCloseGoalSelect: 'goal_page_close_goal_select',
  goalPageEnableAutoInvestments: 'goal_page_enable_auto_investments',
  goalPageScheduledAutoInvestmentsSelect: 'goal_page_scheduled_auto_investments_select',
  investPageGoalSelect: 'invest_page_goal_select',
  investPagePortfolioSelect: 'invest_page_portfolio_select',
  investPageAmountSelect: 'invest_page_amount_select',
  investPageBankAccountSelect: 'invest_page_bank_account_select',
  investPageNewBankAccountSelect: 'invest_page_new_bank_account_select',
  investPageStartDateSelect: 'invest_page_start_date_select',
  investPageConfirmBankAccount: 'invest_page_confirm_bank_account',
  investPageConfirmExternalAccount: 'invest_page_confirm_external_account',
  investPageConfirmExistingPortfolio: 'invest_page_confirm_existing_portfolio',
  autoInvestPageCancelSelect: 'auto_invest_page_cancel_select',
  homePageInvestSelectToInvestPageNewBankAccountSelect: 'home_page_invest_select > invest_page_new_bank_account_select',
  homePageInvestSelectToInvestPageConfirmExistingPortfolio: 'home_page_invest_select > invest_page_confirm_existing_portfolio',
  homePageInvestSelectToInvestPageConfirmExternalAccount: 'home_page_invest_select > invest_page_confirm_external_account',
  goalPageInvestSelectToInvestPageNewBankAccountSelect: 'goal_page_invest_select > invest_page_new_bank_account_select',
  goalPageInvestSelectToInvestPageConfirmExistingPortfolio: 'goal_page_invest_select > invest_page_confirm_existing_portfolio',
  goalPageInvestSelectToInvestPageConfirmExternalAccount: 'goal_page_invest_select > invest_page_confirm_external_account',
  accountPageInvestSelectToInvestPageNewBankAccountSelect: 'account_page_invest_select > invest_page_new_bank_account_select',
  accountPageInvestSelectToInvestPageConfirmExistingPortfolio: 'account_page_invest_select > invest_page_confirm_existing_portfolio',
  accountPageInvestSelectToInvestPageConfirmExternalAccount: 'account_page_invest_select > invest_page_confirm_external_account',
  personaVerificationError: 'persona_verification_error',
  flinksBankAccountError: 'flinks_bank_account_error',
  onBoardingLetsGetToKnowYouPage1: 'on_boarding_lets_get_to_know_you_page_1',
  onBoardingLetsGetToKnowYouPage2: 'on_boarding_lets_get_to_know_you_page_2',
  onBoardingLetsGetToKnowYouPage3: 'on_boarding_lets_get_to_know_you_page_3',
  onBoardingLetsGetToKnowYouPage4: 'on_boarding_lets_get_to_know_you_page_4',
  investPageAddBankManuallySelect: 'invest_page_add_bank_manually_select',
  addNewBankPageAddAccountSelect: 'add_new_bank_page_add_account_select',
  addNewBankPageAddAccountAutomaticallySelect: 'add_new_bank_page_add_account_automatically_select',
  makeAnInvestmentPageTFSASelect: 'make_an_investment_page_TFSA_select',
  makeAnInvestmentPageRRSPSelect: 'make_an_investment_page_RRSP_select',
  makeAnInvestmentPageRESPSelect: 'make_an_investment_page_RESP_select',
  makeAnInvestmentPageCashSelect: 'make_an_investment_page_Cash_select',
  portfolioPageClosePortfolioConfirm: 'portfolio_page_close_portfolio_confirm',
  portfolioPageClosePortfolioCancel: 'portfolio_page_close_portfolio_cancel',
  portfolioPageHoldingsSelect: 'portfolio_page_holdings_select',
  portfolioPageRateOfReturnSelect: 'portfolio_page_rate_of_return_select',
  portfolioPageViewPerformanceSelect: 'portfolio_page_view_performance_select',
  portfolioPageThemeSelect: 'portfolio_page_theme_select',
  portfolioPageRiskAndReturnSelect: 'portfolio_page_risk_and_return_select',
  withdrawalPagePortfolioSelect: 'withdrawal_page_portfolio_select',
  withdrawalPageAmountContinue: 'withdrawal_page_amount_continue',
  withdrawalPageReasonSelect: 'withdrawal_page_reason_select',
  withdrawalPageBankAccountContinue: 'withdrawal_page_bank_account_continue',
  withdrawalPageStartDateContinue: 'withdrawal_page_start_date_continue',
  withdrawalPageReviewConfirm: 'withdrawal_page_review_confirm',
  addGoalGoalTypeSelect: 'add_goal_goal_type_select',
  addGoalTimeHorizonSelect: 'add_goal_time_horizon_select',
  addGoalEnterTargetAmountContinue: 'add_goal_enter_target_amount_continue',
  addGoalNoTargetAmountContinue: 'add_goal_no_target_amount_continue',
  addGoalRiskToleranceSelect: 'add_goal_risk_tolerance_select',
  addGoalPortfolioProjectionContinue: 'add_goal_portfolio_projection_continue',
  addGoalForecastDescriptionSelect: 'add_goal_forecast_description_select',
  addGoalInitialInvestmentSlider: 'add_goal_initial_investment_slider',
  addGoalMonthlyInvestmentSlider: 'add_goal_monthly_investment_slider',
  addGoalSelectAccountType: 'add_goal_select_account_type',
  addGoalReviewYourAgreementView: 'add_goal_review_your_agreement_view',
  addGoalReviewYourAgreementConfirm: 'add_goal_review_your_agreement_confirm',
  homePageProfileSelect: 'home_page_profile_select',
  profilePagePersonalDetailsSelect: 'profile_page_personal_details_select',
  profilePageDocumentsSelect: 'profile_page_documents_select',
  profilePageHelpSelect: 'profile_page_help_select',
  addBeneficiaryPageContinueButtonSelect: 'add_beneficiary_page_continue_button_select',
  addBeneficiaryAddressContinueButtonSelect: 'add_beneficiary_address_continue_button_select',
  addBeneficiaryDateOfBirthContinueButtonSelect: 'add_beneficiary_date_of_birth_continue_button_select',
  addBeneficiaryInProvinceSinceSelect: 'add_beneficiary_in_province_since_select',
  addBeneficiarySINNumberContinueButtonSelect: 'add_beneficiary_SIN_number_continue_button_select',
  addBeneficiaryGenderMaleButtonSelect: 'add_beneficiary_gender_male_button_select',
  addBeneficiaryGenderFemaleButtonSelect: 'add_beneficiary_gender_female_button_select',
  addBeneficiaryAffiliationTypeSelect: 'add_beneficiary_affiliation_type_select',
  addBeneficiaryAllocationSelect: 'add_beneficiary_allocation_continue_button_select',
  addBeneficiaryRelationChildButtonSelect: 'add_beneficiary_relation_child_button_select',
  addBeneficiaryRelationGrandchildButtonSelect: 'add_beneficiary_relation_grandchild_button_select',
  addBeneficiaryRelationSiblingButtonSelect: 'add_beneficiary_relation_sibling_button_select',
  reviewBeneficiaryPageContinueButtonSelect: 'review_beneficiary_page_continue_button_select',
  reviewBeneficiaryPageAddBeneficiaryButtonSelect: 'review_beneficiary_page_add_beneficiary_button_select',
  addBeneficiaryLegalGaurdianYesButtonSelect: 'add_beneficiary_legal_gaurdian_yes_button_select',
  addBeneficiaryLegalGaurdianNoButtonSelect: 'add_beneficiary_legal_gaurdian_no_button_select',
  addBeneficiaryPrimaryCaregiverYesButtonSelect: 'add_beneficiary_primary_caregiver_yes_button_select',
  addBeneficiaryPrimaryCaregiverNoButtonSelect: 'add_beneficiary_primary_caregiver_no_button_select',
  applyGrantsPageContinueButtonSelect: 'apply_grants_page_continue_button_select',
  rrifAccountTypePageRRIFSelect: 'RRIF_account_type_page_RRIF_select',
  rrifBeforeYouProceedIUnderstandButtonSelect: 'RRIF_before_you_proceed_i_understand_button_select',
  rrif_SpouseNamePageContinueButtonSelect: 'RRIF_spouse_name_page_continue_button_select',
  rrifSpouseEmailPageContinueButtonSelect: 'RRIF_spouse_email_page_continue_button_select',
  rrifSpouseAddressContinueButtonSelect: 'RRIF_spouse_address_continue_button_select',
  rrifSpouseDateOfBirthContinueButtonSelect: 'RRIF_spouse_date-of_birth_continue_button_select',
  rrifSpouseSinNumberContinueButtonSelect: 'RRIF_spouse_SIN_number_continue_button_select',
  rrifSpouseEmploymentSelect: 'RRIF_spouse_employment_select',
  rrifSpouseIndustryContinueButtonSelect: 'RRIF_spouse_industry_continue_button_select',
  rrifSpouseRoleContinueButtonSelect: 'RRIF_spouse_role_continue_button_select',
  rrifSpouseReviewInformationScreenContinueButtonSelect: 'RRIF_spouse_review_information_screen_continue_button_select',
  rrifThemeSelect: 'RRIF_theme_select',
  rrifThemeHoldingsContinueButtonSelect: 'RRIF_theme_holdings_continue_button_select',
  accountTypeAgreementsPageContinueButtonSelect: {
    RRSP: 'RRSP_agreements_page_continue_button_select',
    PERSONAL: 'PERSONAL_agreements_page_continue_button_select',
    TFSA: 'TFSA_agreements_page_continue_button_select',
    RESP: 'RESP_agreements_page_continue_button_select',
    RRIF: 'RRIF_agreements_page_continue_button_select',
  },
  rrifImportantInformationModalContinueButtonSelect: 'RRIF_important_information_modal_continue_button_select',
  rrifAnnualPaymentInstructionsPageContinueButtonSelect: 'RRIF_annual_payment_instructions_page_continue_button_select',
  rrifInformationSavedModalOkButtonSelect: 'RRIF_information_saved_modal_ok_button_select',
  rrifFinishAccountSetupModalFinishButtonSelect: 'RRIF_finish_account_setup_modal_finish_button_select',
  rrifAccountDetailsPageEditButtonSelect: 'RRIF_account_details_page_edit_button_select',
  rrifMakeAnInvestmentPageContinueButtonSelect: 'RRIF_make_an_investment_page_continue_button_select',
  documentsPageAddDocumentSelect: 'documents_page_add_document_select',
  documentsPageApplyFilterSelect: 'documents_page_apply_filter_select',
  addDocumentModalContinueButtonSelect: 'add_document_modal_continue_button_select',
};
