import { colors } from '../../../theme/colors';

export const styles = {
  containerWrapper: {
    paddingLeft: '0px',
    width: '100%',
    paddingRight: '0px',
    position: 'relative',
  },
  depositContainer: {
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top: '-6px',
  },
  depositInnerContainer: {
    typography: 'body1',
    backgroundColor: colors.transparent,
    padding: '0px !important',
    display: 'flex',
    flexDirection: 'column' as const,
    flexGrow: 1,
  },
  closeBox: {
    width: '26px',
    height: '26px',
    top: '-6px',
    marginRight: '24px',
    marginBottom: '22px',
    cursor: 'pointer',
    background: colors.backButtonBackground,
    borderRadius: '3.42105px',
  },
};
