/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { gql } from '@apollo/client';
import { auth0Logout } from './auth0Client';
import { removeFromLocalStorage, removeFromSessionStorage, setToLocalStorage } from './localStorage';
import {
  accessTokenVar, isLoggedInVar, isOnboardingCompleteVar, isSignUpCompleteVar, refreshTokenVar, userEmailVar, userIdVar,
} from './localVariables';

// refresh access token
const REFRESH_ACCESS_TOKEN = gql`
  mutation refreshAccessToken($input: RefreshAccessTokenInput!) {
    refreshAccessToken(input: $input) {
      token {
        accessToken
        expiresIn
        refreshToken
      }
    }
  }  
`;

interface Token {
  accessToken?: string,
  refreshToken?: string,
}

export const setAuthToken = (token: Token): void => {
  setToLocalStorage('token', token.accessToken as string);
  setToLocalStorage('refresh-token', token.refreshToken || '');
  accessTokenVar(token.accessToken);
  refreshTokenVar(token.refreshToken || '');
  isLoggedInVar(true);
};

export const signUserOut = (apolloClient: any): void => {
  // Evict and garbage-collect the cached user object
  apolloClient.cache.evict({});
  apolloClient.cache.gc();
  // Remove user details from localStorage
  removeFromSessionStorage('token');
  removeFromLocalStorage('token');
  removeFromLocalStorage('refresh-token');
  removeFromSessionStorage('path');
  removeFromSessionStorage('params');
  removeFromLocalStorage('suitabilityReview');
  removeFromLocalStorage('from-onboarding');

  // Set the logged-in status to false
  isLoggedInVar(false);
  accessTokenVar('');
  refreshTokenVar('');

  // Clear other variables
  isOnboardingCompleteVar(null);
  isSignUpCompleteVar(true);
  userIdVar('');
  userEmailVar('');

  auth0Logout();

  apolloClient.clearStore();
  window.location.reload();
};

export const refreshAuthToken = async (apolloClient: any): Promise<string> => {
  const refreshToken = refreshTokenVar();
  if (!refreshToken) {
    signUserOut(apolloClient);
    return '';
  }
  const response = await apolloClient.mutate({
    mutation: REFRESH_ACCESS_TOKEN,
    variables: { input: { refreshToken } },
  });

  if (response.data.refreshAccessToken.token.accessToken) {
    console.log({ event: 'ACCESS_TOKEN_REFRESHED' });
    const { token } = response.data.refreshAccessToken;
    setAuthToken(token);
    return token.accessToken;
  }
  signUserOut(apolloClient);
  return '';
};
