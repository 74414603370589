import {
  Typography,
  List,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import SearchInput from '../../common/inputs/searchInput';
import GeneralListItem from '../../common/lists/generalListItem';
import { countryList, Country, getFormattedCountry } from '../resources';
import OvForm from '../../common/wrappers/ovForm';

export interface ForeignTaxInformation {
  foreignTaxCountry: string,
  foreignTaxNumber: string,
}

export interface UserState {
  countryOfTaxResidence?: string,
  foreignTaxInformation?: ForeignTaxInformation[],
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
}

const ForeignTax = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [searchString, setSearchString] = useState('');
  const [userForeignTaxInformation, setUserForeignTaxInformation] = useState<ForeignTaxInformation[] | undefined>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [checkedState, setCheckedState] = useState(countryList.reduce((obj: any, c) => {
    // eslint-disable-next-line no-param-reassign
    obj[c.code] = (user.foreignTaxInformation?.find((f) => f.foreignTaxCountry === c.code) !== undefined);
    return obj;
  }, {}));
  // Since we are checking for countries user pays tax in, canada shouldn't be on this list.
  const [state, setState] = useState({
    sourceData: getFormattedCountry().filter((country) => country.code !== 'CA'),
    filterData: getFormattedCountry().filter((country) => country.code !== 'CA'),
  });
  const [clickedValue, setClickedValue] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterList = (e: any): void => {
    if (e.target) {
      const updatedList = state.sourceData.filter((item: Country) => (item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1));
      setState({ ...state, filterData: updatedList });
    }
  };

  const onContinueClick = (): void => {
    if (props.onContinue) props.onContinue();
  };

  useEffect(() => {
    setUserForeignTaxInformation(user.foreignTaxInformation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (item: Country): void => {
    if (!checkedState[item.code]) {
      const info = userForeignTaxInformation?.find((elem) => elem.foreignTaxCountry === item.code);
      updateUserState({
        ...user,
        countryOfTaxResidence: user.countryOfTaxResidence ?? item.code,
        foreignTaxInformation: [
          ...(user.foreignTaxInformation?.filter((f) => f.foreignTaxNumber !== '' && f.foreignTaxCountry !== item.code) ?? []).map((country) => ({
            foreignTaxCountry: country.foreignTaxCountry, foreignTaxNumber: country.foreignTaxNumber,
          })),
          (info ? { foreignTaxCountry: item.code, foreignTaxNumber: info.foreignTaxNumber } : { foreignTaxCountry: item.code, foreignTaxNumber: '' }),
        ],
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setCheckedState((prev: any) => {
        const newState = prev;
        newState[clickedValue] = false;
        newState[item.code] = true;
        return newState;
      });
      setSearchString(item.name);
      setClickedValue(item.code);
    } else {
      updateUserState({
        ...user,
        countryOfTaxResidence: user.countryOfTaxResidence ?? undefined,
        foreignTaxInformation: user.foreignTaxInformation?.filter((f) => f.foreignTaxCountry !== item.code) ?? [],
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setCheckedState((prev: any) => {
        const newState = prev;
        newState[item.code] = false;
        return newState;
      });
      setSearchString('');
      setClickedValue('');
    }
  };
  return (
    <OvForm
      onSubmit={onContinueClick}
      disableButton={!Object.values(checkedState).some((elem) => elem)}
      submitButton={false}
    >
      <Typography variant="heading2">{t('user:foreignTaxCountry.title')}</Typography>
      <Typography variant="paragraph3">{t('user:foreignTaxCountry.subTitle')}</Typography>
      <SearchInput
        label={t('user:foreignTaxCountry.label')}
        value={searchString}
        onChange={(event) => {
          setSearchString(event.target.value);
          filterList(event);
        }}
        onClear={() => {
          setSearchString('');
          setState({ ...state, filterData: getFormattedCountry() });
          setClickedValue('');
          updateUserState({ ...user, foreignTaxInformation: [] });
        }}
      />
      <List className="on-boarding-list no-scrollbar">
        {state.filterData.map((item: Country) => (
          <GeneralListItem
            prefixIcon={item.icon}
            prefixIconStyle={{ width: 20, height: 14 }}
            text={item.name}
            key={item.name}
            hasRadioField
            checked={checkedState[item.code]}
            onClick={() => {
              if (item) handleClick(item);
            }}
          />
        ))}
      </List>
    </OvForm>
  );
};
ForeignTax.defaultProps = {
  onContinue: undefined,
};

export default ForeignTax;
