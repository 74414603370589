/* eslint-disable max-len */
import {
  Box,
  Button,
  Link,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FormEvent, useContext, useRef, useState,
} from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { useMutation } from '@apollo/client';
import List from '@mui/material/List';
import ToggleListItem from '../../common/lists/toggleListItem';
import { UPDATE_USER } from '../graphql';
import { AlternativeSteps, UserState } from './completeAssumptionsWizard';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';
import FeedbackModal from '../../common/wrappers/modals/ovFeedbackModal';
import { colors } from '../../../theme/colors';
import { countryList } from '../resources';
import { UserContext } from '../../../providers/userContextProvider';

export interface ForeignTaxInformation {
  foreignTaxCountry: string,
  foreignTaxNumber: string,
}

export interface AssumptionRefType {
  powerOfAttorneyGranted: boolean,
  isMemberOfIiroc: boolean,
  isOfficerOfPublicCompany: boolean,
  isOwnerOfPublicCompany: boolean,
  politicallyExposedDomesticPerson: boolean,
  headOfInternationalOrganization: boolean,
  politicallyExposedForeignPerson: boolean,
  closeAssociateOfPEP: boolean,
  citizenships: string[],
  foreignTaxInformation: ForeignTaxInformation[],
  countryOfTaxResidence?: string
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  setAlternativeSteps: (att: AlternativeSteps) => void,
  onContinue?: (step?: string) => void,
  goToNamedStep?: (step: string) => void,
  cached?: boolean,
  title?: string,
  subTitle?: string
}
const styles = {
  subText: {
    marginBottom: '20px',
    textAlign: 'left',
  },
  headingText: {
    marginBottom: '16px',
    textAlign: 'left',
  },
  assumprionButton: {
    width: '100%',
    marginTop: '53px',
    position: 'relative',
    bottom: 0,
    left: 0,
    right: 0,
  },
  infoIcon: {
    position: 'relative',
    fontSize: '16px',
    ml: '2px',
    top: '3px',
    cursor: 'pointer',
    color: colors.gray80,
  },
};
interface AssumptionType {
  name: string,
  status: boolean,
  key: string,
  infoObject?: {
    icon: JSX.Element,
  },
}
const Assumption = ({
  user,
  updateUserState,
  setAlternativeSteps,
  onContinue,
  goToNamedStep,
  cached,
  title,
  subTitle,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user', 'assumptions']);
  const { userContext } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [showPOAMessage, setShowPOAMessage] = useState(false);
  const [showIIROCMessage, setShowIIROCMessage] = useState(false);
  const [showUsPersonMessage, setShowUsPersonMessage] = useState(false);
  const [showPublicCompanyMessage, setShowPublicCompanyMessage] = useState(false);
  const [showPublicCompanyOwnerMessage, setShowPublicCompanyOwnerMessage] = useState(false);
  const payTaxInAnotherCountry = useRef(false);
  const assumptionsRef = useRef<AssumptionRefType>({
    powerOfAttorneyGranted: false,
    isMemberOfIiroc: false,
    isOfficerOfPublicCompany: false,
    isOwnerOfPublicCompany: false,
    politicallyExposedDomesticPerson: false,
    headOfInternationalOrganization: false,
    politicallyExposedForeignPerson: false,
    closeAssociateOfPEP: false,
    citizenships: ['CA'],
    foreignTaxInformation: [],
    countryOfTaxResidence: 'CA',
  });

  const getCountryNameFromCode = (code: string): string => countryList.find((country) => country.code === code)?.name ?? '';

  const parseCitizenship = (): string => {
    if (user.citizenships === undefined || user.citizenships.length === 0 || user.citizenships.includes('CA')) {
      return t('assumptions:isCanadianPersonQ1');
    }
    return t('assumptions:isCanadianPersonQ2', { countries: user.citizenships.map(getCountryNameFromCode).join(', ') });
  };
  const parseTaxCountries = (): string => {
    if (user.foreignTaxInformation === undefined || user.foreignTaxInformation.length === 0) {
      return t('assumptions:payTaxInAnotherCountry');
    }
    if (user.countryOfTaxResidence === 'CA' && user.foreignTaxInformation.length > 0) {
      return t('assumptions:payTaxInAnotherCountryQ3', { countries: user.foreignTaxInformation.map((info) => getCountryNameFromCode(info.foreignTaxCountry)).join(', ') });
    }
    return t('assumptions:payTaxInAnotherCountryQ2', { countries: user.foreignTaxInformation.map((info) => getCountryNameFromCode(info.foreignTaxCountry)).join(', ') });
  };
  const hasCompletedUsTax = (): boolean => {
    const found = user.foreignTaxInformation?.find((tax) => tax?.foreignTaxCountry === 'US' && tax.foreignTaxNumber);
    return !!found;
  };
  const removeCitizenship = (countryCodes: string[]): string[] => [...(assumptionsRef.current.citizenships?.filter((countryCode) => !countryCodes.includes(countryCode)) ?? [])];
  const [showAdditionalInfoDialog, setShowAdditionalInfoDialog] = useState(false);
  const [assumptions, setAssumptions] = useState<AssumptionType[]>([
    {
      name: parseCitizenship(),
      status: true,
      key: 'isCanadianPerson',
    },
    {
      name: t('assumptions:isUsPerson'),
      status: user.citizenships === undefined || user.citizenships.length === 0 || !user.citizenships.includes('US'),
      key: 'isUsPerson',
      infoObject: {
        icon: <HelpIcon onClick={() => setShowUsPersonMessage(true)} sx={styles.infoIcon} />,
      },
    },
    {
      name: parseTaxCountries(),
      status: true,
      key: 'payTaxInAnotherCountry',
    },
    {
      name: t('assumptions:powerOfAttorneyGranted'),
      status: !user.powerOfAttorneyGranted,
      key: 'powerOfAttorneyGranted',
      infoObject: {
        icon: <HelpIcon onClick={() => setShowPOAMessage(true)} sx={styles.infoIcon} />,
      },
    },
    {
      name: t('assumptions:isMemberOfIiroc'),
      status: !user.isMemberOfIiroc,
      key: 'isMemberOfIiroc',
      infoObject: {
        icon: <HelpIcon onClick={() => setShowIIROCMessage(true)} sx={styles.infoIcon} />,
      },
    },
    {
      name: t('assumptions:isOfficerOfPublicCompany'),
      status: !user.isOfficerOfPublicCompany,
      key: 'isOfficerOfPublicCompany',
      infoObject: {
        icon: <HelpIcon onClick={() => setShowPublicCompanyMessage(true)} sx={styles.infoIcon} />,
      },
    },
    {
      name: t('assumptions:isOwnerOfPublicCompany'),
      status: !user.isOwnerOfPublicCompany,
      key: 'isOwnerOfPublicCompany',
      infoObject: {
        icon: <HelpIcon onClick={() => setShowPublicCompanyOwnerMessage(true)} sx={styles.infoIcon} />,
      },
    },
    {
      name: t('assumptions:isPepRelated'),
      status: !user.politicallyExposedDomesticPerson || !user.politicallyExposedForeignPerson
        || !user.headOfInternationalOrganization || !user.closeAssociateOfPEP,
      key: 'isPepRelated',
      infoObject: {
        icon: <HelpIcon onClick={() => setShowAdditionalInfoDialog(true)} sx={styles.infoIcon} />,
      },
    },
  ]);

  const defineEditButton = (assumption: AssumptionType): { showEditButton: boolean, buttonText: string, onClick?: () => void } | undefined => {
    if (assumption.key === 'isCanadianPerson') {
      return {
        showEditButton: user.citizenships !== undefined && ((user.citizenships.length === 1 && !user.citizenships.includes('CA')) || user.citizenships.length > 1),
        buttonText: t('assumptions:editButton'),
        onClick: (goToNamedStep ? () => {
          setAlternativeSteps({
            isCitizenshipStepRequired: true,
            isForeignTaxStepRequired: false,
          });
          goToNamedStep('Citizenship');
        } : undefined),
      };
    }
    if (assumption.key === 'payTaxInAnotherCountry') {
      return {
        showEditButton: user.foreignTaxInformation !== undefined && user.foreignTaxInformation.length > 0,
        buttonText: t('assumptions:editButton'),
        onClick: (goToNamedStep ? () => {
          setAlternativeSteps({
            isCitizenshipStepRequired: false,
            isForeignTaxStepRequired: true,
          });
          goToNamedStep('CountryOfTaxResidence');
        } : undefined),
      };
    }
    return undefined;
  };
  const assumptionsOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'ASSUMPTIONS_UPDATED', assumptions: assumptionsRef.current });
    updateUserState({
      ...user,
      ...assumptionsRef.current,
      incompleteFields: data.updateUser.user.incompleteFields,
    });

    setAlternativeSteps({
      isCitizenshipStepRequired: assumptionsRef.current.citizenships.length === 0,
      isForeignTaxStepRequired: payTaxInAnotherCountry.current,
    });
    setLoading(false);
    // Define next step based on selected assumptions
    if (!assumptions.find((assumption) => assumption.key === 'isCanadianPerson')?.status ?? true) {
      if (goToNamedStep) goToNamedStep('Citizenship');
    } else if (payTaxInAnotherCountry.current) {
      if (goToNamedStep) goToNamedStep('CountryOfTaxResidence');
    } else if (assumptionsRef.current.citizenships.includes('US') && !hasCompletedUsTax()) {
      updateUserState({
        ...user,
        ...assumptionsRef.current,
        foreignTaxInformation: [
          ...(user.foreignTaxInformation?.filter((f) => f.foreignTaxNumber !== '' && f.foreignTaxCountry !== 'US') ?? []),
          { foreignTaxCountry: 'US', foreignTaxNumber: '' },
        ],
      });// preselect taxCountry to US
      if (goToNamedStep) goToNamedStep('ForeignTaxNumber');
    } else if (onContinue) onContinue();
  };
  const handleCachedData = (): void => {
    updateUserState({
      ...assumptionsRef.current,
    });
    setAlternativeSteps({
      isCitizenshipStepRequired: assumptionsRef.current.citizenships.length === 0,
      isForeignTaxStepRequired: payTaxInAnotherCountry.current,
    });
    setLoading(false);
    // Define next step based on selected assumptions
    if (!assumptions.find((assumption) => assumption.key === 'isCanadianPerson')?.status ?? true) {
      if (goToNamedStep) goToNamedStep('Citizenship');
    } else if (payTaxInAnotherCountry.current) {
      if (goToNamedStep) goToNamedStep('CountryOfTaxResidence');
    } else if (assumptionsRef.current.citizenships.includes('US') && !hasCompletedUsTax()) {
      updateUserState({
        ...user,
        ...assumptionsRef.current,
        foreignTaxInformation: [
          ...(user.foreignTaxInformation?.filter((f) => f.foreignTaxNumber !== '' && f.foreignTaxCountry !== 'US') ?? []),
          { foreignTaxCountry: 'US', foreignTaxNumber: '' },
        ],
      });// preselect taxCountry to US
      if (goToNamedStep) goToNamedStep('ForeignTaxNumber');
    } else if (onContinue) onContinue();
  };
  const [updateAssumptions] = useMutation(UPDATE_USER, {
    variables: {
      input: { ...assumptionsRef.current },
    },
    onCompleted: assumptionsOnCompleted,
    onError: () => setLoading(false),
  });

  const onContinueClick = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingAssumptionsContinue).then();
    assumptions.forEach((item) => {
      if (item.key === 'isCanadianPerson') {
        if (!item.status) {
          assumptionsRef.current.citizenships = [];
        } else if (user.citizenships && user.citizenships.length > 0) {
          assumptionsRef.current.citizenships = user.citizenships;
        }
      }
      if (item.key === 'isUsPerson') {
        assumptionsRef.current.citizenships = !item.status ? [...assumptionsRef.current.citizenships, 'US'] : removeCitizenship(['US']);
      }
      if (item.key === 'isMemberOfIiroc') {
        assumptionsRef.current.isMemberOfIiroc = !item.status;
      }
      if (item.key === 'isOfficerOfPublicCompany') {
        assumptionsRef.current.isOfficerOfPublicCompany = !item.status;
      }
      if (item.key === 'isOwnerOfPublicCompany') {
        assumptionsRef.current.isOwnerOfPublicCompany = !item.status;
      }
      if (item.key === 'powerOfAttorneyGranted') {
        assumptionsRef.current.powerOfAttorneyGranted = !item.status;
      }
      if (item.key === 'payTaxInAnotherCountry') {
        payTaxInAnotherCountry.current = !item.status;
        assumptionsRef.current.foreignTaxInformation = user.foreignTaxInformation ?? [];
        if (!item.status) {
          assumptionsRef.current.countryOfTaxResidence = undefined;
        } else if (user.countryOfTaxResidence) {
          assumptionsRef.current.countryOfTaxResidence = user.countryOfTaxResidence;
        }
      }
      if (item.key === 'isPepRelated') {
        assumptionsRef.current.politicallyExposedDomesticPerson = !item.status;
        assumptionsRef.current.headOfInternationalOrganization = !item.status;
        assumptionsRef.current.politicallyExposedForeignPerson = !item.status;
        assumptionsRef.current.closeAssociateOfPEP = !item.status;
      }
    });
    if (cached) {
      handleCachedData();
    } else {
      setLoading(true);
      updateAssumptions({
        variables: {
          input: {
            ...assumptionsRef.current,
            foreignTaxInformation: assumptionsRef.current.foreignTaxInformation.map((taxInfo) => ({
              foreignTaxCountry: taxInfo.foreignTaxCountry,
              foreignTaxNumber: taxInfo.foreignTaxNumber,
            })),
          },
        },
      }).then();
    }
  };

  const handleToggle = (seletedValue: AssumptionType) => () => {
    const newList = assumptions.map((item) => {
      if (item.name === seletedValue.name) {
        const updatedItem = {
          ...item,
          status: !seletedValue.status,
        };
        return updatedItem;
      }
      return item;
    });
    setAssumptions(newList);
  };
  const showAdditionalInfo = (key: string): void => {
    if (key === 'isPepRelated') {
      setShowAdditionalInfoDialog(true);
    }
  };
  const closeAdditionalInfoModal = (): void => {
    setShowAdditionalInfoDialog(false);
  };
  if (showUsPersonMessage) {
    return (
      <FeedbackModal
        open={showUsPersonMessage}
        onClose={() => setShowUsPersonMessage(false)}
        component={(
          <Box>
            <Typography variant="headingMain" sx={styles.headingText}>
              {t('user:assumptions.usPersonModal.title')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.headingText}>
              {t('user:assumptions.usPersonModal.subTitle')}
            </Typography>
            <ul>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {t('user:assumptions.usPersonModal.criterias.one')}
                </Typography>
              </li>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {t('user:assumptions.usPersonModal.criterias.two')}
                </Typography>
              </li>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {`${t('user:assumptions.usPersonModal.criterias.three')}${['picton', 'pmam'].includes(userContext.organizationKey ?? '') ? `. ${t('user:assumptions.usPersonModal.criterias.threeComplement')}` : ''}`}
                </Typography>
              </li>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {`${t('user:assumptions.usPersonModal.criterias.four')}${['picton', 'pmam'].includes(userContext.organizationKey ?? '') ? `. ${t('user:assumptions.usPersonModal.criterias.fourComplement')}` : ''}`}
                </Typography>
              </li>
            </ul>
            <Box sx={styles.assumprionButton}>
              <Button variant="primary-large" onClick={() => setShowUsPersonMessage(false)} sx={{ marginBottom: '5px' }} data-testid="close-assumption-modal-button">{t('base:button.gotIt')}</Button>
            </Box>
          </Box>
        )}
      />
    );
  }
  if (showPOAMessage) {
    return (
      <FeedbackModal
        open={showPOAMessage}
        onClose={() => setShowPOAMessage(false)}
        component={(
          <Box>
            <Typography variant="headingMain" sx={styles.headingText}>
              {t('user:assumptions.poaModal.title')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.poaModal.paragraphOne.sectionOne')}
              <u>{t('user:assumptions.poaModal.sectionTitle')}</u>
              {t('user:assumptions.poaModal.paragraphOne.sectionTwo')}
            </Typography>
            <Box sx={styles.assumprionButton}>
              <Button variant="primary-large" onClick={() => setShowPOAMessage(false)} sx={{ marginBottom: '5px' }} data-testid="close-assumption-modal-button">{t('base:button.gotIt')}</Button>
            </Box>
          </Box>
        )}
      />
    );
  }
  if (showIIROCMessage) {
    return (
      <FeedbackModal
        open={showIIROCMessage}
        onClose={() => setShowIIROCMessage(false)}
        component={(
          <Box>
            <Typography variant="headingMain" sx={styles.headingText}>
              {t('user:assumptions.iIROCModal.title')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.iIROCModal.paragraphOne.sectionOne')}
              <u>{t('user:assumptions.iIROCModal.paragraphOne.sectionTitle')}</u>
              {t('user:assumptions.iIROCModal.paragraphOne.sectionTwo')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.iIROCModal.paragraphTwo.sectionOne')}
              <u>{t('user:assumptions.iIROCModal.paragraphTwo.sectionTitle')}</u>
              {t('user:assumptions.iIROCModal.paragraphTwo.sectionTwo')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.iIROCModal.paragraphThree.sectionOne')}
              <u>{t('user:assumptions.iIROCModal.paragraphThree.sectionTitle')}</u>
              {t('user:assumptions.iIROCModal.paragraphThree.sectionTwo')}
            </Typography>
            <Box sx={styles.assumprionButton}>
              <Button variant="primary-large" onClick={() => setShowIIROCMessage(false)} sx={{ marginBottom: '5px' }} data-testid="close-assumption-modal-button">{t('base:button.gotIt')}</Button>
            </Box>
          </Box>
        )}
      />
    );
  }
  if (showPublicCompanyMessage) {
    return (
      <FeedbackModal
        open={showPublicCompanyMessage}
        onClose={() => setShowPublicCompanyMessage(false)}
        component={(
          <Box>
            <Typography variant="headingMain" sx={styles.headingText}>
              {t('user:assumptions.publicCompanyModal.title')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.headingText}>
              {t('user:assumptions.publicCompanyModal.paragraphTitle')}
            </Typography>
            <ul>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {t('user:assumptions.publicCompanyModal.criterias.one')}
                </Typography>
              </li>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {t('user:assumptions.publicCompanyModal.criterias.two')}
                </Typography>
              </li>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {t('user:assumptions.publicCompanyModal.criterias.three')}
                </Typography>
              </li>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {t('user:assumptions.publicCompanyModal.criterias.four')}
                </Typography>
              </li>
            </ul>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.publicCompanyModal.paragraphOne.sectionOne')}
              <u>{t('user:assumptions.publicCompanyModal.sectionTitle')}</u>
              {t('user:assumptions.publicCompanyModal.paragraphOne.sectionTwo')}
            </Typography>
            <Box sx={styles.assumprionButton}>
              <Button variant="primary-large" onClick={() => setShowPublicCompanyMessage(false)} sx={{ marginBottom: '5px' }} data-testid="close-assumption-modal-button">{t('base:button.gotIt')}</Button>
            </Box>
          </Box>
        )}
      />
    );
  }
  if (showPublicCompanyOwnerMessage) {
    return (
      <FeedbackModal
        open={showPublicCompanyOwnerMessage}
        onClose={() => setShowPublicCompanyOwnerMessage(false)}
        component={(
          <Box>
            <Typography variant="headingMain" sx={styles.headingText}>
              {t('user:assumptions.publicCompanyOwnerModal.title')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.headingText}>
              {t('user:assumptions.publicCompanyOwnerModal.paragraphTitle')}
            </Typography>
            <ul>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {t('user:assumptions.publicCompanyOwnerModal.criterias.one')}
                </Typography>
              </li>
              <li>
                <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                  {t('user:assumptions.publicCompanyOwnerModal.criterias.two')}
                </Typography>
              </li>
            </ul>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.publicCompanyOwnerModal.paragraphOne.sectionOne')}
              <u>{t('user:assumptions.publicCompanyOwnerModal.sectionTitle')}</u>
              {t('user:assumptions.publicCompanyOwnerModal.paragraphOne.sectionTwo')}
            </Typography>
            <Box sx={styles.assumprionButton}>
              <Button variant="primary-large" onClick={() => setShowPublicCompanyOwnerMessage(false)} sx={{ marginBottom: '5px' }} data-testid="close-assumption-modal-button">{t('base:button.gotIt')}</Button>
            </Box>
          </Box>
        )}
      />
    );
  }
  if (showAdditionalInfoDialog) {
    return (
      <FeedbackModal
        open={showAdditionalInfoDialog}
        onClose={closeAdditionalInfoModal}
        component={(
          <Box>
            <Typography variant="headingMain" sx={styles.headingText}>
              {t('user:assumptions.modal.title')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.modal.subTitleOne.sectionOne')}
              <u>{t('user:assumptions.modal.subTitleOne.sectionTwo')}</u>
              {t('user:assumptions.modal.subTitleOne.sectionThree')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.modal.subTitleTwo.sectionOne')}
              <u>{t('user:assumptions.modal.subTitleTwo.sectionTwo')}</u>
              {t('user:assumptions.modal.subTitleTwo.sectionThree')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              <Link href="https://onevest.zendesk.com/hc/en-us/articles/6057383847316-Information-for-Politically-Exposed-Persons-PEP-" underline="always" target="_blank">
                {t('user:assumptions.modal.moreDetailsSection.sectionOne')}
              </Link>
              {t('user:assumptions.modal.moreDetailsSection.sectionTwo')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.modal.familyMember.sectionOne')}
              <u>{t('user:assumptions.modal.familyMember.sectionTitle')}</u>
              {t('user:assumptions.modal.familyMember.sectionTwo')}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {t('user:assumptions.modal.closeAssociate.sectionOne')}
              <u>{t('user:assumptions.modal.closeAssociate.sectionTitle')}</u>
              {t('user:assumptions.modal.closeAssociate.sectionTwo')}
            </Typography>
            <Box sx={styles.assumprionButton}>
              <Button variant="primary-large" onClick={closeAdditionalInfoModal} sx={{ marginBottom: '5px' }} data-testid="close-assumption-modal-button">{t('base:button.gotIt')}</Button>
            </Box>
          </Box>
        )}
      />
    );
  }
  return (
    <OvForm loading={loading} onSubmit={onContinueClick}>
      <Typography variant="heading2">{title ?? t('user:assumptions.title')}</Typography>
      <Typography variant="paragraph3">{subTitle ?? t('user:assumptions.subTitle')}</Typography>
      <List className="assumption">
        {assumptions.map((value: AssumptionType) => (
          <ToggleListItem
            text={value.name}
            key={value.key}
            onChange={
              handleToggle(value)
            }
            onClick={
              () => showAdditionalInfo(value.key)
            }
            hasLabel
            checked={value.status}
            infoObject={value.infoObject}
            editButton={defineEditButton(value)}
          />
        ))}
      </List>
    </OvForm>
  );
};

Assumption.defaultProps = {
  onContinue: undefined,
  goToNamedStep: undefined,
  cached: false,
  title: undefined,
  subTitle: undefined,
};

export default Assumption;
