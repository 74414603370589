/* eslint-disable  no-nested-ternary */
import {
  Box, Typography, Button, List,
} from '@mui/material';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface ButtonCardListItemProps {
  prefixIcon?: string,
  header: string,
  description: string,
  hasCheckField?: boolean,
  suffixText?: string,
  checked?: boolean,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  isSelected?: boolean,
}
const ButtonCardListItem = ({ ...props }: ButtonCardListItemProps): JSX.Element => {
  const { colors } = useContextTheme();
  return (
    <Button
      variant="button-list-item"
      style={{
        minHeight: '69px',
        alignItems: 'start',
        padding: '12px 12px 10px 12px',
        backgroundColor: props.isSelected ? colors.buttonItemBackground : colors.baseComponentBackground,
      }}
      onClick={props.onClick}>
      <Box display="flex" flexDirection="row" alignItems="start">
        {props.prefixIcon ? <img src={props.prefixIcon} alt="icon" width={40} /> : null}
        <Box ml={props.prefixIcon ? 1.2 : 0} flexDirection="column" alignItems="start" textAlign="left">
          <Typography variant="heading4">{ props.header }</Typography>
          <Typography variant="paragraph3" style={{ fontSize: '12px', marginBottom: '0px' }}>{ props.description }</Typography>
        </Box>
      </Box>
    </Button>
  );
};

ButtonCardListItem.defaultProps = {
  prefixIcon: null,
  hasCheckField: false,
  suffixText: null,
  checked: false,
  isSelected: undefined,
};

export const ButtonCardList: React.FC = ({ children }) => (
  <List sx={{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '10px',
  }}>
    {children}
  </List>
);

export default ButtonCardListItem;
