import { Typography } from '@mui/material';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import PhoneInput from '../common/inputs/phoneInput';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import HelpLink from './helpLink';

export interface UserState {
  phone?: string,
  phoneFromPartner?: string,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  texts?: { title?: string, subTitle?: string },
  displayHelpLink?: boolean,
}

const Phone = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'signUp']);
  const [validState, setValidateState] = useState(true);

  const checkFieldsValidity = async (): Promise<boolean> => {
    const phoneValidity = await yup.string()
      .required()
      .min(10)
      .isValid(user.phone ?? user.phoneFromPartner);
    setValidateState(phoneValidity);
    return phoneValidity;
  };

  const onContinueClick = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingEnterPhoneContinue).then();
    if (!await checkFieldsValidity()) return;
    if (props.onContinue) props.onContinue();
  };

  return (
    <OvForm onSubmit={onContinueClick}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: '1 0 auto' }}>
          <Typography variant="heading2" style={{ marginBottom: 16 }}>{props.texts?.title ?? t('signUp:phone.title')}</Typography>
          <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{props.texts?.subTitle ?? t('signUp:phone.subTitle')}</Typography>
          <PhoneInput error={!validState} label={t('signUp:phone.label')} style={{ marginBottom: 32 }} value={user.phone ?? user.phoneFromPartner} onChange={(value) => updateUserState({ ...user, phone: value })} />
        </div>
        {props.displayHelpLink ? <HelpLink /> : null}
      </div>
    </OvForm>
  );
};

Phone.defaultProps = {
  onContinue: undefined,
  texts: undefined,
  displayHelpLink: false,
};

export default Phone;
