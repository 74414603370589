import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import timerImage from '../../../assets/images/timer.svg';
import OvForm from '../wrappers/ovForm';
import { signUserOut } from '../../../utils/authToken';
import { setToLocalStorage } from '../../../utils/localStorage';
import FeedbackModal from '../wrappers/modals/ovFeedbackModal';

export interface SessionTimeOutModalProps {
  open: boolean,
  onLogout: () => void,
  stayLoggedIn: () => void,
  onSessionTimeOut: () => void,
}

const InactiveLayoutModal = ({
  open, onLogout, stayLoggedIn, onSessionTimeOut,
}: SessionTimeOutModalProps): JSX.Element => {
  const client = useApolloClient();
  const { t } = useTranslation(['base']);
  const [duration, setDuration] = useState(30);
  const ticker = useRef<NodeJS.Timer | undefined>(undefined);
  const startTimer = (): void => {
    ticker.current = setInterval(() => {
      setDuration((prevState) => prevState - 1);
    }, 1000);
  };
  const logout = (): void => {
    signUserOut(client);
    onLogout();
  };
  useEffect(() => {
    if (duration <= 0) {
      if (ticker.current) {
        clearInterval(ticker.current);
      }
      setToLocalStorage('inactivity', 'true');
      signUserOut(client);
      onSessionTimeOut();
      setDuration(30);
    }
  }, [duration, onSessionTimeOut, client]);
  useEffect(() => {
    if (open) {
      startTimer();
    }
  }, [open]);
  const handleClose = (): void => {
    if (ticker.current) {
      clearInterval(ticker.current);
    }
    stayLoggedIn();
    setDuration(30);
  };
  const onStayLoggedIn = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleClose();
  };
  return (
    <FeedbackModal
      open={open}
      onClose={handleClose}
      component={(
        <Box>
          <OvForm
            hasButton
            buttonText={t('base:inactiveModal.prefixButton')}
            onSubmit={onStayLoggedIn}
            suffixButton={{
              onClick: () => logout(),
              text: t('base:inactiveModal.suffixButton'),
              variant: 'secondary-large',
              dataTestId: 'log-out-button',
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" style={{ marginBottom: '32px' }}>
              <img src={timerImage} alt="timer" />
              <Typography variant="headingMain" style={{ marginBottom: 0 }}>{t('base:inactiveModal.headingText')}</Typography>
              <Typography variant="paragraphMain" style={{ marginBottom: 0, textAlign: 'center' }}>
                {t('base:inactiveModal.bodyText1')}
                <span style={{ fontWeight: 700, marginLeft: '3px' }}>{duration}</span>
                <span style={{ fontWeight: 700, marginLeft: '3px' }}>{t('base:inactiveModal.bodyText2')}</span>
              </Typography>
            </Box>
          </OvForm>
        </Box>
      )}
    />
  );
};
export default InactiveLayoutModal;
