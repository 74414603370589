import StepWizard from 'react-step-wizard';
import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import WithStepWizard, { StepProps } from '../common/wrappers/withStepWizard';
import {
  GoalRiskLevels, GoalTypes,
} from './resources';
import TrackingBar from '../common/headers/trackingBar';
import ViewGoal from './viewGoal';
import CreateGoalWizard from './createGoalWizard';
import { ANNUAL_SUITABILITY_COMPLETED } from '../suitability-review/graphql';
import { userIdVar } from '../../utils/localVariables';
import { UserContext } from '../../providers/userContextProvider';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';

export interface GoalRef {
  id?: string,
  type?: GoalTypes,
  name?: string,
  state?: string,
  timeHorizon?: string
  targetAmountCents?: number,
  riskQuestion1?: GoalRiskLevels,
  suggestedFinancialProduct?: {
    forecastedRateOfReturn?: number,
    forecastedStandardDeviation?: number,
  },
}
interface Props {
  onContinue?: () => void,
  stepProps?: StepProps,
  stepTitle?: string,
  onStepChanged?: (step: number) => void,
  goals: GoalRef[],

}
const UpdateMigratedGoalsWizard = ({
  onContinue, stepProps, onStepChanged, goals, stepTitle,
}: Props): JSX.Element => {
  const { userContext, setUserContext } = useContext(UserContext);
  const ViewGoalStep = WithStepWizard(ViewGoal);
  const [loading, setLoading] = useState(false);
  const CreateGoalWizardStep = WithStepWizard(CreateGoalWizard);
  const isLastItemInArray = (arrayLength: number, index: number): boolean => arrayLength === (index + 1);
  const onLastSuitabilityReviewUpdated = (data: { annualSuitabilityCompleted: { user: { lastSuitabilityReviewAt: string } } }): void => {
    setUserContext({ ...userContext, lastSuitabilityReviewAt: data.annualSuitabilityCompleted.user.lastSuitabilityReviewAt });
    if (onContinue) onContinue();
    setLoading(false);
  };

  const [updateSuitabilityReview] = useMutation(ANNUAL_SUITABILITY_COMPLETED, {
    variables: {
      userId: userIdVar(),
    },
    onCompleted: onLastSuitabilityReviewUpdated,
    onError: () => setLoading(false),
  });

  const shouldUpdateSuitabilityReview = (): void => {
    setLoading(true);
    updateSuitabilityReview().then();
  };

  const steps = goals.reduce((acc, goal, index) => [
    ...acc,
    <ViewGoalStep key={`viewGoal-${goal.id}`} stepName="viewGoal" stepTitle={stepTitle} currentGoal={goal} />,
    <CreateGoalWizardStep key={`updateGoal-${goal.id}`} stepName="updateGoal" goalName={goal.name} fromBulkUpload hideTrackingBar goalId={goal.id} action={isLastItemInArray(goals.length, index) ? () => shouldUpdateSuitabilityReview() : undefined} />,
  ], [] as JSX.Element[]);
  return (
    loading ? (<OvLoadingIndicator />)
      : (
        <StepWizard
          instance={undefined}
          isLazyMount
          transitions={{}}
          onStepChange={(stepChange: {
            previousStep: number
            activeStep: number
          }) => {
            if (onStepChanged) {
              onStepChanged(stepChange.activeStep);
            }
          }}
          className="ov-step-wizard"
          nav={(
            <TrackingBar steps={steps} baselineStepProps={stepProps} />
          )}
        >
          {steps}
        </StepWizard>
      )
  );
};

UpdateMigratedGoalsWizard.defaultProps = {
  onContinue: undefined,
  stepTitle: undefined,
  stepProps: undefined,
  onStepChanged: undefined,
};

export default UpdateMigratedGoalsWizard;
