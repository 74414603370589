/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import {
  InvestmentKnowledges,
  InvestmentKnowedgeQuestion,
  getOrgBasedInvestmentKnowledgeLevels,
} from '../resources';
import { UPDATE_USER } from '../graphql';
import ButtonDescriptionListItem from '../../common/lists/buttonDescriptionListItem';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import { UserContext } from '../../../providers/userContextProvider';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  investmentKnowledge?: InvestmentKnowledges,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  cached?: boolean,
}

const InvestmentKnowledge = ({
  user, updateUserState, cached, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const { userContext } = useContext(UserContext);
  const investmentKnowledgeOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'INVESTMENT_KNOWLEDGE_UPDATED', InvestmentKnowledge: user.investmentKnowledge });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    if (props.onContinue) props.onContinue();
  };
  const [updateEmployementStatus] = useMutation(UPDATE_USER, {
    variables: {
      input: { investmentKnowledge: user.investmentKnowledge },
    },
    onCompleted: investmentKnowledgeOnCompleted,
  });
  const updateUserStateAsync = (
    investmentKnowledge: InvestmentKnowledges,
  ): Promise<void> => Promise.resolve(updateUserState({ ...user, investmentKnowledge }));
  const handleClick = async (item: InvestmentKnowedgeQuestion): Promise<void> => {
    console.log({ event: 'UPDATE_INVESTMENT_KNOWLEDGE', InvestmentKnowledge: item });
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingInvestmentKnowledge).then();
    await updateUserStateAsync(item.value);
    if (cached && props.onContinue) {
      props.onContinue();
    } else {
      updateEmployementStatus({
        variables: {
          input: {
            investmentKnowledge: item.value,
          },
        },
      });
    }
  };
  let orgCode;
  if (userContext.organizationSettings?.specificText?.investmentKnowledgeLevels) {
    orgCode = userContext.organizationSettings?.specificText?.orgCode;
  }
  const investmentKnowledgeCategories: InvestmentKnowedgeQuestion[] = getOrgBasedInvestmentKnowledgeLevels(orgCode);
  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2">{t('user:investmentKnowledge.title')}</Typography>
      <Typography variant="paragraph3">{t('user:investmentKnowledge.subTitle')}</Typography>
      <List className="investment-knowledge-list">
        {investmentKnowledgeCategories.map((item: InvestmentKnowedgeQuestion) => (
          <ButtonDescriptionListItem
            header={item.level}
            description={item.subText || ''}
            hasCheckField
            key={item.value}
            hasSuffix={false}
            onClick={() => {
              if (item.value) {
                handleClick(item).then();
              }
            }}
            isSelected={(user.investmentKnowledge && item.value === user.investmentKnowledge) || false}
          />
        ))}
      </List>
    </OvForm>
  );
};

InvestmentKnowledge.defaultProps = {
  onContinue: undefined,
  cached: false,
};

export default InvestmentKnowledge;
