import { gql } from '@apollo/client';

export const CREATE_GOAL = gql`
  mutation createGoal($input: CreateGoalInput!) {
    createGoal(input: $input) {
      goal {
        id
        suggestedFinancialProduct{
          forecastedRateOfReturn
          forecastedStandardDeviation
        }
      }
    }
  }
`;

export const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
        suggestedFinancialProduct{
          forecastedRateOfReturn
          forecastedStandardDeviation
        }
      }
    }
  }
`;
