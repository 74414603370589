// Basic ME query
import { gql } from '@apollo/client';

export const ME_FOR_PERSONA_VERIFICATION = gql`
  query BasicMe {
    me {
      user {
        firstName
        middleName
        lastName
        primaryEmail
        dateOfBirth
        iDCheckRetrigger
        iDVerified
      }
    }
  }
`;
