import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';
import OvForm from '../common/wrappers/ovForm';
import { colors } from '../../theme/colors';
import {
  GoalRiskLevels, goalRiskLevels, GoalTimeHorizons, goalTimeHorizons,
} from '../goal/resources';

interface GoalProps {
  id: string,
  name: string,
  riskQuestion1: GoalRiskLevels,
  timeHorizon: GoalTimeHorizons,
}

export interface UserState {
  goals?: GoalProps[],
}

export interface Props {
  user: UserState,
  onEdit: (att: GoalProps) => void,
  onContinue?: () => void,
  goToNamedStep?: (step: string) => void,
  loading?: boolean,
}

const style = {
  container: {
    marginBottom: '32px',
    minHeight: '200px',
    maxHeight: '550px',
    overflowY: 'auto' as const,
  },
};

const ConfirmGoals = ({
  user, onContinue, goToNamedStep, onEdit, loading,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'suitabilityReview']);
  const selectGoalForEdit = (goal: GoalProps): void => {
    onEdit(goal);
    if (goToNamedStep) {
      goToNamedStep('riskQuestion');
    }
  };

  const itemElement = (goal: GoalProps, index: number): JSX.Element => (
    <Box key={index}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="heading4" textAlign="left" style={{ marginBottom: '10px', color: colors.neutral30, fontWeight: '500' }}>{goal.name}</Typography>
        <Typography
          data-testid={`edit-${index}`}
          onClick={() => selectGoalForEdit(goal)}
          variant="heading4"
          textAlign="left"
          style={{
            marginBottom: '10px', color: colors.success, fontWeight: '500', cursor: 'pointer',
          }}
        >
          {t('suitabilityReview:editText')}
        </Typography>
      </Box>
      <Box style={{ backgroundColor: colors.neutral80, marginBottom: '16px' }}>
        <Box style={{ padding: '16px', borderBottom: `2px solid ${colors.neutral70}` }}>
          <Typography variant="heading4" textAlign="left" sx={{ fontWeight: '500' }}>{goalRiskLevels.find((riskLevel) => riskLevel.key === goal.riskQuestion1)?.description ?? ''}</Typography>
        </Box>
        <Box style={{ padding: '16px' }}>
          <Typography variant="heading4" textAlign="left" sx={{ fontWeight: '500' }}>{goalTimeHorizons.find((timeHorizon) => timeHorizon.key === goal.timeHorizon)?.description ?? ''}</Typography>
        </Box>
      </Box>
    </Box>
  );

  const sendChangesToServer = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    if (onContinue) {
      onContinue();
    }
  };
  return (
    <OvForm
      hasButton
      buttonText={t('suitabilityReview:confirmGoals.button')}
      onSubmit={sendChangesToServer}
      loading={loading}
    >
      <Box display="flex" flexDirection="column" style={style.container}>
        <Typography variant="headingMain" textAlign="left" style={{ marginBottom: '20px' }}>{t('suitabilityReview:confirmGoals.title')}</Typography>
        {user.goals?.map((goal, index) => itemElement(goal, index))}
      </Box>
    </OvForm>
  );
};

ConfirmGoals.defaultProps = {
  onContinue: undefined,
  goToNamedStep: undefined,
  loading: false,
};

export default ConfirmGoals;
