/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from 'react';
import {
  Typography, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MemberTag } from '../goal/memberTag';
import { formatMoneyValue, formatPercentValue } from '../../utils/commonMethods';
import { navigate } from '../../utils/router';
import { UserContext } from '../../providers/userContextProvider';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { householdMembersIdVar } from '../../utils/localVariables';
import { Relationship } from '../../utils/commonGraphql';
import { getAccountTypeName } from './resources';

const AccountItem = ({ account, totalMarketValue, isHouseholdView }: { account: any, totalMarketValue: number, isHouseholdView?: boolean }) => {
  const { colors } = useContextTheme();
  const { setUserContext } = useContext(UserContext);
  const { t } = useTranslation(['account']);
  const [hoverIndex] = useState<number | null>(null);

  function isValidSubAccount(subAccount: any) {
    const isActive = subAccount.state !== 'INACTIVE';
    return isActive;
  }

  const numSubAccountsLinked = account.subAccounts?.filter(isValidSubAccount).length ?? 0;

  /* For household views, split statistics by member */
  interface NameAndStats { name: string, statistics: any }
  const orderedStatisticsByMember: NameAndStats[] = [];
  const selectedStatistic = account.statistics;
  const marketValue = selectedStatistic?.marketValueCents ?? 0;

  let shownMarketValue = marketValue;
  let shownSimpleReturnAmount = selectedStatistic?.simpleReturnAmount ?? 0;
  let shownSimpleReturnPercent = selectedStatistic?.simpleReturnPercent ?? 0;
  if (hoverIndex !== null) {
    const partialStatistics = orderedStatisticsByMember[hoverIndex].statistics;
    shownMarketValue = partialStatistics.marketValueCents ?? 0;
    shownSimpleReturnAmount = partialStatistics.simpleReturnAmount ?? 0;
    shownSimpleReturnPercent = partialStatistics.simpleReturnPercent ?? 0;
  }

  const getPercentageOfTotalAsset = (): number => {
    const totalMarketValueCents: number = totalMarketValue || 0;
    const percentage = (account?.statistics?.marketValueCents ?? 0) / totalMarketValueCents;
    return Number.isNaN(percentage) ? 0 : percentage;
  };

  return (
    <Box
      onClick={() => navigate(setUserContext, '/account', { accountId: account.id, household: account.householdClientGroup, accountType: account.type })}
      padding="16px"
      border={`1px solid ${colors.portfolioCardBorder}`}
      borderRadius="4px"
      sx={{
        cursor: 'pointer',
        transition: 'all 0.2s',
        background: colors.baseComponentBackground,
        '&:hover': {
          boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      {isHouseholdView && (
        <Box sx={{ position: 'relative', top: '-27px', height: 0 }}>
          {account.householdClientGroup?.relationships ? (
            account.householdClientGroup.relationships.filter((rel: Relationship) => rel.user?.id === account.user?.id).map((elem: Relationship) => {
              const index = householdMembersIdVar().findIndex((id) => id === elem.user?.id);
              return <MemberTag key={elem.user?.id} sequenceNumber={index} name={elem.user?.firstName ?? ''} />;
            })
          ) : (
            <MemberTag
              sequenceNumber={householdMembersIdVar().findIndex((id) => id === account.user?.id)}
              name={account.user?.firstName ?? ''}
            />
          )}
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" marginBottom="15px">
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <Typography fontWeight={600} fontSize="16px">{account.name ? account.name : getAccountTypeName(account.type)}</Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Typography fontWeight={600} fontSize="18px">{formatMoneyValue(shownMarketValue)}</Typography>
          <Typography color={(shownSimpleReturnAmount) >= 0 ? 'primary' : 'error'} fontWeight={500} fontSize="12px">
            {formatMoneyValue(shownSimpleReturnAmount)}
            {`(${formatPercentValue(shownSimpleReturnPercent)})`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box display="flex" justifyContent="space-between" marginTop="31px">
          <Typography color={colors.neutral30} fontWeight={500} fontSize="12px">
            {t('item.subAccountsLinked', { count: numSubAccountsLinked ?? 0 })}
          </Typography>
          <Typography color={colors.neutral30} fontWeight={500} fontSize="12px">
            {t('item.ofTotalAssets', { percentage: formatPercentValue(getPercentageOfTotalAsset(), 0) })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

AccountItem.defaultProps = { isHouseholdView: false };

export default AccountItem;
