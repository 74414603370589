import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import SimpleInput from '../common/inputs/simpleInput';
import { UPDATE_USER } from './graphql';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';

export interface UserState {
  firstName?: string,
  middleName?: string,
  lastName?: string,
  incompleteFields?: string[],
}

export interface ValidateState {
  firstNameIsValid?: boolean,
  lastNameIsValid?: boolean,
  middleNameIsValid?: boolean,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  title?: string,
  subtitle?: string,
  onContinue?: () => void,
  shouldSaveUpdate?: boolean,
  analyticsEvent?: string,
}

const Name = ({
  user, updateUserState, title, subtitle, shouldSaveUpdate = false, analyticsEvent, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [validState, setValidState] = useState<ValidateState>({ firstNameIsValid: true, lastNameIsValid: true, middleNameIsValid: true });
  const [loading, setLoading] = useState(false);

  const checkFieldsValidity = async (): Promise<boolean> => {
    const firstNameValidity = await yup.string().required().isValid(user.firstName);
    setValidState({ ...validState, firstNameIsValid: firstNameValidity });
    const lastNameValidity = await yup.string().required().isValid(user.lastName);
    setValidState({ ...validState, lastNameIsValid: lastNameValidity });
    return firstNameValidity && lastNameValidity;
  };

  const onNameUpdated = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'NAME_UPDATED' });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };
  const [updateName] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        firstName: user.firstName?.trim(),
        lastName: user.lastName?.trim(),
        middleName: user.middleName?.trim(),
      },
    },
    onCompleted: onNameUpdated,
    onError: () => setLoading(false),
  });

  const onContinueClick = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.onBoardingNameContinue).then();
    if (!await checkFieldsValidity()) return;
    if (shouldSaveUpdate) {
      setLoading(true);
      updateName().then();
    } else if (props.onContinue) {
      updateUserState({
        ...user,
        firstName: user.firstName?.trim(),
        middleName: user.middleName?.trim(),
        lastName: user.lastName?.trim(),
      });
      props.onContinue();
    }
  };

  const clearFirstName = (): void => {
    updateUserState({ ...user, firstName: '' });
    setValidState({ ...validState, firstNameIsValid: true });
  };
  const clearMiddleName = (): void => {
    updateUserState({ ...user, middleName: '' });
    setValidState({ ...validState, middleNameIsValid: true });
  };
  const clearLastName = (): void => {
    updateUserState({ ...user, lastName: '' });
    setValidState({ ...validState, lastNameIsValid: true });
  };

  return (
    <OvForm onSubmit={onContinueClick} loading={loading}>
      <Typography variant="heading2" style={{ marginBottom: 16 }}>{title ?? t('user:name.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{subtitle ?? t('user:name.subTitle')}</Typography>
      <SimpleInput
        testId="firstName-input"
        label={t('user:name.firstNamelabel')}
        value={user.firstName ?? ''}
        style={{ marginBottom: 16 }}
        subtitle={validState.firstNameIsValid ? '' : t('user:name.firstNameInvalidMsg')}
        error={!validState.firstNameIsValid}
        required
        onChange={(event) => updateUserState({ ...user, firstName: event.target.value })}
        data-testid="firstName-input"
        onClear={clearFirstName}
      />
      <SimpleInput
        testId="middleName-input"
        label={t('user:name.middleNamelabel')}
        value={user.middleName ?? ''}
        style={{ marginBottom: 16 }}
        onChange={(event) => updateUserState({ ...user, middleName: event.target.value })}
        data-testid="middleName-input"
        onClear={clearMiddleName}
      />
      <SimpleInput
        testId="lastName-input"
        label={t('user:name.lastNamelabel')}
        value={user.lastName ?? ''}
        style={{ marginBottom: 32 }}
        subtitle={validState.lastNameIsValid ? '' : t('user:name.lastNameInvalidMsg')}
        error={!validState.lastNameIsValid}
        required
        onChange={(event) => updateUserState({ ...user, lastName: event.target.value })}
        data-testid="lastName-input"
        onClear={clearLastName}
      />
    </OvForm>
  );
};

Name.defaultProps = {
  title: undefined,
  subtitle: undefined,
  onContinue: undefined,
  shouldSaveUpdate: false,
  analyticsEvent: undefined,
};

export default Name;
