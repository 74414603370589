/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import { ReactSmartScroller } from 'react-smart-scroller';
import { UPDATE_USER } from '../graphql';
import {
  RiskLevels,
  RiskQuestion,
  getOrgBasedRiskQuestion2,
  smartScrollStyles,
} from '../resources';
import riskGraphImage from '../../../assets/images/risk/invest_simulation.gif';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';
import ButtonDescriptionListItem from '../../common/lists/buttonDescriptionListItem';
import { UserContext } from '../../../providers/userContextProvider';
import ButtonListItem from '../../common/lists/buttonListItem';

export interface UserState {
  riskQuestion2?: RiskLevels,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  cached?: boolean,
}

const RiskQuestion2 = ({
  user, updateUserState, cached, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const { userContext } = useContext(UserContext);
  const riskQuestion2OnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'RISK_QUESTION_1_UPDATED', InvestmentKnowledge: user.riskQuestion2 });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    if (props.onContinue) props.onContinue();
  };
  const [updateRiskQuestion2Status] = useMutation(UPDATE_USER, {
    variables: {
      input: { riskQuestion2: user.riskQuestion2 },
    },
    onCompleted: riskQuestion2OnCompleted,
  });
  const updateUserStateAsync = (riskQuestion2: RiskLevels): Promise<void> => Promise.resolve(updateUserState({ ...user, riskQuestion2 }));
  const handleClick = async (item: RiskQuestion): Promise<void> => {
    console.log({ event: 'UPDATE_RISK_QUESTION_2', RiskQuestions2: item });
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectInvestmentLoss).then();
    await updateUserStateAsync(item.level);
    if (cached && props.onContinue) {
      props.onContinue();
    } else {
      updateRiskQuestion2Status({
        variables: {
          input: {
            riskQuestion2: item.level,
          },
        },
      });
    }
  };

  let orgCode = '';
  if (userContext.organizationSettings?.specificText?.riskQuestion2) {
    orgCode = userContext.organizationSettings?.specificText?.orgCode || '';
  }
  const riskQuestion2Categories: RiskQuestion[] = getOrgBasedRiskQuestion2(orgCode);

  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2">{t('user:riskQuestion2.title')}</Typography>
      <img
        src={riskGraphImage}
        alt="risk-projection"
        style={{
          maxHeight: '300px',
          width: '100%',
          outlineOffset: '-3px',
          outline: '4px solid #fff',
        }}
      />
      <Typography style={{ marginBottom: '16px' }} variant="paragraph3">{t('user:riskQuestion2.subTitle')}</Typography>

      <List className="risk-question-2-list">
        <ReactSmartScroller
          style={{
            height: '300px',
          }}
          trackProps={smartScrollStyles.trackProps}
          thumb={
            (
              <div
                style={smartScrollStyles.thumb}
              />
            )
          }
          vertical
        >
          {riskQuestion2Categories.map((item: RiskQuestion) => (
            orgCode ? (
              <ButtonDescriptionListItem
                header={item.question}
                description={item.subText || ''}
                hasCheckField
                key={item.level}
                hasSuffix={false}
                onClick={() => {
                  if (item.level) {
                    handleClick(item).then();
                  }
                }}
                isSelected={(user.riskQuestion2 && item.level === user.riskQuestion2) || false}
              />
            ) : (
              <ButtonListItem
                key={item.level}
                text={item.question || ''}
                onClick={() => {
                  if (item.level) {
                    handleClick(item).then();
                  }
                }}
                isSelected={(user.riskQuestion2 && item.level === user.riskQuestion2) || false}
              />
            )
          ))}
        </ReactSmartScroller>
      </List>

    </OvForm>
  );
};

RiskQuestion2.defaultProps = {
  onContinue: undefined,
  cached: false,
};

export default RiskQuestion2;
