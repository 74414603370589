import React, { FormEvent } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme/colors';
import OvForm from '../wrappers/ovForm';

interface Props {
  components: JSX.Element[],
  applyFilters: (e: FormEvent<HTMLFormElement>) => void,
  clearFilters: () => void,
}

const Filter = ({ components, applyFilters, clearFilters }: Props): JSX.Element => {
  const { t } = useTranslation(['document']);

  const styles = {
    filterWrapper: {
      boxShadow: `0px 2px 12px ${colors.transparentShadow}`,
      borderRadius: '4px',
      minWidth: '264px',
      backgroundColor: colors.ovWhite,
      padding: '16px',
    },
  };

  return (
    <Box sx={styles.filterWrapper}>
      <OvForm
        buttonText={t('document:filter.button.applyFilters')}
        onSubmit={applyFilters}
      >
        {components.map((Component) => (
          <Box sx={{ mb: 2 }} key={Math.random()}>
            {Component}
          </Box>
        ))}
      </OvForm>
      <Button
        fullWidth
        onClick={clearFilters}
      >
        {t('document:filter.button.clearFilters')}
      </Button>
    </Box>

  );
};

export default Filter;
