import { useContext, useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import { Box, Typography } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import { styles } from './styles';
import { formatUSNumber } from '../../utils/commonMethods';
import { BASIC_PERSONAL_INFO, PersonalDetailsResponse } from './graphql';
import UpdatePhysicalAddressWizard from '../user/address/updatePhysicalAddressWizard';
import UpdateEmployementStatusWizard from '../user/employment/updateEmployementStatusWizard';
import UpdateEmailWizard from '../signUp/updateEmailWizard';
import UpdatePhoneWizard from '../signUp/updatePhoneWizard';
import HelpModal from '../common/modals/helpModal';
import { getTranslatedEmployementStatus } from '../user/resources';
import { UserContext } from '../../providers/userContextProvider';
import FlowModal from '../common/wrappers/modals/ovFlowModal';
import { useGlobalToast } from '../../providers/globalToastProvider';
import SelectLanguage, { UserLanguage } from '../user/language/selectLanguage';

interface PersonalInfo {
  legalName: string,
  address: string,
  language?: UserLanguage,
  email: string,
  phone: string,
  employement: string,
}

const PersonalDetails = (): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
    legalName: '',
    address: '',
    email: '',
    phone: '',
    employement: '',
  });
  const getJobField = (user: PersonalDetailsResponse): string => (user.employmentStatus === 'STUDENT' ? user.studentAreaOfStudy || '' : user.jobTitle);
  const onCompleted = (data: { me: { user: PersonalDetailsResponse } }): void => {
    if (data) {
      console.log({ event: 'PERSONAL_DETAILS', user: data.me.user });
      setPersonalInfo({
        legalName: `${data.me.user.firstName} ${data.me.user.lastName}`,
        address: `${data.me.user.physicalAddress.houseNumber}, ${data.me.user.physicalAddress.streetName}, ${data.me.user.physicalAddress.city}, ${data.me.user.physicalAddress.province}, ${data.me.user.physicalAddress.country}`,
        email: `${data.me.user.primaryEmail}`,
        language: `${data.me.user.language}` as UserLanguage,
        employement: `${getTranslatedEmployementStatus(data.me.user.employmentStatus)} - ${getJobField(data.me.user)}`,
        phone: `${formatUSNumber(data.me.user.phone || '')}`,
      });
    } else {
      console.error({ message: 'USER_NOT_LOADED' });
    }
    setLoading(false);
  };
  const [getBasicMe] = useLazyQuery(BASIC_PERSONAL_INFO, {
    onCompleted,
    nextFetchPolicy: 'standby',
    onError: () => setLoading(false),
  });
  useEffect(() => {
    setLoading(true);
    getBasicMe();
  }, [getBasicMe, setLoading]);
  const { showToast } = useGlobalToast();
  const [updateNameWizard, setUpdateNameWizard] = useState(false);
  const [updateEmployementStatusWizard, setUpdateEmployementStatusWizard] = useState(false);
  const [updateEmailWizard, setUpdateEmailWizard] = useState(false);
  const [updatePhoneWizard, setUpdatePhoneWizard] = useState(false);
  const [updateAgreementLanguageWizard, setUpdateAgreementLanguageWizard] = useState(false);
  const [updateAddressWizard, setUpdateAddressWizard] = useState(false);
  const [userProfile, updateUserProfile] = useState({
    primaryEmail: '',
    phone: '',
    employmentStatus: '',
    studentAreaOfStudy: '',
    jobTitle: '',
    companyType: '',
    physicalAddress: {},
  });
  const { userContext } = useContext(UserContext);

  const setUserProfile = (att: Partial<typeof userProfile>): void => updateUserProfile({ ...userProfile, ...att });
  const onEmailUpdate = (): void => {
    setUpdateEmailWizard(true);
  };
  const onNameUpdate = (): void => {
    setUpdateNameWizard(true);
  };
  const onPhoneUpdate = (): void => {
    setUpdatePhoneWizard(true);
  };
  const onAgreementLanguageUpdate = (): void => {
    setUpdateAgreementLanguageWizard(true);
  };
  const onEmployementStatusUpdate = (): void => {
    setUpdateEmployementStatusWizard(true);
  };
  const onAddressUpdate = (): void => {
    setUpdateAddressWizard(true);
  };
  if (updateNameWizard) {
    return (
      <FlowModal
        open={updateNameWizard}
        onClose={() => setUpdateNameWizard(false)}
        showCloseButton
        component={(
          <HelpModal
            email={personalInfo.email}
            hideFaq
            title={t('user:helpModal.legalNameTitle').toString()}
            subTitle={t('user:helpModal.legalNameSubTitle').toString()}
          />
        )}
      />
    );
  }
  if (updateAddressWizard) {
    return (
      <FlowModal
        open={updateAddressWizard}
        goBack={() => setUpdateAddressWizard(false)}
        showCloseButton={false}
        component={(
          <UpdatePhysicalAddressWizard
            user={userProfile}
            showAgreements
            goBack={() => setUpdateAddressWizard(false)}
            onContinue={() => {
              showToast({ message: t('toastMessages:updateHomeAddressSuccess.h2'), severity: 'success', title: t('toastMessages:updateHomeAddressSuccess.h1') });
              setUpdateAddressWizard(false);
              getBasicMe();
            }}
            updateUserState={setUserProfile}
            showStepper={false}
          />
        )}
      />
    );
  }
  if (updateEmployementStatusWizard) {
    return (
      <FlowModal
        open={updateEmployementStatusWizard}
        goBack={() => setUpdateEmployementStatusWizard(false)}
        showCloseButton={false}
        component={(
          <UpdateEmployementStatusWizard
            user={userProfile}
            goBack={() => setUpdateEmployementStatusWizard(false)}
            onContinue={() => {
              showToast({ message: t('toastMessages:updateEmploymentDetailsSuccess.h2'), severity: 'success', title: t('toastMessages:updateEmploymentDetailsSuccess.h1') });
              setUpdateEmployementStatusWizard(false);
              getBasicMe();
            }}
            updateUserState={setUserProfile}
            showStepper={false}
          />
        )}
      />
    );
  }
  if (updateAgreementLanguageWizard) {
    return (
      <FlowModal
        open={updateAgreementLanguageWizard}
        onClose={() => setUpdateAgreementLanguageWizard(false)}
        showCloseButton
        component={(
          <SelectLanguage
            language={personalInfo.language}
            onSelect={() => {
              showToast({ message: t('toastMessages:updateLanguageSuccess.h2'), severity: 'success', title: t('toastMessages:updateLanguageSuccess.h1') });
              setUpdateAgreementLanguageWizard(false);
              getBasicMe();
            }}
          />
        )}
      />
    );
  }
  if (updateEmailWizard) {
    return (
      <FlowModal
        open={updateEmailWizard}
        goBack={() => setUpdateEmailWizard(false)}
        showCloseButton={false}
        component={(
          <UpdateEmailWizard
            goBack={() => setUpdateEmailWizard(false)}
            onContinue={() => {
              showToast({ message: t('toastMessages:updateEmailSuccess.h2'), severity: 'success', title: t('toastMessages:updateEmailSuccess.h1') });
              setUpdateEmailWizard(false);
              getBasicMe();
            }}
            updateState={setUserProfile}
          />
        )}
      />
    );
  }
  if (updatePhoneWizard) {
    return (
      <FlowModal
        open={updatePhoneWizard}
        goBack={() => setUpdatePhoneWizard(false)}
        showCloseButton={false}
        component={(
          <UpdatePhoneWizard
            goBack={() => setUpdatePhoneWizard(false)}
            onContinue={() => {
              showToast({ message: t('toastMessages:updatePhoneNumberSuccess.h2'), severity: 'success', title: t('toastMessages:updatePhoneNumberSuccess.h1') });
              setUpdatePhoneWizard(false);
              getBasicMe();
            }}
            updateState={setUserProfile}
          />
        )}
      />
    );
  }
  if (loading) {
    return <OvLoadingIndicator />;
  }
  const allowPasswordlessUpdate = isNil(userContext.partnerId);
  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <Typography variant="headingMain" style={{ marginBottom: 33 }}>{t('user:personalDetailsModal.title')}</Typography>
        <Typography variant="captionSectionerMedium" style={styles.subTitleText}>{t('user:personalDetailsModal.legalName')}</Typography>
        <Box sx={styles.inputBox}>
          <Typography variant="bodySub2" style={styles.inputText}>{personalInfo.legalName}</Typography>
          {allowPasswordlessUpdate ? (<Typography variant="bodySub2" style={styles.editText} onClick={onNameUpdate}>{t('user:personalDetailsModal.edit')}</Typography>) : null}
        </Box>
        <Typography variant="captionSectionerMedium" style={styles.subTitleText}>{t('user:personalDetailsModal.contactInfo')}</Typography>
        <Box sx={styles.inputBox}>
          <Typography variant="bodySub2" style={styles.inputText}>{personalInfo.email}</Typography>
          {allowPasswordlessUpdate ? (<Typography variant="bodySub2" style={styles.editText} onClick={onEmailUpdate}>{t('user:personalDetailsModal.edit')}</Typography>) : null}
        </Box>
        <Box sx={styles.inputBox} style={{ marginTop: '32px !important' }}>
          <Typography variant="bodySub2" style={styles.inputText}>{personalInfo.phone}</Typography>
          {allowPasswordlessUpdate ? (<Typography variant="bodySub2" style={styles.editText} onClick={onPhoneUpdate}>{t('user:personalDetailsModal.edit')}</Typography>) : null}
        </Box>
        <Typography variant="captionSectionerMedium" style={styles.subTitleText}>{t('user:personalDetailsModal.agreementLanguage')}</Typography>
        <Box sx={styles.inputBox}>
          <Typography variant="bodySub2" style={styles.inputText}>{capitalize(personalInfo.language)}</Typography>
          <Typography variant="bodySub2" style={styles.editText} onClick={onAgreementLanguageUpdate}>{t('user:personalDetailsModal.edit')}</Typography>
        </Box>
        <Typography variant="captionSectionerMedium" style={styles.subTitleText}>{t('user:personalDetailsModal.address')}</Typography>
        <Box sx={styles.inputBox}>
          <Typography variant="bodySub2" style={styles.inputText}>{personalInfo.address}</Typography>
          <Typography variant="bodySub2" style={styles.editText} onClick={onAddressUpdate}>{t('user:personalDetailsModal.edit')}</Typography>
        </Box>
        <Typography variant="captionSectionerMedium" style={styles.subTitleText}>{t('user:personalDetailsModal.empInfo')}</Typography>
        <Box sx={styles.inputBox}>
          <Typography variant="bodySub2" style={styles.inputText}>{personalInfo.employement}</Typography>
          <Typography variant="bodySub2" style={styles.editText} onClick={onEmployementStatusUpdate}>{t('user:personalDetailsModal.edit')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalDetails;
