import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import securityImage from '../../assets/images/security.svg';
import OvForm from '../common/wrappers/ovForm';

export interface Props {
  onContinue?: () => void,
  continueLater?: () => void,
}

const Information = ({ continueLater, onContinue }: Props): JSX.Element => {
  const { t } = useTranslation(['idVerificationChecker']);
  const continueToNextStep = (): void => {
    if (onContinue) {
      onContinue();
    }
  };
  return (
    <OvForm
      hasButton
      buttonText={t('idVerificationChecker:information.prefixButton')}
      onSubmit={continueToNextStep}
      suffixButton={{
        onClick: () => {
          if (continueLater) {
            continueLater();
          }
        },
        text: t('idVerificationChecker:information.suffixButton'),
        variant: 'secondary-large',
        dataTestId: 'close-button',
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" style={{ marginBottom: '32px' }}>
        <img src={securityImage} alt="securityImage" />
        <Typography variant="headingMain" textAlign="center" style={{ marginBottom: 0 }}>{t('idVerificationChecker:information.title')}</Typography>
        <Typography variant="paragraphMain" style={{ marginBottom: 0, textAlign: 'center' }}>
          <Markup content={t('idVerificationChecker:information.body.1')} />
        </Typography>
        <Typography variant="paragraphMain" style={{ marginBottom: 0, textAlign: 'center' }}>
          {t('idVerificationChecker:information.body.2')}
          <Link href="https://onevest.zendesk.com/hc/en-us/requests/new" underline="always" target="_blank">{t('idVerificationChecker:information.body.3')}</Link>
        </Typography>
      </Box>
    </OvForm>
  );
};

Information.defaultProps = {
  onContinue: undefined,
  continueLater: undefined,
};

export default Information;
