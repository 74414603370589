/* eslint-disable no-promise-executor-return */
import { PhysicalAddress } from '../components/user/address/resources';

export const isLocalEnv = !['staging', 'production'].includes(process.env.REACT_APP_ENV || '');
export const isTestEnv = ['staging', 'sandbox'].includes(process.env.REACT_APP_ENV || '');

export const millisToMinutesAndSeconds = (millis: number): string => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000);
  return `${minutes}:${(seconds < 10 ? '0' : '')}${seconds.toFixed(0)}`;
};

export const delay = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

export const formatMoneyValue = (cents: number, currency = '$', fractionDigits = 2): string => `${currency}${(Math.round(cents) / 100).toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

const defineGainSymbol = (percent: number): string => {
  if (percent > 0) return '';
  if (percent < 0) return '-';
  return '';
};
export const formatReturnAmount = (cents: number, currency = '$', fractionDigits = 2): string => `${defineGainSymbol(cents)}${currency}${(Math.round(Math.abs(cents)) / 100).toFixed(fractionDigits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const formatPercentValue = (percent: number, fractionDigits = 2): string => `${(percent * 100).toFixed(fractionDigits).toString()}%`;

export const formatUSNumber = (phoneNumberString: string, isRemoveParathesis = false): string => {
  const cleaned = `${(phoneNumberString).replace(/\D/g, '')}`;
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    if (isRemoveParathesis) return `${intlCode} ${match[2]}-${match[3]}-${match[4]}`;
    return `${intlCode} (${match[2]}) ${match[3]} - ${match[4]}`;
  }
  return phoneNumberString;
};

export const formatDate = (dateValue: Date): string => {
  if (dateValue && dateValue !== undefined) {
    try {
      return new Date(dateValue).toISOString().split('T')[0];
    } catch (error) {
      //  eliminate invalid dates
      return '';
    }
  }
  return '';
};

export const generateAddressString = (address: Partial<PhysicalAddress>): string => {
  if (!address) return '';

  return [address.unitNumber, address.houseNumber, address.streetName, address.city, address.province, address.postal, address.country].filter(Boolean).join(', ');
};
