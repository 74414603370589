/* This modal Generated as general Modal for Flow popups and popups with Close Icon on top header according to following figma:
https://www.figma.com/file/Y3J9whUp6qR2fIIJrk93wQ/%F0%9F%92%BB--Web---Design-system?node-id=1338%3A2764
*/
import {
  Box,
  Card,
  CardContent,
  Dialog,
  Modal,
  useMediaQuery,
} from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/system/createTheme';
import CloseButton from '../../buttons/closeButton';
import { colors } from '../../../../theme/colors';
import { StepWizardTransitions } from '../withStepWizard';

export const ovActivityTransition: StepWizardTransitions = {
  enterRight: 'animate__fadeIn',
  enterLeft: 'animate__fadeIn',
  exitRight: 'animate__fadeOut',
  exitLeft: 'animate__fadeOut',
  intro: 'animate__fadeIn',
};

const styles = {
  modal: {
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '300px',
    bgcolor: colors.ovFlowModalBackground,
    borderRadius: '5px',
    maxHeight: '95vh',
    overflowY: 'auto',
    padding: '32px 16px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.07)',
    maxWidth: '90vw',
    '@media (min-width:480px)': {
      padding: '32px',
      minWidth: '392px',
    },
  },
  dialog: {
    bgcolor: colors.ovFlowModalBackground,
    height: '100%',
    padding: '32px 32px 0px 32px',
  },
  mobileContainer: {
    position: 'relative',
    width: '100%',
    left: 0,
    top: 0,
  },
  componentContainer: {
    position: 'absolute' as const,
    padding: '32px',
    left: '0',
    top: '0',
    right: '0',
    background: '#ffffff',
    overflowY: 'auto' as const,
  },
  closeBtnContainer: {
    position: 'absolute' as const,
    top: 0,
    background: '#ffffff',
    left: 0,
    padding: '32px',
    right: 0,
    overflowY: 'auto' as const,
    zIndex: 1,
  },
};

interface Props {
  component: JSX.Element,
  maintainModal?: boolean,
  fullScreenModal?: boolean,
  sx?: SxProps<Theme>,
  open: boolean,
  onClose?: () => void,
  goBack?: () => void,
  showCloseButton?: boolean,
  padding?: string,
}

const WrappedComponent = (
  component: JSX.Element,
  onClose?: () => void,
  goBack?: () => void,
  showCloseButton?: boolean,
  padding?: string,
): JSX.Element => (
  <Card
    id="scrollable-surface"
    className="preliminary ov-activity-flow"
    sx={{ backgroundColor: colors.ovFlowModalBackground }}
  >
    <CardContent
      className="preliminary"
      style={{
        boxShadow: 'none !important',
        height: '100%',
      }}
    >
      {showCloseButton ? (
        <Box style={{ backgroundColor: colors.ovFlowModalBackground }} className="close-btn-area" sx={styles.closeBtnContainer}>
          <CloseButton onClick={() => {
            if (onClose) onClose();
          }} />
          <Box className="componentAreaWithCloseBtn">
            {component}
          </Box>
        </Box>
      ) : (
        <Box
          className="componentArea"
          sx={{
            ...styles.componentContainer,
            zIndex: 1,
            padding,
            backgroundColor: colors.ovFlowModalBackground,
          }}
        >
          {component}
        </Box>
      )}
    </CardContent>
  </Card>
);
const WrappedDialogComponent = (
  component: JSX.Element,
  onClose?: () => void,
  goBack?: () => void,
  showCloseButton?: boolean,
  padding?: string,
): JSX.Element => (
  <Dialog
    fullScreen
    open
  >
    {showCloseButton ? (
      <Box style={{ backgroundColor: colors.ovFlowModalBackground }} className="close-btn-area" sx={styles.closeBtnContainer}>
        <CloseButton onClick={() => {
          if (onClose) onClose();
        }} />
        <Box className="componentAreaWithCloseBtn">
          {component}
        </Box>
      </Box>
    ) : (
      <Box className="componentArea" sx={{ ...styles.dialog, padding }}>
        {component}
      </Box>
    )}
  </Dialog>
);
const boxShadow = '0px 0px 20px rgba(0, 0, 0, 0.07)';
const FlowModal = ({
  component,
  sx,
  onClose,
  goBack,
  open,
  showCloseButton,
  padding,
  maintainModal,
  fullScreenModal,
}: Props): JSX.Element => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  if (isMobileScreen && !maintainModal) {
    if (fullScreenModal) {
      return WrappedDialogComponent(component, onClose, goBack, showCloseButton, padding);
    }
    return WrappedComponent(component, onClose, goBack, showCloseButton, padding);
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={sx ?? styles.modal} boxShadow={boxShadow}>
        <Box>
          {showCloseButton ? (
            <CloseButton onClick={() => {
              if (onClose) onClose();
            }} />
          ) : null}
        </Box>
        <Box>
          {component}
        </Box>
      </Box>
    </Modal>
  );
};

FlowModal.defaultProps = {
  sx: undefined,
  onClose: undefined,
  goBack: undefined,
  showCloseButton: undefined,
  padding: '32px',
  maintainModal: false,
  fullScreenModal: false,
};

export default FlowModal;
