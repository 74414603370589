/* eslint-disable max-len */
import {
  Box, Link,
  Typography,
} from '@mui/material';
import React, {
  FormEvent, useContext,
  useEffect, useRef,
  useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import DropdownInput from '../../common/inputs/dropdownInput';
import OvForm from '../../common/wrappers/ovForm';
import { EXTERNAL_TRANSFER, FETCH_SUB_ACCOUNT_NET_CONTRIBUTIONS } from '../graphql';
import AddInstitutionWizard from './addAddress/addInstitutionWizard';
import { AddressCountries, AddressProvinces } from '../../user/address/resources';
import { UserContext } from '../../../providers/userContextProvider';
import SimpleInput from '../../common/inputs/simpleInput';
import { InputType } from '../../common/inputs/utils/inputType';
import FeedbackModal from '../../common/wrappers/modals/ovFeedbackModal';
import PortfolioDropdown from '../../common/controllers/portfolioDropdown';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import { AccountTypes, getSupportedAccountTypes } from '../../account/resources';
import { SubAccount } from '../../../utils/commonGraphql';
import { colors } from '../../../theme/colors';
import GoalDropDown from '../../common/controllers/goalDropdown';
import Disclaimer from '../../common/disclaimer';
import { lockedInAccount } from '../../../utils/accountUtil';

interface Institution {
  id?: string,
  name: string,
  physicalAddress?: PhysicalAddress,
}

export interface PhysicalAddress {
  unitNumber?: string,
  houseNumber: string,
  streetName: string,
  neighborhood?: string,
  city: string,
  province: AddressProvinces,
  country: AddressCountries,
  postal: string,
}

interface Props {
  onClose: () => void,
  selectedGoalId?: string,
  showOnlySelectedGoalAsOption?: boolean,
  selectedSubAccountId?: string,
  showOnlySelectedSubAccountAsOption?: boolean,
  onSuccessTransfer?: () => void,
  hideToastMessage?: boolean,
  analyticsEvent?: string,
  preSelectedAccountType?: AccountTypes,
}

const ExternalAccountDeposit = ({
  onClose,
  selectedGoalId,
  showOnlySelectedGoalAsOption,
  selectedSubAccountId,
  showOnlySelectedSubAccountAsOption,
  onSuccessTransfer,
  hideToastMessage,
  analyticsEvent,
  preSelectedAccountType,
}: Props): JSX.Element => {
  const { t } = useTranslation(['transfer']);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [subAccountContributionsAmount, setSubAccountContributionsAmount] = useState(0);
  const [toGoal, setToGoal] = useState(selectedGoalId);
  const [toPortfolio, setToPortfolio] = useState(selectedSubAccountId ?? '');
  const [financialInstitution, setFinancialInstitution] = useState<Institution>();
  const [accountNumber, setAccountNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [clbAmount, setClbAmount] = useState('');
  const [bctesgAmount, setbctesgAmount] = useState('');
  const [transferType, setTransferType] = useState('');
  const [addInstitution, setAddInstitution] = useState(false);
  const [accountTypeOptions, setAccountTypeOptions] = useState<AccountTypes[]>([]);
  const [accountType, setAccountType] = useState(preSelectedAccountType || '');
  const [goalSubAccounts, setGoalSubAccounts] = useState<SubAccount[] | undefined>(undefined);
  const { userContext } = useContext(UserContext);
  const isMounted = useRef(false);
  const { showToast } = useGlobalToast();
  useEffect(() => {
    const subAccount = goalSubAccounts?.find((item) => item.id === toPortfolio);
    if (subAccount && subAccount.account) {
      if (lockedInAccount.includes(subAccount?.account.type)) {
        setAccountTypeOptions([AccountTypes.RRIF, AccountTypes.RIF_SPOUSAL, AccountTypes.LIRA]);
      } else {
        setAccountTypeOptions(getSupportedAccountTypes().filter((x: AccountTypes) => x !== AccountTypes.RESP));
      }
    } else {
      setAccountTypeOptions([]);
    }
  }, [toPortfolio, goalSubAccounts]);
  const minInitialDepositCents = userContext.organization?.minInitialDepositCents ?? 100;
  const minInvestAmount = minInitialDepositCents / 100;
  const hasContributedEnough = (minInitialDepositCents - subAccountContributionsAmount) <= 0;
  const setDepositTransferData = (data: { createExternalTransfer: { manualProcess: { id: string, state: string, } } }): void => {
    const { id, state } = data.createExternalTransfer.manualProcess;
    console.log({ event: 'TRANSFER_CREATED', id, state });
    if (!hideToastMessage) {
      showToast({ message: t('toastMessages:investmentMadeSuccess.h2'), severity: 'success', title: t('toastMessages:investmentMadeSuccess.h1') });
    }
    setLoading(false);
    if (onSuccessTransfer) onSuccessTransfer();
    onClose();
  };

  const isRESPAccount = (): boolean => {
    const subAccount: SubAccount | undefined = userContext.subAccounts?.find((item) => (item?.id ?? '') === toPortfolio);
    if (subAccount) {
      return subAccount.account?.type === AccountTypes.RESP
        || subAccount.account?.type === AccountTypes.RESP_SINGLE
        || subAccount.account?.type === AccountTypes.RESP_FAMILY
        || subAccount.account?.type === AccountTypes.RESP_ADULT;
    }
    return false;
  };

  const [createExternalTransfer] = useMutation(EXTERNAL_TRANSFER, {
    variables: {
      input: {
        subAccountId: toPortfolio,
        institution: {
          id: financialInstitution?.id ?? undefined,
          name: financialInstitution?.name ?? '',
          physicalAddress: financialInstitution?.physicalAddress,
        },
        transferMethod: transferType,
        transferAccountNumber: accountNumber,
        transferAccount: accountType,
        amountCents: Math.round(Number(amount) * 100),
        clbAmountCents: isRESPAccount() ? Math.round(Number(clbAmount) * 100) : undefined,
        bctesgAmountCents: isRESPAccount() ? Math.round(Number(bctesgAmount) * 100) : undefined,
      },
    },
    onCompleted: (e) => setDepositTransferData(e),
    onError: () => setLoading(false),
  });

  const [fetchSelectedSubAccountContributions] = useLazyQuery(FETCH_SUB_ACCOUNT_NET_CONTRIBUTIONS, {
    variables: {
      subAccountId: toPortfolio,
    },
    onCompleted(data) {
      const netContributions = data.fetchSubAccount.subAccount.netContributionsHistoryCents;
      const totalContributionsAmountCents = netContributions.reduce((acc: number, cur: { amountCents: number }) => acc + cur.amountCents, 0);
      setSubAccountContributionsAmount(totalContributionsAmountCents);
    },
  });

  useEffect(() => {
    if (!isMounted.current) {
      let goalId = selectedGoalId;
      if (!selectedGoalId && userContext.goals && userContext.goals.length === 1) {
        goalId = userContext.goals[0].id;
        setToGoal(goalId);
      }
      const subAccounts = userContext?.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalId);
      if (subAccounts && subAccounts.length === 1) {
        setToPortfolio(subAccounts[0].id);
      }
      isMounted.current = true;
    }
  }, [userContext, selectedGoalId]);

  useEffect(() => {
    if (toGoal && toPortfolio && financialInstitution && amount && accountType && Number(amount) > 0 && transferType && accountNumber && (hasContributedEnough || minInvestAmount <= (Number(amount) || 0))) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [toGoal, toPortfolio, financialInstitution, amount, accountType, transferType, accountNumber, hasContributedEnough, minInvestAmount]);

  useEffect(() => {
    if (toPortfolio) {
      fetchSelectedSubAccountContributions();
    }
  }, [toPortfolio, fetchSelectedSubAccountContributions]);

  const clearAmount = (): void => setAmount('');

  const determineConfirmExternalTransferJourney = (): void => {
    sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.investPageConfirmExternalAccount).then();
    if (userContext.path === '/') {
      sendAnalyticsEvent(ovAnalyticsEvents.homePageInvestSelectToInvestPageConfirmExternalAccount).then();
    } else if (userContext.path === '/goal') {
      sendAnalyticsEvent(ovAnalyticsEvents.goalPageInvestSelectToInvestPageConfirmExternalAccount).then();
    } else if (userContext.path === '/account') {
      sendAnalyticsEvent(ovAnalyticsEvents.accountPageInvestSelectToInvestPageConfirmExternalAccount).then();
    }
  };

  const makeDeposit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    determineConfirmExternalTransferJourney();
    setLoading(true);
    createExternalTransfer().then();
  };

  const onGoalChanged = (goalid: string): void => {
    clearAmount();
    setToGoal(goalid);
    const subAccounts = userContext?.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalid);
    if (subAccounts && subAccounts.length === 1) {
      setToPortfolio(subAccounts[0].id);
    }
  };

  if (addInstitution) {
    return (
      <FeedbackModal
        open={addInstitution}
        onClose={() => setAddInstitution(false)}
        component={(
          <AddInstitutionWizard
            onCreated={(name, physicalAddress) => {
              setFinancialInstitution({
                id: undefined,
                name,
                physicalAddress,
              });
              setAddInstitution(false);
            }}
            onGoBack={() => setAddInstitution(false)}
            onInstitutionSelected={(item) => {
              setFinancialInstitution(item);
              setAddInstitution(false);
            }}
          />
        )}
      />
    );
  }
  const handleAmountChange = (value: string): void => {
    setAmount(value);
  };
  return (
    <OvForm buttonText={t('transfer:externalAccountDeposit.submitButton')} onSubmit={(e) => makeDeposit(e)} loading={loading} disableButton={disableButton}>
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('transfer:externalAccountDeposit.toGoal')}</Typography>
      <GoalDropDown
        dataTestId="toGoal-dropdown"
        selectedGoalId={selectedGoalId}
        value={toGoal ?? ''}
        onGoalChanged={(e) => {
          onGoalChanged(e);
        }}
        setSubAccounts={(values) => {
          setGoalSubAccounts(values);
        }}
        showOnlySelectedGoalAsOption={showOnlySelectedGoalAsOption}
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:externalAccountDeposit.toPortfolio')}</Typography>
      <PortfolioDropdown
        forDeposit
        onChange={(e) => setToPortfolio(e.id)}
        value={toPortfolio}
        dataTestId="toPortfolio-dropdown"
        goalId={toGoal ?? ''}
        subAccounts={goalSubAccounts}
        selectedSubAccountId={selectedSubAccountId}
        showOnlySelectedSubAccountAsOption={showOnlySelectedSubAccountAsOption}
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:externalAccountDeposit.financialInstitution')}</Typography>
      <SimpleInput
        testId="financial-institution-text-field"
        style={{ marginBottom: 0, height: '50px', marginTop: '-12px' }}
        inputProps={{ onClick: () => setAddInstitution(true) }}
        value={financialInstitution?.name ?? ''}
        label=""
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:externalAccountDeposit.accountNumber')}</Typography>
      <SimpleInput
        testId="account-number"
        value={accountNumber}
        style={{ height: '50px', marginTop: '-12px' }}
        onChange={(e) => setAccountNumber(e.target.value)}
        onClear={() => setAccountNumber('')}
        label=""
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:externalAccountDeposit.accountType')}</Typography>
      <DropdownInput
        dataTestId="account-type-dropdown"
        onChange={(e) => setAccountType(e.target.value as AccountTypes)}
        options={accountTypeOptions.map((item) => (
          { imageUrl: '', text: t(`transfer:externalAccountDeposit.accountTypeOptions.${item}`), value: item }
        ))}
        value={accountType as string}
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:externalAccountDeposit.transferType')}</Typography>
      <DropdownInput
        dataTestId="transfer-type-dropdown"
        onChange={(e) => setTransferType(e.target.value)}
        options={[
          { imageUrl: '', text: t('transfer:externalAccountDeposit.transferTypeOptions.fullTransfer'), value: 'ALL_IN_CASH' },
          { imageUrl: '', text: t('transfer:externalAccountDeposit.transferTypeOptions.partialTransfer'), value: 'PARTIAL_CASH' },
        ]}
        value={transferType}
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t(`transfer:externalAccountDeposit.${isRESPAccount() && transferType === 'PARTIAL_CASH' ? 'transferAmount' : 'amount'}`)}</Typography>
      <SimpleInput
        testId="amount"
        value={amount}
        style={{ height: '50px', marginTop: '-12px' }}
        onChange={(e) => handleAmountChange(e.target.value)}
        inputType={InputType.CURRENCY}
        inputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputprops: { value: amount },
        }}
        onClear={() => setAmount('')}
        label=""
      />
      {toPortfolio && !hasContributedEnough && minInvestAmount > (Number(amount) || 0) && <Typography variant="captionSectionerMedium" data-testid="error-min-initial-deposit" style={{ marginBottom: 0 }}>{t('transfer:errorMinInvestment', { minAmount: minInvestAmount.toFixed(2) })}</Typography>}
      {isRESPAccount() && transferType === 'PARTIAL_CASH' ? (
        <>
          <Box style={{ marginBottom: 24 }} />
          <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:externalAccountDeposit.clbTransferAmount')}</Typography>
          <SimpleInput
            testId="clbAmount"
            value={clbAmount}
            style={{ height: '50px', marginTop: '-12px' }}
            onChange={(e) => setClbAmount(e.target.value)}
            inputType={InputType.CURRENCY}
            inputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputprops: { value: clbAmount },
            }}
            onClear={() => setClbAmount('')}
            label=""
          />
          <Box style={{ marginBottom: 24 }} />
          <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:externalAccountDeposit.bcGrantTransferAmount')}</Typography>
          <SimpleInput
            testId="bctesgAmount"
            value={bctesgAmount}
            style={{ height: '50px', marginTop: '-12px' }}
            onChange={(e) => setbctesgAmount(e.target.value)}
            inputType={InputType.CURRENCY}
            inputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputprops: { value: bctesgAmount },
            }}
            onClear={() => setbctesgAmount('')}
            label=""
          />
        </>
      ) : undefined}
      <Box style={{ marginTop: 15 }}>
        <Disclaimer text={t('transfer:externalAccountDeposit.disclaimer')} />
      </Box>
      {isRESPAccount() ? (
        <Typography variant="captionSectionerMedium" sx={{ marginTop: '15px', fontWeight: '600', color: `${colors.disabledText} !important` }}>
          <Markup content={t('transfer:externalAccountDeposit.respNote')} />
          <Link href="https://onevest.zendesk.com/hc/en-us/articles/9923204590356-What-is-a-Registered-Education-Savings-Plan-RESP-" target="_blank" rel="noreferrer" style={{ color: colors.disabledText }}> here </Link>
        </Typography>
      ) : undefined}
      <Box style={{ marginBottom: 36 }} />
    </OvForm>
  );
};

ExternalAccountDeposit.defaultProps = {
  selectedGoalId: '',
  selectedSubAccountId: '',
  onSuccessTransfer: undefined,
  hideToastMessage: false,
  analyticsEvent: undefined,
  preSelectedAccountType: undefined,
  showOnlySelectedGoalAsOption: false,
  showOnlySelectedSubAccountAsOption: false,
};

export default ExternalAccountDeposit;
