import { Typography } from '@mui/material';
import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';
import React from 'react';
import OvForm from '../../common/wrappers/ovForm';

interface Props {
  setDisableTLH: (value: boolean) => void,
  onContinue?: () => void,
  loading?: boolean,
}

const DisableTLH = ({
  setDisableTLH, loading, onContinue,
}: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  return (
    <OvForm
      loading={loading}
      buttonText={t('account:disableTLH.buttonText')}
      onSubmit={(e) => {
        e.preventDefault();
        setDisableTLH(true);
        if (onContinue) {
          onContinue();
        }
      }}
      suffixButton={{
        onClick: () => {
          setDisableTLH(false);
          if (onContinue) {
            onContinue();
          }
        },
        text: t('account:disableTLH.suffixButtonText'),
        variant: 'secondary-large',
        dataTestId: '',
      }}
    >
      <Typography variant="heading2" data-testid="title">{t('account:disableTLH.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>
        <Markup content={t('account:disableTLH.subTitle')} />
      </Typography>
    </OvForm>
  );
};

DisableTLH.defaultProps = {
  onContinue: undefined,
  loading: false,
};

export default DisableTLH;
