import { colors } from '../../theme/colors';

export const styles = {
  documentContainer: {
    width: '398px',
    padding: '29px !important',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '380px',
  },
  depositInnerContainer: {
    typography: 'body1',
    backgroundColor: colors.transparent,
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column' as const,
    flexGrow: 1,
  },
  closeBox: {
    width: '26px',
    height: '26px',
    textAlign: 'center',
    top: '-6px',
    marginTop: '18px',
    marginRight: '24px',
    marginBottom: '22px',
    cursor: 'pointer',
    background: colors.backButtonBackground,
    borderRadius: '3.42105px',
  },
  detailBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterButton: {
    margin: '0 8px 16px 0',
    padding: '8px',
    height: 34,
    width: 34,
    borderRadius: '50%',
    border: 'none',
  },
  filterNumber: {
    position: 'absolute',
    right: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#DC2626',
    borderRadius: '2px',
    width: 12,
    height: 12,
    fontSize: '8px',
    fontWeight: 600,
    color: 'white',
  },
};
