import { useMutation } from '@apollo/client';
import {
  Typography, Button,
} from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { setAuthToken } from '../../utils/authToken';
import {
  START_PHONE_MFA, CONFIRM_PHONE_MFA, TokenResponse, START_PHONE_MFA_CHALLENGE,
} from './graphql';
import PinInput from '../common/inputs/pinInput';
import { formatUSNumber, millisToMinutesAndSeconds } from '../../utils/commonMethods';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import OvForm from '../common/wrappers/ovForm';
import HelpLink from './helpLink';
import { organizationIdVar } from '../../utils/localVariables';

export interface UserState {
  primaryEmail?: string,
  phone?: string,
  verificationToken?: string,
  token?: TokenResponse,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  goBack?: () => void,
  skipOtpMutations?: boolean,
  displayHelpLink?: boolean,
}

const PhoneVerification = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const skipOtpMutations = props.skipOtpMutations || false;
  const { t } = useTranslation(['base', 'signUp']);
  const [validState, setValidateState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(!!user.phone && !skipOtpMutations);
  const [resendLink, setResendLink] = useState({ enabled: true, timerMs: 0 });

  const organizationId = organizationIdVar() || process.env.REACT_APP_ONEVEST_APP_ORGANIZATION_ID;

  const checkFieldsValidity = async (): Promise<boolean> => {
    const tokenValidity = await yup.string()
      .required()
      .min(6)
      .max(6)
      .isValid(user.verificationToken);
    setValidateState(tokenValidity);
    return tokenValidity;
  };

  const sendOtpError = (): void => {
    console.log({ event: 'PHONE_IS_INVALID' });
    updateUserState({ ...user, phone: '' });
    if (props.goBack) props.goBack();
    setSendingOtp(false);
  };

  const sendOtpOnCompleted = (data: { startMultifactorEnrollment: { oobCode: string } }): void => {
    console.log({ event: 'SMS_OTP_SENT', phone: user.phone });
    updateUserState({
      ...user,
      verificationToken: '',
      token: {
        mfaToken: user.token?.mfaToken,
        oobCode: data.startMultifactorEnrollment.oobCode,
      },
    });
    setResendLink({ enabled: false, timerMs: 30000 });
    setSendingOtp(false);
  };
  const [sendOtp] = useMutation(START_PHONE_MFA, {
    variables: {
      input: {
        primaryEmail: user.primaryEmail,
        phoneNumber: user.phone,
        mfaToken: user.token?.mfaToken,
        organizationId,
      },
    },
    onCompleted: sendOtpOnCompleted,
    onError: sendOtpError,
  });

  const resendOtpChallengeOnCompleted = (data: { startMultifactorChallenge: { oobCode: string } }): void => {
    console.log({ event: 'SMS_OTP_SENT' });
    updateUserState({
      ...user,
      verificationToken: '',
      token: {
        mfaToken: user.token?.mfaToken,
        oobCode: data.startMultifactorChallenge.oobCode,
      },
    });
    setResendLink({ enabled: false, timerMs: 30000 });
    setSendingOtp(false);
  };
  const [resendOtpChallenge] = useMutation(START_PHONE_MFA_CHALLENGE, {
    variables: {
      input: {
        primaryEmail: user.primaryEmail,
        mfaToken: user.token?.mfaToken,
        organizationId,
      },
    },
    onCompleted: resendOtpChallengeOnCompleted,
  });

  const confirmOtpOnCompleted = (data: { verifyMultifactorToken: { token: TokenResponse } }): void => {
    console.log({ event: 'SMS_OTP_VALIDATED', phone: user.phone });
    updateUserState({
      ...user,
      verificationToken: '',
      token: {
        accessToken: data.verifyMultifactorToken.token.accessToken,
        refreshToken: data.verifyMultifactorToken.token.refreshToken,
        mfaToken: data.verifyMultifactorToken.token.mfaToken,
        oobCode: data.verifyMultifactorToken.token.oobCode,
      },
    });
    if (data.verifyMultifactorToken.token.accessToken) {
      console.log({ event: 'USER_IS_AUTHENTICATED' });
      setAuthToken(data.verifyMultifactorToken.token);
    } else {
      setLoading(false);
      if (props.onContinue) props.onContinue();
    }
  };
  const [confirmOtp] = useMutation(CONFIRM_PHONE_MFA, {
    variables: {
      input: {
        primaryEmail: user.primaryEmail,
        verificationToken: user.verificationToken,
        mfaToken: user.token?.mfaToken,
        oobCode: user.token?.oobCode,
        organizationId,
      },
    },
    onCompleted: confirmOtpOnCompleted,
    onError: () => setLoading(false),
  });

  useEffect(() => {
    if (user.phone && !skipOtpMutations) {
      sendOtp().then();
    }
  }, [sendOtp, user.phone, skipOtpMutations]);

  useEffect(() => {
    if (!resendLink.enabled) {
      // countdown timer
      const timer = setTimeout(() => setResendLink((prev) => ({
        enabled: (prev.timerMs - 1000) <= 0,
        timerMs: prev.timerMs - 1000,
      })), 1000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [resendLink]);

  const onResendOtp = (): void => {
    if (user.phone) {
      sendOtp().then();
    } else {
      resendOtpChallenge().then();
    }
    setResendLink({ enabled: false, timerMs: 30000 });
  };

  const onContinue = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    if (skipOtpMutations && props.onContinue) {
      props.onContinue();
      return;
    }
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingEnterPhoneVerification).then();
    if (!await checkFieldsValidity()) return;
    setLoading(true);
    confirmOtp().then();
  };

  if (sendingOtp) {
    return <OvLoadingIndicator />;
  }

  return (
    <OvForm loading={loading} onSubmit={onContinue}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: '1 0 auto' }}>
          <Typography variant="heading2" style={{ marginBottom: 16 }}>{t('signUp:phoneVerification.title')}</Typography>
          <Typography variant="paragraph3" style={{ marginBottom: 32 }}>
            {user.phone ? t('signUp:phoneVerification.subTitle', { phone: formatUSNumber(user.phone, true) }) : t('signUp:phoneVerification.subTitleWithoutPhone')}
          </Typography>
          <PinInput
            error={!validState}
            label={t('base:input.pinLabel')}
            style={{ marginBottom: 32 }}
            onChange={(value) => {
              if (!validState) { setValidateState(true); }
              updateUserState({ ...user, verificationToken: value });
            }}
          />
          {skipOtpMutations ? null : (
            <Button variant="text-large" disabled={!resendLink.enabled} onClick={onResendOtp} data-testid="resend-otp-button" style={{ marginBottom: 32 }}>
              {`${t('base:input.pinResendLink')} ${!resendLink.enabled ? `(${millisToMinutesAndSeconds(resendLink.timerMs)})` : ''}`}
            </Button>
          )}
        </div>
        {props.displayHelpLink ? <HelpLink /> : null}
      </div>
    </OvForm>
  );
};

PhoneVerification.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  skipOtpMutations: false,
  displayHelpLink: false,
};

export default PhoneVerification;
