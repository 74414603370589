import { Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../common/modals/confirmationModal';
import ButtonCardListItem, { ButtonCardList } from '../common/lists/buttonCardListItem';
import { AccountTypeAttributes, AccountTypes, accountTypesRESP } from './resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import { SubAccount } from '../../utils/commonGraphql';
import ModalListItem from '../common/lists/modalListItem';
import { SubAccountRef } from './accountType';

interface Props {
  setSubAccountRef: (att: SubAccountRef) => void,
  onContinue?: () => void,
  currentSubAccount?: SubAccount | {
    account?: {
      type?: AccountTypes;
    },
    theme?: {
      id?: string;
    },
  }
}

const RespAccountType = ({ setSubAccountRef, onContinue, currentSubAccount }: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const [displayConfirmationMessage, setDisplayConfirmationMessage] = useState(false);
  const dialogConfirmClick = (): void => {
    setDisplayConfirmationMessage(false);
    if (onContinue) onContinue();
  };

  const dialogClose = (): void => {
    setDisplayConfirmationMessage(false);
  };

  const getAccountTitle = (key: AccountTypes): string => {
    if (key === AccountTypes.RESP_SINGLE) return t('account:resources.typeTitle.RESP_SINGLE');
    if (key === AccountTypes.RESP_FAMILY) return t('account:resources.typeTitle.RESP_FAMILY');
    if (key === AccountTypes.RESP_ADULT) return t('account:resources.typeTitle.RESP_ADULT');
    return '';
  };

  const handleClick = async (item: AccountTypeAttributes): Promise<void> => {
    console.log({ event: 'ACCOUNT_TYPE_SELECTED', type: item.key });
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectAccountType).then();
    setSubAccountRef({ accountType: item.key, name: item.title });
    setDisplayConfirmationMessage(true);
  };
  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2" data-testid="title">{t('account:accountTypeRESP.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('account:accountTypeRESP.subTitle')}</Typography>
      <ButtonCardList>
        {accountTypesRESP.map((item: AccountTypeAttributes) => (
          <ButtonCardListItem
            key={item.key}
            header={getAccountTitle(item.key)}
            description={item.description}
            onClick={() => handleClick(item)}
            isSelected={currentSubAccount?.account?.type === item.key}
          />
        ))}
      </ButtonCardList>
      <ConfirmationModal
        title={t('account:accountType.confirmationDialogTitle')}
        description={t('account:accountTypeRESP.confirmationDialogDescription')}
        buttonText={t('account:accountTypeRESP.confirmationDialogButton')}
        open={displayConfirmationMessage}
        onButtonClick={dialogConfirmClick}
        onClose={dialogClose}>
        <ModalListItem index={1} title={t('account:accountTypeRESP.stepOneTitle')} body={t('account:accountTypeRESP.stepOneBody')} marginBottom={4} />
        <ModalListItem index={2} title={t('account:accountTypeRESP.stepTwoTitle')} body={t('account:accountTypeRESP.stepTwoBody')} marginBottom={4} />
        <ModalListItem index={3} title={t('account:accountTypeRESP.stepThreeTitle')} body={t('account:accountTypeRESP.stepThreeBody')} marginBottom={4} />
      </ConfirmationModal>
    </OvForm>
  );
};

RespAccountType.defaultProps = {
  onContinue: undefined,
  currentSubAccount: undefined,
};

export default RespAccountType;
