import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/system/createTheme';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTranslation } from 'react-i18next';
import { formatMoneyValue } from '../../utils/commonMethods';
import { useContextTheme } from '../../providers/contextThemeProvider';

interface ScheduledTransfer {
  amountCents: number
  frequency: string
  id: string,
  type: string,
  scheduledDate: string,
  state: string,
  bankAccount: {
    id: string,
  },
  subAccount: {
    id: string,
    theme: {
      key: string,
      translatedName: {
        en: string,
        fr: string,
      },
    },
    account: {
      id: string,
      type: string,
    },
  }
}

interface Props {
  scheduledTransfer: ScheduledTransfer,
  onCancel: () => void,
  onEdit: (scheduledTransfer: ScheduledTransfer) => void,
  sx?: SxProps<Theme>,
}

const style = {
  outerBox: {
    height: '70px',
    padding: '16px 12px',
    boxSizing: 'border-box' as const,
    border: '1px solid rgba(218, 228, 237, 0.4)',
    boxShadow: '0px 4px 10px rgba(234, 236, 239, 0.25)',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const AutoScheduleListItem = ({
  scheduledTransfer,
  onCancel,
  onEdit,
  sx,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base']);
  const { colors } = useContextTheme();
  const getFrequency = (frequency: string): string => {
    const frequencyMap = new Map<string, string>([
      ['WEEKLY', 'Weekly'],
      ['BI_WEEKLY', 'Biweekly'],
      ['MONTHLY', 'Monthly'],
    ]);
    return frequencyMap.get(frequency) ?? '';
  };
  return (
    <Box style={style.outerBox} sx={sx}>
      <Box display="flex" alignItems="center">
        <CloseRoundedIcon data-testid="close-icon" sx={{ height: '18px', cursor: 'pointer', color: colors.exitButtonIconColour }} onClick={() => onCancel()} />
        <Box mr={1.8} />
        <Box>
          <Typography variant="bodySub1" style={{ marginBottom: 0, fontWeight: 500 }}>
            {`${scheduledTransfer.subAccount.account.type} - ${scheduledTransfer?.subAccount?.theme?.translatedName?.en ?? ''}`}
          </Typography>
          <Box mb={0.5} />
          <Typography variant="heading5" style={{ marginBottom: 0, color: colors.neutral30 }}>{`${formatMoneyValue(scheduledTransfer.amountCents)}  ${getFrequency(scheduledTransfer.frequency)}`}</Typography>
        </Box>
      </Box>
      <Typography onClick={() => onEdit(scheduledTransfer)} variant="bodySub1" sx={{ marginBottom: 0, color: colors.link, cursor: 'pointer' }}>{t('base:link.edit')}</Typography>
    </Box>
  );
};

AutoScheduleListItem.defaultProps = {
  sx: undefined,
};

export default AutoScheduleListItem;
