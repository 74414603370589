import { gql } from '@apollo/client';

export const FETCH_SCHEDULED_TRANSFERS = gql`
  query fetchScheduledTransfers($input: FetchScheduledTransfersInput!) {
    fetchScheduledTransfers(input: $input) {
      scheduledTransfers {
        id
        amountCents
        frequency
        type
        scheduledDate
        state
        bankAccount {
          id
        }
        subAccount {
          id,
          theme {
            id
            key,
            translatedName {
              en,
              fr,
            },
          },
          account {
            id,
            type,
          },
        }
      }
      totalCount
    }
  }
`;

export const UPDATE_SCHEDULED_TRANSFER = gql`
  mutation updateScheduledTransfer($input: UpdateScheduledTransferInput!) {
    updateScheduledTransfer(input: $input) {
      scheduledTransfer {
        id
        state
      }
    }
  }
`;

export const CANCEL_SCHEDULED_TRANSFER = gql`
  mutation cancelScheduledTransfer($scheduledTransferId: ObjectID!) {
    cancelScheduledTransfer(scheduledTransferId: $scheduledTransferId) {
      scheduledTransfer {
        id
        state
      }
    }
  }
`;
