import { useMutation } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import { employementOptions } from '../resources';
import { UPDATE_USER } from '../graphql';
import ButtonListItem from '../../common/lists/buttonListItem';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  employmentStatus?: string,
  employmentSituation?: string,
  employmentSituationOtherDescription?: string,
  incompleteFields?: string[],
}

export interface EmployementStatus {
  tag: string,
  key: string,
  translatedName: string,
}

interface Input {
  employmentStatus?: string,
  employmentSituation?: string | null,
  employmentSituationOtherDescription?: string | null,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  goToNamedStep?: (stepName: string) => void,
  cached?: boolean,
  title?: string,
  subTitle?: string,
  analyticsEvent?: string,
  onContinueStepUnEmployed?: () => void,
}

const SelecteEmployement = ({
  user,
  updateUserState,
  cached,
  title,
  subTitle,
  analyticsEvent,
  onContinueStepUnEmployed,
  ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const empStatusOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'EMPLOYEMENT_STATUS_UPDATED', EmploymentStatus: user.employmentStatus });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    if (cached && user.employmentStatus === 'UNEMPLOYED' && props.goToNamedStep) {
      props.goToNamedStep('personalDetails');
    } else if (props.onContinue) props.onContinue();
  };
  const input: Input = {
    employmentStatus: user.employmentStatus,
    employmentSituation: user.employmentSituation,
    employmentSituationOtherDescription: user.employmentSituationOtherDescription,
  };
  const [updateEmploymentData] = useMutation(UPDATE_USER, {
    variables: {
      input,
    },
    onCompleted: empStatusOnCompleted,
  });

  const updateUserStateAsync = (att: UserState): Promise<void> => Promise.resolve(updateUserState({ ...user, ...att }));
  const handleClick = async (item: EmployementStatus): Promise<void> => {
    console.log({ event: 'UPDATE_EMPLOYMENT_STATUS', EmploymentStatus: item.key });
    sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.onBoardingSelectEmploymentStatus).then();
    if (cached) {
      await updateUserStateAsync({ employmentStatus: item.key });
      if (item.key === 'UNEMPLOYED' && onContinueStepUnEmployed) {
        onContinueStepUnEmployed();
      } else if (props.onContinue) {
        props.onContinue();
      }
    } else if (item.key === 'RETIRED') {
      await updateUserStateAsync({ employmentStatus: item.key, employmentSituation: 'OTHER', employmentSituationOtherDescription: item.key });
      updateEmploymentData({
        variables: {
          input: {
            employmentStatus: item.key,
            employmentSituation: 'OTHER',
            employmentSituationOtherDescription: item.key,
          },
        },
      }).then();
    } else if (user.employmentSituation || user.employmentSituationOtherDescription) {
      await updateUserStateAsync({ employmentStatus: item.key, employmentSituation: 'OTHER', employmentSituationOtherDescription: undefined });
      updateEmploymentData({
        variables: {
          input: {
            employmentStatus: item.key,
            employmentSituation: 'OTHER',
            employmentSituationOtherDescription: null,
          },
        },
      }).then();
    } else {
      await updateUserStateAsync({ employmentStatus: item.key });
      updateEmploymentData({ variables: { input: { employmentStatus: item.key } } }).then();
    }
  };
  return (
    <OvForm
      hasButton={false}
    >
      <Typography variant="heading2">{title ?? t('user:employement.selectEmployement.title')}</Typography>
      <Typography variant="paragraph3">{subTitle ?? t('user:employement.selectEmployement.subTitle')}</Typography>
      <List sx={{ maxHeight: '400px', overflowY: 'auto' }}>
        {employementOptions.map((value: EmployementStatus) => (
          <Box key={value.key}>
            <ButtonListItem
              key={value.key}
              text={value.translatedName}
              onClick={() => {
                if (value.key) {
                  handleClick(value).then();
                }
              }}
              isSelected={(user.employmentStatus && value.key === user.employmentStatus) || false}
            />
          </Box>
        ))}
      </List>
    </OvForm>
  );
};

SelecteEmployement.defaultProps = {
  onContinue: undefined,
  goToNamedStep: undefined,
  cached: false,
  title: undefined,
  subTitle: undefined,
  analyticsEvent: undefined,
  onContinueStepUnEmployed: undefined,
};

export default SelecteEmployement;
