import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import CustomAssetProgressBar from '../loaders/customAssetsProgressBar';
import {
  defaultGraphColors,
  getAssetBarIndeterminateColor,
  PrimaryAssetClassHoldings,
  SecondaryAssetClassHoldings,
} from '../../holdings/resources';
import { translateBackend } from '../../../assets/i18n/config';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface HoldingsItemProps {
  width?: number,
  primaryHoldingIndex: number,
  primaryAssetClassHoldings: PrimaryAssetClassHoldings,
  onClick?: (secondaryAsset: SecondaryAssetClassHoldings) => void,
  allPrimaryAssetClassHoldings: PrimaryAssetClassHoldings[],
}

const HoldingsListItem2 = ({ primaryHoldingIndex, ...props }: HoldingsItemProps): JSX.Element => {
  const { colors } = useContextTheme();
  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: 'none !important',
        backgroundColor: colors.baseComponentBackground,
        '&:before': {
          backgroundColor: colors.baseComponentBackground,
        },
        '.MuiAccordionSummary-expandIconWrapper': {
          marginTop: '35px',
          marginRight: '15px',
        },
        '.MuiAccordionSummary-root': {
          padding: '0px',
        },
        '.MuiAccordionDetails-root': {
          padding: '8px 0px 0px',
        },
        '.MuiCollapse-root': {
          backgroundColor: colors.secondaryAssetClassBackPanel,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: colors.ovBlack }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box sx={{ width: '100%', paddingLeft: '15px' }}>
          <Box display="flex">
            <Typography variant="captionSectionerBold">{translateBackend(props.primaryAssetClassHoldings.assetClass.translatedName ?? '')}</Typography>
          </Box>
          <Box sx={{ width: '100%' }} mt={2} display="flex" alignItems="center" justifyContent="space-between">
            <CustomAssetProgressBar
              sx={{ width: `${props.width}px` }}
              data={props.allPrimaryAssetClassHoldings.map((item, index) => ({
                key: `section-${item.assetClass.key}-${index}`,
                percentage: item?.percentage ?? 0,
                position: 1,
                colorCode: item.assetClass.key === props.primaryAssetClassHoldings.assetClass.key
                  ? (item.color ?? defaultGraphColors(colors)[primaryHoldingIndex]) : getAssetBarIndeterminateColor(index),
              }))}
            />
            <Typography sx={{ marginLeft: '10px' }} variant="captionSectionerBold">{`${Math.round(props.primaryAssetClassHoldings.percentage)}%`}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ width: '100%' }}>
        <Box mb={2} sx={{ paddingX: '15px' }}>
          <Typography>{translateBackend(props.primaryAssetClassHoldings.assetClass.translatedDescription ?? '')}</Typography>
        </Box>
        <Box sx={{ paddingX: '15px' }}>
          {
            (props?.primaryAssetClassHoldings.secondaryAssetClassHoldings ?? []).map((item: SecondaryAssetClassHoldings) => (
              <Box key={item.assetClass.key} onClick={() => props.onClick && props?.onClick(item)} display="flex" py={2} justifyContent="space-between" alignItems="center" style={{ width: '100%', borderBottom: `1px solid ${colors.neutral10}` }}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box minHeight={16} minWidth={16} bgcolor={defaultGraphColors(colors)[primaryHoldingIndex]} borderRadius={1} />
                  <Typography textAlign="left" variant="captionSectionerBold" ml={1.8} mb={0}>{translateBackend(item.assetClass.translatedName)}</Typography>
                </Box>
                <Typography paddingLeft={1} variant="paragraph2">{`${Math.round(item?.percentage ?? 0)}%`}</Typography>
              </Box>
            ))
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

HoldingsListItem2.defaultProps = {
  onClick: undefined,
  width: undefined,
};
export default HoldingsListItem2;
