/* eslint-disable  no-nested-ternary */

import * as React from 'react';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import RoundedCheckbox from '../controllers/roundedCheckbox';
import OvRadioButton from '../controllers/ovRadioButton';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface ButtonListItemProps {
  prefixIcon?: string,
  text: string,
  hasCheckField?: boolean,
  hasRadioField?: boolean,
  suffixText?: string,
  checked?: boolean,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  marginTop?: number,
  marginRight?: number,
  marginBottom?: number,
  marginLeft?: number,
  margin?: number,
  isSelected?: boolean,
  disabled?: boolean,
}

const ButtonListItem = ({ ...props }: ButtonListItemProps): JSX.Element => {
  const { colors } = useContextTheme();
  return (
    <Box mb={props.marginBottom} mt={props.marginTop} mr={props.marginRight} ml={props.marginLeft} m={props.margin}>
      <Button variant="button-list-item" disabled={props.disabled} style={{ backgroundColor: props.isSelected ? colors.buttonItemBackground : colors.baseComponentBackground }} onClick={props.onClick}>
        <Box display="flex" flexDirection="row" alignItems="center">
          {props.prefixIcon ? <img src={props.prefixIcon} alt="icon" width={40} /> : null}
          <Typography variant="bodySub2" textAlign="left" ml={props.prefixIcon ? 1.2 : 0}>{props.text}</Typography>
        </Box>
        {props.suffixText ? <Typography variant="bodySub1" color={colors.link}>{props.suffixText}</Typography> : props.hasCheckField ? <RoundedCheckbox checked={props.checked} indeterminate={!props.checked} /> : props.hasRadioField ? <OvRadioButton checked={props.checked} /> : <ChevronRightRoundedIcon className="black" />}
      </Button>
    </Box>
  );
};

ButtonListItem.defaultProps = {
  prefixIcon: null,
  hasCheckField: false,
  suffixText: null,
  checked: false,
  marginTop: undefined,
  marginRight: undefined,
  marginBottom: 1,
  marginLeft: undefined,
  margin: undefined,
  hasRadioField: undefined,
  isSelected: undefined,
  disabled: undefined,
};

export default ButtonListItem;
