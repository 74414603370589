import { Box, CircularProgress } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContextTheme } from '../../../providers/contextThemeProvider';

const OvLoadingIndicator = (): JSX.Element => {
  const matches = useMediaQuery('(max-width:600px)');
  const { colors } = useContextTheme();
  return matches ? (
    <Box height="100%" width="100%">
      <CircularProgress
        data-testid="loading-element"
        style={{
          position: 'absolute',
          top: '44%',
          right: '44%',
          bottom: '44%',
          left: '44%',
          color: colors.loading,
        }}
      />
    </Box>
  )
    : (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
        <CircularProgress data-testid="loading-element" sx={{ color: colors.loading }} />
      </Box>
    );
};

export default OvLoadingIndicator;
