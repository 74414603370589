import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { translatedUrl } from '../../assets/i18n/config';
import { colors } from '../../theme/colors';

const HelpLink = (): JSX.Element => {
  const { t } = useTranslation(['signUp']);
  return (
    <Typography variant="captionDescriptorMedium" className="button-disclaimer-responsive">
      {t('signUp:helpLink.text')}
      <Link style={{ color: colors.link }} href={translatedUrl('https://onevest.zendesk.com/hc/en-us/articles/4713779257108-How-does-passwordless-login-work-', 'https://onevest.zendesk.com/hc/fr-ca/articles/4713779257108-Comment-fonctionne-la-connexion-sans-mot-de-passe-')} underline="always" target="_blank">{t('signUp:helpLink.link')}</Link>
    </Typography>
  );
};

export default HelpLink;
