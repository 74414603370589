import { Typography, List } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../graphql';
import OvForm from '../../common/wrappers/ovForm';
import SimpleInput from '../../common/inputs/simpleInput';
import { pmamEmploymentSituation } from '../resources';
import ButtonListItem from '../../common/lists/buttonListItem';

export interface UserState {
  employmentSituation?: string,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  cached?: boolean,
  title?: string,
  subTitle?: string,
  analyticsEvent?: string,
}

const EmploymentSituation = ({
  user, updateUserState, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const [employmentSituationOtherDescription, setEmploymentSituationOtherDescription] = useState<string>('');
  const [employmentSituation, setEmploymentSituation] = useState<string>('');
  const updateEmploymentSituationOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };
  const [updateEmploymentSituation] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        employmentSituation: user.employmentSituation,
        employmentSituationOtherDescription: employmentSituation === 'OTHER' ? employmentSituationOtherDescription : undefined,
      },
    },
    onCompleted: updateEmploymentSituationOnCompleted,
    onError: () => setLoading(false),
  });
  const onContinueClick = (): void => {
    setLoading(true);
    updateEmploymentSituation().then();
  };
  const handleValueSelect = (key: string): void => {
    setEmploymentSituation(key);
    updateUserState({ ...user, employmentSituation: key });
    if (key !== 'OTHER') {
      updateEmploymentSituation({
        variables: {
          input: {
            employmentSituation: key, employmentSituationOtherDescription: undefined,
          },
        },
      }).then();
    }
  };
  return (
    <OvForm
      loading={loading}
      onSubmit={onContinueClick}
      disableButton={!employmentSituationOtherDescription}
      submitButton={false}
      hasButton={employmentSituation === 'OTHER'}
    >
      <Typography variant="heading2">{t('user:employement.employmentSituation.title')}</Typography>
      <Typography variant="paragraph3">{t('user:employement.employmentSituation.subTitle')}</Typography>
      <List className="goal-list">
        {pmamEmploymentSituation.map((value: {key: string, translatedName: string}) => (
          <ButtonListItem
            key={value.key}
            text={value.translatedName}
            isSelected={employmentSituation === value.key}
            onClick={() => {
              if (value) {
                handleValueSelect(value.key);
              }
            }}
          />
        ))}
        {
          employmentSituation === 'OTHER' ? (
            <SimpleInput
              testId="other-option-description-input"
              label=""
              value={employmentSituationOtherDescription}
              style={{ marginBottom: 60 }}
              subtitle=""
              required
              onChange={(event) => setEmploymentSituationOtherDescription(event.target.value)}
              data-testid="other-option-description-input"
              onClear={() => setEmploymentSituationOtherDescription('')}
              inputProps={{
                autoFocus: employmentSituation === 'OTHER',
              }}
            />
          ) : undefined
        }
      </List>
    </OvForm>
  );
};

EmploymentSituation.defaultProps = {
  onContinue: undefined,
  cached: false,
  title: undefined,
  subTitle: undefined,
  analyticsEvent: undefined,
};

export default EmploymentSituation;
