import * as React from 'react';
import { useQuery } from '@apollo/client';
import DropdownInput from '../inputs/dropdownInput';
import { HouseholdViewTypes, SubAccount } from '../../../utils/commonGraphql';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import { FETCH_MY_GOALS } from '../../../pages/dashboard/graphql';
import { Goal } from '../../../pages/dashboard';

interface goalDropdownProps {
  onGoalChanged: (e: string) => void,
  setSubAccounts?: (subAccounts: SubAccount[]) => void,
  value: string,
  selectedGoalId?: string,
  showOnlySelectedGoalAsOption?: boolean,
  dataTestId?: string,
}

const GoalDropDown = ({
  selectedGoalId,
  value,
  onGoalChanged,
  setSubAccounts,
  showOnlySelectedGoalAsOption,
  dataTestId,
}: goalDropdownProps): JSX.Element => {
  const [goals, setGoals] = React.useState<Goal[]>([]);

  const setGoalData = (data: {
    fetchGoals: {
      goals: Goal[],
    }
  }): void => {
    console.log({ event: 'GOALS', goals: data.fetchGoals.goals });
    const activeGoals = data.fetchGoals.goals.filter((goal) => goal.state?.toLowerCase() === 'active');
    setGoals(activeGoals);
    if (value) {
      const subAccounts = activeGoals.find((x) => x.id === value)?.subAccounts;
      if (subAccounts && setSubAccounts) setSubAccounts(subAccounts);
    }
  };

  useQuery(FETCH_MY_GOALS, {
    variables: {
      startDate: undefined,
      forUserId: undefined,
      householdViewType: HouseholdViewTypes.INDIVIDUAL,
    },
    fetchPolicy: 'network-only',
    onCompleted: setGoalData,
  });

  return (
    <DropdownInput
      dataTestId={dataTestId ?? 'from-dropdown'}
      onChange={(e) => {
        sendAnalyticsEvent(ovAnalyticsEvents.investPageGoalSelect).then();
        onGoalChanged(e.target.value);
        const subAccounts = goals.find((x) => x.id === e.target.value)?.subAccounts;
        if (subAccounts && setSubAccounts) setSubAccounts(subAccounts);
      }}
      options={
        (goals?.filter((item) => (showOnlySelectedGoalAsOption ? item.id === selectedGoalId : true)) ?? []).map((goal) => (
          { imageUrl: '', text: goal?.name ?? '', value: goal.id }
        ))
      }
      value={goals.map((x) => x.id).includes(value) ? value : ''}
    />
  );
};

GoalDropDown.defaultProps = {
  selectedGoalId: undefined,
  dataTestId: undefined,
  setSubAccounts: undefined,
  showOnlySelectedGoalAsOption: false,
};

export default GoalDropDown;
