/* eslint-disable @typescript-eslint/no-explicit-any */
import DesjardinsLogo from '../../assets/images/partners/desjardins.png';
import VanguardLogo from '../../assets/images/partners/vanguard.png';
import AtbLogo from '../../assets/images/partners/atb.png';
import PmamLogo from '../../assets/images/partners/pman.png';
import PeoplesTrustLogo from '../../assets/images/partners/peoplestrust.png';
import SimpliiLogo from '../../assets/images/partners/simplii.jpeg';
import { getTheme } from '../../utils/platformUtils';

export const logoAssets: any = {
  desjardins: { src: DesjardinsLogo, style: { height: '50px' } },
  vanguard: { src: VanguardLogo },
  atb: { src: AtbLogo },
  pmam: { src: PmamLogo },
  peoplestrust: { src: PeoplesTrustLogo },
  simplii: { src: SimpliiLogo },
};

export const getPartnerLogo = (logoUrl?: string): { src: string, style?: any } | null => {
  if (logoUrl) return { src: logoUrl };
  const theme = getTheme();
  return logoAssets[theme ?? ''] ?? null;
};
