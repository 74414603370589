import { Box, List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ConfirmationModal from '../common/modals/confirmationModal';
import {
  AffiliationRelations,
  AffiliationTypes,
  BinaryAnswer,
  binaryAnswers,
} from './resources';
import OvForm from '../common/wrappers/ovForm';
import ButtonListItem from '../common/lists/buttonListItem';
import { userIdVar } from '../../utils/localVariables';
import { Affiliation } from './createAccountAuxiliaryWizard';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';

interface Props {
  isRespFamily?: boolean,
  onContinue?: () => void,
  setGuardianAffiliation: (att: Affiliation) => void,
}

const BeneficiaryGuardian = ({ isRespFamily, onContinue, setGuardianAffiliation }: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const [displayConfirmationMessage, setDisplayConfirmationMessage] = useState(false);

  const dialogClose = (): void => {
    setDisplayConfirmationMessage(false);
  };

  const updateGuardianAffiliation = (): void => {
    setGuardianAffiliation({
      userId: userIdVar(),
      type: AffiliationTypes.OTHER,
      relation: AffiliationRelations.GUARDIAN,
      signatureRequired: false,
    });
    if (onContinue) onContinue();
  };

  const handleClick = async (item: Record<string, string>): Promise<void> => {
    if (item.key === BinaryAnswer.YES) {
      sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryLegalGaurdianYesButtonSelect).then();
      updateGuardianAffiliation();
    } else {
      sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryLegalGaurdianNoButtonSelect).then();
      setDisplayConfirmationMessage(true);
    }
  };

  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2" data-testid="title">{t(`beneficiary.guardian.${isRespFamily ? 'title2' : 'title'}`)}</Typography>
      <Typography variant="paragraph3" whiteSpace="pre-wrap" style={{ marginBottom: 32 }}>{t('beneficiary.guardian.subtitle')}</Typography>
      <List sx={{ maxHeight: '250px', overflowY: 'auto' }}>
        {binaryAnswers.map((item: Record<string, string>) => (
          <Box key={item.key}>
            <ButtonListItem
              key={item.key}
              text={item.value}
              onClick={() => handleClick(item)}
            />
            <Box mb={1} />
          </Box>
        ))}
      </List>
      <ConfirmationModal
        title={t('beneficiary.support.title')}
        description={t('beneficiary.support.subtitle', { relationship: t('beneficiary.support.legalGuardian') })}
        buttonText={t('beneficiary.support.understand')}
        open={displayConfirmationMessage}
        onButtonClick={dialogClose}
        onClose={dialogClose}
      />
    </OvForm>
  );
};

BeneficiaryGuardian.defaultProps = {
  isRespFamily: false,
  onContinue: undefined,
};

export default BeneficiaryGuardian;
