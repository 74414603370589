import { Box, Typography } from '@mui/material';
import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import OvForm from '../common/wrappers/ovForm';

interface Props {
  onContinue?: () => void,
  showLoading: boolean,
}

const ThemeConfirmation = ({ onContinue, showLoading } : Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const nextStep = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (onContinue) onContinue();
  };
  return (
    <OvForm buttonText="Confirm" onSubmit={nextStep} loading={showLoading}>
      <Typography variant="heading3">{t('account:themeConfirmation.title')}</Typography>
      <Box mb={0.5} />
      <Typography variant="paragraphMain" style={{ marginBottom: 32 }}>{t('account:themeConfirmation.body')}</Typography>
    </OvForm>
  );
};

ThemeConfirmation.defaultProps = {
  onContinue: undefined,
};

export default ThemeConfirmation;
