import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { Checkbox } from '@mui/material';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface Props {
  checked?: boolean,
  indeterminate?: boolean,
  onChange?: (checked: boolean) => void,
}

const RoundedCheckbox = ({ ...props }: Props): JSX.Element => {
  const { colors } = useContextTheme();
  return (
    <Checkbox
      sx={{ color: colors.ovBlack }}
      checked={props.checked}
      indeterminate={props.indeterminate}
      icon={<RadioButtonUncheckedIcon />}
      checkedIcon={<CheckCircleRoundedIcon />}
      indeterminateIcon={props.checked ? <RemoveCircleRoundedIcon /> : <CircleRoundedIcon style={{ color: colors.checkboxColor }} />}
      onChange={() => {
        if (props.onChange) props.onChange(!props.checked);
      }}
    />
  );
};

RoundedCheckbox.defaultProps = {
  checked: false,
  indeterminate: false,
  onChange: undefined,
};

export default RoundedCheckbox;
