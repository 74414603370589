import { gql } from '@apollo/client';
import { GoalRiskLevels, GoalTypes } from '../components/goal/resources';
import { AccountTypes, getAccountTypeName, TrustedContactPersonRelations } from '../components/account/resources';
import { AllIncompleteFormAgreement } from '../components/documents/incompleteAgreements';
import { PhysicalAddress } from '../components/user/address/resources';
import { InvestmentKnowledges, RiskLevels } from '../components/user/resources';
import { formatMoneyValue } from './commonMethods';
import i18n, { translateBackend } from '../assets/i18n/config';
import { Affiliation } from '../pages/affiliates';

// Basic ME query
export const BASIC_ME = gql`
  query BasicMe {
    me {
      user {
        id
        incompleteFields
        firstName
        middleName
        lastName
        primaryEmail
        gender
        phone
        employmentStatus
        studentAreaOfStudy
        dateOfBirth
        jobTitle
        investmentKnowledge
        riskQuestion1
        riskQuestion2
        companyType
        totalDebtCents
        annualIncomeCents
        maritalStatus
        financialFixedAssetsCents
        financialLiquidAssetsCents
        annualDebtPaymentsCents
        availableFeatureFlags
        households {
          id
          relationships {
            user {
              id
            }
            accessType
          }
        }
        organization {
          id
          supportUrl
          helpCentreUrl
          minInitialDepositCents
          externalProvider {
            id
            idVerificationProvider {
              templateId
              vendor
            }
            bankingConnectorProvider {
              clientIframeUrl
              vendor
            }
          }
        }
        physicalAddress {
          city
          province
          streetName
          postal
          unitNumber
          houseNumber
          neighborhood
          country
        }
        iDCheckCompleted
        iDVerified
        goals {
          id
          name
          type
          state
          timeHorizon
          targetAmountCents
          riskQuestion1
        }
        accounts {
          id
          type
          state
        }
        subAccounts {
          id
          name
          state
          goal { id }
          account { id type
            user {
              id
            }
            affiliations {
              id
              type
              user { id }
            }
          }
          theme { id key translatedName {fr en} id},
          allowClientDeposits
        }
        allIncompleteFormAgreements {
          type
          minVersion
          digitalSignatureEnabled
          translatedDisplayName {
            en
            fr
          }
          goal {
            id
          }
          scheduledTransfer {
            id
          }
          user {
            id
          }
          account {
            id
            type
            state
          }
          subAccount {
            id
            state
            account {
              id
              type
            }
          }
        }
        allLatestFormAgreements {
          id
        }
        citizenships
        foreignTaxInformation {
          foreignTaxCountry
          foreignTaxNumber
        }
        powerOfAttorneyGranted
        isMemberOfIiroc
        isOfficerOfPublicCompany
        isOwnerOfPublicCompany
        politicallyExposedDomesticPerson
        politicallyExposedForeignPerson
        headOfInternationalOrganization
        closeAssociateOfPEP
        lastSuitabilityReviewAt
        hasCheckedPolicyAndCondition
        bulkAccountOpening
        readyToSignAgreement
        sourceOfWealth
        sourceOfWealthOtherDescription
        sourceOfFunds
        sourceOfFundsOtherDescription
        employmentSituation
        employmentSituationOtherDescription
        employerName
        employerCompanyAddress {
          city
          province
          streetName
          postal
          unitNumber
          houseNumber
          neighborhood
          country
        }
        uniqueCircumstances
        spouseFirstName
        spouseMiddleName
        spouseLastName
        spouseCompanyType
        spouseJobTitle
        spouseDateOfBirth
        spouseSin
        spouseEmail
        spouseAddress {
          city
          province
          streetName
          postal
          unitNumber
          houseNumber
          neighborhood
          country
        }
        spouseEmploymentType
        trustedContactPerson {
          name
          email
          phone
          relation
          physicalAddress {
            city
            province
            streetName
            postal
            unitNumber
            houseNumber
            neighborhood
            country
          }
        }
      }
    }
  }
`;

export const FETCH_ORGANIZATION_CONSUMER = (organizationId: string) => gql`query fetchPublicOrganization{
  fetchPublicOrganization(organizationId: "${organizationId}"){
    organization {
      id
      useClientOrgAuth0
      subdomain
      helpCentreUrl
      supportUrl
      availableFeatureFlags
      theme {
        embeddedExperienceTheme
      }
      embeddedExperienceLogoUrl
    }
  }
}`;
export interface Goal {
  id: string,
  name?: string,
  type?: GoalTypes,
  state?: string,
  subAccounts?: SubAccount[],
  timeHorizon?: string,
  targetAmountCents?: number,
  riskQuestion1?: GoalRiskLevels,
  suggestedFinancialProduct?: {
    forecastedRateOfReturn?: number,
    forecastedStandardDeviation?: number,
  },
}

export interface Account {
  id: string,
  type?: AccountTypes,
  state?: string,
  user?: Partial<User>,
  affiliations?: Affiliation[],
  incompleteFormAgreements?: AllIncompleteFormAgreement[],
}

export interface SubAccount {
  id: string,
  name?: string,
  state?: string,
  goal?: { id: string },
  account?: { id: string, state?: string, type: AccountTypes, affiliations?: Affiliation[], user?: Partial<User> },
  allIncompleteFormAgreements?: AllIncompleteFormAgreement[],
  theme?: { key: string, translatedName: { en: string, fr: string }, id: string },
  allowClientDeposits?: boolean,
  hasTradingSchedule?: boolean,
  statistics?: {
    availableFundsToWithdrawCents?: number,
    moneyAvailableCents?: number,
    marketValueCents?: number,
    cashOnHoldToWithdrawCents?: number,
  },
}

export interface ExternalProvider {
  id: string,
  idVerificationProvider: {
    templateId: string,
    vendor: string,
  },
  bankingConnectorProvider: {
    clientIframeUrl: string,
    vendor: string,
  },
}

export interface Organization {
  id: string,
  supportUrl: string,
  helpCentreUrl: string,
  externalProvider?: ExternalProvider,
  minInitialDepositCents: number,
}

export interface TrustedContactperson {
  email?: string
  name?: string
  phone?: string
  physicalAddress?: Partial<PhysicalAddress>
  relation?: TrustedContactPersonRelations,
}
export interface User {
  id: string,
  incompleteFields: string[],
  allIncompleteFormAgreements?: AllIncompleteFormAgreement[],
  allLatestFormAgreements?: { id: string }[],
  goals: Goal[],
  accounts: Account[],
  subAccounts: SubAccount[],
  households?: ClientGroup[],
  firstName?: string,
  middleName?: string,
  lastName?: string,
  dateOfBirth?: Date,
  primaryEmail?: string,
  gender?: string,
  phone?: string,
  iDCheckCompleted?: boolean,
  iDVerified?: boolean,
  studentAreaOfStudy?: string,
  employmentStatus?: string,
  jobTitle?: string,
  physicalAddress?: Partial<PhysicalAddress>,
  investmentKnowledge?: InvestmentKnowledges,
  riskQuestion1?: RiskLevels,
  riskQuestion2?: RiskLevels,
  totalDebtCents?: number,
  companyType?: string,
  annualIncomeCents?: number,
  maritalStatus?: string,
  financialFixedAssetsCents?: number,
  financialLiquidAssetsCents?: number,
  annualDebtPaymentsCents?: number,
  availableFeatureFlags?: string[],
  hasDebt?: boolean,
  citizenships?: string[]
  foreignTaxInformation?: {
    foreignTaxCountry: string,
    foreignTaxNumber: string
  }[],
  powerOfAttorneyGranted?: boolean
  isMemberOfIiroc?: boolean
  isOfficerOfPublicCompany?: boolean
  isOwnerOfPublicCompany?: boolean
  politicallyExposedDomesticPerson?: boolean
  politicallyExposedForeignPerson?: boolean
  headOfInternationalOrganization?: boolean
  closeAssociateOfPEP?: boolean
  lastSuitabilityReviewAt?: string,
  hasCheckedPolicyAndCondition?: boolean,
  bulkAccountOpening?: boolean,
  organization?: Organization,
  inProvinceSince?: Date,
  readyToSignAgreement?: boolean,
  sourceOfWealth?: string[],
  sourceOfWealthOtherDescription?: string,
  sourceOfFunds?: string[],
  sourceOfFundsOtherDescription?: string,
  trustedContactPerson?: TrustedContactperson,
}

export enum ClientGroupTypes {
  HOUSEHOLD = 'HOUSEHOLD',
  BUSINESS = 'BUSINESS',
}

export enum HouseholdViewTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  HOUSEHOLD = 'HOUSEHOLD',
  ALL = 'ALL',
}

export enum ClientGroupMemberAccessTypes {
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  NO_ACCESS = 'NO_ACCESS',
}

export interface Relationship {
  type?: string
  user?: Partial<User>
  accessType?: ClientGroupMemberAccessTypes,
}

export interface Statistics {
  marketValueCents?: number
  moneyAvailableCents?: number
  netContributionCents?: number
  newContributionCents?: number
  pendingContributionCents?: number
  availableFundsToWithdrawCents?: number
}

export interface ClientGroup {
  id: string,
  name?: string,
  type?: ClientGroupTypes,
  primaryUser?: User,
  relationships?: Relationship[],
  statistics?: Statistics,
}

export const getGoalName = (goal?: Goal): string => {
  if (goal?.name) return goal.name;
  return goal?.type ? i18n.t(`client:goalType.${goal.type}`) : '';
};

export const getSubAccountName = (subAccount?: SubAccount): string => {
  let name = '';
  if (subAccount?.account?.type) name = getAccountTypeName(subAccount.account.type);
  if (subAccount?.theme?.translatedName) name = `${name ? `${name} - ` : ''}${translateBackend(subAccount.theme?.translatedName)}`;
  name = `${name ? `${name} - ` : ''}${`(${formatMoneyValue(subAccount?.statistics?.availableFundsToWithdrawCents ?? 0.00)})`}`;
  return name;
};
