/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import isNil from 'lodash/isNil';
import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import OvLoadingIndicator from '../loaders/ovLoadingIndicator';

export interface StepWizardTransitions {
  enterRight: string,
  enterLeft: string,
  exitRight: string,
  exitLeft: string,
  intro: string,
}

export interface StepProps {
  currentStep?: number,
  totalSteps?: number,
  absoluteTotalSteps?: number,
}
interface Props {
  onContinue?: () => void,
  goBack?: () => void,
  goToNamedStep?: (step: string) => void,
  stepProps?: StepProps,
}
interface SW extends Partial<StepWizardChildProps> {
  action?: (callback: () => void) => void,
  isLoading?: boolean,
  setLoading?: (loading: boolean) => void,
  continueToNamedStep?: () => string,
  disableBackControl?: boolean,
  disableTracker?: boolean,
  goBackAction?: () => void,
  goBackToNamedStep?: () => string,
  stepTitle?: string,
  stepProps?: StepProps,
}

const WithStepWizard = <P extends Props>(Component: React.FC<P>): React.FC<P & SW> => (
  ({
    action, isLoading, setLoading, continueToNamedStep, disableBackControl = false,
    disableTracker = false, goBackToNamedStep, stepProps, stepTitle, ...props
  }: P & SW): JSX.Element => {
    const getStepProps = (): StepProps => (stepProps ?? {
      currentStep: props.currentStep,
      totalSteps: props.totalSteps,
    });
    const nextStep = (): void => {
      if (continueToNamedStep && props.goToNamedStep) {
        const stepNameToGoTo = continueToNamedStep();
        if (stepNameToGoTo.trim()) {
          props.goToNamedStep(stepNameToGoTo);
        }
      } else {
        const totalSteps: number = props.totalSteps || 0;
        if (props.currentStep && props.totalSteps && props.currentStep < totalSteps && props.nextStep) props.nextStep();
      }
    };

    const goToNamedStep = (step: string): void => {
      if (props.goToNamedStep) props.goToNamedStep(step);
    };

    const isFirstStep = (): boolean => (props.currentStep && props.currentStep === 1) || false;
    const goToPreviousStep = (): void => {
      if (goBackToNamedStep && props.goToNamedStep) {
        props.goToNamedStep(goBackToNamedStep());
      } else if (props.goBack) {
        props.goBack();
      } else if (!isFirstStep() && props.previousStep) props.previousStep();
    };

    const actionCallback = (): void => {
      nextStep();
      if (!isNil(setLoading)) setLoading(false);
    };

    const onContinue = (): void => {
      if (!isNil(action)) {
        if (!isNil(isLoading) && !isNil(setLoading)) {
          setLoading(true);
        }
        if (isNil(action(actionCallback))) nextStep();
      } else {
        nextStep();
      }
    };

    if (isLoading) {
      return <OvLoadingIndicator />;
    }

    return (
      <Component
        {...props as P}
        disableBackControl={disableBackControl}
        onContinue={onContinue}
        goBack={goToPreviousStep}
        goToNamedStep={goToNamedStep}
        stepProps={getStepProps()}
        stepTitle={stepTitle}
        disableTracker={disableTracker}
      />
    );
  }
);

export default WithStepWizard;
