import { IconButton, InputAdornment, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';
import OvForm from '../common/wrappers/ovForm';
import SimpleInput from '../common/inputs/simpleInput';
import { colors } from '../../theme/colors';
import { getOrgBasedInvestmentKnowledgeLevels, getOrgBasedRiskQuestion2, riskQuestions1List } from '../user/resources';

export interface UserState {
  investmentKnowledge?: string,
  riskQuestion1?: string,
  riskQuestion2?: string,
}

export interface Props {
  user: UserState,
  onContinue?: () => void,
  goToNamedStep?: (step: string) => void,
}

const InvestmentPreferences = ({ user, onContinue, goToNamedStep }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'suitabilityReview']);
  const continueToNextStep = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    if (onContinue) {
      onContinue();
    }
  };
  const endAdornment = (onClick: () => void): JSX.Element => (
    <InputAdornment position="end">
      <IconButton
        aria-label="clear input field"
        disableRipple
        onClick={() => onClick()}
        edge="end"
      >
        <Typography variant="heading5" color={colors.success} style={{ marginBottom: 'px', textAlign: 'left' }}>
          {t('suitabilityReview:editText')}
        </Typography>
      </IconButton>
    </InputAdornment>
  );
  return (
    <OvForm
      hasButton
      buttonText={t('base:button.continue')}
      onSubmit={continueToNextStep}
    >
      <Box display="flex" flexDirection="column" style={{ marginBottom: '32px' }}>
        <Typography variant="headingMain" textAlign="left" style={{ marginBottom: 0 }}>{t('suitabilityReview:investmentPreferences.title')}</Typography>
        <Typography variant="paragraphMain" style={{ marginBottom: '25px', textAlign: 'left' }}>
          {t('suitabilityReview:investmentPreferences.subTitle')}
        </Typography>
        <SimpleInput
          testId="investmentKnowledgeInput"
          label={t('suitabilityReview:investmentPreferences.investmentKnowledgeLabel')}
          value={getOrgBasedInvestmentKnowledgeLevels().find((item) => item.value === user.investmentKnowledge)?.level ?? ''}
          suffixAdornment={endAdornment(
            () => {
              if (goToNamedStep) {
                goToNamedStep('InvestmentKnowledge');
              }
            },
          )}
        />
        <SimpleInput
          testId="riskQuestion1Input"
          label={t('suitabilityReview:investmentPreferences.riskQuestion1Label')}
          value={riskQuestions1List.find((item) => item.level === user.riskQuestion1)?.question ?? ''}
          suffixAdornment={endAdornment(
            () => {
              if (goToNamedStep) {
                goToNamedStep('RiskQuestion1');
              }
            },
          )}
        />
        <SimpleInput
          testId="riskQuestion2Input"
          label={t('suitabilityReview:investmentPreferences.riskQuestion2Label')}
          value={getOrgBasedRiskQuestion2().find((item) => item.level === user.riskQuestion2)?.question ?? ''}
          suffixAdornment={endAdornment(
            () => {
              if (goToNamedStep) {
                goToNamedStep('RiskQuestion2');
              }
            },
          )}
        />
      </Box>
    </OvForm>
  );
};

InvestmentPreferences.defaultProps = {
  onContinue: undefined,
  goToNamedStep: undefined,
};

export default InvestmentPreferences;
