import { useContext, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import WithStepWizard from '../../common/wrappers/withStepWizard';
import TrackingBar from '../../common/headers/trackingBar';
import AnnualIncome from '../howToInvest/annualIncome';
import ValueOfSavingsAndInvestment from '../howToInvest/valueOfSavingsAndInvestment';
import {
  Debt,
  InvestmentKnowledge,
  MaritalStatus,
  RiskQuestion1,
  RiskQuestion2,
  ValueOfAnnualDebtPayments,
  ValueOfDebt,
  ValueOfPropertyAndPhysicalAssets,
} from '..';
import { InvestmentKnowledges, RiskLevels } from '../resources';
import { UserContext, UserContextProps } from '../../../providers/userContextProvider';
import SourceOfWealth from '../howToInvest/sourceOfWealth';
import SourceOfFunds from '../howToInvest/sourceOfFunds';
import UniqueCircumstancesDescription from '../howToInvest/uniqueCircumstancesDescription';
import { PhysicalAddress } from '../address/resources';
import TrustedContactPersonDetails from '../trustedContactPerson/trustedContactPersonDetails';
import { TrustedContactperson } from '../../../utils/commonGraphql';
import HasTrustedContactPerson from '../howToInvest/hasTrustedContactPerson';
import SpouseDetailsWizard from '../spouse/spouseDetailsWizard';

const TrustedContactPersonStep = WithStepWizard(TrustedContactPersonDetails);
const HasTrustedContactPersonStep = WithStepWizard(HasTrustedContactPerson);
const AnnualIncomeStep = WithStepWizard(AnnualIncome);
const ValueOfSavingsAndInvestmentStep = WithStepWizard(ValueOfSavingsAndInvestment);
const ValueOfPropertyAndPhysicalAssetsStep = WithStepWizard(ValueOfPropertyAndPhysicalAssets);
const SourceOfWealthStep = WithStepWizard(SourceOfWealth);
const SourceOfFundsStep = WithStepWizard(SourceOfFunds);
const DebtStep = WithStepWizard(Debt);
const ValueOfDebtStep = WithStepWizard(ValueOfDebt);
const ValueOfAnnualDebtPaymentsStep = WithStepWizard(ValueOfAnnualDebtPayments);
const MaritalStatusStep = WithStepWizard(MaritalStatus);
const InvestmentKnowledgeStep = WithStepWizard(InvestmentKnowledge);
const RiskQuestion1Step = WithStepWizard(RiskQuestion1);
const RiskQuestion2Step = WithStepWizard(RiskQuestion2);
const UniqueCircumstancesDescriptionStep = WithStepWizard(UniqueCircumstancesDescription);
const SpouseDetailsWizardStep = WithStepWizard(SpouseDetailsWizard);
export interface UserState {
  totalDebtCents?: number,
  hasDebt?: boolean,
  annualDebtPaymentsCents?: number,
  annualIncomeCents?: number,
  maritalStatus?: string,
  financialFixedAssetsCents?: number,
  financialLiquidAssetsCents?: number,
  investmentKnowledge?: InvestmentKnowledges,
  riskQuestion1?: RiskLevels,
  riskQuestion2?: RiskLevels,
  incompleteFields?: string[],
  sourceOfWealth?: string[],
  sourceOfFunds?: string[],
  uniqueCircumstances?: string,
  spouseFirstName?: string,
  spouseMiddleName?: string,
  spouseLastName?: string,
  spouseCompanyType?: string,
  spouseJobTitle?: string,
  spouseDateOfBirth?: Date,
  spouseSin?: string,
  spouseEmail?: string,
  spouseAddress?: Partial<PhysicalAddress>,
  spouseEmploymentType?: string,
  trustedContactPerson?: TrustedContactperson,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  goBack?: () => void,
  stepTitle?: string,
  showStepper?: boolean,
}
const defineInitialStatus = (user: UserState, userContext: UserContextProps): number => {
  let step = 1;
  if (user.incompleteFields?.includes('annualIncomeCents')) {
    return step;
  }
  step += 1;
  if (user.incompleteFields?.includes('financialLiquidAssetsCents')) {
    return step;
  }
  step += 1;
  if (user.incompleteFields?.includes('financialFixedAssetsCents')) {
    return step;
  }
  if (userContext.organizationSettings?.showSourceOfWealth) {
    step += 1;
    if (user.sourceOfWealth?.length === 0) {
      return step;
    }
  }
  if (userContext.organizationSettings?.showSourceOfFunds) {
    step += 1;
    if (user.sourceOfFunds?.length === 0) {
      return step;
    }
  }
  step += 1;
  if (user.incompleteFields?.includes('totalDebtCents') || user.incompleteFields?.includes('annualDebtPaymentsCents')) {
    return step;
  }
  step += 1;
  if (user.hasDebt) {
    return step;
  }
  if (userContext.organizationSettings?.showUniqueCircumstance) {
    if (!user.uniqueCircumstances && user.uniqueCircumstances !== '') {
      return step;
    }
    step += 1;
  }
  if (user.incompleteFields?.includes('maritalStatus')) {
    return step;
  }
  step += 1;
  if (user.incompleteFields?.includes('investmentKnowledge')) {
    return step;
  }
  step += 1;
  if (user.incompleteFields?.includes('riskQuestion1')) {
    return step;
  }
  step += 1;
  if (user.incompleteFields?.includes('riskQuestion2')) {
    return step;
  }
  return step;
};

const HowToInvestWizard = ({
  user, updateUserState, onContinue, goBack, stepTitle, showStepper,
}: Props): JSX.Element => {
  const { userContext } = useContext(UserContext);
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const [trustedContactStatus, setTrustedContactStatus] = useState<{
    hasTrustedContact: string,
  }>({ hasTrustedContact: 'No' });
  const decideBackStepForMarriageStep = (): string => {
    if (userContext.organizationSettings?.showUniqueCircumstance) {
      return 'uniqueCircumstanceDescription';
    }
    if (user.hasDebt && !user.incompleteFields?.includes('annualDebtPaymentsCents')) {
      return 'valueOfAnnualDebtPayments';
    }
    if (user.hasDebt && !user.incompleteFields?.includes('totalDebtCents')) {
      return 'valueOfDebt';
    }
    return 'debt';
  };
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const steps: JSX.Element[] = [];
  steps.push(<AnnualIncomeStep key="annualIncome" user={user} updateUserState={updateUserState} stepTitle={stepTitle} goBackAction={goBack} />);
  steps.push(<ValueOfSavingsAndInvestmentStep key="valueOfSavingsAndInvestment" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  steps.push(<ValueOfPropertyAndPhysicalAssetsStep key="valueOfPropertyAndPhysicalAssets" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  if (userContext.organizationSettings?.showSourceOfWealth) {
    steps.push(<SourceOfWealthStep key="sourceOfWealth" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  }
  if (userContext.organizationSettings?.showSourceOfFunds) {
    steps.push(<SourceOfFundsStep key="sourceOfFund" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  }
  steps.push(<DebtStep key="debt" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  if (user.hasDebt) {
    steps.push(<ValueOfDebtStep key="valueOfDebt" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
    steps.push(<ValueOfAnnualDebtPaymentsStep key="valueOfAnnualDebtPayments" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  }
  if (userContext.organizationSettings?.showUniqueCircumstance) {
    steps.push(<UniqueCircumstancesDescriptionStep key="uniqueCircumstanceDescription" stepName="uniqueCircumstanceDescription" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  }
  steps.push(<MaritalStatusStep goBackToNamedStep={decideBackStepForMarriageStep} key="maritalStatus" stepName="maritalStatus" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  if (userContext.organizationSettings?.showSpouseInfo && user.maritalStatus === 'COMMON_LAW') {
    steps.push(<SpouseDetailsWizardStep key="SpouseDetailsWizard" user={user} updateUserState={updateUserState} hideNav />);
  }
  if (userContext.organizationSettings?.showTrustedContactperson) {
    steps.push(<HasTrustedContactPersonStep key="hasTrustedContactPerson" stepName="hasTrustedContactPerson" user={trustedContactStatus} updateUserState={setTrustedContactStatus} />);
    if (trustedContactStatus.hasTrustedContact === 'YES') {
      steps.push(<TrustedContactPersonStep
        stepName="TrustedContactPerson"
        key="TrustedContactPerson"
        user={user}
      />);
    }
  }
  steps.push(<InvestmentKnowledgeStep key="InvestmentKnowledge" stepName="InvestmentKnowledge" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  steps.push(<RiskQuestion1Step key="RiskQuestion1" user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  steps.push(<RiskQuestion2Step key="RiskQuestion2" user={user} updateUserState={updateUserState} stepTitle={stepTitle} action={onContinue} />);
  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      initialStep={defineInitialStatus(user, userContext)}
      className="ov-step-wizard"
      transitions={{}}
      nav={(
        <TrackingBar steps={steps} showStepper={showStepper} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

HowToInvestWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  stepTitle: undefined,
  showStepper: true,
};

export default HowToInvestWizard;
