import { useMutation } from '@apollo/client';
import {
  Typography, Button,
} from '@mui/material';
import {
  useState,
  useEffect,
  FormEvent,
  useContext,
} from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { setAuthToken } from '../../utils/authToken';
import { isSignUpCompleteVar, organizationIdVar } from '../../utils/localVariables';
import { START_EMAIL_PASSWORDLESS_AUTH, CONFIRM_EMAIL_PASSWORDLESS_AUTH, TokenResponse } from './graphql';
import PinInput from '../common/inputs/pinInput';
import { millisToMinutesAndSeconds } from '../../utils/commonMethods';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import PrivacyPolicyLink from './privacyPolicyLink';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import OvForm from '../common/wrappers/ovForm';
import HelpLink from './helpLink';
import { UserContext } from '../../providers/userContextProvider';

export interface UserState {
  primaryEmail?: string,
  verificationToken?: string,
  token?: TokenResponse,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  goToNamedStep?: (step: string) => void,
  displayPrivacyPolicy?: boolean,
  skipOtpMutations?: boolean,
  displayHelpLink?: boolean,
  updateModalState?: (att: boolean) => void,
}

const EmailVerification = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const skipOtpMutations = props.skipOtpMutations || false;
  const { userContext } = useContext(UserContext);
  const { t } = useTranslation(['base', 'signUp']);
  const [validState, setValidateState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(!skipOtpMutations);
  const [resendLink, setResendLink] = useState({ enabled: true, timerMs: 0 });

  const organizationId = organizationIdVar() || process.env.REACT_APP_ONEVEST_APP_ORGANIZATION_ID;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const sendOtpError = (error: any): void => {
    if (error.graphQLErrors?.find((e: any) => e.extensions?.code === 'NOT_ACCESSIBLE')) {
      console.log({ event: 'USER_DOES_NOT_EXIST' });
      isSignUpCompleteVar(false);
      if (userContext.organizationSettings?.blockClientSignUp) {
        setSendingOtp(false);
        setLoading(false);
        if (props.updateModalState) {
          props.updateModalState(true);
        }
        if (props.goToNamedStep) props.goToNamedStep('email');
      }
    } else {
      setSendingOtp(false);
    }
  };
  /* eslint-disable @typescript-eslint/no-explicit-any */

  const sendOtpOnCompleted = (): void => {
    console.log({ event: 'EMAIL_OTP_SENT', email: user.primaryEmail });
    updateUserState({ ...user, verificationToken: '' });
    setResendLink({ enabled: false, timerMs: 30000 });
    setSendingOtp(false);
  };

  const checkFieldsValidity = async (): Promise<boolean> => {
    const tokenValidity = await yup.string()
      .required()
      .min(6)
      .max(6)
      .isValid(user.verificationToken);
    setValidateState(tokenValidity);
    return tokenValidity;
  };

  const [sendOtp] = useMutation(START_EMAIL_PASSWORDLESS_AUTH, {
    variables: {
      input: { primaryEmail: user.primaryEmail, organizationId },
      skipErrorHandler: true,
    },
    onCompleted: sendOtpOnCompleted,
    onError: sendOtpError,
  });

  const confirmOtpOnCompleted = (data: { login: { token: TokenResponse } }): void => {
    console.log({ event: 'EMAIL_OTP_VALIDATED', email: user.primaryEmail });
    updateUserState({
      ...user,
      verificationToken: '',
      token: {
        accessToken: data.login.token.accessToken,
        refreshToken: data.login.token.refreshToken,
        mfaToken: data.login.token.mfaToken,
        oobCode: data.login.token.oobCode,
      },
    });
    if (data.login.token.accessToken) {
      console.log({ event: 'USER_IS_AUTHENTICATED' });
      setAuthToken(data.login.token);
    } else {
      setLoading(false);
      if (data.login.token?.mfaToken && data.login.token?.oobCode) {
        if (props.goToNamedStep) props.goToNamedStep('phoneVerification');
      } else if (props.onContinue) props.onContinue();
    }
  };

  const [confirmOtp] = useMutation(CONFIRM_EMAIL_PASSWORDLESS_AUTH, {
    variables: {
      input: { primaryEmail: user.primaryEmail, verificationToken: user.verificationToken, organizationId },
    },
    onCompleted: confirmOtpOnCompleted,
    onError: () => setLoading(false),
  });

  useEffect(() => {
    if (!skipOtpMutations) {
      sendOtp().then();
    }
  }, [sendOtp, skipOtpMutations]);

  useEffect(() => {
    if (!resendLink.enabled) {
      // countdown timer
      const timer = setTimeout(() => setResendLink((prev) => ({
        enabled: (prev.timerMs - 1000) <= 0,
        timerMs: prev.timerMs - 1000,
      })), 1000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [resendLink]);

  const onResendOtp = (): void => {
    sendOtp().then();
    setResendLink({ enabled: false, timerMs: 30000 });
  };

  const onContinue = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    if (props.skipOtpMutations && props.onContinue) {
      props.onContinue();
      return;
    }
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingEnterEmailVerification).then();
    if (!await checkFieldsValidity()) return;
    setLoading(true);
    confirmOtp().then();
  };

  if (sendingOtp) {
    return <OvLoadingIndicator />;
  }

  return (
    <OvForm loading={loading} onSubmit={onContinue}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: '1 0 auto' }}>
          <Typography variant="heading2" style={{ marginBottom: 16 }}>{t('signUp:emailVerification.title')}</Typography>
          <Typography variant="paragraph3" style={{ marginBottom: 32 }}>
            {t('signUp:emailVerification.subTitle', { primaryEmail: user.primaryEmail })}
          </Typography>
          <PinInput
            error={!validState}
            label={t('base:input.pinLabel')}
            style={{ marginBottom: 32 }}
            onChange={(value) => {
              if (!validState) { setValidateState(true); }
              updateUserState({ ...user, verificationToken: value });
            }}
          />
          {skipOtpMutations ? null : (
            <Button variant="text-large" disabled={!resendLink.enabled} onClick={onResendOtp} data-testid="resend-otp-button" style={{ marginBottom: 32 }}>
              {`${t('base:input.pinResendLink')} ${!resendLink.enabled ? `(${millisToMinutesAndSeconds(resendLink.timerMs)})` : ''}`}
            </Button>
          )}
        </div>
        {props.displayPrivacyPolicy ? <PrivacyPolicyLink /> : null}
        {props.displayHelpLink ? <HelpLink /> : null}
      </div>
    </OvForm>
  );
};

EmailVerification.defaultProps = {
  onContinue: undefined,
  goToNamedStep: undefined,
  displayPrivacyPolicy: false,
  skipOtpMutations: false,
  displayHelpLink: false,
  updateModalState: undefined,
};

export default EmailVerification;
