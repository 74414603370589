import { CssBaseline } from '@mui/material';
import OvApolloProvider from './providers/ovApolloProvider';
import UserContextProvider from './providers/userContextProvider';
import Content from './pages';
import ToastContextProvider from './providers/globalToastProvider';
import ContextThemeProvider from './providers/contextThemeProvider';
import Auth0Provider from './providers/auth0Provider';

const App = (): JSX.Element => (
  <ToastContextProvider>
    <OvApolloProvider>
      <UserContextProvider>
        <ContextThemeProvider>
          <Auth0Provider>
            <CssBaseline />
            <Content />
          </Auth0Provider>
        </ContextThemeProvider>
      </UserContextProvider>
    </OvApolloProvider>
  </ToastContextProvider>
);

export default App;
