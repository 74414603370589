/* eslint-disable  react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Link,
  DialogContent,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, {
  FormEvent,
  useContext,
  useEffect, useRef,
  useState,
} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StaticDatePicker } from '@mui/lab';
import { Markup } from 'interweave';
import WithdrawalBreakdown from './withdrawalBreakdown';
import {
  FETCH_MY_BANK_ACCOUNTS,
  CREATE_SCHEDULED_WITHDRAW_TRANSFER,
  CREATE_WITHDRAW_TRANSFER,
  WITHDRAW_ALL_FROM_SUBACCOUNT, FETCH_SUB_ACCOUNT_DETAIL,
} from '../graphql';
import DropdownInput from '../../common/inputs/dropdownInput';
import Flinks from '../../flinks/flinks';
import OvButton from '../../common/buttons/ovButton';
import PortfolioWithdrawalDetailsModal from './portfolioWithdrawalDetailsModal';
import { UserContext } from '../../../providers/userContextProvider';
import SimpleInput from '../../common/inputs/simpleInput';
import { InputType } from '../../common/inputs/utils/inputType';
import CloseButton from '../../common/buttons/closeButton';
import PortfolioDropdown from '../../common/controllers/portfolioDropdown';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import { getLocale } from '../../../assets/i18n/config';
import { generateUniqueRequestId } from '../../../utils/generic';
import { AccountTypes, accountTypesRESP, FeatureFlagTypes } from '../../account/resources';
import { orgSupportUrlVar } from '../../../utils/localVariables';
import GoalDropDown from '../../common/controllers/goalDropdown';
import { SubAccount } from '../../../utils/commonGraphql';
import { Colors, useContextTheme } from '../../../providers/contextThemeProvider';

dayjs.extend(utc);

const styles = (colors: Colors) => ({
  card: {
    background: 'white',
    borderRadius: '8px',
    border: `1px solid ${colors.portfolioCardBorder}`,
    padding: '16px',
  },
});

interface Props {
  onClose: () => void,
  goalId?: string,
  subAccountId?: string,
  onSuccessWithdraw?: () => void,
}

const Withdraw = ({
  onClose,
  goalId,
  subAccountId,
  onSuccessWithdraw,
}: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const nextDay = new Date().setDate(new Date().getDate() + 1);
  const { t } = useTranslation(['transfer']);
  const { userContext } = useContext(UserContext);
  const matches = useMediaQuery('(max-width:600px)');
  const [from, setFrom] = React.useState(goalId);
  const [portfolio, setPortfolio] = React.useState(subAccountId);
  const [amount, setAmount] = React.useState('');
  const [bankAccountId, setBankAccountId] = React.useState('');
  const [schedule, setSchedule] = React.useState('ONE_TIME');
  const [withdrawalReason, setWithdrawalReason] = React.useState('');
  const [withdrawalReasonDescription, setWithdrawalReasonDescription] = useState('');
  const [bankAccounts, setBankAccounts] = useState<{ id: string; name: string; bankAccountNumber: string; }[]>([]);
  const bankAccountsRef = useRef(bankAccounts);
  const [showFlinks, setShowFlinks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [openPortfolioWithdrawalDetailsModal, setOpenPortfolioWithdrawalDetailsModal] = useState(false);
  const [selectedSubAccount, setSelectedSubAccount] = useState<SubAccount | undefined>(undefined);
  const [goalSubAccounts, setGoalSubAccounts] = useState<SubAccount[] | undefined>(undefined);
  const [starting, setStarting] = useState('Now');
  const [scheduledDate, setScheduledDate] = useState(dayjs(nextDay).format('YYYY-MM-DD'));
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [tempDate, setTempDate] = useState('');
  const [isFullWithdrawal, setIsFullWithdrawal] = useState(false);
  const [isIlliquidWithdrawal, setIsIlliquidWithdrawal] = useState(false);
  const isMounted = useRef(false);
  const { showToast } = useGlobalToast();
  const [showErrorBanner, setShowErrorBanner] = useState<{ open: boolean, message: string | JSX.Element }>({
    open: false,
    message: '',
  });

  const otherReasonMaxCharacters = 200;
  const getReason = (): string => {
    if (withdrawalReason !== 'COMPLETED_MY_GOAL') {
      return withdrawalReason;
    }
    const goalType = userContext?.goals?.find((goal) => goal.id === from)?.type ?? '';
    if (goalType === 'BUILD_WEALTH' || goalType === 'SAFETY_NET') {
      return 'OTHER';
    }
    if (goalType === 'RETIREMENT') {
      return 'RETIREMENT_INCOME';
    }
    return goalType;
  };

  const lockedInAccount: AccountTypes[] = [
    AccountTypes.LIRA, AccountTypes.LRSP, AccountTypes.RLSP, AccountTypes.LIF, AccountTypes.RLIF, AccountTypes.LRIF, AccountTypes.PRIF,
  ];
  const respAccounts: AccountTypes[] = [...accountTypesRESP.map((x) => x.key), AccountTypes.RESP_SINGLE_JOINT, AccountTypes.RESP_FAMILY_JOINT];
  const getHomeBuyersPlanError = (color: string, linkUrl: string): JSX.Element => (
    <Typography data-testid="error-banner" variant="paragraph3" style={{ marginBottom: 5, color: colors.error30 }}>
      <span>{t('transfer:withdrawal.errorBanner.homeBuyersPlanMessage.p1')}</span>
      <Link sx={{ color, textDecorationColor: color }} href="https://www.canada.ca/content/dam/cra-arc/formspubs/pbg/t1036/t1036-21e.pdf" underline="always" target="_blank">{t('transfer:withdrawal.errorBanner.homeBuyersPlanMessage.p2')}</Link>
      <span>{t('transfer:withdrawal.errorBanner.homeBuyersPlanMessage.p3')}</span>
      <Link sx={{ color, textDecorationColor: color }} href={linkUrl} underline="always" target="_blank">{t('transfer:withdrawal.errorBanner.homeBuyersPlanMessage.p4')}</Link>
    </Typography>
  );
  const getLifeLongLearningPlanError = (color: string, linkUrl: string): JSX.Element => (
    <Typography data-testid="error-banner" variant="paragraph3" style={{ marginBottom: 5, color: colors.error30 }}>
      <span>{t('transfer:withdrawal.errorBanner.lifeLongLearningPlanMessage.p1')}</span>
      <Link sx={{ color, textDecorationColor: color }} href="https://www.canada.ca/content/dam/cra-arc/formspubs/pbg/rc96/rc96-22e.pdf" underline="always" target="_blank">{t('transfer:withdrawal.errorBanner.lifeLongLearningPlanMessage.p2')}</Link>
      <span>{t('transfer:withdrawal.errorBanner.lifeLongLearningPlanMessage.p3')}</span>
      <Link sx={{ color, textDecorationColor: color }} href={linkUrl} underline="always" target="_blank">{t('transfer:withdrawal.errorBanner.lifeLongLearningPlanMessage.p4')}</Link>
    </Typography>
  );
  // Made it return both a string and a react element cause <Markup/> html strings doesn't allow links open in a new tab.
  const getErrorMessage = (reason: string, accountType: AccountTypes): string | JSX.Element => {
    if (respAccounts.includes(accountType)) return t('transfer:withdrawal.errorBanner.RESPErrorMessage', { link: colors.error30, linkUrl: orgSupportUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us/requests/new' });
    if ([AccountTypes.RRIF, AccountTypes.RIF_SPOUSAL].includes(accountType)) return t(`transfer:withdrawal.errorBanner.${accountType}`, { link: colors.error30, linkUrl: orgSupportUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us/requests/new' });
    if (lockedInAccount.includes(accountType)) return t('transfer:withdrawal.errorBanner.lockedInAccountMessage', { link: colors.error30, linkUrl: orgSupportUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us/requests/new' });
    if (reason === 'HOME_BUYERS_PLAN') return getHomeBuyersPlanError(colors.error30, orgSupportUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us/requests/new');
    if (reason === 'LIFELONG_LEARNING_PLAN') return getLifeLongLearningPlanError(colors.error30, orgSupportUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us/requests/new');
    return '';
  };

  const validateWithdrawal = (reason: string, accountType: AccountTypes): void => {
    const withdrawalReasonError = ['HOME_BUYERS_PLAN', 'LIFELONG_LEARNING_PLAN'].includes(reason ?? '');
    const accountWithdrawalRestricted = [
      ...lockedInAccount, ...respAccounts, AccountTypes.RRIF, AccountTypes.RIF_SPOUSAL,
    ].includes(accountType);
    if (withdrawalReasonError || accountWithdrawalRestricted) {
      setShowErrorBanner({
        open: true,
        message: getErrorMessage(reason, accountType),
      });
    } else {
      setShowErrorBanner({
        open: false,
        message: '',
      });
    }
  };

  const setBankAccountData = (data: {
    me: {
      user: {
        bankAccounts: {
          id: string,
          name: string,
          bankAccountNumber: string,
        }[]
      },
    }
  }): void => {
    setBankAccounts(data.me.user.bankAccounts);
    const banks = data.me.user.bankAccounts;
    setBankAccountId(banks.length > 0 ? banks[banks.length - 1].id : '');
  };

  const [fetchMyBankAccounts] = useLazyQuery(FETCH_MY_BANK_ACCOUNTS, {
    variables: {},
    onCompleted: setBankAccountData,
    nextFetchPolicy: 'standby',
  });

  const setPortfolioData = (data: { fetchSubAccount: { subAccount: SubAccount } }): void => {
    setSelectedSubAccount(data.fetchSubAccount.subAccount);
    validateWithdrawal('', data.fetchSubAccount.subAccount!.account!.type as AccountTypes);
  };

  const [fetchSubAccount] = useLazyQuery(FETCH_SUB_ACCOUNT_DETAIL, {
    variables: {},
    onCompleted: (e) => setPortfolioData(e),
    nextFetchPolicy: 'standby',
  });

  useEffect(() => {
    if (!isMounted.current) {
      fetchMyBankAccounts().then();
      let selectedGoalId = goalId;
      if (!goalId && userContext.goals && userContext.goals.length === 1) {
        selectedGoalId = userContext.goals[0].id;
        setFrom(selectedGoalId);
      }
      const subAccounts = userContext?.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalId);
      if (subAccounts && subAccounts.length === 1) {
        setPortfolio(subAccounts[0].id);
      }
      isMounted.current = true;
    }
  }, [userContext, fetchMyBankAccounts, goalId, userContext?.goals]);

  useEffect(() => {
    bankAccountsRef.current = bankAccounts;
  }, [bankAccounts]);

  const setWithdrawTransferData = (data: { createWithdrawTransfer: { transfer: { id: string, state: string, failReason: string, } } }): void => {
    const { id, state, failReason } = data.createWithdrawTransfer.transfer;
    console.log({
      event: 'TRANSFER_CREATED', id, state, failReason,
    });
    sendAnalyticsEvent(ovAnalyticsEvents.withdrawalPageReviewConfirm).then();
    showToast({ message: t('toastMessages:withdrawalMadeSuccess.h2'), severity: 'success', title: t('toastMessages:withdrawalMadeSuccess.h1') });
    if (onSuccessWithdraw) onSuccessWithdraw();
    setLoading(false);
    onClose();
  };

  const setWithdrawAllTransferData = (
    data: {
      withdrawAllFromSubAccount: {
        transfer: { id: string, state: string, failReason: string, }
      },
    },
  ): void => {
    const { id, state, failReason } = data.withdrawAllFromSubAccount.transfer;
    console.log({
      event: 'TRANSFER_CREATED', id, state, failReason,
    });
    sendAnalyticsEvent(ovAnalyticsEvents.withdrawalPageReviewConfirm).then();
    showToast({ message: t('toastMessages:withdrawalMadeSuccess.h2'), severity: 'success', title: t('toastMessages:withdrawalMadeSuccess.h1') });
    if (onSuccessWithdraw) onSuccessWithdraw();
    setLoading(false);
    onClose();
  };

  const [createWithdrawAllFromSubAccountMutation] = useMutation(WITHDRAW_ALL_FROM_SUBACCOUNT, {
    variables: {
      input: {
        subAccountId: portfolio,
        bankAccountId,
        requestId: generateUniqueRequestId(),
      },
    },
    onCompleted: (e) => setWithdrawAllTransferData(e),
    onError: () => setLoading(false),
  });

  const [createWithdrawTransferMutation] = useMutation(CREATE_WITHDRAW_TRANSFER, {
    variables: {
      input: {
        amountCents: Math.round(Number(amount) * 100),
        subAccountId: portfolio,
        bankAccountId,
        withdrawalReason: getReason(),
        ...(withdrawalReasonDescription ? { withdrawalReasonDescription: withdrawalReasonDescription.trim() } : {}),
        requestId: generateUniqueRequestId(),
      },
    },
    onCompleted: (e) => setWithdrawTransferData(e),
    onError: () => setLoading(false),
  });

  const setScheduledWithdrawTransferData = (data: {
    createScheduledWithdrawTransfer: { scheduledTransfer: { id: string, state: string, failReason: string, } },
  }): void => {
    const { id, state, failReason } = data.createScheduledWithdrawTransfer.scheduledTransfer;
    console.log({
      event: 'SCHEDULED_TRANSFER_CREATED', id, state, failReason,
    });
    sendAnalyticsEvent(ovAnalyticsEvents.withdrawalPageReviewConfirm).then();
    showToast({ message: t('toastMessages:withdrawalMadeSuccess.h2'), severity: 'success', title: t('toastMessages:withdrawalMadeSuccess.h1') });
    if (onSuccessWithdraw) onSuccessWithdraw();
    setLoading(false);
    onClose();
  };

  const [createScheduledWithdrawTransferMutation] = useMutation(CREATE_SCHEDULED_WITHDRAW_TRANSFER, {
    variables: {
      input: {
        amountCents: Math.round(Number(amount) * 100),
        subAccountId: portfolio,
        bankAccountId,
        frequency: schedule,
        withdrawalReason: getReason(),
        scheduledDate,
        requestId: generateUniqueRequestId(),
        ...(withdrawalReasonDescription ? { withdrawalReasonDescription: withdrawalReasonDescription.trim() } : {}),
      },
    },
    onCompleted: (e) => setScheduledWithdrawTransferData(e),
    onError: () => setLoading(false),
  });

  useEffect(() => {
    if (from && portfolio && amount && schedule && withdrawalReason) {
      if ((selectedSubAccount && ((selectedSubAccount?.statistics!.availableFundsToWithdrawCents ?? 0) < 10)) || showErrorBanner.open || (withdrawalReason === 'OTHER' && (withdrawalReasonDescription.trim() === '' || withdrawalReasonDescription.trim().length > otherReasonMaxCharacters))) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }
  }, [from, portfolio, amount, schedule, withdrawalReason, selectedSubAccount, showErrorBanner, withdrawalReasonDescription]);

  const confirmDate = (): void => {
    setScheduledDate(tempDate);
    setOpenCalendar(false);
  };

  const setDate = (value: string): void => {
    setStarting(value === 'Now' ? value : 'Future Date');
    if (value === 'Now') {
      setScheduledDate(dayjs().format('YYYY-MM-DD'));
    } else {
      setOpenCalendar(true);
    }
  };

  const formatDateForUI = (): string => {
    if (scheduledDate) {
      return ` (${dayjs(scheduledDate).format('D MMM YYYY')})`;
    }
    return '';
  };

  const makeWithdrawal = (): void => {
    setLoading(true);
    if (isFullWithdrawal) {
      createWithdrawAllFromSubAccountMutation().then();
    } else if (schedule === 'ONE_TIME' && starting.toLowerCase() === 'now') {
      createWithdrawTransferMutation().then();
    } else {
      createScheduledWithdrawTransferMutation().then();
    }
  };

  useEffect(() => {
    if (amount && selectedSubAccount) {
      const availableFundsCents = selectedSubAccount?.statistics!.availableFundsToWithdrawCents ?? 0;
      if (selectedSubAccount && (Math.round(Number(amount) * 100) >= (availableFundsCents * 0.98))) {
        setIsFullWithdrawal(true);
      } else {
        setIsFullWithdrawal(false);
      }
    }
  }, [amount, selectedSubAccount]);

  useEffect(() => {
    if (selectedSubAccount) {
      setIsIlliquidWithdrawal(selectedSubAccount.hasTradingSchedule!);
    }
  }, [selectedSubAccount]);

  const submitWithdrawal = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    makeWithdrawal();
  };

  const onGoalChanged = (goalid: string): void => {
    setFrom(goalid);
    const subAccounts = userContext?.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalid);
    if (subAccounts && subAccounts.length === 1) {
      setPortfolio(subAccounts[0].id);
    }
  };

  const onPortfolioChanged = (portfolioID: string): void => {
    setPortfolio(portfolioID);
  };
  const handleAmountChange = (value: string): void => {
    const availableWithdrawCent = selectedSubAccount?.statistics!.availableFundsToWithdrawCents ?? 0;
    if (Math.round(Number(value) * 100) > availableWithdrawCent) {
      setAmount((availableWithdrawCent / 100).toFixed(2));
    } else {
      setAmount(value);
    }
  };

  const onScheduledTransferChanged = (value: string): void => {
    setSchedule(value);
    if (value !== 'ONE_TIME' && starting === 'Now') {
      setStarting('Future Date');
    }
  };

  useEffect(() => {
    if (portfolio) {
      fetchSubAccount({
        variables: {
          subAccountId: portfolio,
        },
      }).then();
    }
  }, [portfolio, fetchSubAccount]);
  const availableFeatureFlags = userContext?.availableFeatureFlags || [];
  const removeAddbankAccountOption = !availableFeatureFlags?.includes(FeatureFlagTypes.AUTOMATED_BANK_ACCOUNT_LINKING)
    && !availableFeatureFlags?.includes(FeatureFlagTypes.MANUALLY_ADD_BANK_ACCOUNT);
  return (
    <form onSubmit={submitWithdrawal} style={styles(colors).card}>
      <Box padding="32px 32px">
        <CloseButton onClick={onClose} />
        <Typography variant="heading2" style={{ margin: 0, marginBottom: 24 }}>{t('transfer:withdrawal.headerMessage')}</Typography>
      </Box>
      <Grid container padding="0px 32px 50px 32px" rowSpacing={1} spacing={10}>
        <Grid item xs={matches ? 12 : 6}>
          <Box>
            <Typography variant="captionSectionerMedium" style={{ margin: 0 }}>{t('transfer:withdrawal.from')}</Typography>
            <GoalDropDown
              selectedGoalId=""
              value={from ?? ''}
              onGoalChanged={(e) => {
                onGoalChanged(e);
              }}
              setSubAccounts={(values) => {
                setGoalSubAccounts(values);
              }}
            />
            <Box style={{ marginBottom: 24 }} />
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('transfer:withdrawal.portfolio')}</Typography>
            <PortfolioDropdown
              onChange={(e) => {
                sendAnalyticsEvent(ovAnalyticsEvents.withdrawalPagePortfolioSelect).then();
                onPortfolioChanged(e.id);
              }}
              subAccounts={goalSubAccounts}
              value={portfolio ?? ''}
              canCreateSubAccount={false}
              dataTestId="portfolio-dropdown"
              goalId={from ?? ''}
            />
            <Box style={{ marginBottom: 24 }} />
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 0 }}>{t('transfer:withdrawal.amount')}</Typography>
            <SimpleInput
              testId="amount"
              value={amount}
              style={{ height: '50px', marginTop: '-12px' }}
              onChange={(e) => handleAmountChange(e.target.value)}
              inputType={InputType.CURRENCY}
              inputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputprops: { value: amount },
                onBlur: () => sendAnalyticsEvent(ovAnalyticsEvents.withdrawalPageAmountContinue).then(),
              }}
              onClear={() => setAmount('')}
              label=""
            />
            {isFullWithdrawal
              ? <Typography variant="captionSectionerMedium" color={colors.error} sx={{ marginBottom: 0, marginTop: 0.8 }}>{t('transfer:withdrawal.withdrawalTypeNotification')}</Typography>
              : undefined}
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 15 }}>{t('transfer:withdrawal.to')}</Typography>
            <DropdownInput
              dataTestId="to-dropdown"
              onChange={(e) => {
                setBankAccountId(e.target.value);
                sendAnalyticsEvent(ovAnalyticsEvents.withdrawalPageBankAccountContinue).then();
              }}
              options={bankAccounts.map((bankAccount) => (
                { imageUrl: '', text: `${bankAccount.name} ${bankAccount.bankAccountNumber}`, value: bankAccount.id }
              ))}
              value={bankAccountId}
              suffixLink={!removeAddbankAccountOption ? { text: t('transfer:flinksAddBankAccount'), onChange: () => setShowFlinks(true) } : undefined}
            />
            <Box style={{ marginBottom: 24 }} />
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:withdrawal.schedule')}</Typography>
            <DropdownInput
              onChange={(e) => onScheduledTransferChanged(e.target.value)}
              options={[
                { imageUrl: '', text: t('transfer:withdrawal.scheduleOptions.justThisOnce'), value: 'ONE_TIME' },
                { imageUrl: '', text: t('transfer:withdrawal.scheduleOptions.weekly'), value: 'WEEKLY' },
                { imageUrl: '', text: t('transfer:withdrawal.scheduleOptions.biWeekly'), value: 'BI_WEEKLY' },
                { imageUrl: '', text: t('transfer:withdrawal.scheduleOptions.monthly'), value: 'MONTHLY' },
              ]}
              value={schedule}
            />
            <Box style={{ marginBottom: 24 }} />
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:withdrawal.starting')}</Typography>
            <DropdownInput
              onItemClicked={(value) => {
                setDate(value);
                sendAnalyticsEvent(ovAnalyticsEvents.withdrawalPageStartDateContinue).then();
              }}
              options={schedule === 'ONE_TIME' ? [
                { imageUrl: '', text: t('transfer:withdrawal.startingOptions.now'), value: 'Now' },
                { imageUrl: '', text: `${t('transfer:withdrawal.startingOptions.futureDate')} ${formatDateForUI()}`, value: 'Future Date' },
              ] : [
                { imageUrl: '', text: `${t('transfer:withdrawal.startingOptions.futureDate')} ${formatDateForUI()}`, value: 'Future Date' },
              ]}
              value={starting}
            />
            <Box style={{ marginBottom: 24 }} />
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:withdrawal.withdrawalReason')}</Typography>
            <DropdownInput
              onItemClicked={(value) => {
                sendAnalyticsEvent(ovAnalyticsEvents.withdrawalPageReasonSelect).then();
                validateWithdrawal(value, selectedSubAccount?.account!.type as AccountTypes);
              }}
              dataTestId="withdraw-reason-dropdown"
              onChange={(e) => {
                if (e.target.value !== 'OTHER') setWithdrawalReasonDescription('');
                setWithdrawalReason(e.target.value);
              }}
              options={[
                { imageUrl: '', text: t('transfer:withdrawal.withdrawalReasonOptions.completedMyGoal'), value: 'COMPLETED_MY_GOAL' },
                { imageUrl: '', text: t('transfer:withdrawal.withdrawalReasonOptions.dissatisfiedWithOneVest'), value: 'DISSATISFIED_WITH_ONEVEST' },
                { imageUrl: '', text: t('transfer:withdrawal.withdrawalReasonOptions.investingIsNotForMe'), value: 'INVESTING_IS_NOT_FOR_ME' },
                { imageUrl: '', text: t('transfer:withdrawal.withdrawalReasonOptions.poorReturns'), value: 'POOR_RETURNS' },
                ...(selectedSubAccount?.account?.type === 'RRSP' ? [
                  { imageUrl: '', text: t('transfer:withdrawal.withdrawalReasonOptions.homeBuyersPlan'), value: 'HOME_BUYERS_PLAN' },
                  { imageUrl: '', text: t('transfer:withdrawal.withdrawalReasonOptions.lifeLongLearningPlan'), value: 'LIFELONG_LEARNING_PLAN' },
                ] : []),
                { imageUrl: '', text: t('transfer:withdrawal.withdrawalReasonOptions.other'), value: 'OTHER' },
              ]}
              value={withdrawalReason}
            />
            {withdrawalReason === 'OTHER' && (
              <>
                <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 24 }}>{t('transfer:withdrawal.withdrawalOtherReason.title')}</Typography>
                <SimpleInput
                  testId="other-reason"
                  label=""
                  value={withdrawalReasonDescription}
                  style={{ height: '50px', marginTop: '-12px' }}
                  subtitle=""
                  required
                  onChange={(event) => setWithdrawalReasonDescription(event.target.value)}
                  onClear={() => setWithdrawalReasonDescription('')}
                  inputProps={{
                    autoFocus: withdrawalReason === 'OTHER',
                  }}
                />
                {withdrawalReasonDescription.trim().length > otherReasonMaxCharacters && (
                  <DialogContent sx={{ p: 0, mt: 1 }}>
                    <Typography data-testid="error-other-reason" id="modal-modal-description" color="error" sx={{ mb: 0 }} variant="paragraph3">
                      {t('transfer:withdrawal.withdrawalOtherReason.errorMessage', { maxCharacters: otherReasonMaxCharacters })}
                    </Typography>
                  </DialogContent>
                )}
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={matches ? 12 : 6} style={{ marginTop: matches ? '55px' : '0px' }}>
          <Typography variant="captionSectionerMedium" color={colors.neutral20} style={{ marginBottom: 12 }}>Your withdrawal breakdown</Typography>
          <WithdrawalBreakdown
            accountId={selectedSubAccount?.account!.id ?? ''}
            availableToWithdrawCents={selectedSubAccount?.statistics!.availableFundsToWithdrawCents ?? 0}
            withdrawAmountCents={Math.round(Number(amount) * 100)}
            isIlliquidWithdrawal={isIlliquidWithdrawal}
            showDetails={() => setOpenPortfolioWithdrawalDetailsModal(true)}
          />
          <Box style={{ marginBottom: 28 }} />
          <OvButton
            disabled={disableButton}
            loading={loading}
            text={t('transfer:withdrawal.confirmWithdrawal')}
            type="submit"
            variant="primary-large"
            data-testid="continue-button"
          />
          {
            showErrorBanner.open && (
              <>
                <Box style={{ marginBottom: 28 }} />
                <Box sx={{ bgcolor: '#FEF5F6', p: 3 }}>
                  <Typography fontWeight="bold" style={{ marginBottom: 5, color: colors.error30 }}>{t('transfer:withdrawal.errorBanner.title')}</Typography>
                  {
                    typeof showErrorBanner.message === 'string' ? (
                      <Typography data-testid="error-banner" variant="paragraph3" style={{ marginBottom: 5, color: colors.error30 }}>
                        <Markup content={showErrorBanner.message} />
                      </Typography>
                    ) : showErrorBanner.message
                  }
                </Box>
              </>
            )
          }
        </Grid>
        <Flinks
          onClose={() => setShowFlinks(false)}
          open={showFlinks}
          bankAccounts={bankAccounts}
          retrievedBank={(bank) => {
            setBankAccounts((prevState) => [...prevState, bank]);
            setFrom(bank.id);
          }}
        />
      </Grid>
      <PortfolioWithdrawalDetailsModal
        amountOnHoldCents={selectedSubAccount?.statistics!.cashOnHoldToWithdrawCents ?? 0}
        onClose={() => setOpenPortfolioWithdrawalDetailsModal(false)}
        open={openPortfolioWithdrawalDetailsModal}
        portfolioBalanceCents={selectedSubAccount?.statistics!.marketValueCents ?? 0}
        onSubmit={() => setOpenPortfolioWithdrawalDetailsModal(false)}
        availableWithdrawCents={selectedSubAccount?.statistics!.availableFundsToWithdrawCents ?? 0}
      />
      <Modal
        open={openCalendar}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClose={() => {
          setOpenCalendar(false);
        }}
      >
        <Box display="flex" flexDirection="column" position="relative">
          <LocalizationProvider locale={getLocale()} dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              label="yyyy-mm-dd"
              open
              displayStaticWrapperAs="mobile"
              minDate={nextDay}
              value={Date.parse(dayjs(scheduledDate).local().format('YYYY-MM-DD'))}
              onChange={(newValue) => setTempDate(dayjs(newValue).utc().format('YYYY-MM-DD'))}
              renderInput={(params) => (
                <TextField
                  sx={{ marginBottom: '32px' }}
                  variant="standard"
                  {...params}
                  fullWidth
                />
              )}
              mask="____-__-__"
            />
          </LocalizationProvider>
          <Box position="absolute" right="35px" bottom="35px" display="flex">
            <Typography variant="captionSectionerMedium" onClick={() => setOpenCalendar(false)} style={{ marginBottom: 0, marginRight: '15px', cursor: 'pointer' }}>{t('transfer:bankAccountDeposit.calendar.cancel')}</Typography>
            <Typography variant="captionSectionerMedium" onClick={() => confirmDate()} style={{ marginBottom: 0, cursor: 'pointer' }}>{t('transfer:bankAccountDeposit.calendar.confirm')}</Typography>
          </Box>
        </Box>
      </Modal>
    </form>
  );
};

Withdraw.defaultProps = {
  goalId: '',
  subAccountId: '',
  onSuccessWithdraw: undefined,
};

export default Withdraw;
