interface OrganizationColors {
  primary?: string,
  baseText?: string,
  body?: string,
  holdingsGraph?: string[],
  marketValueChart1?: string,
  marketValueChart2?: string,
  marketValueChartOutline?: string,
  menuBar?: string,
}

export const getPartnerColor = (colors?: OrganizationColors): Record<string, string | undefined | Record<string, string>> => {
  if (colors) {
    return {
      // primaryColor
      ...(colors.primary ? {
        primary: colors.primary,
        radio: colors.primary,
        loading: colors.primary,
        exitButtonIconColour: colors.primary,
        link: colors.primary,
        toggleSwitchBGColor: colors.primary,
        infoIconColor: colors.primary,
        autoInvestmentSummaryCard: colors.primary,
        progressBarColor: colors.primary,
        addPortfolioButtonColor: colors.primary,
        marketValueChartOutlineColor: colors.primary,
        buttonItemHoverBorder: colors.primary,
      } : {}),
      ...(colors.holdingsGraph && colors.holdingsGraph.length > 0 ? {
        holdingsGraph: colors.holdingsGraph.reduce((p, c, i) => {
          // eslint-disable-next-line no-param-reassign
          p[i + 1] = c;
          return p;
        }, {} as Record<string, string>),
      } : {}),
      ...(colors.baseText ? {
        baseText: colors.baseText,
      } : {}),
      ...(colors.body ? {
        body: colors.body,
        buttonItemBackground: colors.body,
      } : {}),
      ...(colors.marketValueChart1 ? {
        marketValueChartColor1: colors.marketValueChart1,
      } : {}),
      ...(colors.marketValueChart2 ? {
        marketValueChartColor2: colors.marketValueChart2,
      } : {}),
      ...(colors.marketValueChartOutline ? {
        marketValueChartOutlineColor: colors.marketValueChartOutline,
      } : {}),
      ...(colors.menuBar ? {
        menuBar: colors.menuBar,
      } : {}),
    };
  }
  return {};
};
