/* eslint-disable max-len */

import { List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonDescriptionListItem from '../common/lists/buttonDescriptionListItem';
import { GoalRiskLevelAttributes, goalRiskLevels, GoalRiskLevels } from './resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import { Goal } from '../../utils/commonGraphql';
import { isLoggedInVar, isOnboardingCompleteVar } from '../../utils/localVariables';

export interface GoalRef {
  riskQuestion1?: GoalRiskLevels,
}

interface Props {
  setGoalRef: (att: GoalRef) => void,
  currentGoal?: Goal | GoalRef,
  onContinue?: () => void,
}

const RiskQuestion = ({ setGoalRef, onContinue, currentGoal }: Props): JSX.Element => {
  const { t } = useTranslation(['goal']);
  const handleClick = async (item: GoalRiskLevelAttributes): Promise<void> => {
    console.log({ event: 'GOAL_RISK_LEVEL_SELECTED', type: item.key });
    if (isLoggedInVar() && !isOnboardingCompleteVar()) {
      sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectGoalRiskTolerance).then();
    } else {
      sendAnalyticsEvent(ovAnalyticsEvents.addGoalRiskToleranceSelect).then();
    }
    setGoalRef({ riskQuestion1: item.key });
    if (onContinue) onContinue();
  };
  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2">{t('goal:riskQuestion.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('goal:riskQuestion.subTitle')}</Typography>
      <List sx={{ width: '100%', cursor: 'pointer' }}>
        {goalRiskLevels.filter((level) => ![GoalRiskLevels.LEVEL_2, GoalRiskLevels.LEVEL_4].includes(level.key)).map((item: GoalRiskLevelAttributes) => (
          <ButtonDescriptionListItem
            prefixIcon={item.iconPath}
            key={item.key}
            description={item.description}
            onClick={() => handleClick(item)}
            isSelected={(currentGoal?.riskQuestion1 === item.key) || false}
          />
        ))}
      </List>
    </OvForm>
  );
};

RiskQuestion.defaultProps = {
  onContinue: undefined,
  currentGoal: undefined,
};

export default RiskQuestion;
