import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormEvent, useState } from 'react';
import OvForm from '../common/wrappers/ovForm';
import SimpleInput from '../common/inputs/simpleInput';
import { InputType } from '../common/inputs/utils/inputType';

export interface UserState {
  annualIncomeCents?: number,
  financialLiquidAssetsCents?: number,
  financialFixedAssetsCents?: number,
  totalDebtCents?: number,
  annualDebtPaymentsCents?: number,
}

export interface Props {
  user: UserState,
  onContinue?: () => void,
  updateUserState: (att: UserState) => void,
}

const FinancialInformation = ({
  user, onContinue, updateUserState,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'suitabilityReview']);
  const [tempAnnualIncomeCents, setTempAnnualIncomeCents] = useState(user.annualIncomeCents);
  const [tempFinancialLiquidAssetsCents, setTempFinancialLiquidAssetsCents] = useState(user.financialLiquidAssetsCents);
  const [tempFinancialFixedAssetsCents, setTempFinancialFixedAssetsCents] = useState(user.financialFixedAssetsCents);
  const [tempTotalDebtCents, setTempTotalDebtCents] = useState(user.totalDebtCents);
  const [tempAnnualDebtPaymentsCents, setTempAnnualDebtPaymentsCents] = useState(user.annualDebtPaymentsCents);
  const cacheData = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    updateUserState({
      ...user,
      annualIncomeCents: tempAnnualIncomeCents,
      financialLiquidAssetsCents: tempFinancialLiquidAssetsCents,
      financialFixedAssetsCents: tempFinancialFixedAssetsCents,
      totalDebtCents: tempTotalDebtCents,
      annualDebtPaymentsCents: tempAnnualDebtPaymentsCents,
    });
    if (onContinue) {
      onContinue();
    }
  };
  return (
    <OvForm
      hasButton
      buttonText={t('base:button.continue')}
      onSubmit={cacheData}
    >
      <Box display="flex" flexDirection="column" style={{ marginBottom: '32px' }}>
        <Typography variant="headingMain" textAlign="left" style={{ marginBottom: 0 }}>{t('suitabilityReview:financialInformation.title')}</Typography>
        <Typography variant="paragraphMain" style={{ marginBottom: '25px', textAlign: 'left' }}>
          {t('suitabilityReview:financialInformation.subTitle')}
        </Typography>
        <SimpleInput
          testId="annualIncomeInput"
          label={t('suitabilityReview:financialInformation.annualIncomeLabel')}
          value={tempAnnualIncomeCents !== undefined ? (tempAnnualIncomeCents / 100).toString() : ''}
          onChange={(e) => setTempAnnualIncomeCents(Math.round(Number(e.target.value) * 100))}
          onClear={() => setTempAnnualIncomeCents(undefined)}
          inputType={InputType.CURRENCY}
        />
        <SimpleInput
          testId="financialLiquidAssetsInput"
          label={t('suitabilityReview:financialInformation.financialLiquidAssetsLabel')}
          value={tempFinancialLiquidAssetsCents !== undefined ? (tempFinancialLiquidAssetsCents / 100).toString() : ''}
          onChange={(e) => setTempFinancialLiquidAssetsCents(Math.round(Number(e.target.value) * 100))}
          onClear={() => setTempFinancialLiquidAssetsCents(undefined)}
          inputType={InputType.CURRENCY}
        />
        <SimpleInput
          testId="financialFixedAssetsInput"
          label={t('suitabilityReview:financialInformation.financialFixedAssetsLabel')}
          value={tempFinancialFixedAssetsCents !== undefined ? (tempFinancialFixedAssetsCents / 100).toString() : ''}
          onChange={(e) => setTempFinancialFixedAssetsCents(Math.round(Number(e.target.value) * 100))}
          onClear={() => setTempFinancialFixedAssetsCents(undefined)}
          inputType={InputType.CURRENCY}
        />
        <SimpleInput
          testId="totalDeptInput"
          label={t('suitabilityReview:financialInformation.totalDebtLabel')}
          value={tempTotalDebtCents !== undefined ? (tempTotalDebtCents / 100).toString() : ''}
          onChange={(e) => setTempTotalDebtCents(Math.round(Number(e.target.value) * 100))}
          onClear={() => setTempTotalDebtCents(undefined)}
          inputType={InputType.CURRENCY}
        />
        <SimpleInput
          testId="annualDeptPaymentInput"
          label={t('suitabilityReview:financialInformation.annualDebtPaymentsLabel')}
          value={tempAnnualDebtPaymentsCents !== undefined ? (tempAnnualDebtPaymentsCents / 100).toString() : ''}
          onChange={(e) => setTempAnnualDebtPaymentsCents(Math.round(Number(e.target.value) * 100))}
          onClear={() => setTempAnnualDebtPaymentsCents(undefined)}
          inputType={InputType.CURRENCY}
        />
      </Box>
    </OvForm>
  );
};

FinancialInformation.defaultProps = {
  onContinue: undefined,
};

export default FinancialInformation;
