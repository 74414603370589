import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeAttributes } from './resources';
import OvForm from '../common/wrappers/ovForm';

interface Props {
  theme: ThemeAttributes,
  goBack?: () => void,
  onContinue?: () => void,
  hasMultipleAvailableTheme?: boolean,
}

const ThemeSummary = ({
  theme, onContinue, goBack, hasMultipleAvailableTheme,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'account']);
  const nextStep = (): void => {
    if (onContinue) onContinue();
  };
  const cancel = (): void => {
    if (goBack) goBack();
  };
  return (
    <OvForm
      buttonText={t('base:button.gotIt')}
      onSubmit={cancel}
      submitButton={false}
      suffixButton={hasMultipleAvailableTheme ? {
        onClick: () => nextStep(),
        text: t('account:themeSummary.changePortfolioTheme'),
        variant: 'secondary-large',
        dataTestId: 'changeThemeButton',
      } : undefined}
    >
      <Box display="flex" flexDirection="row" justifyContent="center" marginBottom="16px">
        <img src={theme.iconPath} alt="icon" />
      </Box>
      <Typography textAlign="center" variant="heading3">{theme.name}</Typography>
      <Box mb={0.5} />
      <Typography textAlign="center" variant="paragraphMain" style={{ marginBottom: 32 }}>{theme.description}</Typography>
    </OvForm>
  );
};

ThemeSummary.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  hasMultipleAvailableTheme: true,
};

export default ThemeSummary;
