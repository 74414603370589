import { Typography, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import pull from 'lodash/pull';
import SearchInput from '../../common/inputs/searchInput';
import GeneralListItem from '../../common/lists/generalListItem';
import { UPDATE_USER } from '../graphql';
import { Country, getFormattedCountry } from '../resources';
import { AlternativeSteps } from './completeAssumptionsWizard';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  citizenships?: string[],
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  alternativeSteps?: AlternativeSteps,
  onContinue?: () => void,
  goToNamedStep?: (step: string) => void,
  cached?: boolean,
}

const Citizenships = ({
  user, updateUserState, alternativeSteps, cached, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    sourceData: getFormattedCountry(),
    filterData: getFormattedCountry(),
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterList = (e: any): void => {
    if (e.target) {
      const updatedList = state.sourceData.filter((item: Country) => (item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1));
      setState({ ...state, filterData: updatedList });
    }
  };
  const citizenshipUpdateOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'CITIZENSHIP_UPDATED', Citizenships: user.citizenships });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (alternativeSteps?.isForeignTaxStepRequired) {
      if (props.goToNamedStep) props.goToNamedStep('CountryOfTaxResidence');
    } else if (props.goToNamedStep) props.goToNamedStep('Assumptions');
  };
  const handleCachedData = (): void => {
    updateUserState({ ...user });
    if (alternativeSteps?.isForeignTaxStepRequired) {
      if (props.goToNamedStep) props.goToNamedStep('CountryOfTaxResidence');
    } else if (props.goToNamedStep) props.goToNamedStep('Assumptions');
  };
  const [citizenshipUpdateMutation] = useMutation(UPDATE_USER, {
    variables: {
      input: { citizenships: user.citizenships },
    },
    onCompleted: citizenshipUpdateOnCompleted,
    onError: () => setLoading(false),
  });
  const onContinueClick = (): void => {
    if (cached) {
      handleCachedData();
    } else {
      setLoading(true);
      citizenshipUpdateMutation().then();
    }
  };
  return (
    <OvForm loading={loading} onSubmit={onContinueClick} disableButton={!user.citizenships || isEmpty(user.citizenships)} submitButton={false}>
      <Typography variant="heading2">{t('user:citizenship.title')}</Typography>
      <Typography variant="paragraph3">{t('user:citizenship.subTitle')}</Typography>
      <SearchInput
        label={t('user:citizenship.label')}
        value={searchString}
        onChange={(event) => {
          setSearchString(event.target.value);
          filterList(event);
        }}
        onClear={() => {
          setSearchString('');
          setState({ ...state, filterData: getFormattedCountry() });
          updateUserState({ ...user, citizenships: [] });
        }}
      />
      <List className="on-boarding-list no-scrollbar">
        {state.filterData.map((item: Country) => (
          <GeneralListItem
            hasSquareCheckbox
            prefixIcon={item.icon}
            prefixIconStyle={{ width: 20, height: 14 }}
            text={item.name}
            key={item.name}
            checked={user.citizenships?.includes(item.code)}
            onClick={() => {
              if (item) {
                if (user.citizenships) {
                  let newArr = user.citizenships;
                  if (user.citizenships.includes(item.code)) {
                    const tempCitizenship = [...user.citizenships];
                    newArr = pull(tempCitizenship, item.code);
                    updateUserState({ ...user, citizenships: [...newArr] });
                  } else {
                    updateUserState({ ...user, citizenships: [...newArr, item.code.toString()] });
                  }
                } else {
                  updateUserState({ ...user, citizenships: [item.code.toString()] });
                }
              }
            }}
          />
        ))}
      </List>
    </OvForm>
  );
};

Citizenships.defaultProps = {
  onContinue: undefined,
  alternativeSteps: undefined,
  goToNamedStep: undefined,
  cached: false,
};

export default Citizenships;
