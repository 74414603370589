import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme/colors';
import BeneficiaryIcon from '../../assets/images/filled/beneficiary.svg';
import AlertIcon from '../../assets/images/filled/alert.svg';

interface Props {
  affiliateCount: number,
  onClick?: () => void,
}

const styles = {
  outerBox: {
    width: '100%',
    height: '70px',
    maxHeight: '86px',
    display: 'flex',
    padding: '0 20px',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${colors.portfolioCardBorder}`,
    background: colors.baseComponentBackground,
    justifyContent: 'start' as const,
    boxSizing: 'border-box' as const,
  },
  headerText: {
    color: colors.autoInvestmentSummaryHeader,
    margin: 0,
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'start',
    margin: 10,
  },
  underline: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: colors.link,
  },
};

const AffiliatesCard = ({
  affiliateCount, onClick,
}: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const anyBeneficiary: boolean = affiliateCount > 0;
  const getPronoun: string = affiliateCount > 1 ? 'linkedBeneficiaryPlural' : 'linkedBeneficiarySingular';

  return (
    <Box style={styles.outerBox}>
      <img src={anyBeneficiary ? BeneficiaryIcon : AlertIcon} alt="portfolio-icon" />
      <Box style={styles.innerBox}>
        <Typography variant="heading5" style={styles.headerText}>
          {anyBeneficiary ? t(`account:affiliates.${getPronoun}`, { count: affiliateCount }) : t('account:affiliates.noBeneficiary')}
        </Typography>
        <Typography
          style={styles.underline}
          variant="captionSectionerBold"
          color={colors.link}
          onClick={onClick}>
          {anyBeneficiary ? t('account:affiliates.viewAffiliates') : t('account:affiliates.addAffiliates')}
        </Typography>
      </Box>
    </Box>
  );
};

AffiliatesCard.defaultProps = {
  onClick: undefined,
};

export default AffiliatesCard;
