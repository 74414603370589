/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useContext, useState } from 'react';
import { Box } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import OvMenu from './ovMenu';
import DocumentsMenuIcon from '../../../assets/images/menu/documents.svg';
import ProfileMenuIcon from '../../../assets/images/menu/profile.svg';
import PersonalDetails from '../../profile/personalDetails';
import Documents from '../../documents';
import { styles } from './styles';
import FlowModal from '../wrappers/modals/ovFlowModal';
import { FeatureFlagTypes, isFeatureEnabled } from '../../account/resources';
import { navigate } from '../../../utils/router';
import { UserContext } from '../../../providers/userContextProvider';

const OvPartnerHeader = (): JSX.Element => {
  const { t } = useTranslation(['base']);
  const matches = useMediaQuery('(max-width:960px)');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { setUserContext } = useContext(UserContext);
  const [personalDetailsModal, setPersonalDetailsModal] = useState(false);
  const [documentsModal, setDocumentsModal] = useState(false);
  const handleClick = (event: React.MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onPersonalDetails = (): void => {
    setAnchorEl(null);
    setPersonalDetailsModal(true);
  };

  const onDocuments = (): void => {
    setAnchorEl(null);
    setDocumentsModal(true);
  };

  if (documentsModal) {
    return (
      <FlowModal
        open={documentsModal}
        showCloseButton
        onClose={() => setDocumentsModal(false)}
        component={(
          <Documents
            onClose={() => setDocumentsModal(false)}
          />
        )}
      />
    );
  }
  if (personalDetailsModal) {
    return (
      <FlowModal
        open={personalDetailsModal}
        onClose={() => setPersonalDetailsModal(false)}
        showCloseButton
        component={(
          <PersonalDetails />
        )}
      />
    );
  }
  const MENU_ITEMS = [
    ...isFeatureEnabled(FeatureFlagTypes.UPDATE_PERSONAL_DETAILS) ? [{ icon: ProfileMenuIcon, title: `${t('base:headerMenu.personalDetails')}`, onClick: () => onPersonalDetails() }] : [],
    {
      icon: DocumentsMenuIcon,
      title: `${t('base:headerMenu.documents')}`,
      onClick: () => {
        if (isFeatureEnabled(FeatureFlagTypes.DOCUMENTS)) {
          setAnchorEl(null);
          navigate(setUserContext, '/documents');
        } else {
          onDocuments();
        }
      },
    },
  ];
  return (
    <Box sx={styles.detailBox}>
      <Box sx={styles.dotMenueWrapper}>
        <MenuRoundedIcon sx={styles.dotMenuIcon} style={{ top: matches ? '4px' : '11px' }} onClick={handleClick} />
        <OvMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          menuItems={MENU_ITEMS}
        />
      </Box>
    </Box>
  );
};

export default OvPartnerHeader;
