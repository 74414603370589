import {
  Box,
  ListItem,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { getBackendLanguage } from '../../assets/i18n/config';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { formatMoneyValue } from '../../utils/commonMethods';
import {
  defineTypeGainSymbol, parseTransferLabel,
  stateMapping,
  Transfer,
} from './resources';
import { styles } from './styles';

const TransferItem = ({ ...props }: Transfer): JSX.Element => {
  const { colors } = useContextTheme();
  return (
    <ListItem key={Math.random()} sx={{ p: 1 }}>
      <Box display="flex" flexDirection="column" mb={4} width="100%" sx={{ marginBottom: '0 !important' }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="bodySub2" color={colors.ovBlack}>{parseTransferLabel(props.source, props.type)}</Typography>
          <Typography variant="bodySub1" color={colors.neutral30}>
            {`${defineTypeGainSymbol(props.type)}
                      ${formatMoneyValue(props.amountCents)}
                      `}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="captionDescriptorBold" color={colors.neutral30}>{stateMapping(props.state)}</Typography>
          <Typography variant="captionDescriptorBold" color={colors.neutral30}>{dayjs(props.createdAt).locale(getBackendLanguage()).format('DD MMMM YYYY')}</Typography>
        </Box>
        { !props.last && (
          <Box sx={styles(colors).historyBorder} />
        )}
      </Box>
    </ListItem>
  );
};

export default TransferItem;
