/* eslint-disable prefer-arrow-callback */

import React from 'react';
import NumberFormat from 'react-number-format';

interface FormatterProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const CurrencyFormatter = React.forwardRef<NumberFormat<string>, FormatterProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          if (Number.isNaN(Number(values.value))) {
            return;
          }
          let formattedValue: string;
          formattedValue = ((Number(values.value) * 100) % 1 === 0) ? values.value : Number(values.value).toFixed(2);
          formattedValue = Math.abs(Number(formattedValue)).toString();
          onChange({
            target: {
              name: props.name,
              value: formattedValue,
            },
          });
        }}
        decimalScale={2}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  },
);

export {
  CurrencyFormatter,
};
