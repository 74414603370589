/* eslint-disable no-promise-executor-return */

import React, { useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import WithStepWizard from '../common/wrappers/withStepWizard';
import YourPaymentInstructions from './yourPaymentInstructions';
import AnnualPaymentInstructions from '../rrif-post-onboarding/annualPaymentInstructions';
import TrackingBar from '../common/headers/trackingBar';

export interface ScheduledIncomeFundTransferProp {
  id: string,
  scheduledDate: string,
  minimumAnnualAmountCents?: number,
  annualAmountCents?: number,
  subAccount: {
    id: string,
    theme: {
      translatedName: {
        en: string,
        fr: string
      }
    }
  },
  bankAccount: {
    id: string,
  },
  frequency: string,
  amountPayableType: string,
  dateOfBirth: Date,
}

const YourPaymentInstructionsStep = WithStepWizard(YourPaymentInstructions);
const AnnualPaymentInstructionsStep = WithStepWizard(AnnualPaymentInstructions);

interface Props {
  onClose: () => void,
  paymentInstructionToUpdate: ScheduledIncomeFundTransferProp,
  account: {
    id: string,
    type: string,
  },
  hideNavBar: boolean,
}

const EditPaymentInstructionWizard = ({
  onClose,
  paymentInstructionToUpdate,
  account,
  hideNavBar,
}: Props): JSX.Element => {
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const [paymentInstruction, setPaymentInstruction] = useState<ScheduledIncomeFundTransferProp>(paymentInstructionToUpdate);
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });

  const steps: JSX.Element[] = [
    <YourPaymentInstructionsStep
      onEdit={(item) => setPaymentInstruction(item)}
      stepName="your-payment-instruction"
      key="your-payment-instruction"
      goToNamedStep={() => 'edit-payment-instruction-form'}
      loading={false}
      scheduledTransfers={paymentInstruction}
      onClose={onClose}
    />,
    <AnnualPaymentInstructionsStep
      account={account}
      key="annual-payment-instruction"
      paymentInstruction={paymentInstruction}
      action={() => onClose()}
      stepName="edit-payment-instruction-form"
    />,
  ];
  return (
    <StepWizard
      instance={setInstance}
      transitions={{}}
      isLazyMount
      className="addInstitutionStepWizard"
      nav={
        hideNavBar ? undefined : <TrackingBar steps={steps} />
      }
    >
      {steps}
    </StepWizard>
  );
};

export default EditPaymentInstructionWizard;
