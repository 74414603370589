/* eslint-disable no-nested-ternary */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable  max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Button, Container, Grid, IconButton, Typography,
} from '@mui/material';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Alert } from '@mui/lab';
import ErrorIcon from '@mui/icons-material/Error';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { navigate, useParams } from '../../utils/router';
import LogoutMenuIcon from '../../assets/images/menu/logout.svg';
import OvBreadCrumb from '../../components/common/headers/ovBreadCrumb';
import { Item, styles } from './styles';
import OvMenu from '../../components/common/headers/ovMenu';
import { CLOSE_ACCOUNT, FETCH_ACCOUNT } from './graphql';
import { UserContext } from '../../providers/userContextProvider';
import LoadingBox from '../../components/common/loaders/loadingBox';
import PortfolioCard from '../../components/statistics/portfolioCard';
import { translateBackend } from '../../assets/i18n/config';
import AutoInvestmentSummary from '../../components/transfers/autoInvestmentSummary';
import LatestTransfers from '../../components/transfers/latestTransfers';
import HistoryChart from '../../components/statistics/historyChart';
import BalanceAndReturnValues from '../../components/statistics/balanceAndReturnValues';
import { DailyStatsTypes } from '../../components/statistics/graphql';
import FeedbackModal from '../../components/common/wrappers/modals/ovFeedbackModal';
import FlowModal from '../../components/common/wrappers/modals/ovFlowModal';
import Deposit from '../../components/transfers/deposit';
import Withdraw from '../../components/transfers/withdrawal';
import ConfirmationModal from '../../components/common/modals/confirmationModal';
import CreateSubAccountForAccountWizard from '../../components/account/createSubAccountForAccountWizard';
import ContributionsCard from '../../components/statistics/contributionsCard';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import {
  AccountTypes,
  accountTypesRESP,
  AffiliationTypes,
  FeatureFlagTypes,
  getAccountTypeDescription,
  getAccountTypeName,
  isFeatureEnabled,
} from '../../components/account/resources';
import informationIcon from '../../assets/images/information.svg';
import AffiliatesCard from '../../components/statistics/affiliatesCard';
import { useGlobalToast } from '../../providers/globalToastProvider';
import EnableTLHButton from '../../components/taxLossHarvesting/enableTLHButton';
import PaymentInstruction from '../../components/transfers/paymentInstruction';
import { householdMembersIdVar, isIdVerifiedVar, userIdVar } from '../../utils/localVariables';
import { HouseholdBadge } from '../../components/common/components/householdBadge';
import { householdInitial } from '../dashboard';
import { ClientGroup, HouseholdViewTypes } from '../../utils/commonGraphql';
import { useContextTheme } from '../../providers/contextThemeProvider';
import {
  accountsWhichRequirePaymentInstructions,
  hasRetirementAccount,
  hideExistingPortfolioTransfer,
  isRetirementAccount, lockedInAccount,
} from '../../utils/accountUtil';
import { Affiliation } from '../affiliates';

interface SubAccount {
  id: string,
  name?: string,
  state?: string,
  goal: { id: string, type: string, name: string },
  availableThemes: { id: string }[],
  statistics: {
    marketValueCents: number,
    simpleReturnPercent: number,
    simpleReturnAmount: number,
  },
  account: {
    id: string,
    type: string,
    user: { id: string }
  },
  theme: {
    id: string,
    key: string,
    translatedDescription: {
      en: string,
      fr: string,
    },
    translatedName: {
      en: string,
      fr: string,
    },
    translatedShortDescription: {
      en: string,
      fr: string,
    },
    iconKey?: string,
  },
  financialProduct: {
    riskReturnLevel: number,
  },
}

interface Account {
  tlhEnabled: boolean,
  user: { id: string, firstName: string, statistics: { marketValueCents: number }, },
  householdClientGroup?: ClientGroup,
  affiliations: Affiliation[],
  statistics: {
    marketValueCents: number,
    simpleReturnPercent: number,
    simpleReturnAmount: number,
    netContributionCents: number,
    pendingContributionCents: number,
    netContributionCentsInFiscalYear: number,
    capitalGainsAndLossesInFiscalYear: number,
    taxLossHarvestCreditInFiscalYear: {
      creditsCents: number,
    },
  },
  scheduledIncomeFundTransfer: { id: string, },
  subAccounts: SubAccount[],
}

export const createMembers = (user: { id: string, firstName?: string }, householdClientGroup?: ClientGroup): { name?: string, index: number }[] => {
  const membersList: { name?: string, index: number }[] = [];
  const membersId = householdMembersIdVar();
  if (householdClientGroup) {
    householdClientGroup.relationships?.forEach((elem) => {
      const index = membersId.findIndex((id) => id === elem.user?.id);
      if (index > -1) {
        membersList.push({
          name: elem.user?.firstName ?? '',
          index,
        });
      }
    });
  } else {
    const index = membersId.findIndex((id) => id === user.id);
    if (index > -1) {
      membersList.push({
        name: user.firstName,
        index,
      });
    }
  }
  return membersList;
};

const AccountDetails = (): JSX.Element => {
  const params = useParams();
  const { colors } = useContextTheme();
  const accountId = params.accountId ?? '';
  const accountType = params.accountType ?? '';
  const matches = useMediaQuery('(max-width:960px)');
  const { t } = useTranslation(['base', 'user', 'transfer', 'goal', 'portfolio', 'account']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(true);
  const { userContext, setUserContext } = useContext(UserContext);
  const [account, setAccount] = useState<any>(null);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showWithdrawal, setShowWithdrawal] = useState(false);
  const [addSubAccount, setAddSubAccount] = useState(false);
  const [closeAccountInfo, setCloseAccountInfo] = useState(false);
  const [closeAccountConfirmation, setCloseAccountConfirmation] = useState(false);
  const [refreshLatestTransfers, setRefreshLatestTransfers] = useState(false);
  const [openAccountTypeDetailModal, setOpenAccountTypeDetailModal] = useState(false);
  const [openAffiliatesInfoModal, setOpenAffiliatesInfoModal] = useState(false);
  const [closeAccountError, setCloseAccountError] = useState(false);
  const [showHousehold, setShowHousehold] = useState(false);
  const [householdName, setHouseholdName] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [closeAccountMsg, setCloseAccountMsg] = useState('');
  const [addSubAccountWizardStep, setAddSubAccountWizardStep] = useState(1);
  const [allocationOfInvestment, setAllocationOfInvestment] = useState(0);
  const [affiliationCount, setAffiliationCount] = useState(0);
  const [netContributionCentsInFiscalYear, setNetContributionCentsInFiscalYear] = useState(0);
  const [netContributionCents, setNetContributionCents] = useState(0);
  const [capitalGainsAndLossesInFiscalYear, setCapitalGainsAndLossesInFiscalYear] = useState(0);
  const [taxLossHarvestCreditInFiscalYear, setTaxLossHarvestCreditInFiscalYear] = useState(0);
  const [tlhEnabled, setTlhEnabled] = useState(false);
  const [showRrifNotificationBanner, setShowRrifNotificationBanner] = useState(false);
  const [showRrifNoTransferBanner, setShowRrifNoTransferBanner] = useState(false);
  const [members, setMembers] = useState<{ name?: string, index: number }[]>();
  const { showToast } = useGlobalToast();
  const [statistics, setStats] = useState<{ marketValueCents: number, simpleReturnPercent: number, simpleReturnAmount: number }>(
    { marketValueCents: 0, simpleReturnPercent: 0, simpleReturnAmount: 0 },
  );
  const respAccounts: (AccountTypes | undefined)[] = accountTypesRESP.map(((item) => item.key));
  const affiliateEnabledAccounts: boolean = [respAccounts, AccountTypes.TFSA, AccountTypes.RRSP].includes(accountType);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const hideBankAccountDeposit = isRetirementAccount({ accountType });
  const MENU_ITEMS = isFeatureEnabled(FeatureFlagTypes.CLOSE_ACCOUNT) ? [
    {
      icon: LogoutMenuIcon,
      title: t('account:menu.closeAccount'),
      onClick: () => {
        sendAnalyticsEvent(ovAnalyticsEvents.accountPageCloseAccountSelect).then();
        handleClose();
        setCloseAccountInfo(true);
      },
    },
  ] : [];
  const [subAccounts, setSubAccounts] = useState<SubAccount[]>([]);
  const setAccountData = (data: {
    fetchAccount: {
      account: Account,
    },
  }): void => {
    if (!data.fetchAccount.account.subAccounts) return;
    setLoading(false);
    setAccount(data.fetchAccount.account);
    const authorizedUser = data.fetchAccount.account.affiliations.filter((x) => (
      x.type !== null && [
        AffiliationTypes.AUTHORIZED_INDIVIDUAL, AffiliationTypes.JOINT,
      ].includes(x.type as AffiliationTypes)));
    const isAuthorizedUser = authorizedUser.map((x) => x.user?.id).includes(userIdVar());
    setShowHousehold(data.fetchAccount.account.user.id !== userIdVar() && !isAuthorizedUser);
    setHouseholdName(data.fetchAccount.account?.householdClientGroup?.name ?? '');
    setUserFirstName(data.fetchAccount.account.user.firstName);
    setStats(data.fetchAccount.account.statistics);
    setSubAccounts(data.fetchAccount.account.subAccounts);
    setAffiliationCount(data.fetchAccount.account.affiliations.length);
    setAllocationOfInvestment(data.fetchAccount.account.statistics.marketValueCents / data.fetchAccount.account.user.statistics.marketValueCents);
    setNetContributionCentsInFiscalYear(data.fetchAccount.account.statistics.netContributionCentsInFiscalYear);
    setNetContributionCents(data.fetchAccount.account.statistics.netContributionCents);
    setCapitalGainsAndLossesInFiscalYear(data.fetchAccount.account.statistics.capitalGainsAndLossesInFiscalYear);
    setTlhEnabled(data.fetchAccount.account.tlhEnabled);
    if (params.accountType === 'PERSONAL') {
      setTaxLossHarvestCreditInFiscalYear(data.fetchAccount.account.statistics.taxLossHarvestCreditInFiscalYear.creditsCents);
    }
    const { scheduledIncomeFundTransfer } = data.fetchAccount.account;
    const statisticsVal = data.fetchAccount.account.statistics;
    setShowRrifNotificationBanner(!scheduledIncomeFundTransfer);
    setShowRrifNoTransferBanner(!scheduledIncomeFundTransfer
      && statisticsVal.netContributionCents === 0 && statisticsVal.pendingContributionCents === 0);
    const membersList = createMembers(data.fetchAccount.account.user, data.fetchAccount.account.householdClientGroup);
    setMembers(membersList);
  };
  const [fetchAccount, { refetch, called }] = useLazyQuery(FETCH_ACCOUNT(params.accountType === 'PERSONAL'), {
    variables: {},
    onCompleted: setAccountData,
    nextFetchPolicy: 'standby',
    fetchPolicy: 'standby',
    onError: () => navigate(setUserContext, '/'),
  });
  useEffect(() => {
    fetchAccount({
      variables: {
        accountId,
      },
    }).then();
  }, [fetchAccount, accountId]);
  // This triggers a refetch when the checkRrifInformationStep changes.
  useEffect(() => {
    refetch();
  }, [userContext.checkRrifInformationStep?.refreshComponent, refetch]);
  // This triggers a recheck when the checkRrifInformationStep is true.
  useEffect(() => {
    if (!userContext.checkRrifInformationStep) {
      fetchAccount().then();
    }
  }, [userContext.checkRrifInformationStep, fetchAccount]);
  const completeAccountError = (error: ApolloError): void => {
    const foundError = error.graphQLErrors.find((item) => item.extensions.code === 'CLOSING_SUBACCOUNT_WITH_MONEY_AVAILABLE');
    setCloseAccountConfirmation(false);
    setCloseAccountError(true);
    if (foundError) {
      setCloseAccountMsg(t(`account:errorDialog.errorMessages.${foundError.extensions.code}`));
    }
  };
  const completeAccountData = (data: { closeAccount: { account: { id: string } } }): void => {
    console.log({ event: 'COMPLETE_ACCOUNT', subAccount: data.closeAccount.account.id });
    setCloseAccountConfirmation(false);
    navigate(setUserContext, '/');
    showToast({ message: t('toastMessages:closeAccountSuccess.h2'), severity: 'success', title: t('toastMessages:closeAccountSuccess.h1') });
  };
  const [completeAccountMutation] = useMutation(CLOSE_ACCOUNT, {
    variables: {
      input: {
        accountId,
        inactiveReason: 'OTHER',
      },
      skipErrorHandler: true,
    },
    onCompleted: completeAccountData,
    onError: (e) => completeAccountError(e),
  });
  if (loading) {
    return <LoadingBox />;
  }
  if (addSubAccount) {
    return (
      <FlowModal
        goBack={() => setAddSubAccount(false)}
        open={addSubAccount}
        showCloseButton={false}
        onClose={() => (addSubAccountWizardStep === 1 ? setAddSubAccount(false) : undefined)}
        component={(
          <Box sx={{ maxWidth: '400px' }}>
            <CreateSubAccountForAccountWizard
              onStepChanged={(step) => setAddSubAccountWizardStep(step)}
              stepTitle={t('goal:goalDetails.addPortfolioWizardHeader')}
              goBack={() => setAddSubAccount(false)}
              onContinue={() => {
                showToast({ message: t('toastMessages:openPortfolioSuccess.h2'), severity: 'success', title: t('toastMessages:openPortfolioSuccess.h1') });
                fetchAccount({ variables: { accountId } }).then();
                setAddSubAccount(false);
                setAddSubAccountWizardStep(1);
              }}
              onSubAccountCreated={(value) => {
                if (accountsWhichRequirePaymentInstructions.includes(value.accountType as AccountTypes)) {
                  setUserContext({ ...userContext, checkRrifInformationStep: { accountId, open: true } });
                }
              }}
              accountId={accountId}
              accountType={accountType}
            />
          </Box>
        )}
      />
    );
  }
  const onSuccessTransfer = (): void => {
    setRefreshLatestTransfers(true);
    if (called) {
      refetch({
        accountId,
      });
    }
  };
  if (showDeposit) {
    return (
      <FlowModal
        open={showDeposit}
        onClose={() => setShowDeposit(false)}
        showCloseButton
        maintainModal
        component={(
          <Deposit
            hideBankAccountDeposit={hideBankAccountDeposit}
            hideExistingPortfolioDeposit={hideExistingPortfolioTransfer(userContext.subAccounts ?? [])}
            onClose={() => setShowDeposit(false)}
            onSuccessTransfer={() => {
              onSuccessTransfer();
            }}
            selectedGoalId={subAccounts.length > 0 ? subAccounts[0].goal.id : undefined}
            preSelectedAccountType={accountType}
            initialStep={lockedInAccount.includes(accountType as AccountTypes) ? 3 : 1}
          />
        )}
      />
    );
  }
  const rifHasNotBeenCompleted = (): boolean => isFeatureEnabled(accountType as FeatureFlagTypes) && accountsWhichRequirePaymentInstructions.includes(accountType as AccountTypes) && (showRrifNotificationBanner || showRrifNoTransferBanner);
  const onSuccessWithdraw = (): void => {
    console.log('SUCCESSFUL WITHDRAW DETECTED');
    setRefreshLatestTransfers(true);
    if (called) {
      refetch({
        accountId,
      });
    }
  };

  if (showWithdrawal) {
    return (
      <Withdraw
        onClose={() => setShowWithdrawal(false)}
        onSuccessWithdraw={() => {
          onSuccessWithdraw();
        }}
      />
    );
  }
  return (
    <Container className="home-page" sx={styles.subAccountDetailsContainer}>
      <Box sx={styles.detailBox}>
        <Box sx={{ paddingLeft: '24px', position: 'relative', top: '-7px' }}>
          <OvBreadCrumb breadcrumbItems={[
            { title: t('base:breadcrumb.home'), onClick: () => navigate(setUserContext, '/') },
            { title: getAccountTypeName(accountType) },
          ]} />
        </Box>
        {
          isFeatureEnabled(FeatureFlagTypes.CLOSE_ACCOUNT) && (
            <Box sx={styles.dotMenueWrapper}>
              <MoreVertIcon data-testid="more-menu-icon" sx={styles.menuIcon} style={{ top: matches ? '8px' : '11px' }} onClick={handleClick} />
              <OvMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                menuItems={MENU_ITEMS}
              />
            </Box>
          )
        }
      </Box>
      {
        rifHasNotBeenCompleted() ? (
          <Alert
            sx={{
              backgroundColor: colors.neutral10,
              margin: '0px 28px 16px 28px',
              color: colors.secondary,
              border: `1px solid ${colors.secondary}`,
            }}
            icon={<ErrorIcon style={{ color: colors.black }} />}
          >
            <Box display="flex" alignItems="center">
              {showRrifNoTransferBanner ? t('account:rrifNoTransferAlert', { accountType: getAccountTypeName(accountType) }) : t('account:rrifNoPaymentInstructionAlert', { accountType: getAccountTypeName(accountType) })}
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setUserContext({ ...userContext, checkRrifInformationStep: { accountId, open: true } });
                }}
              >
                <Typography variant="heading5" ml={2} mb={0}>
                  {t('account:rrifSteps.complete')}
                </Typography>
                <ChevronRightRoundedIcon sx={{ color: colors.black }} />
              </Box>
            </Box>
          </Alert>
        ) : undefined
      }
      <Box sx={styles.detailWrapper}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={!matches ? 7 : 12}>
            <Item
              sx={styles.leftCage}
              style={{
                padding: '0px', paddingTop: 16, marginLeft: !matches ? '24px' : '0px', background: colors.baseComponentBackground,
              }}
            >
              {showHousehold && (
                <Box display="flex" flexDirection="row" justifyContent="end" sx={{ m: 1 }}>
                  <HouseholdBadge
                    sequenceNumber={0}
                    initials={householdInitial(householdName)}
                    toolTipLabel={householdName}
                    showInitial={false}
                  />
                </Box>
              )}
              <HistoryChart
                aggregation={{ type: DailyStatsTypes.ACCOUNTS_DAILY, typeId: accountId }}
              >
                <Box sx={styles.cageWrapper}>
                  <BalanceAndReturnValues
                    boxProps={{ marginLeft: '0px !important', height: 'auto !important', alignItems: 'center !important' }}
                    title={t('account:balanceAndReturnValues.title', { accountName: getAccountTypeName(accountType) })}
                    marketValueCents={statistics.marketValueCents}
                    returnAmount={statistics.simpleReturnAmount}
                    returnPercent={statistics.simpleReturnPercent}
                    subtitleProp={{ marginTop: '0px !important' }}
                    component="home"
                    onInfoClicked={() => setOpenAccountTypeDetailModal(true)}
                  />
                  {
                    rifHasNotBeenCompleted() ? undefined : (
                      <Box sx={styles.buttonWrapper}>
                        {
                          isFeatureEnabled(FeatureFlagTypes.DEPOSITS) && (
                            <Button
                              variant="primary-small"
                              sx={styles.investButton}
                              disabled={showHousehold || !isIdVerifiedVar()}
                              onClick={() => {
                                sendAnalyticsEvent(ovAnalyticsEvents.accountPageInvestSelect).then();
                                setRefreshLatestTransfers(false);
                                setShowDeposit(true);
                              }}
                            >
                              {t('transfer:action.invest')}
                            </Button>
                          )
                        }
                        {
                          isFeatureEnabled(FeatureFlagTypes.WITHDRAWALS) && (
                            <Button
                              variant={showHousehold ? 'primary-small' : 'secondary-small'}
                              sx={styles.withdrawButton}
                              disabled={showHousehold}
                              onClick={() => {
                                sendAnalyticsEvent(ovAnalyticsEvents.accountPageWithdrawSelect).then();
                                setShowWithdrawal(true);
                                setRefreshLatestTransfers(false);
                              }}
                            >
                              {t('transfer:action.withdraw')}
                            </Button>
                          )
                        }
                      </Box>
                    )
                  }
                  {
                    rifHasNotBeenCompleted() && (
                      <Box sx={styles.buttonWrapper}>
                        <Button
                          variant="primary-large"
                          sx={{ width: '190px' }}
                          onClick={() => {
                            console.log('this is life', userContext.checkRrifInformationStep);
                            setUserContext({ ...userContext, checkRrifInformationStep: { accountId, open: true } });
                          }}
                        >
                          {t('account:rrifSteps.finishAccountSetup')}
                        </Button>
                      </Box>
                    )
                  }
                </Box>
              </HistoryChart>
            </Item>
            <Box sx={{
              margin: '25px 0px',
              marginLeft: matches ? '10px' : '24px',
            }}>
              <Typography variant="captionSectionerBold" color={colors.neutral20} style={styles.portfolioText}>{t('goal:goalDetails.portfolios')}</Typography>
              <Grid container spacing={1}>
                {
                  subAccounts
                    ?.filter((s) => s.state !== 'INACTIVE')
                    .map((subAcc) => (
                      <Grid item md={subAccounts.length > 1 ? 6 : 12} xs={12} key={subAcc.id}>
                        <PortfolioCard
                          boxProps={{ margin: '0', sx: { cursor: 'pointer' } }}
                          key={subAcc.id}
                          themeKey={subAcc.theme?.key || ''}
                          themeIconKey={subAcc.theme?.iconKey || ''}
                          themeName={translateBackend(subAcc.theme?.translatedName) || ''}
                          accountType={subAcc.goal?.name ? subAcc.goal?.name : t(`goal:resources.typeTitle.${subAcc.goal?.type}`)}
                          marketValueCents={subAcc.statistics.marketValueCents}
                          simpleReturnAmount={subAcc.statistics.simpleReturnAmount}
                          simpleReturnPercent={subAcc.statistics.simpleReturnPercent}
                          onClick={() => {
                            sendAnalyticsEvent(ovAnalyticsEvents.goalPagePortfolioSelect).then();
                            navigate(setUserContext, '/goal/portfolio', { goalId: subAcc.goal.id, subAccountId: subAcc.id, origin: 'account' });
                          }}
                          members={members}
                        />
                      </Grid>
                    ))
                }
              </Grid>
              {
                isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_SUB_ACCOUNTS) && !hasRetirementAccount(subAccounts) && (
                  <Button
                    onClick={() => {
                      sendAnalyticsEvent(ovAnalyticsEvents.accountPageAddNewPortfolioSelect).then();
                      setAddSubAccount(true);
                    }}
                    fullWidth
                    size="large"
                    sx={{
                      mt: 2, background: 'rgba(0,0,0,0)', color: colors.primary,
                    }}
                  >
                    <AddIcon sx={{ color: colors.primary }} />
                    {t('base:button.addPortfolio')}
                  </Button>
                )
              }
              {(affiliateEnabledAccounts || affiliationCount > 0) ? (
                <Box>
                  <Box display="flex" style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <Typography variant="captionSectionerBold" color={colors.neutral20} style={{ marginBottom: '3px' }}>{t('account:affiliates.affiliates')}</Typography>
                    <IconButton style={{ marginLeft: '10px', padding: '0px' }} onClick={() => setOpenAffiliatesInfoModal(true)}><img src={informationIcon} alt="information-icon" /></IconButton>
                  </Box>
                  <Box sx={{ paddingRight: '14px' }}>
                    <AffiliatesCard
                      affiliateCount={affiliationCount}
                      onClick={() => {
                        sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryPageContinueButtonSelect).then();
                        navigate(setUserContext, '/account/affiliate', { accountType, accountId });
                      }} />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={!matches ? 5 : 12} sx={{ paddingRight: !matches ? '27px' : '14px' }}>
            <Item sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
              <Box sx={{
                margingTop: '24px',
                marginLeft: '10px',
              }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '8px' }}>
                  <Typography variant="captionSectionerBold" color={colors.neutral20}>{t('portfolio:accountHighlights')}</Typography>
                </Box>
                <ContributionsCard
                  accountType={accountType}
                  contributionValueCent={netContributionCentsInFiscalYear}
                  gainsAndLossesValueCent={capitalGainsAndLossesInFiscalYear}
                  allocationValue={Number.isNaN(allocationOfInvestment) ? 0.0 : allocationOfInvestment}
                />
              </Box>
              <Box sx={{
                marginTop: '8px',
                padding: '10px 0px 10px 10px',
                textAlign: 'left',
              }}>
                {
                  !accountsWhichRequirePaymentInstructions.includes(accountType) ? (
                    <AutoInvestmentSummary
                      accountId={accountId}
                      onEnableAutoInvestment={() => {
                        sendAnalyticsEvent(ovAnalyticsEvents.accountPageEnableAutoInvestmentSelect).then();
                      }}
                      viewAutoInvestment={() => {
                        sendAnalyticsEvent(ovAnalyticsEvents.accountPageViewAutoInvestmentSelect).then();
                      }}
                      householdViewType={showHousehold ? HouseholdViewTypes.ALL : HouseholdViewTypes.INDIVIDUAL}
                    />
                  ) : undefined
                }
                {
                  accountsWhichRequirePaymentInstructions.includes(accountType) && !loading ? (
                    <PaymentInstruction
                      account={{ id: accountId, type: accountType }}
                      viewAutoInvestment={() => {
                        sendAnalyticsEvent(ovAnalyticsEvents.accountPageViewAutoInvestmentSelect).then();
                      }}
                    />
                  ) : undefined
                }
              </Box>
              {
                process.env.REACT_APP_ENV && ['sandbox', 'staging'].includes(process.env.REACT_APP_ENV) && userContext.availableFeatureFlags?.includes('TAX_LOSS_HARVESTING') && accountType === 'PERSONAL' ? (
                  <Box sx={{
                    margingTop: '36px',
                    padding: '10px 0px 10px 10px',
                    textAlign: 'left',
                  }}>
                    <EnableTLHButton
                      accountId={accountId}
                      isEnabled={tlhEnabled}
                      netContributionCents={netContributionCents}
                      creditCents={taxLossHarvestCreditInFiscalYear}
                      onComplete={refetch}
                    />
                  </Box>
                ) : undefined
              }
              <Box sx={styles.historyWrapper}>
                <LatestTransfers
                  accountId={accountId}
                  refresh={refreshLatestTransfers}
                  onViewAllTransactionsClicked={() => sendAnalyticsEvent(ovAnalyticsEvents.accountPageViewAllTransactionsSelect).then()}
                  householdViewType={showHousehold ? HouseholdViewTypes.ALL : HouseholdViewTypes.INDIVIDUAL}
                />
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <FeedbackModal
        open={openAccountTypeDetailModal}
        onClose={() => setOpenAccountTypeDetailModal(false)}
        component={(
          <>
            <Typography textAlign="center" variant="heading3" fontSize="32px" mb={1} color={colors.secondary}>
              {t('account:accountDetailModal.title', { accountName: getAccountTypeName(accountType) })}
            </Typography>
            <Typography textAlign="center" variant="paragraphMain" mb={4}>
              {getAccountTypeDescription(accountType)}
            </Typography>
            <Button variant="primary-large" onClick={() => setOpenAccountTypeDetailModal(false)}>Got it</Button>
          </>
        )}
      />
      <FeedbackModal
        open={openAffiliatesInfoModal}
        onClose={() => setOpenAffiliatesInfoModal(false)}
        component={(
          <>
            <Typography textAlign="center" variant="heading3" fontSize="32px" mb={1} color={colors.secondary}>
              {t('account:affiliates.title')}
            </Typography>
            <Typography textAlign="center" variant="paragraphMain" mb={4}>
              {t('account:affiliates.body')}
            </Typography>
            <Button variant="primary-large" onClick={() => setOpenAffiliatesInfoModal(false)}>{t('account:affiliates.understand')}</Button>
          </>
        )}
      />
      <ConfirmationModal
        title={t('account:infoDialog.title')}
        description={t('account:infoDialog.details')}
        buttonText={t('account:infoDialog.continueButton')}
        open={closeAccountInfo}
        onButtonClick={() => {
          sendAnalyticsEvent(ovAnalyticsEvents.accountPageCloseAccountContinueSelect).then();
          setCloseAccountInfo(false);
          setCloseAccountConfirmation(true);
        }}
        onClose={() => setCloseAccountInfo(false)}
      />
      <ConfirmationModal
        title={t('account:confirmationDialog.title')}
        description={t('account:confirmationDialog.details')}
        buttonText={t('account:confirmationDialog.continueButton')}
        open={closeAccountConfirmation}
        onButtonClick={() => {
          if (subAccounts?.filter((s) => s.state !== 'INACTIVE').length > 0) {
            setCloseAccountConfirmation(false);
            setCloseAccountError(true);
            setCloseAccountMsg(t('account:errorDialog.errorMessages.CLOSING_OPEN_SUBACCOUNT'));
          } else {
            sendAnalyticsEvent(ovAnalyticsEvents.accountPageCloseAccountConfirmSelect).then();
            completeAccountMutation().then();
          }
        }}
        cancelButton={{
          onClick: () => setCloseAccountConfirmation(false),
          text: t('account:confirmationDialog.cancelButton'),
        }}
        onClose={() => setCloseAccountConfirmation(false)}
      />
      <ConfirmationModal
        title={t('account:errorDialog.title')}
        description={closeAccountMsg}
        buttonText={t('account:errorDialog.continueButton')}
        open={closeAccountError}
        onButtonClick={() => setCloseAccountError(false)}
        onClose={() => setCloseAccountError(false)}
      />
    </Container>
  );
};

export default AccountDetails;
