/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Typography } from '@mui/material';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import * as React from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { colors } from '../../theme/colors';
import { formatMoneyValue } from '../../utils/commonMethods';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import FlowModal from '../common/wrappers/modals/ovFlowModal';
import EditPaymentInstructionWizard from '../paymentInstructions/editPaymentInstructionWizard';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import EngineGearWithMoneyImage from '../../assets/images/engine-gear-with-money.svg';
import { UserContext } from '../../providers/userContextProvider';
import informationIcon from '../../assets/images/information.svg';
import { showInformationDialog } from '../../utils/ui/informationDialog';
import { FETCH_TRANSFERS } from './graphql';

const FETCH_RRIF_ACCOUNT_SCHEDULED_INCOME_TRANSFER_FUND = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        statistics {
          id
          netContributionCents
          pendingContributionCents
        }
        scheduledIncomeFundTransfer {
          id
          scheduledDate
          minimumAnnualAmountCents
          annualAmountCents
          subAccount {
            id
            theme {
              id
              translatedName {
                en
                fr
              }
            }
          }
          bankAccount {
            id
          }
          frequency
          amountPayableType
          dateOfBirth
        }
      }
    }
  }
`;

interface Props {
  account: {
    id: string,
    type: string,
  },
  viewAutoInvestment?: () => void,
  hasPaymentInstructions?: (value: boolean) => void,
  hasNoTransfer?: (value: boolean) => void,
}

const styles = {
  outerBox: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'start',
    justifyContent: 'center',
    padding: '15px 16px 20px 16px',
    backgroundColor: colors.autoInvestmentSummaryCard,
    borderRadius: '6px',
    cursor: 'pointer',
  },
  addPaymentInstructionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 16px',
    backgroundColor: colors.ovWhite,
    cursor: 'pointer',
    alignItems: 'center',
    border: '1px solid rgba(10, 46, 101, 0.09)',
    boxShadow: '3px 6px 10px rgba(0, 102, 245, 0.03)',
    borderRadius: '4px',
    boxSizing: 'border-box' as const,
  },
  targetMessage: {
    textAlign: 'left',
    marginTop: '12px',
    borderBottom: '1px dashed #DDD9D9',
    paddingBottom: '12px',
  },
  headerText: {
    color: colors.autoInvestmentSummaryHeader,
    margin: 0,
  },
  bodyText: {
    color: colors.autoInvestmentSummaryBody,
    margin: 0,
  },
  autoInvestTitle: {
    marginBottom: '0px',
    marginRight: '10px',
    color: colors.neutral20,
    textAlign: 'left',
  },
  enableAutoInvestButton: {
    backgroundColor: colors.baseComponentBackground,
    height: '60px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    border: '1px solid rgba(10, 46, 101, 0.09)',
    borderRadius: '4px',
    boxShadow: '3px 6px 10px rgba(0, 102, 245, 0.03)',
    chevronIcon: {
      color: colors.ovBlack,
    },
    box: {
      image: {
        marginRight: '18px',
      },
      display: 'flex',
      flexDirection: 'row' as const,
      alignItems: 'center',
    },
  },
};

const PaymentInstruction = ({
  account,
  viewAutoInvestment,
  hasPaymentInstructions,
  hasNoTransfer,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user', 'transfer']);
  const [showAutoInvestModal, setShowAutoInvestModal] = useState(false);
  const { userContext, setUserContext } = useContext(UserContext);
  const [openInfoModal, setOpenInfoModal] = useState<{ open: boolean, header: string, body: string, }>({
    open: false,
    header: '',
    body: '',
  });
  const closeInformationModal = (status: boolean): void => setOpenInfoModal((prevState) => ({ ...prevState, open: status }));

  const fetchTransfersResponse = useQuery(FETCH_TRANSFERS, {
    variables: {
      input: {
        filter: {
          accountId: account.id,
        },
        pagination: {
          perPage: 4,
          sortField: 'createdAt',
          sortDesc: false,
        },
      },
    },
  });

  const fetchRrifAccountResponse = useQuery(FETCH_RRIF_ACCOUNT_SCHEDULED_INCOME_TRANSFER_FUND, {
    variables: {
      accountId: account.id,
    },
    onCompleted: (response) => {
      const { netContributionCents, pendingContributionCents } = response.fetchAccount.account.statistics;
      if (hasNoTransfer && netContributionCents === 0 && pendingContributionCents === 0) {
        hasNoTransfer(true);
      }
      if (hasPaymentInstructions) {
        hasPaymentInstructions(response?.fetchAccount?.account?.scheduledIncomeFundTransfer !== null);
      }
    },
  });

  // This triggers a recheck when the checkRrifInformationStep is true.
  useEffect(() => {
    if (!userContext.checkRrifInformationStep) {
      fetchRrifAccountResponse.refetch().then();
    }
  }, [userContext.checkRrifInformationStep]);
  // This triggers a recheck when the checkRrifInformationStep changes.
  useEffect(() => {
    fetchRrifAccountResponse.refetch().then();
  }, [userContext.checkRrifInformationStep?.refreshComponent]);

  const convertToString = (autoInvestDate: string): string => {
    dayjs.extend(advancedFormat);
    return dayjs(autoInvestDate).local().format('Do of MMMM YYYY');
  };

  const showPaymentInstructionInformationModal = ():void => setOpenInfoModal({
    open: true,
    header: t('account:rrifSteps.fieldInformation.title'),
    body: t('account:rrifSteps.fieldInformation.paymentInstructionBody'),
  });

  if (openInfoModal.open) {
    return showInformationDialog({
      open: openInfoModal.open,
      header: openInfoModal.header,
      body: openInfoModal.body,
      buttonText: t('account:rrifSteps.fieldInformation.confirmationButton'),
      onClose: (value) => closeInformationModal(value),
    });
  }

  const paymentInstructionCard = (): JSX.Element => {
    const scheduledFund = fetchRrifAccountResponse.data.fetchAccount.account.scheduledIncomeFundTransfer;
    const frequency = t(`transfer:paymentInstruction.frequency.${scheduledFund.frequency}`);
    const nextPaymentAmount = scheduledFund.annualAmountCents ?? scheduledFund.minimumAnnualAmountCents ?? 0;
    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" mb={0.6}>
            <Typography variant="heading5" sx={styles.autoInvestTitle}>{t('transfer:paymentInstruction.card.title')}</Typography>
            <IconButton style={{ margin: '0px', padding: '0px' }} onClick={() => showPaymentInstructionInformationModal()}>
              <img src={informationIcon} style={{ marginTop: '2px' }} alt="information-icon" />
            </IconButton>
          </Box>
          <Typography
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              textDecorationColor: colors.link,
            }}
            variant="captionSectionerBold"
            color={colors.link}
            onClick={() => {
              sendAnalyticsEvent(ovAnalyticsEvents.rrifAccountDetailsPageEditButtonSelect).then();
              setShowAutoInvestModal(true);
            }}>
            {t('transfer:paymentInstruction.edit')}
          </Typography>
        </Box>
        <Box
          style={styles.outerBox}
          onClick={() => {
            if (viewAutoInvestment) {
              viewAutoInvestment();
            }
            setShowAutoInvestModal(true);
          }}
        >
          <Typography variant="heading5" style={styles.headerText}>
            {t('transfer:paymentInstruction.card.subTitle', { frequency: frequency.toLowerCase() })}
          </Typography>
          <Box mb={1} />
          <Typography variant="paragraph3" style={styles.bodyText}>
            <Markup content={t(`transfer:paymentInstruction.card.${nextPaymentAmount > 0 ? 'content' : 'content2'}`, {
              amount: formatMoneyValue(nextPaymentAmount, undefined, 0),
              date: convertToString(scheduledFund.scheduledDate ?? ''),
            })} />
          </Typography>
        </Box>
      </>
    );
  };

  const addPaymentInstructionCard = (): JSX.Element => (
    <Box
      style={styles.addPaymentInstructionBox}
      onClick={() => {
        setUserContext({ ...userContext, checkRrifInformationStep: { accountId: account.id, open: true } });
      }}
    >
      <Box display="flex" alignItems="center">
        <img src={EngineGearWithMoneyImage} alt="setup-payment-instruction" />
        <Typography variant="heading5" ml={2} mb={0}>
          {fetchTransfersResponse.data?.fetchTransfers?.transfers.length === 0 ? t('transfer:paymentInstruction.makeInvestmentAndSetupPaymentInstruction') : t('transfer:paymentInstruction.setupPaymentInstruction')}
        </Typography>
      </Box>
      <ChevronRightRoundedIcon sx={{ color: colors.black }} />
    </Box>
  );

  const displayUI = (): JSX.Element => {
    if (fetchRrifAccountResponse.loading || fetchTransfersResponse.loading) {
      return (
        <OvLoadingIndicator />
      );
    }
    const scheduledIncomeFundTransfer = fetchRrifAccountResponse.data?.fetchAccount?.account.scheduledIncomeFundTransfer;
    return scheduledIncomeFundTransfer ? paymentInstructionCard() : addPaymentInstructionCard();
  };

  if (showAutoInvestModal) {
    return (
      <FlowModal
        open={showAutoInvestModal}
        onClose={() => setShowAutoInvestModal(false)}
        showCloseButton={false}
        component={(
          <EditPaymentInstructionWizard
            account={account}
            hideNavBar
            paymentInstructionToUpdate={fetchRrifAccountResponse.data?.fetchAccount?.account?.scheduledIncomeFundTransfer}
            onClose={() => {
              fetchRrifAccountResponse.refetch();
              setShowAutoInvestModal(false);
            }}
          />
        )}
      />
    );
  }
  return (
    displayUI()
  );
};

PaymentInstruction.defaultProps = {
  viewAutoInvestment: undefined,
  hasPaymentInstructions: undefined,
  hasNoTransfer: undefined,
};

export default PaymentInstruction;
