import { Box, BoxProps } from '@mui/system';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import informationIcon from '../../../assets/images/information.svg';
import { colors } from '../../../theme/colors';
import { formatReturnAmount } from '../../../utils/commonMethods';
import { CALCULATE_WITHDRAWAL_FEES } from '../graphql';
import IlliquidPortfolioWithdrawalInfoModal from './illiquidPortfolioWithdrawalInfoModal';

const ILLIQUID_PORTFOLIO_ESTIMATE_AMOUNT_SPREAD = 0.05;

interface Props {
  boxProps?: BoxProps,
  accountId: string,
  availableToWithdrawCents: number,
  withdrawAmountCents: number,
  isIlliquidWithdrawal?: boolean,
  showDetails: () => void,
}

const styles = {
  outerBox: {
    border: '1px solid rgba(10, 46, 101, 0.09)',
    boxShadow: '3px 6px 10px rgba(0, 102, 245, 0.03)',
    borderRadius: '4px',
  },
  innerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderBottom: '1px dashed #DDD9D9',
  },
  image: {
    marginTop: '3px',
    color: colors.neutral30,
  },
};

const WithdrawalBreakdown = ({
  boxProps,
  availableToWithdrawCents,
  accountId,
  withdrawAmountCents,
  isIlliquidWithdrawal,
  showDetails,
}: Props): JSX.Element => {
  const { t } = useTranslation(['transfer']);
  const [totalFees, setTotalFees] = useState(0);
  const [fetchingFees, setFetchingFees] = useState(false);
  const [withdrawalFees, setWithdrawalFees] = useState<{ type: string, amountCents: number, }[]>([]);
  const [showIlliquidWarningModal, setShowIlliquidWarningModal] = useState(false);
  const setCalculateWithdrawalFeesData = (data: {
    calculateWithdrawFees: {
      withdrawFees: {
        type: string,
        amountCents: number,
      }[]
    },
  }): void => {
    let feesCents = 0;
    data.calculateWithdrawFees.withdrawFees.forEach((withdrawFee) => {
      feesCents += withdrawFee.amountCents;
    });
    setTotalFees(feesCents);
    setWithdrawalFees(data.calculateWithdrawFees.withdrawFees);
    setFetchingFees(false);
  };
  const [calculateWithdrawalFees] = useLazyQuery(CALCULATE_WITHDRAWAL_FEES, {
    variables: {
      input: {
        accountId,
        amountCents: withdrawAmountCents,
        isfullWithdraw: withdrawAmountCents >= (availableToWithdrawCents * 0.98),
      },
    },
    onCompleted: (e) => setCalculateWithdrawalFeesData(e),
    onError: () => setFetchingFees(false),
    nextFetchPolicy: 'standby',
  });

  useEffect(() => {
    if (accountId) {
      setFetchingFees(true);
      calculateWithdrawalFees().then();
    }
  }, [calculateWithdrawalFees, accountId]);

  const breakdownItems = (children: JSX.Element, value: string, key?: string, color: string = colors.secondary): JSX.Element => (
    <Box key={key} style={styles.innerBox}>
      <Box display="flex" alignItems="center">
        {children}
      </Box>
      <Typography variant="bodySub2" color={color}>{value}</Typography>
    </Box>
  );
  return (
    <Box {...boxProps} style={styles.outerBox}>
      {
        breakdownItems(
          <>
            <Typography variant="bodySub2" color={colors.neutral30} style={{ marginBottom: 0, marginRight: '7.5px' }}>
              {t('transfer:withdrawal.withdrawalBreakDown.availableToWithdraw')}
            </Typography>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={showDetails}
            >
              <img src={informationIcon} style={styles.image} alt="info" height={12} width={12} />
            </IconButton>
          </>,
          formatReturnAmount(availableToWithdrawCents, '$', 2),
          colors.neutral30,
        )
      }
      {
        breakdownItems(
          <Typography variant="bodySub2" style={{ marginBottom: 0, marginRight: '7.5px' }}>
            {t('transfer:withdrawal.withdrawalBreakDown.withdrawalAmount')}
          </Typography>,
          formatReturnAmount(withdrawAmountCents, '$', 2),
        )
      }
      {
        withdrawalFees.map((withdrawalFee) => (
          breakdownItems(
            <Typography variant="bodySub2" style={{ marginBottom: 0, marginRight: '7.5px' }}>
              {t(`transfer:withdrawal.withdrawalBreakDown.feesAndTaxTypes.${withdrawalFee.type}`)}
            </Typography>,
            fetchingFees ? t('transfer:withdrawal.withdrawalBreakDown.loadingMessage') : formatReturnAmount(withdrawalFee.amountCents, '$', 2),
            withdrawalFee.type,
            colors.secondary,
          )))
      }
      <Box display="flex" flexDirection="column" style={{ padding: '16px' }}>
        <Typography variant="bodySub1" style={{ marginBottom: '10px', marginRight: '7.5px' }}>
          {t('transfer:withdrawal.withdrawalBreakDown.estimatedWithdrawalAmount')}
          { isIlliquidWithdrawal && (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setShowIlliquidWarningModal(true)}
            >
              <img src={informationIcon} style={styles.image} alt="info" height={12} width={12} />
            </IconButton>
          )}
          { showIlliquidWarningModal
            && <IlliquidPortfolioWithdrawalInfoModal onClose={() => setShowIlliquidWarningModal(false)} />}
        </Typography>
        <Typography variant="heading2" style={{ marginBottom: 0, marginRight: '7.5px' }}>
          { !isIlliquidWithdrawal
            ? formatReturnAmount(withdrawAmountCents - totalFees, '$', 2)
            : (
              <>
                {formatReturnAmount(withdrawAmountCents * (1 - ILLIQUID_PORTFOLIO_ESTIMATE_AMOUNT_SPREAD) - totalFees, '$', 2)}
                &nbsp; - &nbsp;
                {formatReturnAmount(withdrawAmountCents * (1 + ILLIQUID_PORTFOLIO_ESTIMATE_AMOUNT_SPREAD) - totalFees, '$', 2)}
              </>
            ) }
        </Typography>
      </Box>
    </Box>
  );
};

WithdrawalBreakdown.defaultProps = {
  boxProps: undefined,
  isIlliquidWithdrawal: false,
};

export default WithdrawalBreakdown;
