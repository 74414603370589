import { gql } from '@apollo/client';
import { ClientGroup } from '../../utils/commonGraphql';
import { FinancialProduct } from '../../interfaces/financialProduct';

export type SequenceAndId = { sequence: number, id: string };
export const FETCH_GOAL = (withMemberStats?: SequenceAndId[]) => gql`
  query fetchGoal($goalId: ObjectID!, $startDate: Date, $forUserId: ObjectID) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        type
        name
        state
        targetAmountCents
        financialProduct {
          id
          theme {
            id
            name
          }
        }
        user {
          id
          firstName
        }
        statistics(forUserId: $forUserId) {
          id
          marketValueCents
          simpleReturnAmount(startDate : $startDate)
          simpleReturnPercent(startDate : $startDate)
          availableFundsToWithdrawCents
        }
        ${(withMemberStats || []).map(({ id, sequence }) => `
          user${sequence}statistics:statistics(forUserId:"${id}") {
            id
            marketValueCents
          }
        `)}
        householdClientGroup {
          id
          name
          relationships {
            user {
              id
              firstName
            }
            accessType
          }
        }
        subAccounts{
          id
          state
          statistics {
            id
            marketValueCents
            simpleReturnAmount
            simpleReturnPercent
          }
          theme {
            id
            key
            translatedName { en fr }
            iconKey
          }
          account {
            id
            state
            type
            user {
              id
              firstName
            }
            householdClientGroup {
              id
            }
          }
          allowClientDeposits
        }
      }
    }
  }
`;

export const UPDATE_GOAL_DETAILS = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
        type
        name
        targetAmountCents
      }
    }
  }
`;

export const COMPLETE_GOAL = gql`
  mutation completeGoal($input: CompleteGoalInput!) {
    completeGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

export interface GoalDetailsResponse {
  id?: string,
  name?: string,
  type?: string,
  state?: string,
  targetAmountCents?: number,
  financialProduct?: FinancialProduct,
  user?: {
    id: string,
    firstName?: string,
  },
  statistics?: {
    marketValueCents: number,
    simpleReturnPercent: number,
    simpleReturnAmount: number,
    availableFundsToWithdrawCents: number,
  },
  totalStats?: {
    marketValueCents: number,
    simpleReturnPercent: number,
    simpleReturnAmount: number,
    availableFundsToWithdrawCents: number,
  },
  householdClientGroup?: ClientGroup,
  subAccounts?: {
    id: string,
    state: string,
    statistics: {
      marketValueCents: number,
      simpleReturnPercent: number,
      simpleReturnAmount: number,
    },
    theme: {
      key: string,
      translatedName: {
        en: string,
        fr: string,
      },
      iconKey?: string,
    },
    account: {
      id: string,
      type: string,
      state: string,
      user: {
        id: string,
        firstName: string,
      },
      householdClientGroup?: ClientGroup,
    },
    allowClientDeposits: boolean,
  }[],
}

export interface UpdatedGoalResponse {
  id: string,
  name: string,
  type: string,
  targetAmountCents: number,
}
