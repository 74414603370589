import * as React from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

interface HoldingsItemProps {
  hexCode: string,
  prefix?: string,
  holdingsType: string,
  percent: number,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
}

const HoldingsListItem = ({ ...props }: HoldingsItemProps): JSX.Element => (
  <Button variant="button-list-item" onClick={props.onClick} style={{ marginBottom: '10px' }}>
    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box minHeight={16} minWidth={16} bgcolor={props.hexCode} borderRadius={1} />
        {
          props.prefix ? (
            <>
              <Typography textAlign="left" variant="captionSectionerBold" ml={1.8} mb={0}>{props.prefix}</Typography>
              <Typography textAlign="left" variant="captionSectionerMedium" ml={1.8} mb={0}>-</Typography>
            </>
          ) : undefined
        }
        <Typography textAlign="left" variant="captionSectionerMedium" paddingY={0.6} ml={1.8} mb={0}>{props.holdingsType}</Typography>
      </Box>
      <Typography paddingLeft={1} variant="paragraph2">{`${props.percent}%`}</Typography>
    </Box>
  </Button>
);

HoldingsListItem.defaultProps = {
  onClick: undefined,
  prefix: undefined,
};
export default HoldingsListItem;
