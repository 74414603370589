/* eslint-disable  @typescript-eslint/no-explicit-any */

import { useRef, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useApolloClient, useMutation } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import WithStepWizard from '../common/wrappers/withStepWizard';
import TrackingBar from '../common/headers/trackingBar';
import { AccountTypes, ThemeAttributes } from './resources';
import SelectTheme from './selectTheme';
import ThemeDescription from './themeDescription';
import { FETCH_SUB_ACCOUNT_IPS_FORMS, UPDATE_SUB_ACCOUNT } from './graphql';
import ThemeSummary from './themeSummary';
import ThemeConfirmation from './themeConfirmation';
import { SIGN_FORM_AGREMETNS } from '../documents/graphql';
import { FormTemplate } from '../documents/incompleteAgreements';

const ThemeSummaryStep = WithStepWizard(ThemeSummary);
const SelectThemeStep = WithStepWizard(SelectTheme);
const ThemeDescriptionStep = WithStepWizard(ThemeDescription);
const ThemeConfirmationStep = WithStepWizard(ThemeConfirmation);

export interface UserState {
  subAccounts: { id: string, name?: string, account?: { type: AccountTypes } }[],
}

export interface UpdateUserState {
  subAccountId: string,
  themeId: string,
  financialProductId: string,
}

export interface SubAccountRef {
  id?: string,
  goalId: string,
  themeId?: string,
  accountType?: AccountTypes,
  name?: string,
  financialProductId?: string,
}

interface Props {
  subAccount: { id: string },
  themeAttributes: ThemeAttributes,
  goalId: string,
  onContinue?: (selectedTheme: ThemeAttributes | undefined) => void,
  goBack?: () => void,
  availableThemes: { id: string}[],
}

const UpdateThemeWizard = ({
  subAccount,
  onContinue,
  goBack,
  goalId,
  themeAttributes,
  availableThemes,
}: Props): JSX.Element => {
  const client = useApolloClient();
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const [selectedTheme, setSelectedTheme] = useState<ThemeAttributes>(themeAttributes);
  const [loading, setLoading] = useState(false);
  const subAccountRef = useRef<SubAccountRef>({ id: subAccount?.id, goalId });
  const setSubAccountRef = (att: Partial<SubAccountRef>): void => {
    subAccountRef.current = { ...subAccountRef.current, ...att };
  };

  const fetchSubAccountIpsForm = (id: string): Promise<ApolloQueryResult<any>> => client.query({
    query: FETCH_SUB_ACCOUNT_IPS_FORMS,
    variables: { subAccountId: id },
    fetchPolicy: 'network-only',
  });

  const fetchIpsFormAndSign = (id: string): void => {
    const interval = setInterval(async () => {
      const result = await fetchSubAccountIpsForm(id);
      const forms = result.data.fetchSubAccount.subAccount.incompleteFormAgreements as FormTemplate[];
      if (forms && forms.length > 0 && forms.find((form) => form.type === 'INVESTMENT_POLICY_STATEMENT')) {
        const ipsForm = forms.find((form) => form.type === 'INVESTMENT_POLICY_STATEMENT');
        const ipsResult = await client.mutate({
          mutation: SIGN_FORM_AGREMETNS,
          variables: {
            input: {
              subAccountId: id,
              formAgreements: [{
                type: ipsForm?.type ?? 'INVESTMENT_POLICY_STATEMENT',
                version: ipsForm?.minVersion ?? 1,
              }],
            },
          },
        });
        if (ipsResult.data) {
          clearInterval(interval);
        }
      }
    }, 4000);
  };
  const onUpdateSubAccountCompleted = (data: { updateSubAccount: { subAccount: { id: string, name: string } } }): void => {
    console.log({ event: 'UPDATED_SUB_ACCOUNT', subAccountId: data.updateSubAccount.subAccount.id });
    if (subAccountRef.current.id) fetchIpsFormAndSign(subAccountRef.current.id);
    setLoading(false);
    if (onContinue) onContinue(selectedTheme);
  };
  const [updateSubAccountMutation] = useMutation(UPDATE_SUB_ACCOUNT, {
    onCompleted: onUpdateSubAccountCompleted,
    onError: () => setLoading(false),
  });
  const updateSubAccountTheme = (): void => {
    updateSubAccountMutation({
      variables: {
        input: { subAccountId: subAccountRef.current.id, themeId: selectedTheme?.id, financialProductId: subAccountRef.current.financialProductId },
      },
    }).then();
    setLoading(true);
  };
  const steps: JSX.Element[] = [
    <ThemeSummaryStep hasMultipleAvailableTheme={availableThemes.length > 1} stepTitle="" stepName="themeSummary" theme={themeAttributes} key="themeSummary" goBack={goBack} disableTracker />,
    <SelectThemeStep stepTitle="" stepName="selectTheme" goalId={goalId} setSubAccountRef={setSubAccountRef} selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} key="selectTheme" />,
    <ThemeDescriptionStep stepTitle="" stepName="themeDescription" goalId={goalId} setSubAccountRef={setSubAccountRef} selectedTheme={selectedTheme} showLoading={loading} key="themeDescription" />,
    <ThemeConfirmationStep stepTitle="" stepName="themeConfirmation" key="themeConfirmation" action={updateSubAccountTheme} showLoading={loading} />,
  ];

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      transitions={{}}
      className="ov-step-wizard"
      nav={(
        <TrackingBar steps={steps} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

UpdateThemeWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
};

export default UpdateThemeWizard;
