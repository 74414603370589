import { Button, Typography } from '@mui/material';
import * as React from 'react';
import { colors } from '../../theme/colors';
import FeedbackModal from '../../components/common/wrappers/modals/ovFeedbackModal';

export const showInformationDialog = (opt: {
  open: boolean,
  header: string,
  body: string,
  buttonText: string,
  onClose: (value: boolean) => void,
}): JSX.Element => (
  <FeedbackModal
    open={opt.open}
    onClose={() => opt.onClose(false)}
    component={(
      <>
        <Typography variant="heading2" mb={1} color={colors.secondary}>
          {opt.header}
        </Typography>
        <Typography textAlign="left" variant="paragraphMain" mb={4}>
          {opt.body}
        </Typography>
        <Button variant="primary-large" onClick={() => opt.onClose(false)}>{opt.buttonText}</Button>
      </>
    )}
  />
);
