import {
  Box,
} from '@mui/material';
import { uniqueId } from 'lodash';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { ArrowTooltip } from '../common/components/arrowToolTip';
import { householdColor } from '../common/components/householdColors';

interface MultiLinearProgressProps {
  maximum: number
  values: number[]
  labels?: string[]
  backgroundColors?: string[]
}

const MultiLinearProgress = ({
  maximum, values, labels, backgroundColors,
}: MultiLinearProgressProps) => {
  const height = '12px';
  const { colors } = useContextTheme();

  const getColor = (index: number): string => {
    if (backgroundColors) return backgroundColors[index];
    if (index === 0) return colors.progressBarColor;
    return householdColor(index).bar;
  };

  return (
    <span style={{
      display: 'inline-block',
      width: '100%',
      height,
      backgroundColor: colors.goalProgressBar,
      borderRadius: 20,
    }}>
      {values.filter((value) => value > 0).map((value, index) => (
        <ArrowTooltip key={uniqueId()} title={labels ? labels[index] : ''}>
          <span
            key={uniqueId()}
            style={{
              display: 'inline-block',
              height,
              width: `${(100 * value) / maximum}%`,
              backgroundColor: getColor(index),
              borderRadius: 20,
            }}
          >
            <Box
              style={{
                display: 'inline',
                position: 'relative',
              }}>
              {/* eslint-disable-next-line */}
              <>&nbsp;</>
            </Box>
          </span>
        </ArrowTooltip>
      ))}
    </span>
  );
};

MultiLinearProgress.defaultProps = {
  labels: undefined,
  backgroundColors: undefined,
};

export default MultiLinearProgress;
