import {
  Typography,
  List,
  Box,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import HelpIcon from '@mui/icons-material/Help';
import { useContext, useState } from 'react';
import { Markup } from 'interweave';
import ButtonListItem from '../../common/lists/buttonListItem';
import { hasTrustedContact } from '../resources';
import OvForm from '../../common/wrappers/ovForm';
import { colors } from '../../../theme/colors';
import { UserContext } from '../../../providers/userContextProvider';
import FeedbackModal from '../../common/wrappers/modals/ovFeedbackModal';

export interface UserState {
  hasTrustedContact: string,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
}

const HasTrustedContactPerson = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const { userContext } = useContext(UserContext);
  const [openTCPDefinitionModal, setOpenTCPDefinitionModal] = useState(false);
  const onItemSelected = (item: Record<string, string>): void => {
    console.log({ event: 'TRUSTED_CONTECT_PERSON_CHOICE', hasTrustedContactPerson: item.value });
    updateUserState({ ...user, hasTrustedContact: item.value });
    if (props.onContinue) props.onContinue();
  };

  if (openTCPDefinitionModal) {
    return (
      <FeedbackModal
        open={openTCPDefinitionModal}
        onClose={() => setOpenTCPDefinitionModal(false)}
        component={(
          <>
            <Typography variant="heading2">{t('user:howToInvest.hasTrustedContact.trustedContactPersonInfo.title')}</Typography>
            <Markup content={t('user:howToInvest.hasTrustedContact.trustedContactPersonInfo.info')} />
            <Button variant="primary-large" onClick={() => setOpenTCPDefinitionModal(false)}>Got it</Button>
          </>
        )}
      />
    );
  }

  return (
    <OvForm loading={false} hasButton={false}>
      <Typography variant="heading2">{t('user:howToInvest.hasTrustedContact.title')}</Typography>
      {['picton', 'pmam'].includes(userContext.organizationKey ?? '') ? (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ mr: 1 }} variant="paragraph3">{t('user:howToInvest.hasTrustedContact.subTitle')}</Typography>
          <HelpIcon onClick={() => setOpenTCPDefinitionModal(true)} sx={{ fontSize: '16px', cursor: 'pointer', color: colors.gray80 }} />
        </Box>
      ) : <Typography variant="paragraph3">{t('user:howToInvest.hasTrustedContact.subTitle')}</Typography> }
      <List sx={{ maxHeight: '250px', overflowY: 'auto' }}>
        {hasTrustedContact.map((item: Record<string, string>) => (
          <Box key={item.name}>
            <ButtonListItem
              key={item.name}
              text={item.name}
              onClick={() => {
                if (item.value) onItemSelected(item);
              }}
              isSelected={false}
            />
            <Box mb={1} />
          </Box>
        ))}
      </List>
    </OvForm>
  );
};

HasTrustedContactPerson.defaultProps = {
  onContinue: undefined,
};

export default HasTrustedContactPerson;
