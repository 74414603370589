import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/system/createTheme';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme/colors';
import { formatMoneyValue } from '../../utils/commonMethods';
import { ScheduledIncomeFundTransferProp } from './editPaymentInstructionWizard';
import { translateBackend } from '../../assets/i18n/config';

interface Props {
  scheduledTransfer: ScheduledIncomeFundTransferProp,
  onEdit: (scheduledTransfer: ScheduledIncomeFundTransferProp) => void,
  sx?: SxProps<Theme>,
}

const style = {
  outerBox: {
    height: '70px',
    padding: '16px 12px',
    boxSizing: 'border-box' as const,
    border: '1px solid rgba(218, 228, 237, 0.4)',
    boxShadow: '0px 4px 10px rgba(234, 236, 239, 0.25)',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const PaymentInstructionListItem = ({
  scheduledTransfer,
  onEdit,
  sx,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'account']);
  return (
    <Box style={style.outerBox} sx={sx}>
      <Box display="flex" alignItems="center">
        <Box mr={1.8} />
        <Box>
          <Typography variant="bodySub1" style={{ marginBottom: 0, fontWeight: 500 }}>
            {`${t('account:resources.typeTitle.RRIF')} -  ${translateBackend(scheduledTransfer?.subAccount?.theme?.translatedName)}`}
          </Typography>
          <Box mb={0.5} />
          <Typography variant="heading5" style={{ marginBottom: 0, color: colors.neutral30 }}>{`${formatMoneyValue(scheduledTransfer?.annualAmountCents ?? scheduledTransfer?.minimumAnnualAmountCents ?? 0)}  ${t(`account:rrifSteps.rrifAnnualPaymentInstruction.frequency.${scheduledTransfer.frequency}`)}`}</Typography>
        </Box>
      </Box>
      <Typography onClick={() => onEdit(scheduledTransfer)} variant="bodySub1" sx={{ marginBottom: 0, color: colors.link, cursor: 'pointer' }}>{t('base:link.edit')}</Typography>
    </Box>
  );
};

PaymentInstructionListItem.defaultProps = {
  sx: undefined,
};

export default PaymentInstructionListItem;
