/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box, FormControlLabel, RadioGroup, Stack, TextField, Typography,
} from '@mui/material';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useLazyQuery } from '@apollo/client';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Filter from '../common/components/filter';
import { colors } from '../../theme/colors';
import DropdownInput from '../common/inputs/dropdownInput';
import { FETCH_USER_ACCOUNTS } from './graphql';
import { Account } from './index';
import OvRadioButton from '../common/controllers/ovRadioButton';

interface Props {
  dateRange: DateRange<dayjs.Dayjs> | null | undefined,
  dateFilterSelected: string,
  selectedAccount: string,
  applyFilters: (e: FormEvent<HTMLFormElement>) => void,
  clearFilters: () => void,
  setDateRange: (value: React.SetStateAction<DateRange<dayjs.Dayjs> | null | undefined>) => void,
  setDateFilterSelected: (value: React.SetStateAction<string>) => void,
  setSelectedAccount: (value: React.SetStateAction<string>) => void,
}

const FilterDocuments = ({
  dateRange, dateFilterSelected, selectedAccount, applyFilters, clearFilters, setDateRange, setDateFilterSelected, setSelectedAccount,
}: Props): JSX.Element => {
  const accountStates = [
    'ACTIVE',
    'REQUESTED',
    'FROZEN',
    'FAILED',
    'INITIATED',
    'READY',
  ];

  const { t } = useTranslation(['document']);
  const now = dayjs();

  const [userAccounts, setUserAccounts] = useState<Account[]>([]);

  const [fetchUserAccounts] = useLazyQuery(FETCH_USER_ACCOUNTS, {
    variables: {
      input: {
        filter: {
          states: accountStates,
        },
      },
    },
    onCompleted: (data) => setUserAccounts(data.fetchAccounts.accounts),
    nextFetchPolicy: 'standby',
  });

  const handleDateRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'customDate') setDateRange([now.subtract(1, 'day'), now]);
    if (e.target.value === 'lastYear') setDateRange([now.subtract(1, 'year'), now]);
    if (e.target.value === 'lastThreeMonths') setDateRange([now.subtract(3, 'month'), now]);
    if (e.target.value === 'lastMonth') setDateRange([now.subtract(1, 'month'), now]);
    setDateFilterSelected(e.target.value);
  };

  useEffect(() => {
    fetchUserAccounts().then();
  }, []);

  return (
    <Filter
      components={[
        (
          <RadioGroup
            value={dateFilterSelected}
            onChange={handleDateRangeChange}
          >
            <Typography variant="captionDescriptorMedium" sx={{ color: colors.secondary, fontWeight: 500, mb: 1 }}>{t('document:filter.date')}</Typography>
            <Stack spacing={1.5}>
              <FormControlLabel sx={{ m: 0 }} value="lastYear" label={t('document:filter.radioOption.lastYear', { lastYear: now.toDate().getFullYear() - 1 }).toString()} control={<OvRadioButton sx={{ mr: 1 }} />} />
              <FormControlLabel sx={{ m: 0 }} value="lastThreeMonths" label={t('document:filter.radioOption.lastThreeMonths').toString()} control={<OvRadioButton sx={{ mr: 1 }} />} />
              <FormControlLabel sx={{ m: 0 }} value="lastMonth" label={t('document:filter.radioOption.lastMonth').toString()} control={<OvRadioButton sx={{ mr: 1 }} />} />
              <Box>
                <FormControlLabel sx={{ m: 0, mb: 1 }} value="customDate" label={t('document:filter.radioOption.customDate').toString()} control={<OvRadioButton sx={{ mr: 1 }} />} />
                {dateFilterSelected === 'customDate' && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      renderInput={(startProps, endProps) => (
                        <>
                          <TextField sx={{ maxWidth: '112px' }} {...startProps} />
                          <Box sx={{ mx: 1 }}> to </Box>
                          <TextField sx={{ maxWidth: '112px' }} {...endProps} />
                        </>
                      )}
                      disableFuture
                      value={dateRange!}
                      onChange={(newRange) => {
                        if (newRange.includes(null)) return;
                        setDateRange(newRange);
                      }}
                    />
                  </LocalizationProvider>
                )}
              </Box>
            </Stack>
          </RadioGroup>
        ),
        (
          <>
            <Typography variant="captionDescriptorMedium" sx={{ color: colors.secondary, fontWeight: 500 }}>{t('document:filter.account')}</Typography>
            <DropdownInput
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
              options={userAccounts.map((acc) => ({
                imageUrl: '',
                text: `${acc.type}${acc.custodianAccountNumber ? ` - ${acc.custodianAccountNumber}` : ''}`,
                value: acc.id,
              }))}
            />
          </>
        ),
      ]}
      applyFilters={applyFilters}
      clearFilters={clearFilters}
    />
  );
};

export default FilterDocuments;
