import {
  InputAdornment, IconButton, Input, FormControl, InputLabel,
} from '@mui/material';
import React from 'react';
import CloseIcon from '../../../assets/images/close-icon.svg';
import SearchIcon from '../../../assets/images/search-icon.svg';

interface Props {
  label: string,
  value?: string,
  placeholder?: string,
  onContinue?: () => void,
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
  onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

const SearchInput = ({ ...props }: Props): JSX.Element => {
  const onClear = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (props.onClear) props.onClear(event);
  };

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel htmlFor="standard-adornment-password">{props.label}</InputLabel>
      <Input
        data-testid="search-input"
        placeholder={props.placeholder}
        startAdornment={<img src={SearchIcon} alt="search-icon" />}
        fullWidth
        style={{ width: '100%' }}
        required
        value={props.value}
        onChange={(event) => {
          if (props.onChange) props.onChange(event);
        }}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              data-testid="clear-button"
              aria-label="toggle password visibility"
              disableRipple
              onClick={(event) => onClear(event)}
              edge="end"
            >
              <img src={CloseIcon} alt="close" />
            </IconButton>
          </InputAdornment>
        )}
      />
    </FormControl>
  );
};

SearchInput.defaultProps = {
  placeholder: '',
  value: '',
  onContinue: undefined,
  onChange: undefined,
  onClear: undefined,
};

export default SearchInput;
