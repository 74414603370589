/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import sumBy from 'lodash/sumBy';
import { HoldingResponse, mapHoldingAttributes } from './resources';
import { styles } from './styles';
import { formatMoneyValue } from '../../utils/commonMethods';
import { useContextTheme } from '../../providers/contextThemeProvider';

Chart.register(ArcElement);

export interface HoldingsGraphWidgetProps {
  holdings: HoldingResponse[],
}

const HoldingsGraph = ({ holdings, ...props }: HoldingsGraphWidgetProps): JSX.Element => {
  const { t } = useTranslation(['base', 'user', 'transfer']);
  const { colors } = useContextTheme();
  const mappedHoldings = mapHoldingAttributes(holdings, colors);
  const totalCentsValue = sumBy(mappedHoldings, (h) => h.totalCents ?? 0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipData, setTooltipData] = useState({
    marketValue: 0,
    percentage: 0,
  });
  const setTooltipInfo = (elements: any[]): void => {
    const dataIndex = elements[0].dataIndex || 0;
    const { percentage, totalCents } = mappedHoldings[dataIndex];
    setTooltipData({
      marketValue: totalCents || 0,
      percentage,
    });
  };
  const externalTooltipHandler = (context: any): void => {
    // Tooltip Element
    const { tooltip } = context;
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      setShowTooltip(false);
      return;
    }
    setShowTooltip(true);
    setTooltipInfo(tooltip.dataPoints);
  };
  const data = {
    labels: mappedHoldings.map((item) => item.secondaryAssetClassName),
    datasets: [
      {
        label: 'HoldingsData',
        data: mappedHoldings.length ? mappedHoldings.map((item) => (item.percentage)) : [100],
        backgroundColor: mappedHoldings.length ? mappedHoldings.map((item) => (item.hexColor)) : [colors.emptyPieChartColor],
        hoverOffset: 4,
      },
    ],
  };

  const NEAREST = 'nearest';
  const options = {
    cutout: 75,
    tooltips: {
      enabled: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest' as typeof NEAREST,
        external: mappedHoldings.length ? externalTooltipHandler : undefined,
      },
    },
    legend: {
      display: false,
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    responsive: true,
  };
  const createPortfolioDecs = (): string => {
    const numberOfHoldings = mappedHoldings.length;
    return showTooltip ? `${tooltipData.percentage}% of portfolio` : `${numberOfHoldings} asset classes`;
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <Doughnut options={options} data={data} id="chart" style={styles.chart} />
      <Box sx={styles.centerBox} data-testid="holdings-data-section">
        <Typography variant="paragraph3" sx={styles.chartTitle}>Portfolio value</Typography>
        <Typography variant="heading3" sx={styles.chartValue}>{showTooltip ? formatMoneyValue(tooltipData.marketValue) : formatMoneyValue(totalCentsValue)}</Typography>
        <Typography variant="paragraph3" sx={styles.chartPercentage}>{createPortfolioDecs()}</Typography>
      </Box>
    </Box>
  );
};

export default HoldingsGraph;
