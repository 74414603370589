/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentNode, useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HistoryChart from './historyChart';
import { colors } from '../../theme/colors';
import { formatMoneyValue } from '../../utils/commonMethods';
import {
  FETCH_ACCOUNT_PERFORMANCE,
  FETCH_GOAL_PERFORMANCE,
  FETCH_SUB_ACCOUNT_PERFORMANCE,
  FETCH_USER_PERFORMANCE,
  Aggregation,
  DailyStatsTypes,
} from './graphql';
import { portfolioPerformanceStyles } from './styles';

interface PortfolioPerformanceProps {
  title?: string,
  marketValueCents: number,
  aggregation: Aggregation,
  onClick: () => void,
}

const PortfolioPerformance = ({
  title,
  aggregation,
  marketValueCents,
  onClick,
}: PortfolioPerformanceProps): JSX.Element => {
  const { t } = useTranslation(['base', 'statistics']);

  const formatWeigttedValues = (weight: number): string => (`${(weight * 100).toFixed(2)} %`);

  const [weightValues, setWeightValues] = useState({
    moneyWeight: 0,
    timeWeight: 0,
  });

  const getQuery = (): { documentNode: DocumentNode, variables: any } => {
    let documentNode = FETCH_USER_PERFORMANCE;
    const variables: any = {};
    if (aggregation.type === DailyStatsTypes.GOALS_DAILY) {
      documentNode = FETCH_GOAL_PERFORMANCE;
      variables.goalId = aggregation.typeId;
    }
    if (aggregation.type === DailyStatsTypes.ACCOUNTS_DAILY) {
      documentNode = FETCH_ACCOUNT_PERFORMANCE;
      variables.accountId = aggregation.typeId;
    }
    if (aggregation.type === DailyStatsTypes.SUB_ACCOUNTS_DAILY) {
      documentNode = FETCH_SUB_ACCOUNT_PERFORMANCE;
      variables.subAccountId = aggregation.typeId;
    }
    return { documentNode, variables };
  };
  const [query] = useState(getQuery());

  const setPerformanceData = (data: any): void => {
    console.log({ event: 'PERFORMANCE', performance: data });
    if (aggregation.type === DailyStatsTypes.GOALS_DAILY) {
      setWeightValues({
        moneyWeight: data.fetchGoal.goal.statistics.moneyWeightedReturn,
        timeWeight: data.fetchGoal.goal.statistics.timeWeightedReturn,
      });
    }
    if (aggregation.type === DailyStatsTypes.USERS_DAILY) {
      setWeightValues({
        moneyWeight: data.me.user.statistics.moneyWeightedReturn,
        timeWeight: data.me.user.statistics.timeWeightedReturn,
      });
    }
    if (aggregation.type === DailyStatsTypes.ACCOUNTS_DAILY) {
      setWeightValues({
        moneyWeight: data.fetchAccount.account.statistics.moneyWeightedReturn,
        timeWeight: data.fetchAccount.account.statistics.timeWeightedReturn,
      });
    }
    if (aggregation.type === DailyStatsTypes.SUB_ACCOUNTS_DAILY) {
      setWeightValues({
        moneyWeight: data.fetchSubAccount.subAccount.statistics.moneyWeightedReturn,
        timeWeight: data.fetchSubAccount.subAccount.statistics.timeWeightedReturn,
      });
    }
  };
  const [fetchPerformace] = useLazyQuery(query.documentNode, {
    variables: query.variables,
    onCompleted: setPerformanceData,
    nextFetchPolicy: 'standby',
  });
  useEffect(() => {
    fetchPerformace({
      variables: { ...query.variables, startDate: null },
    });
  }, [fetchPerformace, query]);
  const changeUnit = (frame: string): void => {
    let startDate = null;
    if (frame === '1W') {
      startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
    }
    if (frame === '1M') {
      startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
    }
    if (frame === '3M') {
      startDate = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
    }
    if (frame === '1Y') {
      startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    }

    fetchPerformace({
      variables: { ...query.variables, startDate },
    }).then();
  };
  return (
    <Box>
      <HistoryChart
        changeUnit={changeUnit}
        aggregation={aggregation}
      >
        <Box sx={portfolioPerformanceStyles.cageWrapper}>
          <Box sx={portfolioPerformanceStyles.infoCage}>
            {title ? <Typography variant="heading5" style={{ marginBottom: '0px', width: '100%', textAlign: 'center' }}>{title}</Typography> : null}
            <Typography variant="headingMain" style={{ marginTop: '0px !important', width: '100%', textAlign: 'center' }}>{formatMoneyValue(marketValueCents)}</Typography>
          </Box>
        </Box>
      </HistoryChart>
      <Box sx={portfolioPerformanceStyles.descriptionBox}>
        <Box sx={portfolioPerformanceStyles.moneyWeightCage}>
          <Typography variant="bodySub1" style={{ color: colors.secondary, marginBottom: '8px' }}>{formatWeigttedValues(weightValues.moneyWeight)}</Typography>
          <Typography variant="bodySub1" style={{ color: colors.secondary, marginBottom: '8px' }}>{t('statistics:rateOfReturnCard.moneyWeightedData.title')}</Typography>
          <Typography variant="captionSectionerMedium" style={{ color: colors.neutralBlack, marginBottom: '0px' }}>{t('statistics:rateOfReturnCard.moneyWeightedData.body')}</Typography>
        </Box>
        <Box sx={{ display: 'grid' }}>
          <Typography variant="bodySub1" style={{ color: colors.secondary, marginBottom: '8px' }}>{formatWeigttedValues(weightValues.timeWeight)}</Typography>
          <Typography variant="bodySub1" style={{ color: colors.secondary, marginBottom: '8px' }}>{t('statistics:rateOfReturnCard.timeWeightedData.title')}</Typography>
          <Typography variant="captionSectionerMedium" style={{ color: colors.neutralBlack }}>{t('statistics:rateOfReturnCard.timeWeightedData.body')}</Typography>
        </Box>
      </Box>
      <Box sx={portfolioPerformanceStyles.buttonBox}>
        <Button variant="secondary-large" onClick={onClick}>{t('base:button.dismiss')}</Button>
      </Box>
    </Box>
  );
};

PortfolioPerformance.defaultProps = {
  title: '',
};

export default PortfolioPerformance;
