import { Colors } from '../../providers/contextThemeProvider';

export const styles = (colors: Colors) => ({
  listItemWrapper: {
    background: colors.baseComponentBackground,
    border: `1px solid ${colors.portfolioCardBorder}`,
    boxShadow: '0px 4px 10px rgba(234, 236, 239, 0.25)',
    borderRadius: '8px',
    padding: '8px',
  },
  historyTitle: {
    margingBottom: '12px',
    color: colors.neutral20,
    textAlign: 'left',
    padding: '10px',
  },
  historyBorder: {
    borderBottom: '1px solid #ECF3FA',
    paddingLeft: '10px',
    paddingTop: '16px',
  },
  noPadding: {
    padding: '0px 0px 0px 0px',
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 !important',
  },
  allTransferContainer: {
    typography: 'body1',
    backgroundColor: 'transparent',
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column' as const,
    flexGrow: 1,
  },
  depositInnerContainer: {
    typography: 'body1',
    backgroundColor: colors.ovWhite,
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column' as const,
    flexGrow: 1,
  },
  closeBox: {
    width: '26px',
    height: '26px',
    top: '-6px',
    marginTop: '18px',
    marginRight: '24px',
    marginBottom: '22px',
    cursor: 'pointer',
    background: colors.backButtonBackground,
    borderRadius: '3.42105px',
  },
  underline: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: colors.link,
  },
});
