import { FormEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { UPDATE_USER } from './graphql';
import { PhysicalAddress } from './address/resources';
import CloseIconSVG from '../../assets/images/close-icon.svg';
import OvForm from '../common/wrappers/ovForm';
import { getLocale } from '../../assets/i18n/config';
import { BASIC_ADDRESS_INFO } from '../profile/graphql';

export interface UserState {
  dateOfBirth?: Date,
  inProvinceSince?: Date,
  physicalAddress?: Partial<PhysicalAddress>,
  incompleteFields?: string[],
}
dayjs.extend(utc);
interface Props {
  user: UserState,
  title?: string,
  subtitle?: string,
  shouldSaveUpdate?: boolean,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  analytictsEvent?: string,
}

const InProvinceSince = ({
  user, updateUserState, shouldSaveUpdate = true, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(user.inProvinceSince || null);
  const [updateUser] = useMutation(UPDATE_USER);

  const formatDate = (dateValue: Date): string => {
    if (dateValue && dateValue !== undefined) {
      try {
        return new Date(dateValue).toISOString().split('T')[0];
      } catch (error) {
        //  eliminate invalid dates
        return '';
      }
    }
    return '';
  };
  const updateInProvinceSince = async (inProvinceSince: Date): Promise<void> => {
    updateUser({
      variables: {
        input: {
          inProvinceSince: formatDate(inProvinceSince),
        },
      },
      onCompleted: () => {
        setLoading(false);
        if (props.onContinue) props.onContinue();
      },
      refetchQueries: [BASIC_ADDRESS_INFO],
      onError: () => setLoading(false),
    }).then();
  };

  const onContinueClick = (event: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement> | undefined, sinceBirth: boolean): void => {
    event?.preventDefault();
    if (sinceBirth && user.dateOfBirth) {
      setValue(user.dateOfBirth);
      updateUserState({ ...user, inProvinceSince: user.dateOfBirth });
    }
    if (shouldSaveUpdate) {
      setLoading(true);
      const inProvinceSince = sinceBirth ? user.dateOfBirth : value;
      if (inProvinceSince) updateInProvinceSince(inProvinceSince);
    } else if (props.onContinue) props.onContinue();
  };

  return (
    <OvForm
      loading={loading}
      suffixButton={{
        text: t('user:inProvinceSince.sinceBirth'), onClick: (event) => { onContinueClick(event, true); }, variant: 'secondary-large', dataTestId: 'secondary-btn',
      }}
      onSubmit={(event) => onContinueClick(event, false)}
    >
      <Typography variant="heading2">{props.title ?? t('user:inProvinceSince.title')}</Typography>
      <Typography variant="paragraph3">{props.subtitle ?? t('user:inProvinceSince.subtitle')}</Typography>
      <LocalizationProvider locale={getLocale()} dateAdapter={AdapterDateFns}>
        <DatePicker
          label="yyyy-mm-dd"
          value={value ? dayjs(value).utc().toDate() : null}
          onChange={(newValue) => {
            setValue(newValue);
            if (newValue) {
              updateUserState({ ...user, inProvinceSince: newValue });
            }
          }}
          renderInput={(params) => (
            <TextField
              data-testid="dateofbirth-input"
              sx={{ marginBottom: '32px' }}
              variant="standard"
              {...params}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="clear input field"
                      disableRipple
                      onClick={() => {
                        setValue(null);
                      }}
                      edge="end"
                    >
                      <img src={CloseIconSVG} alt="close" />
                    </IconButton>
                  </InputAdornment>),
              }}
            />
          )}
          inputFormat="yyyy-MM-dd"
          mask="____-__-__"
        />
      </LocalizationProvider>
    </OvForm>
  );
};

InProvinceSince.defaultProps = {
  title: undefined,
  subtitle: undefined,
  shouldSaveUpdate: true,
  onContinue: undefined,
  analytictsEvent: undefined,
};

export default InProvinceSince;
