import { gql } from '@apollo/client';

export const PREVIEW_FORM_AGREEMENT = gql`
  query previewFormAgreement($input: PreviewFormAgreementInput!) {
    previewFormAgreement(input: $input) {
      html
    }
  }
`;

export const SIGN_FORM_AGREMETNS = gql`
  mutation signFormAgreements($input: SignFormAgreementsInput!) {
    signFormAgreements(input: $input) {
      formAgreements {
        id
        type
        version
        signedAt
      }
    }
  }
`;

export const FETCH_ALL_INCOMPLETE_FORM_AGREEMENT = gql`
  query fetchFormAgreements {
    me { 
      user {
        allIncompleteFormAgreements {
          type
          minVersion
          changeType
          digitalSignatureEnabled
          user {
            id
          }
          goal {
            id
            name
          }
          scheduledTransfer {
            id
            type
            subAccount {
              account {
                type
              }
            }
          }
          account {
            id
            type
            state
          }
          subAccount {
            id
            state
            account { 
              id 
              type
            }
          }
          translatedDisplayName {
            en
            fr
          }
          templateUrl
        }
      }
    }
  }
`;

export const FETCH_SUB_ACCOUNT_INCOMPLETE_FORMS = gql`
  query fetchSubAccountIncompleteForms($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        state
        account {
          id
          type
          state
        }
        allIncompleteFormAgreements {
          type
          minVersion
          changeType
          digitalSignatureEnabled
          user {
            id
          }
          account {
            id
          }
          goal {
            id
            name
          }
          subAccount {
            id
          }
          scheduledTransfer {
            id
            type
          }
          translatedDisplayName {
            en
            fr
          }
          templateUrl
        }
      }
    }
  }
`;

export const FETCH_ALL_LATEST_FORM_AGREEMENT = gql`
  query fetchFormAgreements {
    me { 
      user {
        allLatestFormAgreements{
          id
          type
          translatedDisplayName {
            en
            fr
          }
          signedAt
          user{
            id
          }
          account{
            id
            type
            custodianAccountNumber
          }
          subAccount{
            id
            goal {
              name
            }
            account {
              id
              type
              custodianAccountNumber
            }
          }
          goal{
            id
            name
          }
          signedUrl
          scheduledTransfer {
            state
            subAccount {
              account {
                id
                type
                custodianAccountNumber
              }
            }
          }
        }
      }
    }
  }
`;

export const RENDER_FORM_AGREEMENT = gql`
  query renderFormAgreement($input: RenderFormAgreementInput!) {
    renderFormAgreement(input: $input) {
      html
    }
  }
`;

export const FETCH_ACCOUNT_STATEMENTS = gql`
  query fetchAccountStatements($input: FetchAccountStatementsInput!) {
    fetchAccountStatements(input: $input) {
      accountStatements {
        id
        type
        fileName
        translatedFileName {
          en
          fr
        }
        signedUrl
        state
        referenceDate
      }
      totalCount
    }
  }
`;

export const FETCH_ACCOUNT_STATEMENT = gql`
  query fetchAccountStatement($accountStatementId: ObjectID!) {
    fetchAccountStatement(accountStatementId: $accountStatementId) {
      accountStatement {
        id
        type
        fileName
        translatedFileName {
          en
          fr
        }
        signedUrl
        state
        referenceDate
      }
    }
  }
`;

export const FETCH_FILE_DOCUMENTS = gql`
  query fetchFileDocuments($input: FetchFileDocumentsInput!) {
    fetchFileDocuments(input: $input) {
      fileDocuments {
        id
        type
        name
        fileName
        uploadedAt
        downloadUrl
        mediaType
      }
    }
  }
`;

export const FETCH_FILE_DOCUMENT = gql`
  query fetchFileDocument($fileDocumentId: ObjectID!) {
    fetchFileDocument(fileDocumentId: $fileDocumentId) {
      fileDocument {
        id
        type
        name
        fileName
        uploadedAt
        downloadUrl
        mediaType
      }
    }
  }
`;

export const FETCH_USER_ACCOUNTS = gql`
  query fetchAccounts($input: FetchAccountsInput!) {
    fetchAccounts(input: $input) {
      accounts {
        id
        custodianAccountNumber
        type
      }
    }
  }
`;

export const USER_LATEST_FORM_AGREEMENTS = gql`
  query BasicMe {
    me {
      user {
        latestFormAgreements {
          id
        }
      }
    }
  }
`;
