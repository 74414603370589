/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */

import StepWizard from 'react-step-wizard';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import { gql, useLazyQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { UserState } from '../idVerificationChecker/idVerificationCheckerWizard';
import WithStepWizard from '../common/wrappers/withStepWizard';
import SuitabilityReview from '../suitability-review';
import IdVerificationChecker from '../idVerificationChecker';
import FeedbackModal from '../common/wrappers/modals/ovFeedbackModal';
import { getFromLocalStorage, setToLocalStorage } from '../../utils/localStorage';
import { UserContext } from '../../providers/userContextProvider';
import { ME_FOR_PERSONA_VERIFICATION } from '../idVerificationChecker/graphql';
import CloseButton from '../common/buttons/closeButton';
import { AccountTypes, FeatureFlagTypes, isFeatureEnabled } from '../account/resources';
import RRIFOnboardingWizard from '../rrif-post-onboarding/rrifPostOnboardingWizard';
import { accountsWhichRequirePaymentInstructions, allowedAccountStates } from '../../utils/accountUtil';

const SuitabilityReviewStep = WithStepWizard(SuitabilityReview);
const IdVerificationCheckerStep = WithStepWizard(IdVerificationChecker);
const RRIFOnboardingWizardStep = WithStepWizard(RRIFOnboardingWizard);
export const stepsMap = {
  1: 'idVerification',
  2: 'suitabilityReview',
  3: 'rrifOnboardingStep',
};

export const decideNextStep = (
  stepToRemove: number,
  journey: number[],
  updateJourney: (data: number[]) => void,
  goToNamedStep?: (step: string) => void,
): void => {
  if (journey.length > 1 && goToNamedStep) {
    // gets the 2nd value (that is the next step) in the array
    const nextJourney = journey[1];
    goToNamedStep(stepsMap[nextJourney as 1 | 2 | 3]);
  }
  // deletes the 1st value (that is the present step) in the array
  if (journey.length > 0) {
    updateJourney([...journey.filter((item) => item !== stepToRemove)]);
  }
};

export const FETCH_ALL_ACCOUNTS = gql`
  query BasicMe {
    me {
      user {
        accounts {
          id
          type
          custodianAccountNumber
          state
          subAccounts {
            id
            goal {
              id
              name
            }
          }
          statistics {
            id
            netContributionCents
            pendingContributionCents
          }
          scheduledIncomeFundTransfer {
            id
          }
        }
      }
    }
  }
`;

export const FETCH_RRIF_ACCOUNT_SCHEDULED_INCOME_TRANSFER_FUND = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        scheduledIncomeFundTransfer {
          id
        }
        statistics {
          id
          netContributionCents
          pendingContributionCents
        }
      }
    }
  }
`;

export interface RetirementAccountType {
  id: string,
  type: AccountTypes,
  custodianAccountNumber: string,
  subAccounts: {
    id: string,
    goal: {
      id: string,
      name: string,
    }
  }[],
  statistics: {
    netContributionCents: number,
    pendingContributionCents: number,
  },
  scheduledIncomeFundTransfer: {
    id: string,
  }
}
const Checker = (): JSX.Element => {
  const isMounted = useRef(true);
  const [suitabilityReviewInitStep, setSuitabilityReviewInitStep] = useState(1);
  const [user, setUser] = useState<UserState>({});
  const [journey, setJourney] = useState<number[]>([]);
  const { userContext, setUserContext } = useContext(UserContext);
  const [uncompletedRrifAccounts, setUncompletedRrifAccounts] = useState<RetirementAccountType[]>([]);
  const [fetchMeForPersona] = useLazyQuery(ME_FOR_PERSONA_VERIFICATION, {
    variables: {
      input: {},
    },
    nextFetchPolicy: 'standby',
  });
  const [fetchRrifAccountScheduledIncomeTransfer] = useLazyQuery(FETCH_RRIF_ACCOUNT_SCHEDULED_INCOME_TRANSFER_FUND, {
    variables: {
      accountId: '',
    },
  });
  const [fetchAllAccounts] = useLazyQuery(FETCH_ALL_ACCOUNTS, {
    variables: {},
  });
  const changeTheIsOnboardingFlag = (): void => {
    if (journey && journey.length > 0 && journey[0] === 3) {
      setToLocalStorage('from-onboarding', 'false');
    }
  };
  const checkRrifOnboardingStep = useCallback(async (fromOnboarding: boolean, checkRrifInformationStep?: {
    accountId: string|undefined,
    open: boolean,
  }): Promise<void> => {
    const response = await fetchAllAccounts();
    // Set's RRIFOnboardingWizardStep in the flow
    let foundRrifAccounts;
    if (checkRrifInformationStep?.open) {
      foundRrifAccounts = response.data.me.user.accounts?.filter((item: { id: string, state: string }) => (item.id === checkRrifInformationStep?.accountId && allowedAccountStates.includes(item.state)));
    } else {
      foundRrifAccounts = response.data.me.user.accounts?.filter((item: { statistics: { netContributionCents: number; pendingContributionCents: number; }; scheduledIncomeFundTransfer: { id: string }; state: string, type: string }) => ((item.statistics.netContributionCents === 0 && item.statistics.pendingContributionCents === 0) || !item.scheduledIncomeFundTransfer) && allowedAccountStates.includes(item.state) && accountsWhichRequirePaymentInstructions.includes(item.type as AccountTypes));
    }
    if (foundRrifAccounts && foundRrifAccounts.length > 0) {
      // sets this here so the useState has ample time to update before it's getter is used.
      setUncompletedRrifAccounts(foundRrifAccounts);
      setJourney((prevState) => [...prevState, 3]);
    }
  }, [fetchRrifAccountScheduledIncomeTransfer]);
  const checkSuitabilityReview = useCallback((fromOnboarding: boolean): void => {
    const lastSuitabilityReviewAt = dayjs(userContext.lastSuitabilityReviewAt);
    const presentDate = dayjs();
    const differentMonth = presentDate.diff(lastSuitabilityReviewAt, 'month');
    const isSuitabilityReviewDone = getFromLocalStorage('suitabilityReview');
    if (isFeatureEnabled(FeatureFlagTypes.ANNUAL_INFORMATION_UPDATE) && !fromOnboarding) {
      if ((differentMonth === 11 && isSuitabilityReviewDone === null) || !userContext.lastSuitabilityReviewAt) {
        setSuitabilityReviewInitStep(1);
        setJourney((prevState) => [...prevState, 2]);
      }
      if ((differentMonth >= 12 && isSuitabilityReviewDone === null) || !userContext.lastSuitabilityReviewAt) {
        setSuitabilityReviewInitStep(2);
        setJourney((prevState) => [...prevState, 2]);
      }
    }
    checkRrifOnboardingStep(fromOnboarding).then();
  }, [userContext.lastSuitabilityReviewAt, checkRrifOnboardingStep]);
  useEffect(() => {
    const fromOnboarding = getFromLocalStorage('from-onboarding') === 'true';
    // First calls the ID,then Suitability and lastly the Rrif Onboarding Checker
    // Set's IdVerificationCheckerStep in the flow
    if (isFeatureEnabled(FeatureFlagTypes.ID_VERIFICATION) && !fromOnboarding) {
      fetchMeForPersona().then((response) => {
        if (response.data.me.user.iDCheckRetrigger) {
          setJourney((prevState) => [...prevState, 1]);
          setUser(response.data.me.user);
        }
        // RRIF checker inside the suitability checker because of async issues
        checkSuitabilityReview(fromOnboarding);
      });
    }
    if (fromOnboarding) {
      checkRrifOnboardingStep(fromOnboarding).then();
    }
  }, [
    isMounted,
    userContext.lastSuitabilityReviewAt,
    fetchMeForPersona,
    fetchRrifAccountScheduledIncomeTransfer,
    checkRrifOnboardingStep,
    checkSuitabilityReview,
  ]);
  useEffect(() => {
    if (userContext.checkRrifInformationStep?.open) {
      const fromOnboarding = getFromLocalStorage('from-onboarding') === 'true';
      checkRrifOnboardingStep(fromOnboarding, userContext.checkRrifInformationStep).then();
    }
  }, [userContext.checkRrifInformationStep?.open]);
  const steps: JSX.Element[] = [
    <IdVerificationCheckerStep key="idVerification" stepName="idVerification" user={user} journey={journey} updateJourney={setJourney} />,
    <SuitabilityReviewStep key="suitabilityReview" stepName="suitabilityReview" initStep={suitabilityReviewInitStep} journey={journey} updateJourney={setJourney} />,
    <RRIFOnboardingWizardStep uncompletedRrifAccounts={uncompletedRrifAccounts} key="rrifOnboardingStep" stepName="rrifOnboardingStep" journey={journey} updateJourney={setJourney} />,
  ];
  return (
    <FeedbackModal
      open={journey.length > 0}
      component={(
        <StepWizard
          isLazyMount
          transitions={{}}
          initialStep={journey[0]}
          className="ov-step-wizard"
          nav={
            (
              <Box position="relative" height="30px !important" mb={1.5}>
                <CloseButton onClick={() => {
                  setUserContext((prevState) => ({
                    ...prevState,
                    checkRrifInformationStep: {
                      accountId: '',
                      open: false,
                    },
                  }));
                  setToLocalStorage('suitabilityReview', 'true');
                  changeTheIsOnboardingFlag();
                  setJourney([]);
                }} />
              </Box>
            )
          }
        >
          {steps}
        </StepWizard>
      )}
    />
  );
};

export default Checker;
