/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { gql, useMutation } from '@apollo/client';
import LoadingBox from '../components/common/loaders/loadingBox';
import auth0 from '../utils/auth0Client';
import { setAuthToken } from '../utils/authToken';
import { getRedirectUri, parseJwt } from '../utils/platformUtils';
import { UserContext } from './userContextProvider';
import { getBackendLanguage } from '../assets/i18n/config';
import { delay } from '../utils/commonMethods';

const CUSTOM_CLAIM_NAMESPACE = 'https://onevest.com';
const DELAY_MS = 2000;

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`;

const Auth0Provider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { userContext } = useContext(UserContext);
  const queryParams = new URLSearchParams(window.location.search);
  const invitiation = queryParams.get('invitation');
  const organizationParam = queryParams.get('organization');
  const state = queryParams.get('state');
  const { organizationKey, organizationId, useClientOrgAuth0 } = userContext;
  const redirectUri = getRedirectUri();

  if (invitiation && organizationKey && useClientOrgAuth0) {
    // eslint-disable-next-line max-len
    window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize?invitation=${invitiation}&organization=${organizationParam}&response_type=token&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}&redirect_uri=${redirectUri}`;
  }

  const [createUserMutation] = useMutation(CREATE_USER);

  useEffect(() => {
    console.log({
      event: 'AUTH', organizationKey, organizationId, useClientOrgAuth0,
    });
    if (!useClientOrgAuth0 || !organizationKey || !organizationId) {
      setLoading(false);
      return;
    }
    if (invitiation) return;

    if (state) {
      auth0.handleRedirectCallback().then(() => {
        auth0.getTokenSilently().then(async (newToken: string) => {
          const payload = parseJwt(newToken);
          const userId = payload[`${CUSTOM_CLAIM_NAMESPACE}/user`];
          if (!userId) {
            const auth0User = await auth0.getUser();
            if (auth0User && auth0User.email) {
              const primaryEmail = auth0User.email;
              console.log({ event: 'USER_SIGNUP', primaryEmail });
              const resp = await createUserMutation({
                variables: {
                  input: {
                    primaryEmail,
                    language: getBackendLanguage() === 'fr' ? 'FRENCH' : 'ENGLISH',
                    timezone: 'America/Edmonton',
                    organizationId,
                  },
                },
              });
              if (resp.data?.createUser?.user?.id) {
                await delay(DELAY_MS);
                auth0.loginWithRedirect({
                  authorizationParams: {
                    redirect_uri: redirectUri,
                    organization: organizationKey,
                  },
                });
              }
            }
          } else {
            console.log({ event: 'AUTHENTICATED_USER', userId });
            setAuthToken({ accessToken: newToken });
            setLoading(false);
          }
        }).catch(async (error) => {
          console.log({ error });
          auth0.loginWithRedirect({
            authorizationParams: {
              redirect_uri: redirectUri,
              organization: organizationKey,
            },
          });
        });
      });
    } else {
      auth0.getTokenSilently().then((newToken: string) => {
        setAuthToken({ accessToken: newToken });
        setLoading(false);
      }).catch(async () => {
        auth0.loginWithRedirect({
          authorizationParams: {
            redirect_uri: redirectUri,
            organization: organizationKey,
          },
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>{loading ? <LoadingBox /> : children}</>
  );
};

export default Auth0Provider;
