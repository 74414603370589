import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonProps } from '@mui/material/Button/Button';
import { Colors, useContextTheme } from '../../../providers/contextThemeProvider';

const styles = (colors: Colors) => ({
  closeBox: {
    textAlign: 'center',
    width: '26px',
    height: '26px !important',
    top: '-6px',
    marginBottom: '22px',
    cursor: 'pointer',
    background: colors.backButtonBackground,
    borderRadius: '3.42105px',
  },
});
interface CloseButtonProps {
  onClick: () => void,
}

const CloseButton = ({ onClick }: ButtonProps & CloseButtonProps): JSX.Element => {
  const { colors } = useContextTheme();
  return (
    <Box sx={styles(colors).closeBox}>
      <CloseIcon onClick={onClick} style={{ padding: '1px', color: colors.exitButtonIconColour }} />
    </Box>
  );
};

export default CloseButton;
