import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { MouseEvent, useState } from 'react';
import { colors } from '../../../theme/colors';
import { PREVIEW_FORM_AGREEMENT } from '../../documents/graphql';
import DialogPopUp from '../../documents/dialog';

const PADLink = (): JSX.Element => {
  const [rawHtml, setRawHtml] = useState('');
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(['base']);

  const client = useApolloClient();

  const previewRawHtml = async (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLSpanElement>): Promise<void> => {
    e.preventDefault();
    const result = await client.query({
      query: PREVIEW_FORM_AGREEMENT,
      variables: {
        input: {
          type: 'PAD_AGREEMENT',
          raw: true,
        },
      },
    });

    if (result.data) {
      console.log(result.data.previewFormAgreement.html);
      setRawHtml(result.data.previewFormAgreement.html.replaceAll(/\{\{[^}]*\}\}/g, ''));
      setOpen(true);
    }
  };

  const handleClose = (): void => {
    setRawHtml('');
    setOpen(false);
  };

  if (open) {
    return (
      <DialogPopUp
        content={rawHtml}
        handleClose={handleClose}
        open={open}
      />
    );
  }

  return (
    <Typography variant="captionDescriptorMedium" className="button-disclaimer-responsive">
      {t('base:padLink.text')}
      {/* eslint-disable-next-line */}
      <Link style={{ color: colors.link }} component="button" onClick={(e) => previewRawHtml(e)} underline="always">{t('base:padLink.link')}</Link>
    </Typography>
  );
};

export default PADLink;
