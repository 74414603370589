/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable  no-extra-boolean-cast */
/* eslint-disable  react-hooks/exhaustive-deps */
/* eslint-disable   @typescript-eslint/no-explicit-any */
/* eslint-disable  react-hooks/exhaustive-deps */

import {
  Box,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import React, {
  createRef,
  useEffect,
  useState,
} from 'react';
import times from 'lodash/times';
import useMediaQuery from '@mui/material/useMediaQuery';
import { colors } from '../../../theme/colors';

interface Props {
  label: string,
  value?: string,
  length?: number,
  onContinue?: () => void,
  error?: boolean,
  onChange: (value: string) => void,
  onCompleted?: (value: string) => void,
  onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  style?: React.CSSProperties,
}

const PinInput = ({ ...props }: Props): JSX.Element => {
  const [value, setValue] = useState<Array<string>>(Array.from({ length: props.length! }, () => ''));
  const [pinState] = useState(true);
  const refs = Array.from({ length: props.length! }, () => createRef<HTMLInputElement>());
  const matches = useMediaQuery('(max-width:600px)');

  const getTokenAsString = (): void => {
    const token = value.reduce((a, b) => a + b);
    props.onChange(token);
    if (token.length === props.length) if (props.onCompleted) props.onCompleted(token);
  };

  const handleKeyboardAutoSuggest = (event: any, elementIndex: number): void => {
    if (event.target.value.length === 1 && !['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(event.target.value)) {
      return;
    }
    if (event.target.value.length === 1) {
      value[elementIndex] = event.target.value;
      setValue([...value]);
      refs[elementIndex + 1]?.current?.focus();
      getTokenAsString();
    } else {
      const text = event.target.value;
      const maxLength = text.length <= props.length! ? text.length : props.length;
      if (!/^[0-9]+$/.test(text)) return;
      for (let index = 0; index < maxLength; index += 1) {
        value[index] = text[index];
        setValue([...value]);
      }
      refs[text.length >= props.length! ? props.length! - 1 : text.length]?.current?.focus();
      getTokenAsString();
    }
  };

  const getIndexToDelete = (index: number): number => {
    let foundIndex = 0;
    let startIndex = index - 1;
    while (startIndex >= 0) {
      if (!!value[startIndex]) {
        foundIndex = startIndex;
        break;
      }
      startIndex -= 1;
    }
    return foundIndex;
  };

  const handleDelete = (event: React.KeyboardEvent<HTMLDivElement>, index: number): void => {
    if (index === 0 && !value[index]) return;
    event.preventDefault();
    if (!value[index]) {
      const indexToDelete = getIndexToDelete(index);
      value[indexToDelete] = '';
      refs[indexToDelete]?.current?.focus();
    } else {
      value[index] = '';
      refs[index]?.current?.focus();
    }
    setValue([...value]);
  };

  const getClassName = (hasError: boolean | undefined, inputValue: string): string => {
    if (hasError) {
      return 'pinError';
    }
    if (!inputValue) {
      return 'pinEmpty';
    }
    return 'pinStandard';
  };

  useEffect(() => {
    refs[0].current?.focus();
  }, [pinState]);

  return (
    <Stack style={props.style}>
      <InputLabel className="blackInputLabel" required>{props.label}</InputLabel>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        {times(props.length!, (i) => (
          <TextField
            data-testid={`token-input-${i}`}
            key={i.toString()}
            className={getClassName(props.error, value[i])}
            placeholder=""
            sx={{
              borderRadius: '4px',
              marginRight: matches && i !== 5 ? '3px' : '0px',
            }}
            inputRef={refs[i]}
            inputProps={{
              inputMode: 'numeric',
              size: 3,
              autoComplete: 'off',
              style: {
                outline: 'none',
                width: '30.5px',
                color: `${props.error ? colors.error : colors.ovBlack}`,
                textAlign: 'center',
                backgroundColor: `${props.error ? colors.error80 : colors.pinInputBackgroundColor}`,
                caretColor: colors.transparent,
                borderRadius: '4px',
              },
            }}
            required
            value={value[i]}
            onClick={() => {
              refs[i]?.current?.focus();
            }}
            onChange={(e) => { handleKeyboardAutoSuggest(e, i); }}
            onKeyDown={(event) => {
              if (event.key === 'Backspace') {
                handleDelete(event, i);
              }
            }}
          />
        ))}
      </Box>
    </Stack>
  );
};

PinInput.defaultProps = {
  value: '',
  error: false,
  length: 6,
  onContinue: undefined,
  onCompleted: undefined,
  onClear: undefined,
  style: undefined,
};

export default PinInput;
