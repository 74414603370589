import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import {
  Container,
  useMediaQuery,
} from '@mui/material';
import {
  useEffect, useRef, useState, useContext,
} from 'react';
import { uniq, isNil } from 'lodash';
import { useIdleTimer } from 'react-idle-timer';
import { waitForFontLoad } from '../utils/platformUtils';
import {
  BASIC_ME, User, SubAccount, Account, ClientGroup, ClientGroupMemberAccessTypes,
} from '../utils/commonGraphql';
import {
  GET_USER_INITIAL_STATUS,
  isOnboardingCompleteVar,
  isSignUpCompleteVar,
  isLoggedInVar,
  userIdVar,
  userEmailVar,
  organizationIdVar,
  userGenderVar,
  allAccountTypesVar,
  isEmbedded,
  availableFeatureFlagsVar,
  orgSupportUrlVar,
  canViewHouseholdVar,
  householdMembersIdVar,
  isIdVerifiedVar,
  orgHelpCentreUrlVar,
} from '../utils/localVariables';
import { UserContext } from '../providers/userContextProvider';
import LoadingBox from '../components/common/loaders/loadingBox';
import AppBarHeader from '../components/common/headers/appBarHeader';
import { signUserOut } from '../utils/authToken';
import { setSentryContext } from '../utils/sentry';
import { AllIncompleteFormAgreement, filterAgreements } from '../components/documents/incompleteAgreements';
import { loadCountryFlags } from '../utils/imageFactory';
import InactiveLayoutModal from '../components/common/modals/inactiveLayoutModal';
import SessionTimeoutLayoutModal from '../components/common/modals/sessionTimeoutLayoutModal';
import { getFromLocalStorage } from '../utils/localStorage';
import { FETCH_ACCOUNT_TYPES } from './accountsDetails/graphql';
import {
  AccountTypeAttributes, AccountType, isFeatureEnabled, FeatureFlagTypes,
} from '../components/account/resources';
import { translateBackend } from '../assets/i18n/config';
import Checker from '../components/checker';
import { setAnalyticsUserId } from '../utils/firebase';
import HomePage from './home';
import SignInPage from './signIn';
import OnboardingPage from './onboarding';

const goalEnabled = isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS);
const subAccountEnabled = isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_SUB_ACCOUNTS);
const goalAndSubAccountEnabled = goalEnabled && subAccountEnabled;

export const isOnboardingComplete = (user:
  {
    incompleteFields: string[],
    allIncompleteFormAgreements?: AllIncompleteFormAgreement[],
    accounts: Account[],
    goals: { id: string }[],
    subAccounts: SubAccount[],
    bulkAccountOpening?: boolean,
    lastSuitabilityReviewAt?: string,
    iDCheckCompleted?: boolean
  }): boolean => (
  user.incompleteFields.length === 0
  && (goalEnabled ? user.goals.length > 0 : true)
  && (goalAndSubAccountEnabled ? user.subAccounts.length > 0 : true)
  && (filterAgreements(user.allIncompleteFormAgreements ?? []).length === 0)
  && (isFeatureEnabled(FeatureFlagTypes.ID_VERIFICATION) ? user.iDCheckCompleted ?? false : true)
  && ((user.bulkAccountOpening === true && !!user.lastSuitabilityReviewAt) || (!user.bulkAccountOpening))
);

export const hasHouseholdEditAccess = (household?: ClientGroup): boolean => {
  if (!household) return false;
  const hasEditAccess = household?.relationships?.some((x) => x.user?.id === userIdVar()
    && x.accessType && [ClientGroupMemberAccessTypes.EDIT, ClientGroupMemberAccessTypes.VIEW].includes(x.accessType));
  return hasEditAccess ?? false;
};

const Content = (): JSX.Element => {
  const { userContext, setUserContext } = useContext(UserContext);
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const [openInactivityDialog, setOpenInactivityDialog] = useState(false);
  const [fetchFromServer, setFetchingFromServer] = useState(true);
  const [openSessionTimeoutDialog, setOpenSessionTimeoutDialog] = useState(getFromLocalStorage('inactivity') !== null);
  const [state, updateState] = useState({
    loading: isLoggedInVar(),
    isLoadingFonts: true,
  });
  const initialOnboardingAttributes = useRef<User>({
    id: '',
    incompleteFields: [],
    allLatestFormAgreements: [],
    goals: [],
    accounts: [],
    subAccounts: [],
    readyToSignAgreement: false,
    sourceOfWealth: [],
    sourceOfWealthOtherDescription: '',
    sourceOfFunds: [],
    sourceOfFundsOtherDescription: '',
  });
  const apolloClient = useApolloClient();

  const idleTimer = useIdleTimer({
    timeout: Number(process.env.REACT_APP_SESSION_TIMEOUT_MILLISECONDS ?? '900000'),
    onIdle: () => {
      if (isNil(userContext.partnerId) && isLoggedInVar()) {
        setOpenInactivityDialog(true);
      }
    },
    debounce: 0,
    startManually: true,
  });
  if (process.env.REACT_APP_ENV === 'production') {
    idleTimer.start();
  }

  const setAccountTypeData = (data: { fetchAccountTypes: { accountTypes: AccountType[] } }): void => {
    const accountTypeList: AccountTypeAttributes[] = data.fetchAccountTypes.accountTypes.map((x) => (
      {
        key: x.value,
        title: translateBackend(x.translatedName),
        description: translateBackend(x.translatedDescription),
      }
    ));
    allAccountTypesVar(accountTypeList);
  };

  const [fetchAccountTypes] = useLazyQuery(FETCH_ACCOUNT_TYPES, {
    variables: {},
    onCompleted: (data) => setAccountTypeData(data),
  });

  const { data } = useQuery(GET_USER_INITIAL_STATUS);
  useEffect(() => {
    waitForFontLoad('12px Causten Round').then((loaded) => {
      if (loaded) updateState((prev) => ({ ...prev, isLoadingFonts: false }));
    });
    if (data.isLoggedIn) {
      setFetchingFromServer(true);
      apolloClient.query({
        query: BASIC_ME,
        variables: { skipErrorHandler: true },
      }).then((value) => {
        if (value.data?.me?.user) {
          const user: User = value.data?.me.user;
          console.log({ event: 'USER_AUTHENTICATED', userId: user.id });
          userIdVar(user.id);
          setAnalyticsUserId(user.id);
          userEmailVar(user.primaryEmail);
          userGenderVar(user.gender);
          const householdMembersIds = user.households?.reduce((acc: string[], cur: ClientGroup) => {
            if (cur.relationships) {
              const userIds: string[] = cur.relationships.map((relationship) => relationship.user?.id ?? '');
              acc.push(...userIds);
            }
            return uniq(acc);
          }, []);
          if (householdMembersIds) householdMembersIdVar(householdMembersIds);
          if (user.iDVerified) isIdVerifiedVar(user.iDVerified);
          if (user.organization?.supportUrl) orgSupportUrlVar(user.organization?.supportUrl);
          if (user.organization?.helpCentreUrl) orgHelpCentreUrlVar(user.organization?.helpCentreUrl);
          availableFeatureFlagsVar(user.availableFeatureFlags);
          const onboarded = isOnboardingComplete(user);
          if (!onboarded) {
            loadCountryFlags();
          }
          isOnboardingCompleteVar(onboarded);
          setUserContext((prev) => ({
            ...prev,
            allIncompleteFormAgreements: user.allIncompleteFormAgreements,
            goals: user.goals.filter((g) => g.state === 'ACTIVE'),
            accounts: user.accounts.filter((g) => !['CANCELED', 'INACTIVE'].includes(g.state ?? '')),
            subAccounts: user.subAccounts.filter((g) => g.state !== 'INACTIVE'),
            households: user.households,
            lastSuitabilityReviewAt: user.lastSuitabilityReviewAt,
            hasCheckedPolicyAndCondition: user.hasCheckedPolicyAndCondition,
            availableFeatureFlags: user.availableFeatureFlags,
            bulkAccountOpening: user.bulkAccountOpening,
            dateOfBirth: user.dateOfBirth,
            maritalStatus: user.maritalStatus,
            organization: user.organization,
          }));
          const canViewOrEditHousehold = user.households && user.households.some((x) => hasHouseholdEditAccess(x));
          const viewHousehold = canViewOrEditHousehold ?? false;
          canViewHouseholdVar(user.availableFeatureFlags?.includes(FeatureFlagTypes.HOUSEHOLD) ? viewHousehold : false);
          setSentryContext();
          isSignUpCompleteVar(true);
          initialOnboardingAttributes.current = user;
          updateState((prev) => ({ ...prev, loading: false }));
        } else {
          console.error({ message: 'USER_NOT_LOADED' });
          updateState((prev) => ({ ...prev, loading: false }));
          signUserOut(apolloClient);
        }
        setFetchingFromServer(false);
      }).catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (error.graphQLErrors?.find((e: any) => e.extensions?.code === 'NOT_ACCESSIBLE')) {
          console.log({ event: 'USER_DOES_NOT_EXIST' });
          updateState((prev) => ({ ...prev, loading: false }));
          isSignUpCompleteVar(false);
        } else {
          console.error({ message: error.message });
          updateState((prev) => ({ ...prev, loading: false }));
          signUserOut(apolloClient);
        }
      });
      updateState((prev) => ({ ...prev, loading: true }));
      fetchAccountTypes().then();
    }
  }, [data.isLoggedIn, apolloClient, setUserContext, fetchAccountTypes]);

  const isNotEmbedded = isNil(userContext.partnerId);
  isEmbedded(!isNil(userContext.partnerId));
  organizationIdVar(userContext.organizationId);
  const loading = state.loading || state.isLoadingFonts || (data.isLoggedIn && isNil(data.isOnboardingComplete));
  const homePageClass = data.isLoggedIn && (loading || (data.isSignUpComplete && data.isOnboardingComplete));
  const definePage = (): JSX.Element => {
    if (loading) {
      return <LoadingBox />;
    }

    if (data.isLoggedIn && !data.isOnboardingComplete) {
      return <OnboardingPage userAttributes={initialOnboardingAttributes.current} />;
    }

    if (data.isLoggedIn) {
      return <HomePage />;
    }

    return <SignInPage />;
  };

  return (
    <>
      {isNotEmbedded ? <AppBarHeader /> : null}
      <Container id="body" className={`preliminary ${homePageClass ? 'home-page-class' : ''} ${isNotEmbedded ? 'ov-header' : ''}`} sx={{ position: isMobileScreen ? 'relative !important' : '' }} maxWidth="sm">
        {definePage()}
      </Container>
      {isOnboardingCompleteVar() && !fetchFromServer ? <Checker /> : undefined}
      {
        isNotEmbedded ? (
          <>
            <InactiveLayoutModal
              open={openInactivityDialog}
              onLogout={() => setOpenInactivityDialog(false)}
              stayLoggedIn={() => setOpenInactivityDialog(false)}
              onSessionTimeOut={() => setOpenInactivityDialog(false)}
            />
            <SessionTimeoutLayoutModal open={openSessionTimeoutDialog} onClose={() => setOpenSessionTimeoutDialog(false)} />
          </>
        ) : null
      }
    </>
  );
};

export default Content;
