/* eslint-disable prefer-arrow-callback */
/* eslint-disable react/jsx-no-duplicate-props */

import { TextField } from '@mui/material';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface Props {
  value?: string,
  valueCents?: number,
  onChange?: (event: { target: { name: string; value: string; valueCents: number } }) => void;
  style?: React.CSSProperties,
  testId?:string,
  label: string,
  disabled?: boolean,
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string; valueCents: number } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<string>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
              valueCents: Math.round((Number(values.value) * 100)),
            },
          });
        }}
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  },
);

const AmountInput = ({ ...props }: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const [value, setValue] = useState(props.valueCents ? (props.valueCents / 100).toFixed(2).toString() : props.value);

  return (
    <TextField
      data-testid={props.testId}
      className="amountActive"
      fullWidth
      disabled={props.disabled}
      placeholder="$0.00"
      value={value}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumberFormatCustom as any,
      }}
      inputProps={{
        inputMode: 'numeric',
        style: {
          backgroundColor: `${props.disabled ? colors.neutral80 : colors.transparent}`,
          borderRadius: '4px',
          textAlign: 'center',
          fontWeight: 600,
          fontSize: '32px',
          lineHeight: '40px',
          color: `${props.disabled ? colors.neutral70 : colors.neutralBlack}`,
          caretColor: colors.success,
        },
      }}
      onChange={(event) => {
        setValue(event.target.value);
        if (props.onChange) {
          props.onChange({
            target: {
              name: event.target.name,
              value: event.target.value,
              valueCents: Math.round((Number(event.target.value) * 100)),
            },
          });
        }
      }}
    />
  );
};

AmountInput.defaultProps = {
  disabled: false,
  onChange: undefined,
  value: '',
  valueCents: undefined,
  style: undefined,
  testId: undefined,
};

export default AmountInput;
