import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useState } from 'react';
import WithStepWizard, { StepProps } from '../common/wrappers/withStepWizard';
import TrackingBar from '../common/headers/trackingBar';
import Information from './information';
import PersonaWizard from '../persona/personalWizard';

const InformationStep = WithStepWizard(Information);
const PersonaWizardStep = WithStepWizard(PersonaWizard);

export interface UserState {
  firstName?: string,
  middleName?: string,
  lastName?: string,
  primaryEmail?: string,
  dateOfBirth?: Date,
  iDCheckCompleted?: boolean,
}

export interface AlternativeSteps {
  isCitizenshipStepRequired?: boolean,
  isForeignTaxStepRequired?: boolean,
}

interface Props {
  user: UserState,
  initStep?: number,
  onCompleted: () => void,
  continueLater: () => void,
  stepProps?: StepProps,
  stepTitle?: string,
}

const IdVerificationCheckerWizard = ({
  user, continueLater, stepTitle, stepProps, initStep, onCompleted,
}: Props): JSX.Element => {
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const steps: JSX.Element[] = [
    <InformationStep stepTitle={stepTitle} stepName="information" key="information" continueLater={continueLater} />,
    <PersonaWizardStep stepTitle={stepTitle} stepName="persona" key="persona" user={user} onCompleted={onCompleted} />,
  ];
  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      transitions={{}}
      className="ov-step-wizard"
      initialStep={initStep}
      nav={(
        <TrackingBar steps={steps} baselineStepProps={stepProps} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

IdVerificationCheckerWizard.defaultProps = {
  stepTitle: undefined,
  stepProps: undefined,
  initStep: 1,
};

export default IdVerificationCheckerWizard;
