/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import {
  ApolloClient,
  createHttpLink,
  ApolloProvider,
  from,
  fromPromise,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { refreshAuthToken, signUserOut } from '../utils/authToken';
import {
  accessTokenVar, cache, isLoggedInVar, organizationIdVar, refreshTokenVar, typeDefs,
} from '../utils/localVariables';
import en from '../assets/i18n/en';
import { useGlobalToast } from './globalToastProvider';

const OvApolloProvider: React.FC = ({ children }) => {
  const { t } = useTranslation(['errorMessages']);
  const { showToast } = useGlobalToast();

  const client = useMemo(() => new ApolloClient({
    link: from([onError(({
      graphQLErrors, networkError, operation, forward,
    }): any => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({
          message, locations, path, extensions,
        }) => console.log(`[GraphQL error]: Code: ${extensions?.code}, Message: ${message}, Operation: ${JSON.stringify(operation.query)}, Variables: ${JSON.stringify(operation.variables)}, Location: ${JSON.stringify(locations)}, Path: ${path}`));
        if (!operation.variables.skipErrorHandler && graphQLErrors.length > 0 && graphQLErrors[0].message) {
          const error = graphQLErrors[0];
          let msg = error.message;
          const errorCode = error.extensions?.code as string | undefined;
          if (errorCode && Object.keys(en.errorMessages).includes(errorCode)) {
            msg = t(`errorMessages:${errorCode}`);
          }
          showToast({ message: msg, severity: 'error', title: t('toastMessages:error') });
        }
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);

        // TODO: Get the 401 statusCode from the networkError. Currently it's not comming in the networkError as expected by the documentation.
        if (networkError.toString() === 'TypeError: Failed to fetch') {
          console.log('[Authorization error]');
          if (refreshTokenVar() && isLoggedInVar()) {
            accessTokenVar('');
            return fromPromise(
              refreshAuthToken(client).catch((error) => {
                console.error(error);
                signUserOut(client);
              }),
            ).filter((value) => Boolean(value)).flatMap((accessToken) => {
              const oldHeaders = operation.getContext().headers;
              // modify the operation context with a new token
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                },
              });
              // retry the request, returning the new observable
              return forward(operation);
            });
          }
          signUserOut(client);
        }
      }
    }), setContext(async (_, { headers }) => {
      const token = accessTokenVar();
      const organizationId = organizationIdVar() || process.env.REACT_APP_ONEVEST_APP_ORGANIZATION_ID;
      return {
        headers: {
          ...headers,
          requestTraceId: uuid(),
          authorization: token ? `Bearer ${token}` : '',
          ...(organizationId ? { 'organization-id': organizationId } : {}),
        },
      };
    }).concat(createHttpLink({
      uri: process.env.REACT_APP_CONSUMER_GRAPHQL_URI,
    }))]),
    cache,
    typeDefs,
  }), [showToast, t]);

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

export default OvApolloProvider;
