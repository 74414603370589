import { useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import WithStepWizard from '../common/wrappers/withStepWizard';
import Email from './email';
import EmailVerification from './emailVerification';
import PhoneVerification from './phoneVerification';
import { START_PASSWORDLESS_UPDATE, VERIFY_AND_UPDATE_PASSWORDLESS, TokenResponse } from './graphql';
import TrackingBar from '../common/headers/trackingBar';

const EmailStep = WithStepWizard(Email);
const EmailVerificationStep = WithStepWizard(EmailVerification);
const PhoneVerificationStep = WithStepWizard(PhoneVerification);

interface Props {
  goBack?: () => void,
  onContinue?: () => void,
  updateState?: (user: { primaryEmail: string }) => void,
}

const UpdateEmailWizard = ({
  goBack,
  onContinue,
  updateState,
} : Props): JSX.Element => {
  const [stepWizard, updateStepWizard] = useState({
    SW: {} as StepWizardProps,
  });
  const [user, updateUser] = useState({
    primaryEmail: '',
    phone: '',
    verificationToken: '',
    token: {} as TokenResponse,
  });
  const [loading, setLoading] = useState(false);
  const graphqlClient = useApolloClient();

  const setInstance = (SW: StepWizardProps): void => updateStepWizard({ ...stepWizard, SW });
  const setUserState = (att: Partial<typeof user>): void => updateUser({ ...user, ...att });

  const onError = (): void => {
    setLoading(false);
    if (goBack) goBack();
  };

  const onEmailContinue = async (callback: () => void): Promise<void> => {
    setLoading(true);
    try {
      const resp = await graphqlClient.mutate({
        mutation: START_PASSWORDLESS_UPDATE,
        variables: { input: { username: user.primaryEmail, connection: 'email' } },
      });
      const phone = resp.data.startPasswordlessUpdate.username;
      console.log({ event: 'EMAIL_UPDATE_STARTED', email: user.primaryEmail, phone });
      setUserState({ ...user, phone });
      setLoading(false);
      callback();
    } catch (e) {
      setLoading(false);
    }
  };

  const onPhoneValidated = async (callback: () => void): Promise<void> => {
    setLoading(true);
    try {
      const step1 = await graphqlClient.mutate({
        mutation: VERIFY_AND_UPDATE_PASSWORDLESS,
        variables: { input: { username: user.phone, connection: 'sms', verificationToken: user.verificationToken } },
      });
      console.log({ event: 'PHONE_VALIDATED', phone: user.phone });
      const { mfaToken } = step1.data.verifyAndUpdatePasswordless;
      setUserState({ ...user, token: { ...user.token, mfaToken } });

      const step2 = await graphqlClient.mutate({
        mutation: START_PASSWORDLESS_UPDATE,
        variables: { input: { username: user.primaryEmail, connection: 'email', mfaToken } },
      });
      console.log({ event: 'EMAIL_VALIDATION_STARTED', email: user.primaryEmail });
      const { oobCode } = step2.data.startPasswordlessUpdate;
      setUserState({ ...user, token: { ...user.token, mfaToken, oobCode } });
      setLoading(false);
      callback();
    } catch (e) {
      onError();
    }
  };

  const onEmailValidated = async (): Promise<void> => {
    setLoading(true);
    try {
      await graphqlClient.mutate({
        mutation: VERIFY_AND_UPDATE_PASSWORDLESS,
        variables: {
          input: {
            username: user.primaryEmail, connection: 'email', mfaToken: user.token.mfaToken, oobCode: user.token.oobCode, verificationToken: user.verificationToken,
          },
        },
      });
      console.log({ event: 'EMAIL_UPDATED', email: user.primaryEmail });
      setLoading(false);
      if (updateState) updateState({ primaryEmail: user.primaryEmail });
      if (onContinue) onContinue();
    } catch (e) {
      onError();
    }
  };

  const { t } = useTranslation(['signUp']);
  const steps: JSX.Element[] = [
    <EmailStep key="1" stepName="email" user={user} updateUserState={setUserState} action={onEmailContinue} isLoading={loading} goBackAction={goBack} texts={{ title: t('signUp:updateEmail.emailTitle'), subTitle: t('signUp:updateEmail.emailSubTitle') }} />,
    <PhoneVerificationStep key="2" stepName="phoneVerification" user={user} updateUserState={setUserState} skipOtpMutations action={onPhoneValidated} isLoading={loading} displayHelpLink />,
    <EmailVerificationStep key="3" stepName="emailVerification" user={user} updateUserState={setUserState} skipOtpMutations action={onEmailValidated} isLoading={loading} onContinue={onContinue} goBackToNamedStep={() => 'email'} displayHelpLink />,
  ];

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      className="ov-step-wizard"
      transitions={{}}
      nav={(
        <TrackingBar steps={steps} showStepper={false} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

UpdateEmailWizard.defaultProps = {
  goBack: undefined,
  onContinue: undefined,
  updateState: undefined,
};

export default UpdateEmailWizard;
