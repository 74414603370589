import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useContextTheme } from '../../providers/contextThemeProvider';

const Disclaimer = ({ text }: { text: string }): JSX.Element => {
  const { colors } = useContextTheme();
  return (
    <Box style={{
      backgroundColor: colors.neutral70, padding: '15px 10px', minWidth: '100%', borderRadius: '3px',
    }}>
      <Typography variant="captionSectionerMedium" fontWeight="600" style={{ margin: 0 }}>
        {text}
      </Typography>
    </Box>
  );
};

export default Disclaimer;
