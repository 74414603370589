/* eslint-disable camelcase */
import base from './base.json';
import goal from './goal.json';
import account from './account.json';
import signUp from './signUp.json';
import user from './user.json';
import identity from './identity.json';
import widget from './widget.json';
import statistics from './statistics.json';
import transfer from './transfer.json';
import portfolio from './portfolio.json';
import flinks from './flinks.json';
import deposit from './deposit.json';
import document from './document.json';
import autoInvestment from './autoInvestment.json';
import projection from './projection.json';
import toastMessages from './toastMessages.json';
import errorMessages from './errorMessages.json';
import assumptions from './assumptions.json';
import suitabilityReview from './suitabilityReview.json';
import idVerificationChecker from './idVerificationChecker.json';
import orgSpecific_picton from './orgSpecific_picton.json';

const en = {
  base,
  goal,
  account,
  signUp,
  user,
  identity,
  widget,
  statistics,
  transfer,
  flinks,
  deposit,
  portfolio,
  document,
  projection,
  autoInvestment,
  toastMessages,
  errorMessages,
  assumptions,
  suitabilityReview,
  idVerificationChecker,
  orgSpecific_picton,
};

export const namespaces = Object.keys(en);
export const defaultNS = 'base';

export default en;
