import { List, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonListItem from '../common/lists/buttonListItem';
import { GoalTimeHorizonAttributes, GoalTimeHorizons, goalTimeHorizons } from './resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import { Goal } from '../../utils/commonGraphql';
import { isLoggedInVar, isOnboardingCompleteVar } from '../../utils/localVariables';

export interface GoalRef {
  timeHorizon?: GoalTimeHorizons,
}

interface Props {
  setGoalRef: (att: GoalRef) => void,
  currentGoal?: Goal | GoalRef,
  onContinue?: () => void,
  name?: string,
}

const TimeHorizon = ({
  setGoalRef, onContinue, currentGoal, name,
}: Props): JSX.Element => {
  const { t } = useTranslation(['goal']);
  const handleClick = async (item: GoalTimeHorizonAttributes): Promise<void> => {
    console.log({ event: 'GOAL_TIME_HORIZON_SELECTED', type: item.key });
    if (isLoggedInVar() && !isOnboardingCompleteVar()) {
      sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectTimeHorizon).then();
    } else {
      sendAnalyticsEvent(ovAnalyticsEvents.addGoalTimeHorizonSelect).then();
    }
    setGoalRef({ timeHorizon: item.key });
    if (onContinue) onContinue();
  };
  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2">{name ? t('goal:timeHorizon.titleName', { goalName: name }) : t('goal:timeHorizon.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('goal:timeHorizon.subTitle')}</Typography>
      <List sx={{ width: '100%', cursor: 'pointer' }}>
        {goalTimeHorizons.map((item: GoalTimeHorizonAttributes) => (
          <ButtonListItem
            key={item.key}
            text={item.description}
            onClick={() => handleClick(item)}
            isSelected={currentGoal?.timeHorizon === item.key}
          />
        ))}
      </List>
    </OvForm>
  );
};

TimeHorizon.defaultProps = {
  onContinue: undefined,
  currentGoal: undefined,
  name: undefined,
};

export default TimeHorizon;
