// Basic ME query
import { gql } from '@apollo/client';

export const ME_FOR_SUITABILITY_REVIEW_PROPERTIES = gql`
  query BasicMe {
    me {
      user {
        employmentStatus
        companyType
        jobTitle
        studentAreaOfStudy
        citizenships
        foreignTaxInformation {
          foreignTaxCountry
          foreignTaxNumber
        }
        powerOfAttorneyGranted
        isMemberOfIiroc
        isOfficerOfPublicCompany
        isOwnerOfPublicCompany
        politicallyExposedDomesticPerson
        headOfInternationalOrganization
        politicallyExposedForeignPerson
        closeAssociateOfPEP
        investmentKnowledge
        annualIncomeCents
        financialLiquidAssetsCents
        financialFixedAssetsCents
        totalDebtCents
        annualDebtPaymentsCents
        riskQuestion1
        riskQuestion2
        goals {
          id
          name
          riskQuestion1
          timeHorizon
          subAccounts {
            id
            theme { id }
            name
            suggestedFinancialProduct {
              id
              name
            }
            financialProduct {
              id
              name
            }
            acceptableFinancialProduct
            account {
              id
              type
            }
          }
          suggestedFinancialProduct{
            id
            forecastedRateOfReturn
            forecastedStandardDeviation
          }
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const ANNUAL_SUITABILITY_COMPLETED = gql`
  mutation annualSuitabilityCompleted($userId: ObjectID!) {
    annualSuitabilityCompleted(userId: $userId) {
      user {
        id
        lastSuitabilityReviewAt
      }
    }
  }
`;

export const INCOMPLETE_FORM_AGREEMENT = gql`
  query BasicMe {
    me {
      user {
        accounts {
          id
          type
          state
          incompleteFormAgreements {
            type 
            minVersion
            translatedDisplayName {
              en
              fr
            }
          }
        }
        subAccounts {
          id
          name
          state
          goal { id }
          account { id type }
          incompleteFormAgreements {
            type 
            minVersion
            translatedDisplayName {
              en
              fr
            }
          }
          theme { id key translatedName {fr en} id},
          allowClientDeposits
        }
        incompleteFormAgreements {
          type 
          minVersion
          translatedDisplayName {
            en
            fr
          }
        }
        allIncompleteFormAgreements { type minVersion translatedDisplayName { en fr } digitalSignatureEnabled }
      }
    }
  }
`;
