import { colors } from '../../theme/colors';

export const styles = {
  listItemWrapper: {
    background: colors.ovWhite,
    border: '1px solid rgba(218, 228, 237, 0.4)',
    boxShadow: '0px 4px 10px rgba(234, 236, 239, 0.25)',
    borderRadius: '6px',
    padding: '10px',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    bgcolor: colors.ovWhite,
    borderRadius: '6px',
    minHeight: '250px',
    maxWidth: '400px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.07)',
  },
  closeBox: {
    width: '26px',
    height: '26px',
    top: '-6px',
    borderRadius: '3.42px',
    background: 'rgba(240, 244, 248, 0.5)',
    marginTop: '18px',
    marginLeft: '36px',
    marginRight: '36px',
    marginBottom: '22px',
  },
  headingText: {
    marginBottom: '16px',
  },
  subText: {
    marginBottom: '43px',
  },
  goalEditButton: {
    position: 'relative',
    width: '100%',
  },
  listWrapper: {
    bgcolor: colors.holdingListBackground,
    cursor: 'pointer',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  listWrapper2: {
    bgcolor: colors.baseComponentBackground,
    cursor: 'pointer',
  },
  chart: {
    margin: '20px',
    height: '180px',
    maxHeight: '220px',
  },
  centerBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  chartTitle: {
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '0px',
    color: colors.secondary,
    textAlign: 'center',
  },
  chartValue: {
    fontSize: '24px',
    fontWeight: 600,
    color: colors.secondary,
    textAlign: 'center',
  },
  chartPercentage: {
    fontSize: '12px',
    fontWeight: 400,
    color: colors.neutral30,
    textAlign: 'center',
    marginBottom: '0px',
  },
};
