/* This modal Generated as general Modal for Feedback popups with Action buttons according to following figma:
https://www.figma.com/file/Y3J9whUp6qR2fIIJrk93wQ/%F0%9F%92%BB--Web---Design-system?node-id=1338%3A2764
*/
import { Box, Modal } from '@mui/material';
import { colors } from '../../../../theme/colors';
import CloseButton from '../../buttons/closeButton';

const styles = {
  modal: {
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '392px',
    bgcolor: colors.ovWhite,
    borderRadius: '5px',
    maxHeight: '95vh',
    overflowY: 'auto',
    padding: '32px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.07)',
    maxWidth: '90vw',
  },
};

interface Props {
  component: JSX.Element,
  open: boolean,
  onClose?: () => void,
  showCloseButton?: boolean,
}
const boxShadow = '0px 0px 20px rgba(0, 0, 0, 0.07)';
const FeedbackModal = ({
  component,
  onClose,
  open,
  showCloseButton,
}: Props): JSX.Element => (
  <Modal
    open={open}
    onClose={onClose}
  >
    <Box sx={styles.modal} boxShadow={boxShadow}>
      <Box>
        {showCloseButton ? (
          <CloseButton onClick={() => {
            if (onClose) onClose();
          }} />
        ) : null}
      </Box>
      {component}
    </Box>
  </Modal>
);

FeedbackModal.defaultProps = {
  onClose: undefined,
  showCloseButton: undefined,
};

export default FeedbackModal;
