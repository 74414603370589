import i18n from '../../assets/i18n/config';
import BuildWealthIcon from '../../assets/images/goals/build-wealth.svg';
import RetirementIcon from '../../assets/images/goals/retirement.svg';
import MajorPurchaseIcon from '../../assets/images/goals/major-purchase.svg';
import BuyACarIcon from '../../assets/images/goals/buy-a-car.svg';
import BuyAHouseIcon from '../../assets/images/goals/buy-a-house.svg';
import EducationIcon from '../../assets/images/goals/education.svg';
import VacationIcon from '../../assets/images/goals/vacation.svg';
import StartABusinessIcon from '../../assets/images/goals/start-a-business.svg';
import WeddingIcon from '../../assets/images/goals/wedding.svg';
import ParentalLeaveIcon from '../../assets/images/goals/parental-leave.svg';
import SomethingElseIcon from '../../assets/images/goals/something-else.svg';
import SafetyNetIcon from '../../assets/images/goals/safety-net.svg';
import OtherIcon from '../../assets/images/goals/other.svg';
import RiskLevel1Icon from '../../assets/images/goals/risk-level-1.svg';
import RiskLevel3Icon from '../../assets/images/goals/risk-level-3.svg';
import RiskLevel5Icon from '../../assets/images/goals/risk-level-5.svg';

export enum GoalTypes {
  CAR = 'CAR',
  BUILD_WEALTH = 'BUILD_WEALTH',
  RETIREMENT = 'RETIREMENT',
  MAJOR_PURCHASE = 'MAJOR_PURCHASE',
  HOUSE = 'HOUSE',
  EDUCATION = 'EDUCATION',
  VACATION = 'VACATION',
  START_A_BUSINESS = 'START_A_BUSINESS',
  WEDDING = 'WEDDING',
  PARENTAL_LEAVE = 'PARENTAL_LEAVE',
  SAFETY_NET = 'SAFETY_NET',
  OTHER = 'OTHER',
}

export enum GoalRiskLevels {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
  LEVEL_4 = 'LEVEL_4',
  LEVEL_5 = 'LEVEL_5',
}

export enum GoalTimeHorizons {
  VERY_SHORT_TERM = 'VERY_SHORT_TERM',
  SHORT_TERM = 'SHORT_TERM',
  MID_TERM = 'MID_TERM',
  LONG_TERM = 'LONG_TERM',
  VERY_LONG_TERM = 'VERY_LONG_TERM',
}

export interface GoalTypeAttributes {
  key: GoalTypes,
  description: string,
  iconPath: string,
  defaultTargetAmountCents?: number,
  secondaryGoalTypes?: GoalTypeAttributes[],
}

export const goalTypes: GoalTypeAttributes[] = [
  {
    key: GoalTypes.BUILD_WEALTH,
    description: i18n.t('goal:resources.typeTitle.BUILD_WEALTH'),
    iconPath: BuildWealthIcon,
    defaultTargetAmountCents: 5000000,
  },
  {
    key: GoalTypes.RETIREMENT,
    description: i18n.t('goal:resources.typeTitle.RETIREMENT'),
    iconPath: RetirementIcon,
    defaultTargetAmountCents: 100000000,
  },
  {
    key: GoalTypes.MAJOR_PURCHASE,
    description: i18n.t('goal:resources.typeTitle.MAJOR_PURCHASE'),
    iconPath: MajorPurchaseIcon,
    secondaryGoalTypes: [
      {
        key: GoalTypes.CAR,
        description: i18n.t('goal:resources.typeTitle.CAR'),
        iconPath: BuyACarIcon,
        defaultTargetAmountCents: 5000000,
      },
      {
        key: GoalTypes.HOUSE,
        description: i18n.t('goal:resources.typeTitle.HOUSE'),
        iconPath: BuyAHouseIcon,
        defaultTargetAmountCents: 10000000,
      },
      {
        key: GoalTypes.EDUCATION,
        description: i18n.t('goal:resources.typeTitle.EDUCATION'),
        iconPath: EducationIcon,
        defaultTargetAmountCents: 3500000,
      },
      {
        key: GoalTypes.VACATION,
        description: i18n.t('goal:resources.typeTitle.VACATION'),
        iconPath: VacationIcon,
        defaultTargetAmountCents: 400000,
      },
      {
        key: GoalTypes.START_A_BUSINESS,
        description: i18n.t('goal:resources.typeTitle.START_A_BUSINESS'),
        iconPath: StartABusinessIcon,
        defaultTargetAmountCents: 2500000,
      },
      {
        key: GoalTypes.WEDDING,
        description: i18n.t('goal:resources.typeTitle.WEDDING'),
        iconPath: WeddingIcon,
        defaultTargetAmountCents: 2500000,
      },
      {
        key: GoalTypes.PARENTAL_LEAVE,
        description: i18n.t('goal:resources.typeTitle.PARENTAL_LEAVE'),
        iconPath: ParentalLeaveIcon,
        defaultTargetAmountCents: 300000,
      },
      {
        key: GoalTypes.MAJOR_PURCHASE,
        description: i18n.t('goal:resources.typeTitle.MAJOR_PURCHASE_SOMTEHING_ELSE'),
        iconPath: SomethingElseIcon,
        defaultTargetAmountCents: 1000000,
      },
    ],
  },
  {
    key: GoalTypes.SAFETY_NET,
    description: i18n.t('goal:resources.typeTitle.SAFETY_NET'),
    iconPath: SafetyNetIcon,
    defaultTargetAmountCents: 500000,
  },
  {
    key: GoalTypes.OTHER,
    description: i18n.t('goal:resources.typeTitle.OTHER'),
    iconPath: OtherIcon,
    defaultTargetAmountCents: 1000000,
  },
];

export interface GoalTimeHorizonAttributes {
  key: GoalTimeHorizons,
  description: string,
}

export const goalTimeHorizons: GoalTimeHorizonAttributes[] = [
  {
    key: GoalTimeHorizons.VERY_SHORT_TERM,
    description: i18n.t('goal:resources.timeHorizonDescription.VERY_SHORT_TERM'),
  },
  {
    key: GoalTimeHorizons.SHORT_TERM,
    description: i18n.t('goal:resources.timeHorizonDescription.SHORT_TERM'),
  },
  {
    key: GoalTimeHorizons.MID_TERM,
    description: i18n.t('goal:resources.timeHorizonDescription.MID_TERM'),
  },
  {
    key: GoalTimeHorizons.LONG_TERM,
    description: i18n.t('goal:resources.timeHorizonDescription.LONG_TERM'),
  },
];

export interface GoalRiskLevelAttributes {
  key: GoalRiskLevels,
  description: string,
  iconPath: string,
}

export const goalRiskLevels: GoalRiskLevelAttributes[] = [
  {
    key: GoalRiskLevels.LEVEL_1,
    description: i18n.t('goal:resources.riskLevelDescription.LEVEL_1'),
    iconPath: RiskLevel1Icon,
  },
  {
    key: GoalRiskLevels.LEVEL_2,
    description: i18n.t('goal:resources.riskLevelDescription.LEVEL_2'),
    iconPath: RiskLevel1Icon,
  },
  {
    key: GoalRiskLevels.LEVEL_3,
    description: i18n.t('goal:resources.riskLevelDescription.LEVEL_3'),
    iconPath: RiskLevel3Icon,
  },
  {
    key: GoalRiskLevels.LEVEL_4,
    description: i18n.t('goal:resources.riskLevelDescription.LEVEL_4'),
    iconPath: RiskLevel3Icon,
  },
  {
    key: GoalRiskLevels.LEVEL_5,
    description: i18n.t('goal:resources.riskLevelDescription.LEVEL_5'),
    iconPath: RiskLevel5Icon,
  },
];
// In graphQL maximun Long value is 2^53 -1 - added 10B $ for a web app limit
export const MAXIMUM_TARGET_AMOUNT = 10000000000000;

export const getGoalIcon = (type: string): string => {
  const primaryType = goalTypes.find((g) => g.key === type || g.secondaryGoalTypes?.find((sg) => sg.key === type));
  const goal = primaryType?.key === type ? primaryType : primaryType?.secondaryGoalTypes?.find((g) => g.key === type);
  return goal?.iconPath ?? '';
};
