import { colors } from '../../theme/colors';

export const styles = {
  title: {
    marginBottom: '22px',
  },
  completionText: {
    fontSize: '10.94px',
    color: colors.neutral20,
  },
  infoText: {
    fontSize: '10.94px',
    curson: 'pointer',
    color: colors.neutral20,
    marginLeft: '4.07px',
    position: 'relative' as const,
    top: '3px',
  },
  likelyText: {
    color: colors.ovBlack,
    fontSize: '10.94px',
    position: 'relative',
    top: '-3px',
    marginLeft: '5px',
    marginBottom: '0px',
  },
  projectionValue: {
    fontSize: '14.59px',
    fontWeight: 700,
    color: colors.ovBlack,
    position: 'relative',
  },
  yearText: {
    fontSize: '14.59px',
    color: colors.neutral20,
    position: 'relative',
    top: '7px',
    marginLeft: '5.5px',
  },
  infoWrapper: {
    color: colors.neutral20,
    background: colors.infoBackground,
    display: 'flex',
    width: '143px',
    height: '21.88px',
    borderRadius: '3.64662px',
    cursor: 'pointer',
  },
  infoIcon: {
    width: '20px',
    color: colors.infoIconColor,
    cursor: 'pointer',
  },
  bottomInfoIcon: {
    width: '20px',
    position: 'relative' as const,
    top: '-9px',
    marginLeft: '3px',
    color: colors.infoIconColor,
    cursor: 'pointer',
  },
  goalIcon: {
    width: '22px',
  },
  yearWrapper: {
    display: 'flex',
  },
  byYearText: {
    fontSize: '12px',
    fontWeight: 600,
    marginTop: '13px',
    marginBottom: '12px',
  },
  likelyBox: {
    width: '10.3px',
    height: '10.3px',
    background: colors.projectionBackgroundDark,
    border: `0.911655px solid ${colors.projectionBoundaries}`,
    borderRadius: '1.82331px',
  },
  lessLikelyBox: {
    width: '10.3px',
    height: '10.3px',
    background: colors.primary80,
    border: `0.911655px solid ${colors.projectionBoundaries}`,
    borderRadius: '1.82331px',
  },
  adjustDesc: {
    marginTop: '15px',
    marginBottom: '15px',
    color: colors.helpSubTitle,
  },
  bottomWrapper: {
    width: '100%',
    display: 'flex',
    marginTop: '16px',
    marginBottom: '16px',
    justifyContent: 'space-between',
  },
  inputBox: {
    background: colors.inputBorder,
    borderRadius: '1.82331px',
  },
  chart: {
    height: '220px',
    maxHeight: '250px',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '398px',
    bgcolor: colors.ovWhite,
    borderRadius: '6px',
    minHeight: '175px',
    padding: '16px',
    boxShadow: `0px 0px 20px ${colors.transparentShadow}`,
    outline: 'none',
  },
  headingText: {
    marginBottom: '16px',
    textAlign: 'left',
  },
  subText: {
    marginBottom: '24px',
    textAlign: 'justify' as const,
  },
  gotItButton: {
    width: '100%',
    position: 'relative',
    bottom: 0,
    left: 0,
    right: 0,
  },
  infoList: {
    paddingLeft: '24px',
    fontSize: '14px',
    color: colors.neutral20,
    fontFamily: 'Causten Round',
    fontStyle: 'normal',
    textAlign: 'justify' as const,
    width: '84%',
  },
  infoModalContent: {
    height: '400px',
  },
};
