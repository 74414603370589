import {
  styled, Tooltip, tooltipClasses, TooltipProps,
} from '@mui/material';

export const ArrowTooltip = styled(({ className, ...props2 }: TooltipProps) => (
  <Tooltip {...props2} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 15,
  },
}));
