import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        incompleteFields
        iDCheckCompleted
        iDVerified
        inProvinceSince
        hasCheckedPolicyAndCondition
      }
    }
  }
`;

export const USER_PROVINCE = gql`
  query BasicMe {
    me {
      user {
        physicalAddress {
          province
        }
      }
    }
  }
`;
