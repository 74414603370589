import {
  Box,
  Button, Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import React from 'react';
import FlowModal from '../wrappers/modals/ovFlowModal';
import { colors } from '../../../theme/colors';

interface Props {
  open: boolean,
  onClose: () => void,
  onButtonClick: () => void,
  accountType: string,
}

const RRIFConfirmationModal = ({
  open,
  onClose,
  onButtonClick,
  accountType,
}: Props): JSX.Element | null => {
  const { t } = useTranslation(['account']);
  const translatedAccountNonAbbreviatedName = t(`account:resources.accountNonAbbreviatedName.${accountType}`);
  const translatedAccountType = t(`account:resources.typeTitle.${accountType}`);
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  if (open) {
    return (
      <FlowModal
        open={open}
        onClose={onClose}
        showCloseButton
        component={(
          <Box sx={{ width: isMobileScreen ? '100%' : '450px' }}>
            <Typography data-testid="dialog-title" variant="heading3">{t('account:accountType.confirmationDialogTitle')}</Typography>
            <Typography data-testid="dialog-description" variant="paragraph3">
              <Markup content={t('account:accountType.rrifConfirmationDialogDescription.1', { accountType: translatedAccountType })} />
            </Typography>
            <Typography data-testid="dialog-description" variant="paragraph3">
              <span>{t('account:accountType.rrifConfirmationDialogDescription.2', { accountNameAndAbbreviation: `${translatedAccountNonAbbreviatedName} (${translatedAccountType})` })}</span>
              <Link sx={{ margin: '0px 5px' }} href="https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/registered-retirement-income-fund-rrif/receiving-income-a-rrif.html" style={{ color: colors.link }} underline="always" target="_blank">{t('account:accountType.rrifConfirmationDialogDescription.3')}</Link>
              <span>{t('account:accountType.rrifConfirmationDialogDescription.4', { accountType: translatedAccountType })}</span>
            </Typography>
            <Button variant="primary-large" data-testid="dialog-continue-button" onClick={onButtonClick}>{t('account:accountType.rrifConfirmationDialogButtonText')}</Button>
          </Box>
        )}
      />
    );
  }
  return null;
};

export default RRIFConfirmationModal;
