/* eslint-disable no-confusing-arrow */
import i18n from '../../assets/i18n/config';

export interface Transfer {
  type: string,
  source: string,
  amountCents: number,
  state: string,
  createdAt: string,
  last?: boolean,
}
export interface PageData {
  totalCount: number,
  currentCount: number
}
export const defineTypeGainSymbol = (type: string): string => {
  if (type === 'DEPOSIT') return '+';
  if (type === 'WITHDRAW') return '-';
  return '';
};

export const stateMapping = (state: string): string => ['RECONCILED', 'FAILED', 'CANCELED'].includes(state) ? i18n.t(`transfer:state.${state}`) : i18n.t('transfer:state.pending');

export const typeMapping = (type: string): string => type === 'DEPOSIT' ? i18n.t('transfer:type.investment') : i18n.t('transfer:type.withdrawal');

export const parseTransferLabel = (source: string, type: string): string => {
  let label = '';
  if (type === 'DEPOSIT') {
    if (source === 'DIVIDEND') {
      label = i18n.t('transfer:type.dividend');
    } else if (source === 'CHEQUE') {
      label = i18n.t('transfer:type.cheque');
    } else if (source === 'DISTRIBUTION') {
      label = i18n.t('transfer:type.distribution');
    } else if (source === 'GOV_CONTRIBUTIONS') {
      label = i18n.t('transfer:type.govContributions');
    } else if (source === 'INCOME_FUND_TRANSFER') {
      label = i18n.t('transfer:type.incomeFundTransfer');
    } else if (source === 'EXTERNAL_TRANSFER_IN') {
      label = i18n.t('transfer:type.transferInExternal');
    } else if (source === 'FROM_ACCOUNT' || source === 'FROM_SUB_ACCOUNT') {
      label = i18n.t('transfer:type.transferExistingPortfolio');
    } else if (source === 'USER') {
      label = i18n.t('transfer:type.investment');
    } else if (source === 'ADJUSTMENT') {
      label = i18n.t('transfer:type.adjustment');
    }
  } else if (type === 'WITHDRAW') {
    if (source === 'FEE') {
      label = i18n.t('transfer:type.managementFee');
    } else if (source === 'CUSTODIAN_ACTIVITY') {
      label = i18n.t('transfer:type.custodianActivity');
    } else if (source === 'EXTERNAL_TRANSFER_OUT') {
      label = i18n.t('transfer:type.transferOutExternal');
    } else if (source === 'WITHHOLDING_TAX') {
      label = i18n.t('transfer:type.withholdingTax');
    } else if (source === 'DEREGISTRATION_TAX') {
      label = i18n.t('transfer:type.deRegistrationTax');
    } else if (source === 'DEREGISTRATION') {
      label = i18n.t('transfer:type.deRegistrationFee');
    } else if (source === 'TO_SUB_ACCOUNT' || source === 'TO_ACCOUNT') {
      label = i18n.t('transfer:type.transferExistingPortfolio');
    } else if (source === 'USER') {
      label = i18n.t('transfer:type.withdrawal');
    } else if (source === 'ADJUSTMENT') {
      label = i18n.t('transfer:type.adjustment');
    }
  }
  return label;
};
