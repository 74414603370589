import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  handleClose: () => void,
  open: boolean,
  content: string,
}

const DialogPopUp = ({
  open, handleClose, content,
}: Props): JSX.Element => (
  <Dialog
    onClose={handleClose}
    open={open}
  >
    <IconButton
      onClick={handleClose}
      style={{
        display: 'flex',
        justifyContent: 'left',
        width: '40px',
        borderRadius: 'unset',
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent>
      <Typography id="modal-modal-description" dangerouslySetInnerHTML={{ __html: `${content}` }} variant="paragraph3" />
    </DialogContent>
  </Dialog>
);

DialogPopUp.defaultProps = {
};

export default DialogPopUp;
