/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormEvent, useEffect, useState } from 'react';
import List from '@mui/material/List';
import OvForm from '../common/wrappers/ovForm';
import { ApplyForGovFunds, GovFundsAttribute } from './resources';
import ToggleListItemExtended from '../common/lists/toggleListItemExtended';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { translatedUrl } from '../../assets/i18n/config';
import { sendAnalyticsEvent, ovAnalyticsEvents } from '../../utils/firebase';

interface Props {
  isRespAdult: boolean,
  createAccountAction: (att: Array<ApplyForGovFunds>) => void,
  onContinue?: (step?: string) => void,
  proceedToNextStep?: () => void,
}

const Grants = ({
  // eslint-disable-next-line
  onContinue, createAccountAction, isRespAdult, proceedToNextStep,
}: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const [loading] = useState(false);
  const { showToast } = useGlobalToast();

  const [grants, setGrants] = useState<GovFundsAttribute[]>([
    {
      title: t('account:beneficiary.grants.CESG'),
      subtitle: t('account:beneficiary.grants.CESGBody'),
      value: true,
      key: ApplyForGovFunds.BASIC_CESG,
    },
    ...(!isRespAdult ? [{
      title: t('account:beneficiary.grants.additionalCESG'),
      subtitle: t('account:beneficiary.grants.additionalCESGBody'),
      linkText: t('account:beneficiary.grants.learnMore'),
      linkUrl: translatedUrl('https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/registered-education-savings-plans-resps/canada-education-savings-programs-cesp/canada-education-savings-grant-cesg.html'),
      value: true,
      key: ApplyForGovFunds.ADDITIONAL_CESG,
    }] : []),
    {
      title: t('account:beneficiary.grants.canadaLearningBond'),
      subtitle: t('account:beneficiary.grants.canadaLearningBondBody'),
      linkText: t('account:beneficiary.grants.learnMore'),
      linkUrl: translatedUrl('https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/registered-education-savings-plans-resps/canada-education-savings-programs-cesp/canada-learning-bond.html'),
      value: true,
      key: ApplyForGovFunds.CLB,
    },
  ]);

  const handleClick = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    const govFunds = grants.filter((x) => x.value !== false).map((item) => item.key);
    if (govFunds.length === 0) {
      showToast({ message: t('toastMessages:govtGrantError.h1'), severity: 'error', title: t('toastMessages:genericError.h1') });
      return;
    }
    sendAnalyticsEvent(ovAnalyticsEvents.applyGrantsPageContinueButtonSelect).then();
    if (proceedToNextStep) proceedToNextStep();
  };

  const handleToggle = (seletedValue: GovFundsAttribute) => () => {
    const newList = grants.map((item) => {
      if (item.key === seletedValue.key) {
        const updatedItem = {
          ...item,
          value: !seletedValue.value,
        };
        return updatedItem;
      }
      return item;
    });
    setGrants(newList);
  };

  useEffect(() => {
    const govFunds = grants.filter((x) => x.value !== false).map((item) => item.key);
    createAccountAction(govFunds);
  }, [grants]);

  return (
    <OvForm loading={loading} onSubmit={handleClick}>
      <Typography variant="heading2">{t('beneficiary.grants.title')}</Typography>
      <Typography variant="paragraph3">{t('beneficiary.grants.subtitle')}</Typography>
      <List className="assumption">
        {grants.map((item: GovFundsAttribute) => (
          <ToggleListItemExtended
            title={item.title}
            subtitle={item.subtitle}
            key={item.key}
            linkText={item.linkText}
            linkUrl={item.linkUrl}
            onChange={
              handleToggle(item)
            }
            hasLabel
            checked={item.value}
          />
        ))}
      </List>
    </OvForm>
  );
};

Grants.defaultProps = {
  onContinue: undefined,
  proceedToNextStep: undefined,
};

export default Grants;
