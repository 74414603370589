import { gql } from '@apollo/client';

export const CREATE_SUB_ACCOUNT = gql`
  mutation createSubAccount($input: CreateSubAccountInput!) {
    createSubAccount(input: $input) {
      subAccount {
        id
        name
        allowClientDeposits
        theme { id }
        account {
          id
          state
        }
        state
      }
    }
  }
`;

export const UPDATE_SUB_ACCOUNT = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount {
        id
        name
        allowClientDeposits
        state
      }
    }
  }
`;

export const FETCH_SUB_ACCOUNT_IPS_FORMS = gql`
  query fetchSubAccountIncompleteForms($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        incompleteFormAgreements {
          type
          minVersion
        }
      }
    }
  }
`;

export const CLOSE_SUB_ACCOUNT = gql`
  mutation closeSubAccount($input: CloseSubAccountInput!) {
    closeSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

export const FETCH_GOAL_AVAILABLE_THEMES = gql`
  query fetchGoalAvailableThemes($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        availableThemes {
          id
          key
          translatedName { en fr }
          translatedShortDescription { en fr }
          translatedDescription { en fr }
          iconKey
        }
      }
    }
  }
`;

export const FETCH_GOAL_SUGGESTED_HOLDINGS = gql`
  query fetchGoalSuggestedHoldings($goalId: ObjectID!, $themeId: ObjectID) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        suggestedFinancialProduct(themeId: $themeId) {
          id
          expectedPrimaryAssetClassHoldings {
            assetClass {
              id
              key
              name
              description
              translatedName {
                en
                fr
              }
              translatedDescription {
                en
                fr
              }
            }
            percentage
            expectedSecondaryAssetClassHoldings {
              assetClass {
                id
                key
                name
                description
                translatedName {
                  en
                  fr
                }
                translatedDescription {
                  en
                  fr
                }
              }
              percentage
              expectedSecurityHoldings {
                financialProduct {
                  id
                  ticker
                  translatedPortfolioDescription { en fr}
                  translatedName { en fr }
                }
                percentage
              }
            }
          }
          children {
            financialProduct {
              id
              ticker
              translatedName { en fr }
              translatedPortfolioDescription { en fr }
              primaryAssetClass {
                key
                translatedName { en fr }
                translatedDescription { en fr }
              }
              secondaryAssetClass {
                key
                translatedName { en fr }
                translatedDescription { en fr }
              }
            }
            percentage
          }
          translatedPortfolioDisclaimer { en fr }
        }
      }
    }
  }
`;

export const CREATE_AFFILIATE = gql`
  mutation createAffiliate($input: CreateAffiliateInput!){
    createAffiliate(input: $input){
      user{
        id
      }
    }
  }
`;

export const UPDATE_AFFILIATE = gql`
  mutation updateAffiliate($input: UpdateAffiliateInput!){
    updateAffiliate(input: $input){
      user{
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const ADD_AFFILIATION = gql`
  mutation addAffiliation($input: AddAffiliationInput!){
    addAffiliation(input: $input){
      account{
        id
      }
    }
  }
`;

export const UPDATE_AFFILIATIONS = gql`
  mutation updateAffiliations($input: UpdateAffiliationsInput!){
    updateAffiliations(input: $input){
      account{
        id
      }
    }
  }
`;

export const REMOVE_AFFILIATION = gql`
  mutation removeAffiliation($input: RemoveAffiliationInput!){
    removeAffiliation(input: $input){
      account{
        id
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccount($input: CreateAccountInput!){
    createAccount(input: $input){
      account{
        id
        type
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation updateAccount($input: UpdateAccountInput!){
    updateAccount(input: $input){
      account{
        id
        type
      }
    }
  }
`;

export const CLOSE_ACCOUNT = gql`
  mutation closeAccount($input: CloseAccountInput!){
    closeAccount(input: $input){
      account{
        id
      }
    }
  }
`;

export interface ThemeResponse {
  id: string
  key: string
  translatedName: {
    en: string
    fr?: string
  }
  translatedShortDescription: {
    en: string
    fr?: string
  }
  translatedDescription: {
    en: string
    fr?: string
  }
  iconKey?: string
}

export interface SuggestedHoldingResponse {
  financialProduct: {
    secondaryAssetClass: {
      key: string,
      translatedName: {
        en: string,
        fr: string,
      },
      translatedDescription: {
        en: string,
        fr: string,
      },
    }
  }
  percentage: number,
}
