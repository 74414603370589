import { Box, Avatar, Typography } from '@mui/material';

interface ModalListItemProps {
  index: number,
  title: string,
  body: string,
  marginBottom?: number,
}

const ModalListItem = ({ ...props }: ModalListItemProps): JSX.Element => (
  <Box display="flex" marginBottom={props.marginBottom} mt={0}>
    <Avatar className="personaStepListItemCount">{props.index ?? 1}</Avatar>
    <Box display="flex" flexDirection="column" ml={2}>
      <Typography variant="paragraph2">
        <b>
          {props.title}
        </b>
        {props.body}
      </Typography>
    </Box>
  </Box>
);

ModalListItem.defaultProps = {
  marginBottom: undefined,
};

export default ModalListItem;
