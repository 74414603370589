import { AccountTypes, getAccountTypes } from '../components/account/resources';

interface SubAccountProps {
  state?: string, account?: { id: string, type: string, }
}

// eslint-disable-next-line max-len
export const lockedInAccount = [AccountTypes.LIRA, AccountTypes.LRSP, AccountTypes.RLSP, AccountTypes.LIF, AccountTypes.RLIF, AccountTypes.LRIF, AccountTypes.PRIF, AccountTypes.RRIF, AccountTypes.RIF_SPOUSAL];
export const lockedInAccountsWithAuxiliaryStep = [AccountTypes.RRIF, AccountTypes.RIF_SPOUSAL];
export const accountsWhichRequirePaymentInstructions = [
  AccountTypes.RRIF,
  AccountTypes.RIF_SPOUSAL,
];
export const allowedAccountStates = [
  'ACTIVE',
  'REQUESTED',
  'READY',
  'INITIATED',
];

export const determineAccountsToExclude = (subAccounts: SubAccountProps[]): AccountTypes[] => {
  const accountToExclude: AccountTypes[] = [];
  const nonRetirementAccount = subAccounts.filter((s) => s.state !== 'INACTIVE' && s.account && s.account.type && !lockedInAccount.includes(s.account.type as AccountTypes));
  if (nonRetirementAccount && nonRetirementAccount.length > 0) {
    accountToExclude.push(AccountTypes.RRIF);
    return accountToExclude;
  }
  const retirementAccounts = subAccounts?.filter((s) => s.state !== 'INACTIVE' && s.account && s.account.type && lockedInAccount.includes(s.account.type as AccountTypes));
  if (retirementAccounts && retirementAccounts.length > 0) {
    getAccountTypes()
      .filter((account) => !lockedInAccount.includes(account.key))
      .map((account) => account.key)
      .forEach((account) => accountToExclude.push(account));
    return accountToExclude;
  }
  return accountToExclude;
};
export const hasRetirementAccount = (subAccounts: SubAccountProps[]): boolean => subAccounts.length > 0 && !!subAccounts.find((subAccount) => subAccount.state !== 'INACTIVE' && subAccount.account && accountsWhichRequirePaymentInstructions.includes(subAccount.account.type as AccountTypes));
export const isRetirementAccount = (data: { accountType?: AccountTypes, subAccounts?: SubAccountProps[]}) => {
  if (data.accountType) {
    return lockedInAccount.includes(data.accountType);
  }
  return !!data.subAccounts?.find((s) => s.state !== 'INACTIVE' && s.account && s.account.type && lockedInAccount.includes(s.account.type as AccountTypes));
};
export const hasPaymentInstruction = (data: { accountType?: AccountTypes, subAccounts?: SubAccountProps[]}) => {
  if (data.accountType) {
    return accountsWhichRequirePaymentInstructions.includes(data.accountType);
  }
  return !!data.subAccounts?.find((s) => s.state !== 'INACTIVE' && s.account && s.account.type && accountsWhichRequirePaymentInstructions.includes(s.account.type as AccountTypes));
};
export const hasNonLockedInAccount = (subAccounts: SubAccountProps[]): boolean => !!subAccounts.find((subAccount) => subAccount.state !== 'INACTIVE' && subAccount.state !== 'FROZEN' && subAccount.account && !lockedInAccount.includes(subAccount.account.type as AccountTypes));
export const hideExistingPortfolioTransfer = (subAccounts: SubAccountProps[]): boolean => {
  if (subAccounts.length === 1) return true;
  return !hasNonLockedInAccount(subAccounts);
};
