import { useMutation } from '@apollo/client';
import {
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  FormEvent,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { UPDATE_USER } from '../graphql';
import SimpleInput from '../../common/inputs/simpleInput';
import { InputType } from '../../common/inputs/utils/inputType';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  annualDebtPaymentsCents?: number,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
}

const ValueOfAnnualDebtPayments = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(user.annualDebtPaymentsCents ? (user.annualDebtPaymentsCents / 100).toFixed(2) : '');
  const updateAnnualDebtPaymentsCentsOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'ANNUAL_DEBT_PAYMENT_CENTS_UPDATED', annualDebtPaymentsCents: Math.round(Number(value) * 100) });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };

  const [updateAnnualDebtPaymentsCentsStatus] = useMutation(UPDATE_USER, {
    variables: {
      input: { annualDebtPaymentsCents: Math.round(Number(value) * 100) },
    },
    onCompleted: updateAnnualDebtPaymentsCentsOnCompleted,
    onError: () => setLoading(false),
  });

  const onContinueClick = (event : FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingEnterAnnualDebtPayments).then();
    setLoading(true);
    updateAnnualDebtPaymentsCentsStatus().then();
  };

  const updateState = (event: ChangeEvent<HTMLTextAreaElement|HTMLInputElement>): void => {
    let valueOfSavings;
    if (Number(event.target.value) > 90000000000000) {
      valueOfSavings = '90000000000000';
    } else {
      valueOfSavings = event.target.value;
    }
    setValue(valueOfSavings);
    updateUserState({ ...user, annualDebtPaymentsCents: Math.round(Number(valueOfSavings) * 100) });
  };

  const clear = (): void => {
    setValue('');
  };

  return (
    <OvForm loading={loading} onSubmit={onContinueClick}>
      <Typography variant="heading2">{t('user:howToInvest.annualDebtValue.title')}</Typography>
      <Typography variant="paragraph3">{t('user:howToInvest.annualDebtValue.subTitle')}</Typography>
      <SimpleInput inputProps={{ inputMode: 'numeric' }} onClear={clear} label={t('user:howToInvest.annualIncome.valuePrompt')} testId="value-input" required inputType={InputType.CURRENCY} value={value} onChange={updateState} />
    </OvForm>
  );
};

ValueOfAnnualDebtPayments.defaultProps = {
  onContinue: undefined,
};

export default ValueOfAnnualDebtPayments;
