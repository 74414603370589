import {
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import OvBackButton from '../buttons/ovBackButton';
import { StepProps } from '../wrappers/withStepWizard';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface Props {
  previousStep?: () => void,
  goToNamedStep?: (step: string) => void,
  totalSteps?: number,
  currentStep?: number,
  steps?: {
    props?: {
      stepTitle: string,
      stepName?: string,
      disableBackControl: boolean,
      disableTracker: boolean,
      goBackAction?: () => void,
      goBackToNamedStep?: () => string,
      stepProps?: StepProps
    },
  }[],
  showStepper?: boolean,
  baselineStepProps?: StepProps,
}

const TrackingBar = ({
  previousStep, goToNamedStep, totalSteps = 0, currentStep = 0, steps = [], showStepper, baselineStepProps,
}: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const styles = {
    root: {
      background: colors.trackingBarBackground,
      flexGrow: 1,
      marginBottom: '16px',
      maxHeight: '48px',
    },
    grow: {
      flexGrow: 1,
      marginBottom: '5px',
      position: 'relative',
      top: '1px',
      color: colors.trackingText,
    },
    icon: {
      fontSize: '20px',
    },
    progress: {
      position: 'relative',
      top: '3px',
      borderRadius: '10px',
    },
    menueBar: {
      background: colors.trackingBarBackground,
      boxShadow: 'none',
    },
    toolBar: {
      padding: 0,
    },
    toolBarRightColoumn: {
      width: '100%',
      paddingLeft: '16px',
    },
  };

  const calculateProgress = (): number => {
    const total = baselineStepProps?.absoluteTotalSteps ?? (totalSteps + (baselineStepProps?.totalSteps ?? 0));
    const currentStepFromStep = steps[currentStep - 1]?.props?.stepProps?.currentStep;
    let current;
    if (currentStepFromStep) {
      current = currentStepFromStep;
    } else {
      current = (currentStep + (baselineStepProps?.currentStep ?? 0));
    }
    const currentProgress = (current / total) * 100 || 0;
    return currentProgress || 0;
  };

  const showBackButton = (): boolean => (
    (currentStep !== 1 || steps[0]?.props?.goBackAction !== undefined)
    && !steps[currentStep - 1]?.props?.disableBackControl
  );

  const onBackButtonClick = (): void => {
    const goBackAction = steps[currentStep - 1]?.props?.goBackAction;
    const goBackToNamedStep = steps[currentStep - 1]?.props?.goBackToNamedStep;
    if (goBackAction) goBackAction();
    else if (goBackToNamedStep && goToNamedStep) {
      goToNamedStep(goBackToNamedStep());
    } else if (previousStep) previousStep();
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        !steps[currentStep - 1]?.props?.disableTracker
        && (showStepper || showBackButton())
        && (
          <div style={styles.root}>
            <AppBar position="static" color="default" style={styles.menueBar}>
              <Toolbar variant="dense" style={styles.toolBar}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                  {showBackButton() ? (
                    <OvBackButton onClick={onBackButtonClick} />
                  ) : <Box sx={{ width: '40px' }} />}
                  {showStepper && (
                    <Box sx={styles.toolBarRightColoumn}>
                      <Typography variant="heading4" color="inherit" sx={styles.grow}>
                        {steps[currentStep - 1]?.props?.stepTitle}
                      </Typography>
                      <LinearProgress variant="determinate" value={calculateProgress()} sx={styles.progress} />
                    </Box>
                  )}
                </Box>
              </Toolbar>
            </AppBar>
          </div>
        )
      }
    </>
  );
};

TrackingBar.defaultProps = {
  previousStep: () => undefined,
  goToNamedStep: () => undefined,
  totalSteps: 0,
  currentStep: 0,
  steps: [],
  showStepper: true,
  baselineStepProps: undefined,
};

export default TrackingBar;
