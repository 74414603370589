/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from 'react';
import {
  Typography, Box, LinearProgress, linearProgressClasses, styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MemberTag } from './memberTag';
import { colors as oldColors } from '../../theme/colors';
import { formatMoneyValue, formatPercentValue } from '../../utils/commonMethods';
import { navigate } from '../../utils/router';
import { UserContext } from '../../providers/userContextProvider';
import { useContextTheme } from '../../providers/contextThemeProvider';

interface MultiLinearProgressProps {
  maximum: number
  values: number[]
  names: string[]
  onHover: (index: number | null) => void
}

const MultiLinearProgress = ({
  maximum, values, names, onHover,
}: MultiLinearProgressProps) => {
  const height = 12;
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  return (
    <span style={{
      display: 'block',
      width: '100%',
      height,
      backgroundColor: oldColors.neutral10,
      borderRadius: height / 2,
      marginTop: '32px',
    }}>
      {values.map((value, index) => (
        <span
          key={value}
          onFocus={() => { setHoverIndex(index); onHover(index); }}
          onBlur={() => { setHoverIndex(null); onHover(null); }}
          onMouseOver={() => { setHoverIndex(index); onHover(index); }}
          onMouseOut={() => { setHoverIndex(null); onHover(null); }}
        >
          <span
            style={{
              display: 'inline-block',
              height,
              backgroundColor: oldColors.primary,
              width: `${Math.min((100 * value) / maximum, 100)}%`,
              borderRadius: height / 2,
            }}
          >
            {
              index === hoverIndex ? (
                <Typography
                  color={oldColors.neutral70}
                  fontWeight={500}
                  fontSize="12px"
                  style={{
                    display: 'inline',
                    position: 'relative',
                    bottom: '24px',
                    overflow: 'visible',
                    whiteSpace: 'nowrap',
                  }}>
                  {names[index]}
                </Typography>
              ) : (<>&nbsp;</>)
            }
          </span>
        </span>
      ))}
    </span>
  );
};

const GoalItem = ({ goal }: { goal: any }) => {
  const { colors } = useContextTheme();
  const { setUserContext } = useContext(UserContext);
  const { t } = useTranslation(['goal']);
  // const householdContext = useHouseholdContext();
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  function isValidSubAccount(subAccount: any) {
    const isActive = subAccount.state !== 'INACTIVE';
    return isActive;
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 20,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: colors.primary,
    },
  }));

  const numSubAccountsLinked = goal.subAccounts?.filter(isValidSubAccount).length ?? 0;

  /* For household views, split statistics by member */
  interface NameAndStats { name: string, statistics: any }
  let orderedStatisticsByMember: NameAndStats[] = [];
  const selectedStatistic = goal.statistics;
  const marketValue = selectedStatistic?.marketValueCents ?? 0;

  if (goal.householdClientGroup?.relationships && (goal as any).user0statistics) {
    orderedStatisticsByMember = goal.householdClientGroup.relationships.map((x: any, index: number) => (
      { name: x?.user?.firstName ?? '', statistics: (goal as any)[`user${index}statistics`] }
    ));
  }

  let shownMarketValue = marketValue;
  let shownSimpleReturnAmount = selectedStatistic?.simpleReturnAmount ?? 0;
  let shownSimpleReturnPercent = selectedStatistic?.simpleReturnPercent ?? 0;
  if (hoverIndex !== null) {
    const partialStatistics = orderedStatisticsByMember[hoverIndex].statistics;
    shownMarketValue = partialStatistics.marketValueCents ?? 0;
    shownSimpleReturnAmount = partialStatistics.simpleReturnAmount ?? 0;
    shownSimpleReturnPercent = partialStatistics.simpleReturnPercent ?? 0;
  }

  const targetValue = goal.targetAmountCents ?? 0;
  const percentageOfTarget = targetValue === 0 ? 0 : Math.min(marketValue / targetValue, 1.00);

  return (
    <Box
      onClick={() => navigate(setUserContext, '/goal', { goalId: goal.id, household: goal.householdClientGroup })}
      padding="16px"
      border={`1px solid ${colors.portfolioCardBorder}`}
      borderRadius="4px"
      sx={{
        cursor: 'pointer',
        transition: 'all 0.2s',
        background: colors.baseComponentBackground,
        '&:hover': {
          boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      {goal.householdClientGroup?.id && (
        <Box sx={{ position: 'relative', top: '-27px', height: 0 }}>
          <MemberTag sequenceNumber="HOUSEHOLD" name={goal.householdClientGroup.name} />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" marginBottom="15px">
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            <Typography fontWeight={600} fontSize="16px">{goal.name ? goal.name : t(`goal:resources.typeTitle.${goal.type}`)}</Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Typography fontWeight={600} fontSize="18px">{formatMoneyValue(shownMarketValue)}</Typography>
          <Typography color={(shownSimpleReturnAmount) >= 0 ? 'primary' : 'error'} fontWeight={500} fontSize="12px">
            {formatMoneyValue(shownSimpleReturnAmount)}
            {`(${formatPercentValue(shownSimpleReturnPercent)})`}
          </Typography>
        </Box>
      </Box>
      <Box>
        { Object.keys(orderedStatisticsByMember).length < 2 ? (
          <BorderLinearProgress
            variant="determinate"
            value={percentageOfTarget * 100}
          />
        ) : (
          <MultiLinearProgress
            maximum={targetValue}
            values={orderedStatisticsByMember.map((x) => x.statistics?.marketValueCents ?? 0)}
            names={orderedStatisticsByMember.map((x) => x.name)}
            onHover={setHoverIndex}
          />
        )}
        <Box display="flex" justifyContent="space-between" marginTop="5px">
          <Typography color={oldColors.neutral30} fontWeight={500} fontSize="12px">
            {t('item.subAccountsLinked', { count: numSubAccountsLinked })}
          </Typography>
          <Typography color={oldColors.neutral30} fontWeight={500} fontSize="12px">
            {`${formatPercentValue(percentageOfTarget, 0)} ${t('item.ofTargetAmount')}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GoalItem;
