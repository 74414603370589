import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import Divider from '@material-ui/core/Divider';
import { FormEvent, useContext } from 'react';
import OvForm from '../common/wrappers/ovForm';
import { Goal } from '../../utils/commonGraphql';
import { colors } from '../../theme/colors';
import { AccountType } from '../account/resources';
import { UserContext } from '../../providers/userContextProvider';
import { allAccountTypesVar } from '../../utils/localVariables';

export interface GoalRef {
  id?: string,
  name?: string,
  accountType?: AccountType,
}

interface Props {
  currentGoal?: Goal | GoalRef,
  onContinue?: () => void,
}

const ViewGoal = ({ onContinue, currentGoal }: Props): JSX.Element => {
  const { t } = useTranslation(['goal', 'account']);
  const { userContext } = useContext(UserContext);
  const handleClick = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    if (onContinue) onContinue();
  };
  const getAccountType = ():string => {
    const foundSubAccount = userContext.subAccounts && userContext.subAccounts.find((item) => item?.goal?.id === currentGoal?.id);
    if (foundSubAccount) {
      const foundType = allAccountTypesVar().find((item) => item.key === foundSubAccount.account?.type);
      return foundType?.title ?? '';
    }
    return '';
  };
  return (
    <OvForm hasButton loading={false} onSubmit={handleClick}>
      <Typography variant="heading2">{t('goal:viewGoal.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('goal:viewGoal.subTitle')}</Typography>
      <Box sx={{ backgroundColor: colors.neutral10 }} mb={4}>
        <Box display="flex" p={2}>
          <Box><Typography variant="heading4" fontWeight={500} marginRight={2}>{t('goal:viewGoal.goalText')}</Typography></Box>
          <Box><Typography variant="heading4" fontWeight={500}>{currentGoal?.name}</Typography></Box>
        </Box>
        <Divider style={{ backgroundColor: colors.neutral70 }} />
        <Box display="flex" p={2}>
          <Box>
            <Typography variant="heading4" fontWeight={500} marginRight={2}>{t('goal:viewGoal.accountText')}</Typography>
          </Box>
          <Box>
            <Typography variant="heading4" fontWeight={500}>
              {getAccountType()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </OvForm>
  );
};

ViewGoal.defaultProps = {
  onContinue: undefined,
  currentGoal: undefined,
};

export default ViewGoal;
