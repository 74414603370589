import {
  Typography,
  List,
  Box,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Markup } from 'interweave';
import { uniq } from 'lodash';
import { FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styles } from '../../pages/subAccountDetails/styles';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { formatDate, generateAddressString } from '../../utils/commonMethods';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { orgSupportUrlVar } from '../../utils/localVariables';
import SimpleInput from '../common/inputs/simpleInput';
import OvForm from '../common/wrappers/ovForm';
import { genderList } from '../user/resources';
import { AffiliateUser, Affiliation } from './createAccountAuxiliaryWizard';

export interface UserState {
  affiliation?: Affiliation,
}

interface Props {
  affiliateUserList: Array<AffiliateUser>,
  allowMultipleAffiliate?: boolean,
  isTFSAOrRRSPAccount?: boolean,
  goToNamedStep?: (step: string) => void,
  editField?: (index: number) => void,
  addNewBeneficiary?: () => Promise<void>,
  removeBeneficiary?: (user: AffiliateUser) => Promise<void>,
  addNewBeneficiaryonDone?: () => void,
  onContinue?: () => void,
  proceedToNextWizard?: () => void,
}

const BeneficiaryReview = ({ addNewBeneficiaryonDone, allowMultipleAffiliate, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const { colors } = useContextTheme();
  const { showToast } = useGlobalToast();

  const getAffiliationTypes = props.affiliateUserList.map((x) => x.affiliation?.type);
  const getAllocationSum = props.affiliateUserList.reduce((a, b) => a + (b.affiliation?.allocation ?? 0), 0);
  const availableAffiliationTypes = uniq(getAffiliationTypes);

  useEffect(() => {
    if (addNewBeneficiaryonDone) addNewBeneficiaryonDone();
    // eslint-disable-next-line
  }, []);

  const addBeneficiary = async (): Promise<void> => {
    sendAnalyticsEvent(ovAnalyticsEvents.reviewBeneficiaryPageAddBeneficiaryButtonSelect).then();
    if (props.addNewBeneficiary) await props.addNewBeneficiary();
    if (props.goToNamedStep) props.goToNamedStep('BeneficiaryName');
  };

  const removeBeneficiary = async (beneficiary: AffiliateUser): Promise<void> => {
    if (props.affiliateUserList.length <= 1) return;
    if (props.removeBeneficiary) props.removeBeneficiary(beneficiary);
  };

  const edit = async (index: number, stepName: string): Promise<void> => {
    if (props.editField) props.editField(index);
    if (props.goToNamedStep) props.goToNamedStep(stepName);
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();
    if (getAllocationSum > 100) {
      showToast({ message: t('toastMessages:allocationExceededError.h2'), severity: 'error', title: t('toastMessages:allocationExceededError.h1') });
      return;
    }
    sendAnalyticsEvent(ovAnalyticsEvents.reviewBeneficiaryPageContinueButtonSelect).then();
    if (props.proceedToNextWizard) {
      props.proceedToNextWizard();
      return;
    }
    if (props.onContinue) props.onContinue();
  };

  const endAdornment = (onClick: () => void): JSX.Element => (
    <InputAdornment position="end">
      <IconButton
        data-testid="edit-btn"
        aria-label="clear input field"
        disableRipple
        onClick={() => onClick()}
        edge="end"
      >
        <Typography variant="heading5" color={colors.success} style={{ marginBottom: 'px', textAlign: 'left', marginRight: '5px' }}>
          {t('beneficiary.review.button.edit')}
        </Typography>
      </IconButton>
    </InputAdornment>
  );

  return (
    <OvForm loading={false} onSubmit={onSubmit}>
      <Typography variant="heading2">{t(`beneficiary.review.${props.isTFSAOrRRSPAccount ? 'reviewYourAffiliates' : 'title'}`)}</Typography>
      {
        availableAffiliationTypes.map((affiliationType) => (
          <Box key={affiliationType}>
            <Typography variant="bodyMain1" color={colors.neutral20}>{t(`account:affiliates.types.${affiliationType}`)}</Typography>
            <List sx={{ maxHeight: '500px', overflowY: 'auto', marginBottom: '14px' }}>
              {props.affiliateUserList.filter((i) => i.affiliation?.type === affiliationType).map((item: AffiliateUser) => (
                <Box key={props.affiliateUserList.indexOf(item)}>
                  <Box display="flex" alignItems="center" sx={styles.detailBox} mb="14px">
                    <Typography variant="bodyMain1" color={colors.neutral20}>{item.firstName}</Typography>
                    <Box key={item.firstName}>
                      <Typography
                        style={styles.underline}
                        variant="captionSectionerBold"
                        onClick={() => removeBeneficiary(item)}
                        color={props.affiliateUserList.length <= 1 ? colors.disabledText : colors.link}>
                        {t('beneficiary.review.button.remove')}
                      </Typography>
                    </Box>
                  </Box>
                  <SimpleInput
                    testId="name-input"
                    label={t('beneficiary.review.legalName')}
                    value={`${item.firstName} ${item.lastName}`}
                    inputProps={{ readOnly: true }}
                    suffixAdornment={endAdornment(() => { edit(props.affiliateUserList.indexOf(item), 'BeneficiaryName'); })}
                  />
                  <SimpleInput
                    testId="relationship-input"
                    label={t('beneficiary.review.relationship')}
                    value={t(`account:affiliationRelations.${item.affiliation?.relation}`)}
                    inputProps={{ readOnly: true }}
                    suffixAdornment={endAdornment(() => { edit(props.affiliateUserList.indexOf(item), 'BeneficiaryRelationsip'); })}
                  />
                  {
                    item.affiliation?.allocation && (
                      <SimpleInput
                        testId="allocation-input"
                        label={t('beneficiary.review.allocation')}
                        value={item.affiliation?.allocation?.toString() ?? ''}
                        type="number"
                        inputProps={{ readOnly: true }}
                        suffixAdornment={endAdornment(() => { edit(props.affiliateUserList.indexOf(item), 'Allocation'); })}
                      />
                    )
                  }
                  <SimpleInput
                    testId="dob-input"
                    label={t('beneficiary.review.dateOfBirth')}
                    value={item.dateOfBirth ? formatDate(item.dateOfBirth) : ''}
                    inputProps={{ readOnly: true }}
                    suffixAdornment={endAdornment(() => { edit(props.affiliateUserList.indexOf(item), 'DateOfBirth'); })}
                  />
                  <SimpleInput
                    testId="gender-input"
                    label={t('beneficiary.review.gender')}
                    value={genderList.find((x) => x.value === item.gender)?.name}
                    inputProps={{ readOnly: true }}
                    suffixAdornment={endAdornment(() => { edit(props.affiliateUserList.indexOf(item), 'Gender'); })}
                  />
                  <SimpleInput
                    testId="address-input"
                    label={t('beneficiary.review.address')}
                    value={item.physicalAddress ? generateAddressString(item.physicalAddress) : ''}
                    inputProps={{ readOnly: true }}
                    suffixAdornment={endAdornment(() => { edit(props.affiliateUserList.indexOf(item), 'physicalAddress'); })}
                  />
                  {
                    item.inProvinceSince && (
                      <SimpleInput
                        testId="in-province-since-input"
                        label={t('beneficiary.review.inProvinceSince')}
                        value={item.inProvinceSince ? formatDate(item.inProvinceSince) : ''}
                        inputProps={{ readOnly: true }}
                        suffixAdornment={endAdornment(() => { edit(props.affiliateUserList.indexOf(item), 'BeneficiaryInProvinceSince'); })}
                      />
                    )
                  }
                  <Box mb={2} />
                </Box>
              ))}
            </List>
          </Box>
        ))
      }
      {!props.isTFSAOrRRSPAccount && (
        <Typography variant="captionSectionerMedium" data-testid="disclaimer-2" color={colors.neutral30} whiteSpace="pre-wrap" style={{ marginBottom: 32 }}>
          <Markup content={allowMultipleAffiliate ? t('beneficiary.review.disclaimer2', { link: colors.link, linkUrl: orgSupportUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us/requests/new' }) : t('beneficiary.review.disclaimer')} />
        </Typography>
      )}
      {props.isTFSAOrRRSPAccount && (
        <Typography variant="captionSectionerMedium" data-testid="disclaimer-3" color={colors.neutral30} whiteSpace="pre-wrap" style={{ marginBottom: 32 }}>{t('beneficiary.review.disclaimer3')}</Typography>
      )}
      <Button variant="secondary-large" disabled={allowMultipleAffiliate ? false : props.affiliateUserList.length >= 3} data-testid="cancel-button" onClick={addBeneficiary} style={{ marginBottom: 16 }}>{t(`beneficiary.review.button.${props.isTFSAOrRRSPAccount ? 'addAffiliate' : 'addBeneficiary'}`)}</Button>
    </OvForm>
  );
};

BeneficiaryReview.defaultProps = {
  goToNamedStep: undefined,
  allowMultipleAffiliate: undefined,
  onContinue: undefined,
  editField: undefined,
  addNewBeneficiary: undefined,
  removeBeneficiary: undefined,
  isTFSAOrRRSPAccount: false,
  addNewBeneficiaryonDone: undefined,
  proceedToNextWizard: undefined,
};

export default BeneficiaryReview;
