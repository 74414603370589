import {
  Box,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FlowModal from '../wrappers/modals/ovFlowModal';

interface Props {
  open: boolean,
  onClose: () => void,
  onButtonClick: () => void,
}

const BlockSignUpModal = ({
  open,
  onClose,
  onButtonClick,
}: Props): JSX.Element | null => {
  const { t } = useTranslation(['signUp']);
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  if (open) {
    return (
      <FlowModal
        open={open}
        onClose={onClose}
        showCloseButton
        component={(
          <Box sx={{ width: isMobileScreen ? '100%' : '450px' }}>
            <Typography data-testid="dialog-title" variant="heading3">{t('blockClientSignUpDialog.title')}</Typography>
            <Typography data-testid="dialog-description" variant="paragraph3">
              {t('blockClientSignUpDialog.description')}
            </Typography>
            <Button variant="primary-large" data-testid="dialog-continue-button" onClick={onButtonClick}>{t('blockClientSignUpDialog.buttonText')}</Button>
          </Box>
        )}
      />
    );
  }
  return null;
};

export default BlockSignUpModal;
