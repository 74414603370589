import React, { useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useTranslation } from 'react-i18next';
import WithStepWizard from '../../../common/wrappers/withStepWizard';
import AddInstitution from './addInstitution';
import Address from './address';
import { AddressCountries, AddressProvinces } from '../../../user/address/resources';
import ViewInstitution from './viewInstitutions';
import TrackingBar from '../../../common/headers/trackingBar';

const AddInstitutionStep = WithStepWizard(AddInstitution);
const AddressStep = WithStepWizard(Address);
const ViewInstitutionStep = WithStepWizard(ViewInstitution);

interface Institution {
  id: string,
  name: string,
  physicalAddress?: undefined,
}

export interface Props {
  onCreated: (name: string, physicalAddress: PhysicalAddress) => void,
  onGoBack: () => void,
  onInstitutionSelected: (institution: Institution) => void,
}

export interface PhysicalAddress {
  unitNumber?: string,
  houseNumber: string,
  streetName: string,
  neighborhood?: string,
  city: string,
  province: AddressProvinces,
  country: AddressCountries,
  postal: string,
}

const AddInstitutionWizard = ({ onInstitutionSelected, onCreated, onGoBack }: Props): JSX.Element => {
  const { t } = useTranslation(['deposit']);
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const [bankName, updateBankName] = useState('');
  const [addressDescription, updateAddressDescription] = useState('');
  const [physicalAddress, updatePhysicalAddress] = useState<PhysicalAddress>({
    unitNumber: '',
    houseNumber: '',
    streetName: '',
    neighborhood: '',
    city: '',
    province: AddressProvinces.AB,
    country: AddressCountries.CA,
    postal: '',
  });

  const steps: JSX.Element[] = [
    <ViewInstitutionStep key="view-institutions" stepTitle={t('deposit:stepTitles.viewIstitutions')} onInstitutionSelected={onInstitutionSelected} goBackAction={onGoBack} />,
    <AddInstitutionStep
      stepTitle={t('deposit:stepTitles.addInstitution')}
      key="add-institution"
      stepName="add-institution"
      updatePhysicalAddress={updatePhysicalAddress}
      onAdd={() => onCreated(bankName, physicalAddress)}
      addressDescription={addressDescription}
      updateAddressDescription={updateAddressDescription}
      bankName={bankName}
      updateBankName={updateBankName}
      goBack={onGoBack}
    />,
    <AddressStep key="address" stepTitle={t('deposit:stepTitles.addAddress')} physicalAddress={physicalAddress} goToNamedStep={() => 'add-institution'} updatePhysicalAddress={updatePhysicalAddress} />,
  ];

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      className="addInstitutionStepWizard"
      transitions={{}}
      nav={(
        <TrackingBar steps={steps} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

export default AddInstitutionWizard;
