/* eslint-disable  no-nested-ternary */

import * as React from 'react';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import RoundedCheckbox from '../../common/controllers/roundedCheckbox';

interface GetToKnowYouItemProps {
  prefixIcon: string,
  header: string,
  subTitle: string,
  checked?: boolean,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
}

const GetToKnowYouItem = ({ ...props }: GetToKnowYouItemProps): JSX.Element => {
  const matches = useMediaQuery('(max-width:600px)');
  const descriptioDivColumns = matches ? 10 : 11;
  const svgDivColumns = matches ? 2 : 1;
  return (
    <Box
      display="flex"
      style={{
        cursor: props.checked ? 'pointer' : 'default',
        marginBottom: '25px',
        height: '65px',
        opacity: props.checked ? '0.4' : '1',
      }}
      onClick={props.onClick}
    >
      <Box display="flex">
        <img src={props.prefixIcon} alt="icon" loading="eager" width={48} style={{ margin: 8 }} />
      </Box>
      <Box display="flex" flexDirection="column" ml={2}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 3, md: 3 }}>
          <Grid item xs={props.checked ? descriptioDivColumns : 12} style={{ maxWidth: props.checked ? '375px' : '100%' }}>
            <Box>
              <Typography variant="heading4" mb={0.5}>{props.header}</Typography>
              <Typography variant="paragraph2">{props.subTitle}</Typography>
            </Box>
          </Grid>
          {props.checked ? (
            <Grid item xs={svgDivColumns}>
              <Box sx={{ textAlign: 'center' }}>
                <RoundedCheckbox checked={props.checked} indeterminate={!props.checked} />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};

GetToKnowYouItem.defaultProps = {
  onClick: null,
  checked: false,
};

export default GetToKnowYouItem;
