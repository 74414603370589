import sumBy from 'lodash/sumBy';
import { translateBackend } from '../../assets/i18n/config';
import { Colors } from '../../providers/contextThemeProvider';
import AlternativeInvestmentStrategiesIcon from '../../assets/images/assetClass/alternative-investment-strategies.svg';
import CashAndEquivalentIcon from '../../assets/images/assetClass/cash-and-equivalents.svg';
import DigitalAssetsIcon from '../../assets/images/assetClass/digital-assets.svg';
import EquitiesIcon from '../../assets/images/assetClass/equities.svg';
import FixedIncomeIcon from '../../assets/images/assetClass/fixed-income.svg';
import RealAssetsIcon from '../../assets/images/assetClass/real-assets.svg';
import TotalIcon from '../../assets/images/assetClass/total.svg';

export interface HoldingAttributes {
  key: string,
  hexColor: string,
  secondaryAssetClassName: string,
  secondaryAssetClassDescription: string,
  percentage: number,
  totalCents?: number,
}
export const getAssetBarIndeterminateColor = (index: number):string => {
  const arrayOfAshColors = [
    '#EEF1F4',
    '#E5E8EB',
    '#DBDEE0',
    '#D1D4D6',
    '#C7CACC',
  ];
  return arrayOfAshColors[index];
};
export const getPrimaryAssetImage = (key: string): string => {
  switch (key) {
    case 'ALTERNATIVE_INVESTMENT_STRATEGIES': {
      return AlternativeInvestmentStrategiesIcon;
    }
    case 'PUBLIC_EQUITIES': {
      return EquitiesIcon;
    }
    case 'REAL_ASSETS': {
      return RealAssetsIcon;
    }
    case 'FIXED_INCOME': {
      return FixedIncomeIcon;
    }
    case 'DIGITAL_ASSETS': {
      return DigitalAssetsIcon;
    }
    case 'CASH_AND_EQUIVALENTS': {
      return CashAndEquivalentIcon;
    }
    default: {
      return TotalIcon;
    }
  }
};

export const defaultGraphColors = (colors: Colors): string[] => ([
  colors.holdingsGraph[1],
  colors.holdingsGraph[2],
  colors.holdingsGraph[3],
  colors.holdingsGraph[4],
  colors.holdingsGraph[5],
  colors.holdingsGraph[6],
  colors.holdingsGraph[7],
  colors.holdingsGraph[8],
  colors.holdingsGraph[9],
  colors.holdingsGraph[10],
  colors.holdingsGraph[11],
  colors.holdingsGraph[12],
  colors.holdingsGraph[13],
  colors.holdingsGraph[14],
  colors.holdingsGraph[15],
  colors.holdingsGraph[16],
  colors.holdingsGraph[17],
  colors.holdingsGraph[18],
  colors.holdingsGraph[19],
  colors.holdingsGraph[20],
  colors.holdingsGraph[21],
]);
export interface FinancialProduct {
  id: number,
  name: number,
  ticker: string,
  translatedName: {
    en: string,
    fr: string,
  },
  translatedPortfolioDescription: {
    en: string,
    fr: string,
  }
}
export interface ExpectedSecurityHoldings {
  percentage: number,
  financialProduct: FinancialProduct,
}
export interface ExpectedSecondaryAssetClassHoldings {
  assetClass: AssetClass
  percentage: number,
  expectedSecurityHoldings: ExpectedSecurityHoldings[],
}
export interface ExpectedPrimaryAssetClassHoldings {
  assetClass: AssetClass,
  percentage: number,
  expectedSecondaryAssetClassHoldings: ExpectedSecondaryAssetClassHoldings[],
}
export interface SecurityHoldings {
  percentage: number,
  totalCents: number,
  quantity: number,
  financialProduct: FinancialProduct,
}
export interface SecondaryAssetClassHoldings {
  assetClass: AssetClass
  percentage: number,
  totalCents: number,
  securityHoldings: SecurityHoldings[],
}
export interface PrimaryAssetClassHoldings {
  assetClass: AssetClass,
  percentage: number,
  totalCents: number,
  secondaryAssetClassHoldings: SecondaryAssetClassHoldings[],
  color?: string,
}
export interface AssetClass {
  key: string,
  translatedName: {
    en: string,
    fr: string,
  },
  translatedDescription: {
    en: string,
    fr: string,
  },
}

export interface HoldingResponse {
  financialProduct: {
    id: number,
    name: number,
    ticker: string,
    translatedName: { en: string, fr: string, },
    translatedPortfolioDescription: { en: string, fr: string, }
    primaryAssetClass: AssetClass,
    secondaryAssetClass: AssetClass,
  },
  percentage?: number,
  totalCents?: number,
  quantity?: number,
}
const calculatePercentage = ({
  totalCents,
  assetClassTotalCents,
  assetClassPercentage,
}: { totalCents: number, assetClassTotalCents?: number, assetClassPercentage?: number }): number => {
  if (assetClassPercentage) return assetClassPercentage;
  return (assetClassTotalCents && totalCents > 0) ? (assetClassTotalCents / totalCents) * 100 : 0;
};

export const mapHoldingAttributes = (holdings: HoldingResponse[], colors: Colors): HoldingAttributes[] => {
  const totalCents = sumBy(holdings, (h) => h?.totalCents ?? 0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const holdingsMap = holdings.reduce((r: any, h: HoldingResponse) => {
    const assetClassKey = h.financialProduct.secondaryAssetClass.key;
    const assetClass = r[assetClassKey];
    if (assetClass) {
      assetClass.percentage += calculatePercentage({
        totalCents,
        assetClassTotalCents: h.totalCents,
        assetClassPercentage: h.percentage,
      });
      assetClass.totalCents += h.totalCents;
    } else {
      // eslint-disable-next-line no-param-reassign
      r[assetClassKey] = {
        secondaryAssetClassName: translateBackend(h.financialProduct.secondaryAssetClass.translatedName),
        secondaryAssetClassDescription: translateBackend(h.financialProduct.secondaryAssetClass.translatedDescription),
        percentage: calculatePercentage({
          totalCents,
          assetClassTotalCents: h.totalCents,
          assetClassPercentage: h.percentage,
        }),
        totalCents: h.totalCents ?? 0,
      };
    }
    return r;
  }, {});
  const sortedHoldings = Object.keys(holdingsMap).map((k) => ({ key: k, ...holdingsMap[k] })).sort((a, b) => b.percentage - a.percentage);
  const graphColors = defaultGraphColors(colors);
  return sortedHoldings.filter(
    (h) => h.percentage > 0,
  ).map((h, i) => ({ ...h, hexColor: graphColors[i], percentage: Number(h.percentage.toFixed(2)) }));
};

export const calculatePrimaryAssetClassHoldings = (
  holdings: HoldingResponse[],
): PrimaryAssetClassHoldings[] => {
  const primaryAssetClassHoldings = holdings.reduce((result: PrimaryAssetClassHoldings[], current: HoldingResponse) => {
    const index = result.findIndex(
      (a) => a.assetClass.key === current.financialProduct.primaryAssetClass.key,
    );
    if (index === -1) {
      result.push(<PrimaryAssetClassHoldings><unknown>{
        assetClass: { ...current.financialProduct.primaryAssetClass },
        percentage: current.percentage,
        totalCents: 0,
        secondaryAssetClassHoldings: [{
          assetClass: { ...current.financialProduct.secondaryAssetClass },
          percentage: current.percentage,
          totalCents: 0,
          securityHoldings: [
            {
              financialProduct: { ...current.financialProduct },
              percentage: current.percentage,
              totalCents: 0,
              quantity: current.quantity ?? 0,
            },
          ],
        }],
      });
    } else {
      const { secondaryAssetClassHoldings } = result[index];
      const assetClassIndex = secondaryAssetClassHoldings.findIndex(
        (a: SecondaryAssetClassHoldings) => a.assetClass.key === current.financialProduct.primaryAssetClass.key,
      );
      if (assetClassIndex === -1) {
        secondaryAssetClassHoldings.push(<SecondaryAssetClassHoldings><unknown>{
          assetClass: { ...current.financialProduct.secondaryAssetClass },
          percentage: current.percentage,
          totalCents: 0,
          securityHoldings: [{
            financialProduct: { ...current.financialProduct },
            percentage: current.percentage,
            totalCents: 0,
            quantity: current.quantity ?? 0,
          }],
        });
      } else {
        const { securityHoldings } = secondaryAssetClassHoldings[assetClassIndex];
        const securityHoldingsIndex = securityHoldings.findIndex(
          (a: SecurityHoldings) => a.financialProduct.id === current.financialProduct.id,
        );
        if (securityHoldingsIndex === -1) {
          securityHoldings.push({
            financialProduct: { ...current.financialProduct },
            percentage: current.percentage ?? 0,
            totalCents: current.totalCents ?? 0,
            quantity: current.quantity ?? 0,
          });
        } else {
          securityHoldings[securityHoldingsIndex] = {
            ...securityHoldings[securityHoldingsIndex],
            percentage: securityHoldings[securityHoldingsIndex].percentage + (current.percentage ?? 0),
            totalCents: (current.totalCents ?? 0) + securityHoldings[securityHoldingsIndex].totalCents,
            quantity: (current.quantity ?? 0) + securityHoldings[securityHoldingsIndex].quantity,
          };
        }

        secondaryAssetClassHoldings[assetClassIndex] = {
          ...secondaryAssetClassHoldings[assetClassIndex],
          percentage: (current.percentage ?? 0) + secondaryAssetClassHoldings[assetClassIndex].percentage,
          totalCents: (current.totalCents ?? 0) + secondaryAssetClassHoldings[assetClassIndex].totalCents,
          securityHoldings,
        };
      }
      /* eslint no-param-reassign: "error" */
      result[index] = {
        ...result[index],
        percentage: (current.percentage ?? 0) + result[index].percentage,
        totalCents: (current.totalCents ?? 0) + result[index].totalCents,
        secondaryAssetClassHoldings,
      };
    }
    return result;
  }, []);

  return primaryAssetClassHoldings;
};
