import {
  IconButton, Modal, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import * as React from 'react';
import { FormEvent, useContext, useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StaticDatePicker } from '@mui/lab';
import dayjs from 'dayjs';
import { gql, useMutation, useQuery } from '@apollo/client';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getLocale } from '../../assets/i18n/config';
import DropdownInput from '../common/inputs/dropdownInput';
import OvForm from '../common/wrappers/ovForm';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { InputType } from '../common/inputs/utils/inputType';
import SimpleInput from '../common/inputs/simpleInput';
import { FETCH_MY_BANK_ACCOUNTS } from '../transfers/graphql';
import Flinks from '../flinks/flinks';
import { UserContext } from '../../providers/userContextProvider';
import { ScheduledIncomeFundTransferProp } from '../paymentInstructions/editPaymentInstructionWizard';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import informationIcon from '../../assets/images/information.svg';
import { showInformationDialog } from '../../utils/ui/informationDialog';
import { FeatureFlagTypes } from '../account/resources';

interface Props {
  paymentInstruction?: ScheduledIncomeFundTransferProp,
  onContinue?: () => void,
  width?: string,
  account: {
    id: string,
    type: string,
  },
}

export const UPDATE_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation updateScheduledIncomeFundTransfer($input: UpdateScheduledIncomeFundTransferInput!) {
    updateScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer {
        id
      }
    }
  }
`;

export const CREATE_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation createScheduledIncomeFundTransfer($input: CreateScheduledIncomeFundTransferInput!) {
    createScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer {
        id
      }
    }
  }
`;

export const FETCH_RRIF_ACCOUNT_DETAIL = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        affiliations {
          user {
            firstName
            lastName
            dateOfBirth    
          }
          type
          relation
        }
        subAccounts {
            id
        }
      }
    }
  }
`;

export const FETCH_USER_DOB = gql`
  query BasicMe {
    me {
      user {
        dateOfBirth
      }
    }
  }
`;

const AnnualPaymentInstructions = ({
  onContinue, width, paymentInstruction, account,
}: Props): JSX.Element => {
  const defineAnnualPayment = (): string => {
    if (paymentInstruction) {
      if (paymentInstruction.annualAmountCents !== undefined && paymentInstruction.annualAmountCents !== null) return 'Custom';
      return 'Minimum';
    }
    return '';
  };
  const matches = useMediaQuery('(max-width:600px)');
  const nextDay = new Date(`01-01-${(new Date().getFullYear() + 1)}`);
  const { userContext, setUserContext } = useContext(UserContext);
  const { t } = useTranslation(['base', 'account']);
  const translatedAccountType = t(`account:resources.typeTitle.${account.type}`);
  const [callingApi, setCallingApi] = useState(false);
  const [annualPayment, setAnnualPayment] = useState(defineAnnualPayment());
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState<{ open: boolean, header: string, body: string, }>({
    open: false,
    header: '',
    body: '',
  });
  const [selectedBankAccount, setSelectedBankAccount] = useState(paymentInstruction ? paymentInstruction.bankAccount?.id : '');
  const [tempDate, setTempDate] = useState(paymentInstruction ? paymentInstruction.scheduledDate : '');
  const [scheduledDate, setScheduledDate] = useState(dayjs(paymentInstruction ? paymentInstruction.scheduledDate : nextDay).format('YYYY-MM-DD'));
  const [showFlinks, setShowFlinks] = useState(false);
  const [amount, setAmount] = useState<string|undefined>(paymentInstruction && paymentInstruction.annualAmountCents ? paymentInstruction.annualAmountCents.toString() : '');
  const [amountType, setAmountType] = useState<string|undefined>(paymentInstruction ? paymentInstruction.amountPayableType : '');
  const [annualPaymentSchedule, setAnnualPaymentSchedule] = useState(paymentInstruction ? paymentInstruction.frequency : '');
  const [age, setAge] = useState('');
  const closeInformationModal = (status: boolean): void => setOpenInfoModal((prevState) => ({ ...prevState, open: status }));

  const showTypeOfAmountInformationModal = ():void => setOpenInfoModal({
    open: true,
    header: t('account:rrifSteps.fieldInformation.title'),
    body: t('account:rrifSteps.fieldInformation.typeOfAmountBody'),
  });

  const fetchingUserDOB = useQuery(FETCH_USER_DOB, {
    variables: {},
  });

  const userIsMarriedOrCommonLaw: boolean = userContext.maritalStatus === 'MARRIED' || userContext.maritalStatus === 'COMMON_LAW';
  const confirmDate = (): void => {
    setScheduledDate(tempDate);
    setOpenCalendar(false);
  };

  const fetchRrifObject = useQuery(FETCH_RRIF_ACCOUNT_DETAIL, {
    variables: {
      accountId: account.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (e) => {
      let value = '';
      if (fetchRrifObject && fetchRrifObject.loading) value = '';
      if (userIsMarriedOrCommonLaw && paymentInstruction && paymentInstruction.dateOfBirth === e.fetchAccount.account.affiliations[0].user.dateOfBirth) value = 'SPOUSE_AGE';
      if (paymentInstruction && paymentInstruction.dateOfBirth === (userContext.dateOfBirth ?? fetchingUserDOB.data?.me?.user?.dateOfBirth)) value = 'MY_AGE';
      setAge(value);
    },
  });

  const decideAgeToSendToServer = (): Date|undefined => {
    if (fetchRrifObject.loading || fetchingUserDOB.loading) {
      return undefined;
    }
    if (age === 'SPOUSE_AGE') {
      const spouseDateOfBirth = fetchRrifObject.data?.fetchAccount?.account?.affiliations[0]?.user?.dateOfBirth;
      if (spouseDateOfBirth) return spouseDateOfBirth;
    }
    return fetchingUserDOB.data.me.user.dateOfBirth;
  };

  const getSubAccountId = (): string => {
    if (fetchRrifObject.loading) {
      return '';
    }
    if (paymentInstruction) return paymentInstruction.subAccount.id;
    return fetchRrifObject.data.fetchAccount.account.subAccounts[0].id;
  };

  const activateRefreshOfComponents = () => {
    setUserContext({
      ...userContext,
      checkRrifInformationStep: {
        accountId: userContext.checkRrifInformationStep?.accountId,
        open: userContext.checkRrifInformationStep?.open ?? false,
        refreshComponent: !userContext.checkRrifInformationStep?.refreshComponent,
      },
    });
  };

  const [updateScheduledIncomeFundTransfer] = useMutation(UPDATE_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        // dateOfBirth: decideAgeToSendToServer(),
        scheduledIncomeFundTransferId: paymentInstruction?.id,
        subAccountId: getSubAccountId(),
        bankAccountId: selectedBankAccount,
        frequency: annualPaymentSchedule,
        scheduledDate,
        annualAmountCents: amount,
        amountPayableType: amountType,
        taxOption: 'NO_TAX_ON_MINIMUM',
        transferredAmountCents: 0,
      },
    },
    onCompleted: () => {
      setCallingApi(false);
      activateRefreshOfComponents();
      if (onContinue) onContinue();
    },
  });

  const [createScheduledIncomeFundTransfer] = useMutation(CREATE_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: {
        dateOfBirth: decideAgeToSendToServer(),
        subAccountId: getSubAccountId(),
        bankAccountId: selectedBankAccount,
        frequency: annualPaymentSchedule,
        scheduledDate,
        annualAmountCents: amount,
        amountPayableType: amountType,
        taxOption: 'NO_TAX_ON_MINIMUM',
        transferredAmountCents: 0,
      },
    },
    onCompleted: () => {
      setCallingApi(false);
      activateRefreshOfComponents();
      if (onContinue) onContinue();
    },
  });

  const { data, refetch, loading } = useQuery(FETCH_MY_BANK_ACCOUNTS, {
    variables: {},
  });

  const getAge = (userDOB?: Date): string => {
    if (!userDOB) return '0';
    const today = new Date();
    const birthDate = new Date(userDOB);
    let myAge = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      myAge -= 1;
    }
    return myAge.toString();
  };

  const getSpousesAge = (): string => {
    if (fetchRrifObject.loading) {
      return 'Fetching';
    }
    const { firstName } = fetchRrifObject.data.fetchAccount.account.affiliations[0].user;
    const dob = fetchRrifObject.data.fetchAccount.account.affiliations[0].user.dateOfBirth;
    return t('account:rrifSteps.rrifAnnualPaymentInstruction.ageOptions.spouseAge', { firstName: firstName.trim(), age: getAge(dob) });
  };

  const submitForm = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setCallingApi(true);
    sendAnalyticsEvent(ovAnalyticsEvents.rrifAnnualPaymentInstructionsPageContinueButtonSelect).then();
    if (paymentInstruction) {
      updateScheduledIncomeFundTransfer().then();
    } else {
      createScheduledIncomeFundTransfer().then();
    }
  };

  if (fetchingUserDOB.loading || fetchRrifObject.loading || loading) {
    return (<OvLoadingIndicator />);
  }

  if (openInfoModal.open) {
    return showInformationDialog({
      open: openInfoModal.open,
      header: openInfoModal.header,
      body: openInfoModal.body,
      buttonText: t('account:rrifSteps.fieldInformation.confirmationButton'),
      onClose: (value) => closeInformationModal(value),
    });
  }
  const availableFeatureFlags = userContext?.availableFeatureFlags || [];
  const removeAddbankAccountOption = !availableFeatureFlags?.includes(FeatureFlagTypes.AUTOMATED_BANK_ACCOUNT_LINKING)
    && !availableFeatureFlags?.includes(FeatureFlagTypes.MANUALLY_ADD_BANK_ACCOUNT);
  const disableForm = ():boolean => !age || !annualPaymentSchedule || !annualPayment || !scheduledDate || !selectedBankAccount || (annualPayment === 'Custom' && (!amount || amount === '0' || !amountType));
  return (
    <Box width={matches ? 'fit-content' : width ?? 415} boxSizing="border-box" py="18px">
      <OvForm onSubmit={(e) => submitForm(e)} loading={callingApi} disableButton={disableForm()}>
        <div style={{
          display: 'flex', flexDirection: 'column', height: '100%',
        }}>
          <div style={{ flex: '1 0 auto' }}>
            <Typography variant="heading2" style={{ marginBottom: 16 }}>{t('account:rrifSteps.rrifAnnualPaymentInstruction.title', { accountType: translatedAccountType })}</Typography>
            <Typography variant="bodySub2" style={{ marginBottom: 16 }}>{t('account:rrifSteps.rrifAnnualPaymentInstruction.subTitle')}</Typography>
            <Box mt={2} />
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('account:rrifSteps.rrifAnnualPaymentInstruction.ageLabel')}</Typography>
            <DropdownInput
              dataTestId="age-dropdown"
              onChange={(e) => {
                setAge(e.target.value);
              }}
              options={userIsMarriedOrCommonLaw || account.type === 'RIF_SPOUSAL' ? [
                { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.ageOptions.myAge', { age: getAge(fetchingUserDOB.loading ? undefined : fetchingUserDOB.data.me.user.dateOfBirth) }), value: 'MY_AGE', imageUrl: '' },
                { text: getSpousesAge(), value: 'SPOUSE_AGE', imageUrl: '' },
              ] : [
                { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.ageOptions.myAge', { age: getAge(fetchingUserDOB.loading ? undefined : fetchingUserDOB.data.me.user.dateOfBirth) }), value: 'MY_AGE', imageUrl: '' },
              ]}
              value={age ?? ''}
            />
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('account:rrifSteps.rrifAnnualPaymentInstruction.annualPaymentAmountLabel')}</Typography>
            <DropdownInput
              dataTestId="annual-payment-dropdown"
              onChange={(e) => {
                if (e.target.value === 'Minimum') {
                  setAmount(undefined);
                  setAmountType(undefined);
                }
                setAnnualPayment(e.target.value);
              }}
              options={[
                { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.annualPaymentDropdown.minimumAnnualPayment'), value: 'Minimum', imageUrl: '' },
                { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.annualPaymentDropdown.custom'), value: 'Custom', imageUrl: '' },
              ]}
              value={annualPayment ?? ''}
            />
            {
              annualPayment === 'Custom' && (
                <>
                  <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('account:rrifSteps.rrifAnnualPaymentInstruction.amountLabel')}</Typography>
                  <SimpleInput
                    testId="amount"
                    style={{ height: '50px', marginTop: '-12px' }}
                    value={amount ? (Number(amount) / 100).toString() : ''}
                    onChange={(e) => {
                      setAmount((Number(e.target.value) * 100).toString());
                    }}
                    inputType={InputType.CURRENCY}
                    onClear={() => setAmount('')}
                    label=""
                  />
                </>
              )
            }
            {
              annualPayment === 'Custom' && (
                <>
                  <Box mt={4} display="flex">
                    <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginRight: '10px' }}>{t('account:rrifSteps.rrifAnnualPaymentInstruction.amountTypeLabel')}</Typography>
                    <IconButton style={{ margin: '-4px 0px 0px 0px', padding: '0px' }} onClick={() => showTypeOfAmountInformationModal()}>
                      <img
                        src={informationIcon}
                        style={{ marginTop: '2px' }}
                        alt="information-icon"
                      />
                    </IconButton>
                  </Box>
                  <DropdownInput
                    dataTestId="amount-type-dropdown"
                    onChange={(e) => {
                      setAmountType(e.target.value);
                    }}
                    options={[
                      { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.amountTypeDropdown.gross'), value: 'GROSS', imageUrl: '' },
                      { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.amountTypeDropdown.net'), value: 'NET', imageUrl: '' },
                    ]}
                    value={amountType ?? ''}
                  />
                </>
              )
            }
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('account:rrifSteps.rrifAnnualPaymentInstruction.paymentScheduleLabel')}</Typography>
            <DropdownInput
              dataTestId="annual-payment-dropdown"
              onChange={(e) => {
                setAnnualPaymentSchedule(e.target.value);
              }}
              options={[
                { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.paymentSchedule.annually'), value: 'ANNUALLY', imageUrl: '' },
                { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.paymentSchedule.semiAnnually'), value: 'SEMI_ANNUALLY', imageUrl: '' },
                { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.paymentSchedule.quarterly'), value: 'QUARTERLY', imageUrl: '' },
                { text: t('account:rrifSteps.rrifAnnualPaymentInstruction.paymentSchedule.monthly'), value: 'MONTHLY', imageUrl: '' },
              ]}
              value={annualPaymentSchedule ?? ''}
            />
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('account:rrifSteps.rrifAnnualPaymentInstruction.startingLabel')}</Typography>
            <SimpleInput
              testId="date"
              style={{ height: '50px', marginTop: '-12px' }}
              value={scheduledDate}
              inputType={InputType.NAME}
              inputProps={{
                onClick: () => setOpenCalendar(true),
              }}
              onClear={() => setScheduledDate('')}
              label=""
            />
            <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('account:rrifSteps.rrifAnnualPaymentInstruction.toLabel')}</Typography>
            <DropdownInput
              dataTestId="to-dropdown"
              onChange={(e) => {
                setSelectedBankAccount(e.target.value);
              }}
              onItemClicked={() => {
                sendAnalyticsEvent(ovAnalyticsEvents.investPageBankAccountSelect).then();
              }}
              options={loading ? [] : data.me.user.bankAccounts.map((bankAccount: {name: string, bankAccountNumber: string, id: string }) => (
                { imageUrl: '', text: `${bankAccount.name} ${bankAccount.bankAccountNumber}`, value: bankAccount.id }
              ))}
              value={selectedBankAccount}
              suffixLink={!removeAddbankAccountOption ? {
                text: t('transfer:flinksAddBankAccount'),
                onChange: () => {
                  setShowFlinks(true);
                },
              } : undefined}
            />
            <Typography variant="captionSectionerMedium" textAlign="center" style={{ marginBottom: 32, marginTop: 16 }}>
              {t('account:rrifSteps.rrifAnnualPaymentInstruction.note')}
            </Typography>
          </div>
        </div>
        <Modal
          open={openCalendar}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClose={() => {
            setOpenCalendar(false);
          }}
        >
          <Box display="flex" flexDirection="column" position="relative">
            <LocalizationProvider locale={getLocale()} dateAdapter={AdapterDateFns}>
              <StaticDatePicker
                label="yyyy-mm-dd"
                open
                displayStaticWrapperAs="mobile"
                value={Date.parse(dayjs(scheduledDate).local().format('YYYY-MM-DD'))}
                minDate={nextDay}
                onChange={(newValue) => {
                  setTempDate(dayjs(newValue).utc().format('YYYY-MM-DD'));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ marginBottom: '32px' }}
                    variant="standard"
                    {...params}
                    fullWidth
                  />
                )}
                mask="____-__-__"
              />
            </LocalizationProvider>
            <Box position="absolute" right="35px" bottom="35px" display="flex">
              <Typography variant="captionSectionerMedium" onClick={() => setOpenCalendar(false)} style={{ marginBottom: 0, marginRight: '15px', cursor: 'pointer' }}>{t('transfer:bankAccountDeposit.calendar.cancel')}</Typography>
              <Typography variant="captionSectionerMedium" onClick={() => confirmDate()} style={{ marginBottom: 0, cursor: 'pointer' }}>{t('transfer:bankAccountDeposit.calendar.confirm')}</Typography>
            </Box>
          </Box>
        </Modal>
        <Flinks
          onClose={() => setShowFlinks(false)}
          open={showFlinks}
          bankAccounts={loading ? [] : data.me.user.bankAccounts}
          retrievedBank={(bank) => {
            refetch().then();
            setSelectedBankAccount(bank.id);
          }}
        />
      </OvForm>
    </Box>
  );
};

AnnualPaymentInstructions.defaultProps = {
  onContinue: undefined,
  paymentInstruction: undefined,
  width: undefined,
};

export default AnnualPaymentInstructions;
