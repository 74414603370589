import {
  AppBar,
  Toolbar,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import React, { useContext, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import convert from 'color-convert';
import { navigate } from '../../../utils/router';
import OneVestIcon from '../../../assets/images/onevest/onevest.svg';
import OvMenu from './ovMenu';
import LogoutMenuIcon from '../../../assets/images/menu/logout.svg';
import DocumentsMenuIcon from '../../../assets/images/menu/documents.svg';
import ProfileMenuIcon from '../../../assets/images/menu/profile.svg';
import SupportMenuIcon from '../../../assets/images/menu/support.svg';
import { signUserOut } from '../../../utils/authToken';
import {
  availableFeatureFlagsVar, isLoggedInVar, isOnboardingCompleteVar, userEmailVar,
} from '../../../utils/localVariables';
import { styles } from './styles';
import PersonalDetails from '../../profile/personalDetails';
import Documents from '../../documents';
import HelpModal from '../modals/helpModal';
import { UserContext } from '../../../providers/userContextProvider';
import { getPartnerLogo } from '../../../theme/partners/getPartnerLogo';
import FlowModal from '../wrappers/modals/ovFlowModal';
import { FeatureFlagTypes, isFeatureEnabled } from '../../account/resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import { useContextTheme } from '../../../providers/contextThemeProvider';

const AppBarHeader = (): JSX.Element => {
  const client = useApolloClient();
  const { t } = useTranslation(['base']);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [personalDetailsModal, setPersonalDetailsModal] = useState(false);
  const [documentsModal, setDocumentsModal] = useState(false);
  const [helpWizard, setHelpWizard] = useState(false);
  const { setUserContext } = useContext(UserContext);
  const { logoUrl, colors } = useContextTheme();
  const handleClick = (event: React.MouseEvent<Element>): void => {
    sendAnalyticsEvent(ovAnalyticsEvents.homePageProfileSelect).then();
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onLogout = (): void => {
    setAnchorEl(null);
    signUserOut(client);
  };

  const onPersonalDetails = (): void => {
    setAnchorEl(null);
    setPersonalDetailsModal(true);
  };

  const onDocuments = (): void => {
    setAnchorEl(null);
    setDocumentsModal(true);
  };

  const onHelp = (): void => {
    setAnchorEl(null);
    setHelpWizard(true);
  };

  const onOneVestLogoClick = (): void => {
    navigate(setUserContext, '/');
  };

  if (documentsModal) {
    return (
      <FlowModal
        open={documentsModal}
        showCloseButton
        onClose={() => setDocumentsModal(false)}
        component={(
          <Documents
            onClose={() => setDocumentsModal(false)}
          />
        )}
      />
    );
  }
  if (personalDetailsModal) {
    return (
      <FlowModal
        open={personalDetailsModal}
        onClose={() => setPersonalDetailsModal(false)}
        showCloseButton
        component={(
          <PersonalDetails />
        )}
      />
    );
  }
  if (helpWizard) {
    return (
      <FlowModal
        open={helpWizard}
        showCloseButton
        onClose={() => setHelpWizard(false)}
        component={(
          <HelpModal
            email={userEmailVar()}
          />
        )}
      />
    );
  }

  const profileImage = new Image();
  const documentsImage = new Image();
  const supportImage = new Image();
  const logoutImage = new Image();

  profileImage.src = ProfileMenuIcon;
  documentsImage.src = DocumentsMenuIcon;
  supportImage.src = SupportMenuIcon;
  logoutImage.src = LogoutMenuIcon;

  const defineNavOption = (): { icon: string, title: string, onClick: () => void }[] => {
    if (!isLoggedInVar()) {
      return [
        { icon: supportImage.src, title: `${t('base:headerMenu.help')}`, onClick: () => onHelp() },
      ];
    }
    if (isLoggedInVar() && !isOnboardingCompleteVar()) {
      return [
        { icon: supportImage.src, title: `${t('base:headerMenu.help')}`, onClick: () => onHelp() },
        { icon: logoutImage.src, title: `${t('base:headerMenu.logOut')}`, onClick: () => onLogout() },
      ];
    }
    return [
      ...isFeatureEnabled(FeatureFlagTypes.UPDATE_PERSONAL_DETAILS) ? [
        {
          icon: profileImage.src,
          title: `${t('base:headerMenu.personalDetails')}`,
          onClick: () => {
            sendAnalyticsEvent(ovAnalyticsEvents.profilePagePersonalDetailsSelect).then();
            onPersonalDetails();
          },
        },
      ] : [],
      ...availableFeatureFlagsVar().some((x) => ['VIEW_STATEMENTS', 'VIEW_TAX_DOCUMENTS', 'VIEW_AGREEMENTS'].includes(x)) ? [
        {
          icon: documentsImage.src,
          title: `${t('base:headerMenu.documents')}`,
          onClick: () => {
            sendAnalyticsEvent(ovAnalyticsEvents.profilePageDocumentsSelect).then();
            if (isFeatureEnabled(FeatureFlagTypes.DOCUMENTS)) {
              setAnchorEl(null);
              navigate(setUserContext, '/documents');
            } else {
              onDocuments();
            }
          },
        },
      ] : [],
      ...isFeatureEnabled(FeatureFlagTypes.VIEW_HELP_CENTER) ? [
        {
          icon: supportImage.src,
          title: `${t('base:headerMenu.help')}`,
          onClick: () => {
            sendAnalyticsEvent(ovAnalyticsEvents.profilePageHelpSelect).then();
            onHelp();
          },
        },
      ] : [],
      { icon: logoutImage.src, title: `${t('base:headerMenu.logOut')}`, onClick: () => onLogout() },
    ];
  };

  const logo = getPartnerLogo(logoUrl) ?? { src: OneVestIcon };

  const textColor = () => {
    if (convert.hex.hsl(colors.menuBar)[2] < 80) {
      return 'white';
    }

    return 'black';
  };

  return (
    <AppBar className="ov-header" sx={{ justifyContent: 'center', backgroundColor: `${colors.menuBar} !important` }}>
      <Toolbar variant="dense" style={styles.toolBar}>
        <Box onClick={onOneVestLogoClick} sx={{ cursor: 'pointer' }}>
          <img src={logo.src} alt="OneVest" style={logo?.style ?? styles.logo} />
        </Box>
        <MenuRoundedIcon
          sx={{
            ...styles.menuIcon,
            color: textColor(),
          }}
          onClick={handleClick}
        />
        <OvMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          menuItems={defineNavOption()}
        />
      </Toolbar>
    </AppBar>
  );
};

export default AppBarHeader;
