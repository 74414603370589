/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Typography,
  Box,
  List,
  Button,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import OvForm from '../common/wrappers/ovForm';
import AutoScheduleListItem from './autoScheduleListItem';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import { CANCEL_SCHEDULED_TRANSFER } from './graphql';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { FeatureFlagTypes, isFeatureEnabled } from '../account/resources';
import FlowModal from '../common/wrappers/modals/ovFlowModal';
import { UserContext } from '../../providers/userContextProvider';

interface ScheduledTransfer {
  amountCents: number
  frequency: string
  id: string,
  type: string,
  scheduledDate: string,
  state: string,
  bankAccount: {
    id: string,
  },
  subAccount: {
    id: string,
    theme: {
      key: string,
      translatedName: {
        en: string,
        fr: string,
      },
    },
    account: {
      id: string,
      type: string,
    },
  }
}

interface Props {
  goToNamedStep: (name: string) => void,
  onEdit: (scheduledTransfer: ScheduledTransfer) => void,
  loading: boolean,
  scheduledTransfers: ScheduledTransfer[],
  fetchAutoInvestment: () => void,
}

const YourAutoInvestment = ({
  goToNamedStep,
  onEdit,
  loading,
  scheduledTransfers,
  fetchAutoInvestment,
}: Props): JSX.Element => {
  const [init] = useState(false);
  const { t } = useTranslation(['autoInvestment']);
  const matches = useMediaQuery('(max-width:600px)');
  const client = useApolloClient();
  const [showConfimation, setShowConfirmation] = useState(false);
  const { userContext } = useContext(UserContext);
  const [selectedItem, setSelectedItem] = useState<ScheduledTransfer>();
  useEffect(() => {
    fetchAutoInvestment();
  }, [init]);

  const addNewInvestment = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    goToNamedStep('add-auto-investment-form');
  };

  const initEdit = (scheduledTransfer: ScheduledTransfer): void => {
    onEdit(scheduledTransfer);
    goToNamedStep('add-auto-investment-form');
  };

  const cancelAutoTransfer = async (id: string): Promise<void> => {
    const result = await client.mutate({
      mutation: CANCEL_SCHEDULED_TRANSFER,
      variables: {
        scheduledTransferId: id,
      },
    });
    if (result.data) {
      fetchAutoInvestment();
    }
  };

  return (
    <Box width={matches ? 'fit-content' : 415} boxSizing="border-box" height={matches ? '95%' : 520} py="18px">
      <OvForm onSubmit={addNewInvestment} hasButton={isFeatureEnabled(FeatureFlagTypes.AUTO_INVESTMENTS)} buttonVariant="secondary-large" buttonText={t('autoInvestment:yourAutoInvestmentStep.buttonText')}>
        <Typography variant="heading2" style={{ marginBottom: 16 }}>{t('autoInvestment:yourAutoInvestmentStep.title')}</Typography>
        <Typography variant="paragraph2" style={{ marginBottom: 16 }}>{t('autoInvestment:yourAutoInvestmentStep.subtitle')}</Typography>
        {
          loading ? <Box position="absolute" top="50%" left={0} right={0}><OvLoadingIndicator /></Box> : undefined
        }
        {
          !loading && scheduledTransfers.length === 0 ? <Typography key="empty" position="absolute" top="50%" variant="paragraph2" style={{ marginBottom: 16 }}>{t('autoInvestment:yourAutoInvestmentStep.emptyStateMessage')}</Typography> : undefined
        }
        <List sx={{ maxHeight: matches ? '100%' : '300px', overflowY: 'auto' }}>
          {
            scheduledTransfers.map((item) => (
              <AutoScheduleListItem
                key={item.id}
                sx={{ marginBottom: '24px' }}
                onEdit={(transfer) => initEdit(transfer)}
                scheduledTransfer={item}
                onCancel={() => {
                  setSelectedItem(item);
                  if (userContext.availableFeatureFlags?.includes('PAD_AGREEMENTS')) {
                    setShowConfirmation(true);
                  } else {
                    sendAnalyticsEvent(ovAnalyticsEvents.autoInvestPageCancelSelect).then();
                    if (item) {
                      cancelAutoTransfer(item.id).then();
                    }
                  }
                }}
              />
            ))
          }
        </List>
      </OvForm>
      {
        showConfimation ? (
          <FlowModal
            open={showConfimation}
            onClose={() => setShowConfirmation(false)}
            showCloseButton={false}
            component={(
              <Box height="100%" width="398px">
                <Typography data-testid="dialog-title" variant="heading2">{t('autoInvestment:confirmationDialog.title')}</Typography>
                <Typography data-testid="dialog-description" variant="paragraph3">
                  {t('autoInvestment:confirmationDialog.description.preText')}
                  <b style={{ margin: '0 5px 0 5px' }}>
                    {t('autoInvestment:confirmationDialog.description.boldText', { accountType: `${selectedItem?.subAccount.account.type} - ${selectedItem?.subAccount?.theme?.translatedName?.en ?? ''}` })}
                  </b>
                  {t('autoInvestment:confirmationDialog.description.postText')}
                </Typography>
                <Button
                  variant="error-large"
                  data-testid="dialog-continue-button"
                  onClick={() => {
                    sendAnalyticsEvent(ovAnalyticsEvents.autoInvestPageCancelSelect).then();
                    if (selectedItem) {
                      cancelAutoTransfer(selectedItem.id).then();
                    }
                    setShowConfirmation(false);
                  }}
                  sx={{ marginBottom: '16px' }}
                >
                  {t('autoInvestment:confirmationDialog.cancel')}
                </Button>
                <Button
                  variant="text-large"
                  data-testid="dialog-exit-button"
                  onClick={() => setShowConfirmation(false)}
                  sx={{ marginBottom: '16px' }}
                >
                  {t('autoInvestment:confirmationDialog.exit')}
                </Button>
              </Box>
            )}
        />
        ) : undefined
      }
    </Box>
  );
};

export default YourAutoInvestment;
