/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import uuid from 'react-uuid';
import {
  useState, useRef, useMemo, useEffect,
} from 'react';
import {
  Typography,
  Button,
  InputAdornment,
} from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import IconButton from '@mui/material/IconButton';
import { geocodeByPlaceID } from 'mui-places-autocomplete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AddressCountries, PhysicalAddress, provinces } from './resources';
import { UserState } from './updatePhysicalAddressWizard';
import CloseIconSVG from '../../../assets/images/close-icon.svg';
import OvForm from '../../common/wrappers/ovForm';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_KEY;

interface Props {
  user: UserState,
  title?: string,
  subtitle?: string,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  setAddressState?: (att: { address: string, error: string, open: boolean }) => void,
}

const loadScript = (src: string, position: HTMLElement | null, id: string): void => {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id?: string;
}
const styles = {
  noOptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  noOptionText: {
    position: 'relative',
    top: '15px',
  },
};
const GoogleAddress = ({
  user, updateUserState, setAddressState, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }
  const RESTRICTED_COUNTRY = 'ca';
  const fetch = useMemo(
    () => throttle(
      (
        request: { input: string, componentRestrictions: { country: string, } },
        callback: (results?: readonly PlaceType[]) => void,
      ) => {
        (autocompleteService.current as any).getPlacePredictions(
          request,
          callback,
        );
      },
      200,
    ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!inputValue) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, componentRestrictions: { country: RESTRICTED_COUNTRY } }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const checkForCountryExceptions = (country: string): boolean => {
    if (country && country.toLocaleLowerCase() !== 'canada') {
      console.log({ event: 'USER_FROM_DIFFERENT_COUNTRY' });
      if (setAddressState) {
        setAddressState({ address: '', error: 'USER_FROM_DIFFERENT_COUNTRY', open: true });
      }
      return true;
    }
    return false;
  };

  const handleSelect = (results: any[]): void => {
    const localAddress: Partial<PhysicalAddress> = {};
    let countryText = '';
    let provinceText = '';
    if (results && results.length > 0) {
      const country: any[] = results[0]?.address_components.filter((e: any) => (e.types.includes('country')));
      if (country && country.length > 0) {
        countryText = country[0].long_name;
      }
      const postal: any[] = results[0]?.address_components.filter((e: any) => (e.types.includes('postal_code')));
      if (postal && postal.length > 0) {
        localAddress.postal = postal[0].long_name;
      }
      // eslint-disable-next-line
      const streetNumber: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('street_number');
      });
      if (streetNumber && streetNumber.length > 0) {
        localAddress.houseNumber = streetNumber[0].long_name;
      }
      // eslint-disable-next-line
      const unitNumber: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('unit_number');
      });
      if (unitNumber && unitNumber.length > 0) {
        localAddress.unitNumber = unitNumber[0].long_name;
      }
      // eslint-disable-next-line
      const route: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('route');
      });
      if (route && route.length > 0) {
        localAddress.streetName = route[0].long_name;
      }
      // eslint-disable-next-line
      const administrativeAreaLevel1: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('administrative_area_level_1');
      });
      if (administrativeAreaLevel1 && administrativeAreaLevel1.length > 0) {
        provinceText = administrativeAreaLevel1[0].long_name;
      }
      // eslint-disable-next-line
      const administrativeAreaLevel2: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('administrative_area_level_2') || e.types.includes('locality');
      });
      if (administrativeAreaLevel2 && administrativeAreaLevel2.length > 0) {
        localAddress.city = administrativeAreaLevel2[0].long_name;
      }
    }
    if (provinceText && countryText) {
      if (!checkForCountryExceptions('') && !checkForCountryExceptions(countryText)) {
        localAddress.country = AddressCountries.CA;
        localAddress.province = provinces.find((p) => p.value.toLowerCase() === provinceText.toLocaleLowerCase())?.key;
        updateUserState({ ...user, physicalAddress: localAddress });
        if (props.onContinue) props.onContinue();
      } else if (setAddressState) {
        setAddressState({ address: '', error: '', open: true });
      }
    }
  };
  const newAddressClick = (): void => {
    if (props.onContinue) props.onContinue();
  };

  return (
    <OvForm hasButton={false}>
      <Typography variant="heading2">{ props.title ?? t('user:address.title')}</Typography>
      <Typography variant="paragraph3">{ props.subtitle ?? t('user:address.subTitle')}</Typography>
      <Autocomplete
        id="google-place-suggestion"
        fullWidth
        popupIcon={(options && options.length > 0) ? <ArrowDropDownIcon /> : null}
        getOptionLabel={(option) => ((typeof option === 'string') ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText={
          (
            <Box style={styles.noOptionWrapper}>
              <Typography variant="paragraph3" sx={styles.noOptionText}>{t('user:address.noOptionText')}</Typography>
              <Button variant="primary-small" data-testid="add-new-address-button" onClick={newAddressClick}>{t('base:button.addAddress')}</Button>
            </Box>
          )
        }
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          if (newValue && newValue.place_id) {
            geocodeByPlaceID(newValue.place_id).then((results: any[]) => {
              handleSelect(results);
            }).catch((err: any) => {
              console.log('error', err.message);
            });
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            sx={{ marginBottom: '32px' }}
            variant="standard"
            {...params}
            label={t('user:address.searchPlaceholder')}
            fullWidth
            InputProps={{
              ref: params.InputProps.ref,
              endAdornment: (!inputValue && inputValue === '') ? undefined : (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="clear input field"
                    disableRipple
                    onClick={() => {
                      setValue({
                        description: '',
                        structured_formatting: {
                          main_text: '',
                          secondary_text: '',
                          main_text_matched_substrings: [],
                        },
                        place_id: '',
                      });
                    }}
                    edge="end"
                  >
                    <img src={CloseIconSVG} alt="close" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(propsVals, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...propsVals}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: 'text.secondary', mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part) => (
                    <span
                      key={uuid()}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </OvForm>
  );
};

GoogleAddress.defaultProps = {
  title: undefined,
  subtitle: undefined,
  onContinue: undefined,
  setAddressState: undefined,
};

export default GoogleAddress;
