/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { useQuery } from '@apollo/client';
import { colors as defaultColors } from '../theme/colors';
import { buildThemeOptions } from '../theme';
import { getPartnerColor } from '../theme/partners/getPartnerColor';
import { getOrganizationId, getOrganizationKey } from '../utils/platformUtils';
import { FETCH_ORGANIZATION_CONSUMER } from '../utils/commonGraphql';
import {
  availableFeatureFlagsVar,
  orgHelpCentreUrlVar,
  orgSupportUrlVar,
  organizationIdVar,
} from '../utils/localVariables';
import LoadingBox from '../components/common/loaders/loadingBox';
import { UserContext } from './userContextProvider';

export type Colors = typeof defaultColors;

export interface Theme {
  font?: string,
  logoUrl?: string,
  colors: Colors,
}

const ThemeContext = createContext<Theme>({ colors: defaultColors });

const ContextThemeProvider: React.FC = ({ children }) => {
  const { userContext, setUserContext } = useContext(UserContext);
  const [organizationTheme, setOrganizationTheme] = useState<Theme>({ colors: defaultColors });
  const contextOrganizationId = getOrganizationId() || getOrganizationKey();
  const { data, loading } = useQuery(FETCH_ORGANIZATION_CONSUMER(contextOrganizationId ?? ''), {
    skip: !contextOrganizationId,
  });
  const [contextLoading, setContextLoading] = useState(true);

  useEffect(() => {
    const org = data?.fetchPublicOrganization?.organization;
    const availableFeatureFlags = org?.availableFeatureFlags || [];
    availableFeatureFlagsVar(availableFeatureFlags);
    if (org?.helpCentreUrl) orgHelpCentreUrlVar(org?.helpCentreUrl);
    if (org?.supportUrl) orgSupportUrlVar(org?.supportUrl);
    const orgColors = org?.theme?.embeddedExperienceTheme?.colors;
    const colors = {
      ...defaultColors,
      ...(orgColors?.primary ? getPartnerColor(orgColors) : {}),
    };
    const font = org?.theme?.embeddedExperienceTheme?.font;
    const logoUrl = org?.embeddedExperienceLogoUrl;
    setOrganizationTheme((prev) => ({
      ...prev,
      ...(font ? { font } : {}),
      ...(logoUrl ? { logoUrl } : {}),
      colors,
    }));
    if (org?.id) {
      organizationIdVar(org.id);
      setUserContext((prev) => ({
        ...prev,
        organizationId: org.id,
        organizationKey: org.subdomain,
        useClientOrgAuth0: org.useClientOrgAuth0 || false,
      }));
    } else if (!loading) {
      setContextLoading(false);
    }
  }, [data, setUserContext, loading]);

  useEffect(() => {
    if (userContext.useClientOrgAuth0 !== undefined) {
      setContextLoading(false);
    }
  }, [userContext.useClientOrgAuth0, setContextLoading]);

  const theme = useMemo(() => {
    let customFonts;
    if (organizationTheme?.font) {
      customFonts = {
        h1: organizationTheme.font,
        h2: organizationTheme.font,
        h3: organizationTheme.font,
        h4: organizationTheme.font,
        base: organizationTheme.font,
      };
    }
    const options = buildThemeOptions(organizationTheme.colors, customFonts);
    return createTheme(options);
  }, [organizationTheme]);

  const themeContextValue = useMemo(() => (organizationTheme), [organizationTheme]);

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <ThemeProvider theme={theme}>
        {(loading || contextLoading) ? <LoadingBox /> : children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useContextTheme = (): Theme => useContext(ThemeContext);

export default ContextThemeProvider;
