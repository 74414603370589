import {
  Typography,
  List,
  Avatar,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormEvent, useContext } from 'react';
import OvForm from '../common/wrappers/ovForm';
import { colors } from '../../theme/colors';
import padlock from '../../assets/images/padlock.svg';
import checkIcon from '../../assets/images/check-black.svg';
import { UserContext } from '../../providers/userContextProvider';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { FeatureFlagTypes } from '../account/resources';

export interface UserState {
  id?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  primaryEmail?: string,
  dateOfBirth?: Date,
  phone?: string,
}

interface Props {
  onContinue?: () => void,
  onManualyAddBankAccount?: () => void,
}

const Information = ({ onContinue, onManualyAddBankAccount }: Props): JSX.Element => {
  const { t } = useTranslation(['flinks', 'base']);
  const { userContext } = useContext(UserContext);
  const availableFeatureFlags = userContext?.availableFeatureFlags || [];
  const listItem = (index: number, info: string, marginBottom = 2): JSX.Element => (
    <Box display="flex" marginBottom={marginBottom} mt={0}>
      <Avatar className="personaStepListItemCount">{index}</Avatar>
      <Box display="flex" flexDirection="column" ml={2}>
        <Typography variant="bodySub1" mb={0.5}>{info}</Typography>
      </Box>
    </Box>
  );

  const nextStep = (e: FormEvent<HTMLFormElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    if (onContinue) {
      onContinue();
    }
  };

  const manualyAddbankAccount = (): void => {
    if (onManualyAddBankAccount) {
      onManualyAddBankAccount();
    }
  };
  return (
    <Box padding="0px 0px" height="100%">
      <OvForm
        onSubmit={nextStep}
        buttonText={t('base:button.continue')}
        suffixButton={availableFeatureFlags.includes(FeatureFlagTypes.MANUALLY_ADD_BANK_ACCOUNT) ? {
          onClick: () => {
            sendAnalyticsEvent(ovAnalyticsEvents.investPageAddBankManuallySelect).then();
            manualyAddbankAccount();
          },
          text: t('account:themeSummary.manuallyAddBankAccount'),
          variant: 'secondary-large',
          dataTestId: 'manuallyAddbankAccountLink',
          type: 'text',
        } : undefined}
      >
        <Typography variant="heading2" data-testid="title">{t('flinks:information.header')}</Typography>
        <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('flinks:information.paragraphOne')}</Typography>
        <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('flinks:information.paragraphTwo')}</Typography>
        <List style={{ marginBottom: 20 }}>
          {listItem(1, t('flinks:information.optionOne'), 5.25)}
          {listItem(2, t('flinks:information.optionTwo'))}
        </List>
        <Box borderRadius="6px" mb={2.5} padding="16px 10px 20px 22px" boxSizing="border-box" bgcolor={colors.sinCard} width="100%">
          <Box display="flex" alignItems="center">
            <img src={padlock} alt="padlock" width={24} height={24} style={{ marginRight: 13 }} />
            <Typography variant="heading5" style={{ marginBottom: 0 }}>{t('flinks:information.notificationCard.header')}</Typography>
          </Box>
          <Box pl={4.5} mt={2.5}>
            <Box display="flex" alignItems="center">
              <img src={checkIcon} alt="padlock" width={10} height={8} style={{ marginRight: 13 }} />
              <Typography variant="paragraph3" style={{ margin: 0 }}>{t('flinks:information.notificationCard.pointOne')}</Typography>
            </Box>
            <Box mt={2} />
            <Box display="flex" alignItems="center">
              <img src={checkIcon} alt="padlock" width={10} height={8} style={{ marginRight: 13 }} />
              <Typography variant="paragraph3" style={{ marginBottom: 0 }}>{t('flinks:information.notificationCard.pointTwo')}</Typography>
            </Box>
          </Box>
        </Box>
      </OvForm>
    </Box>
  );
};

Information.defaultProps = {
  onContinue: undefined,
  onManualyAddBankAccount: undefined,
};

export default Information;
