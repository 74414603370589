export enum AddressProvinces {
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NT = 'NT',
  NS = 'NS',
  NU = 'NU',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
}

export enum AddressCountries {
  CA = 'CA',
}

export interface PhysicalAddress {
  unitNumber?: string,
  houseNumber: string,
  streetName: string,
  neighborhood?: string,
  city: string,
  province: AddressProvinces,
  country: AddressCountries,
  postal: string,
}

export interface CountryList {
  key: AddressCountries,
  value: string,
}

export interface ProvinceList {
  key: AddressProvinces,
  value: string,
  minAge: number,
}

export const countries: CountryList[] = [
  {
    key: AddressCountries.CA,
    value: 'Canada',
  },
];

export const provinces: ProvinceList[] = [
  {
    key: AddressProvinces.AB,
    value: 'Alberta',
    minAge: 18,
  },
  {
    key: AddressProvinces.BC,
    value: 'British Columbia',
    minAge: 19,
  },
  {
    key: AddressProvinces.MB,
    value: 'Manitoba',
    minAge: 18,
  },
  {
    key: AddressProvinces.NB,
    value: 'New Brunswick',
    minAge: 19,
  },
  {
    key: AddressProvinces.NL,
    value: 'Newfoundland and Labrador',
    minAge: 19,
  },
  {
    key: AddressProvinces.NS,
    value: 'Nova Scotia',
    minAge: 19,
  },
  {
    key: AddressProvinces.ON,
    value: 'Ontario',
    minAge: 18,
  },
  {
    key: AddressProvinces.PE,
    value: 'Prince Edward Island',
    minAge: 18,
  },
  {
    key: AddressProvinces.SK,
    value: 'Saskatchewan',
    minAge: 18,
  },
  {
    key: AddressProvinces.QC,
    value: 'Québec',
    minAge: 18,
  },
  {
    key: AddressProvinces.YT,
    value: 'Yukon',
    minAge: 19,
  },
  {
    key: AddressProvinces.NT,
    value: 'Northwest Territories',
    minAge: 19,
  },
  {
    key: AddressProvinces.NU,
    value: 'Nunavut',
    minAge: 19,
  },
];
