/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DocumentNode,
  gql,
} from '@apollo/client';

export interface CreateFileInput {
  objectType: FileDocumentObjectTypes,
  objectId: string,
  fileName: string,
  type: FileDocumentTypes,
  name?: string,
  mediaType?: string,
  sourceId?: string,
  sourceType?: string,
}
export enum FileDocumentTypes {
  BANKING = 'BANKING',
  OTHER = 'OTHER',
}
export enum FileDocumentObjectTypes {
  USER = 'USER',
}
export const FETCH_FILE_UPLOAD_URL = gql`
  query fetchFileUploadUrl ($input:FetchFileUploadUrlInput!) {
    fetchFileUploadUrl(input:$input) {
      temporarySignedURL
    }
  }
`;

export const CREATE_FILE_DOCUMENT = gql`
  mutation createFileDocument ($input:CreateFileDocumentInput!) {
    createFileDocument(input:$input) {
      fileDocument {
        id name fileName s3Key type
      }
    }
  }
`;

export interface FileUploaderProps {
  uploadingFile: File,
  createFileInput: CreateFileInput,
  onSuccess?: () => void,
  refetchQueries?: [DocumentNode],
  apolloClient: any,
}

export const fileUploader = async (
  {
    uploadingFile,
    createFileInput,
    onSuccess,
    refetchQueries,
    apolloClient,
  }: FileUploaderProps,
): Promise<void> => {
  if (!uploadingFile) return;

  /* (1) fetch the S3 upload URL from backend */
  const queryResult = await apolloClient.query({
    query: FETCH_FILE_UPLOAD_URL,
    variables: {
      input: {
        objectType: createFileInput.objectType,
        objectId: createFileInput.objectId,
        fileName: createFileInput.fileName,
        type: createFileInput.type,
      },
      skipErrorHandler: true,
    },
    fetchPolicy: 'no-cache',
  });

  const uploadUrl = queryResult?.data?.fetchFileUploadUrl.temporarySignedURL;
  if (!uploadUrl || queryResult?.error) {
    console.log(queryResult?.error || '');
    return;
  }

  /* (2) do the upload */
  try {
    // setUploadPending(true);
    const uploaded: Response = await fetch(new Request(uploadUrl, { method: 'PUT', body: uploadingFile }));
    if (!uploaded.ok) throw (new Error(`${uploaded.status} ${uploaded.statusText}`));
  } catch {
    console.log('Upload failed, step 2 of 3 (HTTP PUT)');
    return;
  }

  /* (3) create the fileDocument within backend */
  try {
    await await apolloClient.mutate({
      mutation: CREATE_FILE_DOCUMENT,
      variables: { input: createFileInput },
      refetchQueries,
    });
  } catch {
    console.log('Create File Document Success');
    return;
  }
  if (onSuccess) {
    onSuccess();
  }
};
