import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  goToNamedStep?: (step: string) => void,
}

const Error = ({ ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['identity', 'base']);
  const onContinueClicked = (): void => {
    if (props.goToNamedStep) props.goToNamedStep('information');
  };

  return (
    <>
      <Typography variant="heading2" data-testid="title">{t('identity:error.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 10 }}>{t('identity:error.paragraphOne')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('identity:error.paragraphTwo')}</Typography>
      <Button className="responsive" variant="primary-large" type="submit" data-testid="continue-button" onClick={onContinueClicked}>{t('base:button.goToHome')}</Button>
    </>
  );
};

Error.defaultProps = {
  goToNamedStep: undefined,
};

export default Error;
