import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import WithStepWizard, { StepProps } from '../../common/wrappers/withStepWizard';
import TrackingBar from '../../common/headers/trackingBar';
import SelecteEmployement from './selectEmployement';
import EmployeeInfo from './employeeInfo';
import EmployeeJobTitle from './employeeJobTitle';
import AreaOfStudies from './areaOfStudies';
import { UserContext } from '../../../providers/userContextProvider';
import UpdatePhysicalAddressWizard from '../address/updatePhysicalAddressWizard';
import { PhysicalAddress } from '../address/resources';
import EmploymentSituation from './employmentSituation';
import EmployerName from './employerName';

const SelectEmploymentStep = WithStepWizard(SelecteEmployement);
const EmploymentSituationStep = WithStepWizard(EmploymentSituation);
const EmployeeInfoStep = WithStepWizard(EmployeeInfo);
const EmployeeJobTitleStep = WithStepWizard(EmployeeJobTitle);
const AreaOfStudiesStep = WithStepWizard(AreaOfStudies);
const EmployerNameStep = WithStepWizard(EmployerName);
const EmployerAddressWizardStep = WithStepWizard(UpdatePhysicalAddressWizard);

export interface UserState {
  incompleteFields?: string[],
  employmentStatus?: string,
  studentAreaOfStudy?: string,
  jobTitle?: string,
  companyType?: string,
  employmentSituation?: string,
  employmentSituationOtherDescription?: string,
  employerName?: string,
  employerCompanyAddress?: Partial<PhysicalAddress>,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  goBack?: () => void,
  stepProps?: StepProps,
  stepTitle?: string,
  showStepper?: boolean,
  analyticsEvents?: {
    employmentInfo?: string,
    industry?: string,
    role?: string,
    fieldOfStudy?: string,
  },
  titles?: {
    employmentInfo?: { title: string, subTitle: string },
    industry?: { title: string, subTitle: string },
    role?: { title: string, subTitle: string },
    fieldOfStudy?: { title: string, subTitle: string },
  },
  hideNav?: boolean,
  shouldSaveUpdate?: boolean,
  onContinueStepUnEmployed?: string,
  goToNamedStep?: (stepName: string) => void,
  isSpousalOnly?: boolean,
}

const UpdateEmployementStatusWizard = ({
  user,
  updateUserState,
  onContinue,
  goBack,
  showStepper,
  titles,
  hideNav,
  shouldSaveUpdate = true,
  analyticsEvents,
  onContinueStepUnEmployed,
  goToNamedStep,
  isSpousalOnly,
  ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['user']);
  const { userContext } = useContext(UserContext);
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const steps: JSX.Element[] = [];
  const showEmployerName = !isSpousalOnly && (userContext.organizationSettings?.showEmployerAddress
    || userContext.organizationSettings?.showEmployerName);

  steps.push(
    <SelectEmploymentStep
      analyticsEvent={analyticsEvents?.employmentInfo}
      title={titles?.employmentInfo?.title ?? undefined}
      subTitle={titles?.employmentInfo?.subTitle ?? undefined}
      stepName="SelecteEmployement"
      key="SelecteEmployement"
      user={user}
      updateUserState={updateUserState}
      stepTitle={t('stepTitles.aboutYou')}
      goBackAction={goBack}
      cached={!shouldSaveUpdate}
      onContinueStepUnEmployed={() => {
        if (goToNamedStep && onContinueStepUnEmployed) goToNamedStep(onContinueStepUnEmployed);
      }}
    />,
  );
  if (userContext.organizationSettings?.showEmploymentSituation && !isSpousalOnly && user.employmentStatus !== 'RETIRED') {
    steps.push(<EmploymentSituationStep stepName="EmploymentSituation" key="EmploymentSituation" updateUserState={updateUserState} user={user} />);
  }
  if (user.employmentStatus && ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED', 'UNEMPLOYED'].includes(user.employmentStatus)) {
    steps.push(<EmployeeInfoStep analyticsEvent={analyticsEvents?.industry} title={titles?.industry?.title ?? undefined} subTitle={titles?.industry?.subTitle ?? undefined} stepName="employeeInfo" key="employeeInfo" user={user} updateUserState={updateUserState} stepTitle={t('stepTitles.aboutYou')} cached={!shouldSaveUpdate} />);
    steps.push(
      <EmployeeJobTitleStep
        analyticsEvent={analyticsEvents?.role}
        title={titles?.role?.title ?? undefined}
        subTitle={titles?.role?.subTitle ?? undefined}
        stepName="employeeJobTitle"
        key="employeeJobTitle"
        user={user}
        updateUserState={updateUserState}
        stepTitle={t('stepTitles.aboutYou')}
        action={() => {
          if (!showEmployerName) {
            if (onContinue) onContinue();
          }
        }}
        cached={!shouldSaveUpdate}
      />,
    );
    if (!isSpousalOnly) {
      if (userContext.organizationSettings?.showEmployerName && ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED'].includes(user.employmentStatus)) {
        steps.push(<EmployerNameStep
          stepName="employerName"
          key="employerName"
          user={user}
          updateUserState={updateUserState}
          action={() => {
            if (!userContext.organizationSettings?.showEmployerName) {
              if (onContinue) onContinue();
            }
          }}
        />);
      }
      if (userContext.organizationSettings?.showEmployerAddress && ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED'].includes(user.employmentStatus)) {
        steps.push(
          <EmployerAddressWizardStep
            stepName="employerAddress"
            key="employerAddress"
            user={{ ...user, physicalAddress: user.employerCompanyAddress }}
            // This updates the employerCompanyAddress instead of physicalAddress
            updateEmployerAddress
            updateUserState={(userState) => updateUserState({ ...userState, employerCompanyAddress: userState.physicalAddress })}
            title={t('user:employement.employerAddress.title')}
            subtitle={t('user:employement.employerAddress.subTitle')}
            disableTracker
            action={onContinue}
          />,
        );
      }
    }
  } else {
    steps.push(<AreaOfStudiesStep analyticsEvent={analyticsEvents?.fieldOfStudy} title={titles?.fieldOfStudy?.title ?? undefined} subTitle={titles?.fieldOfStudy?.subTitle ?? undefined} stepName="areaOfStudies" key="areaOfStudies" user={user} updateUserState={updateUserState} stepTitle={t('stepTitles.aboutYou')} action={onContinue} goBackToNamedStep={() => ('SelecteEmployement')} cached={!shouldSaveUpdate} />);
  }

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      className="ov-step-wizard"
      transitions={{}}
      nav={hideNav ? undefined : (
        <TrackingBar steps={steps} baselineStepProps={props.stepProps} showStepper={showStepper} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

UpdateEmployementStatusWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  stepProps: undefined,
  stepTitle: undefined,
  showStepper: true,
  titles: undefined,
  hideNav: false,
  shouldSaveUpdate: true,
  analyticsEvents: undefined,
  goToNamedStep: undefined,
  onContinueStepUnEmployed: undefined,
  isSpousalOnly: undefined,
};

export default UpdateEmployementStatusWizard;
