/* eslint-disable indent */
import countries from 'i18n-iso-countries';
import englishCountry from 'i18n-iso-countries/langs/en.json';
import frenchCountry from 'i18n-iso-countries/langs/fr.json';
import i18n, { getBackendLanguage } from '../../assets/i18n/config';
import SocialThemeIcon from '../../assets/images/filled/articles.svg';
import {
  AD,
  AX,
  AL,
  DZ,
  AS,
  AO,
  AI,
  AQ,
  AG,
  AR,
  AM,
  AW,
  AU,
  AT,
  AZ,
  BS,
  BH,
  BD,
  BB,
  BY,
  BE,
  BZ,
  BJ,
  BM,
  BT,
  BO,
  BQ,
  BA,
  BW,
  BV,
  BR,
  IO,
  BN,
  BG,
  BF,
  BI,
  CV,
  KH,
  CM,
  CA,
  KY,
  CF,
  TD,
  CL,
  CN,
  CX,
  CC,
  CO,
  KM,
  CD,
  CG,
  CK,
  CR,
  CI,
  HR,
  CU,
  CW,
  CY,
  CZ,
  DK,
  DJ,
  DM,
  DO,
  EC,
  EG,
  SV,
  GQ,
  ER,
  EE,
  ET,
  FK,
  FO,
  FJ,
  FI,
  FR,
  GF,
  PF,
  TF,
  GA,
  GM,
  GE,
  DE,
  GH,
  GI,
  GR,
  GL,
  GD,
  GP,
  GU,
  GT,
  GG,
  GN,
  GW,
  GY,
  HT,
  HM,
  VA,
  HN,
  HK,
  HU,
  IS,
  IN,
  ID,
  IR,
  IQ,
  IE,
  IM,
  IL,
  IT,
  JM,
  JP,
  JE,
  JO,
  KZ,
  KE,
  KI,
  KP,
  KR,
  KW,
  KG,
  LA,
  LV,
  LB,
  LS,
  LR,
  LY,
  LI,
  LT,
  LU,
  MO,
  MK,
  MG,
  MW,
  MY,
  MV,
  ML,
  MT,
  MH,
  MQ,
  MR,
  MU,
  YT,
  MX,
  FM,
  MD,
  MC,
  MN,
  ME,
  MS,
  MA,
  MZ,
  MM,
  NA,
  NR,
  NP,
  NL,
  NC,
  NZ,
  NI,
  NE,
  NG,
  NU,
  NF,
  MP,
  NO,
  OM,
  PK,
  PW,
  PS,
  PA,
  PG,
  PY,
  PE,
  PH,
  PN,
  PL,
  PT,
  PR,
  QA,
  RE,
  RO,
  RU,
  RW,
  BL,
  SH,
  KN,
  LC,
  MF,
  PM,
  VC,
  WS,
  SM,
  ST,
  SA,
  SN,
  RS,
  SC,
  SL,
  SG,
  SX,
  SK,
  SI,
  SB,
  SO,
  ZA,
  GS,
  SS,
  ES,
  LK,
  SD,
  SR,
  SJ,
  SZ,
  SE,
  CH,
  SY,
  TW,
  TJ,
  TZ,
  TH,
  TL,
  TG,
  TK,
  TO,
  TT,
  TN,
  TR,
  TM,
  TC,
  TV,
  UG,
  UA,
  AE,
  GB,
  UM,
  US,
  UY,
  UZ,
  VU,
  VE,
  VN,
  VG,
  VI,
  WF,
  EH,
  YE,
  ZM,
  ZW,
  AF,
} from '../../assets/images/flags';
import { colors } from '../../theme/colors';

countries.registerLocale(englishCountry);
countries.registerLocale(frenchCountry);

export const professions = [
  {
    profession: i18n.t('user:professionOptions.accountingBankingInsuranceFinance.title'),
    jobTitles: [
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.accountant'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.actuary'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.auditor'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.bankAdministrator'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.bookkeeper'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.branchManager'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.chiefFinancialOfficer'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.claimsAdjuster'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.clericalWorker'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.commoditiesTrader'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.complianceRiskAnalyst'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.consultant'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.controller'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.creditAnalyst'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.creditManager'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.customerServiceRepresentative'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.economist'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.executiveSeniorManagementAccountingBankingInsuranceAndFinance'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.financialAnalyst'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.financialAdvisor'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.financialPlanner'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.insuranceAgent'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.insuranceBroker'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.internalAuditor'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.investmentAnalyst'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.investmentBanker'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.investmentConsultant'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.investmentFundManager'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.investmentRepresentative'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.loanOfficer'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.managementConsultant'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.manager'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.mutualFundSalesRepresentative'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.portfolioManager'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.productManager'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.securitiesTrader'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.statistician'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.stockBroker'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.taxAssessmentAndCollectionsOfficer'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.treasurer'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.underwriter'),
      i18n.t('user:professionOptions.accountingBankingInsuranceFinance.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.administrationAndHumanResources.title'),
    jobTitles: [
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.administrativeAssistant'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.clericalWorker'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.consultant'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.customerServiceRepresentative'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.custodian'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.executiveAssistant'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.executiveSeniorManagementAdministrationAndHumanResources'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.facilitiesSpecialist'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.generalOfficeClerk'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.humanResourcesConsultant'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.humanResourcesSpecialist'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.inventoryControlClerk'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.inventoryControlSpecialist'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.manager'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.mediator'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.officeManager'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.personalAssistant'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.procurementOfficer'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.projectManager'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.purchasingAgent'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.payrollBenefitsAdministrator'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.receptionist'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.recruiter'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.recordsManagementTechnician'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.telephoneOperator'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.unionRepresentative'),
      i18n.t('user:professionOptions.administrationAndHumanResources.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.agricultureAndNaturalResources.title'),
    jobTitles: [
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.agriculturalWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.agricultureAndNaturalResourcesSupportActivities'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.aquacultureWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.breeder'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.conservationist'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.customerServiceRepresentative'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.dairyWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.drillingOilAndGas'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.executiveSeniorManagementAgricultureAndNaturalResources'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.farmer'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.fisheriesWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.florist'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.foodProducer'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.forestryWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.grainElevatorOperator'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.harvester'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.horticultureWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.inspector'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.livestockWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.loggingWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.managerAgricultureAndNaturalResources'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.metalsProcessingWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.meteorologist'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.mineWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.nurseryGreenhouseWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.oilIndustryWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.pulpAndPaperProcessingWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.quarryWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.solarPowerWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.trapper'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.undergroundWorker'),
      i18n.t('user:professionOptions.agricultureAndNaturalResources.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.artsEntertainmentAndCommunications.title'),
    jobTitles: [
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.actorActress'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.advertisingSpecialist'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.animator'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.announcerBroadcaster'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.antiqueDealer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.artisanCrafter'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.artist'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.audioVisualTechnician'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.author'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.casinoCustomerService'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.casinoDealer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.casinoGamingManager'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.choreographer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.communicationsSpecialist'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.composer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.conductor'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.curator'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.dancer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.designer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.director'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.editor'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.executiveSeniorManagementArtsEntertainmentAndCommunications'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.filmVideoCameraOperator'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.graphicArtist'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.graphicDesigner'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.illustrator'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.industrialDesigner'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.instructor'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.interiorDesignerDecorator'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.interpreter'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.journalist'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.makeupArtist'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.managerArtsEntertainmentAndCommunications'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.mediaRelations'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.model'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.museumWorker'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.musician'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.performingArtist'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.photographer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.printer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.producer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.productionWorker'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.proofreader'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.publicRelationsWorker'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.publicist'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.publisher'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.reporter'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.researcher'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.sculptor'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.singer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.switchboardOperator'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.tattooArtist'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.theatreWorker'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.translator'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.writer'),
      i18n.t('user:professionOptions.artsEntertainmentAndCommunications.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.educationAndTraining.title'),
    jobTitles: [
      i18n.t('user:professionOptions.educationAndTraining.titles.caretakerJanitor'),
      i18n.t('user:professionOptions.educationAndTraining.titles.childCareWorker'),
      i18n.t('user:professionOptions.educationAndTraining.titles.counsellor'),
      i18n.t('user:professionOptions.educationAndTraining.titles.dean'),
      i18n.t('user:professionOptions.educationAndTraining.titles.earlyChildhoodEducator'),
      i18n.t('user:professionOptions.educationAndTraining.titles.educationAndTrainingSupportActivities'),
      i18n.t('user:professionOptions.educationAndTraining.titles.educationalAssistant'),
      i18n.t('user:professionOptions.educationAndTraining.titles.employmentCounsellor'),
      i18n.t('user:professionOptions.educationAndTraining.titles.executiveSeniorManagement'),
      i18n.t('user:professionOptions.educationAndTraining.titles.historian'),
      i18n.t('user:professionOptions.educationAndTraining.titles.instructor'),
      i18n.t('user:professionOptions.educationAndTraining.titles.librarian'),
      i18n.t('user:professionOptions.educationAndTraining.titles.lifeCoach'),
      i18n.t('user:professionOptions.educationAndTraining.titles.managerEducationAndTraining'),
      i18n.t('user:professionOptions.educationAndTraining.titles.officeAdminSecretary'),
      i18n.t('user:professionOptions.educationAndTraining.titles.professor'),
      i18n.t('user:professionOptions.educationAndTraining.titles.researchAssistant'),
      i18n.t('user:professionOptions.educationAndTraining.titles.researcher'),
      i18n.t('user:professionOptions.educationAndTraining.titles.schoolBusDriver'),
      i18n.t('user:professionOptions.educationAndTraining.titles.schoolCounsellor'),
      i18n.t('user:professionOptions.educationAndTraining.titles.schoolPrincipal'),
      i18n.t('user:professionOptions.educationAndTraining.titles.schoolCollegeAdministrator'),
      i18n.t('user:professionOptions.educationAndTraining.titles.superintendent'),
      i18n.t('user:professionOptions.educationAndTraining.titles.teacher'),
      i18n.t('user:professionOptions.educationAndTraining.titles.teachingAssistant'),
      i18n.t('user:professionOptions.educationAndTraining.titles.trainer'),
      i18n.t('user:professionOptions.educationAndTraining.titles.tutor'),
      i18n.t('user:professionOptions.educationAndTraining.titles.vicePrincipal'),
      i18n.t('user:professionOptions.educationAndTraining.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.engineeringArchitectureAndScience.title'),
    jobTitles: [
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.aerospaceEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.architect'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.astronomer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.biochemist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.bioEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.biologist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.cADDraftingTechnician'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.chemicalEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.chemist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.civilEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.consultant'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.electricalEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.electricalPowerAndCableWorker'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.energyNuclearEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.executiveSeniorManagementEngineeringArchitectureAndScience'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.geochemist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.geologist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.industrialEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.inspector'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.laboratoryTechnician'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.managerEngineeringArchitectureAndScience'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.manufacturingEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.marineEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.mathematician'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.mechanicalEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.metallurgist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.meteorologist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.miningEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.nuclearPowerWorker'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.petroleumEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.physicist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.powerSystemsEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.professionalEngineer'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.projectManager'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.researcher'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.scientist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.sociologistAnthropologist'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.urbanPlanner'),
      i18n.t('user:professionOptions.engineeringArchitectureAndScience.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.title'),
    jobTitles: [
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.ambassadorAttacheOrCounsellorOfAnAmbassador'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.borderSecurityAgent'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.cityAdministrator'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.cityCounsellor'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.civilServant'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.communityServicesWorker'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.consultant'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.correctionsOfficer'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.customsOfficer'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.deputyMinister'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.diplomaticPersonnel'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.emergencyMedicalTechnician'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.firefighter'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.governmentAdministrator'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.governmentInspector'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.governmentServicesOfficer'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.governmentDiplomaticAndProtectiveServicesSupportActivities'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.headOfGovernmentAgencyInstituteOrCharity'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.indigenousCouncilMember'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.intelligenceAnalysisSpecialist'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.mayor'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.memberOfGovernment'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.paramedic'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.policeOfficer'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.policyAnalyst'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.postalWorker'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.programOfficer'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.regulatoryOfficer'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.schoolBoardTrustee'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.securityGuard'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.socialWorker'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.urbanPlanner'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.wasteRemoval'),
      i18n.t('user:professionOptions.governmentDiplomaticAndProtectiveServices.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.hospitalityFoodAndTourism.title'),
    jobTitles: [
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.baker'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.bartender'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.butcher'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.caterer'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.chef'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.cook'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.customerServiceRepresentative'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.eventsPlanner'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.executiveSeniorManagementHospitalityFoodAndTourism'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.ferryWorker'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.foodServicesWorker'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.hospitalityServicesWorker'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.hostHostess'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.hotelStaff'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.housekeeper'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.kitchenStaff'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.manager'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.pastryChef'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.restaurantManager'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.sousChef'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.tourTravelGuide'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.travelAgent'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.waiterServer'),
      i18n.t('user:professionOptions.hospitalityFoodAndTourism.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.informationTechnology.title'),
    jobTitles: [
      i18n.t('user:professionOptions.informationTechnology.titles.computerConsultant'),
      i18n.t('user:professionOptions.informationTechnology.titles.computerEngineer'),
      i18n.t('user:professionOptions.informationTechnology.titles.dataCentreManager'),
      i18n.t('user:professionOptions.informationTechnology.titles.dataEntryClerk'),
      i18n.t('user:professionOptions.informationTechnology.titles.electronics'),
      i18n.t('user:professionOptions.informationTechnology.titles.executiveSeniorManagementInformationTechnology'),
      i18n.t('user:professionOptions.informationTechnology.titles.informationSystems'),
      i18n.t('user:professionOptions.informationTechnology.titles.projectManager'),
      i18n.t('user:professionOptions.informationTechnology.titles.specialist'),
      i18n.t('user:professionOptions.informationTechnology.titles.multimediaProgrammer'),
      i18n.t('user:professionOptions.informationTechnology.titles.productSpecialist'),
      i18n.t('user:professionOptions.informationTechnology.titles.softwareEngineer'),
      i18n.t('user:professionOptions.informationTechnology.titles.switchboardOperator'),
      i18n.t('user:professionOptions.informationTechnology.titles.technicalSupportAgent'),
      i18n.t('user:professionOptions.informationTechnology.titles.telecommunicationsInstaller'),
      i18n.t('user:professionOptions.informationTechnology.titles.telecommunicationsSpecialist'),
      i18n.t('user:professionOptions.informationTechnology.titles.webDesigner'),
      i18n.t('user:professionOptions.informationTechnology.titles.productManager'),
      i18n.t('user:professionOptions.informationTechnology.titles.techExecutive'),
      i18n.t('user:professionOptions.informationTechnology.titles.uIUXDesigner'),
      i18n.t('user:professionOptions.informationTechnology.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.legalServices.title'),
    jobTitles: [
      i18n.t('user:professionOptions.legalServices.titles.administrativeAssistant'),
      i18n.t('user:professionOptions.legalServices.titles.arbitrator'),
      i18n.t('user:professionOptions.legalServices.titles.articlingStudent'),
      i18n.t('user:professionOptions.legalServices.titles.bailiff'),
      i18n.t('user:professionOptions.legalServices.titles.byLawOfficer'),
      i18n.t('user:professionOptions.legalServices.titles.courtOfficer'),
      i18n.t('user:professionOptions.legalServices.titles.courtReporter'),
      i18n.t('user:professionOptions.legalServices.titles.judge'),
      i18n.t('user:professionOptions.legalServices.titles.lawClerk'),
      i18n.t('user:professionOptions.legalServices.titles.lawyer'),
      i18n.t('user:professionOptions.legalServices.titles.legalSupportActivities'),
      i18n.t('user:professionOptions.legalServices.titles.magistrateMaster'),
      i18n.t('user:professionOptions.legalServices.titles.notary'),
      i18n.t('user:professionOptions.legalServices.titles.paralegal'),
      i18n.t('user:professionOptions.legalServices.titles.paroleOfficer'),
      i18n.t('user:professionOptions.legalServices.titles.probationOfficer'),
      i18n.t('user:professionOptions.legalServices.titles.registrar'),
      i18n.t('user:professionOptions.legalServices.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.medicalHealthAndCareGiving.title'),
    jobTitles: [
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.acupuncturist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.audiologist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.cardiologist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.chiropractor'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.counsellor'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.coroner'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.dayCareWorker'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.dentalHygienist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.dentist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.dietitian'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.dispatcher'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.doctor'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.fosterParent'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.funeralHomeWorker'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.healthCareCounsellor'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.homeCareWorker'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.homeopath'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.hospitalAdministrator'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.inspector'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.laboratoryTechnician'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.massageTherapist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.medicalTechnician'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.midwife'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.medicalImagingSpecialist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.mortician'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.nanny'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.naturopath'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.nurse'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.nutritionist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.occupationalTherapist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.ophthalmologist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.optician'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.optometrist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.paramedic'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.pharmacyTechnician'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.pathologist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.personalSupportWorker'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.pharmacist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.physiotherapist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.podiatrist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.psychologist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.radiologist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.rehabilitationSpecialist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.respiratoryTherapist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.socialServicesWorker'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.speechPathologist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.surgeon'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.therapist'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.veterinarian'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.veterinarianTechnician'),
      i18n.t('user:professionOptions.medicalHealthAndCareGiving.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.militaryServices.title'),
    jobTitles: [],
  },
  {
    profession: i18n.t('user:professionOptions.realEstateAndRelated.title'),
    jobTitles: [
      i18n.t('user:professionOptions.realEstateAndRelated.titles.appraiser'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.homeBuilder'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.homeInspector'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.landDeveloper'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.landSurveyor'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.mortgageBroker'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.propertyManager'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.propertyAssessor'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.realEstateAgent'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.renovator'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.realEstateBroker'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.realEstateAssessor'),
      i18n.t('user:professionOptions.realEstateAndRelated.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.religiousVocation.title'),
    jobTitles: [
      i18n.t('user:professionOptions.religiousVocation.titles.chaplain'),
      i18n.t('user:professionOptions.religiousVocation.titles.clergyman'),
      i18n.t('user:professionOptions.religiousVocation.titles.deacon'),
      i18n.t('user:professionOptions.religiousVocation.titles.imam'),
      i18n.t('user:professionOptions.religiousVocation.titles.minister'),
      i18n.t('user:professionOptions.religiousVocation.titles.missionary'),
      i18n.t('user:professionOptions.religiousVocation.titles.monk'),
      i18n.t('user:professionOptions.religiousVocation.titles.monsignor'),
      i18n.t('user:professionOptions.religiousVocation.titles.nun'),
      i18n.t('user:professionOptions.religiousVocation.titles.priest'),
      i18n.t('user:professionOptions.religiousVocation.titles.rabbi'),
      i18n.t('user:professionOptions.religiousVocation.titles.religiousWorker'),
      i18n.t('user:professionOptions.religiousVocation.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.salesRepairAndRetailService.title'),
    jobTitles: [
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.animalCareWorker'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.animalTrainer'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.applianceRepairTechnician'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.auctioneer'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.automotiveRepair'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.automotiveSales'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.barber'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.buyer'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.callCentreRepresentative'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.cashier'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.cleaningServiceProvider'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.cosmetologist'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.customerServiceRepresentative'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.dryCleaner'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.electrologist'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.esthetician'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.executiveSeniorManagementSalesRepairAndRetailService'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.exterminatorPestControl'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.franchiseBusinessOwner'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.hairdresser'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.importerExporter'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.installer'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.jeweler'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.managerSalesRepairAndRetailService'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.marketingRepresentative'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.merchandiser'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.retailWorker'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.salesAndRetailSupportWorker'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.salesperson'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.seamstressTailor'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.serviceStationAttendant'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.shoeRepairMaker'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.smallEngineTechnician'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.spaServiceProvider'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.storeManager'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.telemarketer'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.televisionTechnician'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.upholsterer'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.visualDisplayMerchandisingSpecialist'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.windowWasher'),
      i18n.t('user:professionOptions.salesRepairAndRetailService.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.sportsAndLeisure.title'),
    jobTitles: [
      i18n.t('user:professionOptions.sportsAndLeisure.titles.amusementParkWorker'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.coach'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.lifeguard'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.personalServicesWorker'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.personalTrainer'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.professionalAthlete'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.recreationalGuide'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.sportsAgent'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.sportsInstructor'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.sportsManager'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.sportsOfficial'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.sportsTherapist'),
      i18n.t('user:professionOptions.sportsAndLeisure.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.tradesConstructionAndManufacturing.title'),
    jobTitles: [
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.apprentice'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.assemblyWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.blacksmith'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.boilermaker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.bricklayer'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.builder'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.cabinetMaker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.carpenter'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.concreteWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.constructionWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.craneOperator'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.cutter'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.developer'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.draftingDesign'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.driller'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.drywaller'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.electrician'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.elevatorTechnician'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.engineer'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.executiveSeniorManagementTradesConstructionAndManufacturing'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.fabricator'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.factoryWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.finisher'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.forkliftOperator'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.foundryWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.framer'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.gasFitter'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.generalContractor'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.glassFormerFinisher'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.heavyDutyMechanic'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.heavyEquipmentOperator'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.hVACInstaller'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.inspector'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.installer'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.ironworker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.labourer'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.landscaper'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.locksmith'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.machineFitter'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.machineOperator'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.manager'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.manufacturingWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.mechanic'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.metalWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.millwright'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.oilPipelineWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.painter'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.paver'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.pipefitter'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.plasterer'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.plumber'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.purchasingManager'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.qualityAssuranceAnalyst'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.recycler'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.renovator'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.roofer'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.sheetMetalWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.skilledLabourer'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.steamfitter'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.surveyor'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.textileWorker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.tileSetter'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.toolAndDieMaker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.tradesperson'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.welder'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.weaver'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.woodworker'),
      i18n.t('user:professionOptions.tradesConstructionAndManufacturing.titles.other'),
    ],
  },
  {
    profession: i18n.t('user:professionOptions.transportationAndUtilities.title'),
    jobTitles: [
      i18n.t('user:professionOptions.transportationAndUtilities.titles.airTrafficController'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.aircraftTechnician'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.airlineAgent'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.baggageHandler'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.busDriver'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.cargoWorker'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.conductor'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.courierDelivery'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.customsInspection'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.deliveryDriver'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.dockWorker'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.drillerBlaster'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.executiveSeniorManagementTransportationAndUtilities'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.ferryWorker'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.flightAttendant'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.flightInstructor'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.heatingServiceProvider'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.inspector'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.janitor'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.limoDriverChauffeur'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.logisticsAnalyst'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.mover'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.oilHeating'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.pilot'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.publicWorksMaintenance'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.railwayMarineTransportWorker'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.shipmentBroker'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.shipperReceiver'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.taxiDriver'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.transportationAndUtilitiesSupportActivities'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.truckDriver'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.utilityWorker'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.wellDrillersOperators'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.wellSiteSupervisor'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.waterTreatmentTechnician'),
      i18n.t('user:professionOptions.transportationAndUtilities.titles.other'),
    ],
  },
];

export const pmamSourceOfWealth = [
  {
    key: 'EMPLOYMENT_INCOME',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.employmentIncome'),
  },
  {
    key: 'SALE_OF_LARGE_ASSET',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.saleOfLargeAsset'),
  },
  {
    key: 'PROPERTY_SALE',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.propertySale'),
  },
  {
    key: 'INHERITANCE',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.inheritance'),
  },
  {
    key: 'FAMILY_ESTATE',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.familyEstate'),
  },
  {
    key: 'INVESTMENT',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.investment'),
  },
  {
    key: 'BUSINESS_OWNERSHIP_INTEREST',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.businessOwnershipAndInterest'),
  },
  {
    key: 'DEBT',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.debt'),
  },
  {
    key: 'LOTTERY_WINNING',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.lotteryWinning'),
  },
  {
    key: 'OTHER',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.other'),
  },
];
export const pmamSourceOfFunds = [
  {
    key: 'EMPLOYMENT_INCOME',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.employmentIncome'),
  },
  {
    key: 'PERSONAL_SAVINGS',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.personalSavings'),
  },
  {
    key: 'SHARES_SALES_AND_DIVIDENDS',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.sharesSalesAndDividends'),
  },
  {
    key: 'SALE_OF_LARGE_ASSET',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.saleOfLargeAsset'),
  },
  {
    key: 'PROPERTY_SALE',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.propertySale'),
  },
  {
    key: 'INHERITANCES_AND_GIFT',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.inheritanceAndGift'),
  },
  {
    key: 'PENSION_RELEASE',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.pensionRelease'),
  },
  {
    key: 'DEBT',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.debt'),
  },
  {
    key: 'LOTTERY_WINNING',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.lotteryWinning'),
  },
  {
    key: 'OTHER',
    translatedName: i18n.t('user:howToInvest.sourcesOfWealthAndFundsOptions.other'),
  },
];
export const pmamEmploymentSituation = [
  {
    key: 'STABLE_AND_SECURE',
    translatedName: i18n.t('user:employement.employmentSituationOptions.stableAndSecure'),
  },
  {
    key: 'STABLE_BUT_MAY_CHANGE_IN_FUTURE',
    translatedName: i18n.t('user:employement.employmentSituationOptions.stableButMayChangeInTheFuture'),
  },
  {
    key: 'SOMEWHAT_STABLE',
    translatedName: i18n.t('user:employement.employmentSituationOptions.somewhatStable'),
  },
  {
    key: 'UNEMPLOYED_BUT_LOOKING',
    translatedName: i18n.t('user:employement.employmentSituationOptions.unemployedButLooking'),
  },
  {
    key: 'OTHER',
    translatedName: i18n.t('user:employement.employmentSituationOptions.other'),
  },
];

export const areasOfStudies = [
  i18n.t('user:areasOfStudies.aeronauticsAndAviationScience'),
  i18n.t('user:areasOfStudies.anthropology'),
  i18n.t('user:areasOfStudies.art'),
  i18n.t('user:areasOfStudies.businessAdministration'),
  i18n.t('user:areasOfStudies.chemistry'),
  i18n.t('user:areasOfStudies.economics'),
  i18n.t('user:areasOfStudies.education'),
  i18n.t('user:areasOfStudies.engineering'),
  i18n.t('user:areasOfStudies.environmentalScienceAndHealth'),
  i18n.t('user:areasOfStudies.journalismAndMassCommunication'),
  i18n.t('user:areasOfStudies.music'),
  i18n.t('user:areasOfStudies.nursing'),
  i18n.t('user:areasOfStudies.oceanography'),
  i18n.t('user:areasOfStudies.pharmacy'),
  i18n.t('user:areasOfStudies.photography'),
  i18n.t('user:areasOfStudies.physicalTherapy'),
  i18n.t('user:areasOfStudies.politicalScienceAndInternationalRelations'),
  i18n.t('user:areasOfStudies.psychology'),
  i18n.t('user:areasOfStudies.publicRelationsAndAdministration'),
  i18n.t('user:areasOfStudies.statistics'),
  i18n.t('user:areasOfStudies.urbanPlanning'),
];
export const employementOptions = [
  {
    tag: 'Employed',
    key: 'EMPLOYED',
    translatedName: i18n.t('user:employementOptions.employed'),
  },
  {
    tag: 'Self-employed',
    key: 'SELF_EMPLOYED',
    translatedName: i18n.t('user:employementOptions.selfEmployed'),
  },
  {
    tag: 'Retired',
    key: 'RETIRED',
    translatedName: i18n.t('user:employementOptions.retired'),
  },
  {
    tag: 'Unemployed',
    key: 'UNEMPLOYED',
    translatedName: i18n.t('user:employementOptions.unemployed'),
  },
  {
    tag: 'Student',
    key: 'STUDENT',
    translatedName: i18n.t('user:employementOptions.student'),
  },
];

export interface Country {
  name: string,
  code: string,
  icon: string,
}

export const countryList: Country[] = [
  { name: 'Afghanistan', code: 'AF', icon: AF },
  { name: 'Aland Islands', code: 'AX', icon: AX },
  { name: 'Albania', code: 'AL', icon: AL },
  { name: 'Algeria', code: 'DZ', icon: DZ },
  { name: 'American Samoa', code: 'AS', icon: AS },
  { name: 'Andorra', code: 'AD', icon: AD },
  { name: 'Angola', code: 'AO', icon: AO },
  { name: 'Anguilla', code: 'AI', icon: AI },
  { name: 'Antarctica', code: 'AQ', icon: AQ },
  { name: 'Antigua and Barbuda', code: 'AG', icon: AG },
  { name: 'Argentina', code: 'AR', icon: AR },
  { name: 'Armenia', code: 'AM', icon: AM },
  { name: 'Aruba', code: 'AW', icon: AW },
  { name: 'Australia', code: 'AU', icon: AU },
  { name: 'Austria', code: 'AT', icon: AT },
  { name: 'Azerbaijan', code: 'AZ', icon: AZ },
  { name: 'Bahamas (the)', code: 'BS', icon: BS },
  { name: 'Bahrain', code: 'BH', icon: BH },
  { name: 'Bangladesh', code: 'BD', icon: BD },
  { name: 'Barbados', code: 'BB', icon: BB },
  { name: 'Belarus', code: 'BY', icon: BY },
  { name: 'Belgium', code: 'BE', icon: BE },
  { name: 'Belize', code: 'BZ', icon: BZ },
  { name: 'Benin', code: 'BJ', icon: BJ },
  { name: 'Bermuda', code: 'BM', icon: BM },
  { name: 'Bhutan', code: 'BT', icon: BT },
  { name: 'Bolivia (Plurinational State of)', code: 'BO', icon: BO },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ', icon: BQ },
  { name: 'Bosnia and Herzegovina', code: 'BA', icon: BA },
  { name: 'Botswana', code: 'BW', icon: BW },
  { name: 'Bouvet Island', code: 'BV', icon: BV },
  { name: 'Brazil', code: 'BR', icon: BR },
  { name: 'British Indian Ocean Territory (the)', code: 'IO', icon: IO },
  { name: 'Brunei Darussalam', code: 'BN', icon: BN },
  { name: 'Bulgaria', code: 'BG', icon: BG },
  { name: 'Burkina Faso', code: 'BF', icon: BF },
  { name: 'Burundi', code: 'BI', icon: BI },
  { name: 'Cabo Verde', code: 'CV', icon: CV },
  { name: 'Cambodia', code: 'KH', icon: KH },
  { name: 'Cameroon', code: 'CM', icon: CM },
  { name: 'Canada', code: 'CA', icon: CA },
  { name: 'Cayman Islands (the)', code: 'KY', icon: KY },
  { name: 'Central African Republic (the)', code: 'CF', icon: CF },
  { name: 'Chad', code: 'TD', icon: TD },
  { name: 'Chile', code: 'CL', icon: CL },
  { name: 'China', code: 'CN', icon: CN },
  { name: 'Christmas Island', code: 'CX', icon: CX },
  { name: 'Cocos (Keeling) Islands (the)', code: 'CC', icon: CC },
  { name: 'Colombia', code: 'CO', icon: CO },
  { name: 'Comoros (the)', code: 'KM', icon: KM },
  { name: 'Congo (the Democratic Republic of the)', code: 'CD', icon: CD },
  { name: 'Congo (the)', code: 'CG', icon: CG },
  { name: 'Cook Islands (the)', code: 'CK', icon: CK },
  { name: 'Costa Rica', code: 'CR', icon: CR },
  { name: 'Cote d\'Ivoire', code: 'CI', icon: CI },
  { name: 'Croatia', code: 'HR', icon: HR },
  { name: 'Cuba', code: 'CU', icon: CU },
  { name: 'Curacao', code: 'CW', icon: CW },
  { name: 'Cyprus', code: 'CY', icon: CY },
  { name: 'Czech Republic (the)', code: 'CZ', icon: CZ },
  { name: 'Denmark', code: 'DK', icon: DK },
  { name: 'Djibouti', code: 'DJ', icon: DJ },
  { name: 'Dominica', code: 'DM', icon: DM },
  { name: 'Dominican Republic (the)', code: 'DO', icon: DO },
  { name: 'Ecuador', code: 'EC', icon: EC },
  { name: 'Egypt', code: 'EG', icon: EG },
  { name: 'El Salvador', code: 'SV', icon: SV },
  { name: 'Equatorial Guinea', code: 'GQ', icon: GQ },
  { name: 'Eritrea', code: 'ER', icon: ER },
  { name: 'Estonia', code: 'EE', icon: EE },
  { name: 'Ethiopia', code: 'ET', icon: ET },
  { name: 'Falkland Islands (the) [Malvinas]', code: 'FK', icon: FK },
  { name: 'Faroe Islands (the)', code: 'FO', icon: FO },
  { name: 'Fiji', code: 'FJ', icon: FJ },
  { name: 'Finland', code: 'FI', icon: FI },
  { name: 'France', code: 'FR', icon: FR },
  { name: 'French Guiana', code: 'GF', icon: GF },
  { name: 'French Polynesia', code: 'PF', icon: PF },
  { name: 'French Southern Territories (the)', code: 'TF', icon: TF },
  { name: 'Gabon', code: 'GA', icon: GA },
  { name: 'Gambia (the)', code: 'GM', icon: GM },
  { name: 'Georgia', code: 'GE', icon: GE },
  { name: 'Germany', code: 'DE', icon: DE },
  { name: 'Ghana', code: 'GH', icon: GH },
  { name: 'Gibraltar', code: 'GI', icon: GI },
  { name: 'Greece', code: 'GR', icon: GR },
  { name: 'Greenland', code: 'GL', icon: GL },
  { name: 'Grenada', code: 'GD', icon: GD },
  { name: 'Guadeloupe', code: 'GP', icon: GP },
  { name: 'Guam', code: 'GU', icon: GU },
  { name: 'Guatemala', code: 'GT', icon: GT },
  { name: 'Guernsey', code: 'GG', icon: GG },
  { name: 'Guinea', code: 'GN', icon: GN },
  { name: 'Guinea-Bissau', code: 'GW', icon: GW },
  { name: 'Guyana', code: 'GY', icon: GY },
  { name: 'Haiti', code: 'HT', icon: HT },
  { name: 'Heard Island and McDonald Islands', code: 'HM', icon: HM },
  { name: 'Holy See (the)', code: 'VA', icon: VA },
  { name: 'Honduras', code: 'HN', icon: HN },
  { name: 'Hong Kong', code: 'HK', icon: HK },
  { name: 'Hungary', code: 'HU', icon: HU },
  { name: 'Iceland', code: 'IS', icon: IS },
  { name: 'India', code: 'IN', icon: IN },
  { name: 'Indonesia', code: 'ID', icon: ID },
  { name: 'Iran (Islamic Republic of)', code: 'IR', icon: IR },
  { name: 'Iraq', code: 'IQ', icon: IQ },
  { name: 'Ireland', code: 'IE', icon: IE },
  { name: 'Isle of Man', code: 'IM', icon: IM },
  { name: 'Israel', code: 'IL', icon: IL },
  { name: 'Italy', code: 'IT', icon: IT },
  { name: 'Jamaica', code: 'JM', icon: JM },
  { name: 'Japan', code: 'JP', icon: JP },
  { name: 'Jersey', code: 'JE', icon: JE },
  { name: 'Jordan', code: 'JO', icon: JO },
  { name: 'Kazakhstan', code: 'KZ', icon: KZ },
  { name: 'Kenya', code: 'KE', icon: KE },
  { name: 'Kiribati', code: 'KI', icon: KI },
  { name: 'Korea (the Democratic People\'s Republic of)', code: 'KP', icon: KP },
  { name: 'Korea (the Republic of)', code: 'KR', icon: KR },
  { name: 'Kuwait', code: 'KW', icon: KW },
  { name: 'Kyrgyzstan', code: 'KG', icon: KG },
  { name: 'Lao People\'s Democratic Republic (the)', code: 'LA', icon: LA },
  { name: 'Latvia', code: 'LV', icon: LV },
  { name: 'Lebanon', code: 'LB', icon: LB },
  { name: 'Lesotho', code: 'LS', icon: LS },
  { name: 'Liberia', code: 'LR', icon: LR },
  { name: 'Libya', code: 'LY', icon: LY },
  { name: 'Liechtenstein', code: 'LI', icon: LI },
  { name: 'Lithuania', code: 'LT', icon: LT },
  { name: 'Luxembourg', code: 'LU', icon: LU },
  { name: 'Macao', code: 'MO', icon: MO },
  { name: 'Macedonia (the former Yugoslav Republic of)', code: 'MK', icon: MK },
  { name: 'Madagascar', code: 'MG', icon: MG },
  { name: 'Malawi', code: 'MW', icon: MW },
  { name: 'Malaysia', code: 'MY', icon: MY },
  { name: 'Maldives', code: 'MV', icon: MV },
  { name: 'Mali', code: 'ML', icon: ML },
  { name: 'Malta', code: 'MT', icon: MT },
  { name: 'Marshall Islands (the)', code: 'MH', icon: MH },
  { name: 'Martinique', code: 'MQ', icon: MQ },
  { name: 'Mauritania', code: 'MR', icon: MR },
  { name: 'Mauritius', code: 'MU', icon: MU },
  { name: 'Mayotte', code: 'YT', icon: YT },
  { name: 'Mexico', code: 'MX', icon: MX },
  { name: 'Micronesia (Federated States of)', code: 'FM', icon: FM },
  { name: 'Moldova (the Republic of)', code: 'MD', icon: MD },
  { name: 'Monaco', code: 'MC', icon: MC },
  { name: 'Mongolia', code: 'MN', icon: MN },
  { name: 'Montenegro', code: 'ME', icon: ME },
  { name: 'Montserrat', code: 'MS', icon: MS },
  { name: 'Morocco', code: 'MA', icon: MA },
  { name: 'Mozambique', code: 'MZ', icon: MZ },
  { name: 'Myanmar', code: 'MM', icon: MM },
  { name: 'Namibia', code: 'NA', icon: NA },
  { name: 'Nauru', code: 'NR', icon: NR },
  { name: 'Nepal', code: 'NP', icon: NP },
  { name: 'Netherlands (the)', code: 'NL', icon: NL },
  { name: 'New Caledonia', code: 'NC', icon: NC },
  { name: 'New Zealand', code: 'NZ', icon: NZ },
  { name: 'Nicaragua', code: 'NI', icon: NI },
  { name: 'Niger (the)', code: 'NE', icon: NE },
  { name: 'Nigeria', code: 'NG', icon: NG },
  { name: 'Niue', code: 'NU', icon: NU },
  { name: 'Norfolk Island', code: 'NF', icon: NF },
  { name: 'Northern Mariana Islands (the)', code: 'MP', icon: MP },
  { name: 'Norway', code: 'NO', icon: NO },
  { name: 'Oman', code: 'OM', icon: OM },
  { name: 'Pakistan', code: 'PK', icon: PK },
  { name: 'Palau', code: 'PW', icon: PW },
  { name: 'Palestine, State of', code: 'PS', icon: PS },
  { name: 'Panama', code: 'PA', icon: PA },
  { name: 'Papua New Guinea', code: 'PG', icon: PG },
  { name: 'Paraguay', code: 'PY', icon: PY },
  { name: 'Peru', code: 'PE', icon: PE },
  { name: 'Philippines (the)', code: 'PH', icon: PH },
  { name: 'Pitcairn', code: 'PN', icon: PN },
  { name: 'Poland', code: 'PL', icon: PL },
  { name: 'Portugal', code: 'PT', icon: PT },
  { name: 'Puerto Rico', code: 'PR', icon: PR },
  { name: 'Qatar', code: 'QA', icon: QA },
  { name: 'Réunion', code: 'RE', icon: RE },
  { name: 'Romania', code: 'RO', icon: RO },
  { name: 'Russian Federation (the)', code: 'RU', icon: RU },
  { name: 'Rwanda', code: 'RW', icon: RW },
  { name: 'Saint Barthélemy', code: 'BL', icon: BL },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH', icon: SH },
  { name: 'Saint Kitts and Nevis', code: 'KN', icon: KN },
  { name: 'Saint Lucia', code: 'LC', icon: LC },
  { name: 'Saint Martin (French part)', code: 'MF', icon: MF },
  { name: 'Saint Pierre and Miquelon', code: 'PM', icon: PM },
  { name: 'Saint Vincent and the Grenadines', code: 'VC', icon: VC },
  { name: 'Samoa', code: 'WS', icon: WS },
  { name: 'San Marino', code: 'SM', icon: SM },
  { name: 'Sao Tome and Principe', code: 'ST', icon: ST },
  { name: 'Saudi Arabia', code: 'SA', icon: SA },
  { name: 'Senegal', code: 'SN', icon: SN },
  { name: 'Serbia', code: 'RS', icon: RS },
  { name: 'Seychelles', code: 'SC', icon: SC },
  { name: 'Sierra Leone', code: 'SL', icon: SL },
  { name: 'Singapore', code: 'SG', icon: SG },
  { name: 'Sint Maarten (Dutch part)', code: 'SX', icon: SX },
  { name: 'Slovakia', code: 'SK', icon: SK },
  { name: 'Slovenia', code: 'SI', icon: SI },
  { name: 'Solomon Islands', code: 'SB', icon: SB },
  { name: 'Somalia', code: 'SO', icon: SO },
  { name: 'South Africa', code: 'ZA', icon: ZA },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS', icon: GS },
  { name: 'South Sudan', code: 'SS', icon: SS },
  { name: 'Spain', code: 'ES', icon: ES },
  { name: 'Sri Lanka', code: 'LK', icon: LK },
  { name: 'Sudan (the)', code: 'SD', icon: SD },
  { name: 'Suriname', code: 'SR', icon: SR },
  { name: 'Svalbard and Jan Mayen', code: 'SJ', icon: SJ },
  { name: 'Swaziland', code: 'SZ', icon: SZ },
  { name: 'Sweden', code: 'SE', icon: SE },
  { name: 'Switzerland', code: 'CH', icon: CH },
  { name: 'Syrian Arab Republic', code: 'SY', icon: SY },
  { name: 'Taiwan', code: 'TW', icon: TW },
  { name: 'Tajikistan', code: 'TJ', icon: TJ },
  { name: 'Tanzania, United Republic of', code: 'TZ', icon: TZ },
  { name: 'Thailand', code: 'TH', icon: TH },
  { name: 'Timor-Leste', code: 'TL', icon: TL },
  { name: 'Togo', code: 'TG', icon: TG },
  { name: 'Tokelau', code: 'TK', icon: TK },
  { name: 'Tonga', code: 'TO', icon: TO },
  { name: 'Trinidad and Tobago', code: 'TT', icon: TT },
  { name: 'Tunisia', code: 'TN', icon: TN },
  { name: 'Turkey', code: 'TR', icon: TR },
  { name: 'Turkmenistan', code: 'TM', icon: TM },
  { name: 'Turks and Caicos Islands (the)', code: 'TC', icon: TC },
  { name: 'Tuvalu', code: 'TV', icon: TV },
  { name: 'Uganda', code: 'UG', icon: UG },
  { name: 'Ukraine', code: 'UA', icon: UA },
  { name: 'United Arab Emirates (the)', code: 'AE', icon: AE },
  { name: 'United Kingdom of Great Britain and Northern Ireland (the)', code: 'GB', icon: GB },
  { name: 'United States Minor Outlying Islands (the)', code: 'UM', icon: UM },
  { name: 'United States of America (the)', code: 'US', icon: US },
  { name: 'Uruguay', code: 'UY', icon: UY },
  { name: 'Uzbekistan', code: 'UZ', icon: UZ },
  { name: 'Vanuatu', code: 'VU', icon: VU },
  { name: 'Venezuela (Bolivarian Republic of)', code: 'VE', icon: VE },
  { name: 'Viet Nam', code: 'VN', icon: VN },
  { name: 'Virgin Islands (British)', code: 'VG', icon: VG },
  { name: 'Virgin Islands (U.S.)', code: 'VI', icon: VI },
  { name: 'Wallis and Futuna', code: 'WF', icon: WF },
  { name: 'Western Sahara*', code: 'EH', icon: EH },
  { name: 'Yemen', code: 'YE', icon: YE },
  { name: 'Zambia', code: 'ZM', icon: ZM },
  { name: 'Zimbabwe', code: 'ZW', icon: ZW },
];

const updateCountryName = (country: Country): string => {
  if (country.code !== 'TW') {
    return countries.getName(country.code, getBackendLanguage());
  }
  return countries.getName(country.code, getBackendLanguage()).replace(', Province of China', '');
};
export const getFormattedCountry = (): Country[] => {
  const refinedCountryList = countryList.map((country) => ({ ...country, name: updateCountryName(country) }));
  return refinedCountryList.sort((a, b) => (a.name.localeCompare(b.name)));
};

export const annualIncome = [
  {
    name: i18n.t('user:howToInvest.annualIncome.incomeRanges.lessThan35000'),
    value: 15000,
  },
  {
    name: i18n.t('user:howToInvest.annualIncome.incomeRanges.35000To75000'),
    value: 55000,
  },
  {
    name: i18n.t('user:howToInvest.annualIncome.incomeRanges.75000To95000'),
    value: 85000,
  },
  {
    name: i18n.t('user:howToInvest.annualIncome.incomeRanges.95000To125000'),
    value: 110000,
  },
  {
    name: i18n.t('user:howToInvest.annualIncome.incomeRanges.125000To200000'),
    value: 155000,
  },
  {
    name: i18n.t('user:howToInvest.annualIncome.incomeRanges.200000Above'),
    value: 250000,
  },
];

// CONST : maritalStatus
const maritalStatusSingle = 'SINGLE';
const maritalStatusCommonLaw = 'COMMON_LAW';

export const maritalStatus = [
  {
    name: i18n.t('user:howToInvest.maritslStatus.single'),
    value: maritalStatusSingle,
  },
  {
    name: i18n.t('user:howToInvest.maritslStatus.married'),
    value: maritalStatusCommonLaw,
  },
];

const yes = 'YES';
const no = 'NO';

export const hasTrustedContact = [
  {
    name: i18n.t('user:howToInvest.hasTrustedContact.yes'),
    value: yes,
  },
  {
    name: i18n.t('user:howToInvest.hasTrustedContact.no'),
    value: no,
  },
];

const genderMale = 'MALE';
const genderFemale = 'FEMALE';

export const genderList = [
  {
    name: i18n.t('user:howToInvest.gender.male'),
    value: genderMale,
  },
  {
    name: i18n.t('user:howToInvest.gender.female'),
    value: genderFemale,
  },
];

export enum InvestmentKnowledges {
  SOME = 'SOME',
  EXPERT = 'EXPERT',
  LIMITED = 'LIMITED',
  NONE = 'NONE',
}

export enum RiskLevels {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
  LEVEL_4 = 'LEVEL_4',
  LEVEL_5 = 'LEVEL_5',
}

export interface RiskQuestion {
  level: RiskLevels,
  question: string,
  subText?: string,
}

export interface InvestmentKnowedgeQuestion {
  level: string,
  value: InvestmentKnowledges,
  subText?: string,
  icon?: string,
}
interface InvestmentKnowledgeLevel {
  level: string,
  subText: string,
  value: InvestmentKnowledges,
  icon: string,
}
export const getOrgBasedInvestmentKnowledgeLevels = (orgCode?: string): InvestmentKnowledgeLevel[] => {
  const namespace = orgCode ? `orgSpecific_${orgCode}:user.` : 'user:';
  const orgBasedKnowledgeLevels = [
    {
      level: i18n.t(`${namespace}riskTolarence.resources.invetmentKnowledgeMessages.none.level`),
      subText: i18n.t(`${namespace}riskTolarence.resources.invetmentKnowledgeMessages.none.subText`),
      value: InvestmentKnowledges.NONE,
      icon: SocialThemeIcon,
    },
    {
      level: i18n.t(`${namespace}riskTolarence.resources.invetmentKnowledgeMessages.limited.level`),
      subText: i18n.t(`${namespace}riskTolarence.resources.invetmentKnowledgeMessages.limited.subText`),
      value: InvestmentKnowledges.LIMITED,
      icon: SocialThemeIcon,
    },
    {
      level: i18n.t(`${namespace}riskTolarence.resources.invetmentKnowledgeMessages.some.level`),
      subText: i18n.t(`${namespace}riskTolarence.resources.invetmentKnowledgeMessages.some.subText`),
      value: InvestmentKnowledges.SOME,
      icon: SocialThemeIcon,
    },
    {
      level: i18n.t(`${namespace}riskTolarence.resources.invetmentKnowledgeMessages.expert.level`),
      subText: i18n.t(`${namespace}riskTolarence.resources.invetmentKnowledgeMessages.expert.subText`),
      value: InvestmentKnowledges.EXPERT,
      icon: SocialThemeIcon,
    },
  ];
  return orgBasedKnowledgeLevels;
};

interface RiskQuestions2 {
  level: RiskLevels,
  question: string,
  subText: string,
}
export const getOrgBasedRiskQuestion2 = (orgCode?: string): RiskQuestions2[] => {
  const namespace = orgCode ? `orgSpecific_${orgCode}:user.` : 'user:';
  const riskQuestions2List = [
    {
      question: i18n.t(`${namespace}riskTolarence.resources.riskQuestion2.level1.question`),
      subText: i18n.t(`${namespace}riskTolarence.resources.riskQuestion2.level1.subText`),
      level: RiskLevels.LEVEL_1,
    },
    {
      question: i18n.t(`${namespace}riskTolarence.resources.riskQuestion2.level3.question`),
      subText: i18n.t(`${namespace}riskTolarence.resources.riskQuestion2.level3.subText`),
      level: RiskLevels.LEVEL_3,
    },
    {
      question: i18n.t(`${namespace}riskTolarence.resources.riskQuestion2.level4.question`),
      subText: i18n.t(`${namespace}riskTolarence.resources.riskQuestion2.level4.subText`),
      level: RiskLevels.LEVEL_4,
    },
    {
      question: i18n.t(`${namespace}riskTolarence.resources.riskQuestion2.level5.question`),
      subText: i18n.t(`${namespace}riskTolarence.resources.riskQuestion2.level5.subText`),
      level: RiskLevels.LEVEL_5,
    },
  ];
  return riskQuestions2List;
};
export const riskQuestions1List = [
  {
    question: i18n.t('user:riskTolarence.resources.riskQuestion1.level5.question'),
    level: RiskLevels.LEVEL_5,
  },
  {
    question: i18n.t('user:riskTolarence.resources.riskQuestion1.level3.question'),
    level: RiskLevels.LEVEL_3,
  },
  {
    question: i18n.t('user:riskTolarence.resources.riskQuestion1.level1.question'),
    level: RiskLevels.LEVEL_1,
  },
];

export const getTranslatedEmployementStatus = (key: string): string => (employementOptions.filter((option) => option.key.trim() === key.trim())[0].translatedName || '');

export const smartScrollStyles = {
  trackProps: {
    borderRadius: '5px',
  },
  thumb: {
    width: 10,
    backgroundColor: colors.scrollBarThumbBackgroundColor,
    height: 200,
    borderRadius: '5px',
  },
};
