/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import OvToast from '../components/common/modals/ovToast';

export interface ToastProps {
  message: string,
  severity: 'success'|'error'|'info'|'none',
  title: string,
}

export type showToastType = (props: ToastProps) => void;

type ToastContextType = {
  showToast: showToastType,
};
const ToastContext = createContext<ToastContextType>({ showToast: () => ({ message: '', severity: 'none' }) });

const ToastContextProvider = ({ children }: { children: any}): JSX.Element => {
  const [globalSnackbar, setGlobalSnackbar] = useState<{ open: boolean, props?: ToastProps }>({ open: false });
  const closeSnackbar = (): void => setGlobalSnackbar({ open: false });

  const showToast = useCallback((props: ToastProps) => {
    setGlobalSnackbar({ open: true, props });
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <OvToast
        open={globalSnackbar.open}
        handleClose={closeSnackbar}
        type={globalSnackbar?.props?.severity ?? 'none'}
        title={globalSnackbar?.props?.title || ''}
        content={globalSnackbar?.props?.message || ''}
      />
    </ToastContext.Provider>
  );
};

const useGlobalToast = (): ToastContextType => useContext(ToastContext);

export {
  ToastContextProvider, useGlobalToast,
};

export default ToastContextProvider;
