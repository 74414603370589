/* eslint-disable operator-linebreak */

import {
  Typography, List, Box, Button, Link,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Persona, { Client } from 'persona';
import { Fields, InquiryError } from 'persona/dist/lib/interfaces';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { FormEvent, useContext, useState } from 'react';
import PersonaStepsListItem from '../common/lists/personaStepsListItem';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import { UserContext } from '../../providers/userContextProvider';
import { getLanguageForPersona } from '../../assets/i18n/config';
import { USER_PROVINCE } from '../user/graphql';
import { availableFeatureFlagsVar, userEmailVar } from '../../utils/localVariables';
import { FeatureFlagTypes } from '../account/resources';
import FlowModal from '../common/wrappers/modals/ovFlowModal';
import { colors } from '../../theme/colors';

dayjs.extend(utc);

export interface UserState {
  id?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  primaryEmail?: string,
  dateOfBirth?: Date,
  phone?: string,
}

interface Props {
  user: UserState,
  loading: boolean,
  updateLoading: (att: boolean) => void,
  onContinue?: () => void,
  onBypass?: () => void,
  goToNamedStep?: (step: string) => void,
  onComplete: ({ inquiryId, status, fields }: { inquiryId: string; status: string; fields: Fields; }) => void,
  onCancel: ({ inquiryId, sessionToken }: { inquiryId?: string; sessionToken?: string; }) => void,
  onError: (error: InquiryError) => void,
}

const Information = ({
  user,
  loading,
  updateLoading,
  goToNamedStep,
  ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'identity']);
  const { userContext } = useContext(UserContext);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { data } = useQuery(USER_PROVINCE, {
    variables: {},
    fetchPolicy: 'no-cache',
  });

  const bypassVerificationAllowed = availableFeatureFlagsVar().includes(FeatureFlagTypes.BYPASS_ID_VERIFICATION) && data?.me?.user?.physicalAddress?.province === 'QC';
  const openPersona = (event : FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    updateLoading(true);
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingIdentityContinue).then();
    const externalTemplateId = userContext.organization?.externalProvider?.idVerificationProvider.templateId;
    const client: Client = new Persona.Client({
      templateId: externalTemplateId || process.env.REACT_APP_PERSONA_TEMPLATE_ID,
      environment: process.env.REACT_APP_PERSONA_ENV as 'sandbox' | 'production',
      referenceId: user.id,
      language: getLanguageForPersona(),
      fields: {
        nameFirst: user.firstName,
        nameMiddle: user.middleName,
        nameLast: user.lastName,
        birthdate: user.dateOfBirth ? dayjs(user.dateOfBirth).utc().format('YYYY-MM-DD') : '',
        emailAddress: user.primaryEmail ?? '',
        phoneNumber: user.phone ?? '',
      },
      frameAncestors: userContext.origin ? [window.location.origin, userContext.origin] : undefined,
      messageTargetOrigin: userContext.origin,
      onReady: () => client.open(),
      onComplete: ({ inquiryId, status, fields }) => {
        props.onComplete({ inquiryId, status, fields });
      },
      onCancel: ({ inquiryId, sessionToken }) => {
        updateLoading(false);
        props.onCancel({ inquiryId, sessionToken });
      },
      onError: (error) => {
        sendAnalyticsEvent(ovAnalyticsEvents.personaVerificationError).then();
        updateLoading(false);
        if (goToNamedStep) goToNamedStep('error');
        props.onError(error);
      },
    });
  };

  const continueWithoutVerification = () => {
    setOpenConfirmation(false);
    if (props.onBypass) {
      props.onBypass();
    }
  };

  return (
    loading ? <OvLoadingIndicator />
      :
      (
        <>
          <OvForm
            onSubmit={openPersona}
            buttonText={t('base:button.getStarted')}
            suffixButton={bypassVerificationAllowed ? {
              onClick: () => setOpenConfirmation(true),
              dataTestId: 'bypass-verification-button',
              variant: 'secondary-large',
              type: 'text',
              text: t('identity:information.bypassButton'),
            } : undefined}
          >
            <Typography variant="heading2" data-testid="title">{t('identity:information.title')}</Typography>
            <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('identity:information.subTitle')}</Typography>
            <List style={{ marginBottom: 20 }}>
              <PersonaStepsListItem
                index={1}
                header={t('identity:information.stepOneHeader')}
                body={t('identity:information.stepOneBody')}
                marginBottom={4} />
              <PersonaStepsListItem
                index={2}
                header={t('identity:information.stepTwoHeader')}
                body={t('identity:information.stepTwoBody')} />
            </List>
          </OvForm>
          {bypassVerificationAllowed && (
            <FlowModal
              open={openConfirmation}
              maintainModal
              onClose={() => setOpenConfirmation(false)}
              component={(
                <Box sx={{ maxWidth: '420px' }}>
                  <Typography variant="heading2">{t('identity:information.bypassModal.title')}</Typography>
                  <Typography variant="paragraph3">
                    <span>{t('identity:information.bypassModal.body.beginning')}</span>
                    <Link sx={{ paddingX: '3px' }} href={`https://onevest.zendesk.com/hc/en-us/requests/new?tf_anonymous_requester_email=${userEmailVar()}`} underline="always" target="_blank">{t('identity:information.bypassModal.body.link')}</Link>
                    <span>{t('identity:information.bypassModal.body.end')}</span>
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingX: 2 }}>
                    <Button sx={{ width: '100px', mb: 0 }} variant="secondary-small" onClick={() => setOpenConfirmation(false)}>{t('identity:information.bypassModal.cancel')}</Button>
                    <Button sx={{ width: '100px', border: `${colors.primary} 2px solid` }} variant="primary-small" onClick={continueWithoutVerification}>{t('identity:information.bypassModal.continue')}</Button>
                  </Box>
                </Box>
              )}
            />
          )}
        </>
      )
  );
};

Information.defaultProps = {
  onContinue: undefined,
  onBypass: undefined,
  goToNamedStep: undefined,
};

export default Information;
