/* eslint-disable  @typescript-eslint/no-explicit-any */

import React from 'react';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import CloseIcon from '../../../assets/images/close-icon.svg';
import { InputType } from './utils/inputType';
import { CurrencyFormatter } from './utils/formatters';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface Props {
  label: string,
  value?: string,
  subtitle?: string,
  onContinue?: () => void,
  required?: boolean,
  error?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
  onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  style?: React.CSSProperties,
  testId?: string,
  inputProps?: InputBaseComponentProps,
  inputType?: InputType,
  suffixAdornment?: JSX.Element,
  type?: 'text' | 'number' | 'tel'
}

const SimpleInput = ({ ...props }: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const onClear = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (props.onClear) props.onClear(event);
  };

  const getFormatter = (): any => {
    if (props.inputType === InputType.NAME) return undefined;
    if (props.inputType === InputType.CURRENCY) return CurrencyFormatter;
    return undefined;
  };

  const buildEndAdornment = (): JSX.Element | undefined => {
    if (props.suffixAdornment) {
      return props.suffixAdornment;
    }
    if (!props.value && props.value === '') {
      return undefined;
    }
    return (
      <InputAdornment position="end">
        <IconButton
          tabIndex={-1}
          aria-label="clear input field"
          disableRipple
          onClick={(event) => onClear(event)}
          edge="end"
        >
          <img src={CloseIcon} alt="close" />
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <Stack spacing={2} style={props.style}>
      <FormControl fullWidth variant="standard">
        <InputLabel required={props.required}>{props.label}</InputLabel>
        <Input
          data-testid={props.testId}
          fullWidth
          style={{ width: '100%' }}
          required={props.required}
          error={props.error}
          value={props.value}
          onChange={(event) => {
            if (props.onChange) props.onChange(event);
          }}
          inputComponent={getFormatter()}
          inputProps={props.inputProps}
          endAdornment={buildEndAdornment()}
          type={props.type}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          sx={(props.type && props.type === 'number') ? {
            '& .MuiInput-input': {
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
              },
            },
          } : undefined}
        />
      </FormControl>
      <Typography
        variant="captionSectionerMedium"
        style={{ marginBottom: 16, marginTop: 8, color: props.error ? colors.error : colors.neutralBlack }}
      >
        {props.subtitle}
      </Typography>
    </Stack>
  );
};

SimpleInput.defaultProps = {
  value: '',
  required: false,
  subtitle: '',
  error: false,
  onContinue: undefined,
  onChange: undefined,
  onClear: undefined,
  style: undefined,
  testId: '',
  inputProps: undefined,
  inputType: undefined,
  suffixAdornment: undefined,
  type: undefined,
};

export default SimpleInput;
