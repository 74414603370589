import {
  Box,
  Typography,
} from '@mui/material';
import { formatMoneyValue } from '../../utils/commonMethods';
import { Colors, useContextTheme } from '../../providers/contextThemeProvider';
import MultiLinearProgress from './multiLinearProgressBar';
import { NameAndStats } from '../../pages/goalDetails';

const styles = (colors: Colors) => ({
  listItemWrapper: {
    background: colors.baseComponentBackground,
    borderRadius: '8px',
    border: `1px solid ${colors.portfolioCardBorder}`,
    padding: '16px',
  },
});

interface ProgressBarProps {
  title: string,
  text1: string,
  text2: string,
  number1: number,
  number2: number,
  orderedStatisticsByMember?: NameAndStats[],
}
const ProgressBar = ({ ...props }: ProgressBarProps): JSX.Element => {
  const { colors } = useContextTheme();

  const totalContribution = (props.number1 > props.number2) ? 100 : Math.round((props.number1 / props.number2) * 100);
  const values = (props?.orderedStatisticsByMember ?? []).map((x) => x.statistics?.marketValueCents ?? 0);
  const names = (props?.orderedStatisticsByMember ?? []).map((x) => `${x.name}: ${formatMoneyValue(x.statistics?.marketValueCents ?? 0)}`);
  const sumOfValues = values.reduce((a, b) => a + b, 0);
  const maxValue = sumOfValues > props.number2 ? sumOfValues : props.number2;

  return (
    <Box
      display="block"
      sx={{ mb: '10px' }}>
      <Typography variant="heading5" sx={{ marginBottom: '8px', color: colors.neutral20, textAlign: 'left' }}>{props.title}</Typography>
      <Box
        display="block"
        sx={styles(colors).listItemWrapper}
      >
        <Box sx={{ flexGrow: 1 }}>
          {props.orderedStatisticsByMember
            ? (<MultiLinearProgress maximum={maxValue} values={values} labels={names} />)
            : (<MultiLinearProgress maximum={100} values={[totalContribution]} labels={['']} />)}
        </Box>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="captionDescriptorMedium" color={colors.progressTextColor}>{props.text1}</Typography>
            <Typography variant="captionDescriptorMedium" color={colors.progressTextColor}>{props.text2}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="heading5" color={colors.agreementText} sx={{ mb: 0 }}>{formatMoneyValue(props.number1)}</Typography>
            <Typography variant="heading5" color={colors.agreementText} sx={{ mb: 0 }}>{formatMoneyValue(props.number2)}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ProgressBar.defaultProps = {
  orderedStatisticsByMember: undefined,
};

export default ProgressBar;
