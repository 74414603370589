/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Button,
  InputAdornment,
  Typography,
} from '@mui/material';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import { geocodeByPlaceID } from 'mui-places-autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import parse from 'autosuggest-highlight/parse';
import Grid from '@mui/material/Grid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import uuid from 'react-uuid';
import Autocomplete from '@mui/material/Autocomplete';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import OvForm from '../../../common/wrappers/ovForm';
import SimpleInput from '../../../common/inputs/simpleInput';
import CloseIconSVG from '../../../../assets/images/close-icon.svg';
import {
  AddressCountries,
  AddressProvinces,
  PhysicalAddress,
  provinces,
} from '../../../user/address/resources';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_KEY;

interface Props {
  onAdd: () => void,
  bankName: string,
  updateBankName: (att: string) => void,
  updatePhysicalAddress: (att: PhysicalAddress) => void,
  addressDescription: string,
  updateAddressDescription: (att: string) => void,
  onContinue?: () => void,
  goBack: () => void,
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id?: string;
}

const styles = {
  wrapper: {
    boxSizing: 'border-box',
  },
  noOptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  noOptionText: {
    position: 'relative',
    top: '15px',
  },
};

const loadScript = (src: string, position: HTMLElement | null, id: string): void => {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = { current: null };

const AddInstitution = ({
  onAdd,
  bankName,
  updateBankName,
  updatePhysicalAddress,
  onContinue,
  addressDescription,
  updateAddressDescription,
} : Props): JSX.Element => {
  const { t } = useTranslation(['transfer']);
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [value, setValue] = useState<PlaceType | null>({
    description: addressDescription,
    structured_formatting: {
      main_text: '',
      secondary_text: '',
      main_text_matched_substrings: [],
    },
    place_id: '',
  });
  const [inputValue, setInputValue] = useState('');
  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }

  const RESTRICTED_COUNTRY = 'ca';

  const fetch = useMemo(
    () => throttle(
      (
        request: { input: string, componentRestrictions: { country: string, } },
        callback: (results?: readonly PlaceType[]) => void,
      ) => {
        if (autocompleteService.current) {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        }
      },
      200,
    ),
    [],
  );

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!inputValue) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, componentRestrictions: { country: RESTRICTED_COUNTRY } }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleSelect = (results: any[]): void => {
    const localAddress: Partial<PhysicalAddress> = {};
    let countryText = '';
    let provinceText = '';
    if (results && results.length > 0) {
      const country: any[] = results[0]?.address_components.filter((e: any) => (e.types.includes('country')));
      if (country && country.length > 0) {
        countryText = country[0].long_name;
      }
      const postal: any[] = results[0]?.address_components.filter((e: any) => (e.types.includes('postal_code')));
      if (postal && postal.length > 0) {
        localAddress.postal = postal[0].long_name;
      }
      // eslint-disable-next-line
      const streetNumber: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('street_number');
      });
      if (streetNumber && streetNumber.length > 0) {
        localAddress.houseNumber = streetNumber[0].long_name;
      }
      // eslint-disable-next-line
      const unitNumber: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('unit_number');
      });
      if (unitNumber && unitNumber.length > 0) {
        localAddress.unitNumber = unitNumber[0].long_name;
      }
      // eslint-disable-next-line
      const route: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('route');
      });
      if (route && route.length > 0) {
        localAddress.streetName = route[0].long_name;
      }
      // eslint-disable-next-line
      const administrativeAreaLevel1: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('administrative_area_level_1');
      });
      if (administrativeAreaLevel1 && administrativeAreaLevel1.length > 0) {
        provinceText = administrativeAreaLevel1[0].long_name;
      }
      // eslint-disable-next-line
      const administrativeAreaLevel2: any[] = results[0]?.address_components.filter(function (e: any) {
        return e.types.includes('administrative_area_level_2') || e.types.includes('locality');
      });
      if (administrativeAreaLevel2 && administrativeAreaLevel2.length > 0) {
        localAddress.city = administrativeAreaLevel2[0].long_name;
      }
    }
    if (provinceText && countryText) {
      localAddress.country = AddressCountries.CA;
      localAddress.province = provinces.find((p) => p.value.toLowerCase() === provinceText.toLocaleLowerCase())?.key;
      updatePhysicalAddress({
        unitNumber: localAddress.unitNumber,
        houseNumber: localAddress.houseNumber ?? '',
        streetName: localAddress.streetName ?? '',
        neighborhood: localAddress.neighborhood,
        city: localAddress.city ?? '',
        province: localAddress.province as AddressProvinces,
        country: localAddress.country,
        postal: localAddress.postal ?? '',
      });
      if (onContinue) onContinue();
    }
  };

  const newAddressClick = (): void => {
    if (onContinue) onContinue();
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onAdd();
  };

  return (
    <Box height="93%" sx={styles.wrapper}>
      <OvForm onSubmit={(e) => onSubmit(e)}>
        <Typography variant="heading2" style={{ marginBottom: 16 }}>{t('transfer:addInstitution.header')}</Typography>
        <SimpleInput
          label={t('transfer:addInstitution.institutionName')}
          value={bankName}
          inputProps={{ inputMode: 'text' }}
          required
          style={{ marginBottom: 16 }}
          onChange={(event) => updateBankName(event.target.value)}
          testId="institution-name-input"
          onClear={() => updateBankName('')}
        />
        <Autocomplete
          id="google-place-suggestion"
          fullWidth
          popupIcon={(options && options.length > 0) ? <ArrowDropDownIcon /> : null}
          getOptionLabel={(option) => ((typeof option === 'string') ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          noOptionsText={
            (
              <Box style={styles.noOptionWrapper}>
                <Typography variant="paragraph3" sx={styles.noOptionText}>No Option</Typography>
                <Button variant="primary-small" data-testid="add-new-address-button" onClick={newAddressClick}>Add address</Button>
              </Box>
            )
          }
          onChange={(event: any, newValue: PlaceType | null) => {
            updateAddressDescription(newValue?.description ?? '');
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            if (newValue && newValue.place_id) {
              geocodeByPlaceID(newValue.place_id).then((results: any[]) => {
                handleSelect(results);
              }).catch((err: any) => {
                console.log('error', err.message);
              });
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              sx={{ marginBottom: '32px' }}
              variant="standard"
              {...params}
              label="search address"
              data-testid="address"
              fullWidth
              InputProps={{
                ref: params.InputProps.ref,
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="clear input field"
                      disableRipple
                      onClick={() => {
                        setValue({
                          description: '',
                          structured_formatting: {
                            main_text: '',
                            secondary_text: '',
                            main_text_matched_substrings: [],
                          },
                          place_id: '',
                        });
                      }}
                      edge="end"
                    >
                      <img src={CloseIconSVG} alt="close" />
                    </IconButton>
                  </InputAdornment>),
              }}
            />
          )}
          renderOption={(propsVals, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length]),
            );

            return (
              <li {...propsVals}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box
                      component={LocationOnIcon}
                      sx={{ color: 'text.secondary', mr: 2 }}
                    />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part) => (
                      <span
                        key={uuid()}
                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                      >
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </OvForm>
    </Box>
  );
};

AddInstitution.defaultProps = {
  onContinue: undefined,
};

export default AddInstitution;
