import {
  Typography,
  Button,
  Card,
  DialogTitle,
  DialogContent,
  Dialog,
} from '@mui/material';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import CardContent from '@mui/material/CardContent';
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import { Box } from '@mui/system';
import { UPDATE_USER } from './graphql';
import SinInput from '../common/inputs/sinInput';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { colors } from '../../theme/colors';
import OvForm from '../common/wrappers/ovForm';
import CloseButton from '../common/buttons/closeButton';

export interface UserState {
  sin?: string,
  incompleteFields?: string[],
}

export interface ValidateState {
  sinIsValid: boolean,
}

interface Props {
  user: UserState,
  title?: string,
  cardTitle?: string,
  cardSubtitle1?: string,
  cardSubtitle2?: string,
  cardDescription?: string,
  noSinText?: string,
  shouldSaveUpdate?: boolean,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  analytictsEvent?: string,
}
const styles = {
  lockIcon: {
    position: 'relative',
    top: '3px',
    marginRight: '5px',
    color: '#111C28',
  },
  checkIcon: {
    position: 'relative',
    top: '5px',
    marginRight: '5px',
  },
  securityLock: {
    height: '14px',
    width: '14px',
    position: 'relative',
    top: '2px',
    marginRight: '5px',
  },
};
const Sin = ({
  user, updateUserState, shouldSaveUpdate = true, ...props
}: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['base', 'user']);
  const [validState, setValidState] = useState<ValidateState>({ sinIsValid: true });
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    sin: user?.sin || '',
    error: '',
  });
  const handleClose = (): void => {
    setOpen(false);
    setState({
      sin: '',
      error: '',
    });
  };

  const checkFieldsValidity = (): boolean => {
    const sinValidity = !!(user.sin && user.sin.length === 9 && user.sin.match(/^[0-9]+$/) != null);
    setValidState({ ...validState, sinIsValid: sinValidity });
    return sinValidity;
  };
  const confirmSinUpdated = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'SIN_UPDATED', sin: user.sin });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };
  const [updateSin] = useMutation(UPDATE_USER, {
    variables: {
      input: { sin: (user.sin && user.sin !== undefined) ? user.sin : '' },
    },
    onCompleted: confirmSinUpdated,
    onError: () => setLoading(false),
  });
  const onContinueClick = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    if (checkFieldsValidity()) {
      sendAnalyticsEvent(props.analytictsEvent ?? ovAnalyticsEvents.onBoardingEnterSINContinue).then();
      if (shouldSaveUpdate) {
        setLoading(true);
        updateSin().then();
      } else if (props.onContinue) props.onContinue();
    } else {
      setState({ sin: '', error: t('user:sin.errorModal.notValidSin') });
      setOpen(true);
    }
  };
  const clearSin = (): void => {
    updateUserState({ ...user, sin: '' });
    setValidState({ ...validState, sinIsValid: true });
  };
  const onSinDeclineClick = (): void => {
    console.log({ event: 'USER_WITHOUT_SIN' });
    setState({ sin: '', error: t('user:sin.errorModal.dontHaveSinMessage') });
    setOpen(true);
  };

  if (state.error) {
    return (
      <Dialog onClose={handleClose} open={open} style={{ padding: '15px' }}>
        <Box style={{ paddingLeft: '24px', paddingTop: '16px' }}>
          <CloseButton onClick={handleClose} />
        </Box>
        <DialogTitle>
          <Typography id="modal-modal-title" variant="heading3">{t('user:sin.errorModal.title')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography id="modal-modal-description" data-testid="sin-error" color="error" variant="paragraph3">{state.error}</Typography>
          <Button variant="primary-large" disableElevation fullWidth data-testid="continue-button" onClick={handleClose}>{t('user:sin.errorModal.goBack')}</Button>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <OvForm onSubmit={onContinueClick} loading={loading}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: '1 0 auto' }}>
          <Typography variant="heading2">{props.title ?? t('user:sin.title')}</Typography>
          <SinInput
            testId="sin-input"
            label={t('user:sin.placeholder')}
            value={user?.sin}
            style={{ marginBottom: 16 }}
            subtitle={validState.sinIsValid ? '' : t('user:sin.validation')}
            error={!validState.sinIsValid}
            required
            onChange={(event) => updateUserState({ ...user, sin: event.target.value })}
            data-testid="sin-input"
            onClear={clearSin}
          />
          <Card sx={{ background: colors.sinCard }}>
            <CardContent>
              <Typography variant="heading5" sx={{ marginRight: '20px' }}>
                <LockIcon fontSize="small" sx={styles.lockIcon} />
                {props.cardTitle ?? t('user:sin.card.title')}
              </Typography>
              <Typography variant="captionSectionerMedium" sx={{ marginLeft: '35px' }}>
                <CheckIcon fontSize="small" sx={styles.checkIcon} />
                {props.cardSubtitle1 ?? t('user:sin.card.subHeading1')}
              </Typography>
              <Typography variant="captionSectionerMedium" sx={{ marginLeft: '35px' }}>
                <CheckIcon fontSize="small" sx={styles.checkIcon} />
                {props.cardSubtitle2 ?? t('user:sin.card.subHeading2')}
              </Typography>
              <Typography variant="captionDescriptorMedium" sx={{ marginLeft: '35px', marginTop: '20px' }}>
                <LockIcon fontSize="small" sx={styles.securityLock} />
                {props.cardDescription ?? t('user:sin.card.description')}
              </Typography>
            </CardContent>
          </Card>
        </div>
        <Button variant="secondary-large" onClick={onSinDeclineClick} data-testid="cancel-button" style={{ marginBottom: 16 }}>{ props.noSinText ?? t('base:button.dontHaveSin')}</Button>
      </div>
    </OvForm>
  );
};

Sin.defaultProps = {
  title: undefined,
  cardTitle: undefined,
  cardSubtitle1: undefined,
  cardSubtitle2: undefined,
  cardDescription: undefined,
  noSinText: undefined,
  shouldSaveUpdate: true,
  onContinue: undefined,
  analytictsEvent: undefined,
};

export default Sin;
