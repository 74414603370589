import {
  IconButton,
  LinearProgress,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import successIcon from '../../../assets/images/toast/success.svg';
import errorIcon from '../../../assets/images/toast/error.svg';
import infoIcon from '../../../assets/images/toast/info.svg';
import { colors } from '../../../theme/colors';

type TransitionProps = Omit<SlideProps, 'direction'>;
const style = {
  content: {
    boxShadow: '0px 8px 16px rgba(102, 115, 128, 0.32)',
    borderRadius: '6px 6px 0px 0px',
    background: colors.ovWhite,
    width: '352px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '85px',
  },
  padding: {
    padding: '18.5px 18.5px 10px 18.5px',
  },
};

export interface OvToastProps {
  type: 'success'|'error'|'info'|'none',
  title: string,
  content: string,
  open: boolean,
  handleClose?: () => void,
}

const SlideTransition = (props: TransitionProps): JSX.Element => <Slide {...props} timeout={{ enter: 500, exit: 500 }} direction="down" />;

const OvToast = ({
  type, title, content, handleClose, open,
}: OvToastProps): JSX.Element => {
  const [duration, setDuration] = useState(0);
  const ticker = useRef<NodeJS.Timer | undefined>(undefined);
  const defineIcon = (): string => {
    switch (type) {
      case 'success': {
        return successIcon;
      }
      case 'error': {
        return errorIcon;
      }
      case 'info': {
        return infoIcon;
      }
      default: {
        return successIcon;
      }
    }
  };
  const defineColor = (): string => {
    switch (type) {
      case 'success': {
        return colors.success;
      }
      case 'error': {
        return colors.error;
      }
      case 'info': {
        return colors.ovBlack;
      }
      default: {
        return colors.ovBlack;
      }
    }
  };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: defineColor(),
    },
  }));
  const pause = (): void => {
    if (ticker.current) {
      clearInterval(ticker.current);
    }
  };
  const reset = (): void => {
    if (ticker.current) {
      clearInterval(ticker.current);
    }
    setDuration(0);
  };
  const startTimer = (): void => {
    ticker.current = setInterval(() => {
      setDuration((prevState) => prevState + 0.2);
    }, 10);
  };
  useEffect(() => {
    if (open) {
      reset();
      startTimer();
    }
  }, [open]);
  const onClose = (): void => {
    if (handleClose) handleClose();
    reset();
  };
  useEffect(() => {
    if (duration > 100) {
      if (handleClose) handleClose();
      reset();
    }
  }, [duration, handleClose]);
  return (
    <Snackbar
      open={open}
      onMouseOver={pause}
      onMouseLeave={startTimer}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
    >
      <Box sx={style.content}>
        <Box sx={style.padding}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box display="flex" flexDirection="row" alignItems="center">
              { type !== 'none' ? <img src={defineIcon()} alt="icon" style={{ marginRight: '8px' }} /> : undefined }
              <Typography variant="captionSectionerBold" style={{ marginBottom: 0 }}>{title}</Typography>
            </Box>
            <IconButton disableRipple onClick={onClose}>
              <CloseRoundedIcon sx={{ color: colors.black, height: '18px' }} />
            </IconButton>
          </Box>
          <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, paddingLeft: type !== 'none' ? '32px' : '0px' }}>
            {content}
          </Typography>
        </Box>
        <BorderLinearProgress variant="determinate" value={duration} />
      </Box>
    </Snackbar>
  );
};
OvToast.defaultProps = {
  handleClose: undefined,
};

export default OvToast;
