/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useRef, useState } from 'react';
import find from 'lodash/find';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import WithStepWizard, { StepProps } from '../common/wrappers/withStepWizard';
import GoalType from './goalType';
import TimeHorizon from './timeHorizon';
import TargetAmount from './targetAmount';
import RiskQuestion from './riskQuestion';
import {
  GoalTypeAttributes, GoalRiskLevels, GoalTimeHorizons, goalTypes, GoalTypes,
} from './resources';
import { CREATE_GOAL, UPDATE_GOAL } from './graphql';
import TrackingBar from '../common/headers/trackingBar';
import { UserContext } from '../../providers/userContextProvider';
import Projection from '../projection';
import { generateUniqueRequestId } from '../../utils/generic';

const GoalTypeStep = WithStepWizard(GoalType);
const TimeHorizonStep = WithStepWizard(TimeHorizon);
const TargetAmountStep = WithStepWizard(TargetAmount);
const RiskQuestionStep = WithStepWizard(RiskQuestion);
const ProjectionStep = WithStepWizard(Projection);

export interface GoalRef {
  id?: string,
  type?: GoalTypes,
  name?: string,
  timeHorizon?: GoalTimeHorizons
  targetAmountCents?: number,
  riskQuestion1?: GoalRiskLevels,
  suggestedFinancialProduct?: {
    forecastedRateOfReturn?: number,
    forecastedStandardDeviation?: number,
  },
}

interface Props {
  goalId?: string,
  goalName?: string,
  setGoalId?: (goalId: string) => void,
  onContinue?: () => void,
  goBack?: () => void,
  stepProps?: StepProps,
  stepTitle?: string,
  onStepChanged?: (step: number) => void,
  hideTrackingBar?: boolean,
  fromBulkUpload?: boolean,
}

const CreateGoalWizard = ({
  goalId, setGoalId, onContinue, goBack, stepTitle, stepProps,
  onStepChanged, hideTrackingBar, fromBulkUpload, goalName,
}: Props): JSX.Element => {
  const { t } = useTranslation(['projection']);
  const graphqlClient = useApolloClient();
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const [loading, setLoading] = useState(false);
  const [selectedGoalType, setSelectedGoalType] = useState<GoalTypeAttributes>();
  const goal = useRef<GoalRef>({});
  const { userContext, setUserContext } = useContext(UserContext);
  const [currentGoal, setCurrentGoal] = useState(goalId ? find(userContext.goals, { id: goalId }) : undefined);
  const setGoalRef = (att: Partial<GoalRef>): void => {
    goal.current = { ...goal.current, ...att };
    setCurrentGoal({ ...currentGoal, ...att, id: currentGoal?.id || '' });
  };
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });

  const createOrUpdateGoal = async (callback: () => void): Promise<void> => {
    setLoading(true);
    try {
      if (goalId) {
        console.log({ event: 'UPDATING_GOAL', goalId });
        delete goal.current.suggestedFinancialProduct;
        const updateResp = await graphqlClient.mutate({
          mutation: UPDATE_GOAL,
          variables: { input: { ...goal.current, goalId } },
        });
        console.log({ event: 'UPDATED_GOAL', goalId: updateResp.data.updateGoal.goal.id });
        setCurrentGoal({
          ...currentGoal,
          id: updateResp.data.updateGoal.goal.id,
          suggestedFinancialProduct: {
            forecastedRateOfReturn: updateResp.data.updateGoal.goal?.suggestedFinancialProduct?.forecastedRateOfReturn ?? 0,
            forecastedStandardDeviation: updateResp.data.updateGoal.goal?.suggestedFinancialProduct?.forecastedStandardDeviation ?? 0,
          },
        });
      } else {
        console.log({ event: 'CREATING_GOAL' });
        const createGoalResp = await graphqlClient.mutate({
          mutation: CREATE_GOAL,
          variables: {
            input: {
              requestId: generateUniqueRequestId(),
              ...goal.current,
            },
          },
        });
        console.log({ event: 'CREATED_GOAL', goalId: createGoalResp.data.createGoal.goal.id });
        if (setGoalId) {
          setGoalId(createGoalResp.data.createGoal.goal.id);
        }
        setCurrentGoal({
          ...currentGoal,
          id: createGoalResp.data.createGoal.goal.id,
          type: createGoalResp.data.createGoal.goal.type,
          name: createGoalResp.data.createGoal.goal.name,
          timeHorizon: createGoalResp.data.createGoal.goal.timeHorizon,
          targetAmountCents: createGoalResp.data.createGoal.goal.targetAmountCents,
          riskQuestion1: createGoalResp.data.createGoal.goal.riskQuestion1,
          suggestedFinancialProduct: {
            forecastedRateOfReturn: createGoalResp.data.createGoal.goal?.suggestedFinancialProduct?.forecastedRateOfReturn ?? 0,
            forecastedStandardDeviation: createGoalResp.data.createGoal.goal?.suggestedFinancialProduct?.forecastedStandardDeviation ?? 0,
          },
        });
        setUserContext({
          ...userContext,
          goals: [
            ...userContext.goals ?? [],
            {
              id: createGoalResp.data.createGoal.goal.id,
              type: goal.current.type,
              name: goal.current.name,
              state: 'ACTIVE',
              timeHorizon: goal.current.timeHorizon,
              targetAmountCents: goal.current.targetAmountCents,
              riskQuestion1: goal.current.riskQuestion1,
              suggestedFinancialProduct: {
                forecastedRateOfReturn: createGoalResp.data.createGoal.goal?.suggestedFinancialProduct?.forecastedRateOfReturn ?? 0,
                forecastedStandardDeviation: createGoalResp.data.createGoal.goal?.suggestedFinancialProduct?.forecastedStandardDeviation ?? 0,
              },
            },
          ],
        });
        setGoalRef({
          suggestedFinancialProduct: {
            forecastedRateOfReturn: createGoalResp.data.createGoal.goal?.suggestedFinancialProduct?.forecastedRateOfReturn ?? 0,
            forecastedStandardDeviation: createGoalResp.data.createGoal.goal?.suggestedFinancialProduct?.forecastedStandardDeviation ?? 0,
          },
        });
      }
      setLoading(false);
      callback();
    } catch (e) {
      setLoading(false);
    }
  };

  const hasSecondaryType = (): boolean => (selectedGoalType?.secondaryGoalTypes?.length || 0) > 0;
  const getSecondaryTypes = (): GoalTypeAttributes[] => selectedGoalType?.secondaryGoalTypes || [];
  const goalCount = userContext.goals?.length || 0;
  const steps: JSX.Element[] = [
    ...(fromBulkUpload ? [] : [
      <GoalTypeStep stepName="type" goalCount={goalCount} currentGoal={currentGoal} stepTitle={stepTitle} goalTypes={goalTypes} setGoalRef={setGoalRef} selectedGoalType={selectedGoalType} setSelectedGoalType={setSelectedGoalType} continueToNamedStep={() => (hasSecondaryType() ? 'secondaryType' : 'timeHorizon')} goBackAction={goBack} key="type" />,
      <GoalTypeStep stepName="secondaryType" stepTitle={stepTitle} currentGoal={currentGoal} goalTypes={getSecondaryTypes()} setGoalRef={setGoalRef} selectedGoalType={selectedGoalType} setSelectedGoalType={setSelectedGoalType} key="secondaryType" />,
    ]),
    <TimeHorizonStep stepName="timeHorizon" stepTitle={stepTitle} currentGoal={currentGoal} setGoalRef={setGoalRef} goBackToNamedStep={() => 'type'} key="timeHorizon" name={goalName} />,
    <TargetAmountStep stepName="targetAmount" stepTitle={stepTitle} currentGoal={currentGoal} setGoalRef={setGoalRef} defaultAmountCents={selectedGoalType?.defaultTargetAmountCents} key="targetAmount" />,
    <RiskQuestionStep stepName="riskQuestion" stepTitle={stepTitle} currentGoal={currentGoal} setGoalRef={setGoalRef} isLoading={loading} key="riskQuestion" action={createOrUpdateGoal} />,
    <ProjectionStep
      key="Projection"
      stepName="YourProjections"
      currentGoal={currentGoal}
      stepTitle={t('projection:title')}
      action={() => {
        console.log('action');
        if (onContinue) onContinue();
      }} />,
  ];
  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      transitions={{}}
      onStepChange={(stepChange: {
        previousStep: number
        activeStep: number
      }) => {
        if (onStepChanged) {
          onStepChanged(stepChange.activeStep);
        }
      }}
      initialStep={goalId ? 6 : 1}
      className="ov-step-wizard"
      nav={hideTrackingBar ? undefined : (
        <TrackingBar steps={steps} baselineStepProps={stepProps} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

CreateGoalWizard.defaultProps = {
  goalId: undefined,
  goalName: undefined,
  setGoalId: undefined,
  onContinue: undefined,
  goBack: undefined,
  stepTitle: undefined,
  stepProps: undefined,
  onStepChanged: undefined,
  hideTrackingBar: undefined,
  fromBulkUpload: false,
};

export default CreateGoalWizard;
