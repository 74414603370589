import { useMutation } from '@apollo/client';
import { Typography, List } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../common/inputs/searchInput';
import { UPDATE_USER } from '../graphql';
import { areasOfStudies } from '../resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import GeneralListItem from '../../common/lists/generalListItem';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  studentAreaOfStudy?: string,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  title?: string,
  subTitle?: string,
  cached?: boolean,
  analyticsEvent?: string,
}

const AreaOfStudy = ({
  user, updateUserState, title, subTitle, cached, analyticsEvent, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [state, setState] = useState({
    value: '',
    sourceData: areasOfStudies,
    filterData: areasOfStudies,
  });
  const [clickedValue, setClickedValue] = useState('');
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const filterList = (e: any): void => {
    if (e.target && e.target.value) {
      const updatedList = state.sourceData.filter((item) => (item.toLowerCase().search(e.target.value.toLowerCase()) !== -1));
      setState({ ...state, filterData: updatedList });
    }
  };
  const updateAreaOfStudyOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'AREA_OF_STUDY_UPDATED', studentAreaOfStudy: user.studentAreaOfStudy });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };
  const [updateAreaOfStudyMutation] = useMutation(UPDATE_USER, {
    variables: {
      input: { studentAreaOfStudy: user.studentAreaOfStudy },
    },
    onCompleted: updateAreaOfStudyOnCompleted,
    onError: () => setLoading(false),
  });
  const onContinueClick = (): void => {
    if (analyticsEvent === undefined) sendAnalyticsEvent(ovAnalyticsEvents.onBoardingEnterFieldOfStudy).then();
    if (analyticsEvent) sendAnalyticsEvent(analyticsEvent).then();
    if (cached) {
      if (props.onContinue) props.onContinue();
    } else {
      setLoading(true);
      updateAreaOfStudyMutation().then();
    }
  };
  return (
    <OvForm loading={loading} onSubmit={onContinueClick} disableButton={!user.studentAreaOfStudy} submitButton={false}>
      <Typography variant="heading2">{title ?? t('user:employement.areaOfStudy.title')}</Typography>
      <Typography variant="paragraph3">{subTitle ?? t('user:employement.areaOfStudy.subTitle')}</Typography>
      <SearchInput
        label="Job Title"
        value={searchString}
        onChange={(event) => {
          setSearchString(event.target.value);
          filterList(event);
        }}
        onClear={() => {
          setState({ ...state, filterData: areasOfStudies });
          setClickedValue('');
          setSearchString('');
          updateUserState({ ...user, studentAreaOfStudy: '' });
        }}
      />
      <List className="on-boarding-list">
        {state.filterData.map((value: string) => (
          <GeneralListItem
            text={value}
            key={value}
            hasRadioField
            checked={clickedValue === value || value === user.studentAreaOfStudy}
            onClick={() => {
              if (value) {
                setSearchString(value);
                setClickedValue(value);
                updateUserState({ ...user, studentAreaOfStudy: value });
              }
            }}
          />
        ))}
      </List>
    </OvForm>
  );
};

AreaOfStudy.defaultProps = {
  onContinue: undefined,
  title: undefined,
  subTitle: undefined,
  cached: false,
  analyticsEvent: undefined,
};

export default AreaOfStudy;
