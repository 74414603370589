/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import WithStepWizard from '../../common/wrappers/withStepWizard';
import TrackingBar from '../../common/headers/trackingBar';
import { UserContext, UserContextProps } from '../../../providers/userContextProvider';
import { Account, Goal, SubAccount } from '../../../utils/commonGraphql';
import CreateGoalWizard from '../../goal/createGoalWizard';
import CreateSubAccountWizard from '../../account/createSubAccountWizard';
import { FeatureFlagTypes, isFeatureEnabled } from '../../account/resources';

const CreateGoalWizardStep = WithStepWizard(CreateGoalWizard);
const CreateSubAccountWizardStep = WithStepWizard(CreateSubAccountWizard);

export interface UserState {
  incompleteFields?: string[],
  goals: Goal[],
  accounts: Account[],
  subAccounts: SubAccount[],
  iDCheckCompleted?: boolean,
}

interface Props {
  onContinue?: () => void,
  goBack?: () => void,
  stepTitle?: string,
  showStepper?: boolean,
  shouldHideAgreement?: boolean,
}

const defineInitialStatus = (user: UserContextProps): number => {
  if (user.goals?.length === 0) {
    return 1;
  }
  if (user.subAccounts?.length === 0) {
    return 2;
  }
  return 1;
};
const YourGoalsWizard = ({
  onContinue, goBack, showStepper, stepTitle, shouldHideAgreement,
}: Props): JSX.Element => {
  const { userContext } = useContext(UserContext);
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });

  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const steps: JSX.Element[] = [];
  steps.push(<CreateGoalWizardStep stepProps={{ absoluteTotalSteps: 26 }} key="Goal" goalId={userContext.goals?.[0]?.id} stepTitle={stepTitle} goBack={goBack} action={isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_SUB_ACCOUNTS) ? undefined : onContinue} disableTracker />);
  if (isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_SUB_ACCOUNTS)) {
    steps.push(<CreateSubAccountWizardStep shouldHideAgreement={shouldHideAgreement} stepProps={{ absoluteTotalSteps: 26, currentStep: 6 }} key="SubAccount" goalId={userContext.goals?.[0]?.id || ''} subAccount={userContext.subAccounts?.[0]} stepTitle={stepTitle} action={onContinue} disableTracker />);
  }
  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      initialStep={defineInitialStatus(userContext)}
      className="ov-step-wizard"
      transitions={{}}
      nav={(
        <TrackingBar steps={steps} showStepper={showStepper} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

YourGoalsWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  stepTitle: undefined,
  showStepper: true,
  shouldHideAgreement: undefined,
};

export default YourGoalsWizard;
