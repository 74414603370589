import { useMutation } from '@apollo/client';
import {
  Typography,
  List,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import ButtonListItem from '../../common/lists/buttonListItem';
import { UPDATE_USER } from '../graphql';
import { maritalStatus } from '../resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';
import { UserContext } from '../../../providers/userContextProvider';

export interface UserState {
  maritalStatus?: string,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
}

const MaritalStatus = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [value, setValue] = useState('');
  const { userContext, setUserContext } = useContext(UserContext);
  const updateMaritalStatusOnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'MARITAL_STATUS_UPDATED', maritalStatus: value });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields, maritalStatus: value });
    setUserContext({ ...userContext, maritalStatus: value });
    if (props.onContinue) props.onContinue();
  };

  const [updateMaritalStatus] = useMutation(UPDATE_USER, {
    variables: {
      input: { maritalStatus: value },
    },
    onCompleted: updateMaritalStatusOnCompleted,
  });

  const onItemSelected = (item: Record<string, string>): void => {
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectMaritalStatus).then();
    setValue(item.value);
  };

  useEffect(() => {
    if (value) {
      updateMaritalStatus().then();
    }
  }, [value, updateMaritalStatus]);

  return (
    <OvForm loading={false} hasButton={false}>
      <Typography variant="heading2">{t('user:howToInvest.maritslStatus.title')}</Typography>
      <Typography variant="paragraph3">{t('user:howToInvest.maritslStatus.subTitle')}</Typography>
      <List sx={{ maxHeight: '250px', overflowY: 'auto' }}>
        {maritalStatus.map((item: Record<string, string>) => (
          <Box key={item.name}>
            <ButtonListItem
              key={item.name}
              text={item.name}
              onClick={() => {
                if (item.value) onItemSelected(item);
              }}
              isSelected={(user.maritalStatus && item.value === (user.maritalStatus === 'MARRIED' ? 'COMMON_LAW' : user.maritalStatus)) || false}
            />
            <Box mb={1} />
          </Box>
        ))}
      </List>
    </OvForm>
  );
};

MaritalStatus.defaultProps = {
  onContinue: undefined,
};

export default MaritalStatus;
