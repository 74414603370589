import { Box, Avatar as MuiAvatar, Typography } from '@mui/material';
import { ArrowTooltip } from './arrowToolTip';
import { householdColor } from './householdColors';
import HouseHoldIcon from '../../../assets/images/goals/household.svg';

export interface HouseholdBadgeArgs {
  sequenceNumber: number
  initials: string
  toolTipLabel: string
  showInitial: boolean
  size?: 'small' | 'medium' | 'large',
}

export const HouseholdBadge = ({
  sequenceNumber, initials, toolTipLabel, showInitial, size,
}: HouseholdBadgeArgs): JSX.Element => {
  const colors = householdColor(sequenceNumber);

  const scale = size === 'small' ? '1.0em' : '1.50em';
  const fontSize = size === 'small' ? 10 : 15;

  return (
    <ArrowTooltip title={toolTipLabel ?? 'message'}>

      {showInitial ? (
        <MuiAvatar sx={{
          m: 0,
          color: colors.fg,
          backgroundColor: colors.bg,
          width: scale,
          height: scale,
          border: `1px solid ${colors.fg}`,
          boxShadow: '0px 2px 4px 0px #00000026;',
          '&:hover': { border: `3px solid ${colors.fg}`, cursor: 'pointer' },
        }}>
          <Typography sx={{ fontSize, color: colors.fg, fontWeight: 'bolder' }}>{initials}</Typography>
        </MuiAvatar>
      ) : (
        <Box width="1.66em" height="1.66em">
          <img src={HouseHoldIcon} alt="icon" />
        </Box>
      )}
    </ArrowTooltip>
  );
};

HouseholdBadge.defaultProps = {
  size: undefined,
};
