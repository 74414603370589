/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Container,
  Grid,
  Tab,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import dayjs from 'dayjs';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AddIcon from '@mui/icons-material/Add';
import {
  ACCOUNTS_STATS,
  FETCH_MY_HOLDINGS,
  FETCH_MY_GOALS, ME,
} from './graphql';
import LoadingBox from '../../components/common/loaders/loadingBox';
import HistoryChart from '../../components/statistics/historyChart';
import BalanceAndReturnValues from '../../components/statistics/balanceAndReturnValues';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { formatMoneyValue } from '../../utils/commonMethods';
import {
  AccountTypes,
  FeatureFlagTypes,
  isFeatureEnabled,
} from '../../components/account/resources';
import CreateGoalAndSubAccountWizard from '../../components/goal/createGoalAndSubAccountWizard';
import { HoldingResponse, PrimaryAssetClassHoldings } from '../../components/holdings/resources';
import { DailyStatsTypes } from '../../components/statistics/graphql';
import { styles } from './styles';
import { UserContext } from '../../providers/userContextProvider';
import Deposit from '../../components/transfers/deposit';
import Withdraw from '../../components/transfers/withdrawal';
import OvPartnerHeader from '../../components/common/headers/ovPartnerHeader';
import FlowModal from '../../components/common/wrappers/modals/ovFlowModal';
import { useGlobalToast } from '../../providers/globalToastProvider';
import HoldingsList2 from '../../components/holdings/holdingsList2';
import {
  availableFeatureFlagsVar, canViewHouseholdVar, isIdVerifiedVar, userIdVar,
} from '../../utils/localVariables';
import HoldingsList from '../../components/holdings/holdingsList';
import { ClientGroup, HouseholdViewTypes } from '../../utils/commonGraphql';
import { Colors, useContextTheme } from '../../providers/contextThemeProvider';
import { HouseholdToggleButton } from '../../components/common/components/householdToggleButton';
import GoalItem from '../../components/goal/goalItem';
import AccountItem from '../../components/account/accountItem';
import { GoalSkeleton } from '../../components/goal/goalSkeleton';
import { AccountSkeleton } from '../../components/account/accountSkeleton';
import { accountsWhichRequirePaymentInstructions, hasNonLockedInAccount, hideExistingPortfolioTransfer } from '../../utils/accountUtil';

export interface UserState {
  id: string,
  firstName: string,
  lastName: string,
  households?: ClientGroup[]
  statistics: {
    marketValueCents: number,
    moneyAvailableCents: number,
    netContributionCents: number,
    pendingContributionCents: number,
    simpleReturnAmount: number,
    simpleReturnPercent: number,
    timeWeightedReturn: number,
    moneyWeightedReturn: number,
    availableFundsToWithdrawCents: number,
  }
}

export interface Goal {
  id: string,
  name: string,
  type: string,
  state: string,
  statistics: {
    marketValueCents: number,
    simpleReturnPercent: number,
    simpleReturnAmount: number,
    availableFundsToWithdrawCents: number,
  },
  householdClientGroup: {
    id: string,
    name: string,
    relationships: {
      user: {
        id: string,
        firstName: string,
        accessType: string,
      },
    }[],
  },
  subAccounts: {
    id: string,
    state: string,
    name: string,
    account: {
      id: string,
      state: string,
      type: AccountTypes,
      user: {
        id: string,
      }
    },
    allowClientDeposits: boolean
  }[],
}

export const householdInitial = (householdName?: string): string => {
  if (!householdName) return '';
  return householdName.substring(0, 1).toLocaleUpperCase();
};

export interface HouseholdMembers {
  members: { id: string, initials: string, name: string }[],
  indexedMembers: Record<string, number>
  totalMarketValueCents: number
}

const boxStyle = (colors: Colors) => ({
  card: {
    background: colors.baseComponentBackground,
    borderRadius: '8px',
    border: `1px solid ${colors.portfolioCardBorder}`,
  },
});

const DashboardPage = (): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const { colors } = useContextTheme();
  const { userContext, setUserContext } = useContext(UserContext);
  const displayHeader = !isNil(userContext.partnerId);
  const [addGoalWizard, setAddGoalWizard] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showWithdrawal, setShowWithdrawal] = useState(false);
  const createGoalStep = useRef(1);
  const [tabShown, setTabShown] = useState<'personal' | 'household'>(canViewHouseholdVar() ? 'household' : 'personal');
  const [startDate, setStartDate] = useState<string | null>(null);
  const { showToast } = useGlobalToast();
  const addGoalClick = (): void => {
    setAddGoalWizard(true);
  };
  const [holdingList, setHoldingList] = useState<HoldingResponse[]>([]);
  const [assetClassHoldings, setAssetClassHoldings] = useState<PrimaryAssetClassHoldings[]>([]);
  const setHoldingsData = (data: {
    me: {
      user: {
        statistics: {
          marketValueCents: number,
          primaryAssetClassHoldings: PrimaryAssetClassHoldings[],
          holdings: HoldingResponse[],
        },
      },
    }
  }): void => {
    if (data) {
      console.log({ event: 'HOLDINGS', holdings: data.me.user.statistics.holdings });
      setHoldingList(data.me.user.statistics.holdings);
      setAssetClassHoldings(() => ([...data.me.user.statistics.primaryAssetClassHoldings]));
    }
  };
  const fetchMyHoldings = useQuery(FETCH_MY_HOLDINGS, {
    variables: {
      includeAllHouseholds: tabShown === 'household',
    },
    onCompleted: setHoldingsData,
    nextFetchPolicy: 'standby',
  });
  const [goals, setGoals] = React.useState<Goal[]>([]);
  const [accounts, setAccounts] = React.useState<any[]>([]);
  const setAccountsData = (data: {
    fetchAccounts: {
      accounts: {
        id: string,
        type: string,
        state: string,
        user: {
          firstName: string,
        }
        statistics: {
          marketValueCents: number,
          simpleReturnPercent: number,
          simpleReturnAmount: number,
        },
        subAccounts: {
          id: string,
          state: string,
          goal: {
            id: string,
            state: string,
          },
        }[],
      }[],
    }
  }): void => {
    console.log({ event: 'ACCOUNTS', accounts: data.fetchAccounts.accounts });
    const states: string[] = ['INACTIVE', 'FAILED', 'CANCELED'];
    setAccounts(data.fetchAccounts.accounts.filter((x) => !states.includes(x.state)));
  };
  const [fetchMyAccounts, { loading: loadingAccounts }] = useLazyQuery(ACCOUNTS_STATS, {
    variables: {
      startDate,
      householdViewType: tabShown === 'personal' ? HouseholdViewTypes.INDIVIDUAL : HouseholdViewTypes.ALL,
    },
    onCompleted: setAccountsData,
    nextFetchPolicy: 'standby',
  });
  const [value, setValue] = React.useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    if (newValue === '1') {
      sendAnalyticsEvent(ovAnalyticsEvents.homePageGoalsTabSelect).then();
    } else if (newValue === '2') {
      sendAnalyticsEvent(ovAnalyticsEvents.homePageAccountsTabSelect).then();
      fetchMyAccounts();
    } else {
      sendAnalyticsEvent(ovAnalyticsEvents.homePageHoldingsTabSelect).then();
      fetchMyHoldings.refetch();
    }
    setValue(newValue);
  };
  const [state, updateState] = useState({
    loading: true,
  });
  const [user, updateUser] = useState<UserState>({
    id: '',
    firstName: '',
    lastName: '',
    statistics: {
      marketValueCents: 0,
      moneyAvailableCents: 0,
      netContributionCents: 0,
      pendingContributionCents: 0,
      simpleReturnAmount: 0,
      simpleReturnPercent: 0,
      timeWeightedReturn: 0,
      moneyWeightedReturn: 0,
      availableFundsToWithdrawCents: 0,
    },
  });

  const setUserData = (data: { me: { user: UserState } }): void => {
    updateUser({ ...user, ...data.me.user });
    updateState({ ...state, loading: false });
  };

  useQuery(ME, {
    variables: {
      includeAllHouseholds: tabShown === undefined ? true : tabShown === 'household',
    },
    onCompleted: setUserData,
  });

  const [allSubAccountsBlockedDeposits, setAllSubAccountsBlockedDeposits] = useState(false);

  const setGoalData = (data: {
    fetchGoals: {
      goals: Goal[],
    }
  }): void => {
    console.log({ event: 'GOALS', goals: data.fetchGoals.goals });
    const activeGoals = data.fetchGoals.goals.filter((goal) => goal.state?.toLowerCase() === 'active');
    const activeGoalsA = activeGoals.filter((x) => x.householdClientGroup !== null)
      .sort((a, b) => b.statistics.marketValueCents - a.statistics.marketValueCents);
    const activeGoalsB = activeGoals.filter((x) => x.householdClientGroup === null)
      .sort((a, b) => b.statistics.marketValueCents - a.statistics.marketValueCents);
    const allActiveGoals = [...activeGoalsA, ...activeGoalsB];
    setGoals(allActiveGoals);
    const subAccountsWithAllowedDeposits = activeGoals
      .map((goal) => goal.subAccounts)
      .flat()
      .filter((subAccount) => subAccount.allowClientDeposits === true);
    setAllSubAccountsBlockedDeposits(activeGoals.length > 0 && subAccountsWithAllowedDeposits.length === 0);
  };

  const [fetchMyGoals, { loading: loadingGoals }] = useLazyQuery(FETCH_MY_GOALS, {
    variables: {
      startDate,
      forUserId: tabShown === 'personal' ? userIdVar() : undefined,
      householdViewType: tabShown === 'personal' ? HouseholdViewTypes.INDIVIDUAL : HouseholdViewTypes.ALL,
    },
    refetchWritePolicy: 'merge',
    onCompleted: setGoalData,
  });

  const changeUnit = (frame: string): void => {
    let date = null;
    if (frame === '1W') {
      date = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
    }
    if (frame === '1M') {
      date = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
    }
    if (frame === '3M') {
      date = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
    }
    if (frame === '1Y') {
      date = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    }
    setStartDate(date);
    fetchMyGoals({
      variables: {
        startDate: date,
        forUserId: tabShown === 'personal' ? userIdVar() : undefined,
        householdViewType: tabShown === 'personal' ? HouseholdViewTypes.INDIVIDUAL : HouseholdViewTypes.ALL,
      },
    }).then();
    fetchMyAccounts({
      variables: {
        startDate: date,
        householdViewType: tabShown === 'personal' ? HouseholdViewTypes.INDIVIDUAL : HouseholdViewTypes.ALL,
      },
    }).then();
  };
  useEffect(() => {
    sendAnalyticsEvent(ovAnalyticsEvents.homePageGoal).then();
    fetchMyGoals().then();
  }, [fetchMyGoals, user]);

  const onChange = (event: React.MouseEvent<HTMLElement>, toogleValue: 'personal' | 'household'): any => {
    setTabShown(toogleValue);
    fetchMyHoldings.refetch();
    fetchMyAccounts({
      variables: {
        startDate,
        householdViewType: toogleValue === 'personal' ? HouseholdViewTypes.INDIVIDUAL : HouseholdViewTypes.ALL,
      },
    }).then();
    fetchMyGoals({
      variables: {
        startDate,
        forUserId: toogleValue === 'personal' ? userIdVar() : undefined,
        householdViewType: toogleValue === 'personal' ? HouseholdViewTypes.INDIVIDUAL : HouseholdViewTypes.ALL,
      },
    }).then();
  };

  if (state.loading) {
    return <LoadingBox />;
  }

  const onSuccessTransfer = (): void => {
    console.log('SUCCESSFUL TRANSFER DETECTED');
    fetchMyGoals().then();
  };

  const onSuccessWithdraw = (): void => {
    console.log('SUCCESSFUL WITHDRAW DETECTED');
    fetchMyGoals().then();
  };
  if (addGoalWizard) {
    return (
      <FlowModal
        open={addGoalWizard}
        goBack={() => {
          setAddGoalWizard(false);
        }}
        onClose={() => (createGoalStep.current === 1 ? setAddGoalWizard(false) : undefined)}
        component={(
          <Box sx={{ maxWidth: '400px' }}>
            <CreateGoalAndSubAccountWizard
              showExistingRespFlow
              onStepChanged={(step) => { createGoalStep.current = step; }}
              onContinue={() => {
                showToast({ message: t('toastMessages:openGoalSuccess.h2'), severity: 'success', title: t('toastMessages:openGoalSuccess.h1') });
                fetchMyGoals().then();
                setAddGoalWizard(false);
                createGoalStep.current = 1;
              }}
              onSubAccountCreated={(item) => {
                if (accountsWhichRequirePaymentInstructions.includes(item.accountType as AccountTypes)) {
                  setUserContext({ ...userContext, checkRrifInformationStep: { accountId: item.accountId, open: true } });
                }
              }}
              goBack={() => {
                setAddGoalWizard(false);
              }}
            />
          </Box>
        )}
      />
    );
  }
  if (showDeposit) {
    return (
      <FlowModal
        open={showDeposit}
        onClose={() => setShowDeposit(false)}
        showCloseButton
        maintainModal
        component={(
          <Deposit
            hideBankAccountDeposit={!hasNonLockedInAccount(userContext.subAccounts ?? [])}
            hideExistingPortfolioDeposit={hideExistingPortfolioTransfer(userContext.subAccounts ?? [])}
            onClose={() => setShowDeposit(false)}
            onSuccessTransfer={() => {
              onSuccessTransfer();
            }}
          />
        )}
      />
    );
  }
  if (showWithdrawal) {
    return (
      <Withdraw
        onClose={() => setShowWithdrawal(false)}
        onSuccessWithdraw={() => {
          onSuccessWithdraw();
        }}
      />
    );
  }

  return (
    <Container className="home-page" sx={{ width: '100%', typography: 'body1', padding: '0px !important' }}>
      {displayHeader ? <OvPartnerHeader /> : null}
      {canViewHouseholdVar() && (
        <HouseholdToggleButton tabShown={tabShown} onChange={onChange} />
      )}
      <Box sx={{ padding: 0, pt: 3 }} style={boxStyle(colors).card}>
        <HistoryChart
          changeUnit={changeUnit}
          includeAllHouseholds={tabShown === 'household'}
          aggregation={{ type: DailyStatsTypes.USERS_DAILY, typeId: user.id }}
        >
          <Box sx={styles.cageWrapper}>
            <BalanceAndReturnValues
              boxProps={{ marginLeft: '0px !important', height: 'auto !important', alignItems: 'center !important' }}
              title={t('user:dashboard.title')}
              marketValueCents={user.statistics.marketValueCents}
              returnAmount={user.statistics.simpleReturnAmount}
              returnPercent={user.statistics.simpleReturnPercent}
              subtitleProp={{ marginTop: '0px !important' }}
              component="home"
            />
            {user.statistics.marketValueCents ? null : (user.statistics.pendingContributionCents === 0)
              ? <Typography variant="heading4" style={styles.firstInvestmentText}>{t('user:dashboard.firstInvestmentText')}</Typography>
              : (
                <Typography variant="captionSectionerMedium" style={styles.pendingText}>
                  <InfoRoundedIcon sx={styles.pendingIcon} />
                  {t('user:dashboard.pendingDepositText.prefix')}
                  {formatMoneyValue(user.statistics.pendingContributionCents)}
                  {t('user:dashboard.pendingDepositText.postfix')}
                </Typography>
              )}
            <Box sx={styles.buttonWrapper}>
              {
                isFeatureEnabled(FeatureFlagTypes.DEPOSITS) && (
                  <Button
                    variant="primary-small"
                    sx={styles.investButton}
                    onClick={() => {
                      sendAnalyticsEvent(ovAnalyticsEvents.homePageInvestSelect).then();
                      setShowDeposit(true);
                    }}
                    disabled={allSubAccountsBlockedDeposits || !isIdVerifiedVar()}
                  >
                    {t('transfer:action.invest')}
                  </Button>
                )
              }
              {isFeatureEnabled(FeatureFlagTypes.WITHDRAWALS) && ((user.statistics.availableFundsToWithdrawCents ?? 0) > 0) ? (
                <Button
                  variant="secondary-small"
                  sx={styles.withdrawButton}
                  onClick={() => {
                    setShowWithdrawal(true);
                  }}
                >
                  {t('transfer:action.withdraw')}
                </Button>
              ) : undefined}
            </Box>
          </Box>
        </HistoryChart>
      </Box>
      <TabContext value={value}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 2,
        }}
        >
          <TabList onChange={handleChange} aria-label="dashboard tabs">
            <Tab label={t('user:dashboard.tabs.goals')} value="1" />
            <Tab label={t('user:dashboard.tabs.accounts')} value="2" />
            <Tab label={t('user:dashboard.tabs.holdings')} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: 0, display: 'flex' }}>
          <Grid
            container
            spacing={2}
            sx={{
              padding: {
                xs: 1,
                sm: 0,
                md: 0,
              },
            }}
          >
            {loadingGoals ? (
              <>
                <Grid item md={6} xs={12}>
                  <GoalSkeleton />
                </Grid>
                <Grid item md={6} xs={12}>
                  <GoalSkeleton />
                </Grid>
              </>
            ) : (
              <>
                {goals.map((goal) => (
                  <Grid item md={goals.length > 1 ? 6 : 12} xs={12} key={goal.id}>
                    <GoalItem goal={goal} />
                  </Grid>
                ))}
              </>
            )}
            {
              isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS) && !loadingGoals && (
                <Button
                  onClick={addGoalClick}
                  fullWidth
                  size="large"
                  sx={{
                    mt: 2, ml: 2, background: 'rgba(0,0,0,0)', color: colors.primary,
                  }}
                >
                  <AddIcon sx={{ color: colors.primary }} />
                  {t('user:dashboard.tabs.addGoal')}
                </Button>
              )
            }
          </Grid>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0, display: 'flex' }}>
          <Grid
            container
            spacing={2}
            sx={{
              padding: {
                xs: 1,
                sm: 0,
                md: 0,
              },
            }}
          >
            {loadingAccounts ? (
              <>
                <Grid item md={6} xs={12}>
                  <AccountSkeleton />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AccountSkeleton />
                </Grid>
              </>
            ) : (
              <>
                {accounts.map((account) => (
                  <Grid item md={accounts.length > 1 ? 6 : 12} xs={12} key={account.id}>
                    <AccountItem account={account} totalMarketValue={user.statistics.marketValueCents} isHouseholdView={tabShown === 'household'} />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value="3" sx={{ padding: 0, display: 'block', pb: 6 }}>
          {
            availableFeatureFlagsVar().includes('ASSET_CLASSES') ? (
              <HoldingsList2
                holdings={holdingList}
                primaryAssetClassHoldings={assetClassHoldings}
              />
            ) : (
              <HoldingsList
                holdings={holdingList}
              />
            )
          }
        </TabPanel>
      </TabContext>
    </Container>
  );
};
export default DashboardPage;
