import * as React from 'react';
import {
  MenuItem,
  Menu,
} from '@mui/material';

interface Props {
  menuItems: { icon: string, title: string, onClick: () => void }[],
  anchorEl: HTMLElement | null | undefined,
  open: boolean,
  onClose: (reason: 'backdropClick' | 'escapeKeyDown') => void,
}
const OvMenu = ({
  menuItems, anchorEl, open, onClose,
}: Props): JSX.Element => (
  <Menu
    id="ov-menu"
    data-testid="ov-menu"
    anchorEl={anchorEl}
    BackdropProps={{ sx: { backgroundColor: 'transparent !important', backdropFilter: 'none !important', filter: 'none !important' } }}
    open={open}
    onClose={(e, reason) => (onClose(reason))}
    style={{ boxShadow: '0px 0px 14px rgba(122, 122, 122, 0.15)' }}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
    }}
  >
    {
      menuItems.map((item) => (
        <MenuItem key={item.title} sx={{ pt: 1.5, pb: 1.5 }} onClick={item.onClick}>
          <img src={item.icon} alt={item.title} style={{ marginRight: '12px' }} height={20} width={20} />
          {item.title}
        </MenuItem>
      ))
    }
  </Menu>
);

OvMenu.defaultProps = {};

export default OvMenu;
