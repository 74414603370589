export const FlinksErrorCodes = [
  'INVALID_USERNAME',
  'INVALID_PASSWORD',
  'INVALID_LOGIN',
  'INVALID_REQUEST',
  'INVALID_SECURITY_RESPONSE',
  'QUESTION_NOT_FOUND',
  'RETRY_LATER',
  'UNKNOWN_CHALLENGE_KEY',
  'CONCURRENT_SESSION',
  'UNAUTHORIZED',
  'DISABLED_LOGIN',
  'SESSION_EXPIRED',
  'ALREADY_AUTHORIZED',
  'SECURITYRESPONSES_INCOMPLETE',
  'DISABLED_INSTITUTION',
  'AGGREGATION_ERROR',
];
