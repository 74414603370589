import {
  Box,
  Button, Link, Typography, useMediaQuery,
} from '@mui/material';
import FlowModal from '../wrappers/modals/ovFlowModal';

interface Props {
  title: string,
  description: string,
  children?: JSX.Element[],
  link?: { url: string, text: string, descriptionAfterLink?: string }
  buttonText: string,
  open: boolean,
  onClose: () => void,
  onButtonClick: () => void,
  cancelButton?: {
    onClick: () => void,
    text: string,
  },
  disabled?: boolean,
}

const ConfirmationModal = ({
  title,
  description,
  buttonText,
  open,
  onClose,
  onButtonClick,
  link,
  children,
  cancelButton,
  disabled,
}: Props): JSX.Element | null => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  if (open) {
    return (
      <FlowModal
        open={open}
        onClose={onClose}
        showCloseButton
        component={(
          <Box sx={{ width: isMobileScreen ? '100%' : '450px' }}>
            <Typography data-testid="dialog-title" variant="heading3">{title}</Typography>
            <Typography data-testid="dialog-description" variant="paragraph3">
              {description}
              {link ? <Link href={link.url} underline="always" target="_blank">{link.text}</Link> : null}
              {link?.descriptionAfterLink ? link.descriptionAfterLink : null}
            </Typography>
            {children}
            {cancelButton ? <Button variant="secondary-large" disabled={disabled} data-testid="dialog-continue-button" onClick={cancelButton.onClick} sx={{ marginBottom: '16px' }}>{cancelButton.text}</Button> : null}
            <Button variant="primary-large" data-testid="dialog-continue-button" disabled={disabled} onClick={onButtonClick}>{buttonText}</Button>
          </Box>
        )}
      />
    );
  }
  return null;
};

ConfirmationModal.defaultProps = {
  link: undefined,
  cancelButton: undefined,
  children: undefined,
  disabled: undefined,
};

export default ConfirmationModal;
