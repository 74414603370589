import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import * as React from 'react';
import OvForm from '../common/wrappers/ovForm';
import fillFormImage from '../../assets/images/fill-form.svg';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { RetirementAccountType } from '../checker';

interface Props {
  onContinue?: () => void,
  premise: 'onboarding'|'no-payment-instruction'|'no-transaction',
  uncompletedRrifAccount: RetirementAccountType;
}

const FinishRRIFAccountSetup = ({ premise, onContinue, uncompletedRrifAccount }: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const translatedAccountType = t(`account:resources.typeTitle.${uncompletedRrifAccount.type}`);
  return (
    <OvForm
      onSubmit={() => {
        sendAnalyticsEvent(ovAnalyticsEvents.rrifFinishAccountSetupModalFinishButtonSelect).then();
        if (onContinue) onContinue();
      }}
      loading={false}
      buttonText={t('account:rrifSteps.finishRRIFAccountSetup.button')}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: '1 0 auto', textAlign: 'center' }}>
          <img src={fillFormImage} alt="form" />
          <Typography variant="heading2" style={{ marginBottom: 16 }}>{t('account:rrifSteps.finishRRIFAccountSetup.title', { accountType: translatedAccountType })}</Typography>
          {
            premise === 'no-transaction' ? (
              <>
                <Typography variant="bodySub2" style={{ marginBottom: 16, marginRight: 4 }}>{t('account:rrifSteps.finishRRIFAccountSetup.transferFundText.1', { accountType: translatedAccountType })}</Typography>
                <Link href="https://onevest.zendesk.com/hc/en-us/articles/14480147492372-What-is-a-RRIF-" target="_blank" color="inherit">
                  {t('account:rrifSteps.finishRRIFAccountSetup.transferFundText.2')}
                </Link>
              </>
            ) : undefined
          }
          {
            premise === 'no-payment-instruction' ? (
              <>
                <Typography variant="bodySub2" style={{ marginBottom: 16, marginRight: 4 }}>{t('account:rrifSteps.finishRRIFAccountSetup.paymentInstructionText.1', { accountType: translatedAccountType })}</Typography>
                <Link href="https://onevest.zendesk.com/hc/en-us/articles/14480147492372-What-is-a-RRIF-" target="_blank" color="inherit">
                  {t('account:rrifSteps.finishRRIFAccountSetup.paymentInstructionText.2')}
                </Link>
              </>
            ) : undefined
          }
          <Box mt={2} />
        </div>
      </div>
    </OvForm>
  );
};

FinishRRIFAccountSetup.defaultProps = {
  onContinue: undefined,
};

export default FinishRRIFAccountSetup;
