import { DocumentNode, gql } from '@apollo/client';

export const FETCH_ACCOUNT = (isCashAccount: boolean): DocumentNode => gql`
query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        tlhEnabled
        user {
          id
          firstName
          statistics {
            id
            marketValueCents
          }
        }
        householdClientGroup {
          name
          relationships {
            user {
              id
              firstName
            }
          }
        }
        statistics {
          id
          marketValueCents
          simpleReturnPercent
          simpleReturnAmount
          netContributionCents
          pendingContributionCents
          netContributionCentsInFiscalYear
          capitalGainsAndLossesInFiscalYear
          ${isCashAccount ? 'taxLossHarvestCreditInFiscalYear { creditsCents }' : ''}
        }
        scheduledIncomeFundTransfer {
          id
        }
        affiliations {
          id
          type
          user { id }
        }
        subAccounts {
          id
          state
          name
          statistics {
            id
            marketValueCents
            simpleReturnPercent
            simpleReturnAmount
          }
          theme {
            id
            key
            translatedName {
              en
              fr
            },
            iconKey
          }
          goal {
            id
            name
            type
          }
          account {
            id
            type
            state
            user {
              id
            }
          }
        }
      }
    }
  }
`;

export const CLOSE_ACCOUNT = gql`
  mutation closeAccount($input: CloseAccountInput!) {
    closeAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

export const FETCH_ACCOUNT_TYPES = gql`
  query fetchAccountTypes {
    fetchAccountTypes {
      accountTypes {
        value
        translatedName {
          en
          fr
        }
        translatedDescription {
          en
          fr
        }
      }
    }
  }
`;
