import { useLazyQuery } from '@apollo/client';
import { List, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonDescriptionListItem from '../common/lists/buttonDescriptionListItem';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import { FETCH_GOAL_AVAILABLE_THEMES, ThemeResponse } from './graphql';
import { AccountTypes, mapThemeAttributes, ThemeAttributes } from './resources';
import OvForm from '../common/wrappers/ovForm';
import { SubAccount } from '../../utils/commonGraphql';
import { sendAnalyticsEvent } from '../../utils/firebase';

export interface SubAccountRef {
  themeId?: string,
}

interface Props {
  goalId: string,
  setSubAccountRef: (att: SubAccountRef) => void,
  selectedTheme?: ThemeAttributes,
  setSelectedTheme: (att: ThemeAttributes) => void,
  setHasMultipleThemes?: (value: boolean) => void,
  onContinue?: () => void,
  currentSubAccount?: SubAccount | {
    account?: {
      type?: AccountTypes;
    },
    theme?: {
      id?: string;
    },
  },
  analyticsEvent?: string,
}

const SelectTheme = ({
  goalId, setSubAccountRef, selectedTheme, setSelectedTheme, onContinue, currentSubAccount, setHasMultipleThemes, analyticsEvent,
}: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const [availableThemes, setAvailableThemes] = useState<ThemeAttributes[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedOptionId, setSelectedOptionId] = useState(selectedTheme?.id ?? currentSubAccount?.theme?.id ?? '');

  const handleClick = async (item: ThemeAttributes): Promise<void> => {
    console.log({ event: 'SUB_ACCOUNT_THEME_SELECTED', themeId: item.id });
    setSubAccountRef({ themeId: item.id });
    setSelectedTheme(item);
    setSelectedOptionId(item.id);
  };

  const onFetchAvailableThemesCompleted = (data: { fetchGoal: { goal: { availableThemes: ThemeResponse[] } } }): void => {
    console.log({ event: 'AVAILABLE_THEMES_FETCHED', count: data.fetchGoal.goal.availableThemes.length });
    const mappedThemes = mapThemeAttributes(data.fetchGoal.goal.availableThemes);
    if (setHasMultipleThemes) setHasMultipleThemes(mappedThemes.length > 1);
    if (mappedThemes.length === 1) {
      handleClick(mappedThemes[0]).then();
      if (onContinue) onContinue();
      setLoading(false);
    } else {
      setAvailableThemes(mappedThemes);
      if (selectedTheme?.id === undefined && currentSubAccount?.theme?.id === undefined) {
        setSelectedOptionId(data.fetchGoal.goal.availableThemes.find((theme) => theme.key === 'CORE')?.id ?? '');
      }
      setLoading(false);
    }
  };

  const [fetchAvailableThemes] = useLazyQuery(FETCH_GOAL_AVAILABLE_THEMES, {
    onCompleted: onFetchAvailableThemesCompleted,
    nextFetchPolicy: 'standby',
    variables: { goalId },
  });

  useEffect(() => {
    if (availableThemes.length === 0) {
      fetchAvailableThemes().then();
    }
  }, [availableThemes, fetchAvailableThemes]);

  if (loading) {
    return <OvLoadingIndicator />;
  }

  const onThemeSelected = (event: React.FormEvent<HTMLFormElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    if (analyticsEvent) sendAnalyticsEvent(analyticsEvent).then();
    if (selectedTheme === undefined) {
      setSubAccountRef({ themeId: availableThemes[0].id });
      setSelectedTheme(availableThemes[0]);
    }
    if (onContinue) onContinue();
  };

  return (
    <OvForm hasButton loading={false} disableButton={!selectedOptionId} onSubmit={onThemeSelected}>
      <Typography variant="heading2">{t('account:theme.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('account:theme.subTitle')}</Typography>
      <List className="theme-select-list">
        {availableThemes.map((item: ThemeAttributes) => (
          <ButtonDescriptionListItem
            header={item.name}
            prefixIcon={item.singleIconPath}
            key={item.key}
            description={item.shortDescription}
            onClick={() => handleClick(item)}
            hasCheckField
            checked={selectedOptionId === item.id}
            isSelected={selectedOptionId === item.id}
            recommended={item.key === 'CORE'}
          />
        ))}
      </List>
    </OvForm>
  );
};

SelectTheme.defaultProps = {
  selectedTheme: undefined,
  onContinue: undefined,
  currentSubAccount: undefined,
  setHasMultipleThemes: undefined,
  analyticsEvent: undefined,
};

export default SelectTheme;
