/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Button, Container, Grid, Typography,
} from '@mui/material';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { isUndefined } from 'lodash';
import { navigate, useParams } from '../../utils/router';
import LogoutMenuIcon from '../../assets/images/menu/logout.svg';
import EditGoalIcon from '../../assets/images/menu/edit-goal.svg';
import EditTargetAmountIcon from '../../assets/images/menu/edit-target-amount.svg';
import TargetIcon from '../../assets/images/target-icon.svg';
import { DailyStatsTypes } from '../../components/statistics/graphql';
import HistoryChart from '../../components/statistics/historyChart';
import BalanceAndReturnValues from '../../components/statistics/balanceAndReturnValues';
import ProgressBar from '../../components/statistics/progressBar';
import LatestTransfers from '../../components/transfers/latestTransfers';
import AutoInvestmentSummary from '../../components/transfers/autoInvestmentSummary';
import OvBreadCrumb from '../../components/common/headers/ovBreadCrumb';
import {
  COMPLETE_GOAL, FETCH_GOAL, GoalDetailsResponse, SequenceAndId, UPDATE_GOAL_DETAILS, UpdatedGoalResponse,
} from './graphql';
import PortfolioCard from '../../components/statistics/portfolioCard';
import AmountInput from '../../components/common/inputs/amountInput';
import { Item, styles } from './style';
import SimpleInput from '../../components/common/inputs/simpleInput';
import OvMenu from '../../components/common/headers/ovMenu';
import Deposit from '../../components/transfers/deposit';
import Withdraw from '../../components/transfers/withdrawal';
import { translateBackend } from '../../assets/i18n/config';
import CreateSubAccountWizard from '../../components/account/createSubAccountWizard';
import LoadingBox from '../../components/common/loaders/loadingBox';
import { UserContext } from '../../providers/userContextProvider';
import ConfirmationModal from '../../components/common/modals/confirmationModal';
import FlowModal from '../../components/common/wrappers/modals/ovFlowModal';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import {
  AccountTypes, FeatureFlagTypes, getAccountTypeName, isFeatureEnabled,
} from '../../components/account/resources';
import PaymentInstruction from '../../components/transfers/paymentInstruction';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { HouseholdToggleButton } from '../../components/common/components/householdToggleButton';
import { availableFeatureFlagsVar, isIdVerifiedVar, userIdVar } from '../../utils/localVariables';
import { HouseholdBadge } from '../../components/common/components/householdBadge';
import { householdInitial } from '../dashboard';
import { hasHouseholdEditAccess } from '..';
import { HouseholdViewTypes, Relationship, Statistics } from '../../utils/commonGraphql';
import {
  accountsWhichRequirePaymentInstructions, allowedAccountStates,
  determineAccountsToExclude, hasPaymentInstruction,
  hideExistingPortfolioTransfer,
  isRetirementAccount,
} from '../../utils/accountUtil';
import { createMembers } from '../accountsDetails';
import { MAXIMUM_TARGET_AMOUNT } from '../../components/goal/resources';
import { allowedSubAccountStates } from '../../utils/subAccountUtils';

export interface ValidateState {
  goalName?: boolean,
}
export interface NameAndStats { name: string, statistics: Statistics }
const GoalDetails = (): JSX.Element => {
  const { userContext, setUserContext } = useContext(UserContext);
  const { colors } = useContextTheme();
  const params = useParams();
  const goalId = params.goalId ?? '';
  const household = params.household ?? undefined;
  const householdEditAccess = availableFeatureFlagsVar().includes(FeatureFlagTypes.HOUSEHOLD) && household && hasHouseholdEditAccess(household);
  const indexedMembers = householdEditAccess
    ? Object.fromEntries((household?.relationships ?? []).map((rel: Relationship, index: number) => [rel.user?.id, index])) : [];
  const matches = useMediaQuery('(max-width:960px)');
  const { t } = useTranslation(['base', 'transfer', 'goal']);
  const [targetAmountCents, setTargetAmountCents] = useState(0);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showWithdrawal, setShowWithdrawal] = useState(false);
  const [addTargetAmountModal, setAddTargetAmountModal] = useState(false);
  const [addSubAccountWizardStep, setAddSubAccountWizardStep] = useState(1);
  const [editGoalNameModal, setEditGoalNameModal] = useState(false);
  const [addSubAccount, setAddSubAccount] = useState(false);
  const [refreshLatestTransfers, setRefreshLatestTransfers] = useState(false);
  const [tabShown, setTabShown] = useState<'personal' | 'household'>(householdEditAccess ? 'household' : 'personal');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [validState, setValidState] = useState<ValidateState>({
    goalName: true,
  });
  const showEditGoalNameModal = (): void => {
    setEditGoalNameModal(true);
  };
  const showAddTargetAmountModal = (): void => {
    setAddTargetAmountModal(true);
  };
  const dialogClose = (): void => {
    setAddTargetAmountModal(false);
  };
  const goalNameDialogClose = (): void => {
    setEditGoalNameModal(false);
  };
  const [selectedGoal, setSelectedGoal] = useState<GoalDetailsResponse>({});
  const [goalName, setGoalName] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(true);
  const [closeGoalInformation, setCloseGoalInformation] = useState(false);
  const [closeGoalConfirmation, setCloseGoalConfirmation] = useState(false);
  const [closeGoalError, setCloseGoalError] = useState(false);
  const [closeGoalMsg, setCloseGoalMsg] = useState('');
  const [members, setMembers] = useState<{ name?: string, index: number }[]>();
  const { showToast } = useGlobalToast();
  const open = Boolean(anchorEl);
  const hasAccountWithPaymentInstruction = (): boolean => hasPaymentInstruction({ subAccounts: selectedGoal.subAccounts });
  const hasLockedInAccount = (): boolean => isRetirementAccount({ subAccounts: selectedGoal.subAccounts });
  const handleClick = (event: React.MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const completeGoalData = (data: { completeGoal: { goal: { id: string } } }): void => {
    console.log({ event: 'COMPLETE_GOAL', subAccount: data.completeGoal.goal.id });
    sendAnalyticsEvent(ovAnalyticsEvents.goalPageCloseGoalSelect).then();
    // this removes the recently closed goal from the user context
    setUserContext((prevState) => ({ ...prevState, goals: prevState.goals?.filter((goal) => goal.id !== data.completeGoal.goal.id) }));
    setCloseGoalConfirmation(false);
    navigate(setUserContext, '/');
    showToast({ message: t('toastMessages:closeGoalSuccess.h2'), severity: 'success', title: t('toastMessages:closeGoalSuccess.h1') });
  };
  const completeGoalError = (error: ApolloError): void => {
    const foundError = error.graphQLErrors.find((item) => item.extensions.code === 'NOT_ACCESSIBLE');
    setCloseGoalConfirmation(false);
    setCloseGoalError(true);
    if (foundError) {
      setCloseGoalMsg(t(`goal:goalDetails.errorDialog.errorMessages.${foundError.extensions.code}`));
    }
  };
  const [completeGoalMutation] = useMutation(COMPLETE_GOAL, {
    variables: {
      input: {
        goalId,
      },
      skipErrorHandler: true,
    },
    onCompleted: completeGoalData,
    onError: (e) => completeGoalError(e),
  });

  const UPDATE_GOAL_MENU_ITEM = isFeatureEnabled(FeatureFlagTypes.UPDATE_GOAL) ? [
    {
      icon: EditGoalIcon,
      title: t('goal:goalDetails.menuOptions.editGoalName'),
      onClick: () => {
        showEditGoalNameModal();
      },
    },
    { icon: EditTargetAmountIcon, title: t('goal:goalDetails.menuOptions.editTargetAmount'), onClick: () => showAddTargetAmountModal() },
  ] : [];

  const MENU_ITEMS = [
    ...UPDATE_GOAL_MENU_ITEM,
    {
      icon: LogoutMenuIcon,
      title: t('goal:goalDetails.menuOptions.closeGoal'),
      onClick: () => setCloseGoalInformation(true),
    },
  ];

  const clearGoalName = (): void => {
    setGoalName('');
  };
  const setGoalObject = (data: {
    updateGoal: {
      goal: UpdatedGoalResponse,
    },
  }): void => {
    console.log({ event: 'UPDATED_GOAL', userId: data.updateGoal.goal.id });
    if (addTargetAmountModal) {
      sendAnalyticsEvent(ovAnalyticsEvents.goalPageAddTargetAmountSelect).then();
    }
    sendAnalyticsEvent(ovAnalyticsEvents.goalPageEditGoalSelect).then();
    setSelectedGoal((prevState) => ({
      ...prevState,
      name: data.updateGoal.goal.name,
      targetAmountCents: data.updateGoal.goal.targetAmountCents,
    }));
    setGoalName(data.updateGoal.goal.name);
    handleClose();
    setAddTargetAmountModal(false);
    setEditGoalNameModal(false);
  };

  const [updateGoalMutation] = useMutation(UPDATE_GOAL_DETAILS, {
    variables: {
      input: {
        goalId,
        targetAmountCents: selectedGoal.targetAmountCents,
        name: selectedGoal.name,
      },
    },
    onCompleted: setGoalObject,
  });

  const [allSubAccountsBlockedDeposits, setAllSubAccountsBlockedDeposits] = useState(false);

  const setSelectedGoalData = (data: {
    fetchGoal: {
      goal: GoalDetailsResponse,
    },
  }): void => {
    if (!data?.fetchGoal?.goal) return;
    const theGoal = data.fetchGoal.goal;
    console.log({ event: 'GOAL', goal: theGoal });
    setSelectedGoal(theGoal);
    setGoalName(theGoal.name ?? '');
    if (theGoal.targetAmountCents) {
      setTargetAmountCents(theGoal.targetAmountCents);
    }

    const subAccountsWithAllowedDeposits = theGoal.subAccounts?.filter((subAccount) => subAccount.allowClientDeposits === true);
    setAllSubAccountsBlockedDeposits(subAccountsWithAllowedDeposits?.length === 0);

    if (theGoal.user) {
      const membersList = createMembers(theGoal.user, theGoal.householdClientGroup);
      setMembers(membersList);
    }

    setLoading(false);
  };

  /* the statistics split by member */
  let orderedStatisticsByMember: NameAndStats[] = [];
  if (household?.relationships && (selectedGoal as any).user0statistics) {
    orderedStatisticsByMember = household.relationships.map((x: any, index: number) => (
      { name: x?.user?.firstName ?? '', statistics: (selectedGoal as any)[`user${index}statistics`] }
    ));
  }

  const withMemberStats: SequenceAndId[] | undefined = indexedMembers
    && Object.entries(indexedMembers).map(([id, index]) => ({ id, sequence: index }));

  const [fetchGoal, { refetch, called }] = useLazyQuery(FETCH_GOAL(withMemberStats), {
    variables: {
      goalId,
      startDate,
      forUserId: tabShown === 'personal' ? userIdVar() : undefined,
    },
    onCompleted: setSelectedGoalData,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'standby',
    onError: () => navigate(setUserContext, '/'),
  });
  useEffect(() => {
    fetchGoal({
      variables: {
        goalId,
        startDate,
        forUserId: tabShown === 'personal' ? userIdVar() : undefined,
      },
    }).then();
  }, [goalId, fetchGoal, tabShown, startDate]);

  const changeUnit = (frame: string): void => {
    let date = null;
    if (frame === '1W') {
      date = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
    }
    if (frame === '1M') {
      date = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
    }
    if (frame === '3M') {
      date = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
    }
    if (frame === '1Y') {
      date = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
    }
    setStartDate(date);
    fetchGoal({
      variables: {
        goalId,
        startDate: date,
        forUserId: tabShown === 'personal' ? userIdVar() : undefined,
      },
    });
  };

  const onChange = (event: React.MouseEvent<HTMLElement>, toogleValue: 'personal' | 'household'): any => {
    setTabShown(toogleValue);
  };

  const onSuccessTransfer = (): void => {
    console.log('SUCCESSFUL TRANSFER DETECTED');
    setRefreshLatestTransfers(true);
    if (called) {
      refetch({
        goalId,
      });
    }
  };

  const onSuccessWithdraw = (): void => {
    console.log('SUCCESSFUL WITHDRAW DETECTED');
    setRefreshLatestTransfers(true);
    if (called) {
      refetch({
        goalId,
      });
    }
  };

  const addTargetAmount = (): JSX.Element => (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '12px' }}>
        <Typography variant="captionSectionerBold" color={colors.neutral20}>{t('goal:goalDetails.targetAmount.title')}</Typography>
        <u style={{ cursor: 'pointer' }}><Typography variant="captionSectionerBold" color={colors.link}>{t('goal:goalDetails.targetAmount.subTitle')}</Typography></u>
      </Box>
      <Button style={styles.enableAutoInvestButton} onClick={showAddTargetAmountModal}>
        <Box style={styles.enableAutoInvestButton.box}>
          <img src={TargetIcon} alt="diamond-shine" style={styles.enableAutoInvestButton.box.image} />
          <Typography variant="heading5" style={styles.headerText}>
            {t('goal:goalDetails.targetAmount.buttonText')}
          </Typography>
        </Box>
        <ChevronRightRoundedIcon style={styles.enableAutoInvestButton.chevronIcon} />
      </Button>
      <Box display="flex" flexDirection="row" justifyContent="left" sx={styles.targetMessage}>
        <Typography variant="captionSectionerBold" color={colors.targetAmountMessage}>{t('goal:goalDetails.targetAmount.message')}</Typography>
      </Box>
    </>
  );
  const updateTargetAmount = (): void => {
    if (targetAmountCents > MAXIMUM_TARGET_AMOUNT) {
      showToast({ message: '', severity: 'error', title: t('goal:targetAmount.validation') });
      return;
    }
    updateGoalMutation({
      variables: {
        input: {
          goalId,
          name: undefined,
          targetAmountCents,
        },
      },
    }).then();
  };
  const checkFieldsValidity = async (): Promise<boolean> => {
    const goalNameValidity = await yup.string().required().isValid(goalName);
    setValidState({ ...validState, goalName: goalNameValidity });
    return goalNameValidity;
  };
  const updateGoalName = async (): Promise<void> => {
    if (!await checkFieldsValidity()) return;
    updateGoalMutation({
      variables: {
        input: {
          goalId,
          name: goalName,
          targetAmountCents: undefined,
        },
      },
    }).then();
  };

  const loadEditTargetAmountModelContent = (): JSX.Element => (
    <Box>
      <Typography variant="headingMain" sx={styles.headingText}>
        {t('goal:goalDetails.editTargetAmountModal.title')}
      </Typography>
      <Typography variant="captionSectionerMedium" sx={styles.subText}>
        {t('goal:goalDetails.editTargetAmountModal.subTitle')}
      </Typography>
      <Typography variant="captionSectionerMedium" sx={styles.inputLabel}>
        {t('goal:goalDetails.editTargetAmountModal.inputLabel')}
      </Typography>
      <AmountInput
        label={t('goal:targetAmount.label')}
        valueCents={targetAmountCents}
        style={styles.inputStyle}
        onChange={(event) => setTargetAmountCents(event.target.valueCents)}
        testId="goal-target-amount-input"
      />
      <Button variant="primary-large" sx={styles.button} onClick={updateTargetAmount}>{t('base:button.continue')}</Button>
    </Box>
  );
  const loadEditGoalNameModelContent = (): JSX.Element => (
    <Box>
      <Typography variant="headingMain" sx={styles.headingText}>
        {t('goal:goalDetails.editGoalModal.title')}
      </Typography>
      <Typography variant="captionSectionerMedium" sx={styles.subText}>
        {t('goal:goalDetails.editGoalModal.subTitle')}
      </Typography>
      <Box>
        <SimpleInput
          label={t('goal:type.nameInputLabel')}
          value={goalName}
          required
          style={styles.inputStyle}
          onChange={(event) => setGoalName(event.target.value)}
          testId="goal-name-input"
          onClear={clearGoalName}
          error={!validState.goalName}
        />
      </Box>
      <Button variant="primary-large" disabled={!goalName} sx={styles.goalEditButton} onClick={updateGoalName}>{t('base:button.continue')}</Button>
    </Box>
  );

  if (loading) {
    return <LoadingBox />;
  }
  const hideBankAccountDeposit = (): boolean => isRetirementAccount({ subAccounts: selectedGoal.subAccounts });
  if (addSubAccount) {
    return (
      <FlowModal
        goBack={() => setAddSubAccount(false)}
        open={addSubAccount}
        onClose={() => (addSubAccountWizardStep === 1 ? setAddSubAccount(false) : undefined)}
        showCloseButton={false}
        component={(
          <Box sx={{ maxWidth: '400px' }}>
            <CreateSubAccountWizard
              showExistingRespFlow
              accountsToExclude={determineAccountsToExclude(selectedGoal?.subAccounts ?? [])}
              onStepChanged={(step) => setAddSubAccountWizardStep(step)}
              stepTitle={t('goal:goalDetails.addPortfolioWizardHeader')}
              financialProduct={selectedGoal.financialProduct}
              goalId={goalId}
              goBack={() => setAddSubAccount(false)}
              onContinue={() => {
                showToast({ message: t('toastMessages:openPortfolioSuccess.h2'), severity: 'success', title: t('toastMessages:openPortfolioSuccess.h1') });
                fetchGoal({ variables: { goalId, startDate, forUserId: tabShown === 'personal' ? userIdVar() : undefined } }).then();
                setAddSubAccount(false);
                setAddSubAccountWizardStep(1);
              }}
              onSubAccountCreated={(value) => {
                if (accountsWhichRequirePaymentInstructions.includes(value.accountType as AccountTypes)) {
                  setUserContext({ ...userContext, checkRrifInformationStep: { accountId: value.accountId, open: true } });
                }
              }}
            />
          </Box>
        )}
      />
    );
  }
  if (showDeposit) {
    return (
      <FlowModal
        open={showDeposit}
        onClose={() => setShowDeposit(false)}
        showCloseButton
        maintainModal
        component={(
          <Deposit
            hideBankAccountDeposit={hideBankAccountDeposit()}
            hideExistingPortfolioDeposit={hideExistingPortfolioTransfer(userContext.subAccounts ?? [])}
            initialStep={hasLockedInAccount() ? 3 : 1}
            onClose={() => setShowDeposit(false)}
            selectedGoalId={goalId}
            onSuccessTransfer={() => {
              onSuccessTransfer();
            }}
          />
        )}
      />
    );
  }
  if (showWithdrawal) {
    return (
      <Withdraw
        onClose={() => setShowWithdrawal(false)}
        goalId={goalId}
        onSuccessWithdraw={() => {
          onSuccessWithdraw();
        }}
      />
    );
  }
  if (addTargetAmountModal) {
    return (
      <FlowModal
        open={addTargetAmountModal}
        onClose={() => {
          dialogClose();
          handleClose();
        }}
        showCloseButton
        component={loadEditTargetAmountModelContent()}
      />
    );
  }
  if (editGoalNameModal) {
    return (
      <FlowModal
        open={editGoalNameModal}
        onClose={() => {
          goalNameDialogClose();
          handleClose();
        }}
        showCloseButton
        component={loadEditGoalNameModelContent()}
      />
    );
  }
  const getTheFirstRetirementAccount = (): { id: string, type: string } => {
    const foundSubAccount = selectedGoal?.subAccounts?.find((subAccount) => allowedSubAccountStates.includes(subAccount.state)
      && allowedAccountStates.includes(subAccount.account.state)
      && accountsWhichRequirePaymentInstructions.includes(subAccount.account.type as AccountTypes));
    if (foundSubAccount) return foundSubAccount.account;
    return { id: '', type: '' };
  };
  const firstActiveRetirementAccount = getTheFirstRetirementAccount();
  return (
    <Container className="home-page" sx={styles.goalDetailsContainer}>
      <Box sx={styles.detailBox}>
        <Box sx={{ paddingLeft: '24px', position: 'relative', top: '-7px' }}>
          <OvBreadCrumb breadcrumbItems={[
            { title: t('base:breadcrumb.home'), onClick: () => navigate(setUserContext, '/') },
            { title: selectedGoal.name ?? '' },
          ]} />
        </Box>
        <Box sx={styles.dotMenueWrapper}>
          <MoreVertIcon sx={styles.menuIcon} style={{ top: matches ? '8px' : '11px' }} onClick={handleClick} />
          <OvMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            menuItems={MENU_ITEMS}
          />
        </Box>
      </Box>
      <Box sx={styles.detailWrapper}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={!matches ? 7 : 12}>
            <Item sx={styles.leftCage} style={{ padding: '0px !important', marginLeft: !matches ? '24px' : '0px', background: colors.baseComponentBackground }}>
              {householdEditAccess && (
                <Box display="flex" flexDirection="row" sx={{ m: 1 }}>
                  <HouseholdToggleButton tabShown={tabShown} onChange={onChange} />
                  <HouseholdBadge
                    sequenceNumber={0}
                    initials={householdInitial(selectedGoal.householdClientGroup?.name)}
                    toolTipLabel={selectedGoal.householdClientGroup?.name ?? ''}
                    showInitial={false}
                  />
                </Box>
              )}
              <HistoryChart
                changeUnit={changeUnit}
                includeAllHouseholds={tabShown === 'household'}
                aggregation={{ type: DailyStatsTypes.GOALS_DAILY, typeId: goalId }}
              >
                <Box sx={styles.cageWrapper}>
                  <BalanceAndReturnValues
                    boxProps={{ marginLeft: '0px !important', height: 'auto !important', alignItems: 'center !important' }}
                    title="Goal Balance"
                    marketValueCents={selectedGoal.statistics?.marketValueCents ?? 0}
                    returnAmount={selectedGoal.statistics?.simpleReturnAmount ?? 0}
                    returnPercent={selectedGoal.statistics?.simpleReturnPercent ?? 0}
                    subtitleProp={{ marginTop: '0px !important' }}
                    component="home"
                  />
                  <Box sx={styles.buttonWrapper}>
                    {
                      isFeatureEnabled(FeatureFlagTypes.DEPOSITS) && (
                        <Button
                          variant="primary-small"
                          sx={styles.investButton}
                          onClick={() => {
                            sendAnalyticsEvent(ovAnalyticsEvents.goalPageInvestSelect).then();
                            setRefreshLatestTransfers(false);
                            setShowDeposit(true);
                          }}
                          disabled={allSubAccountsBlockedDeposits || !isIdVerifiedVar()}
                        >
                          {t('transfer:action.invest')}
                        </Button>
                      )
                    }
                    {
                      isFeatureEnabled(FeatureFlagTypes.WITHDRAWALS) && ((selectedGoal?.statistics?.availableFundsToWithdrawCents ?? 0) > 0)
                      && (
                        <Button
                          variant="secondary-small"
                          sx={styles.withdrawButton}
                          onClick={() => {
                            sendAnalyticsEvent(ovAnalyticsEvents.goalPageWithdrawSelect).then();
                            setRefreshLatestTransfers(false);
                            setShowWithdrawal(true);
                          }}
                        >
                          {t('transfer:action.withdraw')}
                        </Button>
                      )
                    }
                  </Box>
                </Box>
              </HistoryChart>
            </Item>
            <Box sx={{
              margin: '25px 0px',
              marginLeft: matches ? '10px' : '24px',
              marginRight: matches ? '10px' : '0px',
            }}>
              <Typography variant="captionSectionerBold" color={colors.neutral20} style={styles.portfolioText}>{t('goal:goalDetails.portfolios')}</Typography>
              <Grid container spacing={1.5}>
                {
                  selectedGoal.subAccounts
                    ?.filter((s) => s.state !== 'INACTIVE')
                    .filter((s) => (tabShown === 'personal' ? (isUndefined(s.account?.householdClientGroup?.id) || s.account.user.id === userIdVar()) : true))
                    .map((subAcc) => (
                      <Grid item md={(selectedGoal.subAccounts?.length || 0) > 1 ? 6 : 12} xs={12} key={subAcc.id}>
                        <PortfolioCard
                          boxProps={{ margin: '0', sx: { cursor: 'pointer' } }}
                          key={subAcc.id}
                          themeKey={subAcc.theme?.key || ''}
                          themeIconKey={subAcc.theme?.iconKey || ''}
                          themeName={translateBackend(subAcc.theme?.translatedName) || ''}
                          accountType={getAccountTypeName(subAcc.account.type)}
                          marketValueCents={subAcc.statistics.marketValueCents}
                          simpleReturnAmount={subAcc.statistics.simpleReturnAmount}
                          simpleReturnPercent={subAcc.statistics.simpleReturnPercent}
                          onClick={() => {
                            sendAnalyticsEvent(ovAnalyticsEvents.goalPagePortfolioSelect).then();
                            navigate(setUserContext, '/goal/portfolio', { goalId, subAccountId: subAcc.id, household });
                          }}
                          members={members}
                        />
                      </Grid>
                    ))
                }
              </Grid>
              {
                isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_SUB_ACCOUNTS) && !hasLockedInAccount() && (
                  <Button
                    onClick={() => {
                      sendAnalyticsEvent(ovAnalyticsEvents.goalPageAddNewPortfolioSelect).then();
                      setAddSubAccount(true);
                    }}
                    fullWidth
                    size="large"
                    sx={{
                      mt: 2, background: 'rgba(0,0,0,0)', color: colors.primary,
                    }}
                  >
                    <AddIcon sx={{ color: colors.primary }} />
                    {t('base:button.addPortfolio')}
                  </Button>
                )
              }
            </Box>
          </Grid>
          <Grid item xs={!matches ? 5 : 12} sx={{ paddingRight: !matches ? '27px' : '14px' }}>
            <Box sx={{ boxShadow: 'none' }}>
              <Box sx={{
                marginTop: '8px',
                marginLeft: '10px',
              }}>
                {(selectedGoal.targetAmountCents) ? (
                  <ProgressBar
                    title={t('goal:goalDetails.targetAmountProgress.title')}
                    text1={t('goal:goalDetails.targetAmountProgress.todaysAmount')}
                    text2={t('goal:goalDetails.targetAmountProgress.targetAmount')}
                    number1={selectedGoal.statistics?.marketValueCents ?? 0}
                    number2={selectedGoal.targetAmountCents}
                    orderedStatisticsByMember={tabShown === 'household' ? orderedStatisticsByMember : undefined}
                  />
                ) : (selectedGoal.targetAmountCents === 0) ? null : addTargetAmount()}
              </Box>
              <Box sx={{
                margingTop: '36px',
                padding: '10px 0px 10px 10px',
                textAlign: 'left',
              }}>
                {
                  !hasAccountWithPaymentInstruction() && !loading ? (
                    <AutoInvestmentSummary
                      goalId={goalId}
                      depositsBlocked={allSubAccountsBlockedDeposits}
                      onEnableAutoInvestment={() => {
                        sendAnalyticsEvent(ovAnalyticsEvents.goalPageEnableAutoInvestments).then();
                      }}
                      viewAutoInvestment={() => {
                        sendAnalyticsEvent(ovAnalyticsEvents.goalPageScheduledAutoInvestmentsSelect).then();
                      }}
                      householdViewType={tabShown === 'household' ? HouseholdViewTypes.ALL : HouseholdViewTypes.INDIVIDUAL}
                    />
                  ) : undefined
                }
                {
                  hasAccountWithPaymentInstruction() && !loading && firstActiveRetirementAccount.id !== '' ? (
                    <PaymentInstruction
                      account={firstActiveRetirementAccount}
                      viewAutoInvestment={() => {
                        sendAnalyticsEvent(ovAnalyticsEvents.accountPageViewAutoInvestmentSelect).then();
                      }}
                    />
                  ) : undefined
                }
              </Box>
              <Box sx={styles.historyWrapper}>
                <LatestTransfers
                  goalId={goalId}
                  refresh={refreshLatestTransfers}
                  householdViewType={tabShown === 'household' ? HouseholdViewTypes.ALL : HouseholdViewTypes.INDIVIDUAL}
                  onViewAllTransactionsClicked={() => sendAnalyticsEvent(ovAnalyticsEvents.goalPageInvestmentHistorySelect).then()}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        title={t('goal:goalDetails.informationDialog.title')}
        description={t('goal:goalDetails.informationDialog.details')}
        buttonText={t('goal:goalDetails.informationDialog.continueButton')}
        open={closeGoalInformation}
        onButtonClick={() => {
          setCloseGoalInformation(false);
          setCloseGoalConfirmation(true);
        }}
        onClose={() => setCloseGoalInformation(false)}
      />
      <ConfirmationModal
        title={t('goal:goalDetails.confirmationDialog.title')}
        description={t('goal:goalDetails.confirmationDialog.details')}
        buttonText={t('goal:goalDetails.confirmationDialog.continueButton')}
        cancelButton={{
          onClick: () => setCloseGoalConfirmation(false),
          text: t('goal:goalDetails.confirmationDialog.cancelButton'),
        }}
        open={closeGoalConfirmation}
        onButtonClick={() => {
          completeGoalMutation({
            variables: {
              input: {
                goalId,
              },
              skipErrorHandler: true,
            },
          }).then();
        }}
        onClose={() => setCloseGoalConfirmation(false)}
      />
      <ConfirmationModal
        title={t('goal:goalDetails.errorDialog.title')}
        description={closeGoalMsg}
        buttonText={t('goal:goalDetails.errorDialog.continueButton')}
        open={closeGoalError}
        onButtonClick={() => setCloseGoalError(false)}
        onClose={() => setCloseGoalError(false)}
      />
    </Container>
  );
};

export default GoalDetails;
