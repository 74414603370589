import { IconButton, InputAdornment, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';
import OvForm from '../common/wrappers/ovForm';
import SimpleInput from '../common/inputs/simpleInput';
import { colors } from '../../theme/colors';
import { employementOptions, professions } from '../user/resources';

export interface UserState {
  companyType?: string,
  employmentStatus?: string,
  jobTitle?: string,
  studentAreaOfStudy?: string,
}

export interface Props {
  user: UserState,
  onContinue?: () => void,
  goToNamedStep?: (step: string) => void,
}

const PersonalDetails = ({ user, onContinue, goToNamedStep }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'suitabilityReview']);
  const continueToNextStep = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    if (onContinue) {
      onContinue();
    }
  };
  const isInProfessionList = (value: string): boolean => {
    const foundProfessions = professions.filter((item) => item.profession === value);
    return foundProfessions.length > 0;
  };
  const endAdornment = (onClick: () => void): JSX.Element => (
    <InputAdornment position="end">
      <IconButton
        aria-label="clear input field"
        disableRipple
        onClick={() => onClick()}
        edge="end"
      >
        <Typography variant="heading5" color={colors.success} style={{ marginBottom: 'px', textAlign: 'left' }}>
          {t('suitabilityReview:editText')}
        </Typography>
      </IconButton>
    </InputAdornment>
  );
  return (
    <OvForm
      hasButton
      buttonText={t('base:button.continue')}
      onSubmit={continueToNextStep}
    >
      <Box display="flex" flexDirection="column" style={{ marginBottom: '32px' }}>
        <Typography variant="headingMain" textAlign="left" style={{ marginBottom: 0 }}>{t('suitabilityReview:personalDetails.title')}</Typography>
        <Typography variant="paragraphMain" style={{ marginBottom: '25px', textAlign: 'left' }}>
          {t('suitabilityReview:personalDetails.subTitle')}
        </Typography>
        <SimpleInput
          testId="employmentStatusInput"
          label={t('suitabilityReview:personalDetails.employmentStatusLabel')}
          value={employementOptions.find((option) => user.employmentStatus === option.key)?.tag ?? ''}
          suffixAdornment={endAdornment(
            () => {
              if (goToNamedStep) {
                goToNamedStep('selectEmployment');
              }
            },
          )}
        />
        {
          ['EMPLOYED', 'SELF_EMPLOYED', 'RETIRED'].includes(user.employmentStatus ?? '') && (
            <>
              <SimpleInput
                testId="companyTypeInput"
                label={t('suitabilityReview:personalDetails.companyTypeLabel')}
                value={user.companyType ?? ' '}
                suffixAdornment={endAdornment(
                  () => {
                    if (goToNamedStep) {
                      goToNamedStep('employeeInfo');
                    }
                  },
                )}
              />
              <SimpleInput
                testId="jobTitleInput"
                label={t('suitabilityReview:personalDetails.jobTitleLabel')}
                value={user.jobTitle ?? ' '}
                suffixAdornment={endAdornment(
                  () => {
                    if (goToNamedStep) {
                      if (!user.companyType || !isInProfessionList(user.companyType)) {
                        goToNamedStep('employeeInfo');
                      } else {
                        goToNamedStep('employeeJobTitle');
                      }
                    }
                  },
                )}
              />
            </>
          )
        }
        {
          user.employmentStatus === 'STUDENT' && (
            <SimpleInput
              testId="jobTitleInput"
              label={t('suitabilityReview:personalDetails.studentAreaOfStudyLabel')}
              value={user.studentAreaOfStudy ?? ' '}
              suffixAdornment={endAdornment(
                () => {
                  if (goToNamedStep) {
                    goToNamedStep('areaOfStudies');
                  }
                },
              )}
            />
          )
        }
      </Box>
    </OvForm>
  );
};

PersonalDetails.defaultProps = {
  onContinue: undefined,
  goToNamedStep: undefined,
};

export default PersonalDetails;
