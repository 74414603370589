/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Box, List } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import OvForm from '../common/wrappers/ovForm';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import { FeatureFlagTypes, isFeatureEnabled } from '../account/resources';
import { ScheduledIncomeFundTransferProp } from './editPaymentInstructionWizard';
import PaymentInstructionListItem from './paymentInstructionListItem';

interface Props {
  goToNamedStep: (name: string) => void,
  onEdit: (scheduledTransfer: ScheduledIncomeFundTransferProp) => void,
  loading: boolean,
  scheduledTransfers: ScheduledIncomeFundTransferProp,
  onClose: () => void,
}

const YourPaymentInstructions = ({
  goToNamedStep,
  onEdit,
  loading,
  scheduledTransfers,
  onClose,
}: Props): JSX.Element => {
  const { t } = useTranslation(['transfer']);
  const matches = useMediaQuery('(max-width:600px)');

  const initEdit = (transfer: ScheduledIncomeFundTransferProp): void => {
    onEdit(transfer);
    goToNamedStep('edit-payment-instruction-form');
  };

  return (
    <Box width={matches ? 'fit-content' : 415} boxSizing="border-box" height={matches ? '95%' : 520} py="18px">
      <OvForm onSubmit={() => onClose()} hasButton={isFeatureEnabled(FeatureFlagTypes.AUTO_INVESTMENTS)} buttonVariant="secondary-large" buttonText={t('transfer:paymentInstruction.yourPaymentInstructions.button')}>
        <Typography variant="heading2" style={{ marginBottom: 16 }}>{t('transfer:paymentInstruction.yourPaymentInstructions.title')}</Typography>
        <Typography variant="paragraph2" style={{ marginBottom: 16 }}>{t('transfer:paymentInstruction.yourPaymentInstructions.subTitle')}</Typography>
        {
          loading ? <Box position="absolute" top="50%" left={0} right={0}><OvLoadingIndicator /></Box> : undefined
        }
        {
          !loading && scheduledTransfers === undefined ? <Typography key="empty" position="absolute" top="50%" variant="paragraph2" style={{ marginBottom: 16 }}>{t('autoInvestment:yourAutoInvestmentStep.emptyStateMessage')}</Typography> : undefined
        }
        <List sx={{ maxHeight: matches ? '100%' : '300px', overflowY: 'auto' }}>
          <PaymentInstructionListItem
            key={scheduledTransfers?.id}
            sx={{ marginBottom: '24px' }}
            onEdit={(transfer) => initEdit(transfer)}
            scheduledTransfer={scheduledTransfers}
          />
        </List>
      </OvForm>
    </Box>
  );
};

export default YourPaymentInstructions;
