import {
  Typography,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import OvForm from '../../../common/wrappers/ovForm';
import SearchInput from '../../../common/inputs/searchInput';
import { FETCH_INSTITUTIONS } from '../../graphql';
import GeneralListItem from '../../../common/lists/generalListItem';
import { colors } from '../../../../theme/colors';
import OvLoadingIndicator from '../../../common/loaders/ovLoadingIndicator';
import OvPagination from '../../../common/wrappers/ovPagination';
import { getWindowInnerHeight } from '../../../../utils/platformUtils';

interface Institution {
  id: string,
  name: string,
  physicalAddress?: undefined,
}

interface Props {
  onInstitutionSelected: (institution: Institution) => void,
  goToNamedStep?: (step: string) => void,
}

const style = {
  container: {
    height: '555px',
    '@media (max-width:600px)': {
      width: 'initial',
      height: 'calc(100% - 56px)',
    },
  },
  list: {
    backgroundColor: colors.neutral80,
    padding: '10px 10px',
    maxHeight: '250px',
    '@media (max-width:600px)': {
      maxHeight: 'calc(100vh * 0.54) !important',
    },
    overflowY: 'auto',
    marginBottom: '16px',
  },
  viewFinancialInstitutionsList: {
    backgroundColor: colors.neutral80,
    padding: '0px 10px',
    height: '300px',
    overflowY: 'auto',
    marginBottom: '16px',
    '@media (max-width:600px)': {
      height: `calc(${getWindowInnerHeight()}px - 300px)`,
    },
  },
};

const ViewInstitution = ({ onInstitutionSelected, goToNamedStep }: Props): JSX.Element => {
  const { t } = useTranslation(['transfer']);
  const [loading, setLoading] = useState(false);
  const [fetchingNext, isFetchingNext] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [financialInstitutions, setFinancialInstitutions] = useState<Institution[]>([]);
  const [selectedOption, setSelectedOption] = useState<Institution | undefined>();
  const [totalPage, setTotalPage] = useState<number>(0);

  const setFetchInstitutionData = (data: { fetchInstitutions: { institutions: { id: string, name: string, }[], totalCount: number }}): void => {
    setFinancialInstitutions((prevState) => [...prevState, ...data.fetchInstitutions.institutions]);
    setTotalPage(data.fetchInstitutions.totalCount);
    setLoading(false);
    isFetchingNext(false);
  };

  const [fetchInstitutions] = useLazyQuery(FETCH_INSTITUTIONS, {
    variables: {
      input: {
        filter: {
          searchText: '',
        },
        pagination: {
          offSet: 0,
          perPage: 20,
          sortField: 'name',
          sortDesc: true,
        },
      },
    },
  });

  const onContinueClick = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (selectedOption) {
      onInstitutionSelected(selectedOption);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchInstitutions().then((response) => setFetchInstitutionData(response.data));
  }, [fetchInstitutions]);

  const addInstitutionManually = (): void => {
    if (goToNamedStep) {
      goToNamedStep('add-institution');
    }
  };

  const fetchNextPage = (): void => {
    if (financialInstitutions.length >= totalPage || fetchingNext) return;
    isFetchingNext(true);
    fetchInstitutions({
      variables: {
        input: {
          filter: {
            searchText: '',
          },
          pagination: {
            offSet: financialInstitutions.length,
            perPage: 20,
            sortField: 'name',
            sortDesc: true,
          },
        },
      },
    }).then((response) => setFetchInstitutionData(response.data));
  };

  const fetchSearchString = async (searchKey: string): Promise<void> => {
    setLoading(true);
    const response = await fetchInstitutions({
      variables: {
        input: {
          filter: {
            searchText: searchKey,
          },
          pagination: {
            offSet: 0,
            perPage: 20,
            sortField: 'name',
            sortDesc: true,
          },
        },
      },
    });
    setLoading(false);
    setFinancialInstitutions([...response.data.fetchInstitutions.institutions]);
  };

  return (
    <Box sx={style.container}>
      <OvForm onSubmit={onContinueClick} submitButton={false}>
        <Typography variant="heading2">{t('transfer:viewInstitutions.header')}</Typography>
        <Typography variant="paragraph3">{t('transfer:viewInstitutions.title')}</Typography>
        <SearchInput
          label={t('transfer:viewInstitutions.inputLabel')}
          value={searchString}
          onChange={(event) => {
            setSearchString(event.target.value);
            fetchSearchString(event.target.value).then();
          }}
          onClear={() => {
            setSearchString('');
            fetchSearchString('').then();
          }}
        />
        {
          loading ? <OvLoadingIndicator /> : (
            <OvPagination sx={style.viewFinancialInstitutionsList} loading={fetchingNext} nextPage={() => fetchNextPage()}>
              {financialInstitutions.map((value) => (
                <GeneralListItem
                  text={value.name}
                  key={value.id}
                  hasRadioField
                  checked={value && value.id === selectedOption?.id}
                  onClick={() => {
                    if (value) {
                      setSelectedOption(value);
                    }
                  }}
                />
              ))}
              <Box height="10px" />
              <Typography variant="bodyMain1" color={colors.link} onClick={addInstitutionManually} paddingY="10px">
                {t('transfer:externalAccountDeposit.addInstitutionText')}
              </Typography>
            </OvPagination>
          )
        }
      </OvForm>
    </Box>
  );
};

ViewInstitution.defaultProps = {
  goToNamedStep: undefined,
};

export default ViewInstitution;
