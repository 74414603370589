import { colors } from '../../../theme/colors';

export const styles = {
  helpText: {
    cursor: 'pointer',
    position: 'relative' as const,
    top: '-5px',
    color: colors.ovBlack,
  },
  clickPointer: {
    cursor: 'pointer',
    position: 'relative' as const,
    top: '-2px',
    color: colors.black,
  },
  inputBox: {
    marginBottom: '30px',
    marginTop: '33px',
    display: 'flex',
    justifyContent: 'space-between',
    background: colors.transparent,
  },
  closeBox: {
    textAlign: 'center',
    width: '26px',
    height: '26px',
    top: '-6px',
    marginTop: '18px',
    marginLeft: '24px',
    marginRight: '24px',
    marginBottom: '22px',
    cursor: 'pointer',
    background: colors.backButtonBackground,
    borderRadius: '3.42105px',
  },
  titleStyle: {
    marginBottom: 33,
    color: colors.secondary,
  },
  subTitleStyle: {
    marginBottom: 33,
    color: colors.helpSubTitle,
  },
  iconStyle: {
    color: colors.secondary,
    marginRight: '12px',
  },
};
