import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './trusted-security-policies.js';
import './assets/i18n/config';
import { initFirebase } from './utils/firebase';
import { initSentry } from './utils/sentry';
import App from './App';
import { loadCanadaFlag, loadGetToKnowYouImages } from './utils/imageFactory';

loadGetToKnowYouImages();
loadCanadaFlag();
initFirebase();
initSentry();
ReactDOM.render(
  <React.StrictMode>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Source+Serif+Pro:wght@200;300;400;600;700;900&display=swap" rel="stylesheet" />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

export { };
