/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, Button } from '@mui/material';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import uniq from 'lodash/uniq';
import { colors } from '../../theme/colors';
import { formatMoneyValue } from '../../utils/commonMethods';
import DiamondShineIcon from '../../assets/images/diamond-shine.svg';
import { FETCH_SCHEDULED_DEPOSITS } from './graphql';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import CreateAutoInvestmentWizard from '../auto-investments/createAutoInvestmentWizard';
import FlowModal from '../common/wrappers/modals/ovFlowModal';
import { FeatureFlagTypes, isFeatureEnabled } from '../account/resources';
import { getBackendLanguage } from '../../assets/i18n/config';
import { HouseholdViewTypes } from '../../utils/commonGraphql';

interface Props {
  goalId?: string,
  subAccountId?: string,
  accountId?: string,
  onEnableAutoInvestment?: () => void,
  viewAutoInvestment?: () => void,
  depositsBlocked?: boolean,
  householdViewType?: HouseholdViewTypes,
}

const styles = {
  outerBox: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'start',
    justifyContent: 'center',
    padding: '15px 16px 20px 16px',
    backgroundColor: colors.autoInvestmentSummaryCard,
    borderRadius: '6px',
    cursor: 'pointer',
  },
  targetMessage: {
    textAlign: 'left',
    marginTop: '12px',
    borderBottom: '1px dashed #DDD9D9',
    paddingBottom: '12px',
  },
  headerText: {
    color: colors.autoInvestmentSummaryHeader,
    margin: 0,
  },
  bodyText: {
    color: colors.autoInvestmentSummaryBody,
    margin: 0,
  },
  autoInvestTitle: {
    marginBottom: '6px',
    color: colors.neutral20,
    textAlign: 'left',
  },
  enableAutoInvestButton: {
    backgroundColor: colors.baseComponentBackground,
    height: '60px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    borderRadius: '8px',
    border: `1px solid ${colors.portfolioCardBorder}`,
    chevronIcon: {
      color: colors.ovBlack,
    },
    box: {
      image: {
        marginRight: '18px',
      },
      display: 'flex',
      flexDirection: 'row' as const,
      alignItems: 'center',
    },
  },
};

const AutoInvestmentSummary = ({
  goalId,
  subAccountId,
  accountId,
  onEnableAutoInvestment,
  viewAutoInvestment,
  depositsBlocked,
  householdViewType,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user', 'transfer']);
  const [init] = useState(true);
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const [nextAutoInvestment, setNextAutoInvestment] = useState<{ amountCents: number, id: string, scheduledDate: string }>();
  const [portfolioCount, setPortfolioCount] = useState(2);
  const [showAutoInvestModal, setShowAutoInvestModal] = useState(false);
  const [addAutoInvestment, setAddAutoInvestment] = useState(false);
  const onComplete = (data: {
    fetchScheduledTransfers: {
      scheduledTransfers: { amountCents: number, id: string, scheduledDate: string, subAccount: { id: string } }[],
      totalCount: number,
    }
  }): void => {
    if (data.fetchScheduledTransfers.scheduledTransfers.length > 0) {
      const uniqueSubAccounts = uniq(data.fetchScheduledTransfers.scheduledTransfers.map((s) => s.subAccount.id));
      setPortfolioCount(uniqueSubAccounts.length);
      setNextAutoInvestment(data.fetchScheduledTransfers.scheduledTransfers[0]);
      setStep(2);
    } else {
      setStep(1);
    }
  };
  const [fetchScheduledDeposits] = useLazyQuery(FETCH_SCHEDULED_DEPOSITS, {
    variables: {
      input: {
        filter: {
          goalId,
          subAccountId,
          accountId,
          householdViewType,
        },
        pagination: {
          sortField: 'scheduledDate',
        },
      },
    },
    onCompleted: (data) => onComplete(data),
    onError: () => setStep(1),
    nextFetchPolicy: 'standby',
  });

  const convertToString = (autoInvestDate: string): string => {
    dayjs.extend(advancedFormat);
    return dayjs(autoInvestDate).locale(getBackendLanguage()).local().format('Do of MMMM');
  };

  const autoInvestCard = (): JSX.Element => (
    <>
      <Typography variant="heading5" sx={styles.autoInvestTitle}>{t('transfer:autoInvestment')}</Typography>
      <Box
        style={styles.outerBox}
        onClick={() => {
          if (viewAutoInvestment) {
            viewAutoInvestment();
          }
          setShowAutoInvestModal(true);
          setAddAutoInvestment(false);
        }}
      >
        <Typography variant="heading5" style={styles.headerText}>
          {t('user:dashboard.nextScheduledDepositCard.part1')}
          {` ${portfolioCount} `}
          {` ${portfolioCount > 1 ? t('user:dashboard.nextScheduledDepositCard.portfolios') : t('user:dashboard.nextScheduledDepositCard.portfolio')} `}
        </Typography>
        <Box mb={1} />
        <Typography variant="paragraph3" style={styles.bodyText}>
          {t('user:dashboard.nextScheduledDepositCard.part2')}
          <b>
            {` ${formatMoneyValue(nextAutoInvestment?.amountCents ?? 0, undefined, 0)} `}
          </b>
          {t('user:dashboard.nextScheduledDepositCard.part3')}
          {` ${convertToString(nextAutoInvestment?.scheduledDate ?? '')} `}
        </Typography>
      </Box>
    </>
  );

  const enableAutoInvestment = (): JSX.Element => (

    isFeatureEnabled(FeatureFlagTypes.AUTO_INVESTMENTS) ? (
      <>
        <Typography variant="heading5" sx={styles.autoInvestTitle}>{t('transfer:autoInvestment')}</Typography>
        <Button
          style={styles.enableAutoInvestButton}
          onClick={() => {
            if (onEnableAutoInvestment) {
              onEnableAutoInvestment();
            }
            setShowAutoInvestModal(true);
            setAddAutoInvestment(true);
          }}
          disabled={depositsBlocked}
        >
          <Box style={styles.enableAutoInvestButton.box}>
            <img src={DiamondShineIcon} alt="diamond-shine" style={styles.enableAutoInvestButton.box.image} />
            <Typography variant="heading5" style={styles.headerText}>
              {t('user:dashboard.enableScheduledDepositButton.text')}
            </Typography>
          </Box>
          <ChevronRightRoundedIcon style={styles.enableAutoInvestButton.chevronIcon} />
        </Button>
      </>
    ) : <Box />
  );

  const displayUI = (): JSX.Element => {
    if (step === 0) {
      return (
        <OvLoadingIndicator />
      );
    }
    if (step === 1) { return enableAutoInvestment(); }
    return autoInvestCard();
  };

  useEffect(() => {
    fetchScheduledDeposits().then();
  }, [init]);

  if (showAutoInvestModal) {
    return (
      <FlowModal
        open={showAutoInvestModal}
        onClose={() => setShowAutoInvestModal(false)}
        showCloseButton={false}
        maintainModal
        component={(
          <CreateAutoInvestmentWizard
            goalId={goalId}
            subAccountId={subAccountId}
            accountId={accountId}
            addAutoInvestment={addAutoInvestment}
            onClose={() => setShowAutoInvestModal(false)}
          />
        )}
      />
    );
  }
  return (
    displayUI()
  );
};

AutoInvestmentSummary.defaultProps = {
  goalId: undefined,
  subAccountId: undefined,
  accountId: undefined,
  onEnableAutoInvestment: undefined,
  viewAutoInvestment: undefined,
  depositsBlocked: false,
  householdViewType: undefined,
};

export default AutoInvestmentSummary;
