import * as React from 'react';
import BaseInfoCard, { ITEM } from './baseInfoCard';

interface Props {
  timeWeightedPercentage: number,
  moneyWeightedPercentage: number,
  infoIconClicked?: (item: ITEM) => void,
}

const RateOfReturnCard = ({ timeWeightedPercentage, moneyWeightedPercentage, infoIconClicked } : Props): JSX.Element => (
  <BaseInfoCard
    options={[
      { item: ITEM.timeWeighted, value: timeWeightedPercentage, isPercent: true },
      { item: ITEM.moneyWeighted, value: moneyWeightedPercentage, isPercent: true },
    ]}
    infoIconClicked={infoIconClicked}
  />
);

RateOfReturnCard.defaultProps = {
  infoIconClicked: undefined,
};

export default RateOfReturnCard;
