import * as React from 'react';
import { Box, styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme/colors';

const Root = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 28px;
  height: 16px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${colors.error};
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 1px;
    left: 1px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${colors.neutral30};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 13px;
      top: 1px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: ${colors.toggleSwitchBGColor};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

interface Props {
  labelTextTrue?: string,
  labelTextFalse?: string,
  checked?: boolean,
  onChange?: (checked: boolean) => void,
  invertTextColor?: boolean,
  hasLabel?: boolean,
}

const Toggle = ({ invertTextColor, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base']);
  const lableTextTrue = props.labelTextTrue ?? t('base:toggle.true');
  const labelTextFalse = props.labelTextFalse ?? t('base:toggle.false');

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <SwitchUnstyled
        component={Root}
        checked={props.checked || false}
        onChange={() => {
          if (props.onChange) props.onChange(!props.checked);
        }}
      />
      { props.hasLabel ? <Typography variant="captionDescriptorBold" color={(invertTextColor && props.checked) ? colors.toggleSwitchBGColor : colors.error}>{ props.checked ? lableTextTrue : labelTextFalse }</Typography> : null }
    </Box>
  );
};

Toggle.defaultProps = {
  labelTextTrue: undefined,
  labelTextFalse: undefined,
  checked: false,
  onChange: undefined,
  hasLabel: false,
  invertTextColor: false,
};

export default Toggle;
