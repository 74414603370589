import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import WithStepWizard, { StepProps } from '../common/wrappers/withStepWizard';
import TrackingBar from '../common/headers/trackingBar';
import {
  AccountTypes, AffiliationRelations, AffiliationTypes, ApplyForGovFunds,
} from './resources';
import { SubAccount } from '../../utils/commonGraphql';
import {
  DateOfBirth, Name, Sin, UpdatePhysicalAddressWizard,
} from '../user';
import { PhysicalAddress } from '../user/address/resources';
import Gender from '../user/gender';
import BeneficiaryRelationship from './beneficiaryRelationship';
import BeneficiaryGuardian from './beneficiaryGuardian';
import BeneficiaryCaregiver from './beneficiaryCaregiver';
import Grants from './grants';
import { userGenderVar } from '../../utils/localVariables';
import BeneficiaryReview from './beneficiaryReview';
import { Email } from '../signUp';
import UpdateEmploymentStatusWizard from '../user/employment/updateEmployementStatusWizard';
import { ovAnalyticsEvents } from '../../utils/firebase';
import ConfirmSpousalAffiliateDetails from './ConfirmSpousalAffiliateDetails';
import InProvinceSince from '../user/inProvinceSince';
import { BASIC_ADDRESS_INFO } from '../profile/graphql';
import BeneficiaryAffiliationType from './beneficiaryAffiliationType';
import BeneficiaryAllocation from './beneficiaryAllocation';

const BeneficiaryNameStep = WithStepWizard(Name);
const BeneficiaryAddressWizardStep = WithStepWizard(UpdatePhysicalAddressWizard);
const SpouseDateOfBirthStep = WithStepWizard(DateOfBirth);
const BeneficiaryInProvinceSince = WithStepWizard(InProvinceSince);
const SpouseSinStep = WithStepWizard(Sin);
const GenderStep = WithStepWizard(Gender);
const BeneficiaryAffiliationTypeStep = WithStepWizard(BeneficiaryAffiliationType);
const BeneficiaryAllocationStep = WithStepWizard(BeneficiaryAllocation);
const BeneficiaryRelationshipStep = WithStepWizard(BeneficiaryRelationship);
const BeneficiaryReviewStep = WithStepWizard(BeneficiaryReview);
const BeneficiaryGuardianStep = WithStepWizard(BeneficiaryGuardian);
const BeneficiaryCaregiverStep = WithStepWizard(BeneficiaryCaregiver);
const GrantsStep = WithStepWizard(Grants);
const SpousalNameStep = WithStepWizard(Name);
const SpousalEmailStep = WithStepWizard(Email);
const SpousePhysicalAddressWizardStep = WithStepWizard(UpdatePhysicalAddressWizard);
const SpouseEmploymentStatusWizardStep = WithStepWizard(UpdateEmploymentStatusWizard);
const ConfirmSpousalAffiliateDetailsStep = WithStepWizard(ConfirmSpousalAffiliateDetails);

export interface AffiliateUser {
  id?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  primaryEmail?: string,
  physicalAddress?: Partial<PhysicalAddress>,
  sin?: string,
  gender?: string,
  dateOfBirth?: Date,
  affiliation?: Affiliation,
  affiliateOnly?: boolean,
  employmentStatus?: string,
  studentAreaOfStudy?: string,
  jobTitle?: string,
  inProvinceSince?: Date,
}

export interface User {
  id?: string,
  inProvinceSince?: Date,
  dateOfBirth?: Date,
  physicalAddress?: Partial<PhysicalAddress>,
}

export interface Affiliation {
  accountId?: string,
  userId?: string,
  type?: AffiliationTypes,
  relation?: AffiliationRelations,
  signatureRequired?: boolean,
  allocation?: number,
}

interface Props {
  stepTitle?: string,
  showStepper?: boolean,
  stepProps?: StepProps,
  affiliationList: Array<Affiliation>,
  affiliateUserList: Array<AffiliateUser>,
  onContinue?: () => void,
  goBack?: () => void,
  goBackToNamedStep?: () => string,
  setAffiliationList: (att: Array<Affiliation>) => void,
  setApplyForGovFunds: (att: Array<ApplyForGovFunds>) => void,
  setAffiliateUserList: (att: Array<AffiliateUser>) => void,
  currentSubAccount?: SubAccount | {
    account?: {
      type?: AccountTypes;
    },
    theme?: {
      id?: string;
    },
  },
}

const AccountTypeAuxiliaryWizard = ({
  stepTitle, stepProps, showStepper, onContinue, goBack, currentSubAccount, ...props
}: Props): JSX.Element => {
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });

  const { t } = useTranslation(['account']);
  const firstStep = props.affiliationList.length > 0 ? 10 : 1;

  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const [beneficiaryIndex, updateBeneficiaryIndex] = useState<number>(0);
  const [shouldGoToReview, setShouldGoToReview] = useState<boolean>(false);
  const [showBeneficiaryError, setShowBeneficiaryError] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [user, setUser] = useState<User>({});
  const [inProvinceSince, setInProvinceSince] = useState();
  const [affiliateUser, updateAffiliateUser] = useState<AffiliateUser>(props.affiliateUserList[0] ?? {});
  const [guardianAffiliation, setGuardianAffiliation] = useState<Affiliation>(props.affiliationList[0] ?? {});
  const [caregiverAffiliation, setCaregiverAffiliation] = useState<Affiliation>(props.affiliationList[1] ?? {});
  const [initialStep, setInitialStep] = useState<number>(firstStep);

  const nextStep = (): string => 'BeneficiaryReview';
  const isTFSAOrRRSP: boolean = (currentSubAccount?.account?.type && [
    AccountTypes.RRSP, AccountTypes.TFSA,
  ].includes(currentSubAccount.account.type)) ?? false;
  const singleOrFamily: (AccountTypes | undefined)[] = [AccountTypes.RESP_SINGLE, AccountTypes.RESP_FAMILY];
  const isRespAdult: boolean = currentSubAccount?.account?.type === AccountTypes.RESP_ADULT;
  const isRespFamily: boolean = currentSubAccount?.account?.type === AccountTypes.RESP_FAMILY;
  const isRespFamilyJoint: boolean = currentSubAccount?.account?.type === AccountTypes.RESP_FAMILY_JOINT;
  const isRespFamilyOrFamilyJoint = isRespFamily || isRespFamilyJoint;
  const isSingleOrFamily: boolean = singleOrFamily.includes(currentSubAccount?.account?.type);
  const isRRIF: boolean = currentSubAccount?.account?.type === AccountTypes.RRIF;
  const isRIFSpousal: boolean = currentSubAccount?.account?.type === AccountTypes.RIF_SPOUSAL;
  const isTFSA: boolean = currentSubAccount?.account?.type === AccountTypes.TFSA;
  const minAge: number | undefined = isSingleOrFamily ? 0 : undefined;
  const maxAge: number | undefined = isSingleOrFamily ? 21 : undefined;
  const beneficiaryName: string = affiliateUser.firstName ?? t('beneficiary.beneficiary');

  const exitWizard = (): void => {
    const stepIndex = isTFSAOrRRSP ? 10 : 8;
    if (shouldGoToReview) {
      setInitialStep(stepIndex);
    } else {
      props.setAffiliateUserList([]);
      props.setAffiliationList([]);
      if (goBack) goBack();
    }
  };

  const addAffiliation = async (applyForGovFunds: Array<ApplyForGovFunds>): Promise<void> => {
    props.setApplyForGovFunds(applyForGovFunds);
    const affiliationlist: Array<Affiliation> = [guardianAffiliation, caregiverAffiliation];
    const affiliatesList = affiliationlist.filter((x) => x.userId !== undefined);
    props.setAffiliationList(affiliatesList);
  };

  const goToNextStep = () => {
    if (onContinue) onContinue();
  };

  const proceedToNextWizard = async (): Promise<void> => {
    if (onContinue) onContinue();
  };

  const updateFamilyRESPBeneficiaryList = async (affUser: AffiliateUser): Promise<boolean> => {
    updateAffiliateUser(affUser);
    const beneficiaries: AffiliateUser[] = props.affiliateUserList;
    let relationships = beneficiaries
      .filter((elem) => elem.affiliation?.type === AffiliationTypes.PRIMARY_BENEFICIARY && elem.affiliation.relation !== undefined)
      .map((affiliate) => affiliate.affiliation?.relation);

    if (beneficiaries[beneficiaryIndex]) {
      if (beneficiaries.length > 1 && isRespFamilyOrFamilyJoint && affUser.affiliation?.type === AffiliationTypes.PRIMARY_BENEFICIARY) {
        relationships = relationships.filter((elem, index) => index !== beneficiaryIndex);
        if (relationships.length > 0 && !relationships.includes(affUser.affiliation?.relation)) {
          setShowBeneficiaryError(true);
          return false;
        }
      }
      beneficiaries[beneficiaryIndex] = affUser;
      props.setAffiliateUserList(beneficiaries);
    } else {
      if (beneficiaries.length > 0 && isRespFamilyOrFamilyJoint && affUser.affiliation?.type === AffiliationTypes.PRIMARY_BENEFICIARY) {
        if (relationships.length > 0 && !relationships.includes(affUser.affiliation?.relation)) {
          setShowBeneficiaryError(true);
          return false;
        }
      }
      props.setAffiliateUserList([...props.affiliateUserList, affUser]);
    }
    setShowBeneficiaryError(false);
    return true;
  };

  const updateBeneficiaryList = async (affUser: AffiliateUser): Promise<void> => {
    updateAffiliateUser(affUser);
    if (props.affiliateUserList[beneficiaryIndex]) {
      const beneficiaries: AffiliateUser[] = props.affiliateUserList;
      beneficiaries[beneficiaryIndex] = affUser;
      props.setAffiliateUserList(beneficiaries);
    } else {
      props.setAffiliateUserList([...props.affiliateUserList, affUser]);
    }
  };

  const editField = async (index: number): Promise<void> => {
    setIsEditMode(true);
    setShouldGoToReview(true);
    updateBeneficiaryIndex(index);
    const beneficiaries: AffiliateUser[] = props.affiliateUserList;
    updateAffiliateUser(beneficiaries[index]);
  };

  const addNewBeneficiary = async (): Promise<void> => {
    updateAffiliateUser({});
    setShouldGoToReview(true);
    updateBeneficiaryIndex(beneficiaryIndex + 1);
    setInitialStep(1);
  };

  const addNewBeneficiaryonDone = async (): Promise<void> => {
    if (isEditMode) {
      await updateBeneficiaryList(affiliateUser);
      setIsEditMode(false);
    }
    updateAffiliateUser(props.affiliateUserList[props.affiliateUserList.length - 1]);
    setShouldGoToReview(false);
    updateBeneficiaryIndex(props.affiliateUserList.length - 1);
  };

  const removeBeneficiary = async (beneficiary: AffiliateUser): Promise<void> => {
    const beneficiaries: AffiliateUser[] = props.affiliateUserList.filter((x) => x !== beneficiary);
    props.setAffiliateUserList(beneficiaries);
    updateAffiliateUser(beneficiaries[beneficiaries.length - 1]);
    setShouldGoToReview(false);
    updateBeneficiaryIndex(beneficiaries.length - 1);
  };

  const rrifStepsReviewed = (isRifSpousal: boolean): void => {
    const userWithAffiliation = affiliateUser;
    userWithAffiliation.affiliation = {
      type: isRifSpousal ? AffiliationTypes.CONTRIBUTOR : AffiliationTypes.OTHER,
      relation: AffiliationRelations.SPOUSE,
      signatureRequired: false,
    };
    props.setAffiliateUserList([userWithAffiliation]);
  };

  useQuery(BASIC_ADDRESS_INFO, {
    onCompleted: (data) => {
      setUser(data.me.user);
      setInProvinceSince(data.me.user.inProvinceSince);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const getRespAdultSteps = (): JSX.Element[] => ([
    ...(!userGenderVar() ? [<GenderStep stepName="Gender" key="Gender" shouldSaveUpdate={isRespAdult} user={affiliateUser} updateUserState={updateAffiliateUser} beneficiaryName={isRespAdult ? t('beneficiary.beneficiary') : beneficiaryName} stepTitle={stepTitle} goBackAction={() => exitWizard()} />] : []),
    ...(!inProvinceSince && user.physicalAddress?.province === 'QC' ? [<BeneficiaryInProvinceSince analytictsEvent={ovAnalyticsEvents.addBeneficiaryInProvinceSinceSelect} stepName="UserInProvinceSince" key="UserInProvinceSince" user={user} shouldSaveUpdate updateUserState={setUser} stepTitle={stepTitle} goBackAction={!userGenderVar() ? () => exitWizard() : undefined} />] : []),
    <GrantsStep stepName="Grants" key="Grants" stepTitle={stepTitle} isRespAdult={isRespAdult} createAccountAction={addAffiliation} proceedToNextStep={goToNextStep} goBackAction={() => exitWizard()} />,
  ]);

  const continueFromDOBStep = affiliateUser.physicalAddress?.province === 'QC' ? 'BeneficiaryInProvinceSince' : 'Sin';
  const goBackFromSIN = affiliateUser.physicalAddress?.province === 'QC' ? 'BeneficiaryInProvinceSince' : 'DateOfBirth';
  const continueFromBeneficiaryReviewStep = !user.inProvinceSince && user.physicalAddress?.province === 'QC' ? 'UserInProvinceSince' : 'BeneficiaryGuardian';
  const continueFromRelationshipStep = isRespFamilyOrFamilyJoint ? 'BeneficiaryReview' : continueFromBeneficiaryReviewStep;
  const goBackFromGuardianStep1 = isRespFamilyOrFamilyJoint ? 'BeneficiaryReview' : 'BeneficiaryRelationsip';
  const goBackFromGuardianStep = !user.inProvinceSince && user.physicalAddress?.province === 'QC' ? 'UserInProvinceSince' : goBackFromGuardianStep1;
  const goBackFromUserInProvinceSince = isRespFamilyOrFamilyJoint ? 'BeneficiaryReview' : 'BeneficiaryRelationsip';

  const getRespSingleOrFamilySteps = (): JSX.Element[] => ([
    <BeneficiaryNameStep stepTitle={stepTitle} stepName="BeneficiaryName" continueToNamedStep={isEditMode ? nextStep : undefined} key="BeneficiaryName" user={affiliateUser} goToNamedStep={exitWizard} updateUserState={updateAffiliateUser} title={t('beneficiary.name')} subtitle="" goBackAction={() => exitWizard()} />,
    <BeneficiaryAddressWizardStep analytictsEvent={ovAnalyticsEvents.addBeneficiaryAddressContinueButtonSelect} stepName="physicalAddress" key="physicalAddress" continueToNamedStep={isEditMode ? nextStep : undefined} user={affiliateUser} updateUserState={updateAffiliateUser} stepTitle={stepTitle} disableTracker shouldSaveUpdate={false} title={t('beneficiary.address', { firstName: beneficiaryName })} subtitle={t('beneficiary.addressSubtitle')} />,
    <SpouseDateOfBirthStep analytictsEvent={ovAnalyticsEvents.addBeneficiaryDateOfBirthContinueButtonSelect} stepName="DateOfBirth" key="DateOfBirth" continueToNamedStep={isEditMode ? nextStep : () => continueFromDOBStep} user={affiliateUser} shouldSaveUpdate={false} updateUserState={updateAffiliateUser} stepTitle={stepTitle} title={t('beneficiary.dateOfBirth.title', { firstName: beneficiaryName })} subtitle={maxAge ? t('beneficiary.dateOfBirth.subtitle') : undefined} minAge={minAge} maxAge={maxAge} />,
    <BeneficiaryInProvinceSince analytictsEvent={ovAnalyticsEvents.addBeneficiaryInProvinceSinceSelect} stepName="BeneficiaryInProvinceSince" key="BeneficiaryInProvinceSince" continueToNamedStep={isEditMode ? nextStep : undefined} user={affiliateUser} shouldSaveUpdate={false} updateUserState={updateAffiliateUser} stepTitle={stepTitle} title={t('beneficiary.inProvinceSince.title', { firstName: beneficiaryName })} subtitle={t('beneficiary.inProvinceSince.subtitle')} />,
    <SpouseSinStep analytictsEvent={ovAnalyticsEvents.addBeneficiarySINNumberContinueButtonSelect} stepName="Sin" key="Sin" user={affiliateUser} shouldSaveUpdate={false} goBackToNamedStep={() => goBackFromSIN} updateUserState={updateAffiliateUser} stepTitle={stepTitle} title={t('beneficiary.SIN', { firstName: beneficiaryName })} cardTitle={t('beneficiary.SINDisclaimer.title')} cardSubtitle1={t('beneficiary.SINDisclaimer.subtitle1')} cardSubtitle2={t('beneficiary.SINDisclaimer.subtitle2')} cardDescription={t('beneficiary.SINDisclaimer.subtitle3')} noSinText={t('beneficiary.noSin')} />,
    <GenderStep stepName="Gender" key="Gender" shouldSaveUpdate={isRespAdult} continueToNamedStep={isEditMode ? nextStep : undefined} user={affiliateUser} updateUserState={updateAffiliateUser} beneficiaryName={isRespAdult ? t('beneficiary.beneficiary') : beneficiaryName} stepTitle={stepTitle} />,
    <BeneficiaryRelationshipStep stepName="BeneficiaryRelationsip" continueToNamedStep={() => continueFromRelationshipStep} key="BeneficiaryRelationsip" user={affiliateUser} accountType={currentSubAccount?.account?.type} beneficiaryName={beneficiaryName} updateBeneficiaryList={updateFamilyRESPBeneficiaryList} stepTitle={stepTitle} showBeneficiaryError={showBeneficiaryError} />,
    <BeneficiaryReviewStep stepName="BeneficiaryReview" key="BeneficiaryReview" continueToNamedStep={() => continueFromBeneficiaryReviewStep} editField={editField} allowMultipleAffiliate={isRespFamilyOrFamilyJoint} affiliateUserList={props.affiliateUserList} addNewBeneficiary={addNewBeneficiary} addNewBeneficiaryonDone={addNewBeneficiaryonDone} removeBeneficiary={removeBeneficiary} stepTitle={stepTitle} />,
    <BeneficiaryInProvinceSince analytictsEvent={ovAnalyticsEvents.addBeneficiaryInProvinceSinceSelect} goBackToNamedStep={() => goBackFromUserInProvinceSince} stepName="UserInProvinceSince" key="UserInProvinceSince" continueToNamedStep={isEditMode ? nextStep : undefined} user={user} shouldSaveUpdate updateUserState={setUser} stepTitle={stepTitle} />,
    <BeneficiaryGuardianStep stepName="BeneficiaryGuardian" key="BeneficiaryGuardian" goBackToNamedStep={() => goBackFromGuardianStep} stepTitle={stepTitle} isRespFamily={isRespFamilyOrFamilyJoint} setGuardianAffiliation={setGuardianAffiliation} />,
    <BeneficiaryCaregiverStep stepName="BeneficiaryCaregiver" key="BeneficiaryCaregiver" stepTitle={stepTitle} isRespFamily={isRespFamilyOrFamilyJoint} setCaregiverAffiliation={setCaregiverAffiliation} />,
    <GrantsStep stepName="Grants" key="Grants" stepTitle={stepTitle} isRespAdult={isRespAdult} createAccountAction={addAffiliation} proceedToNextStep={goToNextStep} />,
  ]);

  const nextStepFromGender = isTFSA ? 'AffiliationType' : 'Allocation';
  const prevStepFromAllocation = isTFSA ? 'AffiliationType' : 'Gender';
  const prevStepFromRelationship = affiliateUser.affiliation?.type === AffiliationTypes.SUCCESSOR ? 'AffiliationType' : 'Allocation';
  const disableSuccessorOption = isTFSA && props.affiliateUserList.map((x) => x.affiliation?.type).includes(AffiliationTypes.SUCCESSOR);

  const getTFSAOrRRSPSteps = (): JSX.Element[] => ([
    <BeneficiaryNameStep stepTitle={stepTitle} stepName="BeneficiaryName" continueToNamedStep={isEditMode ? nextStep : undefined} key="BeneficiaryName" user={affiliateUser} goToNamedStep={exitWizard} updateUserState={updateAffiliateUser} title={t('beneficiary.name')} subtitle="" goBackAction={() => exitWizard()} />,
    <BeneficiaryAddressWizardStep analytictsEvent={ovAnalyticsEvents.addBeneficiaryAddressContinueButtonSelect} stepName="physicalAddress" key="physicalAddress" continueToNamedStep={isEditMode ? nextStep : undefined} user={affiliateUser} updateUserState={updateAffiliateUser} stepTitle={stepTitle} disableTracker shouldSaveUpdate={false} title={t('beneficiary.address', { firstName: beneficiaryName })} subtitle={t('beneficiary.addressSubtitle')} />,
    <SpouseDateOfBirthStep analytictsEvent={ovAnalyticsEvents.addBeneficiaryDateOfBirthContinueButtonSelect} stepName="DateOfBirth" key="DateOfBirth" continueToNamedStep={isEditMode ? nextStep : () => continueFromDOBStep} user={affiliateUser} shouldSaveUpdate={false} updateUserState={updateAffiliateUser} stepTitle={stepTitle} title={t('beneficiary.dateOfBirth.title', { firstName: beneficiaryName })} subtitle={maxAge ? t('beneficiary.dateOfBirth.subtitle') : undefined} minAge={0} />,
    <BeneficiaryInProvinceSince analytictsEvent={ovAnalyticsEvents.addBeneficiaryInProvinceSinceSelect} stepName="BeneficiaryInProvinceSince" key="BeneficiaryInProvinceSince" continueToNamedStep={isEditMode ? nextStep : undefined} user={affiliateUser} shouldSaveUpdate={false} updateUserState={updateAffiliateUser} stepTitle={stepTitle} title={t('beneficiary.inProvinceSince.title', { firstName: beneficiaryName })} subtitle={t('beneficiary.inProvinceSince.subtitle')} />,
    <SpouseSinStep analytictsEvent={ovAnalyticsEvents.addBeneficiarySINNumberContinueButtonSelect} stepName="Sin" key="Sin" user={affiliateUser} shouldSaveUpdate={false} goBackToNamedStep={() => goBackFromSIN} updateUserState={updateAffiliateUser} stepTitle={stepTitle} title={t('beneficiary.SIN', { firstName: beneficiaryName })} cardTitle={t('beneficiary.SINDisclaimer.title')} cardSubtitle1={t('beneficiary.SINDisclaimer.subtitle1')} cardSubtitle2={t('beneficiary.SINDisclaimer.subtitle2')} cardDescription={t('beneficiary.SINDisclaimer.subtitle3')} noSinText={t('beneficiary.noSin')} />,
    <GenderStep stepName="Gender" key="Gender" shouldSaveUpdate={false} continueToNamedStep={isEditMode ? nextStep : () => nextStepFromGender} user={affiliateUser} updateUserState={updateAffiliateUser} beneficiaryName={isRespAdult ? t('beneficiary.beneficiary') : beneficiaryName} stepTitle={stepTitle} />,
    <BeneficiaryAffiliationTypeStep stepName="AffiliationType" key="AffiliationType" accountType={currentSubAccount?.account?.type} disableSuccessorOption={disableSuccessorOption} continueToNamedStep={isEditMode ? nextStep : undefined} user={affiliateUser} updateUserState={updateAffiliateUser} beneficiaryName={isRespAdult ? t('beneficiary.beneficiary') : beneficiaryName} stepTitle={stepTitle} />,
    <BeneficiaryAllocationStep stepName="Allocation" key="Allocation" goBackToNamedStep={() => prevStepFromAllocation} continueToNamedStep={isEditMode ? nextStep : undefined} user={affiliateUser} updateUserState={updateAffiliateUser} beneficiaryName={isRespAdult ? t('beneficiary.beneficiary') : beneficiaryName} stepTitle={stepTitle} />,
    <BeneficiaryRelationshipStep stepName="BeneficiaryRelationsip" goBackToNamedStep={() => prevStepFromRelationship} key="BeneficiaryRelationsip" user={affiliateUser} accountType={currentSubAccount?.account?.type} beneficiaryName={beneficiaryName} updateBeneficiaryList={updateBeneficiaryList} stepTitle={stepTitle} />,
    <BeneficiaryReviewStep stepName="BeneficiaryReview" key="BeneficiaryReview" editField={editField} allowMultipleAffiliate={isTFSAOrRRSP} isTFSAOrRRSPAccount affiliateUserList={props.affiliateUserList} addNewBeneficiary={addNewBeneficiary} addNewBeneficiaryonDone={addNewBeneficiaryonDone} removeBeneficiary={removeBeneficiary} stepTitle={stepTitle} proceedToNextWizard={proceedToNextWizard} />,
  ]);

  const getSpousalAffiliateSteps = (): JSX.Element[] => ([
    <SpousalNameStep
      stepTitle={stepTitle}
      stepName="rrifSpousalName"
      continueToNamedStep={() => (isEditMode ? 'rrifSpousalConfirmStep' : 'rrifSpousalEmail')}
      key="rrifSpousalName"
      analyticsEvent={ovAnalyticsEvents.rrif_SpouseNamePageContinueButtonSelect}
      user={affiliateUser}
      updateUserState={updateAffiliateUser}
      title={t('rrifSteps.titles.spouseName.title')}
      subtitle={t('rrifSteps.titles.spouseName.subTitle')}
      goBackAction={() => exitWizard()}
    />,
    <SpousalEmailStep
      texts={{
        title: t('account:rrifSteps.titles.spouseEmail.title', { name: affiliateUser.firstName }),
        subTitle: t('account:rrifSteps.titles.spouseEmail.subTitle'),
      }}
      analyticsEvent={ovAnalyticsEvents.rrifSpouseEmailPageContinueButtonSelect}
      continueToNamedStep={() => (isEditMode ? 'rrifSpousalConfirmStep' : 'rrifSpousalPhysicalAddress')}
      stepName="rrifSpousalEmail"
      key="rrifSpousalEmail"
      updateUserState={updateAffiliateUser}
      user={affiliateUser}
    />,
    <SpousePhysicalAddressWizardStep analytictsEvent={ovAnalyticsEvents.rrifSpouseAddressContinueButtonSelect} continueToNamedStep={() => (isEditMode ? 'rrifSpousalConfirmStep' : 'rrifSpousalDOB')} stepName="rrifSpousalPhysicalAddress" key="rrifSpousalPhysicalAddress" hideNav stepProps={{ absoluteTotalSteps: 6 }} title={t('rrifSteps.titles.spouseAddress.title', { spouseFirstName: affiliateUser.firstName })} subtitle={t('rrifSteps.titles.spouseAddress.subTitle')} user={affiliateUser} updateUserState={updateAffiliateUser} showStepper={false} shouldSaveUpdate={false} />,
    <SpouseDateOfBirthStep analytictsEvent={ovAnalyticsEvents.rrifSpouseDateOfBirthContinueButtonSelect} stepName="rrifSpousalDOB" key="rrifSpousalDOB" continueToNamedStep={() => (isEditMode ? 'rrifSpousalConfirmStep' : 'rrifSpousalSin')} title={t('rrifSteps.titles.spouseDOB.title', { spouseFirstName: affiliateUser.firstName })} subtitle={t('rrifSteps.titles.spouseDOB.subTitle')} user={affiliateUser} updateUserState={updateAffiliateUser} shouldSaveUpdate={false} />,
    <SpouseSinStep analytictsEvent={ovAnalyticsEvents.rrifSpouseSinNumberContinueButtonSelect} stepName="rrifSpousalSin" key="rrifSpousalSin" title={t('rrifSteps.titles.spouseSIN.title', { spouseFirstName: affiliateUser.firstName })} user={affiliateUser} updateUserState={updateAffiliateUser} shouldSaveUpdate={false} />,
    <SpouseEmploymentStatusWizardStep
      continueToNamedStep={() => (isEditMode ? 'rrifSpousalConfirmStep' : 'rrifSpousalConfirmStep')}
      stepName="rrifSpousalEmploymentStatus"
      key="rrifSpousalEmploymentStatus"
      analyticsEvents={{
        employmentInfo: ovAnalyticsEvents.rrifSpouseEmploymentSelect,
        industry: ovAnalyticsEvents.rrifSpouseIndustryContinueButtonSelect,
        role: ovAnalyticsEvents.rrifSpouseRoleContinueButtonSelect,
        fieldOfStudy: undefined,
      }}
      titles={{
        employmentInfo: { title: t('rrifSteps.titles.spouseEmploymentInfo.title', { spouseFirstName: affiliateUser.firstName }), subTitle: t('rrifSteps.titles.spouseEmploymentInfo.subTitle') },
        industry: { title: t(`rrifSteps.titles.spouseEmploymentIndustry.${affiliateUser.employmentStatus === 'RETIRED' ? 'titleForRetiered' : 'title'}`, { spouseFirstName: affiliateUser.firstName }), subTitle: t('rrifSteps.titles.spouseEmploymentIndustry.subTitle') },
        role: { title: t(`rrifSteps.titles.spouseEmploymentRole.${affiliateUser.employmentStatus === 'RETIRED' ? 'titleForRetiered' : 'title'}`, { spouseFirstName: affiliateUser.firstName }), subTitle: t('rrifSteps.titles.spouseEmploymentRole.subTitle') },
        fieldOfStudy: { title: t('rrifSteps.titles.spouseEmploymentFieldOfStudy.title', { spouseFirstName: affiliateUser.firstName }), subTitle: t('rrifSteps.titles.spouseEmploymentFieldOfStudy.subTitle') },
      }}
      user={affiliateUser}
      updateUserState={updateAffiliateUser}
      hideNav
      stepProps={{ absoluteTotalSteps: 6 }}
      shouldSaveUpdate={false}
      onContinueStepUnEmployed="rrifSpousalConfirmStep"
    />,
    <ConfirmSpousalAffiliateDetailsStep stepName="rrifSpousalConfirmStep" key="rrifSpousalConfirmStep" user={affiliateUser} onEditMode={setIsEditMode} proceedToNextStep={goToNextStep} action={() => rrifStepsReviewed(isRIFSpousal)} />,
  ]);

  const steps: JSX.Element[] = [
    ...(isRespAdult ? getRespAdultSteps() : []),
    ...(isSingleOrFamily ? getRespSingleOrFamilySteps() : []),
    ...(isTFSAOrRRSP ? getTFSAOrRRSPSteps() : []),
    ...(isRRIF || isRIFSpousal ? getSpousalAffiliateSteps() : []),
  ];

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      key={initialStep}
      initialStep={initialStep}
      transitions={{}}
      className="ov-step-wizard"
      onStepChange={(stepChange: {
        previousStep: number
        activeStep: number
      }) => {
        if (stepChange.activeStep === 1) {
          setInitialStep(1);
        }
      }}
      nav={(
        <TrackingBar steps={steps} baselineStepProps={stepProps} showStepper={showStepper} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

AccountTypeAuxiliaryWizard.defaultProps = {
  onContinue: undefined,
  stepProps: undefined,
  stepTitle: undefined,
  showStepper: undefined,
  goBack: undefined,
  currentSubAccount: undefined,
  goBackToNamedStep: undefined,
};

export default AccountTypeAuxiliaryWizard;
