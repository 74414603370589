import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Markup } from 'interweave';
import OvForm from '../common/wrappers/ovForm';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { RetirementAccountType } from '../checker';
import { ovColors } from '../../theme/colors';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { userEmailVar } from '../../utils/localVariables';

interface Props {
  onContinue?: () => void,
  isMultipleRrifFlow: boolean,
  uncompletedRrifAccount: RetirementAccountType;
}

const ImportantInformation = ({ onContinue, isMultipleRrifFlow, uncompletedRrifAccount }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'account']);
  const { colors } = useContextTheme();
  const informationListItem = (index: string, title: string, message: string): JSX.Element => (
    <Box display="flex">
      <Box mr={0.5}>{`${index}.`}</Box>
      <Box>
        <Typography variant="bodySub2" style={{ marginBottom: 16 }} textAlign="justify">
          <Markup content={t('account:rrifSteps.rrifImportantInformation.informationItem', { title, message })} />
        </Typography>
      </Box>
    </Box>
  );

  const getGoalName = (rrifAccount: RetirementAccountType): string => {
    if (rrifAccount.subAccounts && rrifAccount.subAccounts.length > 0 && rrifAccount.subAccounts[0].goal) {
      return rrifAccount.subAccounts[0].goal.name;
    }
    return '';
  };
  const translatedAccountType = t(`account:resources.typeTitle.${uncompletedRrifAccount.type}`);
  const translatedAccountNonAbbreviatedName = t(`account:resources.accountNonAbbreviatedName.${uncompletedRrifAccount.type}`);
  const getAccountTypeAndCustodianNumber = (rrifAccount: RetirementAccountType): string => {
    let value = translatedAccountType;
    if (rrifAccount.custodianAccountNumber) value += ` - ${rrifAccount.custodianAccountNumber}`;
    return value;
  };

  return (
    <OvForm
      onSubmit={() => {
        sendAnalyticsEvent(ovAnalyticsEvents.rrifImportantInformationModalContinueButtonSelect).then();
        if (onContinue) onContinue();
      }}
      loading={false}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: '1 0 auto' }}>
          <Typography variant="heading2" style={{ marginBottom: 16 }}>{t('account:rrifSteps.rrifImportantInformation.title', { accountNameAndAbbreviation: `${translatedAccountNonAbbreviatedName} (${translatedAccountType})` })}</Typography>
          <Typography variant="bodySub2" style={{ marginBottom: 16 }}>{t('account:rrifSteps.rrifImportantInformation.body', { accountType: translatedAccountType })}</Typography>
          <Box mt={2} />
          {informationListItem('1', t('account:rrifSteps.rrifImportantInformation.information1Title'), t('account:rrifSteps.rrifImportantInformation.information1Message', { accountType: translatedAccountType }))}
          <Box mt={2} />
          {informationListItem('2', t('account:rrifSteps.rrifImportantInformation.information2Title'), t('account:rrifSteps.rrifImportantInformation.information2Message', { accountType: translatedAccountType }))}
          <Box mt={2} />
          {
            isMultipleRrifFlow ? (
              <>
                <Typography variant="bodySub2" style={{ marginBottom: 16 }}>{t('account:rrifSteps.rrifImportantInformation.multipleRrifMessage1')}</Typography>
                <Box mt={2} />
                <Box bgcolor={ovColors.neutral10}>
                  <Box paddingLeft={3} height="50px" display="flex" alignItems="center">
                    <Typography variant="bodySub2">{getGoalName(uncompletedRrifAccount)}</Typography>
                  </Box>
                  <Box height="1px" bgcolor={colors.neutral70} />
                  <Box paddingLeft={3} height="50px" display="flex" alignItems="center">
                    <Typography variant="bodySub2">{getAccountTypeAndCustodianNumber(uncompletedRrifAccount)}</Typography>
                  </Box>
                </Box>
                <Box mt={2} />
                <Typography variant="bodySub2">{t('account:rrifSteps.rrifImportantInformation.multipleRrifMessage2.1')}</Typography>
                <Link sx={{ margin: '0px 5px' }} href="https://onevest.zendesk.com/hc/en-us/articles/14480147492372" style={{ color: colors.link }} underline="always" target="_blank">{t('account:rrifSteps.rrifImportantInformation.multipleRrifMessage2.2')}</Link>
                <Typography variant="bodySub2">{t('account:rrifSteps.rrifImportantInformation.multipleRrifMessage2.3')}</Typography>
                <Link sx={{ margin: '0px 5px' }} href={userEmailVar() ? `https://onevest.zendesk.com/hc/en-us/requests/new?tf_anonymous_requester_email=${encodeURIComponent(userEmailVar())}` : 'https://onevest.zendesk.com/hc/en-us/requests/new'} style={{ color: colors.link }} underline="always" target="_blank">{t('account:rrifSteps.rrifImportantInformation.multipleRrifMessage2.4')}</Link>
              </>
            ) : undefined
          }
          <Box mt={2} />
        </div>
      </div>
    </OvForm>
  );
};

ImportantInformation.defaultProps = {
  onContinue: undefined,
};

export default ImportantInformation;
