import { gql } from '@apollo/client';

export const FETCH_SUB_ACCOUNT_PERFORMANCE = gql`
query fetchSubAccount ($subAccountId: ObjectID!) {
fetchSubAccount(subAccountId: $subAccountId) {
  subAccount {
    id
    name
    goal { id type name }
    availableThemes {
      id
    }
    statistics {
      id
      primaryAssetClassHoldings {
        assetClass {
          id
          key
          name
          description
          translatedName {
            en
            fr
          }
          translatedDescription {
            en
            fr
          }
        }
        percentage
        totalCents
        secondaryAssetClassHoldings {
          assetClass {
            id
            key
            name
            description
            translatedName {
              en
              fr
            }
            translatedDescription {
              en
              fr
            }
          }
          percentage
          totalCents
          securityHoldings {
            financialProduct {
              id
              ticker
              translatedPortfolioDescription { en fr}
              translatedName { en fr }
            }
            percentage
            totalCents
            quantity
          }
        }
      }
      holdings {
        totalCents
        financialProduct {
          secondaryAssetClass {
            key
            translatedName { en fr }
            translatedDescription { en fr }
          }
        }
      }
      marketValueCents
      timeWeightedReturn
      moneyWeightedReturn
      availableFundsToWithdrawCents
    }
    theme {
      id
      key
      translatedName { en fr }
      translatedShortDescription { en fr }
      translatedDescription { en fr }
      iconKey
    }
    financialProduct{
      riskReturnLevel
    }
    account {
      id,
      type,
    },
    allowClientDeposits
  }
}
}
`;

export const CLOSE_SUB_ACCOUNT = gql`
  mutation closeSubAccount($input: CloseSubAccountInput!) {
    closeSubAccount(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

export const UPDATE_PORTFOLIO_DETAILS = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount {
        id
        name
      }
    }
  }
`;
