type FgAndGbColors = { bg: string, fg:string };
type MemberColors = FgAndGbColors & { bar: string };

const colorsBySequence: MemberColors[] = [
  { bg: '#F2F9FE', fg: '#21496B', bar: '#4B779B' }, // 1st Blue
  { bg: '#F2FDFB', fg: '#338073', bar: '#86C5BB' }, // 2nd Teal
  { bg: '#FEFCEA', fg: '#986523', bar: '#E9CC63' }, // 3rd Yellow
  { bg: '#FEF7EE', fg: '#B34A22', bar: '#E79855' }, // 4th Orange
  { bg: '#EFF2FE', fg: '#4B45A1', bar: '#797AD2' }, // 5th Indigo
  { bg: '#FBF4FE', fg: '#782D86', bar: '#C179D2' }, // 6th Fuchsia
  { bg: '#FDF1F2', fg: '#9E2E40', bar: '#D7747D' }, // 7th Pink
  { bg: '#F4FAF5', fg: '#428A48', bar: '#8BC08F' }, // 8th+ Lime
];

export const householdColors:FgAndGbColors = {
  bg: '#FAF2EA', fg: '#7D4B1C', // household
};

export const memberColors = (index: number) => {
  if (index >= 0 && index < colorsBySequence.length) return colorsBySequence[index];
  return colorsBySequence[colorsBySequence.length - 1];
};
