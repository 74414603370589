import * as Sentry from '@sentry/react';
import { isLocalEnv } from './commonMethods';
import { userIdVar } from './localVariables';

export const initSentry = (): void => {
  if (!isLocalEnv) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Sentry.BrowserTracing()],
      environment: process.env.REACT_APP_ENV,
      tracesSampleRate: 1.0,
    });
  }
};

export const setSentryContext = (): void => {
  if (!isLocalEnv) {
    Sentry.setContext('OV', {
      UserId: userIdVar(),
    });
  }
};
