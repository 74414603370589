import { Typography } from '@mui/material';
import { FormEvent } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';
import OvForm from '../common/wrappers/ovForm';

interface Props {
  financialProductDisclaimer: string,
  onContinue?: () => void,
}

const PortfolioDisclaimer = ({
  financialProductDisclaimer, onContinue,
}: Props): JSX.Element => {
  const { t } = useTranslation(['account']);

  const confirmDisclaimer = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.stopPropagation();
    event?.preventDefault();
    if (onContinue) onContinue();
  };

  return (
    <OvForm onSubmit={confirmDisclaimer} buttonText={t('account:portfolioDisclaimer.confirmButton')}>
      <Typography variant="heading2" style={{ textAlign: 'left' }}>{t('account:portfolioDisclaimer.title')}</Typography>
      <Typography variant="paragraph3" style={{ textAlign: 'left' }}>
        <ReactMarkdown remarkPlugins={[remarkGfm]} linkTarget="_blank">
          {financialProductDisclaimer}
        </ReactMarkdown>
      </Typography>
    </OvForm>
  );
};

PortfolioDisclaimer.defaultProps = {
  onContinue: undefined,
};

export default PortfolioDisclaimer;
