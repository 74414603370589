import { FormEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  TextField,
  Typography,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  InputAdornment,
} from '@mui/material';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { UPDATE_USER } from './graphql';
import { PhysicalAddress, ProvinceList, provinces } from './address/resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import CloseIconSVG from '../../assets/images/close-icon.svg';
import OvForm from '../common/wrappers/ovForm';
import { getLocale } from '../../assets/i18n/config';

export interface UserState {
  dateOfBirth?: Date,
  physicalAddress?: Partial<PhysicalAddress>,
  incompleteFields?: string[],
}
dayjs.extend(utc);
interface Props {
  user: UserState,
  title?: string,
  subtitle?: string,
  minAge?: number,
  maxAge?: number,
  shouldSaveUpdate?: boolean,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  analytictsEvent?: string,
}

const validateAge = (dateVal: Date, minAge: number, maxAge?: number): boolean => {
  const today = dayjs(new Date());
  const birthDate = dayjs(dateVal);
  const age = today.diff(birthDate, 'year', true);
  if (maxAge) return age > minAge && age < maxAge;
  return age > minAge;
};

const DateOfBirth = ({
  user, updateUserState, shouldSaveUpdate = true, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const [provinceKey] = useState(user.physicalAddress?.province || '');
  const [state, updateState] = useState({
    error: '',
  });
  const [value, setValue] = useState(user.dateOfBirth || null);
  const [open, setOpen] = useState(false);
  const handleClose = (): void => {
    setOpen(false);
    updateState({
      error: '',
    });
  };

  const dateOfBirthUpdated = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'DATE_OF_BIRTH_UPDATED', DateOfBirth: user.dateOfBirth });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };

  const formatDate = (dateValue: Date): string => {
    if (dateValue && dateValue !== undefined) {
      try {
        return new Date(dateValue).toISOString().split('T')[0];
      } catch (error) {
        //  eliminate invalid dates
        return '';
      }
    }
    return '';
  };
  const [updateDateOfBirth] = useMutation(UPDATE_USER, {
    variables: {
      input: { dateOfBirth: (user.dateOfBirth && user.dateOfBirth !== undefined) ? formatDate(user.dateOfBirth) : '' },
    },
    onCompleted: dateOfBirthUpdated,
    onError: () => setLoading(false),
  });

  const onContinueClick = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    sendAnalyticsEvent(props.analytictsEvent ?? ovAnalyticsEvents.onBoardingEnterDOBContinue).then();
    if (user.dateOfBirth) {
      const usersProvince: ProvinceList = provinces.filter((s) => s.key === provinceKey)[0];
      let provincialMinAge = 18;
      if (usersProvince && usersProvince.minAge) {
        provincialMinAge = usersProvince.minAge;
      }
      if (isNumber(props.minAge)) provincialMinAge = props.minAge;
      if (validateAge(user?.dateOfBirth, provincialMinAge, props.maxAge)) {
        if (shouldSaveUpdate) {
          setLoading(true);
          updateDateOfBirth().then();
        } else if (props.onContinue) props.onContinue();
      } else {
        const suffix = props.maxAge ? 'affiliate' : 'user';
        updateState({
          ...state,
          error: props.maxAge ? t('user:dateOfBirth.subtitle2', { user: t(`user:dateOfBirth.${suffix}`), maxAge: props.maxAge })
            : t('user:dateOfBirth.subtitle3', { user: t(`user:dateOfBirth.${suffix}`), provincialMinAge }),
        });
        setOpen(true);
      }
    }
  };
  if (state.error) {
    return (
      <Dialog onClose={handleClose} open={open}>
        <IconButton onClick={handleClose} style={{ display: 'flex', justifyContent: 'right' }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle><Typography id="modal-modal-title" variant="heading3">{t('user:dateOfBirth.invaliedMessage')}</Typography></DialogTitle>
        <DialogContent>
          <Typography id="modal-modal-description" color="error" data-testid="dateofbirth-error" variant="paragraph3">{state.error}</Typography>
        </DialogContent>
        <DialogContent>
          <Button variant="primary-large" disableElevation fullWidth data-testid="continue-button" onClick={handleClose}>{t('base:button.goBack')}</Button>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <OvForm loading={loading} onSubmit={onContinueClick}>
      <Typography variant="heading2">{props.title ?? t('user:dateOfBirth.title')}</Typography>
      <Typography variant="paragraph3">{props.subtitle ?? t('user:dateOfBirth.subTitle')}</Typography>
      <LocalizationProvider locale={getLocale()} dateAdapter={AdapterDateFns}>
        <DatePicker
          label="yyyy-mm-dd"
          value={value ? dayjs(value).utc().toDate() : null}
          onChange={(newValue) => {
            setValue(newValue);
            if (newValue) {
              updateUserState({ ...user, dateOfBirth: newValue });
            }
          }}
          renderInput={(params) => (
            <TextField
              data-testid="dateofbirth-input"
              sx={{ marginBottom: '32px' }}
              variant="standard"
              {...params}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="clear input field"
                      disableRipple
                      onClick={() => {
                        setValue(null);
                      }}
                      edge="end"
                    >
                      <img src={CloseIconSVG} alt="close" />
                    </IconButton>
                  </InputAdornment>),
              }}
            />
          )}
          inputFormat="yyyy-MM-dd"
          mask="____-__-__"
        />
      </LocalizationProvider>
    </OvForm>
  );
};

DateOfBirth.defaultProps = {
  title: undefined,
  subtitle: undefined,
  minAge: undefined,
  maxAge: undefined,
  shouldSaveUpdate: true,
  onContinue: undefined,
  analytictsEvent: undefined,
};

export default DateOfBirth;
