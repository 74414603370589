import { gql, InMemoryCache, makeVar } from '@apollo/client';
import { AccountTypeAttributes } from '../components/account/resources';
import { getFromLocalStorage, getFromSessionStorage } from './localStorage';

// Local extended types
export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    isSignUpComplete: Boolean!
    isOnboardingComplete: Boolean!
  }
`;

export const isLoggedInVar = makeVar<boolean>(!!getFromLocalStorage('token') || !!getFromSessionStorage('token'));
export const accessTokenVar = makeVar<string>(getFromLocalStorage('token') || getFromSessionStorage('token') || '');
export const refreshTokenVar = makeVar<string>(getFromLocalStorage('refresh-token') || '');
export const isOnboardingCompleteVar = makeVar<boolean | null>(null);
export const isSignUpCompleteVar = makeVar<boolean>(true); // Assumes user already exists (goes to SignIn page)
export const userIdVar = makeVar<string>('');
export const userGenderVar = makeVar<string>('');
export const organizationIdVar = makeVar<string>('');
export const userEmailVar = makeVar<string>('');
export const ipAddressVar = makeVar<string>('');
export const availableFeatureFlagsVar = makeVar<string[]>([]);
export const allAccountTypesVar = makeVar<Array<AccountTypeAttributes>>([]);
export const isEmbedded = makeVar<boolean>(false);
export const orgSupportUrlVar = makeVar<string | null>(null);
export const orgHelpCentreUrlVar = makeVar<string | null>(null);
export const canViewHouseholdVar = makeVar<boolean>(false);
export const householdMembersIdVar = makeVar<string[]>([]);
export const isIdVerifiedVar = makeVar<boolean>(false);

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    DailySnapshot: {
      keyFields: ['id', 'objectType'],
    },
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        isOnboardingComplete: {
          read() {
            return isOnboardingCompleteVar();
          },
        },
        isSignUpComplete: {
          read() {
            return isSignUpCompleteVar();
          },
        },
      },
    },
  },
});

export const GET_USER_INITIAL_STATUS = gql`
  query GetUserInitialStatus {
    isLoggedIn @client
    isSignUpComplete @client
    isOnboardingComplete @client
  }
`;
