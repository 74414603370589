/* eslint-disable  no-nested-ternary */

import * as React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useTranslation } from 'react-i18next';
import recommendedIcon from '../../../assets/images/recommended-icon.svg';
import OvRadioButton from '../controllers/ovRadioButton';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface ButtonListItemProps {
  prefixIcon?: string,
  header?: string,
  description: string,
  hasSuffix?: boolean,
  hasCheckField?: boolean,
  suffixText?: string,
  checked?: boolean,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  marginTop?: number,
  marginRight?: number,
  marginBottom?: number,
  marginLeft?: number,
  margin?: number,
  isSelected?: boolean,
  recommended?: boolean,
}

const ButtonDescriptionListItem = ({ ...props }: ButtonListItemProps): JSX.Element => {
  const { t } = useTranslation(['base']);
  const { colors } = useContextTheme();
  return (
    <Box mb={props.marginBottom} mt={props.marginTop} mr={props.marginRight} ml={props.marginLeft} m={props.margin}>
      <Button
        variant="button-list-item"
        style={{
          minHeight: 88,
          backgroundColor: props.isSelected ? colors.buttonItemBackground : colors.baseComponentBackground,
        }}
        onClick={props.onClick}
      >
        <Box display="flex" flexDirection="row" alignItems="start" pt={2} pb={2}>
          {props.prefixIcon ? <img src={props.prefixIcon} alt="icon" width={40} /> : null}
          <Box ml={props.prefixIcon ? 1.2 : 0} flexDirection="column" alignItems="start" textAlign="left">
            {props.header ? <Typography variant="bodySub2" fontWeight={600}>{props.header}</Typography> : null}
            <Typography variant="paragraph3" mt={0.5} style={{ marginBottom: 0 }}>{props.description}</Typography>
            {
              props.recommended ? (
                <Box display="flex" flexDirection="row" mt={1.2}>
                  <img src={recommendedIcon} alt="recommended-icon" style={{ marginRight: '5.5px' }} />
                  <Typography variant="captionDescriptorMedium" sx={{ marginBottom: '2px', fontWeight: 600, color: colors.success }}>{t('base:buttonDescriptionListItem.recommended')}</Typography>
                </Box>
              ) : undefined
            }
          </Box>
        </Box>
        {!props.hasSuffix ? null : props.suffixText
          ? <Typography variant="bodySub1" color={colors.link}>{props.suffixText}</Typography> : props.hasCheckField
            ? <OvRadioButton checked={props.checked} /> : <ChevronRightRoundedIcon className="black" />}
      </Button>
    </Box>
  );
};

ButtonDescriptionListItem.defaultProps = {
  prefixIcon: null,
  header: null,
  hasCheckField: false,
  suffixText: null,
  checked: false,
  marginTop: undefined,
  marginRight: undefined,
  marginBottom: 1,
  marginLeft: undefined,
  margin: undefined,
  hasSuffix: true,
  isSelected: undefined,
  recommended: false,
};
export default ButtonDescriptionListItem;
