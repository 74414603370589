/* eslint-disable @typescript-eslint/no-explicit-any */
import { SetUserContextType } from '../providers/userContextProvider';
import { getFromSessionStorage, setToSessionStorage } from './localStorage';

// TODO: react-router is not working when embedded in iframe
// For now, I'm defining our own navigate and useParams to set to and get from sessionStorage

export const navigate = (setUserContext: SetUserContextType, path: string, params: any = {}): void => {
  setToSessionStorage('path', path);
  setToSessionStorage('params', JSON.stringify(params));
  setUserContext((prev) => ({ ...prev, path }));
};

export const useParams = (): any => {
  const params = getFromSessionStorage('params');
  return params ? JSON.parse(params) : {};
};
