import * as React from 'react';
import { Box } from '@mui/system';
import { Typography, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import { colors } from '../../theme/colors';
import informationIcon from '../../assets/images/information.svg';
import { formatPercentValue, formatReturnAmount } from '../../utils/commonMethods';
import FeedbackModal from '../common/wrappers/modals/ovFeedbackModal';
import { getAccountTypeName } from '../account/resources';

export enum ITEM {
  allocation = 'allocation',
  timeWeighted = 'timeWeighted',
  moneyWeighted = 'moneyWeighted',
  contributions = 'contributions',
  TFSA = 'TFSA',
  RRSP = 'RRSP',
  PERSONAL = 'PERSONAL',
  RESP = 'RESP',
  RESP_SINGLE = 'RESP_SINGLE',
  RESP_ADULT = 'RESP_ADULT',
  RESP_FAMILY = 'RESP_FAMILY',
  CASH_JOINT = 'CASH_JOINT',
  CORPORATE_CASH = 'CORPORATE_CASH',
  CORPORATE_CHARITY = 'CORPORATE_CHARITY',
  CORPORATE_ESTATE = 'CORPORATE_ESTATE',
  CORPORATE_TRUST = 'CORPORATE_TRUST',
  GRSP = 'GRSP',
  LIF = 'LIF',
  LIRA = 'LIRA',
  PRIF = 'PRIF',
  RIF_SPOUSAL = 'RIF_SPOUSAL',
  RRIF = 'RRIF',
  RRSP_SPOUSAL = 'RRSP_SPOUSAL',
  RDSP = 'RDSP',
}

interface Props {
  headerTextSize?: string,
  options: { item: ITEM, value: number, isPercent: boolean }[]
  infoIconClicked?: (item: ITEM) => void
}

const BaseInfoCard = ({ options, headerTextSize, infoIconClicked }: Props): JSX.Element => {
  const { t } = useTranslation(['statistics']);
  const styles = {
    outerBox: {
      width: '100%',
      maxHeight: '91px',
      display: 'flex',
      padding: '12px 34px',
      alignItems: 'center',
      borderRadius: '8px',
      border: `1px solid ${colors.portfolioCardBorder}`,
      background: colors.baseComponentBackground,
      justifyContent: 'space-between' as const,
      boxSizing: 'border-box' as const,
    },
    innerBox: {
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      height: '100%',
      width: '48%',
    },
    valueText: {
      color: colors.agreementText,
    },
    infoText: {
      color: colors.agreementText,
    },
  };
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<{ value: string, title: string, body: string, item: ITEM | undefined }>(
    {
      value: '',
      title: '',
      body: '',
      item: undefined,
    },
  );
  const otherItems = [ITEM.allocation, ITEM.timeWeighted, ITEM.moneyWeighted, ITEM.PERSONAL];
  const openOptionModal = (data: { item: ITEM, value: number }): void => {
    const isAccount = !otherItems.includes(data.item);
    setModalData({
      value: formatPercentValue(data.value, 1),
      title: isAccount ? t('statistics:rateOfReturnCard.accountData.title', { AccountType: getAccountTypeName(data.item.toString()) }) : t(`statistics:rateOfReturnCard.${data.item}Data.title`),
      body: isAccount ? t('statistics:rateOfReturnCard.accountData.body', { AccountType: getAccountTypeName(data.item.toString()) }) : t(`statistics:rateOfReturnCard.${data.item}Data.body`),
      item: data.item,
    });
    if (infoIconClicked) {
      infoIconClicked(data.item);
    }
    setOpen(true);
  };
  const cardItems = (value: string, itemName: string, onClick: () => void): JSX.Element => (
    <Box style={styles.innerBox} onClick={onClick} key={itemName}>
      <Box mb={0.7} height="28px">
        <Typography variant="bodyMain1" style={styles.valueText}>{value}</Typography>
      </Box>
      <Box display="flex" alignItems="start">
        <Typography variant="captionDescriptorBold" fontWeight={500} style={styles.infoText}>{itemName}</Typography>
        <Box mr={0.7} />
        <img src={informationIcon} style={{ marginTop: '2px' }} alt="information-icon" />
      </Box>
    </Box>
  );
  return (
    <Box style={styles.outerBox}>
      {options.map((option) => cardItems(option.isPercent ? formatPercentValue(option.value, 1) : formatReturnAmount(option.value), otherItems.includes(option.item) ? t(`statistics:rateOfReturnCard.${option.item}`) : t('statistics:rateOfReturnCard.contributions'), () => openOptionModal(option)))}
      <FeedbackModal
        open={open}
        onClose={() => setOpen(false)}
        component={(
          <>
            {
              modalData.item === ITEM.timeWeighted || modalData.item === ITEM.moneyWeighted ? (
                <Typography variant="heading2" mb={1} color={colors.secondary}>
                  {modalData.value}
                </Typography>
              ) : undefined
            }
            <Typography sx={{ fontSize: headerTextSize, lineHeight: '40px' }} textAlign={modalData.item === ITEM.timeWeighted || modalData.item === ITEM.moneyWeighted ? 'justify' : 'center'} variant="heading3" mb={1} color={colors.secondary}>
              {modalData.title}
            </Typography>
            <Typography textAlign={modalData.item === ITEM.timeWeighted || modalData.item === ITEM.moneyWeighted ? 'justify' : 'center'} variant="paragraphMain" mb={4}>
              <Markup content={modalData.body} />
            </Typography>
            <Button variant="primary-large" onClick={() => setOpen(false)}>Got it</Button>
          </>
        )}
      />
    </Box>
  );
};

BaseInfoCard.defaultProps = {
  headerTextSize: '20px',
  infoIconClicked: undefined,
};

export default BaseInfoCard;
