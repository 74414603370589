import * as React from 'react';
import { Box, Avatar, Typography } from '@mui/material';

interface PersonaStepsListItemProps {
  index: number,
  header: string,
  body: string,
  marginBottom?: number,
}

const PersonaStepsListItem = ({ ...props }: PersonaStepsListItemProps): JSX.Element => (
  <Box display="flex" marginBottom={props.marginBottom} mt={0}>
    <Avatar className="personaStepListItemCount">{ props.index ?? 1 }</Avatar>
    <Box display="flex" flexDirection="column" ml={2}>
      <Typography variant="bodySub1" mb={0.5}>{ props.header ?? 'Empty Header' }</Typography>
      <Typography variant="paragraph2">{ props.body ?? 'Empty Body' }</Typography>
    </Box>
  </Box>
);

PersonaStepsListItem.defaultProps = {
  marginBottom: undefined,
};

export default PersonaStepsListItem;
