import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { useMutation } from '@apollo/client';
import { Box, List, Typography } from '@mui/material';
import OvForm from '../../common/wrappers/ovForm';
import ButtonListItem from '../../common/lists/buttonListItem';
import { UPDATE_USER } from '../graphql';

export enum UserLanguage {
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
}

interface Props {
  language?: UserLanguage,
  onSelect?: () => void,
}

const SelectLanguage = ({ language, onSelect }: Props): JSX.Element => {
  const { t } = useTranslation(['user']);
  const [updateLanguage] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        language,
      },
    },
    onCompleted: () => {
      if (onSelect) onSelect();
    },
  });
  return (
    <OvForm hasButton={false} formsStyle={{ maxWidth: '400px' }}>
      <Typography variant="heading2">{t('user:selectLanguage.agreementLanguage.title')}</Typography>
      <Typography variant="paragraph3">{t('user:selectLanguage.agreementLanguage.subtitle')}</Typography>
      <List sx={{ maxHeight: '400px', overflowY: 'auto' }}>
        {Object.values(UserLanguage).map((lang) => (
          <Box key={Math.random()}>
            <ButtonListItem
              text={capitalize(lang)}
              onClick={() => {
                updateLanguage({
                  variables: {
                    input: {
                      language: lang,
                    },
                  },
                });
                console.log({ event: 'LANGUAGE_UPDATED', Language: lang });
              }}
              isSelected={(language ?? 'english').toUpperCase() === lang}
            />
          </Box>
        ))}
      </List>
    </OvForm>
  );
};

SelectLanguage.defaultProps = {
  language: undefined,
  onSelect: undefined,
};

export default SelectLanguage;
