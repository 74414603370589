/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  Container,
  Tab,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { FETCH_TRANSFERS } from './graphql';
import OvPagination from '../common/wrappers/ovPagination';
import {
  PageData,
  Transfer,
} from './resources';
import TransferItem from './transferItem';
import { styles } from './styles';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { HouseholdViewTypes } from '../../utils/commonGraphql';

interface Props {
  accountId?: string,
  subAccountId?: string,
  goalId?: string,
  userId?: string,
  onClose: () => void,
  householdViewType?: HouseholdViewTypes,
}
const AllTransfers = ({ onClose, ...props }: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const perPage = 15;
  const { t } = useTranslation(['document', 'transfer']);
  const isMobileScreen = useMediaQuery('(max-width:600px)');
  const style = {
    tabPanels: {
      padding: '10px 0 0 0',
      flexDirection: 'column',
      display: 'flex',
      overflowY: 'auto',
      maxHeight: isMobileScreen ? document.getElementsByTagName('body')[0].clientHeight - 230 : '330px',
    },
  };
  const client = useApolloClient();
  const [value, setValue] = useState(1);
  const [transfersPageData, setTransfersPageData] = useState<PageData>({ totalCount: 0, currentCount: 0 });
  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };
  const [transactions, setTransactions] = useState<Transfer[]>([]);
  const [loadAllTransfers, setLoadAllTransfers] = useState(false);
  const getTrasactions = async (data: {
    accountId?: string,
    userId?: string,
    goalId?: string,
    subAccountId?: string,
    householdViewType?: HouseholdViewTypes,
    offset: number,
  }): Promise<ApolloQueryResult<any>> => client.query({
    query: FETCH_TRANSFERS,
    variables: {
      input: {
        filter: {
          accountId: data.accountId,
          userId: data.userId,
          goalId: data.goalId,
          subAccountId: data.subAccountId,
          householdViewType: data.householdViewType,
        },
        pagination: {
          offSet: data.offset,
          perPage,
          sortField: 'createdAt',
          sortDesc: false,
        },
      },
    },
  });
  const setAllTransfersData = (data: {
    fetchTransfers: {
      transfers: Transfer[],
      totalCount: number,
    }
  }): void => {
    setTransfersPageData({
      currentCount: transfersPageData.currentCount + data.fetchTransfers.transfers.length,
      totalCount: data.fetchTransfers.totalCount,
    });
    setTransactions((prev) => [...prev, ...data.fetchTransfers.transfers]);
    setLoadAllTransfers(false);
  };
  useEffect(() => {
    getTrasactions({
      accountId: props.accountId,
      userId: props.userId,
      goalId: props.goalId,
      subAccountId: props.subAccountId,
      householdViewType: props.householdViewType,
      offset: 0,
    }).then((result) => setAllTransfersData(result.data));
  }, [client, props.accountId, props.userId, props.goalId, props.subAccountId]);
  const handleTransactionScroll = async (e: any): Promise<void> => {
    if ((transfersPageData.currentCount < transfersPageData.totalCount) && !loadAllTransfers) {
      setLoadAllTransfers(true);
      const result = await getTrasactions({
        accountId: props.accountId,
        userId: props.userId,
        goalId: props.goalId,
        subAccountId: props.subAccountId,
        householdViewType: props.householdViewType,
        offset: transfersPageData.currentCount,
      });
      if (result.data) {
        setAllTransfersData(result.data);
      }
    }
  };
  const noTransactionMessage = (): JSX.Element => (
    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{ minHeight: '260px' }}>
      <Typography variant="bodySub2" color={colors.neutral30} style={{ maxWidth: '184px' }}>{t('transfer:noTransfers')}</Typography>
    </Box>
  );
  return (
    <Container sx={styles(colors).documentContainer} style={{ padding: '0px !important' }}>
      <Typography variant="heading2" sx={{ marginBottom: '8px' }}>{t('transfer:allTransfers.title')}</Typography>
      <Container className="home-page" sx={styles(colors).allTransferContainer} style={{ padding: '0px !important' }}>
        <TabContext value={value.toString()}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="dashboard tabs">
              <Tab
                id="tab-1"
                label={
                  (
                    <span>
                      {t('transfer:allTransfers.tabs.all')}
                    </span>
                  )
                }
                value="1"
              />
              <Tab
                id="tab-2"
                label={
                  (
                    <span>
                      {t('transfer:allTransfers.tabs.investments')}
                    </span>
                  )
                }
                value="2"
              />
              <Tab
                id="tab-3"
                label={
                  (
                    <span>
                      {t('transfer:allTransfers.tabs.withdrawals')}
                    </span>
                  )
                }
                value="3"
              />
            </TabList>
          </Box>
          <TabPanel id="panel-1" aria-labelledby="panel-1" value="1" sx={styles(colors).noPadding}>
            <OvPagination nextPage={(e) => handleTransactionScroll(e)} sx={style.tabPanels} loading={loadAllTransfers}>
              {transactions.length > 0 ? transactions.filter((i) => (i.amountCents !== 0)).map((item: Transfer) => (
                <TransferItem
                  key={Math.random()}
                  type={item.type}
                  amountCents={item.amountCents}
                  state={item.state}
                  createdAt={item.createdAt}
                  source={item.source}
                />
              )) : noTransactionMessage()}
            </OvPagination>
          </TabPanel>
          <TabPanel id="panel-2" aria-labelledby="panel-2" value="2" sx={style.tabPanels}>
            <OvPagination nextPage={(e) => handleTransactionScroll(e)} sx={style.tabPanels} loading={loadAllTransfers}>
              {transactions.length > 0 ? transactions.filter((i) => (i.amountCents !== 0)).map((item: Transfer) => (
                item.type === 'DEPOSIT' ? (
                  <TransferItem
                    key={Math.random()}
                    type={item.type}
                    amountCents={item.amountCents}
                    state={item.state}
                    createdAt={item.createdAt}
                    source={item.source}
                  />
                ) : null
              )) : noTransactionMessage()}
            </OvPagination>
          </TabPanel>
          <TabPanel id="panel-3" aria-labelledby="panel-3" value="3" sx={styles(colors).noPadding}>
            <OvPagination nextPage={(e) => handleTransactionScroll(e)} sx={style.tabPanels} loading={loadAllTransfers}>
              {transactions.length > 0 ? transactions.filter((i) => (i.amountCents !== 0)).map((item: Transfer) => (
                item.type !== 'DEPOSIT' ? (
                  <TransferItem
                    key={Math.random()}
                    type={item.type}
                    amountCents={item.amountCents}
                    state={item.state}
                    createdAt={item.createdAt}
                    source={item.source}
                  />
                ) : null
              )) : noTransactionMessage()}
            </OvPagination>
          </TabPanel>
        </TabContext>
      </Container>
    </Container>
  );
};
AllTransfers.defaultProps = {
  goalId: undefined,
  accountId: undefined,
  userId: undefined,
  subAccountId: undefined,
  householdViewType: undefined,
};

export default AllTransfers;
