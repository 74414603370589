import { gql } from '@apollo/client';

export const FETCH_AFFILIATES = gql`
query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        user {
          firstName
          lastName
          primaryEmail
        }
        affiliations {
          id
          type
          relation
          allocation
          signatureRequired
          user {
            id
            firstName
            lastName
            gender
            primaryEmail
            dateOfBirth
            affiliateOnly
            inProvinceSince
            physicalAddress {
              city
              province
              streetName
              postal
              unitNumber
              houseNumber
              neighborhood
              country
            }
          }
        }
      }
    }
  }
`;
