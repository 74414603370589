/* eslint-disable no-promise-executor-return */

import { useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { Box } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import WithStepWizard from '../common/wrappers/withStepWizard';
import YourAutoInvestment from './yourAutoInvestment';
import AddAutoInvestmentForm from './addAutoInvestmentForm';
import { FETCH_SCHEDULED_TRANSFERS } from './graphql';
import CloseButton from '../common/buttons/closeButton';
import { Colors, useContextTheme } from '../../providers/contextThemeProvider';

const YourAutoInvestmentStep = WithStepWizard(YourAutoInvestment);
const AddAutoInvestmentFormStep = WithStepWizard(AddAutoInvestmentForm);

const style = (colors: Colors) => ({
  backButton: {
    backgroundColor: colors.neutral90,
    borderRadius: '5px',
    height: '26px',
    width: '26px',
    padding: '0px',
    left: '30px',
  },
  padding24: {
    padding: '0 24px !important',
  },
  closeBox: {
    width: '26px',
    height: '26px',
    top: '-6px',
    marginTop: '18px',
    marginRight: '24px',
    marginBottom: '22px',
    cursor: 'pointer',
    background: colors.backButtonBackground,
    borderRadius: '3.42105px',
  },
  closeArea: {
    position: 'relative',
    top: '10px',
    height: '30px !important',
  },
});
interface ScheduledTransfer {
  amountCents: number
  frequency: string
  id: string,
  type: string,
  scheduledDate: string,
  state: string,
  bankAccount: {
    id: string,
  },
  subAccount: {
    id: string,
    theme: {
      key: string,
      translatedName: {
        en: string,
        fr: string,
      },
    },
    account: {
      id: string,
      type: string,
    },
  }
}
interface Props {
  goalId?: string,
  subAccountId?: string,
  accountId?: string,
  addAutoInvestment: boolean,
  onClose: () => void,
}

const CreateAutoInvestmentWizard = ({
  onClose,
  goalId,
  subAccountId,
  accountId,
  addAutoInvestment,
}: Props): JSX.Element => {
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const { colors } = useContextTheme();
  const [scheduledTransfer, setScheduledTransfer] = useState<ScheduledTransfer>();
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const [scheduledTransfers, setScheduledTransfers] = useState<ScheduledTransfer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [fetchScheduledTransferApi] = useLazyQuery(FETCH_SCHEDULED_TRANSFERS, {
    variables: {
      input: {
        filter: {
          goalId,
          subAccountId,
          accountId,
        },
        pagination: {},
      },
    },
    onCompleted: (e) => {
      setLoading(false);
      setScheduledTransfers([]);
      setScheduledTransfers((prevState) => [...prevState, ...e.fetchScheduledTransfers.scheduledTransfers]);
    },
  });

  const fetchAutoInvestment = async (): Promise<void> => {
    setLoading(true);
    fetchScheduledTransferApi().then();
  };

  const reFetchAutoInvestment = async (): Promise<void> => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    fetchScheduledTransferApi().then();
    setLoading(false);
  };

  const steps: JSX.Element[] = [
    <YourAutoInvestmentStep
      onEdit={setScheduledTransfer}
      stepName="your-auto-investment"
      key="your-auto-investment"
      goToNamedStep={() => 'add-auto-investment-form'}
      loading={loading}
      scheduledTransfers={scheduledTransfers}
      fetchAutoInvestment={fetchAutoInvestment}
    />,
    <AddAutoInvestmentFormStep
      editableScheduledTransfer={scheduledTransfer}
      key="add-auto-investment-form"
      stepName="add-auto-investment-form"
      fetchAutoInvestment={reFetchAutoInvestment}
      subAccountId={subAccountId}
      goalId={goalId}
    />,
  ];
  return (
    <StepWizard
      instance={setInstance}
      transitions={{}}
      isLazyMount
      className="addInstitutionStepWizard"
      initialStep={addAutoInvestment ? 2 : 1}
      nav={
        (
          <Box sx={style(colors).closeArea}>
            <CloseButton onClick={onClose} />
          </Box>
        )
      }
    >
      {steps}
    </StepWizard>
  );
};

CreateAutoInvestmentWizard.defaultProps = {
  goalId: undefined,
  subAccountId: undefined,
  accountId: undefined,
};

export default CreateAutoInvestmentWizard;
