import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import React from 'react';
import { Checkbox } from '@mui/material';
import { colors } from '../../../theme/colors';

interface Props {
    checked?: boolean,
    indeterminate?: boolean,
    onChange?: (checked: boolean) => void,
}

const SquareCheckbox = ({ ...props }: Props): JSX.Element => (
  <Checkbox
    sx={{
      '.MuiSvgIcon-root': {
        color: `${props.checked ? colors.radio : colors.neutral20} !important`,
      },
    }}
    data-testid="square-checkbox"
    checked={props.checked}
    indeterminate={props.indeterminate}
    checkedIcon={<CheckBoxRoundedIcon />}
    indeterminateIcon={<IndeterminateCheckBoxRoundedIcon />}
    onChange={() => {
      if (props.onChange) props.onChange(!props.checked);
    }}
  />
);

SquareCheckbox.defaultProps = {
  checked: false,
  indeterminate: false,
  onChange: undefined,
};

export default SquareCheckbox;
