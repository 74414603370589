import { Button, List, Typography } from '@mui/material';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';
import SimpleInput from '../common/inputs/simpleInput';
import ButtonListItem from '../common/lists/buttonListItem';
import { GoalTypeAttributes, GoalTypes } from './resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import { Goal } from '../../utils/commonGraphql';
import { isLoggedInVar, isOnboardingCompleteVar } from '../../utils/localVariables';

export interface GoalRef {
  type?: GoalTypes,
  name?: string,
  targetAmountCents?: number,
}

interface Props {
  goalTypes: GoalTypeAttributes[],
  setGoalRef: (att: GoalRef) => void,
  selectedGoalType?: GoalTypeAttributes,
  setSelectedGoalType: (att?: GoalTypeAttributes) => void,
  onContinue?: () => void,
  stepName: 'type' | 'secondaryType',
  goalCount?: number,
  currentGoal?: Goal | GoalRef,
}

const GoalType = ({
  goalTypes, setGoalRef, selectedGoalType, setSelectedGoalType, onContinue, stepName, goalCount, currentGoal,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'goal']);
  let title = t('goal:type.secondaryTypeTitle');
  if (stepName === 'type') {
    title = goalCount ? t('goal:type.primaryTypeNewGoalTitle') : t('goal:type.primaryTypeTitle');
  }
  const [displayNameInput, setDisplayNameInput] = useState(false);
  const [goalName, setGoalName] = useState(currentGoal?.name || '');
  const isMounted = useRef(false);

  const clearGoalName = (): void => {
    setGoalName('');
    setSelectedGoalType(undefined);
    setDisplayNameInput(false);
  };
  const confirmGoalName = (): void => {
    console.log({ event: 'GOAL_NAME_DEFINED', name: goalName });
    setGoalRef({ name: goalName });
    if (onContinue) onContinue();
  };

  const handleClick = async (item: GoalTypeAttributes): Promise<void> => {
    if (item.key === GoalTypes.OTHER) {
      setDisplayNameInput(true);
    }
    setGoalRef({
      type: item.key === GoalTypes.MAJOR_PURCHASE && stepName === 'type' ? currentGoal?.type : item.key,
      targetAmountCents: (currentGoal?.type === item.key)
        ? currentGoal.targetAmountCents || item.defaultTargetAmountCents : (item.defaultTargetAmountCents || currentGoal?.targetAmountCents),
      name: item.key === GoalTypes.OTHER ? goalName : item.description,
    });
    setSelectedGoalType(item);
    if (item.key !== GoalTypes.OTHER) {
      console.log({ event: 'GOAL_TYPE_SELECTED', type: item.key });
      if (isLoggedInVar() && !isOnboardingCompleteVar()) {
        sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectGoalType).then();
      } else {
        sendAnalyticsEvent(ovAnalyticsEvents.addGoalGoalTypeSelect).then();
      }
      if (selectedGoalType?.key === item.key && onContinue) {
        onContinue();
      }
    }
  };

  useEffect(() => {
    if (selectedGoalType && isMounted.current) {
      if (selectedGoalType.key !== GoalTypes.OTHER && onContinue) {
        onContinue();
      }
    } else {
      isMounted.current = true;
    }
  }, [selectedGoalType, onContinue]);
  const getSecondaryTypes = (): string[] => find(goalTypes, { key: GoalTypes.MAJOR_PURCHASE })?.secondaryGoalTypes?.map((o) => o.key) || [];
  const getSelectedOptions = (item: GoalTypeAttributes): boolean => {
    if (!currentGoal?.type) return false;
    if (stepName === 'type' && getSecondaryTypes().includes(currentGoal.type) && item.key === GoalTypes.MAJOR_PURCHASE) return true;
    return item.key === currentGoal.type;
  };
  return (
    <OvForm onSubmit={confirmGoalName} hasButton={false} loading={false}>
      <Typography variant="heading2" data-testid="title">{title}</Typography>
      <List className="goal-list">
        {goalTypes.map((item: GoalTypeAttributes) => (
          <ButtonListItem
            prefixIcon={item.iconPath}
            key={item.key}
            text={item.description}
            onClick={() => handleClick(item)}
            isSelected={getSelectedOptions(item)}
          />
        ))}
      </List>
      {displayNameInput && (
        <>
          <SimpleInput
            label={t('goal:type.nameInputLabel')}
            value={goalName}
            required
            style={{ marginBottom: 16 }}
            onChange={(event) => setGoalName(event.target.value)}
            testId="goal-name-input"
            onClear={clearGoalName}
          />
          <Button variant="primary-large" disabled={goalName?.trim().length === 0} onClick={confirmGoalName} data-testid="continue-button">{t('base:button.continue')}</Button>
        </>
      )}
    </OvForm>
  );
};

GoalType.defaultProps = {
  selectedGoalType: undefined,
  onContinue: undefined,
  goalCount: undefined,
  currentGoal: undefined,
};

export default GoalType;
