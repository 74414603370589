import intersection from 'lodash/intersection';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import isNil from 'lodash/isNil';
import { Box } from '@mui/material';
import WithStepWizard from '../../components/common/wrappers/withStepWizard';
import Agreement from '../../components/documents/incompleteAgreements';
import PersonaWizard from '../../components/persona/personalWizard';
import { availableFeatureFlagsVar, isOnboardingCompleteVar } from '../../utils/localVariables';
import { User } from '../../utils/commonGraphql';
import { isOnboardingComplete } from '../index';
import { ForeignTaxInformation } from '../../components/user/assumptions/foreignTax';
import { PhysicalAddress } from '../../components/user/address/resources';
import TrackingBar from '../../components/common/headers/trackingBar';
import { UserContext, UserContextProps } from '../../providers/userContextProvider';
import GetToKnowYou from '../../components/user/getToKnowYou';
import AboutYouWizard from '../../components/user/getToKnowYou/AboutYouWizard';
import HowToInvestWizard from '../../components/user/getToKnowYou/HowToInvestWizard';
import YourGoalsWizard from '../../components/user/getToKnowYou/YourGoalsWizard';
import UpdateMigratedGoalsWizard from '../../components/goal/UpdateMigratedGoalsWizardStep';
import { FeatureFlagTypes, isFeatureEnabled } from '../../components/account/resources';
import { setToLocalStorage } from '../../utils/localStorage';
import WaitingForAdvisorApproval from '../../components/documents/waitingForAdvisorApproval';
import { useContextTheme } from '../../providers/contextThemeProvider';

const AboutYouWizardStep = WithStepWizard(AboutYouWizard);
const GetToKnowYouStep = WithStepWizard(GetToKnowYou);
const HowToInvestWizardStep = WithStepWizard(HowToInvestWizard);
const YourGoalsWizardStep = WithStepWizard(YourGoalsWizard);
const UpdateMigratedGoalsWizardStep = WithStepWizard(UpdateMigratedGoalsWizard);
const AgreementStep = WithStepWizard(Agreement);
const NoAgreementStep = WithStepWizard(WaitingForAdvisorApproval);
const PersonaWizardStep = WithStepWizard(PersonaWizard);
export const aboutYouSteps = ['primaryEmail', 'firstName', 'lastName', 'physicalAddress', 'dateOfBirth', 'employmentStatus', 'companyType', 'jobTitle', 'studentAreaOfStudy', 'sin', 'citizenships', 'isMemberOfIiroc', 'isOfficerOfPublicCompany', 'isOwnerOfPublicCompany', 'politicallyExposedDomesticPerson', 'headOfInternationalOrganization', 'politicallyExposedForeignPerson', 'closeAssociateOfPEP'];
export const howToInvestSteps = ['riskQuestion2', 'riskQuestion1', 'investmentKnowledge', 'maritalStatus', 'totalDebtCents', 'annualDebtPaymentsCents', 'annualIncomeCents', 'financialLiquidAssetsCents', 'financialFixedAssetsCents'];

export interface UserOnboardingState extends User {
  employmentStatus?: string,
  companyType?: string,
  jobTitle?: string,
  studentAreaOfStudy?: string,
  physicalAddress?: Partial<PhysicalAddress>,
  sin?: string,
  dateOfBirth?: Date,
  powerOfAttorneyGranted?: boolean,
  isMemberOfIiroc?: boolean,
  isOfficerOfPublicCompany?: boolean,
  isOwnerOfPublicCompany?: boolean,
  politicallyExposedDomesticPerson?: boolean,
  headOfInternationalOrganization?: boolean,
  politicallyExposedForeignPerson?: boolean,
  closeAssociateOfPEP?: boolean,
  citizenships?: string[],
  foreignTaxInformation?: ForeignTaxInformation[],
  annualIncomeCents?: number,
  financialLiquidAssetsCents?: number,
  financialFixedAssetsCents?: number,
  hasDebt?: boolean,
  sourceOfWealth?: string[],
  sourceOfFunds?: string[],
  employmentSituation?: string,
  employmentSituationOtherDescription?: string,
  employerName?: string,
  uniqueCircumstances?: string,
  spouseFirstName?: string,
  spouseMiddleName?: string,
  spouseLastName?: string,
  spouseCompanyType?: string,
  spouseJobTitle?: string,
  spouseDateOfBirth?: Date,
  spouseSin?: string,
  spouseEmail?: string,
  spouseAddress?: Partial<PhysicalAddress>,
  spouseEmploymentType?: string,
  allLatestFormAgreements?: { id: string }[]
}
interface Props {
  userAttributes: UserOnboardingState,
}

const OnboardingPage = ({ userAttributes }: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const { t } = useTranslation(['user']);
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const [user, updateUser] = useState<UserOnboardingState>(userAttributes);
  const { userContext } = useContext(UserContext);
  const updateUserState = (att: Partial<typeof user>): void => updateUser({ ...user, ...att });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const useUpdateMigratedGoalsStep: boolean = !!userContext.bulkAccountOpening && !userContext.lastSuitabilityReviewAt;
  setToLocalStorage('from-onboarding', 'true');
  const goalAndSubAccountCreationEnabled = isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS)
    && isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_SUB_ACCOUNTS);

  const shouldHideAgreement = (): boolean => availableFeatureFlagsVar().includes('ADVISOR_APPROVAL_REQUIRED_FOR_CLIENTS_TO_SIGN_AGREEMENTS') && user.readyToSignAgreement === false;

  useEffect(() => {
    if (isOnboardingComplete({
      incompleteFields: user.incompleteFields,
      accounts: userContext.accounts ?? [],
      allIncompleteFormAgreements: userContext.allIncompleteFormAgreements ?? [],
      goals: userContext.goals ?? [],
      subAccounts: userContext.subAccounts ?? [],
      bulkAccountOpening: userContext.bulkAccountOpening,
      lastSuitabilityReviewAt: userContext.lastSuitabilityReviewAt,
      iDCheckCompleted: user.iDCheckCompleted,
    })) {
      console.log({ event: 'USER_ONBOARDING_COMPLETED' });
      isOnboardingCompleteVar(true);
    }
  }, [
    user.incompleteFields,
    userContext.goals,
    userContext.subAccounts,
    user.iDCheckCompleted,
    userContext.allIncompleteFormAgreements,
    userContext.accounts,
    userContext.bulkAccountOpening,
    userContext.lastSuitabilityReviewAt,
  ]);
  const goToNamedStep = (stepName: string): string => (stepName);
  const setInitialStep = (attributes: UserOnboardingState): number => {
    const aboutYouMissingSteps = intersection(attributes.incompleteFields, aboutYouSteps).length;
    if (
      // 'companyType', 'jobTitle', and 'studentAreaOfStudy' are not listed in incompleteFields until employmentStatus is defined
      (aboutYouMissingSteps === aboutYouSteps.length - 3)
      // 'primaryEmail' is defined during signUp for referral partners and OV clients
      || (aboutYouMissingSteps === aboutYouSteps.length - 4 && !attributes.incompleteFields.includes('primaryEmail'))
    ) {
      return 1;
    }
    if (intersection(attributes.incompleteFields, aboutYouSteps).length > 0) {
      return 2;
    }
    if (intersection(attributes.incompleteFields, howToInvestSteps).length === howToInvestSteps.length) {
      return 3;
    }
    if (intersection(attributes.incompleteFields, howToInvestSteps).length > 0) {
      return 4;
    }
    if ((attributes.incompleteFields.length === 0 && (attributes.subAccounts.length === 0 && attributes.goals.length === 0)
      && isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS))
      || useUpdateMigratedGoalsStep) {
      return 5;
    }
    if (userAttributes.subAccounts.length === 0 && goalAndSubAccountCreationEnabled) {
      return 6;
    }
    if (!attributes.iDCheckCompleted && isFeatureEnabled(FeatureFlagTypes.ID_VERIFICATION)) {
      return isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS) ? 7 : 5;
    }
    if (((userContext?.allIncompleteFormAgreements?.length || 0) > 0) || shouldHideAgreement()) {
      let stepToReturn = isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS) ? 9 : 7;
      if (!isFeatureEnabled(FeatureFlagTypes.ID_VERIFICATION)) stepToReturn -= 2;
      return stepToReturn;
    }
    return 1;
  };
  const removeProgressBar = (attributes: UserOnboardingState): boolean => {
    if (intersection(attributes.incompleteFields, aboutYouSteps).length === aboutYouSteps.length) {
      return false;
    }
    if (intersection(attributes.incompleteFields, howToInvestSteps).length >= 9) {
      return false;
    }
    if (attributes.incompleteFields.length === 0 && (attributes.subAccounts.length === 0 && attributes.goals.length === 0)) {
      return false;
    }
    if (attributes.subAccounts.length > 0 && !attributes.iDCheckCompleted) {
      return false;
    }
    return true;
  };
  const defineCompletedSteps = (attributes: UserOnboardingState, context: UserContextProps): string[] => {
    const completedStages = [];
    if (goalAndSubAccountCreationEnabled && (context.subAccounts && context.subAccounts.length > 0)
      && ((
        !userContext.bulkAccountOpening
        && !attributes.iDCheckCompleted
        && isNil(context.subAccounts.find((s) => (s.allIncompleteFormAgreements?.length || 0) > 0))) || (
        userContext.bulkAccountOpening && !!userContext.lastSuitabilityReviewAt
      ))
    ) {
      completedStages.push('YOUR_GOALS');
    }
    if (!goalAndSubAccountCreationEnabled && ((context.goals?.length || 0) > 0) && !isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_SUB_ACCOUNTS)) {
      completedStages.push('YOUR_GOALS');
    }
    if (!goalAndSubAccountCreationEnabled && (context.allIncompleteFormAgreements ?? []).length === 0) {
      if (isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS)) {
        if ((context.goals?.length || 0) > 0) completedStages.push('YOUR_GOALS');
      } else {
        completedStages.push('YOUR_GOALS');
      }
    }
    if (intersection(attributes.incompleteFields, howToInvestSteps).length === 0) {
      completedStages.push('HOW_TO_INVEST');
    }
    if (intersection(attributes.incompleteFields, aboutYouSteps).length === 0) {
      completedStages.push('ABOUT_YOU');
    }
    return completedStages;
  };
  const buildStepComponents = (): JSX.Element[] => {
    const steps: JSX.Element[] = [];
    steps.push(<GetToKnowYouStep key="GetToKnowYouSTEP1" completedSteps={defineCompletedSteps(user, userContext)} goToNamedStep={() => goToNamedStep} />);
    steps.push(<AboutYouWizardStep stepProps={{ absoluteTotalSteps: 13 }} stepName="AboutYouWizardStep" key="AboutYouWizard" stepTitle={t('user:stepTitles.aboutYou')} user={user} updateUserState={updateUserState} disableTracker />);
    steps.push(<GetToKnowYouStep stepName="GetToKnowYouSTEP2" key="GetToKnowYouSTEP2" completedSteps={defineCompletedSteps(user, userContext)} goToNamedStep={goToNamedStep} />);
    steps.push(<HowToInvestWizardStep stepName="HowToInvestWizardStep" key="HowToInvestWizard" stepTitle={t('user:stepTitles.howToInvest')} user={user} updateUserState={updateUserState} disableTracker />);
    if (isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS)) {
      steps.push(<GetToKnowYouStep key="GetToKnowYouSTEP2" completedSteps={defineCompletedSteps(user, userContext)} goToNamedStep={goToNamedStep} />);
      steps.push(userContext.bulkAccountOpening ? <UpdateMigratedGoalsWizardStep goals={userContext.goals ?? []} stepName="YourGoalsWizardStep" key="YourGoalsWizard" stepTitle={t('user:stepTitles.yourGoals')} disableTracker /> : <YourGoalsWizardStep shouldHideAgreement={shouldHideAgreement()} stepName="YourGoalsWizardStep" key="YourGoalsWizard" stepTitle={t('user:stepTitles.yourGoals')} disableTracker />);
    }
    if (isFeatureEnabled(FeatureFlagTypes.ID_VERIFICATION)) {
      steps.push(<GetToKnowYouStep disableTracker stepName="GetToKnowYouSTEP4" key="GetToKnowYouSTEP4" completedSteps={defineCompletedSteps(user, userContext)} goToNamedStep={() => goToNamedStep} />);
      steps.push(<PersonaWizardStep stepName="PersonaWizardStep" key="Persona" user={user} updateUserState={updateUserState} stepTitle={t('user:stepTitles.idCheck')} />);
    }
    steps.push(
      ...(shouldHideAgreement() ? [<NoAgreementStep key="noAgreement" stepName="Agreement" disableBackControl />] : [<AgreementStep key="Agreement" stepName="Agreement" stepTitle={t('user:stepTitles.agreements')} disableBackControl />]),
    );
    return steps;
  };
  return (
    <Box
      sx={{
        background: colors.baseComponentBackground,
        borderRadius: '8px',
        border: `1px solid ${colors.portfolioCardBorder}`,
        padding: '16px',
        overflowX: 'hidden',
      }}
    >
      <StepWizard
        instance={setInstance}
        isLazyMount
        initialStep={setInitialStep(userAttributes)}
        className="ov-step-wizard"
        transitions={{}}
        nav={(
          <TrackingBar
            steps={buildStepComponents()}
            showStepper={removeProgressBar(userAttributes)}
          />
        )}
      >
        {buildStepComponents()}
      </StepWizard>
    </Box>
  );
};

export default OnboardingPage;
