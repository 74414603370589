/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext, useEffect, useMemo, useState,
} from 'react';
import {
  Goal,
  Account,
  SubAccount,
  Organization,
  ClientGroup,
} from '../utils/commonGraphql';
import { getFromSessionStorage, setToSessionStorage } from '../utils/localStorage';
import { accessTokenVar, isLoggedInVar } from '../utils/localVariables';
import { AllIncompleteFormAgreement } from '../components/documents/incompleteAgreements';
import { getOrganizationIdFromContext } from '../utils/platformUtils';

interface OrgSettings {
  blockClientSignUp?: boolean,
  canadianTaxResidentOnly?: boolean,
  showEmploymentSituation?: boolean,
  showSourceOfWealth?: boolean,
  showEmployerName?: boolean,
  showEmployerAddress?: boolean,
  showSourceOfFunds?: boolean,
  showUniqueCircumstance?: boolean,
  showSpouseInfo?: boolean,
  showTrustedContactperson?: boolean,
  specificText?: {
    orgCode?: string,
    investmentKnowledgeLevels?: boolean,
    riskQuestion2?: boolean,
  },
}
export interface UserContextProps {
  organizationId?: string,
  organizationKey?: string,
  useClientOrgAuth0?: boolean,
  organizationSettings?: OrgSettings,
  partnerId?: string,
  partnerUserId?: string,
  userId?: string,
  userAccessToken?: string,
  primaryEmail?: string,
  gender?: string,
  origin?: string,
  goals?: Goal[],
  accounts?: Account[],
  subAccounts?: SubAccount[],
  households?: ClientGroup[],
  path?: string,
  pageName?: string,
  allIncompleteFormAgreements?: AllIncompleteFormAgreement[],
  lastSuitabilityReviewAt?: string,
  hasCheckedPolicyAndCondition?: boolean,
  availableFeatureFlags?: string[],
  bulkAccountOpening?: boolean,
  embeddedExperienceTheme?: any,
  dateOfBirth?: Date,
  maritalStatus?: string
  supportUrl?: string,
  organization?: Organization,
  checkRrifInformationStep?: {
    accountId: string | undefined,
    open: boolean,
    refreshComponent?: boolean,
  },
}

export type SetUserContextType = React.Dispatch<React.SetStateAction<UserContextProps>>;
export type UserContextType = {
  setUserContext: SetUserContextType,
  userContext: UserContextProps,
}

const UserContext = createContext<UserContextType>({ userContext: {}, setUserContext: () => { } });
const data = (window as any).__partnerUserContext || {};
const INITIAL_STATE = data;
INITIAL_STATE.path = getFromSessionStorage('path') ?? '/';

if (!INITIAL_STATE.organizationId) {
  const organizationContext = (window as any).__organizationContext || {};
  if (organizationContext.organizationIdVariable) {
    INITIAL_STATE.organizationId = getOrganizationIdFromContext();
    INITIAL_STATE.organizationKey = organizationContext.organizationKey;
    INITIAL_STATE.organizationSettings = organizationContext.organizationSettings;
  }
}

const UserContextProvider: React.FC = ({ children }) => {
  const [userContext, setUserContext] = useState<UserContextProps>(INITIAL_STATE);
  useEffect(() => {
    if (INITIAL_STATE.userAccessToken) {
      setToSessionStorage('token', INITIAL_STATE.userAccessToken);
      accessTokenVar(INITIAL_STATE.userAccessToken);
      isLoggedInVar(true);
    }
  }, []);

  const userContextValue = useMemo(() => ({ userContext, setUserContext }), [userContext]);

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};
const UserContextConsumer = UserContext.Consumer;

export { UserContext, UserContextProvider, UserContextConsumer };

export default UserContextProvider;
