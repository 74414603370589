/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useMutation } from '@apollo/client';
import { Fields, InquiryError } from 'persona/dist/lib/interfaces';
import WithStepWizard, { StepProps } from '../common/wrappers/withStepWizard';
import Information from './information';
import Error from './error';
import { UPDATE_USER } from '../user/graphql';
import { isIdVerifiedVar } from '../../utils/localVariables';

const InformationStep = WithStepWizard(Information);
const ErrorStep = WithStepWizard(Error);

export interface UserState {
  firstName?: string,
  middleName?: string,
  lastName?: string,
  primaryEmail?: string,
  dateOfBirth?: Date,
  iDCheckCompleted?: boolean,
}

interface Props {
  user?: UserState,
  updateUserState?: (att: UserState) => void,
  onContinue?: () => void,
  stepProps?: StepProps,
  stepTitle?: string,
  onCompleted?: () => void,
}

const PersonaWizard = ({
  onCompleted, user, updateUserState, onContinue, ...props
} : Props): JSX.Element => {
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });

  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });

  const [personaInquiryId, setPersonaInquiryId] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const updateIdCheckOnCompleted = (data: { updateUser: { user: { iDCheckCompleted: boolean, iDVerified: boolean } } }): void => {
    console.log({ event: 'ID_CHECK_COMPLETED', iDCheckCompleted: data.updateUser.user.iDCheckCompleted });
    isIdVerifiedVar(data.updateUser.user.iDVerified ?? false);
    if (updateUserState) {
      updateUserState({ iDCheckCompleted: data.updateUser.user.iDCheckCompleted });
    }
    if (onContinue) onContinue();
    if (onCompleted) onCompleted();
  };

  const [updateIdCheck] = useMutation(UPDATE_USER, {
    variables: {
      input: { iDCheckCompleted: true, iDCheckRetrigger: false },
    },
    onCompleted: updateIdCheckOnCompleted,
  });

  const [updateIdCheckWithComplianceReview] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        iDCheckCompleted: true, iDCheckRetrigger: true, complianceState: 'NEEDS_REVIEW', complianceIssueSource: `"Persona was not initialized on their device -- Persona sdk logs: ${personaInquiryId}"`,
      },
    },
    onCompleted: updateIdCheckOnCompleted,
  });

  const onComplete = ({ inquiryId, status }: { inquiryId: string; status: string; fields: Fields; }): void => {
    setPersonaInquiryId(inquiryId);
    if (status === 'completed' || status === 'pending') {
      updateIdCheck().then();
    } else {
      updateIdCheckWithComplianceReview().then();
    }
  };

  const onCancel = (): void => {
    console.log({ event: 'ID_CHECK_CANCELED' });
  };

  const onError = (error: InquiryError): void => {
    setPersonaInquiryId(error.code);
    updateIdCheckWithComplianceReview().then();
  };
  return (
    <StepWizard transitions={{}} instance={setInstance} isLazyMount>
      <InformationStep
        stepName="information"
        onCancel={onCancel}
        onComplete={onComplete}
        onError={onError}
        user={user!}
        loading={loading}
        updateLoading={setLoading}
        onBypass={() => updateIdCheckWithComplianceReview({
          variables: {
            input: {
              iDCheckCompleted: true,
              iDCheckRetrigger: true,
              complianceState: 'NEEDS_REVIEW',
              complianceIssueSource: 'User opted out of ID verification',
            },
          },
        }).then()}
        {...props}
      />
      <ErrorStep stepName="error" {...props} />
    </StepWizard>
  );
};

PersonaWizard.defaultProps = {
  user: undefined,
  updateUserState: undefined,
  onContinue: undefined,
  stepProps: undefined,
  stepTitle: undefined,
  onCompleted: undefined,
};

export default PersonaWizard;
