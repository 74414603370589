import { MouseEventHandler } from 'react';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import Toggle from '../controllers/toggle';

interface ToggleListItem {
  text: string,
  checked: boolean,
  onChange: (checked: boolean) => void,
  hasLabel?: boolean,
  spaceAroundContent?: number,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  infoObject?: {
    icon: JSX.Element,
  },
  editButton?: {
    showEditButton: boolean,
    buttonText: string,
    onClick?: () => void,
  },
}

const ToggleListItem = ({ ...props }: ToggleListItem): JSX.Element => (
  <Box display="flex" flexDirection="column">
    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: props.spaceAroundContent }}>
      <Box>
        <Typography variant="bodySub2" sx={{ marginRight: '15px', cursor: 'pointer' }} onClick={props.onClick}>
          { props.text ?? 'Empty Text' }
          {props.infoObject && props.infoObject.icon}
        </Typography>
        {props.editButton?.showEditButton && (
          /* eslint-disable-next-line */
          <Link component="button" onClick={props.editButton.onClick}>{props.editButton.buttonText}</Link>
        )}
      </Box>
      <Toggle checked={props.checked} onChange={props.onChange} hasLabel={props.hasLabel} invertTextColor />
    </Box>
    <Divider style={{ marginTop: props.spaceAroundContent }} />
  </Box>
);

ToggleListItem.defaultProps = {
  hasLabel: false,
  spaceAroundContent: 17,
  onClick: undefined,
  infoObject: undefined,
  editButton: undefined,
};

export default ToggleListItem;
