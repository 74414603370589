import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { TFunction } from 'react-i18next';
import { colors } from '../../../theme/colors';
import AlertIcon from '../../../assets/images/filled/alert.svg';
import { Item, styles } from '../../../pages/subAccountDetails/styles';
import { AffiliationTypes } from '../../account/resources';

interface AddAffiliateCardListItemProps {
  t: TFunction<('user' | 'base' | 'account')[]>
  type: AffiliationTypes;
  createAffiliate: () => void;
}

const AddAffiliateCardListItem = ({ t, type, createAffiliate }: AddAffiliateCardListItemProps): JSX.Element => (
  <Box key={type} sx={styles.detailWrapper}>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Box sx={{
          margin: '10px 0px',
          marginLeft: '24px',
        }}>
          <Box display="flex" alignItems="center" sx={styles.detailBox} mr="24px">
            <Typography variant="captionSectionerBold" color={colors.neutral20}>{t(`account:affiliates.types.${type}`)}</Typography>
          </Box>
        </Box>
        <Item
          sx={styles.leftCage}
          style={{
            padding: '0px !important', margin: '24px', marginTop: '0px', justifyContent: 'center',
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ margin: '24px', justifyContent: 'center' }}>
              <img src={AlertIcon} alt="portfolio-icon" />
              <Typography variant="bodyMain1" data-testid="no-linked-affiliates" color={colors.secondary} style={{ ...styles.affiliateText, justifyContent: 'center' }}>{t('account:affiliates.noLinkedAffiliate', { type: t(`account:affiliates.noLinkedAffiliateType.${type}`) })}</Typography>
              <Button
                variant="primary-small"
                onClick={createAffiliate}
              >
                {t(`account:affiliates.button.${type}`)}
              </Button>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  </Box>
);

export default AddAffiliateCardListItem;
