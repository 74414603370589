import {
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useState, FormEvent } from 'react';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { UPDATE_USER } from '../graphql';
import SimpleInput from '../../common/inputs/simpleInput';
import {
  AddressCountries, AddressProvinces, countries, CountryList, ProvinceList, provinces,
} from './resources';
import { UserState } from './updatePhysicalAddressWizard';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';

export interface ValidateState {
  houseNumberIsValid?: boolean,
  streetNameIsValid?: boolean,
  cityIsValid?: boolean,
  unitNumberIsValid: boolean,
  postalIsValid?: boolean,
  countryIsValid?: boolean,
  provinceIsValid?: boolean,
}
interface Props {
  user: UserState,
  title?: string,
  subtitle?: string,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  shouldSaveUpdate?: boolean,
  setAddressState?: (att: { address: string, error: string, open: boolean }) => void,
  forceLoading?: boolean,
  analyticsEvent?: string,
  updateEmployerAddress?: boolean,
}

const UserAddress = ({
  user, updateUserState, shouldSaveUpdate = true, setAddressState, forceLoading, analyticsEvent, updateEmployerAddress, ...props
}: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['base', 'user']);
  const [validState, setValidState] = useState<ValidateState>({
    streetNameIsValid: true,
    houseNumberIsValid: true,
    cityIsValid: true,
    unitNumberIsValid: false,
    postalIsValid: true,
    countryIsValid: true,
    provinceIsValid: true,
  });
  const [state, updateState] = useState({
    streetName: user?.physicalAddress?.streetName,
    houseNumber: user?.physicalAddress?.houseNumber,
    unitNumber: user?.physicalAddress?.unitNumber,
    city: user?.physicalAddress?.city,
    province: user?.physicalAddress?.province || AddressProvinces.AB,
    country: user?.physicalAddress?.country || AddressCountries.CA,
    postal: user?.physicalAddress?.postal,
  });
  const confirmAddressUpdateCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'PHYSICAL_ADDRESS_UPDATED', PhysicalAddress: state });
    updateUserState({ ...user, physicalAddress: state, incompleteFields: data.updateUser.user.incompleteFields });
    setLoading(false);
    if (props.onContinue) props.onContinue();
  };

  const [updatePhysicalAddress] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        physicalAddress: updateEmployerAddress ? undefined : state,
        employerCompanyAddress: updateEmployerAddress ? state : undefined,
      },
    },
    onCompleted: confirmAddressUpdateCompleted,
    onError: () => setLoading(false),
  });
  const checkFieldsValidity = async (): Promise<boolean> => {
    const streeNumberValidity = await yup.string().required().isValid(state.houseNumber);
    setValidState({ ...validState, houseNumberIsValid: streeNumberValidity });
    const streetNameValidity = await yup.string().required().isValid(state.streetName);
    setValidState({ ...validState, streetNameIsValid: streetNameValidity });
    const cityValidity = await yup.string().required().isValid(state.city);
    setValidState({ ...validState, cityIsValid: cityValidity });
    const postalValidity = await yup.string().required().isValid(state.postal);
    setValidState({ ...validState, postalIsValid: postalValidity });
    const countryValidity = await yup.string().required().isValid(state.country);
    setValidState({ ...validState, countryIsValid: countryValidity });
    return streetNameValidity && streeNumberValidity && cityValidity && postalValidity && countryValidity;
  };
  const clearUnitNumber = (): void => {
    updateState({ ...state, unitNumber: '' });
  };
  const clearStreetName = (): void => {
    updateState({ ...state, streetName: '' });
    setValidState({ ...validState, streetNameIsValid: true });
  };
  const clearhouseNumber = (): void => {
    updateState({ ...state, houseNumber: '' });
    setValidState({ ...validState, houseNumberIsValid: true });
  };
  const clearCity = (): void => {
    updateState({ ...state, city: '' });
    setValidState({ ...validState, cityIsValid: true });
  };
  const clearPostal = (): void => {
    updateState({ ...state, postal: '' });
    setValidState({ ...validState, postalIsValid: true });
  };

  const checkForCountryExceptions = (country: string): boolean => {
    if (country && country.toLocaleLowerCase() !== 'ca') {
      console.log({ event: 'USER_FROM_DIFFERENT_COUNTRY' });
      if (setAddressState) {
        setAddressState({ address: '', error: 'USER_FROM_DIFFERENT_COUNTRY', open: true });
      }
      return true;
    }
    return false;
  };

  const onContinueClick = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    if (checkForCountryExceptions(state.country)) return;
    sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.onBoardingEnterAddressContinue).then();
    if (!await checkFieldsValidity()) return;
    if (shouldSaveUpdate) {
      setLoading(true);
      updatePhysicalAddress().then();
    } else if (props.onContinue) {
      updateUserState({ ...user, physicalAddress: state });
      props.onContinue();
    }
  };

  return (
    <OvForm onSubmit={onContinueClick} loading={loading || forceLoading}>
      <Typography variant="heading2">{ props.title ?? t('user:address.title')}</Typography>
      <Typography variant="paragraph3">{ props.subtitle ?? t('user:address.subTitle')}</Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <SimpleInput
            testId="unitnumber-input"
            label={t('user:address.labels.unitNumber')}
            value={state?.unitNumber || ''}
            style={{ marginBottom: 16 }}
            onChange={(event) => updateState({ ...state, unitNumber: event.target.value })}
            onClear={clearUnitNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <SimpleInput
            testId="streetnumber-input"
            label={t('user:address.labels.streetNumber')}
            value={state?.houseNumber || ''}
            style={{ marginBottom: 16 }}
            required
            onChange={(event) => updateState({ ...state, houseNumber: event.target.value })}
            onClear={clearhouseNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <SimpleInput
            testId="streetname-input"
            label={t('user:address.labels.streetName')}
            value={state?.streetName || ''}
            style={{ marginBottom: 16 }}
            required
            onChange={(event) => updateState({ ...state, streetName: event.target.value })}
            onClear={clearStreetName}
          />
        </Grid>
        <Grid item xs={6}>
          <SimpleInput
            testId="city-input"
            label={t('user:address.labels.city')}
            value={state?.city || ''}
            style={{ marginBottom: 16 }}
            required
            onChange={(event) => updateState({ ...state, city: event.target.value })}
            onClear={clearCity}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="standard" sx={{ m: 1, width: '100%', margin: 0 }}>
            <InputLabel id="demo-simple-select-label">{t('user:address.labels.province')}</InputLabel>
            <Select
              value={state?.province || ''}
              onChange={(event) => { updateState({ ...state, province: event.target.value as AddressProvinces }); }}
              label={t('user:address.labels.province')}
              style={{ marginBottom: 16 }}
              fullWidth
            >
              {provinces.map((item: ProvinceList) => (
                <MenuItem key={item.key} selected={state?.province === item.key} value={item.key}>{item.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <SimpleInput
            testId="postalcode-input"
            label={t('user:address.labels.postalCode')}
            value={state?.postal || ''}
            style={{ marginBottom: 16 }}
            required
            onChange={(event) => updateState({ ...state, postal: event.target.value })}
            onClear={clearPostal}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="standard" sx={{ width: '100%', margin: 0 }}>
            <InputLabel id="demo-simple-select-label">{t('user:address.labels.country')}</InputLabel>
            <Select
              value={state?.country || ''}
              onChange={(event) => { updateState({ ...state, country: event.target.value as AddressCountries }); }}
              label={t('user:address.labels.country')}
              fullWidth
              style={{ marginBottom: 16 }}
            >
              {countries.map((item: CountryList) => (
                <MenuItem key={item.key} selected={state?.country === item.key} value={item.key}>{item.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </OvForm>
  );
};

UserAddress.defaultProps = {
  title: undefined,
  subtitle: undefined,
  onContinue: undefined,
  shouldSaveUpdate: true,
  setAddressState: undefined,
  forceLoading: false,
  analyticsEvent: undefined,
  updateEmployerAddress: false,
};

export default UserAddress;
