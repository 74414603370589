import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import * as React from 'react';
import fillFormImage from '../../assets/images/fill-form.svg';
import OvForm from '../common/wrappers/ovForm';

export interface Props {
  goToNamedStep?: (step: string) => void,
  onInformationUpToDate?: () => void,
}

const StartCheckOne = ({ goToNamedStep, onInformationUpToDate }: Props): JSX.Element => {
  const { t } = useTranslation(['suitabilityReview']);
  const continueToNextStep = (): void => {
    if (goToNamedStep) {
      goToNamedStep('personalDetails');
    }
  };
  return (
    <OvForm
      hasButton
      buttonText={t('suitabilityReview:startCheckOne.prefixButton')}
      onSubmit={continueToNextStep}
      suffixButton={{
        onClick: () => {
          if (onInformationUpToDate) {
            onInformationUpToDate();
          }
        },
        text: t('suitabilityReview:startCheckOne.suffixButton'),
        variant: 'secondary-large',
        dataTestId: 'close-button',
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" style={{ marginBottom: '32px' }}>
        <img src={fillFormImage} alt="timer" />
        <Typography variant="headingMain" textAlign="center" style={{ marginBottom: 0 }}>{t('suitabilityReview:startCheckOne.title')}</Typography>
        <Typography variant="paragraphMain" style={{ marginBottom: 0, textAlign: 'center' }}>
          <Markup content={t('suitabilityReview:startCheckOne.body')} />
        </Typography>
      </Box>
    </OvForm>
  );
};

StartCheckOne.defaultProps = {
  goToNamedStep: undefined,
  onInformationUpToDate: undefined,
};

export default StartCheckOne;
