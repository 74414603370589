import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import securityCheckImage from '../../../assets/images/security-check.svg';
import OvForm from '../wrappers/ovForm';
import { removeFromLocalStorage } from '../../../utils/localStorage';
import FeedbackModal from '../wrappers/modals/ovFeedbackModal';

// const style = {
//   content: {
//     // boxShadow: '0px 8px 16px rgba(102, 115, 128, 0.32)',
//     // borderRadius: '6px 6px 0px 0px',
//     // background: colors.ovWhite,
//     // width: '352px',
//     // display: 'flex',
//     // flexDirection: 'column',
//     // justifyContent: 'space-between',
//     // height: '336px',
//     // padding: '18.5px 18.5px 0px 18.5px',
//     // boxSizing: 'border-box',
//   },
// };

export interface SessionTimeOutModalProps {
  open: boolean,
  onClose: () => void,
}

const SessionTimeoutLayoutModal = ({ onClose, open }: SessionTimeOutModalProps): JSX.Element => {
  const { t } = useTranslation(['base']);
  const goBackToLogin = (): void => {
    removeFromLocalStorage('inactivity');
    onClose();
  };
  return (
    <FeedbackModal
      open={open}
      onClose={() => goBackToLogin()}
      component={(
        <Box>
          <OvForm
            hasButton
            buttonText={t('base:SessionTimeoutLayoutModal.loginButton')}
            onSubmit={(event: FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              goBackToLogin();
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center" style={{ marginBottom: '32px' }}>
              <img src={securityCheckImage} alt="security-check" />
              <Typography variant="headingMain" style={{ marginBottom: 0 }}>{t('base:SessionTimeoutLayoutModal.headingText')}</Typography>
              <Typography variant="paragraphMain" style={{ marginBottom: 0, textAlign: 'center' }}>
                {t('base:SessionTimeoutLayoutModal.bodyText')}
              </Typography>
            </Box>
          </OvForm>
        </Box>
      )}
    />
  );
};

export default SessionTimeoutLayoutModal;
