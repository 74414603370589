import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import SuitabilityReviewWizard from './suitabilityReviewWizard';
import { setToLocalStorage } from '../../utils/localStorage';
import { StepProps, StepWizardTransitions } from '../common/wrappers/withStepWizard';
import { decideNextStep } from '../checker';

interface Prop {
  initStep?: number,
  journey: number[],
  updateJourney: (data: number[]) => void,
  goToNamedStep?: (step: string) => void,
  stepProps?: StepProps,
  stepTitle?: string,
  transitions?: StepWizardTransitions,
}

const SuitabilityReview = ({
  initStep, ...props
}: Prop): JSX.Element => {
  const { t } = useTranslation(['suitabilityReview']);
  const proceedToNext = (): void => decideNextStep(2, props.journey, props.updateJourney, props.goToNamedStep);
  const closeSuitabilityReviewModal = (): void => {
    setToLocalStorage('suitabilityReview', 'true');
    proceedToNext();
  };
  return (
    <Box>
      <SuitabilityReviewWizard
        onCompleted={proceedToNext}
        stepTitle={t('suitabilityReview:wizardTitle')}
        initStep={initStep}
        onClose={() => closeSuitabilityReviewModal()}
      />
    </Box>
  );
};

SuitabilityReview.defaultProps = {
  stepTitle: undefined,
  goToNamedStep: undefined,
  stepProps: undefined,
  transitions: undefined,
  initStep: 1,
};

export default SuitabilityReview;
