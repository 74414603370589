/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  List, Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import {
  HoldingResponse,
  defaultGraphColors,
  PrimaryAssetClassHoldings, calculatePrimaryAssetClassHoldings, SecondaryAssetClassHoldings, SecurityHoldings,
} from './resources';
import HoldingsListItem2 from '../common/lists/holdingsListItem2';
import { styles } from './styles';
import FeedbackModal from '../common/wrappers/modals/ovFeedbackModal';
import { translateBackend } from '../../assets/i18n/config';
import HoldingsListItem from '../common/lists/holdingsListItem';
import { useContextTheme } from '../../providers/contextThemeProvider';

export interface HoldingsListProps {
  holdings: HoldingResponse[],
  primaryAssetClassHoldings?: PrimaryAssetClassHoldings[],
  listClassName?: string,
  listStyle?: SxProps<Theme>,
}

const HoldingsList = ({
  holdings,
  primaryAssetClassHoldings,
  listStyle,
  ...props
}: HoldingsListProps): JSX.Element => {
  const { t } = useTranslation(['base', 'user', 'transfer']);
  const { colors } = useContextTheme();
  const listElementRef = useRef<HTMLUListElement>(null);
  const [displayAssertClassDescriptionModal, setDisplayAssertClassDescriptionModal] = useState(false);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const assetClassHoldings: PrimaryAssetClassHoldings[] = primaryAssetClassHoldings === undefined ? calculatePrimaryAssetClassHoldings(holdings) : [...primaryAssetClassHoldings];
  const [modalHolding, setModalHolding] = useState<SecondaryAssetClassHoldings>();
  useEffect(() => {
    setProgressBarWidth((listElementRef?.current?.clientWidth ?? 1) - 100);
  }, [listElementRef]);
  const showAsserClassDialog = (): void => {
    setDisplayAssertClassDescriptionModal(true);
  };

  const dialogClose = (): void => {
    setDisplayAssertClassDescriptionModal(false);
  };
  return (
    <List
      ref={listElementRef}
      sx={{
        ...styles.listWrapper2,
        margin: {
          xs: 1,
          sm: '0px',
          md: '0px',
        },
        backgroundColor: colors.baseComponentBackground,
        borderRadius: '8px',
        border: `1px solid ${colors.portfolioCardBorder}`,
        ...listStyle,
      }}
      className={props.listClassName}
    >
      {assetClassHoldings.sort((a, b) => (a.percentage < b.percentage ? 1 : -1)).map((item, index) => (
        <HoldingsListItem2
          width={progressBarWidth}
          primaryHoldingIndex={index}
          key={item.assetClass.key}
          allPrimaryAssetClassHoldings={assetClassHoldings}
          onClick={(secondaryAsset) => {
            setModalHolding(secondaryAsset);
            showAsserClassDialog();
          }}
          primaryAssetClassHoldings={item}
        />
      ))}
      {displayAssertClassDescriptionModal ? (
        <FeedbackModal
          open={displayAssertClassDescriptionModal}
          onClose={dialogClose}
          component={(
            <Box
              data-testid="holding-popup"
            >
              <Typography variant="heading2" sx={styles.headingText}>
                {translateBackend(modalHolding?.assetClass.translatedName)}
              </Typography>
              <Typography variant="captionSectionerMedium" sx={styles.headingText}>
                {translateBackend(modalHolding?.assetClass.translatedDescription)}
              </Typography>
              {
                (modalHolding?.securityHoldings ?? []).map((item: SecurityHoldings, index) => (
                  <HoldingsListItem
                    key={item.financialProduct.id}
                    hexCode={defaultGraphColors(colors)[index]}
                    prefix={item.financialProduct.ticker}
                    holdingsType={translateBackend(item.financialProduct.translatedName)}
                    percent={Math.round(item?.percentage ?? 0)}
                    onClick={undefined}
                  />
                ))
              }
              <Box sx={styles.goalEditButton}>
                <Button variant="primary-large" onClick={dialogClose}>{t('base:button.gotIt')}</Button>
              </Box>
            </Box>
          )}
        />
      ) : undefined}
    </List>
  );
};

HoldingsList.defaultProps = {
  listClassName: '',
  primaryAssetClassHoldings: undefined,
  listStyle: undefined,
};

export default HoldingsList;
