import { useMutation } from '@apollo/client';
import {
  Typography,
  List,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ButtonListItem from '../../common/lists/buttonListItem';
import { UPDATE_USER } from '../graphql';
import { annualIncome } from '../resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  annualIncomeCents?: number,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
}

const AnnualIncome = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [value, setValue] = useState<number>(0);
  const updateAnnualIncomeCentsCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'ANNUAL_INCOME_CENT_UPDATED', annualIncomeCents: value });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields, annualIncomeCents: value });
    if (props.onContinue) props.onContinue();
  };

  const [updateAnnualIncomeCents] = useMutation(UPDATE_USER, {
    variables: {
      input: { annualIncomeCents: value },
    },
    onCompleted: updateAnnualIncomeCentsCompleted,
  });

  const onContinueClick = (item: { name: string; value: number }): void => {
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectAnnualIncome).then();
    setValue(Math.round(item.value * 100));
  };

  useEffect(() => {
    if (value) {
      updateAnnualIncomeCents().then();
    }
  }, [value, updateAnnualIncomeCents]);

  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2">{t('user:howToInvest.annualIncome.title')}</Typography>
      <Typography variant="paragraph3">{t('user:howToInvest.annualIncome.subTitle')}</Typography>
      <List sx={{ maxHeight: '400px', overflowY: 'auto' }}>
        {annualIncome.map((item) => (
          <Box key={item.name}>
            <ButtonListItem
              key={item.name}
              text={item.name}
              onClick={() => {
                if (item) {
                  onContinueClick(item);
                }
              }}
              isSelected={(user.annualIncomeCents && Math.round(item.value * 100) === user.annualIncomeCents) || false}
            />
            <Box mb={1} />
          </Box>
        ))}
      </List>
    </OvForm>
  );
};

AnnualIncome.defaultProps = {
  onContinue: undefined,
};

export default AnnualIncome;
