/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import ButtonCardListItem, { ButtonCardList } from '../common/lists/buttonCardListItem';
import {
  AccountTypes, accountTypesRESP, getAccountTypeName,
} from './resources';
import OvForm from '../common/wrappers/ovForm';
import { SubAccount } from '../../utils/commonGraphql';
import { Affiliation } from '../../pages/affiliates';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import ButtonListItem from '../common/lists/buttonListItem';
import { AccountRef } from './createSubAccountWizard';

export interface SubAccountRef {
  accountId?: string
  accountType: AccountTypes
}

interface Props {
  setSubAccountRef: (att: SubAccountRef) => void,
  setCreatedAccountRef: (att: Partial<AccountRef>) => void,
  onContinue?: () => void,
  goToAnotherStep?: () => string;
  goToNamedStep?: (stepName: string) => void,
  currentSubAccount?: SubAccount | {
    account?: {
      type?: AccountTypes;
    },
    theme?: {
      id?: string;
    },
  },
  setIsExistingRespAccount: (value: boolean) => void,
}

export const FETCH_ALL_ACCOUNTS = gql`
  query BasicMe {
    me {
      user {
        accounts {
          id
          type
          affiliations {
            user {
              firstName
              middleName
              lastName
            }
            relation
          }
        }
      }
    }
  }
`;

interface Account {
  id: string,
  type: AccountTypes,
  affiliations: Affiliation[],
}

const ExistingRespAccountType = ({
  setSubAccountRef, onContinue, currentSubAccount, goToNamedStep, setIsExistingRespAccount, setCreatedAccountRef, goToAnotherStep,
}: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const [callGoToAnotherStep, setCallGoToAnotherStep] = useState(false);
  const respAccounts: (AccountTypes | undefined)[] = [AccountTypes.RESP, ...accountTypesRESP.map(((item) => item.key))];

  const { data, loading } = useQuery(FETCH_ALL_ACCOUNTS, {
    variables: {},
  });

  useEffect(() => {
    if (callGoToAnotherStep) {
      if (goToNamedStep && goToAnotherStep) goToNamedStep(goToAnotherStep());
    }
  }, [callGoToAnotherStep]);

  const getAffiliates = (account: Account): string => {
    const filteredAffiliates = account.affiliations.filter((affiliate) => !['GUARDIAN', 'PRIMARY_CAREGIVER'].includes(affiliate?.relation ?? ''));
    if (filteredAffiliates.length === 0) return t('account:existingResp.noBeneficiaries');
    return `${t('account:existingResp.beneficiaries')} ${filteredAffiliates.reduce((aff1, aff2, index) => {
      if (index === (account.affiliations.length - 1)) {
        return aff1.concat(` ${t('account:existingResp.and')} ${aff2.user?.firstName ?? ''}`);
      }
      if (index > 0 && (index < (account.affiliations.length - 1))) {
        return aff1.concat(`, ${aff2.user?.firstName ?? ''}`);
      }
      return aff1.concat(aff2.user?.firstName ?? '');
    }, '')}`;
  };

  if (loading) {
    return (<OvLoadingIndicator />);
  }
  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2" data-testid="title">{t('account:existingResp.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('account:existingResp.subTitle')}</Typography>
      <ButtonCardList>
        {data.me.user.accounts?.filter((item: Account) => respAccounts.includes(item?.type)).map((item: Account) => (
          <ButtonCardListItem
            key={item.id}
            header={getAccountTypeName(item.type)}
            description={getAffiliates(item)}
            onClick={() => {
              setSubAccountRef({ ...currentSubAccount, accountId: item.id, accountType: item.type });
              setCreatedAccountRef({ id: item.id, type: item.type });
              setIsExistingRespAccount(true);
              if (goToNamedStep) {
                if (goToAnotherStep) {
                  // update a state here and use it on ueEffect calling goToNamedStep(goToAnotherStep())
                  setCallGoToAnotherStep(true);
                  // goToNamedStep(goToAnotherStep());
                } else {
                  goToNamedStep('selectTheme');
                }
              }
            }}
            isSelected={currentSubAccount?.account?.type === item.type}
          />
        ))}
      </ButtonCardList>
      <ButtonListItem
        marginTop={1}
        onClick={() => {
          setCallGoToAnotherStep(false);
          setIsExistingRespAccount(false);
          if (onContinue) onContinue();
        }}
        text="New account"
      />
    </OvForm>
  );
};

ExistingRespAccountType.defaultProps = {
  onContinue: undefined,
  currentSubAccount: undefined,
  goToNamedStep: undefined,
  goToAnotherStep: undefined,
};

export default ExistingRespAccountType;
