/* eslint-disable max-len */

import { useContext, useRef, useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import WithStepWizard, { StepProps } from '../common/wrappers/withStepWizard';
import SelectTheme from './selectTheme';
import ThemeDescription from './themeDescription';
import IncompleteAgreements from '../documents/incompleteAgreements';
import { CREATE_SUB_ACCOUNT, UPDATE_SUB_ACCOUNT } from './graphql';
import {
  AccountTypes,
  getAccountTypeName,
  getSubAccountUniqueName,
  ThemeAttributes,
} from './resources';
import TrackingBar from '../common/headers/trackingBar';
import { UserContext } from '../../providers/userContextProvider';
import GoalsList from './goalsList';
import PortfolioDisclaimer from './portfolioDisclaimer';
import { generateUniqueRequestId } from '../../utils/generic';

const GoalsListStep = WithStepWizard(GoalsList);
const SelectThemeStep = WithStepWizard(SelectTheme);
const ThemeDescriptionStep = WithStepWizard(ThemeDescription);
const PortfolioDisclaimerStep = WithStepWizard(PortfolioDisclaimer);
const IncompleteAgreementsStep = WithStepWizard(IncompleteAgreements);

export interface SubAccountRef {
  id?: string,
  goalId?: string,
  themeId?: string,
  accountId?: string,
  accountType?: AccountTypes,
  name?: string,
  financialProductId?: string,
  financialProductDisclaimer?: string,
}

interface Props {
  // subAccount?: { id: string, account?: { type: AccountTypes } },
  accountId: string,
  accountType: string,
  onContinue?: () => void,
  goBack?: () => void,
  stepProps?: StepProps,
  stepTitle?: string,
  onStepChanged?: (step: number) => void,
  onSubAccountCreated?: (subAccount: Partial<SubAccountRef>) => void,
}

const CreateSubAccountForAccountWizard = ({
  onContinue, goBack, stepTitle, stepProps, accountId, accountType, onStepChanged, onSubAccountCreated,
}: Props): JSX.Element => {
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const [loading, setLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<ThemeAttributes>();
  const [goalId, setGoalId] = useState('');
  const [hasMultipleThemes, setHasMultipleThemes] = useState<boolean>(false);
  const [hasPortfolioDisclaimer, setHasPortfolioDisclaimer] = useState<boolean>(false);
  const subAccountRef = useRef<SubAccountRef>({ id: '', goalId });
  const originalAccountType = useRef<string | undefined>(accountType);
  const callbackRef = useRef<() => void>();
  const { userContext, setUserContext } = useContext(UserContext);
  const { t } = useTranslation(['user']);
  subAccountRef.current = { ...subAccountRef.current, accountId, accountType: accountType as AccountTypes };

  const setSubAccountRef = (att: Partial<SubAccountRef>): void => {
    subAccountRef.current = { ...subAccountRef.current, ...att };
  };
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const currentSubAccount = {
    id: subAccountRef.current.id,
    account: { type: subAccountRef.current.accountType },
    theme: { id: subAccountRef.current.themeId },
    goal: { id: subAccountRef.current.goalId },
  };
  const onCreateSubAccountCompleted = (data: { createSubAccount: { subAccount: { id: string, name: string, account: { id: string } } } }): void => {
    setSubAccountRef({ id: data.createSubAccount.subAccount.id, accountId: data.createSubAccount.subAccount.account.id });
    originalAccountType.current = subAccountRef.current.accountType;
    const otherSubAccounts = (subAccountRef.current.id ? userContext.subAccounts?.filter((s) => s.id !== subAccountRef.current.id) : userContext.subAccounts) ?? [];
    const account = { id: subAccountRef.current.accountId ?? '', type: subAccountRef.current.accountType as AccountTypes };
    const isNewAccount = !userContext.accounts?.find((a) => a.id === subAccountRef.current.accountId);
    setUserContext({
      ...userContext,
      subAccounts: [...otherSubAccounts, {
        id: data.createSubAccount.subAccount.id,
        name: data.createSubAccount.subAccount.name,
        goal: { id: subAccountRef.current.goalId ?? '' },
        account,
      }],
      accounts: isNewAccount ? [...(userContext.accounts ?? []), account] : userContext.accounts,
    });
    setLoading(false);
    if (callbackRef.current) callbackRef.current();
  };

  const [createSubAccountMutation] = useMutation(CREATE_SUB_ACCOUNT, {
    variables: {
      input: {
        goalId: subAccountRef.current.goalId,
        accountId,
        accountType,
        name: '',
        themeId: '',
        financialProductId: '',
        requestId: '',
      },
    },
    onCompleted: onCreateSubAccountCompleted,
    onError: () => setLoading(false),
  });

  const onUpdateSubAccountCompleted = (data: { updateSubAccount: { subAccount: { id: string, name: string } } }): void => {
    console.log({ event: 'UPDATED_SUB_ACCOUNT', subAccountId: data.updateSubAccount.subAccount.id });
    const otherSubAccounts = (subAccountRef.current.id ? userContext.subAccounts?.filter((s) => s.id !== subAccountRef.current.id) : userContext.subAccounts) ?? [];
    const account = { id: subAccountRef.current.accountId ?? '', type: subAccountRef.current.accountType as AccountTypes };
    const isNewAccount = !userContext.accounts?.find((a) => a.id === subAccountRef.current.accountId);
    setUserContext({
      ...userContext,
      subAccounts: [...otherSubAccounts, {
        id: data.updateSubAccount.subAccount.id,
        name: data.updateSubAccount.subAccount.name,
        goal: { id: subAccountRef.current.goalId ?? '' },
        account,
      }],
      accounts: isNewAccount ? [...(userContext.accounts ?? []), account] : userContext.accounts,
    });
    if (callbackRef.current) callbackRef.current();
    setLoading(false);
  };

  const [updateSubAccountMutation] = useMutation(UPDATE_SUB_ACCOUNT, {
    onCompleted: onUpdateSubAccountCompleted,
    onError: () => setLoading(false),
  });

  const createUpdateSubAccount = async (callback: () => void): Promise<void> => {
    console.log({ event: 'CREATING_SUB_ACCOUNT' });
    console.log(getSubAccountUniqueName(`${getAccountTypeName(subAccountRef.current.accountType as string)} ${selectedTheme?.name ?? ''}`, userContext.subAccounts ?? []));
    callbackRef.current = callback;
    if (subAccountRef.current.id) {
      updateSubAccountMutation({
        variables: {
          input: {
            name: getSubAccountUniqueName(`${getAccountTypeName(subAccountRef.current.accountType as string)} ${selectedTheme?.name ?? ''}`, userContext.subAccounts ?? []),
            subAccountId: subAccountRef.current.id,
            themeId: selectedTheme?.id,
            financialProductId: subAccountRef.current.financialProductId,
          },
        },
      }).then();
    } else {
      createSubAccountMutation({
        variables: {
          input: {
            goalId: subAccountRef.current.goalId,
            accountId,
            accountType,
            name: getSubAccountUniqueName(`${getAccountTypeName(subAccountRef.current.accountType as string)} ${selectedTheme?.name ?? ''}`, userContext.subAccounts ?? []),
            themeId: selectedTheme?.id ?? '',
            financialProductId: subAccountRef.current?.financialProductId ?? '',
            requestId: generateUniqueRequestId(),
          },
        },
      }).then();
    }
    setLoading(true);
  };
  const steps: JSX.Element[] = [
    <GoalsListStep setGoalId={setGoalId} stepTitle={stepTitle} stepName="type" setSubAccountRef={setSubAccountRef} isLoading={loading} goBackAction={goBack} key="type" />,
    <SelectThemeStep setHasMultipleThemes={setHasMultipleThemes} stepTitle={stepTitle} stepName="selectTheme" currentSubAccount={currentSubAccount} goalId={subAccountRef.current.goalId ?? ''} setSubAccountRef={setSubAccountRef} selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} key="selectTheme" />,
    <ThemeDescriptionStep setHasPortfolioDisclaimer={setHasPortfolioDisclaimer} goBackToNamedStep={() => { if (hasMultipleThemes) { return 'selectTheme'; } return 'type'; }} stepTitle={stepTitle} stepName="themeDescription" goalId={subAccountRef.current.goalId ?? ''} setSubAccountRef={setSubAccountRef} selectedTheme={selectedTheme} action={!hasPortfolioDisclaimer ? createUpdateSubAccount : undefined} showLoading={loading} key="themeDescription" continueToNamedStep={() => (hasPortfolioDisclaimer ? 'portfolioDisclaimer' : 'agreements')} />,
    <PortfolioDisclaimerStep stepTitle={stepTitle} stepName="portfolioDisclaimer" financialProductDisclaimer={subAccountRef.current.financialProductDisclaimer ?? ''} action={createUpdateSubAccount} key="portfolioDisclaimer" />,
    <IncompleteAgreementsStep
      goBackToNamedStep={() => (hasPortfolioDisclaimer ? 'portfolioDisclaimer' : 'themeDescription')}
      stepTitle={t('user:agreements.stepWizardTitle')}
      stepName="agreements"
      subAccountId={subAccountRef.current.id}
      action={() => {
        if (onContinue) { onContinue(); }
        if (onSubAccountCreated) { onSubAccountCreated(subAccountRef.current); }
      }}
      key="agreements"
    />,
  ];
  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      transitions={{}}
      onStepChange={(stepChange: {
        previousStep: number
        activeStep: number
      }) => {
        if (onStepChanged) {
          onStepChanged(stepChange.activeStep);
        }
      }}
      className="ov-step-wizard"
      nav={(
        <TrackingBar steps={steps} baselineStepProps={stepProps} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

CreateSubAccountForAccountWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  stepTitle: undefined,
  stepProps: undefined,
  onStepChanged: undefined,
  onSubAccountCreated: undefined,
};

export default CreateSubAccountForAccountWizard;
