/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import ButtonListItem from '../../common/lists/buttonListItem';
import { UPDATE_USER } from '../graphql';
import { riskQuestions1List, RiskLevels, RiskQuestion } from '../resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  riskQuestion1?: RiskLevels,
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  cached?: boolean,
}

const RiskQuestion1 = ({
  user, updateUserState, cached, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const riskQuestion1OnCompleted = (data: { updateUser: { user: { incompleteFields: string[] } } }): void => {
    console.log({ event: 'RISK_QUESTION_1_UPDATED', InvestmentKnowledge: user.riskQuestion1 });
    updateUserState({ ...user, incompleteFields: data.updateUser.user.incompleteFields });
    if (props.onContinue) props.onContinue();
  };
  const [updateRiskQuestion1Status] = useMutation(UPDATE_USER, {
    variables: {
      input: { riskQuestion1: user.riskQuestion1 },
    },
    onCompleted: riskQuestion1OnCompleted,
  });
  const updateUserStateAsync = (riskQuestion1: RiskLevels): Promise<void> => Promise.resolve(updateUserState({ ...user, riskQuestion1 }));
  const handleClick = async (item: RiskQuestion): Promise<void> => {
    console.log({ event: 'UPDATE_RISK_QUESTION_1', RiskQuestions1: item });
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectCareAboutMost).then();
    await updateUserStateAsync(item.level);
    if (cached && props.onContinue) {
      props.onContinue();
    } else {
      updateRiskQuestion1Status({
        variables: {
          input: {
            riskQuestion1: item.level,
          },
        },
      });
    }
  };

  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2">{t('user:riskQuestion1.title')}</Typography>
      <Typography variant="paragraph3">{t('user:riskQuestion1.subTitle')}</Typography>
      <List sx={{ maxHeight: '400px', overflowY: 'auto' }}>
        {riskQuestions1List.map((item: RiskQuestion) => (
          <ButtonListItem
            key={item.level}
            text={item.question || ''}
            onClick={() => {
              if (item.level) {
                handleClick(item).then();
              }
            }}
            isSelected={(user.riskQuestion1 && item.level === user.riskQuestion1) || false}
          />
        ))}
      </List>
    </OvForm>
  );
};

RiskQuestion1.defaultProps = {
  onContinue: undefined,
  cached: false,
};

export default RiskQuestion1;
