import * as React from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { TFunction } from 'react-i18next';
import { colors } from '../../../theme/colors';
import { AffiliationRelations, AffiliationTypes, allAffiliationRelations } from '../../account/resources';
import EditIcon from '../../../assets/images/filled/edit.svg';
import { Item, styles } from '../../../pages/subAccountDetails/styles';
import { Affiliation } from '../../../pages/affiliates';
import { AffiliateUser } from '../../account/createAccountAuxiliaryWizard';

export const sortAffiliates = (list: string[]): string[] => {
  // eslint-disable-next-line
  const map: any = {
    ACCOUNT_HOLDER: 1,
    SUCCESSOR: 2,
    BENEFICIARIES: 3,
    TRUSTEE: 4,
    SETTLOR: 5,
    POWER_OF_ATTORNEY: 6,
    OTHER: 7,
  };
  return list.sort((x, y) => map[x] - map[y]);
};

interface AffiliatesListItemProps {
  t: TFunction<('user' | 'base' | 'account')[]>
  matches: boolean;
  type: AffiliationTypes;
  affiliationList: Map<string, Affiliation[]>
  editAffiliate: (att: AffiliateUser) => void,
  createAffiliate: () => void;
  showAddButton: boolean;
}

const getRelationship = (relation: string): string => allAffiliationRelations.find((x) => x.key.toUpperCase() === relation)?.title ?? '';
const isAuthorizedUser = (type: AffiliationTypes): boolean => [AffiliationTypes.AUTHORIZED_INDIVIDUAL, AffiliationTypes.JOINT].includes(type);

const AffiliatesListItem = ({ t, type, ...props }: AffiliatesListItemProps): JSX.Element => (
  <Box key={type} sx={styles.detailWrapper}>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Box sx={{
          margin: '10px 0px',
          marginLeft: '24px',
        }}>
          <Box display="flex" alignItems="center" sx={styles.detailBox} mr="24px">
            <Typography variant="captionSectionerBold" color={colors.neutral20}>{t(`account:affiliates.types.${type}`)}</Typography>
            <Box mr={0.7} />
            {props.showAddButton && ![AffiliationTypes.OTHER, AffiliationTypes.SUCCESSOR].includes(type) ? (
              <Box key={type}>
                <Typography style={{ ...styles.underline, marginRight: '24px' }} onClick={props.createAffiliate} variant="captionSectionerBold" color={colors.link}>{t(`account:affiliates.button.${type}`)}</Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Item sx={styles.leftCage} style={{ padding: '0px !important', margin: '24px', marginTop: '0px' }}>
          <Grid container spacing={2}>
            {props.affiliationList.get(type)?.map((x) => (
              <Grid key={props.affiliationList.get(type)?.indexOf(x)} item xs={props.matches ? 12 : 4}>
                <Box display="flex" alignItems="center" style={styles.relationText}>
                  <Typography variant="captionDescriptorMedium" color={colors.secondary}>{getRelationship(x.relation ?? '')}</Typography>
                  <Box mr={0.7} />
                  {!isAuthorizedUser(type) && type !== 'OTHER' ? (
                    <IconButton
                      style={{ marginLeft: '10px', padding: '0px' }}
                      onClick={() => {
                        const physicalAddress = { ...x.user?.physicalAddress, __typename: undefined };
                        const affiliateUser: AffiliateUser = {
                          ...x.user, id: x.id, physicalAddress, affiliation: { userId: x.user?.id, relation: x.relation as AffiliationRelations },
                        };
                        props.editAffiliate(affiliateUser);
                      }}>
                      <img src={EditIcon} alt="information-icon" data-testid="information-icon" />
                    </IconButton>
                  ) : null}
                </Box>
                <Typography variant="bodyMain1" color={colors.secondary} style={styles.affiliateText}>{`${x.user?.firstName} ${x.user?.lastName}`}</Typography>
                <Typography variant="captionSectionerBold" color={colors.secondary} style={styles.dateText}>{isAuthorizedUser(type) ? x.user?.primaryEmail : t('account:affiliates.screen.dateOfBirth', { dateOfBirth: x.user?.dateOfBirth ?? '' })}</Typography>
              </Grid>
            ))}
          </Grid>
        </Item>
      </Grid>
    </Grid>
  </Box>
);

export default AffiliatesListItem;
