import {
  Typography,
  List,
  Box,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import ButtonListItem from '../common/lists/buttonListItem';
import OvForm from '../common/wrappers/ovForm';
import { Affiliation } from './createAccountAuxiliaryWizard';
import { AccountTypes, AffiliationTypes } from './resources';

export interface UserState {
  affiliation?: Affiliation,
}

interface Props {
  beneficiaryName?: string,
  user: UserState,
  accountType?: AccountTypes,
  disableSuccessorOption: boolean,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  goToNamedStep?: (step: string) => void,
}

const BeneficiaryAffiliationType = ({ user, updateUserState, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'account']);
  const [loading, setLoading] = useState(false);

  const getAffiliationTypes = (): AffiliationTypes[] => {
    let accountAffiliationTypes = [AffiliationTypes.PRIMARY_BENEFICIARY];
    if (props.accountType === AccountTypes.TFSA) accountAffiliationTypes = [AffiliationTypes.SUCCESSOR, AffiliationTypes.PRIMARY_BENEFICIARY];
    return accountAffiliationTypes;
  };

  const handleClick = async (item: AffiliationTypes): Promise<void> => {
    setLoading(true);
    await sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryAffiliationTypeSelect).then();
    updateUserState({ ...user, affiliation: { ...user.affiliation, type: item } });
    if (item === AffiliationTypes.SUCCESSOR && props.goToNamedStep) {
      props.goToNamedStep('BeneficiaryRelationsip');
      return;
    }
    if (props.onContinue) props.onContinue();
  };

  return (
    <OvForm loading={loading} hasButton={false}>
      <Typography variant="heading2">{t('account:beneficiary.affiliation.title', { firstName: props.beneficiaryName })}</Typography>
      <Typography variant="paragraph3">{t('account:beneficiary.affiliation.subtitle', { firstName: props.beneficiaryName })}</Typography>
      <List sx={{ maxHeight: '250px', overflowY: 'auto' }}>
        {getAffiliationTypes().map((item) => (
          <Box key={item}>
            <ButtonListItem
              key={item}
              disabled={props.disableSuccessorOption && item === AffiliationTypes.SUCCESSOR}
              text={t(`account:affiliates.types.${item}`)}
              onClick={() => {
                if (item) handleClick(item);
              }}
              isSelected={(user.affiliation?.type && item === user.affiliation?.type) || false}
            />
            <Box mb={1} />
          </Box>
        ))}
      </List>
    </OvForm>
  );
};

BeneficiaryAffiliationType.defaultProps = {
  beneficiaryName: undefined,
  onContinue: undefined,
  accountType: undefined,
  goToNamedStep: undefined,
};

export default BeneficiaryAffiliationType;
