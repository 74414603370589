/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Tab,
  Box, Typography, Divider,
} from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import {
  useState,
  SyntheticEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styles } from './style';
import BankAccountDeposit from './bankAccountDeposit';
import ExistingPortfolioDeposit from './existingPortfolioDeposit';
import ExternalAccountDeposit from './externalAccountDeposit';
import { AccountTypes, FeatureFlagTypes, isFeatureEnabled } from '../../account/resources';

interface Props {
  onClose?: () => void,
  selectedGoalId?: string,
  selectedSubAccountId?: string,
  onSuccessTransfer?: () => void,
  onContinue?: () => void,
  hideExistingPortfolioDeposit?: boolean,
  hideBankAccountDeposit?: boolean,
  hideToastMessage?: boolean,
  analyticsEvents?: {
    bankAccountDeposit?: string,
    existingPortfolioDeposit?: string,
    externalAccountDeposit?: string,
  },
  preSelectedAccountType?: AccountTypes
  initialStep?: number,
  showOnlySelectedGoalAsOption?: boolean,
  showOnlySelectedSubAccountAsOption?: boolean,
}

const Deposit = ({
  onClose,
  selectedGoalId,
  selectedSubAccountId,
  onSuccessTransfer,
  onContinue,
  analyticsEvents,
  hideExistingPortfolioDeposit,
  hideBankAccountDeposit,
  hideToastMessage,
  preSelectedAccountType,
  initialStep,
  showOnlySelectedGoalAsOption,
  showOnlySelectedSubAccountAsOption,
}: Props): JSX.Element => {
  const { t } = useTranslation(['deposit']);
  const matches = useMediaQuery('(max-width:600px)');
  const defineInitialStep = (): number => {
    if (initialStep) return initialStep;
    let steps = [1, 2, 3];
    if (hideBankAccountDeposit) steps = steps.filter((step) => step !== 1);
    if (hideExistingPortfolioDeposit || !isFeatureEnabled(FeatureFlagTypes.ACCOUNT_TO_ACCOUNT_DEPOSIT)) steps = steps.filter((step) => step !== 2);
    return steps[0];
  };
  const [value, setValue] = useState(defineInitialStep());
  const handleChange = (event: SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <Box sx={styles.containerWrapper}>
      <Box sx={{ width: matches ? '100%' : '329px' }}>
        <Box>
          <Typography variant="heading2" style={{ marginBottom: '8px' }}>{t('deposit:header')}</Typography>
          <Container className="home-page" sx={styles.depositInnerContainer}>
            <TabContext value={value.toString()}>
              <TabList onChange={handleChange} variant="fullWidth" centered aria-label="dashboard tabs">
                {
                  !hideBankAccountDeposit && (
                    <Tab
                      label={(
                        <span>
                          {t('deposit:tabs.bankAccountTab.section1')}
                          <br />
                          {t('deposit:tabs.bankAccountTab.section2')}
                        </span>
                      )}
                      value="1"
                    />
                  )
                }
                {
                  !hideExistingPortfolioDeposit && isFeatureEnabled(FeatureFlagTypes.ACCOUNT_TO_ACCOUNT_DEPOSIT) && (
                    <Tab
                      label={
                        (
                          <span>
                            {t('deposit:tabs.existingPortfolioTab.section1')}
                            <br />
                            {t('deposit:tabs.existingPortfolioTab.section2')}
                          </span>
                        )
                      }
                      value="2"
                    />
                  )
                }
                <Tab
                  label={
                    (
                      <span>
                        {
                          hideBankAccountDeposit && hideExistingPortfolioDeposit ? (

                            t('deposit:tabs.externalAccountTab.title')

                          ) : (
                            <>
                              {t('deposit:tabs.externalAccountTab.section1')}
                              <br />
                              {t('deposit:tabs.externalAccountTab.section2')}
                            </>
                          )
                        }
                      </span>
                    )
                  }
                  value="3"
                />
              </TabList>
              <Divider flexItem />
              <TabPanel value="1" sx={{ padding: 0, flexGrow: 1 }}>
                <BankAccountDeposit
                  onClose={() => {
                    if (onClose) onClose();
                  }}
                  analyticsEvent={analyticsEvents?.bankAccountDeposit}
                  selectedGoalId={selectedGoalId}
                  selectedSubAccountId={selectedSubAccountId}
                  onSuccessTransfer={() => {
                    if (onContinue) onContinue();
                    if (onSuccessTransfer) onSuccessTransfer();
                  }}
                  showOnlySelectedGoalAsOption={showOnlySelectedGoalAsOption}
                  showOnlySelectedSubAccountAsOption={showOnlySelectedSubAccountAsOption}
                />
              </TabPanel>
              <TabPanel value="2" sx={{ padding: 0, flexGrow: 1 }}>
                <ExistingPortfolioDeposit
                  onClose={() => {
                    if (onClose) onClose();
                  }}
                  selectedGoalId={selectedGoalId}
                  selectedSubAccountId={selectedSubAccountId}
                  analyticsEvent={analyticsEvents?.existingPortfolioDeposit}
                  onSuccessTransfer={() => {
                    if (onSuccessTransfer) onSuccessTransfer();
                  }}
                  showOnlySelectedGoalAsOption={showOnlySelectedGoalAsOption}
                  showOnlySelectedSubAccountAsOption={showOnlySelectedSubAccountAsOption}
                />
              </TabPanel>
              <TabPanel value="3" sx={{ padding: 0, flexGrow: 1 }}>
                <ExternalAccountDeposit
                  preSelectedAccountType={preSelectedAccountType}
                  onClose={() => {
                    if (onClose) onClose();
                  }}
                  analyticsEvent={analyticsEvents?.externalAccountDeposit}
                  selectedGoalId={selectedGoalId}
                  selectedSubAccountId={selectedSubAccountId}
                  hideToastMessage={hideToastMessage}
                  onSuccessTransfer={() => {
                    if (onContinue) onContinue();
                    if (onSuccessTransfer) onSuccessTransfer();
                  }}
                  showOnlySelectedGoalAsOption={showOnlySelectedGoalAsOption}
                  showOnlySelectedSubAccountAsOption={showOnlySelectedSubAccountAsOption}
                />
              </TabPanel>
            </TabContext>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

Deposit.defaultProps = {
  selectedGoalId: '',
  selectedSubAccountId: '',
  onSuccessTransfer: undefined,
  onContinue: undefined,
  hideExistingPortfolioDeposit: false,
  hideBankAccountDeposit: false,
  hideToastMessage: false,
  onClose: undefined,
  analyticsEvents: undefined,
  preSelectedAccountType: undefined,
  initialStep: undefined,
  showOnlySelectedGoalAsOption: false,
  showOnlySelectedSubAccountAsOption: false,
};

export default Deposit;
