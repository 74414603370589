/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-cond-assign */
/* eslint-disable no-nested-ternary */
import {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import HistoryTooltip from './historyTooltip';
import { Aggregation, DailyStatsTypes, FETCH_STATS_HISTORY } from './graphql';
import { historyChartStyles } from './styles';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import DefaultHistoryChart from '../../assets/images/history-chart.svg';
import MarketValueIcon from '../../assets/images/market-value-icon.svg';
import NetContributionIcon from '../../assets/images/net-contribution-icon.svg';
import { formatMoneyValue } from '../../utils/commonMethods';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { userIdVar } from '../../utils/localVariables';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);
interface Props {
  aggregation: Aggregation,
  title?: string,
  changeUnit?: (frame: string) => void,
  disableTooltip?: boolean,
  includeAllHouseholds?: boolean,
  children: any
}

interface Input {
  type: DailyStatsTypes,
  id: string,
  filter: {
    includeAllHouseholds: boolean,
    userId?: string,
    beforeDate?: string,
    afterDate?: string,
  },
}

const HistoryChart = ({
  title = '', aggregation, children, includeAllHouseholds = false, ...props
}: Props): JSX.Element => {
  const basicInput: Input = {
    type: aggregation.type,
    id: aggregation.typeId,
    filter: {
      includeAllHouseholds,
      userId: aggregation.type !== DailyStatsTypes.USERS_DAILY && !includeAllHouseholds ? userIdVar() : undefined,
    },
  };
  const { colors } = useContextTheme();
  const [isMarketValueEmpty, setIsMarketValueEmpty] = useState(true);
  const [input, setInput] = useState<Input>(basicInput);
  const { t } = useTranslation(['statistics']);

  const canvas: HTMLCanvasElement = document.createElement('canvas');
  let ctx: CanvasRenderingContext2D | null;
  if (!(ctx = canvas.getContext('2d'))) {
    throw new Error('2d context not supported or canvas already initialized');
  }
  const calculateGadientVal = (resizedWidth: number): number => {
    let gradientHeight = 360;
    if (resizedWidth < 400) {
      gradientHeight = 220;
    } else if (resizedWidth >= 400 && resizedWidth < 960) {
      gradientHeight = 320;
    }
    return gradientHeight;
  };
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = (): void => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const gradient = ctx.createLinearGradient(0, 0, 0, calculateGadientVal(width));

  gradient.addColorStop(0.04, colors.marketValueChartColor1);
  gradient.addColorStop(0.8, colors.marketValueChartColor2);
  const [historyGraphData, setHistoryGraphData] = useState([{
    id: '',
    marketValueCents: 0,
    netContributionCents: 0,
    date: '',
  }]);
  const setGraphData = (data: {
    fetchStatsHistory: {
      snapshots: [{
        id: string,
        marketValueCents: number,
        netContributionCents: number,
        date: string,
      }]
    }
  }): void => {
    console.log({ event: 'HISTORY', history: data.fetchStatsHistory.snapshots });
    // set history data to the state
    data.fetchStatsHistory.snapshots.forEach((s) => {
      if (s.marketValueCents !== 0) {
        setIsMarketValueEmpty(false);
      }
    });
    setHistoryGraphData(data.fetchStatsHistory.snapshots);
  };

  const { loading } = useQuery(FETCH_STATS_HISTORY, {
    variables: {
      input,
    },
    onCompleted: setGraphData,
    nextFetchPolicy: 'standby',
  });
  const [labels, setlabels] = useState(['']);
  const [maxYVal, setmaxYVal] = useState(0);
  const [minVal, setminVal] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipData, setTooltipData] = useState({
    investmentValue: '',
    marketValue: '',
    titleDate: '',
  });
  const setTooltipInfo = (elements: any[]): void => {
    const dataIndex = elements[0].dataIndex || 0;
    const { netContributionCents, marketValueCents, date } = historyGraphData[dataIndex];
    setTooltipData({
      investmentValue: formatMoneyValue(netContributionCents),
      marketValue: formatMoneyValue(marketValueCents),
      titleDate: dayjs(date).format('MMM DD, YYYY'),
    });
  };
  const [currentDataPoint, setCurrentDataPoint] = useState(0);
  const [borderWidthVal, setBoarderWidthVal] = useState(0);
  /* eslint-disable no-underscore-dangle */
  const tooltipLine = {
    id: 'tooltipLine',
    beforeDraw: (chart: any) => {
      const chartPointMarketValueIcon = new Image();
      chartPointMarketValueIcon.src = MarketValueIcon;
      const chartPointNetContributionIcon = new Image();
      chartPointNetContributionIcon.src = NetContributionIcon;
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx3 = chart.ctx;
        ctx3.save();
        const activePoint = chart.tooltip._active[0];
        const netContributionPoint = chart.tooltip._active[1] || null;
        ctx3.beginPath();
        ctx3.setLineDash([5, 10]);
        if (activePoint) {
          ctx3.drawImage(chartPointMarketValueIcon, (activePoint.element.x - 10), (activePoint.element.y - 10));
        }
        ctx3.moveTo(activePoint.element.x, chart.chartArea.top);
        ctx3.lineTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineWidth = 1.5;
        ctx3.strokeStyle = colors.secondary;
        ctx3.stroke();
        ctx3.restore();
        ctx3.beginPath();
        ctx3.setLineDash([5, 10]);
        if (netContributionPoint) {
          ctx3.drawImage(chartPointNetContributionIcon, (netContributionPoint.element.x - 10), (netContributionPoint.element.y - 10));
        }
        ctx3.moveTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx3.lineWidth = 1.5;
        ctx3.strokeStyle = colors.secondary;
        ctx3.stroke();
        ctx3.restore();
      }
    },
  };
  /* eslint-disable no-underscore-dangle */
  const externalTooltipHandler = (context: any): void => {
    if (props?.disableTooltip) {
      return;
    }
    // Tooltip Element
    const { tooltip } = context;
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      setShowTooltip(false);
      setBoarderWidthVal(0);
      return;
    }
    if (currentDataPoint !== tooltip.dataPoints[0].dataIndex) {
      setTooltipInfo(tooltip.dataPoints);
    }
    if (!showTooltip || currentDataPoint !== tooltip.dataPoints[0].dataIndex) {
      setCurrentDataPoint(tooltip.dataPoints[0].dataIndex);
      setShowTooltip(true);
    }
  };
  useEffect(() => {
    setlabels(historyGraphData.map((item) => dayjs(item.date).format('YYYY-MM-DD')));
    setmaxYVal(Math.max(...historyGraphData.map((o) => (o.marketValueCents)), 0));
    setminVal(Math.min(...historyGraphData.map((o) => (o.marketValueCents))));
  }, [historyGraphData]);
  const INDEX = 'index';
  const NEAREST = 'nearest';
  const options = {
    layout: {
      padding: -8,
    },
    animation: {
      duration: 0,
    },
    interaction: {
      intersect: false,
      mode: 'index' as typeof INDEX,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest' as typeof NEAREST,
        external: externalTooltipHandler,
      },
    },
    hover: {
      intersect: false,
      mode: 'point' as const,
    },
    tooltips: {
      mode: 'index',
    },
    responsive: true,
    elements: { point: { radius: 0 } },
    scales: {
      y: {
        offset: true,
        gridLines: { display: false },
        ticks: {
          display: false,
          minVal: minVal || 0,
          maxVal: maxYVal,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      x: {
        display: false,
        gridLines: { display: false },
        ticks: {
          display: false,
        },
        scaleShowLabels: false,
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
    onHover: () => {
      setBoarderWidthVal(1);
    },
  };
  const data = {
    labels,
    datasets: [
      {
        data: historyGraphData.map((item) => (item.marketValueCents / 100)),
        borderColor: colors.marketValueChartOutlineColor,
        backgroundColor: gradient,
        pointColor: colors.pointColor,
        fill: true,
        tension: 0.3,
        pointRadius: 0,
        borderWidth: 2,
        pointStyle: 'none',
      },
      {
        data: historyGraphData.map((item) => (item.netContributionCents / 100)),
        borderColor: colors.marketValueChartOutlineColor,
        backgroundColor: colors.pointColor,
        pointColor: colors.pointColor,
        borderWidth: borderWidthVal,
        fill: false,
        borderDash: [5, 2],
        pointRadius: 0,
        pointStyle: 'none',
      },
    ],
  };
  const [clickedItem, setClickedItem] = useState('ALL');
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? colors.chartDark : colors.chartLight,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: colors.neutral30,
    boxShadow: 'none',
    cursor: 'pointer',
  }));
  const filterDataSet = (range: string): void => {
    let newInput: Input = { ...basicInput };
    if (range !== 'ALL') {
      const beforeDate = dayjs().format('YYYY-MM-DD');
      let afterDate: string;
      if (range === '1W') {
        afterDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
        newInput = {
          ...input,
          filter: {
            ...input.filter,
            beforeDate,
            afterDate,
          },
        };
      }
      if (range === '1M') {
        afterDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
        newInput = {
          ...input,
          filter: {
            ...input.filter,
            beforeDate,
            afterDate,
          },
        };
      }
      if (range === '1Y') {
        afterDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
        newInput = {
          ...input,
          filter: {
            ...input.filter,
            beforeDate,
            afterDate,
          },
        };
      }
      if (range === '3M') {
        afterDate = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
        newInput = {
          ...input,
          filter: {
            ...input.filter,
            beforeDate,
            afterDate,
          },
        };
      }
    }
    setInput(newInput);
  };
  return (
    <Box sx={historyChartStyles.root}>
      <Typography variant="heading4" color="inherit" style={historyChartStyles.grow}>
        {title}
      </Typography>
      <Box sx={{ height: '150px' }}>
        {showTooltip ? (
          <HistoryTooltip investmentValue={tooltipData.investmentValue} marketValue={tooltipData.marketValue} titleDate={tooltipData.titleDate} />
        ) : children}
      </Box>
      <Box
        style={{
          height: historyChartStyles.chart.height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!loading ? isMarketValueEmpty ? <img src={DefaultHistoryChart} alt="empty" height="100%" width="100%" style={{ objectFit: 'contain' }} /> : <Line options={options} plugins={[tooltipLine]} data={data} id="chart" height={historyChartStyles.chart.height} style={historyChartStyles.chart} /> : <CircularProgress className="loader" style={historyChartStyles.loading} data-testid="loading-element" thickness={6} />}
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={historyChartStyles.periodGrid}>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <Item
              onClick={() => {
                sendAnalyticsEvent(ovAnalyticsEvents.homePageChart1WSelect).then();
                setClickedItem('1W');
                filterDataSet('1W');
                if (props?.changeUnit) {
                  props.changeUnit('1W');
                }
              }}
              style={{ color: clickedItem === '1W' ? colors.secondary : colors.neutral30, boxShadow: 'none' }}
            >
              {t('timeValues.1W')}
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item
              onClick={() => {
                sendAnalyticsEvent(ovAnalyticsEvents.homePageChart1MSelect).then();
                setClickedItem('1M');
                filterDataSet('1M');
                if (props?.changeUnit) {
                  props.changeUnit('1M');
                }
              }}
              style={{ color: clickedItem === '1M' ? colors.secondary : colors.neutral30, boxShadow: 'none' }}
            >
              {t('timeValues.1M')}
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item
              onClick={() => {
                sendAnalyticsEvent(ovAnalyticsEvents.homePageChart3MSelect).then();
                setClickedItem('3M');
                filterDataSet('3M');
                if (props?.changeUnit) {
                  props.changeUnit('3M');
                }
              }}
              style={{ color: clickedItem === '3M' ? colors.secondary : colors.neutral30, boxShadow: 'none' }}
            >
              {t('timeValues.3M')}
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item
              onClick={() => {
                sendAnalyticsEvent(ovAnalyticsEvents.homePageChart1YSelect).then();
                setClickedItem('1Y');
                filterDataSet('1Y');
                if (props?.changeUnit) {
                  props.changeUnit('1Y');
                }
              }}
              style={{ color: clickedItem === '1Y' ? colors.secondary : colors.neutral30, boxShadow: 'none' }}
            >
              {t('timeValues.1Y')}
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item
              onClick={() => {
                sendAnalyticsEvent(ovAnalyticsEvents.homePageChartAllSelect).then();
                setClickedItem('ALL');
                filterDataSet('ALL');
                if (props?.changeUnit) {
                  props.changeUnit('ALL');
                }
              }}
              style={{ color: clickedItem === 'ALL' ? colors.secondary : colors.neutral30, boxShadow: 'none' }}
            >
              {t('timeValues.ALL')}
            </Item>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
    </Box>
  );
};

HistoryChart.defaultProps = {
  title: '',
  changeUnit: undefined,
  disableTooltip: undefined,
  includeAllHouseholds: false,
};

export default HistoryChart;
