/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, SxProps } from '@mui/system';
import { Theme } from '@mui/system/createTheme';
import { CircularProgress } from '@mui/material';

interface Props {
  children: React.ReactNode,
  sx?: SxProps<Theme>,
  nextPage: (e: React.UIEvent<HTMLDivElement>) => void,
  loading: boolean,
}

const OvPagination = ({
  children,
  sx,
  nextPage,
  loading,
} : Props): JSX.Element => {
  const handleScroll = async (e: any): Promise<void> => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= (e.target.clientHeight + 2);
    if (bottom) {
      nextPage(e);
    }
  };

  return (
    <Box sx={sx} onScroll={((event) => handleScroll(event))}>
      {children}
      {
        loading ? (
          <Box display="flex" alignSelf="center" justifyContent="center" mt={2}>
            <CircularProgress data-testid="loading-element" />
          </Box>
        ) : undefined
      }
    </Box>
  );
};

OvPagination.defaultProps = {
  sx: undefined,
};
export default OvPagination;
