import { FormEvent, useEffect, useState } from 'react';
import {
  Typography,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import SimpleInput from '../../common/inputs/simpleInput';
import { UPDATE_USER } from '../graphql';
import { ForeignTaxInformation } from './foreignTax';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  foreignTaxInformation?: ForeignTaxInformation[],
  incompleteFields?: string[],
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  goToNamedStep?: (step: string) => void,
  goBack?: () => void,
  cached?: boolean,
}

const ForeignTaxNumber = ({
  user, updateUserState, cached, goToNamedStep, ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(true);
  const [foreignTaxNumber, setForeignTaxNumber] = useState('');
  const [init, setInit] = useState(true);
  const checkFieldValidity = (): boolean => {
    const isValid = foreignTaxNumber.length > 0;
    setValid(isValid);
    return isValid;
  };
  const modifyTaxArray = (val: string): ForeignTaxInformation[] => {
    const newTaxArr: ForeignTaxInformation[] = [];
    user.foreignTaxInformation?.forEach((tax: ForeignTaxInformation) => {
      if (tax.foreignTaxNumber === '') {
        newTaxArr.push({
          foreignTaxCountry: tax.foreignTaxCountry,
          foreignTaxNumber: val,
        });
      } else {
        newTaxArr.push({
          foreignTaxCountry: tax.foreignTaxCountry,
          foreignTaxNumber: tax.foreignTaxNumber,
        });
      }
    });
    return newTaxArr;
  };
  const foreignTaxInformationOnCompleted = (): void => {
    console.log({ event: 'FOREIGN_TAX_INFORMATION_UPDATED' });
    setLoading(false);
    if (goToNamedStep) goToNamedStep('Assumptions');
  };
  const [foreignTaxInformationMutation] = useMutation(UPDATE_USER, {
    variables: {
      input: { foreignTaxInformation: user.foreignTaxInformation },
    },
    onCompleted: foreignTaxInformationOnCompleted,
    onError: () => setLoading(false),
  });
  const backToCountryClick = (): void => {
    if (!checkFieldValidity()) return;
    updateUserState({ ...user, foreignTaxInformation: modifyTaxArray(foreignTaxNumber) });
    if (props.goBack) props.goBack();
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const foreignTaxInfo = user.foreignTaxInformation!.find((f) => f.foreignTaxNumber === '');
    if (init && !foreignTaxInfo && goToNamedStep) {
      foreignTaxInformationMutation({
        variables: {
          input: {
            foreignTaxInformation: (user.foreignTaxInformation ?? []).map((elem) => ({
              foreignTaxCountry: elem.foreignTaxCountry,
              foreignTaxNumber: elem.foreignTaxNumber,
            })),
          },
        },
      }).then();
      setTimeout(() => {
        goToNamedStep('Assumptions');
      }, 10);
    }
    setInit(false);
  }, [foreignTaxInformationMutation, goToNamedStep, user.foreignTaxInformation, init]);

  const onContinueClick = (event : FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    if (!checkFieldValidity()) return;
    const foreignTaxInformation = modifyTaxArray(foreignTaxNumber);
    updateUserState({ ...user, foreignTaxInformation });
    if (cached) {
      if (goToNamedStep) goToNamedStep('Assumptions');
    } else {
      setLoading(true);
      foreignTaxInformationMutation({
        variables: {
          input: { foreignTaxInformation },
        },
      });
    }
  };
  const isButtonDisabled = !foreignTaxNumber || foreignTaxNumber.trim() === '';
  return (
    <OvForm onSubmit={onContinueClick} loading={loading} disableButton={isButtonDisabled}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: '1 0 auto' }}>
          <Typography variant="heading2">{t('user:foreignTaxNumber.title')}</Typography>
          <Typography variant="paragraph3">{t('user:foreignTaxNumber.subTitle')}</Typography>
          <SimpleInput
            label={t('user:foreignTaxNumber.label')}
            value={foreignTaxNumber}
            subtitle={valid ? '' : 'Please enter a valid tax identification number.'}
            error={!valid}
            required
            style={{ marginBottom: 16 }}
            onChange={(event) => {
              setForeignTaxNumber(event.target.value);
            }}
            testId="tax-input"
            onClear={() => {
              setForeignTaxNumber('');
              setValid(true);
            }}
          />
        </div>
        <Button variant="secondary-large" disabled={isButtonDisabled} data-testid="continue-country-button" onClick={backToCountryClick} style={{ marginBottom: 16 }}>{t('base:button.addCountry')}</Button>
      </div>
    </OvForm>
  );
};

ForeignTaxNumber.defaultProps = {
  goToNamedStep: undefined,
  goBack: undefined,
  cached: false,
};

export default ForeignTaxNumber;
