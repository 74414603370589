import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { Box } from '@mui/system';
import { FormEvent } from 'react';
import OvForm from '../common/wrappers/ovForm';
import checkSvg from '../../assets/images/check.svg';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';

interface Props {
  onContinue?: () => void,
}

const Confirmation = ({ onContinue }: Props): JSX.Element => {
  const { t } = useTranslation(['account']);

  const onConfirm = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    sendAnalyticsEvent(ovAnalyticsEvents.rrifInformationSavedModalOkButtonSelect).then();
    if (onContinue) onContinue();
  };
  return (
    <OvForm onSubmit={onConfirm} loading={false} buttonText={t('account:rrifSteps.confirmation.button')}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: '1 0 auto', textAlign: 'center' }}>
          <img src={checkSvg} alt="check" />
          <Typography variant="heading2" textAlign="center" style={{ marginBottom: 16 }}>{t('account:rrifSteps.confirmation.title')}</Typography>
          <Typography variant="bodySub2">{t('account:rrifSteps.confirmation.subTitle')}</Typography>
          <Box mt={4} />
        </div>
      </div>
    </OvForm>
  );
};

Confirmation.defaultProps = {
  onContinue: undefined,
};

export default Confirmation;
