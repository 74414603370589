import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { colors } from '../../theme/colors';

export const styles = {
  leftCage: {
    border: '1.25px solid #F0F4F7',
  },
  subAccountDetailsContainer: {
    width: '100%',
    typography: 'body1',
    padding: '0px !important',
    boxShadow: 'none !important',
  },
  detailBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  listItemWrapper: {
    background: colors.ovWhite,
  },
  cageWrapper: {
    width: '100%',
    display: 'inline-grid',
    justifyContent: 'center',
    textAlign: 'center',
    height: '170px',
  },
  pendingText: {
    color: colors.neutral30,
    marginTop: '11px',
  },
  pendingIcon: {
    color: colors.neutral30,
    marginRight: '4.33px',
    fontSize: '14px',
    top: '2px',
    position: 'relative',
  },
  targetAmountCage: {
    background: '#FFFFFF',
    border: '1px solid rgba(10, 46, 101, 0.09)',
    boxSizing: 'border-box',
    boxShadow: '3px 6px 10px rgba(0, 102, 245, 0.03)',
    borderRadius: '4px',
    height: '60px',
    position: 'relative',
    padding: '17px',
  },
  targetIcon: {
    width: '20px',
    position: 'relative',
    top: '-8px',
  },
  dotMenue: {
    color: colors.secondary,
    fontSize: '1rem',
    cursor: 'pointer',
  },
  dotMenueWrapper: {
    marginRight: '25px',
    borderRadius: '5px',
    position: 'relative',
    width: '24px',
    top: '-7px',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '16px',
    marginBottom: '16px',
  },
  detailWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  historyWrapper: {
    margingTop: '24px',
    height: '260px',
    paddingLeft: '10px',
  },
  historyTitle: {
    margingBottom: '12px',
    color: colors.neutral20,
    textAlign: 'left',
    padding: '10px',
  },
  autoInvestTitle: {
    margingBottom: '12px',
    color: colors.neutral20,
    textAlign: 'left',
  },
  headerText: {
    color: colors.autoInvestmentSummaryHeader,
    margin: 0,
  },
  targetMessage: {
    textAlign: 'left',
    marginTop: '12px',
    borderBottom: '1px dashed #DDD9D9',
    paddingBottom: '12px',
  },
  enableAutoInvestButton: {
    height: '60px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    border: '1px solid rgba(10, 46, 101, 0.09)',
    borderRadius: '4px',
    boxShadow: '3px 6px 10px rgba(0, 102, 245, 0.03)',
    chevronIcon: {
      color: colors.ovBlack,
    },
    box: {
      image: {
        marginRight: '18px',
      },
      display: 'flex',
      flexDirection: 'row' as const,
      alignItems: 'center',
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '398px',
    bgcolor: colors.ovWhite,
    borderRadius: '6px',
    height: '380px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.07)',
    outline: 'none',
  },
  performanceModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '398px',
    bgcolor: colors.ovWhite,
    borderRadius: '6px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.07)',
    height: '94vh',
    overflowY: 'auto',
    outline: 'none',
  },
  closeBox: {
    width: '26px',
    height: '26px',
    top: '-6px',
    borderRadius: '3.42px',
    background: 'rgba(240, 244, 248, 0.5)',
    marginTop: '18px',
    marginLeft: '36px',
    marginRight: '36px',
    marginBottom: '22px',
  },
  headingText: {
    marginBottom: '16px',
    textAlign: 'center',
  },
  subText: {
    marginBottom: '43px',
    textAlign: 'center',
  },
  inputLabel: {
    marginLeft: '36px',
    marginRight: '36px',
    marginBottom: '8px',
  },
  input: {
    marginLeft: '36px',
    width: '334px',
    marginRight: '36px',
    border: 'none',
    borderBottom: '1px solid #D3DEEA',
    marginBottom: '53px',
  },
  button: {
    height: '52px',
    width: '326px',
    left: 'calc(50% - 326px/2)',
    position: 'relative',
    marginTop: '0px',
  },
  goalEditButton: {
    width: '100%',
    marginTop: '53px',
    position: 'relative',
    bottom: 0,
    left: 0,
    right: 0,
  },
  portfolioText: {
    display: 'flex',
    justifyContent: 'left',
    marginBottom: '14px',
  },
  investButton: {
    height: '38px',
    width: '149px !important',
    position: 'relative',
    cursor: 'pointer',
  },
  withdrawButton: {
    height: '38px',
    width: '149px',
    position: 'relative',
    cursor: 'pointer',
    margin: '0px 0px 0px 16px !important',
  },
  inputStyle: {
    border: 'none !important',
    fontSize: '26px !important',
    borderBottom: '1px solid #D3DEEA !important',
    textAlign: 'left' as const,
    marginLeft: '36px !important',
    marginRight: '36px !important',
    padding: 0,
  },
  menuIcon: {
    position: 'relative',
    cursor: 'pointer',
    color: colors.secondary,
    fontSize: '1.5rem',
    padding: '2px 1px',
    background: '#F0F4F8',
    borderRadius: '5.25px',
  },
  addPortfolioWrapper: {
    background: '#FDFEFE',
    border: '1.5px dashed #2CA58D',
    boxShadow: '3px 6px 10px rgba(0, 102, 245, 0.03)',
    borderRadius: '10px',
    width: '153px',
    height: '123px',
    padding: '12px',
  },
  addPortfolioContent: {
    display: 'inline-grid',
    width: 'auto',
    height: 'auto',
    margin: '0 auto',
    padding: '10px',
    position: 'relative',
    top: '20px',
    textAlign: 'center',
  },
  addPortfolioIcon: {
    position: 'relative' as const,
    left: '30px',
    width: '24px',
    marginBottom: '5px',
    height: '24px',
  },
  addPortfolioText: {
    color: colors.success,
  },
  underline: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: colors.link,
  },
  relationText: {
    display: 'flex',
    justifyContent: 'left',
    marginLeft: '20px',
    marginTop: '20px',
  },
  affiliateText: {
    display: 'flex',
    justifyContent: 'left',
    marginLeft: '20px',
    marginTop: '10px',
    marginBottom: '5px',
  },
  dateText: {
    display: 'flex',
    justifyContent: 'left',
    marginBottom: '20px',
    marginLeft: '20px',
  },
};

export const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: colors.neutral30,
  boxShadow: 'none',
  cursor: 'pointer',
  borderRadius: '8px',
}));
