import { useState, useContext } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import WithStepWizard from '../common/wrappers/withStepWizard';
import CreateGoalWizard from './createGoalWizard';
import CreateSubAccountWizard, { SubAccountRef } from '../account/createSubAccountWizard';
import TrackingBar from '../common/headers/trackingBar';
import { UserContext } from '../../providers/userContextProvider';
import { FeatureFlagTypes, isFeatureEnabled } from '../account/resources';

const CreateGoalWizardStep = WithStepWizard(CreateGoalWizard);
const CreateSubAccountWizardStep = WithStepWizard(CreateSubAccountWizard);

interface Props {
  onContinue?: () => void,
  goBack?: () => void,
  onStepChanged?: (step: number) => void,
  onSubAccountCreated?: (subAccount: Partial<SubAccountRef>) => void,
  showExistingRespFlow?: boolean,
}

const CreateGoalAndSubAccountWizard = ({
  onContinue, goBack, onStepChanged, onSubAccountCreated, showExistingRespFlow,
}: Props): JSX.Element => {
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const [goalId, setGoalId] = useState<string>();
  const { userContext } = useContext(UserContext);

  const subAccount = userContext.subAccounts?.find((sa) => sa.goal?.id === goalId);
  const steps: JSX.Element[] = [
    <CreateGoalWizardStep
      onStepChanged={(step) => onStepChanged && onStepChanged(step)}
      stepProps={{ absoluteTotalSteps: 26 }}
      key="Goal"
      goalId={goalId}
      setGoalId={setGoalId}
      disableTracker
      stepTitle="Your goals"
      goBack={goBack}
      action={(callback) => {
        if (isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_SUB_ACCOUNTS)) {
          callback();
        } else if (onContinue) onContinue();
      }}
    />,
    ...(isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_SUB_ACCOUNTS) ? [
      <CreateSubAccountWizardStep
        stepProps={{ absoluteTotalSteps: 26, currentStep: 6 }}
        key="SubAccount"
        goalId={goalId ?? ''}
        subAccount={subAccount}
        disableTracker
        stepTitle="Your goals"
        action={onContinue}
        onSubAccountCreated={onSubAccountCreated}
        showExistingRespFlow={showExistingRespFlow}
      />,
    ] : []),
  ];

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      transitions={{}}
      className="ov-step-wizard"
      nav={(
        <TrackingBar steps={steps} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

CreateGoalAndSubAccountWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  onStepChanged: undefined,
  onSubAccountCreated: undefined,
  showExistingRespFlow: undefined,
};

export default CreateGoalAndSubAccountWizard;
