import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FaqIcon from '../../../assets/images/menu/faq.svg';
import ContactIcon from '../../../assets/images/menu/contact.svg';
import { styles } from './styles';
import { orgHelpCentreUrlVar, orgSupportUrlVar } from '../../../utils/localVariables';

export interface HelpModalProps {
  email: string,
  hideZendesk?: boolean,
  hideFaq?: boolean,
  title?: string,
  subTitle?: string,
}
const HelpModal = ({
  email,
  hideZendesk,
  hideFaq,
  title,
  subTitle,
}: HelpModalProps): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Typography variant="headingMain" style={styles.titleStyle}>{title || t('user:helpModal.title')}</Typography>
          {subTitle ? (<Typography variant="bodySub2" style={styles.titleStyle}>{subTitle}</Typography>) : null}
          {hideFaq ? null : (
            <Box sx={styles.inputBox} style={{ cursor: 'pointer' }} data-testid="faq-div" onClick={() => window.open(orgHelpCentreUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us', '_blank')}>
              <Box>
                <img src={FaqIcon} alt="faqIcon" style={styles.iconStyle} height={20} width={20} />
                <Typography variant="bodySub2" style={styles.helpText}>{t('user:helpModal.faq')}</Typography>
              </Box>
              <Box>
                <ChevronRightIcon style={styles.clickPointer} />
              </Box>
            </Box>
          )}
          {hideZendesk ? null : (
            <Box sx={styles.inputBox} style={{ cursor: 'pointer' }} data-testid="support-div" onClick={() => window.open(orgSupportUrlVar() ?? `https://onevest.zendesk.com/hc/en-us/requests/new?tf_anonymous_requester_email=${encodeURIComponent(email)}`, '_blank')}>
              <Box>
                <img src={ContactIcon} alt="faqIcon" style={styles.iconStyle} height={20} width={20} />
                <Typography variant="bodySub2" style={styles.helpText}>{t('user:helpModal.support')}</Typography>
              </Box>
              <Box>
                <ChevronRightIcon style={styles.clickPointer} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

HelpModal.defaultProps = {
  hideZendesk: undefined,
  hideFaq: undefined,
  title: undefined,
  subTitle: undefined,
};

export default HelpModal;
