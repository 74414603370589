import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import folderStorageImage from '../../assets/images/folder-file-storage.svg';

const WaitingForAdvisorApproval = (): JSX.Element => {
  const { t } = useTranslation(['user']);
  return (
    <Box display="flex" flexDirection="column" textAlign="center" alignItems="center">
      <img src={folderStorageImage} alt="folder" />
      <Box mt={3} sx={{ height: '0px !important' }} />
      <Typography variant="heading2">{t('user:noAgreements.title')}</Typography>
      <Typography variant="bodySub2">
        {t('user:noAgreements.body')}
      </Typography>
      <Box marginBottom={4} sx={{ height: '0px !important' }} />
    </Box>
  );
};

export default WaitingForAdvisorApproval;
