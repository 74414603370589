import {
  Box,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import React from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { colors } from '../../../theme/colors';

const styles = {
  outerButton: {
    width: '100%',
    display: 'flex' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${colors.portfolioCardBorder}`,
    background: 'white',
    backgroundColor: colors.baseComponentBackground,
    color: colors.baseText,
  },
  textBox: {
    display: 'flex' as const,
    flexDirection: 'column' as const,
    alignItems: 'start' as const,
  },
  titleText: {
    margin: '0 0 6px 0',
  },
  subtitleBox: {
    display: 'flex' as const,
  },
  subtitleText: {
    margin: '0 5px 0 0',
  },
  icon: {
    width: '0.9em',
    color: colors.neutralBlack,
  },
  divider: {
    height: '1px',
    width: '100%',
    marginBottom: '8px',
    // borderTop: '1px dashed #DDD9D9',
    borderBottom: `0px dashed ${colors.transparent}`,
    backgroundColor: colors.transparent,
  },
};

interface Props {
  title: string,
  subtitle: string,
  icon?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  hasDivider: boolean,
}

const PortfolioPreferenceListItem = ({
  title,
  subtitle,
  icon,
  onClick,
  hasDivider,
}: Props): JSX.Element => {
  const listItemUI = (): JSX.Element => (
    <Button style={styles.outerButton} disableRipple onClick={onClick}>
      <Box style={styles.textBox}>
        <Typography variant="captionDescriptorMedium" style={styles.titleText}>
          {title}
        </Typography>
        <Box style={styles.subtitleBox}>
          <Typography variant="bodySub1" style={styles.subtitleText}>
            {subtitle}
          </Typography>
          { onClick ? <ChevronRightRoundedIcon style={styles.icon} /> : null }
        </Box>
      </Box>
      <Box>
        { icon ? <img src={icon} alt="icon" height={45} width={65} /> : null }
      </Box>
    </Button>
  );

  return (
    <>
      {listItemUI()}
      {hasDivider ? <Divider style={styles.divider} /> : null}
    </>
  );
};

PortfolioPreferenceListItem.defaultProps = {
  icon: undefined,
  onClick: undefined,
};

export default PortfolioPreferenceListItem;
