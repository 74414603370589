import React, { useState } from 'react';
import {
  Box,
  Button,
  List,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HoldingResponse, HoldingAttributes, mapHoldingAttributes } from './resources';
import { styles } from './styles';
import FeedbackModal from '../common/wrappers/modals/ovFeedbackModal';
import { useContextTheme } from '../../providers/contextThemeProvider';
import HoldingsListItem from '../common/lists/holdingsListItem';

export interface HoldingsListProps {
  holdings: HoldingResponse[],
  listClassName?: string,
}

const HoldingsList = ({ holdings, ...props }: HoldingsListProps): JSX.Element => {
  const { t } = useTranslation(['base', 'user', 'transfer']);
  const { colors } = useContextTheme();
  const mappedHoldings = mapHoldingAttributes(holdings.filter((h) => (h.percentage !== 0)), colors);
  const [displayAssertClassDescriptionModal, setDisplayAssertClassDescriptionModal] = useState(false);
  const [modalHolding, setModalHolding] = useState({
    percentage: 0,
    title: '',
    description: '',
  });
  const showAsserClassDialod = (): void => {
    setDisplayAssertClassDescriptionModal(true);
  };

  const dialogClose = (): void => {
    setDisplayAssertClassDescriptionModal(false);
  };
  if (displayAssertClassDescriptionModal) {
    return (
      <FeedbackModal
        open={displayAssertClassDescriptionModal}
        onClose={dialogClose}
        component={(
          <Box
            data-testid="holding-popup"
          >
            <Typography variant="heading2" sx={styles.headingText}>
              {Number(modalHolding.percentage.toFixed(2))}
              %
            </Typography>
            <Typography variant="heading3" sx={styles.headingText}>
              {modalHolding.title}
            </Typography>
            <Typography variant="captionSectionerMedium" sx={styles.subText}>
              {modalHolding.description}
            </Typography>
            <Box sx={styles.goalEditButton}>
              <Button variant="primary-large" onClick={dialogClose}>{t('base:button.gotIt')}</Button>
            </Box>
          </Box>
        )}
      />
    );
  }
  return (
    <List sx={styles.listWrapper} className={props.listClassName}>
      {mappedHoldings.filter((h) => (h.percentage !== 0)).sort((a, b) => (b.percentage - a.percentage)).map((item: HoldingAttributes) => (
        <HoldingsListItem
          key={item.key}
          hexCode={item.hexColor}
          holdingsType={item.secondaryAssetClassName}
          percent={item.percentage}
          onClick={() => {
            setModalHolding({
              title: item.secondaryAssetClassName,
              description: item.secondaryAssetClassDescription,
              percentage: item.percentage,
            });
            showAsserClassDialod();
          }}
        />
      ))}
    </List>
  );
};

HoldingsList.defaultProps = {
  listClassName: '',
};

export default HoldingsList;
