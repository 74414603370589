import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useContext, useRef, useState } from 'react';
import {
  useApolloClient, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash/fp';
import intersection from 'lodash/intersection';
import WithStepWizard, { StepProps } from '../common/wrappers/withStepWizard';
import TrackingBar from '../common/headers/trackingBar';
import StartCheckOne from './startCheckOne';
import StartCheckTwo from './startCheckTwo';
import PersonalDetails from './personalDetails';
import SelecteEmployement from '../user/employment/selectEmployement';
import EmployeeInfo from '../user/employment/employeeInfo';
import EmployeeJobTitle from '../user/employment/employeeJobTitle';
import AreaOfStudies from '../user/employment/areaOfStudies';
import {
  ANNUAL_SUITABILITY_COMPLETED,
  INCOMPLETE_FORM_AGREEMENT,
  ME_FOR_SUITABILITY_REVIEW_PROPERTIES,
  UPDATE_USER,
} from './graphql';
import Assumptions from '../user/assumptions/assumptions';
import Citizenship from '../user/assumptions/citizenship';
import ForeignTax, { ForeignTaxInformation } from '../user/assumptions/foreignTax';
import ForeignTaxNumber from '../user/assumptions/foreignTaxNumber';
import FinancialInformation from './financialInformation';
import InvestmentPreferences from './investmentPreferences';
import { InvestmentKnowledge, RiskQuestion1, RiskQuestion2 } from '../user';
import { InvestmentKnowledges, RiskLevels } from '../user/resources';
import ConfirmGoals from './confirmGoals';
import { GoalRiskLevels, GoalTimeHorizons } from '../goal/resources';
import RiskQuestion from '../goal/riskQuestion';
import { GoalRef } from '../goal/createGoalWizard';
import TimeHorizon from '../goal/timeHorizon';
import { UPDATE_GOAL } from '../goal/graphql';
import { userIdVar } from '../../utils/localVariables';
import IncompleteAgreements from '../documents/incompleteAgreements';
import { UserContext } from '../../providers/userContextProvider';
import { FETCH_GOAL_SUGGESTED_HOLDINGS, UPDATE_SUB_ACCOUNT } from '../account/graphql';
import { Account } from '../../utils/commonGraphql';
import ConfirmSubAccountTransitions from './confirmSubAccountTransitions';

const StartCheckOneStep = WithStepWizard(StartCheckOne);
const StartCheckTwoStep = WithStepWizard(StartCheckTwo);
const PersonalDetailsStep = WithStepWizard(PersonalDetails);
const SelectEmploymentStep = WithStepWizard(SelecteEmployement);
const EmployeeInfoStep = WithStepWizard(EmployeeInfo);
const EmployeeJobTitleStep = WithStepWizard(EmployeeJobTitle);
const AreaOfStudiesStep = WithStepWizard(AreaOfStudies);
const AssumptionsStep = WithStepWizard(Assumptions);
const CitizenshipStep = WithStepWizard(Citizenship);
const ForeignTaxStep = WithStepWizard(ForeignTax);
const ForeignTaxNumberStep = WithStepWizard(ForeignTaxNumber);
const FinancialInformationStep = WithStepWizard(FinancialInformation);
const InvestmentPreferencesStep = WithStepWizard(InvestmentPreferences);
const InvestmentKnowledgeStep = WithStepWizard(InvestmentKnowledge);
const RiskQuestion1Step = WithStepWizard(RiskQuestion1);
const RiskQuestion2Step = WithStepWizard(RiskQuestion2);
const ConfirmGoalsStep = WithStepWizard(ConfirmGoals);
const ConfirmSubAccountTransitionsStep = WithStepWizard(ConfirmSubAccountTransitions);
const RiskQuestionStep = WithStepWizard(RiskQuestion);
const TimeHorizonStep = WithStepWizard(TimeHorizon);
const IncompleteAgreementsStep = WithStepWizard(IncompleteAgreements);

export interface SubAccount {
  id: string,
  name?: string,
  theme?: { key: string, translatedName: { en: string, fr: string }, id: string },
  suggestedFinancialProduct?: { id: string, name: string },
  acceptableFinancialProduct?: boolean,
  financialProduct?: { id: string, name: string },
  account?: Account,
}

interface GoalProps {
  id: string
  name: string,
  riskQuestion1: GoalRiskLevels,
  timeHorizon: GoalTimeHorizons,
  subAccounts: SubAccount[]
}

const goalPropertiesToBeUpdated = ['riskQuestion1', 'timeHorizon'];
const userPropertiesToBeUpdated = [
  'employmentStatus',
  'companyType',
  'jobTitle',
  'studentAreaOfStudy',
  'citizenships',
  'foreignTaxInformation',
  'powerOfAttorneyGranted',
  'isMemberOfIiroc',
  'isOfficerOfPublicCompany',
  'isOwnerOfPublicCompany',
  'politicallyExposedDomesticPerson',
  'headOfInternationalOrganization',
  'politicallyExposedForeignPerson',
  'closeAssociateOfPEP',
  'annualIncomeCents',
  'financialLiquidAssetsCents',
  'financialFixedAssetsCents',
  'totalDebtCents',
  'annualDebtPaymentsCents',
  'investmentKnowledge',
  'riskQuestion1',
  'riskQuestion2',
];
export interface UserState {
  employmentStatus?: string,
  companyType?: string,
  jobTitle?: string,
  studentAreaOfStudy?: string,
  citizenships?: string[],
  foreignTaxInformation?: ForeignTaxInformation[],
  powerOfAttorneyGranted?: boolean,
  isMemberOfIiroc?: boolean,
  isOfficerOfPublicCompany?: boolean,
  isOwnerOfPublicCompany?: boolean,
  politicallyExposedDomesticPerson?: boolean,
  headOfInternationalOrganization?: boolean,
  politicallyExposedForeignPerson?: boolean,
  closeAssociateOfPEP?: boolean,
  annualIncomeCents?: number,
  financialLiquidAssetsCents?: number,
  financialFixedAssetsCents?: number,
  totalDebtCents?: number,
  annualDebtPaymentsCents?: number,
  investmentKnowledge?: InvestmentKnowledges,
  riskQuestion1?: RiskLevels,
  riskQuestion2?: RiskLevels,
  goals?: GoalProps[],
}

export interface AlternativeSteps {
  isCitizenshipStepRequired?: boolean,
  isForeignTaxStepRequired?: boolean,
}

interface Props {
  initStep?: number,
  onClose: () => void,
  onCompleted: () => void,
  stepProps?: StepProps,
  stepTitle?: string,
}

const SuitabilityReviewWizard = ({
  onClose, stepTitle, stepProps, initStep, onCompleted,
}: Props): JSX.Element => {
  const { t } = useTranslation(['suitabilityReview']);
  const { userContext, setUserContext } = useContext(UserContext);
  const graphqlClient = useApolloClient();
  const [user, setUser] = useState<UserState>({});
  const [loading, setLoading] = useState(false);
  const [updatedGoals, setUpdatedGoals] = useState<GoalProps[]>([]);
  const [originalData, setOriginalData] = useState<UserState>({});
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const goal = useRef<GoalRef>({});
  const [currentGoal, setCurrentGoal] = useState<GoalProps|undefined>(undefined);
  const setGoalRef = (att: Partial<GoalRef>): void => {
    goal.current = { ...goal.current, ...att };
    if (currentGoal) {
      setCurrentGoal({ ...currentGoal, ...att });
    }
    // update goal
    const newGoals = user.goals?.map((item) => (item.id === goal.current.id ? goal.current as GoalProps : item)) ?? [];
    setUser({ ...user, goals: newGoals });
  };
  const [fetchSuggestedHoldings] = useLazyQuery(FETCH_GOAL_SUGGESTED_HOLDINGS, {
    nextFetchPolicy: 'standby',
    variables: { goalId: '', theme: '' },
  });
  const [updateSubAccount] = useMutation(UPDATE_SUB_ACCOUNT, {
    variables: { input: { subAccountId: '', financialProductId: '' } },
  });
  const alternativeSteps = useRef<AlternativeSteps>({});
  const setAlternativeSteps = (att: AlternativeSteps): void => {
    alternativeSteps.current = { ...alternativeSteps.current, ...att };
  };
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const cacheUserChoice = (att: UserState): void => {
    setUser((prevState) => ({ ...prevState, ...att }));
  };
  const { refetch } = useQuery(ME_FOR_SUITABILITY_REVIEW_PROPERTIES, {
    variables: {
      input: {},
    },
    onCompleted: (response) => {
      setUser(response.me.user);
      setOriginalData(response.me.user);
    },
    nextFetchPolicy: 'standby',
  });

  const [getIncompleteFormAgreement] = useLazyQuery(INCOMPLETE_FORM_AGREEMENT, {
    variables: {
      input: {},
    },
    nextFetchPolicy: 'standby',
  });

  const [annualSuitabilityMutation] = useMutation(ANNUAL_SUITABILITY_COMPLETED, {
    variables: {
      userId: {},
    },
  });

  const isGoalsUpdated = useRef(false);
  const getChangedGoals = (): GoalProps[] => user.goals?.filter((item) => {
    const foundGoal = originalData.goals?.find((data) => data.id === item.id);
    return JSON.stringify(item) !== JSON.stringify(foundGoal);
  }) ?? [];

  const updateGoalOnServer = async (): Promise<void> => {
    setUpdatedGoals([]);
    isGoalsUpdated.current = false;
    getChangedGoals().map(async (item) => {
      isGoalsUpdated.current = true;
      setUpdatedGoals([...updatedGoals, item]);
      await graphqlClient.mutate({
        mutation: UPDATE_GOAL,
        variables: {
          input: {
            ...pick(goalPropertiesToBeUpdated, item),
            goalId: item.id,
          },
        },
      });
    });
  };

  const updateSubAccountOnServer = async (): Promise<void> => {
    user.goals?.map(async (item) => {
      const response = await graphqlClient.mutate({
        mutation: UPDATE_GOAL,
        variables: {
          input: {
            ...pick(goalPropertiesToBeUpdated, item),
            goalId: item.id,
          },
        },
      });
      await Promise.all(item?.subAccounts.map(async (sa) => {
        const result = await fetchSuggestedHoldings({ variables: { goalId: response.data.updateGoal.goal.id, theme: sa.theme?.id ?? '' } });
        if (result.data.fetchGoal.goal.suggestedFinancialProduct.id !== sa.theme?.id && !sa.acceptableFinancialProduct) {
          await updateSubAccount({
            variables: { input: { subAccountId: sa.id, financialProductId: result.data.fetchGoal.goal.suggestedFinancialProduct.id } },
          }).then();
        }
      }));
    });
  };

  const updateAnnualSuitability = async (): Promise<void> => {
    await annualSuitabilityMutation({ variables: { userId: userIdVar() } }).then();
    setLoading(false);
    onCompleted();
    onClose();
  };

  const handleInformationUpToDate = async (): Promise<void> => {
    await annualSuitabilityMutation({ variables: { userId: userIdVar() } }).then();
    onClose();
  };

  const finalizeUpdateUserOnServer = async (callback: () => void): Promise<void> => {
    setLoading(true);
    await updateSubAccountOnServer().then();
    await new Promise((resolve): void => {
      setTimeout(resolve, 2000);
    });
    refetch();
    const agreements = await getIncompleteFormAgreement();
    if (agreements.data.me.user.allIncompleteFormAgreements.length > 0) {
      setUserContext({
        ...userContext,
        allIncompleteFormAgreements: agreements.data.me.user.allIncompleteFormAgreements,
        accounts: agreements.data.me.user.accounts.filter((g: { state: string }) => !['CANCELED', 'INACTIVE'].includes(g.state ?? '')),
        subAccounts: agreements.data.me.user.subAccounts.filter((g: { state: string; }) => g.state !== 'INACTIVE'),
      });
      setLoading(false);
      callback();
    } else {
      await updateAnnualSuitability();
    }
  };

  const updateUserOnServer = async (callback: () => void): Promise<void> => {
    setLoading(true);
    const changedObjects = (Object.keys(user) as (keyof typeof user)[]).filter((key) => user[key] !== originalData[key]);
    if (changedObjects.length > 0) {
      const filteredUpdateUserProps = intersection(userPropertiesToBeUpdated, changedObjects);
      if (filteredUpdateUserProps.length > 0) {
        await graphqlClient.mutate({
          mutation: UPDATE_USER,
          variables: { input: pick(filteredUpdateUserProps, user) },
        });
      }
      if (changedObjects.includes('goals')) {
        await updateGoalOnServer().then();
        await new Promise((resolve): void => {
          setTimeout(resolve, 2000);
        });
        refetch();
      }
      await new Promise((resolve): void => {
        setTimeout(resolve, 2000);
      });
      const agreements = await getIncompleteFormAgreement();
      if (agreements.data.me.user.allIncompleteFormAgreements.length > 0) {
        setUserContext({
          ...userContext,
          allIncompleteFormAgreements: agreements.data.me.user.allIncompleteFormAgreements,
          accounts: agreements.data.me.user.accounts.filter((g: { state: string }) => !['CANCELED', 'INACTIVE'].includes(g.state ?? '')),
          subAccounts: agreements.data.me.user.subAccounts.filter((g: { state: string; }) => g.state !== 'INACTIVE'),
        });
      }
    }
    setLoading(false);
    callback();
  };
  /* eslint-disable no-confusing-arrow */
  const steps: JSX.Element[] = [
    <StartCheckOneStep stepTitle={stepTitle} stepName="startCheckOne" key="startCheckOne" onInformationUpToDate={handleInformationUpToDate} />,
    <StartCheckTwoStep stepTitle={stepTitle} stepName="startCheckTwo" key="startCheckTwo" onInformationUpToDate={handleInformationUpToDate} />,
    <PersonalDetailsStep user={user} stepTitle={stepTitle} stepName="personalDetails" key="personalDetails" continueToNamedStep={() => 'Assumptions'} goBackToNamedStep={() => (initStep === 1 ? 'startCheckOne' : 'startCheckTwo')} />,
    <SelectEmploymentStep cached stepName="selectEmployment" key="SelectEmployment" user={user} updateUserState={cacheUserChoice} stepTitle={stepTitle} />,
    <EmployeeInfoStep cached stepName="employeeInfo" key="employeeInfo" user={user} updateUserState={cacheUserChoice} stepTitle={stepTitle} />,
    <EmployeeJobTitleStep cached stepName="employeeJobTitle" key="employeeJobTitle" user={user} updateUserState={cacheUserChoice} stepTitle={stepTitle} continueToNamedStep={() => ('personalDetails')} />,
    <AreaOfStudiesStep stepName="areaOfStudies" key="areaOfStudies" user={user} updateUserState={cacheUserChoice} stepTitle={stepTitle} continueToNamedStep={() => ('personalDetails')} goBackToNamedStep={() => ('selectEmployment')} />,
    <AssumptionsStep cached stepName="Assumptions" user={user} updateUserState={cacheUserChoice} setAlternativeSteps={setAlternativeSteps} title={t('suitabilityReview:assumptions.title')} subTitle={t('suitabilityReview:assumptions.subTitle')} stepTitle={stepTitle} key="1" goBackToNamedStep={() => ('personalDetails')} continueToNamedStep={() => ('FinancialInformation')} />,
    <CitizenshipStep cached stepName="Citizenship" user={user} updateUserState={cacheUserChoice} alternativeSteps={alternativeSteps.current} action={() => console.log('')} stepTitle={stepTitle} key="2" />,
    <ForeignTaxStep stepName="ForeignTax" user={user} updateUserState={cacheUserChoice} goBackToNamedStep={() => (alternativeSteps.current.isCitizenshipStepRequired ? 'Citizenship' : 'Assumptions')} stepTitle={stepTitle} key="3" />,
    <ForeignTaxNumberStep cached stepName="ForeignTaxNumber" user={user} updateUserState={cacheUserChoice} action={() => console.log('')} stepTitle={stepTitle} key="4" />,
    <FinancialInformationStep stepName="FinancialInformation" key="FinancialInformation" user={user} updateUserState={cacheUserChoice} stepTitle={stepTitle} goBackToNamedStep={() => ('Assumptions')} />,
    <InvestmentPreferencesStep stepName="InvestmentPreferences" key="InvestmentPreferences" user={user} stepTitle={stepTitle} continueToNamedStep={() => ('ConfirmGoals')} />,
    <InvestmentKnowledgeStep cached stepName="InvestmentKnowledge" key="InvestmentKnowledge" user={user} updateUserState={cacheUserChoice} stepTitle={stepTitle} continueToNamedStep={() => ('InvestmentPreferences')} />,
    <RiskQuestion1Step cached stepName="RiskQuestion1" key="RiskQuestion1" user={user} updateUserState={cacheUserChoice} stepTitle={stepTitle} continueToNamedStep={() => ('InvestmentPreferences')} />,
    <RiskQuestion2Step cached stepName="RiskQuestion2" key="RiskQuestion2" user={user} updateUserState={cacheUserChoice} stepTitle={stepTitle} continueToNamedStep={() => 'InvestmentPreferences'} />,
    <ConfirmGoalsStep loading={loading} stepName="ConfirmGoals" key="ConfirmGoals" user={user} stepTitle={stepTitle} onEdit={(item) => setGoalRef(item)} action={updateUserOnServer} continueToNamedStep={() => (isGoalsUpdated.current ? 'ConfirmSubAccountTransitions' : 'agreements')} />,
    <ConfirmSubAccountTransitionsStep loading={loading} stepName="ConfirmSubAccountTransitions" key="ConfirmSubAccountTransitions" user={user} action={finalizeUpdateUserOnServer} stepTitle={stepTitle} continueToNamedStep={() => 'agreements'} />,
    <RiskQuestionStep stepName="riskQuestion" stepTitle={stepTitle} currentGoal={currentGoal} setGoalRef={setGoalRef} key="riskQuestion" />,
    <TimeHorizonStep stepName="timeHorizon" stepTitle={stepTitle} currentGoal={currentGoal} setGoalRef={setGoalRef} continueToNamedStep={() => 'ConfirmGoals'} key="timeHorizon" />,
    <IncompleteAgreementsStep disableBackControl goBackToNamedStep={() => (isGoalsUpdated.current ? 'ConfirmSubAccountTransitions' : 'ConfirmGoals')} stepTitle={stepTitle} stepName="agreements" action={updateAnnualSuitability} key="agreements" />,
  ];
  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      transitions={{}}
      className="ov-step-wizard"
      initialStep={initStep}
      nav={(
        <TrackingBar steps={steps} baselineStepProps={stepProps} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

SuitabilityReviewWizard.defaultProps = {
  stepTitle: undefined,
  stepProps: undefined,
  initStep: 1,
};

export default SuitabilityReviewWizard;
