import { Box } from '@mui/system';
import { useRef } from 'react';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Theme } from '@mui/system/createTheme';

const style = {
  outerBox: {
    position: 'relative' as const,
    display: 'flex' as const,
    overflow: 'hidden',
    height: '14px',
    zIndex: '0',
    borderRadius: '3px',
    backgroundColor: '#D5EDE8',
    width: '90%',
  },
  innerBox: () => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'block',
    height: '14px',
    zIndex: '0',
    backgroundColor: '#125ac1',
    width: '90%',
    transform: 'translateX(0.0%)',
  }),
};

export interface CustomAssetProgressBarProps {
  data: {
    key?: string,
    percentage: number,
    colorCode: string,
  }[],
  sx?: SxProps<Theme>
}

const CustomAssetProgressBar = ({ data, sx }: CustomAssetProgressBarProps): JSX.Element => {
  const ref = useRef();
  return (
    <Box ref={ref} sx={{ ...style.outerBox, ...sx }}>
      {
        data.map((item) => (
          <Box
            key={item.key}
            sx={{ ...style.innerBox(), backgroundColor: item.colorCode, width: `${item.percentage}%` }}
            onClick={() => console.log(ref.current ? (ref.current as HTMLElement).offsetWidth : 0)}
          />
        ))
      }
    </Box>
  );
};

CustomAssetProgressBar.defaultProps = {
  sx: undefined,
};

export default CustomAssetProgressBar;
