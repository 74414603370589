/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useParams, navigate } from '../../utils/router';
import LogoutMenuIcon from '../../assets/images/menu/logout.svg';
import LatestTransfers from '../../components/transfers/latestTransfers';
import AutoInvestmentSummary from '../../components/transfers/autoInvestmentSummary';
import OvBreadCrumb from '../../components/common/headers/ovBreadCrumb';
import { Item, styles } from './styles';
import OvMenu from '../../components/common/headers/ovMenu';
import { defaultGraphColors, HoldingResponse, PrimaryAssetClassHoldings } from '../../components/holdings/resources';
import RateOfReturnCard from '../../components/statistics/rateOfReturnCard';
import { CLOSE_SUB_ACCOUNT, FETCH_SUB_ACCOUNT_PERFORMANCE, UPDATE_PORTFOLIO_DETAILS } from './graphql';
import PortfolioPreferenceListItem from '../../components/common/lists/portfolioPreferenceListItem';
import { DailyStatsTypes } from '../../components/statistics/graphql';
import PortfolioPerformance from '../../components/statistics/portfolioPerformance';
import { generateRiskLevel } from './resources';
import {
  AccountTypes,
  FeatureFlagTypes, getAccountTypeName, isFeatureEnabled, mapThemeAttributes, ThemeAttributes,
} from '../../components/account/resources';
import { UserContext } from '../../providers/userContextProvider';
import LoadingBox from '../../components/common/loaders/loadingBox';
import HoldingsGraph from '../../components/holdings/holdingsGraph';
import Deposit from '../../components/transfers/deposit';
import Withdraw from '../../components/transfers/withdrawal';
import UpdateThemeWizard from '../../components/account/updateThemeWizard';
import ConfirmationModal from '../../components/common/modals/confirmationModal';
import SimpleInput from '../../components/common/inputs/simpleInput';
import OvButton from '../../components/common/buttons/ovButton';
import FeedbackModal from '../../components/common/wrappers/modals/ovFeedbackModal';
import FlowModal from '../../components/common/wrappers/modals/ovFlowModal';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import PaymentInstruction from '../../components/transfers/paymentInstruction';
import { useContextTheme } from '../../providers/contextThemeProvider';
import informationIcon from '../../assets/images/information.svg';
import HoldingsList2 from '../../components/holdings/holdingsList2';
import { availableFeatureFlagsVar, isIdVerifiedVar } from '../../utils/localVariables';
import HoldingsList from '../../components/holdings/holdingsList';
import HoldingsGraph2 from '../../components/holdings/holdingsGraph2';
import { accountsWhichRequirePaymentInstructions, lockedInAccount } from '../../utils/accountUtil';

const SubAccountDetails = (): JSX.Element => {
  const { colors } = useContextTheme();
  const params = useParams();
  const goalId = params.goalId ?? '';
  const subAccountId = params.subAccountId ?? '';
  const origin = params.origin ?? '';
  const household = params.household ?? undefined;
  const matches = useMediaQuery('(max-width:960px)');
  const { t } = useTranslation(['base', 'user', 'transfer', 'goal', 'portfolio']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [holdingList, setHoldingList] = useState<HoldingResponse[]>([]);
  const [assetClassHoldings, setAssetClassHoldings] = useState<PrimaryAssetClassHoldings[]>([]);
  const [loading, setLoading] = useState(true);
  const { userContext, setUserContext } = useContext(UserContext);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showWithdrawal, setShowWithdrawal] = useState(false);
  const [showThemeFlow, setShowThemeFlow] = useState(false);
  const [showClosePortfolioConfirmation, setShowClosePortfolioConfirmation] = useState(false);
  const [closePortfolioError, setClosePortfolioError] = useState(false);
  const [closePortfolioErrorMsg, setClosePortfolioErrorMsg] = useState('');
  const [refreshLatestTransfers, setRefreshLatestTransfers] = useState(false);
  const [openEditPortfolioNameModal, setOpenEditPortfolioNameModal] = useState(false);
  const [newPortfolioName, setNewPortfolioName] = useState('');
  const [isUpdatingPortfolioName, setUpdatingPortfolioName] = useState(false);
  const [availableThemes, setAvailableThemes] = useState<{ id: string }[]>([]);
  const [account, setAccount] = useState({ id: '', type: '' });
  const [allowClientDeposits, setAllowClientDeposits] = useState(false);
  const { showToast } = useGlobalToast();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const setSubAccountToInactiveInUserContext = (id: string): void => {
    const foundSubAccounts = userContext.subAccounts?.filter((subAccount) => subAccount?.id !== id);
    if (foundSubAccounts) {
      setUserContext({ ...userContext, subAccounts: [...foundSubAccounts] });
    }
  };

  const closeSubAccountData = (data: { closeSubAccount: { subAccount: { id: string } } }): void => {
    console.log({ event: 'CLOSED_SUB_ACCOUNT', subAccount: data.closeSubAccount.subAccount.id });
    sendAnalyticsEvent(ovAnalyticsEvents.portfolioPageClosePortfolioConfirm).then();
    setSubAccountToInactiveInUserContext(data.closeSubAccount.subAccount.id);
    setShowClosePortfolioConfirmation(false);
    if (origin === 'account') {
      navigate(setUserContext, '/account', { accountType: account.type, accountId: account.id });
    } else {
      navigate(setUserContext, '/goal', { goalId });
    }
    showToast({ message: t('toastMessages:closePortfolioSuccess.h2'), severity: 'success', title: t('toastMessages:closePortfolioSuccess.h1') });
  };
  const closeSubAccountError = (error: ApolloError): void => {
    const foundError = error.graphQLErrors.find((item) => ['VALIDATION', 'CLOSING_SUBACCOUNT_WITH_PENDING_TRANSFERS', 'CLOSING_SUBACCOUNT_WITH_MONEY_AVAILABLE'].includes(item.extensions.code as string));
    setShowClosePortfolioConfirmation(false);
    if (!foundError) {
      setClosePortfolioErrorMsg(t('portfolio:errorDialog.errorMessages.generic'));
    } else {
      setClosePortfolioErrorMsg(t(`portfolio:errorDialog.errorMessages.${foundError.extensions.code}`));
    }
    setClosePortfolioError(true);
  };
  const [closeSubAccountMutation] = useMutation(CLOSE_SUB_ACCOUNT, {
    variables: {
      input: {
        subAccountId,
        inactiveReason: 'OTHER',
      },
      skipErrorHandler: true,
    },
    onCompleted: closeSubAccountData,
    onError: (e) => closeSubAccountError(e),
  });

  const MENU_ITEMS = [
    {
      icon: LogoutMenuIcon,
      title: t('portfolio:menu.closeSubAccount'),
      onClick: () => {
        setShowClosePortfolioConfirmation(true);
        setAnchorEl(null);
      },
    },
  ];
  const [weightValues, setWeightValues] = useState({
    moneyWeight: 0,
    timeWeight: 0,
  });
  const [marketValue, setMarketValue] = useState(0);
  const [availableFundsToWithdraw, setAvailableFundsToWithdraw] = useState(0);
  const [subAccountName, setSubAccountName] = useState('');
  const [goal, setGoal] = useState({ id: '', type: '', name: '' });
  const [theme, setTheme] = useState<ThemeAttributes>({
    key: '',
    id: '',
    name: '',
    shortDescription: '',
    description: '',
  });
  const [riskLevel, setRiskLevel] = useState({
    iconPath: '',
    name: '',
    value: 0,
    description: '',
  });
  const onUpdatePortfolioNameComplete = (
    data: {
      updateSubAccount: {
        subAccount: {
          id: string,
          name: string,
        },
      }
    },
  ): void => {
    setSubAccountName(data.updateSubAccount.subAccount.name);
    setUpdatingPortfolioName(false);
    setOpenEditPortfolioNameModal(false);
  };
  const [updatePortfolioMutation] = useMutation(UPDATE_PORTFOLIO_DETAILS, {
    variables: {
      input: {
        subAccountId,
        name: newPortfolioName,
      },
    },
    onCompleted: (data) => onUpdatePortfolioNameComplete(data),
  });
  const setSubAccountData = (data: {
    fetchSubAccount: {
      subAccount: {
        id: string,
        name?: string,
        goal: { id: string, type: string, name: string },
        availableThemes: { id: string }[],
        statistics: {
          marketValueCents: number,
          timeWeightedReturn: number,
          moneyWeightedReturn: number,
          availableFundsToWithdrawCents: number,
          holdings: HoldingResponse[],
          primaryAssetClassHoldings: PrimaryAssetClassHoldings[],
        },
        account: {
          id: string,
          type: string,
        },
        theme: {
          id: string,
          key: string,
          translatedDescription: {
            en: string,
            fr: string,
          },
          translatedName: {
            en: string,
            fr: string,
          },
          translatedShortDescription: {
            en: string,
            fr: string,
          },
          iconKey?: string,
        },
        financialProduct?: {
          riskReturnLevel?: number,
        },
        allowClientDeposits: boolean
      },
    },
  }): void => {
    if (!data?.fetchSubAccount?.subAccount) return;
    console.log({ event: 'SUB ACCOUNT', subAccount: data.fetchSubAccount.subAccount });
    setWeightValues({
      moneyWeight: data.fetchSubAccount.subAccount.statistics.moneyWeightedReturn,
      timeWeight: data.fetchSubAccount.subAccount.statistics.timeWeightedReturn,
    });
    setHoldingList(data.fetchSubAccount.subAccount.statistics.holdings);
    // This where we assigned the colors to the Primary Asset Classes
    const { primaryAssetClassHoldings } = data.fetchSubAccount.subAccount.statistics;
    setAssetClassHoldings(() => (
      [...primaryAssetClassHoldings].map((item, index) => ({
        ...item,
        color: primaryAssetClassHoldings.length ? defaultGraphColors(colors)[index] : colors.emptyPieChartColor,
      }))
    ));
    if (data.fetchSubAccount.subAccount.theme) {
      setTheme(mapThemeAttributes([data.fetchSubAccount.subAccount.theme])[0]);
    }
    setMarketValue(data.fetchSubAccount.subAccount.statistics.marketValueCents);
    setAvailableFundsToWithdraw(data.fetchSubAccount.subAccount.statistics.availableFundsToWithdrawCents);
    setRiskLevel(generateRiskLevel(data.fetchSubAccount.subAccount?.financialProduct?.riskReturnLevel ?? 0));
    setSubAccountName(data.fetchSubAccount.subAccount.name ?? '');
    setAccount(data.fetchSubAccount.subAccount.account);
    setNewPortfolioName(data.fetchSubAccount.subAccount.name ?? '');
    setGoal(data.fetchSubAccount.subAccount.goal);
    setLoading(false);
    setAvailableThemes(data.fetchSubAccount.subAccount.availableThemes);
    setAllowClientDeposits(data.fetchSubAccount.subAccount.allowClientDeposits);
  };
  const [fetchSubAccount, { refetch, called }] = useLazyQuery(FETCH_SUB_ACCOUNT_PERFORMANCE, {
    variables: {},
    onCompleted: setSubAccountData,
    nextFetchPolicy: 'standby',
    fetchPolicy: 'standby',
    onError: () => navigate(setUserContext, '/'),
  });
  useEffect(() => {
    fetchSubAccount({
      variables: {
        subAccountId,
      },
    });
  }, [fetchSubAccount, subAccountId]);
  const onSuccessTransfer = (): void => {
    console.log('SUCCESSFUL TRANSFER DETECTED');
    setRefreshLatestTransfers(true);
    if (called) {
      refetch({
        subAccountId,
      });
    }
  };
  const onSuccessWithdraw = (): void => {
    console.log('SUCCESSFUL WITHDRAW DETECTED');
    setRefreshLatestTransfers(true);
    if (called) {
      refetch({
        subAccountId,
      });
    }
  };
  const hideBankAccountDeposit = lockedInAccount.includes(account.type as AccountTypes);
  const [showViewPerformance, setShowViewPerformance] = useState(false);
  const viewPerformance = (): void => {
    setShowViewPerformance(true);
  };
  const closeViewPerformance = (): void => {
    setShowViewPerformance(false);
  };
  const [showPreference, setShowPreference] = useState(false);
  const [preferenceData, setPreferenceData] = useState({
    type: '',
    icon: '',
    name: '',
    description: '',
  });
  const showPreferenceModal = (type: string, icon: string, name: string, description: string): void => {
    setPreferenceData({
      type,
      icon,
      name,
      description,
    });
    setShowPreference(true);
  };
  const openThemeFlow = (): void => {
    setShowThemeFlow(true);
  };
  const updateSubAccountName = (): void => {
    setUpdatingPortfolioName(true);
    updatePortfolioMutation().then();
  };
  const portfolioName = `${getAccountTypeName(account.type)} - ${theme.name}`;
  if (showThemeFlow) {
    return (
      <FeedbackModal
        onClose={() => setShowThemeFlow(false)}
        open={showThemeFlow}
        component={
          (
            <UpdateThemeWizard
              goalId={goalId}
              subAccount={{ id: subAccountId }}
              themeAttributes={theme}
              goBack={() => setShowThemeFlow(false)}
              key={subAccountId}
              onContinue={(selectedTheme) => {
                setTheme(selectedTheme ?? {
                  key: '',
                  id: '',
                  name: '',
                  shortDescription: '',
                  description: '',
                });
                setShowThemeFlow(false);
                showToast({ message: t('toastMessages:changedThemeSuccess.h2'), severity: 'success', title: t('toastMessages:changedThemeSuccess.h1') });
              }}
              availableThemes={availableThemes} />
          )
        }
      />
    );
  }
  if (showDeposit) {
    return (
      <FlowModal
        open={showDeposit}
        onClose={() => setShowDeposit(false)}
        showCloseButton
        maintainModal
        component={(
          <Deposit
            onClose={() => setShowDeposit(false)}
            hideBankAccountDeposit={hideBankAccountDeposit}
            selectedGoalId={goalId}
            initialStep={lockedInAccount.includes(account.type as AccountTypes) ? 3 : 1}
            selectedSubAccountId={subAccountId}
            onSuccessTransfer={() => {
              onSuccessTransfer();
            }}
          />
        )}
      />
    );
  }
  if (showWithdrawal) {
    return (
      <Withdraw
        onClose={() => setShowWithdrawal(false)}
        goalId={goalId}
        subAccountId={subAccountId}
        onSuccessWithdraw={() => {
          onSuccessWithdraw();
        }}
      />
    );
  }
  const onEditSubAccountNameClose = (): void => {
    setOpenEditPortfolioNameModal(false);
    setNewPortfolioName(subAccountName);
  };
  const loadEditSubAccountNameModelContent = (): JSX.Element => (
    <Box display="flex" flexDirection="column" justifyContent="space-between" boxSizing="border-box">
      <Box>
        <Typography variant="headingMain" mb={2}>
          {t('portfolio:editPortfolioModal.title')}
        </Typography>
        <Typography variant="captionSectionerMedium" mb={5.2}>
          {t('portfolio:editPortfolioModal.subTitle')}
        </Typography>
        <Box>
          <SimpleInput
            label={t('portfolio:editPortfolioModal.nameInputLabel')}
            value={newPortfolioName}
            required
            style={styles.inputStyle}
            onChange={(event) => setNewPortfolioName(event.target.value)}
            testId="portfolio-name-input"
            onClear={() => setNewPortfolioName('')}
          />
        </Box>
      </Box>
      <OvButton variant="primary-large" text={t('base:button.continue')} loading={isUpdatingPortfolioName} onClick={updateSubAccountName} />
    </Box>
  );
  if (loading) {
    return <LoadingBox />;
  }

  if (showPreference) {
    return (
      <FeedbackModal
        onClose={() => setShowPreference(false)}
        open={showPreference}
        component={
          (
            <Box>
              <Box sx={{ textAlign: 'center' }}>
                <img src={preferenceData.icon} alt="icon" />
              </Box>
              <Typography variant="heading3" sx={styles.headingText}>
                {preferenceData.name}
              </Typography>
              <Typography variant="captionSectionerMedium" sx={styles.subText}>
                {preferenceData.description}
              </Typography>
              <Box sx={styles.goalEditButton}>
                <Button variant="primary-large" onClick={() => setShowPreference(false)} sx={{ marginBottom: '5px' }}>{t('base:button.gotIt')}</Button>
              </Box>
            </Box>
          )
        }
      />
    );
  }
  if (showViewPerformance) {
    return (
      <FlowModal
        open={showViewPerformance}
        onClose={() => closeViewPerformance()}
        showCloseButton
        component={(
          <PortfolioPerformance
            aggregation={{ type: DailyStatsTypes.SUB_ACCOUNTS_DAILY, typeId: subAccountId }}
            marketValueCents={marketValue}
            title={t('portfolio:titles.performanceTitle')}
            onClick={closeViewPerformance}
          />
        )}
      />
    );
  }

  if (openEditPortfolioNameModal) {
    return (
      <FlowModal
        open={openEditPortfolioNameModal}
        onClose={() => onEditSubAccountNameClose()}
        showCloseButton
        component={loadEditSubAccountNameModelContent()}
      />
    );
  }
  return (
    <Container className="home-page" sx={styles.subAccountDetailsContainer}>
      <Box sx={styles.detailBox}>
        <Box sx={{ paddingLeft: '24px', position: 'relative', top: '-7px' }}>
          <OvBreadCrumb breadcrumbItems={origin === 'account' ? [
            { title: t('base:breadcrumb.home'), onClick: () => navigate(setUserContext, '/') },
            { title: getAccountTypeName(account.type), onClick: () => navigate(setUserContext, '/account', { accountType: account.type, accountId: account.id }) },
            { title: portfolioName },
          ] : [
            { title: t('base:breadcrumb.home'), onClick: () => navigate(setUserContext, '/') },
            { title: goal.name, onClick: () => navigate(setUserContext, '/goal', { goalId, household }) },
            { title: portfolioName },
          ]}
          />
        </Box>
        <Box sx={styles.dotMenueWrapper}>
          <MoreVertIcon data-testid="more-menu-icon" sx={styles.menuIcon} style={{ top: matches ? '8px' : '11px' }} onClick={handleClick} />
          <OvMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            menuItems={MENU_ITEMS}
          />
        </Box>
      </Box>
      <Box sx={styles.detailWrapper}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={!matches ? 7 : 12}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{
                margin: {
                  xs: '0px 8px',
                  sm: '0px',
                  md: '0px',
                },
                marginLeft: {
                  sm: '0px',
                  md: '0px',
                  lg: '24px',
                },
              }}
              style={{ marginBottom: '12px' }}
            >
              <Typography variant="captionSectionerBold" color={colors.neutral20}>{t('portfolio:titles.holdings')}</Typography>
              <Box mr={0.7} />
              <img src={informationIcon} alt="information-icon" />
            </Box>
            <Item
              sx={{
                ...styles.leftCage,
                margin: {
                  xs: '0px 8px',
                  sm: '0px',
                  md: '0px',
                },
                marginLeft: {
                  sm: '0px',
                  md: '0px',
                  lg: '24px',
                },
                background: colors.baseComponentBackground,
              }}
              style={{ padding: '0px !important', borderRadius: '8px' }}
            >
              {
                availableFeatureFlagsVar().includes('ASSET_CLASSES') ? (
                  <HoldingsGraph2 primaryAssetClassHoldings={assetClassHoldings} />
                ) : (
                  <HoldingsGraph holdings={holdingList} />
                )
              }
              <Box sx={styles.buttonWrapper}>
                {allowClientDeposits && isFeatureEnabled(FeatureFlagTypes.DEPOSITS)
                  && (
                    <Button
                      variant="primary-small"
                      sx={styles.investButton}
                      disabled={!isIdVerifiedVar()}
                      onClick={() => {
                        setRefreshLatestTransfers(false);
                        setShowDeposit(true);
                      }}>
                      {t('transfer:action.invest')}
                    </Button>
                  )}
                {isFeatureEnabled(FeatureFlagTypes.WITHDRAWALS) && ((availableFundsToWithdraw ?? 0) > 0) ? (
                  <Button
                    variant="secondary-small"
                    sx={styles.withdrawButton}
                    onClick={() => {
                      setRefreshLatestTransfers(false);
                      setShowWithdrawal(true);
                    }}
                  >
                    {t('transfer:action.withdraw')}
                  </Button>
                ) : undefined}
              </Box>
            </Item>
            <Box sx={{ marginLeft: !matches ? '24px' : '0px', borderRadius: '8px', mt: 2 }}>
              {
                availableFeatureFlagsVar().includes('ASSET_CLASSES') ? (
                  <HoldingsList2
                    holdings={[]}
                    primaryAssetClassHoldings={assetClassHoldings}
                  />
                ) : (
                  <HoldingsList
                    holdings={holdingList}
                  />
                )
              }
            </Box>
          </Grid>
          <Grid item xs={!matches ? 5 : 12} sx={{ paddingRight: !matches ? '27px' : '14px' }}>
            <Box sx={{
              margingTop: '24px',
              marginLeft: '10px',
            }}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '12px' }}>
                <Typography variant="captionSectionerBold" color={colors.neutral20}>{t('portfolio:titles.rateOfReturn')}</Typography>
                <Typography
                  style={styles.underline}
                  variant="captionSectionerBold"
                  color={colors.link}
                  onClick={() => {
                    viewPerformance();
                    sendAnalyticsEvent(ovAnalyticsEvents.portfolioPageViewPerformanceSelect).then();
                  }}
                >
                  {t('portfolio:titles.viewPerformance')}
                </Typography>
              </Box>
              <RateOfReturnCard
                timeWeightedPercentage={weightValues.timeWeight}
                moneyWeightedPercentage={weightValues.moneyWeight}
                infoIconClicked={() => sendAnalyticsEvent(ovAnalyticsEvents.portfolioPageRateOfReturnSelect).then()}
              />
            </Box>
            <Box sx={{
              marginTop: '24px',
              marginLeft: '10px',
            }}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: '12px' }}>
                <Typography variant="captionSectionerBold" color={colors.neutral20}>{t('portfolio:titles.preferences')}</Typography>
              </Box>
              {theme ? (
                <PortfolioPreferenceListItem
                  title={t('portfolio:titles.yourTheme').toString()}
                  subtitle={theme.name || theme.key}
                  key={theme.key}
                  icon={theme.singleIconPath}
                  hasDivider
                  onClick={() => {
                    openThemeFlow();
                    sendAnalyticsEvent(ovAnalyticsEvents.portfolioPageThemeSelect).then();
                  }}
                />
              ) : null}
              {riskLevel ? (
                <PortfolioPreferenceListItem
                  title={t('portfolio:titles.riskReturn').toString()}
                  subtitle={riskLevel?.name || ''}
                  key={riskLevel?.value}
                  icon={riskLevel?.iconPath}
                  hasDivider
                  onClick={() => {
                    showPreferenceModal('RISK', riskLevel?.iconPath, riskLevel?.name, riskLevel.description);
                    sendAnalyticsEvent(ovAnalyticsEvents.portfolioPageRiskAndReturnSelect).then();
                  }}
                />
              ) : null}
            </Box>
            <Box sx={{
              margingTop: '36px',
              padding: '10px 0px 10px 10px',
              textAlign: 'left',
            }}>
              {
                 !accountsWhichRequirePaymentInstructions.includes(account.type as AccountTypes)
                   ? (<AutoInvestmentSummary goalId={goalId} subAccountId={subAccountId} />) : undefined
              }
              {
                accountsWhichRequirePaymentInstructions.includes(account.type as AccountTypes) ? (
                  <PaymentInstruction
                    account={account}
                    viewAutoInvestment={() => {
                      sendAnalyticsEvent(ovAnalyticsEvents.accountPageViewAutoInvestmentSelect).then();
                    }}
                  />
                ) : undefined
              }
            </Box>
            <Box sx={styles.historyWrapper}>
              <LatestTransfers subAccountId={subAccountId} refresh={refreshLatestTransfers} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        title={t('portfolio:confirmationDialog.title')}
        description={t('portfolio:confirmationDialog.details')}
        buttonText={t('portfolio:confirmationDialog.continueButton')}
        cancelButton={{
          onClick: () => {
            sendAnalyticsEvent(ovAnalyticsEvents.portfolioPageClosePortfolioCancel).then();
            setShowClosePortfolioConfirmation(false);
          },
          text: t('portfolio:confirmationDialog.cancelButton'),
        }}
        open={showClosePortfolioConfirmation}
        onButtonClick={() => {
          closeSubAccountMutation({
            variables: {
              input: {
                subAccountId,
                inactiveReason: 'OTHER',
              },
              skipErrorHandler: true,
            },
          }).then();
        }}
        onClose={() => setShowClosePortfolioConfirmation(false)}
      />
      <ConfirmationModal
        title={t('portfolio:errorDialog.title')}
        description={closePortfolioErrorMsg}
        buttonText={t('portfolio:errorDialog.continueButton')}
        open={closePortfolioError}
        onButtonClick={() => setClosePortfolioError(false)}
        onClose={() => setClosePortfolioError(false)}
      />
    </Container>
  );
};

export default SubAccountDetails;
