import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../common/modals/confirmationModal';
import ButtonCardListItem, { ButtonCardList } from '../common/lists/buttonCardListItem';
import {
  AccountTypeAttributes,
  AccountTypes,
  getAccountTypes,
} from './resources';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import { SubAccount } from '../../utils/commonGraphql';
import { translatedUrl } from '../../assets/i18n/config';
import RRIFConfirmationModal from '../common/modals/RRIFConfirmationModal';

export interface SubAccountRef {
  accountType?: AccountTypes,
  name?: string,
}

interface Props {
  setSubAccountRef: (att: SubAccountRef) => void,
  onContinue?: () => void,
  goToNamedStep?: (step: string) => void,
  accountsToExclude?: AccountTypes[],
  currentSubAccount?: SubAccount | {
    account?: {
      type?: AccountTypes;
    },
    theme?: {
      id?: string;
    },
  }
}

const AccountType = ({
  setSubAccountRef, onContinue, currentSubAccount, accountsToExclude = [], goToNamedStep,
}: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const [displayConfirmationMessage, setDisplayConfirmationMessage] = useState(false);
  const [displayRRIFConfirmationMessage, setDisplayRRIFConfirmationMessage] = useState({ open: false, accountType: '' });
  const [check, setCheck] = useState(false);

  const handleAnalytics = (accountType: AccountTypes): void => {
    sendAnalyticsEvent(ovAnalyticsEvents.addGoalSelectAccountType).then();
    switch (accountType) {
      case AccountTypes.TFSA:
        sendAnalyticsEvent(ovAnalyticsEvents.makeAnInvestmentPageTFSASelect).then();
        break;
      case AccountTypes.RRSP:
        sendAnalyticsEvent(ovAnalyticsEvents.makeAnInvestmentPageRRSPSelect).then();
        break;
      case AccountTypes.RESP:
        sendAnalyticsEvent(ovAnalyticsEvents.makeAnInvestmentPageRESPSelect).then();
        break;
      case AccountTypes.PERSONAL:
        sendAnalyticsEvent(ovAnalyticsEvents.makeAnInvestmentPageCashSelect).then();
        break;
      case AccountTypes.RRIF:
        sendAnalyticsEvent(ovAnalyticsEvents.rrifAccountTypePageRRIFSelect).then();
        break;
      default:
        sendAnalyticsEvent(ovAnalyticsEvents.makeAnInvestmentPageCashSelect).then();
        break;
    }
  };

  const dialogConfirmClick = (): void => {
    handleAnalytics(currentSubAccount?.account?.type as AccountTypes);
    sendAnalyticsEvent(ovAnalyticsEvents.rrifBeforeYouProceedIUnderstandButtonSelect).then();
    setDisplayConfirmationMessage(false);
    if (onContinue) onContinue();
  };

  const dialogClose = (): void => {
    setDisplayConfirmationMessage(false);
  };

  const handleClick = async (item: AccountTypeAttributes): Promise<void> => {
    console.log({ event: 'ACCOUNT_TYPE_SELECTED', type: item.key });
    sendAnalyticsEvent(ovAnalyticsEvents.onBoardingSelectAccountType).then();
    setSubAccountRef({ accountType: item.key, name: item.title });
    const accounts: AccountTypes[] = [AccountTypes.PERSONAL, AccountTypes.RESP];
    handleAnalytics(item.key);
    if (accounts.includes(item.key) && onContinue) {
      onContinue();
    }
    if (item.key === AccountTypes.RRIF || item.key === AccountTypes.RIF_SPOUSAL) {
      setDisplayRRIFConfirmationMessage({ open: true, accountType: item.key });
    }
    if ([AccountTypes.RRSP, AccountTypes.TFSA].includes(item.key)) {
      setDisplayConfirmationMessage(true);
    }
  };

  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2" data-testid="title">{t('account:accountType.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('account:accountType.subTitle')}</Typography>
      <ButtonCardList>
        {getAccountTypes([...accountsToExclude, AccountTypes.LIRA]).map((item: AccountTypeAttributes) => (
          <ButtonCardListItem
            key={item.key}
            header={item.title}
            description={item.description}
            onClick={() => {
              handleClick(item).then();
            }}
            isSelected={currentSubAccount?.account?.type === item.key}
          />
        ))}
      </ButtonCardList>
      <ConfirmationModal
        title={t('account:accountType.confirmationDialogTitle')}
        description={t('account:accountType.confirmationDialogDescription')}
        link={{
          url: translatedUrl('https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/notice-assessment-understand.html'),
          text: t('account:accountType.confirmationDialogLinkText'),
          descriptionAfterLink: t('account:accountType.confirmationDialogLinkDescription'),
        }}
        buttonText={t('account:accountType.addAffiliate')}
        cancelButton={{
          text: t('account:accountType.skipAffiliate'),
          onClick: dialogConfirmClick,
        }}
        disabled={!check}
        open={displayConfirmationMessage}
        onButtonClick={() => {
          if (goToNamedStep) goToNamedStep('accountAuxiliaryStepWizard');
        }}
        onClose={dialogClose}
      >
        <Typography data-testid="dialog-title" sx={{ mb: 1 }} variant="paragraph3">{t('account:accountType.confirmationDialogLinkDescription3')}</Typography>
        <FormControlLabel
          className="agreement"
          sx={{ marginLeft: 0, marginTop: 0 }}
          control={
            (
              <Checkbox
                data-testid="consent-check"
                name="agreementCheck"
                value="agreementCheckVal"
                checked={check || false}
                sx={{ marginRight: '15px' }}
                onChange={(e) => {
                  setCheck(e.target.checked);
                }}
              />
            )
          }
          label={<Typography variant="body2">{t('account:accountType.confirmationDialogButton')}</Typography>}
        />
      </ConfirmationModal>
      <RRIFConfirmationModal
        open={displayRRIFConfirmationMessage.open}
        accountType={displayRRIFConfirmationMessage.accountType}
        onButtonClick={dialogConfirmClick}
        onClose={() => setDisplayRRIFConfirmationMessage({ open: false, accountType: '' })}
      />
    </OvForm>
  );
};

AccountType.defaultProps = {
  onContinue: undefined,
  currentSubAccount: undefined,
  accountsToExclude: [],
  goToNamedStep: undefined,
};

export default AccountType;
