import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import timerImage from '../../assets/images/timer.svg';
import OvForm from '../common/wrappers/ovForm';

export interface Props {
  onContinue?: () => void,
  onInformationUpToDate?: () => void,
}

const StartCheckTwo = ({ onContinue, onInformationUpToDate }: Props): JSX.Element => {
  const { t } = useTranslation(['suitabilityReview']);
  const continueToNextStep = (): void => {
    if (onContinue) {
      onContinue();
    }
  };
  return (
    <OvForm
      hasButton
      buttonText={t('suitabilityReview:startCheckTwo.prefixButton')}
      onSubmit={continueToNextStep}
      suffixButton={{
        onClick: () => {
          if (onInformationUpToDate) {
            onInformationUpToDate();
          }
        },
        text: t('suitabilityReview:startCheckTwo.suffixButton'),
        variant: 'secondary-large',
        dataTestId: 'close-button',
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" style={{ marginBottom: '32px' }}>
        <img src={timerImage} alt="timer" />
        <Typography variant="headingMain" textAlign="center" style={{ marginBottom: 0 }}>{t('suitabilityReview:startCheckTwo.title')}</Typography>
        <Typography variant="paragraphMain" style={{ marginBottom: 0, textAlign: 'center' }}>
          <Markup content={t('suitabilityReview:startCheckTwo.body')} />
        </Typography>
      </Box>
    </OvForm>
  );
};

StartCheckTwo.defaultProps = {
  onContinue: undefined,
  onInformationUpToDate: undefined,
};

export default StartCheckTwo;
