import { Auth0Client } from '@auth0/auth0-spa-js';
import { getRedirectUri } from './platformUtils';

const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  authorizationParams: {
    scope: 'client_credentials email',
    audience: process.env.REACT_APP_AUTH0_API_NAME ?? '',
  },
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  useCookiesForTransactions: true,
});

export const auth0Logout = async () => {
  if (process.env.REACT_APP_AUTH0_CLIENT_ID) {
    await auth0.logout({
      logoutParams: {
        returnTo: getRedirectUri(),
      },
    });
  }
};

export default auth0;
