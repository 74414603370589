import { List, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../providers/userContextProvider';
import { BinaryAnswer, binaryAnswers } from '../../account/resources';
import ButtonListItem from '../../common/lists/buttonListItem';
import ConfirmationModal from '../../common/modals/confirmationModal';
import OvForm from '../../common/wrappers/ovForm';

export interface UserState {
  countryOfTaxResidence?: string,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
}

const CountryOfTaxResidence = ({ user, updateUserState, onContinue }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);
  const [showModal, setShowModal] = useState(false);
  const { userContext } = useContext(UserContext);

  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2">{t('user:countryOfTaxResidence.title')}</Typography>
      <Typography variant="paragraph3">{t('user:countryOfTaxResidence.subTitle')}</Typography>
      <List sx={{ maxHeight: '400px', overflowY: 'auto' }}>
        {binaryAnswers.map((item: Record<string, string>) => (
          <ButtonListItem
            key={item.key}
            text={item.value}
            onClick={() => {
              if (userContext.organizationSettings?.canadianTaxResidentOnly && item.key === BinaryAnswer.NO) {
                setShowModal(true);
                return;
              }
              updateUserState({ ...user, countryOfTaxResidence: item.key === BinaryAnswer.YES ? 'CA' : undefined });
              if (onContinue) onContinue();
            }}
          />
        ))}
      </List>
      <ConfirmationModal
        title={t('user:countryOfTaxResidence.error.title')}
        description={t('user:countryOfTaxResidence.error.message')}
        buttonText={t('user:countryOfTaxResidence.error.goBack')}
        open={showModal}
        onButtonClick={() => { setShowModal(false); }}
        onClose={() => { setShowModal(false); }}
      />
    </OvForm>
  );
};

CountryOfTaxResidence.defaultProps = {
  onContinue: undefined,
};

export default CountryOfTaxResidence;
