import { gql } from '@apollo/client';

// performance queries
export const FETCH_USER_PERFORMANCE = gql`
  query Me ($startDate: Date) {
    me {
      user {
        id
        statistics {
          id
          marketValueCents
          timeWeightedReturn(startDate : $startDate)
          moneyWeightedReturn(startDate : $startDate)
        }
      }
    }
  }
`;
export const FETCH_GOAL_PERFORMANCE = gql`
  query fetchGoal($goalId: ObjectID!, $startDate: Date) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        statistics {
          id
          marketValueCents
          timeWeightedReturn(startDate : $startDate)
          moneyWeightedReturn(startDate : $startDate)
        }
      }
    }
  }
`;
export const FETCH_ACCOUNT_PERFORMANCE = gql`
  query fetchAccount ($accountId: ObjectID!, $startDate: Date) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        statistics {
          id
          marketValueCents
          timeWeightedReturn(startDate : $startDate)
          moneyWeightedReturn(startDate : $startDate)
        }
      }
    }
  }
`;
export const FETCH_SUB_ACCOUNT_PERFORMANCE = gql`
  query fetchSubAccount ($subAccountId: ObjectID!, $startDate: Date) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        statistics {
          id
          marketValueCents
          timeWeightedReturn(startDate : $startDate)
          moneyWeightedReturn(startDate : $startDate)
        }
      }
    }
  }
`;

// history queries
export const FETCH_STATS_HISTORY = gql`
  query fetchStatsHistory($input: FetchStatsHistoryInput!) {
    fetchStatsHistory(input: $input) {
      snapshots{
        id
        objectType
        marketValueCents
        netContributionCents
        date
      }
    }
  }
`;
export const FETCH_HISTORY_USER = gql`
  query fetchHistory($input: FetchHistoryInput!) {
    fetchHistory(input: $input) {
      history{
        ...on UsersDaily{
          id
          marketValueCents
          netContributionCents
          date
        }
      }
    }
  }
`;
export const FETCH_HISTORY_GOAL = gql`
  query fetchHistory($input: FetchHistoryInput!) {
    fetchHistory(input: $input) {
      history{
        ...on GoalsDaily{
          id
          marketValueCents
          netContributionCents
          date
        }
     }
    }
  }
`;
export const FETCH_HISTORY_ACCOUNT = gql`
  query fetchHistory($input: FetchHistoryInput!) {
    fetchHistory(input: $input) {
      history{
        ...on AccountsDaily{
          id
          marketValueCents
          netContributionCents
          date
        }
      }
    }
  }
`;
export const FETCH_HISTORY_SUBACCOUNT = gql`
  query fetchHistory($input: FetchHistoryInput!) {
    fetchHistory(input: $input) {
      history{
        ...on SubAccountsDaily{
          id
          marketValueCents
          netContributionCents
          date
        }
      }
    }
  }
`;

// interfaces
export enum DailyStatsTypes {
  SUB_ACCOUNTS_DAILY = 'SUB_ACCOUNTS_DAILY',
  ACCOUNTS_DAILY = 'ACCOUNTS_DAILY',
  USERS_DAILY = 'USERS_DAILY',
  GOALS_DAILY = 'GOALS_DAILY',
  CLIENT_GROUPS_DAILY = 'CLIENT_GROUPS_DAILY',
}

export const enum AggregationTypes {
  USER = 'USER',
  GOAL = 'GOAL',
  ACCOUNT = 'ACCOUNT',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
}

export interface Aggregation {
  type: DailyStatsTypes,
  typeId: string,
}

export interface PerformanceResponse {
  id: string,
  statistics: {
    timeWeightedReturn: number,
    moneyWeightedReturn: number,
  }
}

export interface HistoryResponse {
  id: string,
  marketValueCents: number,
  netContributionCents: number,
  date: string,
}
