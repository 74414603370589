import Radio, { RadioProps } from '@mui/material/Radio';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useContextTheme } from '../../../providers/contextThemeProvider';

const OvRadioButton = ({ ...props }: RadioProps): JSX.Element => {
  const { colors } = useContextTheme();
  return (
    <Radio
      color="default"
      icon={<CircleIcon style={{ color: colors.checkboxColor }} />}
      checkedIcon={props.disabled ? <CircleIcon style={{ color: colors.checkboxColor }} /> : <RadioButtonCheckedIcon />}
      {...props}
    />
  );
};

export default OvRadioButton;
