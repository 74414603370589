import {
  Grid, Typography, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MarketValueIcon from '../../assets/images/market-value-icon.svg';
import NetContributionIcon from '../../assets/images/net-contribution-icon.svg';
import { useContextTheme } from '../../providers/contextThemeProvider';

interface Props {
  titleDate: string,
  marketValue: string,
  investmentValue: string,
}
const HistoryTooltip = ({
  titleDate, marketValue, investmentValue,
}: Props): JSX.Element => {
  const { t } = useTranslation(['statistics']);
  const { colors } = useContextTheme();
  const styles = {
    iconStyle: {
      position: 'relative',
      top: '20px',
    },
    titleStyle: {
      color: colors.secondary,
      paddingBottom: '0px !important',
      position: 'relative',
      border: 0,
      textAlign: 'center',

    },
    iconGrid: {
      verticalAlign: 'middle',
      position: 'relative',
      bottom: '20px',
      right: '10px',
      textAlign: 'right',
      paddingTop: '35px !important',
    },
    textCage: {
      textAlign: 'center',
      justifyContent: 'start',
      display: 'grid',
    },
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: 0 }}>
      <Grid item xs={12} sx={{ position: 'relative', border: 'none' }}>
        <Box sx={styles.titleStyle}>{titleDate}</Box>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={4} sx={styles.iconGrid}>
            <img src={MarketValueIcon} alt="market-value-icon" />
          </Grid>
          <Grid item xs={8} sx={styles.textCage}>
            <Typography variant="captionSectionerMedium" sx={{ marginBottom: '0px' }}>{t('statistics:historyTooltip.marketValue')}</Typography>
            <Typography variant="bodyMain1">{marketValue}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={4} sx={styles.iconGrid}>
            <img src={NetContributionIcon} alt="net-contribution-icon" />
          </Grid>
          <Grid item xs={8} sx={styles.textCage}>
            <Typography variant="captionSectionerMedium" sx={{ marginBottom: '0px' }}>{t('statistics:historyTooltip.netContributions')}</Typography>
            <Typography variant="bodyMain1">{investmentValue}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HistoryTooltip;
