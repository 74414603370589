import {
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from '@mui/material';
import { useState, FormEvent } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { PhysicalAddress } from './addInstitutionWizard';
import OvForm from '../../../common/wrappers/ovForm';
import SimpleInput from '../../../common/inputs/simpleInput';
import {
  AddressCountries,
  AddressProvinces,
  countries,
  CountryList,
  ProvinceList,
  provinces,
} from '../../../user/address/resources';

export interface ValidateState {
  houseNumberIsValid?: boolean,
  streetNameIsValid?: boolean,
  cityIsValid?: boolean,
  unitNumberIsValid: boolean,
  postalIsValid?: boolean,
  countryIsValid?: boolean,
  provinceIsValid?: boolean,
}
interface Props {
  physicalAddress: PhysicalAddress,
  updatePhysicalAddress: (att: PhysicalAddress) => void,
  goToNamedStep?: (step: string) => void,
}

const styles = {
  wrapper: {
    boxSizing: 'border-box',
  },
};

const UserAddress = ({ physicalAddress, updatePhysicalAddress, goToNamedStep }: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'user', 'transfer']);
  const [validState, setValidState] = useState<ValidateState>({
    streetNameIsValid: true,
    houseNumberIsValid: true,
    cityIsValid: true,
    unitNumberIsValid: false,
    postalIsValid: true,
    countryIsValid: true,
    provinceIsValid: true,
  });

  const checkFieldsValidity = async (): Promise<boolean> => {
    const streeNumberValidity = await yup.string().required().isValid(physicalAddress.houseNumber);
    setValidState({ ...validState, houseNumberIsValid: streeNumberValidity });
    const streetNameValidity = await yup.string().required().isValid(physicalAddress.streetName);
    setValidState({ ...validState, streetNameIsValid: streetNameValidity });
    const cityValidity = await yup.string().required().isValid(physicalAddress.city);
    setValidState({ ...validState, cityIsValid: cityValidity });
    const postalValidity = await yup.string().required().isValid(physicalAddress.postal);
    setValidState({ ...validState, postalIsValid: postalValidity });
    const countryValidity = await yup.string().required().isValid(physicalAddress.country);
    setValidState({ ...validState, countryIsValid: countryValidity });
    return streetNameValidity && streeNumberValidity && cityValidity && postalValidity && countryValidity;
  };
  const clearUnitNumber = (): void => {
    updatePhysicalAddress({ ...physicalAddress, unitNumber: '' });
  };
  const clearStreetName = (): void => {
    updatePhysicalAddress({ ...physicalAddress, streetName: '' });
    setValidState({ ...validState, streetNameIsValid: true });
  };
  const clearhouseNumber = (): void => {
    updatePhysicalAddress({ ...physicalAddress, houseNumber: '' });
    setValidState({ ...validState, houseNumberIsValid: true });
  };
  const clearCity = (): void => {
    updatePhysicalAddress({ ...physicalAddress, city: '' });
    setValidState({ ...validState, cityIsValid: true });
  };
  const clearPostal = (): void => {
    updatePhysicalAddress({ ...physicalAddress, postal: '' });
    setValidState({ ...validState, postalIsValid: true });
  };
  const onContinueClick = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.preventDefault();
    if (!await checkFieldsValidity()) return;
    if (goToNamedStep) goToNamedStep('add-institution');
  };

  return (
    <Box height="93%" sx={styles.wrapper}>
      <OvForm onSubmit={onContinueClick}>
        <Typography variant="heading2">{t('transfer:addInstitution.title')}</Typography>
        <Typography variant="paragraph3">{t('transfer:addInstitution.subTitle')}</Typography>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <SimpleInput
              testId="unitnumber-input"
              label={t('user:address.labels.unitNumber')}
              value={physicalAddress?.unitNumber}
              style={{ marginBottom: 16 }}
              onChange={(event) => updatePhysicalAddress({ ...physicalAddress, unitNumber: event.target.value })}
              onClear={clearUnitNumber}
            />
          </Grid>
          <Grid item xs={6}>
            <SimpleInput
              testId="streetnumber-input"
              label={t('user:address.labels.streetNumber')}
              value={physicalAddress?.houseNumber}
              style={{ marginBottom: 16 }}
              required
              onChange={(event) => updatePhysicalAddress({ ...physicalAddress, houseNumber: event.target.value })}
              onClear={clearhouseNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleInput
              testId="streetname-input"
              label={t('user:address.labels.streetName')}
              value={physicalAddress?.streetName}
              style={{ marginBottom: 16 }}
              required
              onChange={(event) => updatePhysicalAddress({ ...physicalAddress, streetName: event.target.value })}
              onClear={clearStreetName}
            />
          </Grid>
          <Grid item xs={6}>
            <SimpleInput
              testId="city-input"
              label={t('user:address.labels.city')}
              value={physicalAddress?.city}
              style={{ marginBottom: 16 }}
              required
              onChange={(event) => updatePhysicalAddress({ ...physicalAddress, city: event.target.value })}
              onClear={clearCity}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="standard" sx={{ m: 1, width: '100%', margin: 0 }}>
              <InputLabel id="demo-simple-select-label">{t('user:address.labels.province')}</InputLabel>
              <Select
                value={physicalAddress?.province}
                onChange={(event) => { updatePhysicalAddress({ ...physicalAddress, province: event.target.value as AddressProvinces }); }}
                label={t('user:address.labels.province')}
                style={{ marginBottom: 16 }}
                data-testid="province"
                fullWidth
              >
                {provinces.map((item: ProvinceList) => (
                  <MenuItem key={item.key} selected={physicalAddress?.province === item.key} value={item.key}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SimpleInput
              testId="postalcode-input"
              label={t('user:address.labels.postalCode')}
              value={physicalAddress?.postal}
              style={{ marginBottom: 16 }}
              required
              onChange={(event) => updatePhysicalAddress({ ...physicalAddress, postal: event.target.value })}
              onClear={clearPostal}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" sx={{ width: '100%', margin: 0 }}>
              <InputLabel id="demo-simple-select-label">{t('user:address.labels.country')}</InputLabel>
              <Select
                value={physicalAddress?.country}
                onChange={(event) => { updatePhysicalAddress({ ...physicalAddress, country: event.target.value as AddressCountries }); }}
                label={t('user:address.labels.country')}
                fullWidth
                data-testid="country"
                style={{ marginBottom: 16 }}
              >
                {countries.map((item: CountryList) => (
                  <MenuItem key={item.key} selected={physicalAddress?.country === item.key} value={item.key}>{item.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </OvForm>
    </Box>
  );
};

UserAddress.defaultProps = {
  goToNamedStep: undefined,
};

export default UserAddress;
