import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styles } from '../../../pages/dashboard/styles';
import './householdToggleButton.css';

export const HouseholdToggleButton = ({
  tabShown, onChange,
}: {
  tabShown: 'personal' | 'household' | undefined,
  // eslint-disable-next-line
  onChange: ((event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void) | undefined,
}): JSX.Element => {
  const { t } = useTranslation(['base', 'user']);

  return (
    <Box sx={styles.cageWrapper}>
      <ToggleButtonGroup
        value={tabShown}
        exclusive
        sx={{ justifyContent: 'center', mb: '10px' }}
        size="small"
        onChange={onChange}
      >
        <ToggleButton value="household">{t('user:dashboard.toggle.household')}</ToggleButton>
        <ToggleButton value="personal">{t('user:dashboard.toggle.personal')}</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
