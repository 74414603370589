import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import Toggle from '../controllers/toggle';

interface ToggleListItem {
  title: string,
  subtitle?: string,
  linkText?: string,
  linkUrl?: string,
  checked: boolean,
  onChange: (checked: boolean) => void,
  hasLabel?: boolean,
  spaceAroundContent?: number,
  onClick?: MouseEventHandler<HTMLButtonElement>,
}

const ToggleListItemExtended = ({ ...props }: ToggleListItem): JSX.Element => {
  const { t } = useTranslation(['base']);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center" style={{ marginTop: props.spaceAroundContent }}>
        <Box display="flex" flexDirection="column" mr={1}>
          <Typography variant="bodySub2" sx={{ marginRight: '15px', cursor: 'pointer' }} onClick={props.onClick}><b>{props.title}</b></Typography>
          <Typography variant="bodySub2" sx={{ marginRight: '15px', cursor: 'pointer' }} onClick={props.onClick}>
            {props.subtitle ?? ''}
            <Link href={props.linkUrl ?? ''} underline="always" target="_blank">{props.linkText}</Link>
          </Typography>
        </Box>
        <Toggle checked={props.checked} onChange={props.onChange} labelTextTrue={t('base:toggle.yes')} labelTextFalse={t('base:toggle.no')} hasLabel={props.hasLabel} invertTextColor />
      </Box>
      <Divider style={{ marginTop: props.spaceAroundContent }} />
    </Box>
  );
};

ToggleListItemExtended.defaultProps = {
  subtitle: undefined,
  linkText: undefined,
  linkUrl: undefined,
  hasLabel: false,
  spaceAroundContent: 17,
  onClick: undefined,
};

export default ToggleListItemExtended;
