/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import OvBackButton from '../buttons/ovBackButton';
import { useContextTheme } from '../../../providers/contextThemeProvider';

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
  event.preventDefault();
}

interface Props {
  breadcrumbItems: {icon?: string, title: string, onClick? : ()=> void}[],
}

const OvBreadCrumb = ({ breadcrumbItems }: Props): JSX.Element => {
  const matches = useMediaQuery('(max-width:600px)');
  const { colors } = useContextTheme();

  const displayElement = (): JSX.Element => {
    if (breadcrumbItems.length <= 1) {
      return (<Box />);
    }
    if (matches && breadcrumbItems.length >= 2) {
      return (
        <OvBackButton onClick={() => {
          const lastIndex = breadcrumbItems.length - 2;
          if (breadcrumbItems[lastIndex].onClick) {
            breadcrumbItems[lastIndex].onClick!();
          }
        }} />
      );
    }
    return (
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" height={20} sx={{ color: colors.secondary, margin: 0 }} />}
          aria-label="breadcrumb">
          {
            breadcrumbItems.map((item) => (
              <Link
                className="breadcrumb"
                key={item.title}
                onClick={item.onClick}
                underline="hover"
                color="inherit"
                href="/"
              >
                {item.icon ? <img src={item.icon} alt={item.title} style={{ marginRight: '8px' }} height={20} width={20} /> : null}
                <Typography variant="heading4" style={{ marginTop: -2, color: colors.secondary }}>{item.title}</Typography>
              </Link>
            ))
          }
        </Breadcrumbs>
      </div>
    );
  };

  return displayElement();
};

export default OvBreadCrumb;
