import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OvForm from '../../common/wrappers/ovForm';
import FeedbackModal from '../../common/wrappers/modals/ovFeedbackModal';

interface Props {
  onClose?: () => void,
}

const IlliquidPortfolioWithdrawalInfoModal = ({
  onClose,
}: Props): JSX.Element => {
  const { t } = useTranslation(['transfer']);
  return (
    <FeedbackModal
      open
      showCloseButton
      onClose={onClose}
      component={(
        <OvForm submitButton={false} onSubmit={onClose}>
          <Box>
            <Box>
              <Typography variant="heading4">
                {t('withdrawal.illiqudWithdrawalInfo.caption')}
              </Typography>
            </Box>
            <Box height={12} />
            <Typography variant="paragraph2">
              {t('withdrawal.illiqudWithdrawalInfo.text')}
            </Typography>
            <Box height={24} />
          </Box>
        </OvForm>
      )}
    />
  );
};

IlliquidPortfolioWithdrawalInfoModal.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
};

export default IlliquidPortfolioWithdrawalInfoModal;
