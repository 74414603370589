import {
  Button,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import AmountInput from '../common/inputs/amountInput';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import { MAXIMUM_TARGET_AMOUNT } from './resources';
import { Goal } from '../../utils/commonGraphql';
import { isLoggedInVar, isOnboardingCompleteVar } from '../../utils/localVariables';

export interface GoalRef {
  targetAmountCents?: number,
}

interface Props {
  setGoalRef: (att: GoalRef) => void,
  defaultAmountCents?: number,
  currentGoal?: Goal | GoalRef,
  onContinue?: () => void,
}

const TargetAmount = ({
  setGoalRef,
  defaultAmountCents = 0,
  onContinue,
  currentGoal,
}: Props): JSX.Element => {
  const { t } = useTranslation(['base', 'goal']);
  const [open, setOpen] = useState(false);
  const [localValidation] = useState(t('goal:targetAmount.validation'));
  const handleClick = (): void => {
    setOpen(true);
  };
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const [targetAmountCents, setTargetAmountCents] = useState(currentGoal?.targetAmountCents || defaultAmountCents);
  const confirmTargetAmount = (event: FormEvent<HTMLFormElement> | undefined, option: 'continue' | 'not sure'): void => {
    event?.preventDefault();
    const isOnBoarding = isLoggedInVar() && !isOnboardingCompleteVar();
    if (targetAmountCents > MAXIMUM_TARGET_AMOUNT) {
      handleClick();
    } else {
      console.log({ event: 'GOAL_TARGET_AMOUNT_DEFINED', targetAmountCents });
      if (isOnBoarding) {
        sendAnalyticsEvent(ovAnalyticsEvents.onBoardingEnterTargetAmount).then();
      } else if (!isOnBoarding && option === 'continue') {
        sendAnalyticsEvent(ovAnalyticsEvents.addGoalEnterTargetAmountContinue).then();
      } else {
        sendAnalyticsEvent(ovAnalyticsEvents.addGoalNoTargetAmountContinue).then();
      }
      setGoalRef({ targetAmountCents });
      if (onContinue) onContinue();
    }
  };
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <>
      <OvForm onSubmit={(e) => confirmTargetAmount(e, 'continue')}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div style={{ flex: '1 0 auto' }}>
            <Typography variant="heading2">{t('goal:targetAmount.title')}</Typography>
            <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('goal:targetAmount.subTitle')}</Typography>
            <AmountInput
              label={t('goal:targetAmount.label')}
              valueCents={targetAmountCents}
              style={{ marginBottom: 32 }}
              onChange={(event) => setTargetAmountCents(event.target.valueCents)}
              testId="goal-target-amount-input"
            />
          </div>
          <Button
            variant="secondary-large"
            type="button"
            style={{ marginBottom: 16 }}
            data-testid="not-sure-button"
            onClick={() => confirmTargetAmount(undefined, 'not sure')}
          >
            {t('goal:targetAmount.notSureButton')}
          </Button>
        </div>
      </OvForm>
      <Snackbar
        open={open}
        onClose={handleClose}
        message={localValidation}
        action={action}
      />
    </>
  );
};

TargetAmount.defaultProps = {
  defaultAmountCents: 0,
  onContinue: undefined,
  currentGoal: undefined,
};

export default TargetAmount;
