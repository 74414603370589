import { colors } from '../../../theme/colors';

export const styles = {
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 25px 0 25px',
  },
  menuIcon: {
    color: colors.ovBlack,
    position: 'relative',
    cursor: 'pointer',
  },
  logo: {
    height: '25px',
  },
  detailBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
  },
  listItemWrapper: {
    background: colors.ovWhite,
  },
  dotMenue: {
    color: colors.secondary,
    fontSize: '1rem !important',
    cursor: 'pointer',
  },
  dotMenueWrapper: {
    marginRight: '16px',
    borderRadius: '5px',
    position: 'relative',
    width: '24px',
    top: '-7px',
  },
  dotMenuIcon: {
    position: 'relative',
    cursor: 'pointer',
    color: colors.secondary,
    borderRadius: '5.25px',
  },
};
