import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';
import OvForm from '../../common/wrappers/ovForm';
import { colors } from '../../../theme/colors';
import { formatReturnAmount } from '../../../utils/commonMethods';
import FeedbackModal from '../../common/wrappers/modals/ovFeedbackModal';

interface Props {
  open: boolean,
  onClose: () => void,
  onSubmit: (e: FormEvent<HTMLFormElement>) => void,
  portfolioBalanceCents: number,
  amountOnHoldCents: number,
  availableWithdrawCents: number,
}

const styles = {
  modal: {
    width: '400px',
    '@media (max-width:600px)': {
      width: '360px',
    },
    padding: '0px 16px 16px 16px',
    backgroundColor: colors.ovWhite,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '27px 0px',
    borderBottom: '1px dashed #DDD9D9',
  },
  innerBoxNoDivider: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '27px 0px',
  },
  headerBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: {
    marginTop: '3px',
    color: colors.neutral30,
  },
};

const PortfolioWithdrawalDetailsModal = ({
  open,
  onClose,
  portfolioBalanceCents,
  amountOnHoldCents,
  onSubmit,
  availableWithdrawCents,
}: Props): JSX.Element => {
  const { t } = useTranslation(['transfer']);
  const breakdownItems = (header: string, value: number, body: string, bottomDivider = true): JSX.Element => (
    <Box style={bottomDivider ? styles.innerBox : styles.innerBoxNoDivider}>
      <Box style={styles.headerBox}>
        <Box display="flex" alignItems="center">
          <Typography variant="heading4" style={{ marginBottom: 0, marginRight: '7.5px' }}>
            {header}
          </Typography>
        </Box>
        <Typography variant="heading4">{formatReturnAmount(value, '$', 2)}</Typography>
      </Box>
      <Box height={12} />
      <Typography variant="paragraph2" color={colors.neutral30}>{body}</Typography>
    </Box>
  );
  return (
    <FeedbackModal
      open={open}
      onClose={() => onClose()}
      component={(
        <OvForm onSubmit={onSubmit} submitButton={false}>
          <Box>
            {
              breakdownItems(
                t('transfer:withdrawal.portfolioWithdrawalDetailsModal.portfolioBalance'),
                portfolioBalanceCents,
                t('transfer:withdrawal.portfolioWithdrawalDetailsModal.portfolioBalanceInfo'),
              )
            }
            {
              breakdownItems(
                t('transfer:withdrawal.portfolioWithdrawalDetailsModal.amountOnHold'),
                amountOnHoldCents,
                t('transfer:withdrawal.portfolioWithdrawalDetailsModal.amountOnHoldInfo'),
              )
            }
            {
              breakdownItems(
                t('transfer:withdrawal.portfolioWithdrawalDetailsModal.availableToWithdraw'),
                availableWithdrawCents,
                t('transfer:withdrawal.portfolioWithdrawalDetailsModal.availableToWithdrawInfo'),
                false,
              )
            }
          </Box>
          <Box height={32} />
        </OvForm>
      )}
    />
  );
};

export default PortfolioWithdrawalDetailsModal;
