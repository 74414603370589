/* eslint-disable  no-promise-executor-return */

import { List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import OvForm from '../common/wrappers/ovForm';
import { FETCH_MY_GOALS } from '../transfers/graphql';
import ButtonListItem from '../common/lists/buttonListItem';

export interface SubAccountRef {
  goalId: string,
}

export interface GoalProp {
  id: string,
  name: string,
}

interface Props {
  setSubAccountRef: (att: SubAccountRef) => void,
  setGoalId: (att: string) => void,
  onContinue?: () => void,
}

const GoalsList = ({ setSubAccountRef, onContinue, setGoalId }: Props): JSX.Element => {
  const { t } = useTranslation(['account']);
  const [goals, setGoals] = useState<GoalProp[]>([]);
  const [fetchMyGoals] = useLazyQuery(FETCH_MY_GOALS, {
    variables: {
      input: {},
    },
    onCompleted: (data) => setGoals(data.me.user.goals),
    nextFetchPolicy: 'standby',
  });
  useEffect(() => {
    fetchMyGoals().then();
  }, [fetchMyGoals]);

  const handleClick = async (item: GoalProp): Promise<void> => {
    setSubAccountRef({ goalId: item.id });
    setGoalId(item.id);
    if (onContinue) {
      onContinue();
    }
  };
  return (
    <OvForm hasButton={false} loading={false}>
      <Typography variant="heading2" data-testid="title">{t('account:goal.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('account:goal.subTitle')}</Typography>
      <List sx={{
        width: '100%',
        height: '350px',
        overflowY: 'auto',
        cursor: 'pointer',
      }}>
        {goals.map((item: GoalProp) => (
          <ButtonListItem
            key={item.id}
            text={item.name}
            onClick={() => {
              handleClick(item).then();
            }}
          />
        ))}
      </List>
    </OvForm>
  );
};

GoalsList.defaultProps = {
  onContinue: undefined,
};

export default GoalsList;
