import {
  Box,
  List,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  FETCH_TRANSFERS,
} from './graphql';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import AllTransfers from './allTransfers';
import TransferItem from './transferItem';
import { styles } from './styles';
import { Transfer } from './resources';
import FlowModal from '../common/wrappers/modals/ovFlowModal';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { HouseholdViewTypes } from '../../utils/commonGraphql';

export interface LatestTransfersProps {
  goalId?: string,
  accountId?: string,
  userId?: string,
  subAccountId?: string,
  refresh?: boolean,
  householdViewType?: HouseholdViewTypes,
  onViewAllTransactionsClicked?: () => void,
}
const LatestTransfers = ({ onViewAllTransactionsClicked, ...props }: LatestTransfersProps): JSX.Element => {
  const { t } = useTranslation(['base', 'user', 'transfer']);
  const { colors } = useContextTheme();
  const [showAllTransfers, setShowAllTransfers] = useState(false);
  const [refresh, setRefresh] = useState(props.refresh);
  const { loading, data, refetch } = useQuery(FETCH_TRANSFERS, {
    variables: {
      input: {
        filter: {
          accountId: props.accountId,
          userId: props.userId,
          goalId: props.goalId,
          subAccountId: props.subAccountId,
          householdViewType: props.householdViewType,
        },
        pagination: {
          perPage: 4,
          sortField: 'createdAt',
          sortDesc: false,
        },
      },
    },
  });
  useEffect(() => {
    if (refresh) {
      refetch();
      setRefresh(false);
    }
  }, [refresh, refetch]);
  const transactions: Transfer[] = data?.fetchTransfers?.transfers || [];
  if (loading) {
    return <OvLoadingIndicator />;
  }
  if (showAllTransfers) {
    return (
      <FlowModal
        open={showAllTransfers}
        onClose={() => setShowAllTransfers(false)}
        showCloseButton
        component={(
          <AllTransfers
            householdViewType={props.householdViewType}
            onClose={() => setShowAllTransfers(false)}
          />
        )}
      />
    );
  }
  return (
    <Box
      className="test-class"
      sx={{ padding: '0 !important', mt: 1 }}
      display="block">
      {transactions.length > 0 ? (
        <Box display="flex" flexDirection="column" sx={{ marginBottom: '8px', padding: '0 !important' }} width="100%">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="captionSectionerBold" color={colors.neutral20}>{t('transfer:trasnsferHistory.investmentActivity')}</Typography>
            <Typography
              style={styles(colors).underline}
              variant="captionSectionerBold"
              color={colors.link}
              onClick={() => {
                if (onViewAllTransactionsClicked) { onViewAllTransactionsClicked(); }
                setShowAllTransfers(true);
              }}>
              {t('transfer:trasnsferHistory.viewAll')}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <List
        sx={styles(colors).listItemWrapper}
      >
        {transactions.length > 0 ? transactions.filter((i) => (i.amountCents !== 0)).map((item: Transfer, index: number) => (
          <TransferItem
            key={Math.random()}
            type={item.type}
            amountCents={item.amountCents}
            state={item.state}
            createdAt={item.createdAt}
            source={item.source}
            last={transactions.length - 1 === index}
          />
        )) : (
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{ minHeight: '260px', textAlign: 'center' }}>
            <Typography variant="bodySub2" color={colors.neutral30} style={{ maxWidth: '184px' }}>{t('transfer:noTransfers')}</Typography>
          </Box>
        )}
      </List>
    </Box>
  );
};

LatestTransfers.defaultProps = {
  goalId: undefined,
  accountId: undefined,
  userId: undefined,
  subAccountId: undefined,
  refresh: undefined,
  onViewAllTransactionsClicked: undefined,
  householdViewType: undefined,
};

export default LatestTransfers;
