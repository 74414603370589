import React from 'react';
import { IconButton } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import Box from '@mui/material/Box';

interface Props {
  onClick: (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;
}

const OvBackButton = ({ onClick }: Props): JSX.Element => {
  const styles = {
    root: {
      icon: {
        position: 'relative',
        left: '0px',
        background: 'rgba(240, 244, 248, 0.5)',
        borderRadius: '5px',
      },
    },
  };

  return (
    <Box sx={{ width: '40px' }}>
      <IconButton color="inherit" aria-label="Menu" sx={styles.root.icon} onClick={onClick}>
        <ChevronLeftRoundedIcon className="black" sx={{ cursor: 'pointer' }} />
      </IconButton>
    </Box>
  );
};

export default OvBackButton;
