/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-confusing-arrow */

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  Box,
} from '@mui/material';
import * as React from 'react';
import { colors } from '../../../theme/colors';

export type DropdownOption = { imageUrl: string, text: string, value: string };

interface Props {
  value: string,
  options: DropdownOption[],
  onChange?: (event: SelectChangeEvent) => void,
  onItemClicked?: (value: string) => void,
  suffixLink?: { text: string, onChange: (event: SelectChangeEvent) => void },
  dataTestId?: string,
}

const styles = {
  noBackDrop: {
    '.MuiBackdrop-root': {
      backdropFilter: 'none !important',
    },
  },
};

const DropdownInput = ({
  options,
  onChange,
  onItemClicked,
  value,
  suffixLink,
  dataTestId,
}: Props): JSX.Element => (
  <FormControl variant="standard" sx={{ minWidth: '100%' }}>
    <Select
      MenuProps={{ sx: styles.noBackDrop }}
      labelId="ov-dropdown-input-label"
      id="ov-dropdown-input"
      data-testid={dataTestId}
      value={value}
      onChange={(e) => {
        if (e.target.value === suffixLink?.text) {
          if (suffixLink) suffixLink.onChange(e);
        }
        if (onChange && (e.target.value !== suffixLink?.text ?? '')) onChange(e);
      }}
      label="Value"
      variant="standard"
      IconComponent={KeyboardArrowDownRoundedIcon}
      fullWidth
      sx={{
        '.MuiInputBase-input': {
          whiteSpace: 'break-spaces !important',
        },
      }}
    >
      {
        options.map((option) => (
          <MenuItem style={{ whiteSpace: 'break-spaces' }} value={option.value} key={option.value} onClick={() => onItemClicked ? onItemClicked(option.value) : undefined}>
            <Box display="flex" alignItems="center" fontSize={18}>
              {option.imageUrl
                ? <img src={option.imageUrl} alt="icons" height={25} width={25} style={{ marginRight: 3 }} /> : null}
              {option.text}
            </Box>
          </MenuItem>
        ))
      }
      {
        suffixLink ? (
          <MenuItem value={suffixLink?.text ?? ''} key={suffixLink.text}>
            <Box display="flex" alignItems="center" color={colors.success} fontWeight={600} fontSize={18}>
              {suffixLink?.text ?? ''}
            </Box>
          </MenuItem>
        ) : null
      }
    </Select>
  </FormControl>
);

DropdownInput.defaultProps = {
  suffixLink: undefined,
  dataTestId: undefined,
  onItemClicked: undefined,
  onChange: undefined,
};

export default DropdownInput;
