/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Typography,
} from '@mui/material';
import React, {
  FormEvent, useContext,
  useEffect, useRef,
  useState,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Markup } from 'interweave';
import OvForm from '../../common/wrappers/ovForm';
import {
  FETCH_SUBACCOUNTS_AVAILABLE_FUNDS,
  FETCH_SUB_ACCOUNT_NET_CONTRIBUTIONS,
  FULL_TRANSFER_BETWEEN_SUBACCOUNTS,
  TRANSFER_BETWEEN_SUBACCOUNTS,
} from '../graphql';
import { UserContext } from '../../../providers/userContextProvider';
import { InputType } from '../../common/inputs/utils/inputType';
import SimpleInput from '../../common/inputs/simpleInput';
import PortfolioDropdown from '../../common/controllers/portfolioDropdown';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import { generateUniqueRequestId } from '../../../utils/generic';
import { AccountTypes } from '../../account/resources';
import FeedbackModal from '../../common/wrappers/modals/ovFeedbackModal';
import { orgSupportUrlVar } from '../../../utils/localVariables';
import { colors } from '../../../theme/colors';
import GoalDropDown from '../../common/controllers/goalDropdown';
import { SubAccount } from '../../../utils/commonGraphql';
import { lockedInAccount } from '../../../utils/accountUtil';
import Disclaimer from '../../common/disclaimer';

interface Props {
  onClose: () => void,
  selectedGoalId?: string,
  selectedSubAccountId?: string,
  onSuccessTransfer?: () => void,
  analyticsEvent?: string,
  showOnlySelectedGoalAsOption?: boolean,
  showOnlySelectedSubAccountAsOption?: boolean,
}

const ExistingPortfolioDeposit = ({
  onClose,
  selectedGoalId,
  selectedSubAccountId,
  onSuccessTransfer,
  analyticsEvent,
  showOnlySelectedGoalAsOption,
  showOnlySelectedSubAccountAsOption,
}: Props): JSX.Element => {
  const { t } = useTranslation(['transfer']);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [allSubAccounts, setAllSubAccounts] = React.useState<SubAccount[]>([]);
  const [goalSubAccounts, setGoalSubAccounts] = useState<SubAccount[] | undefined>(undefined);
  const [fromGoal, setFromGoal] = useState('');
  const [fromPortfolio, setFromPortfolio] = useState('');
  const [subAccountContributionsAmount, setSubAccountContributionsAmount] = useState(0);
  const [toGoal, setToGoal] = useState(selectedGoalId ?? '');
  const [toPortfolio, setToPortfolio] = useState(selectedSubAccountId ?? '');
  const [amount, setAmount] = useState('');
  const { userContext } = useContext(UserContext);
  const isMounted = useRef(false);
  const { showToast } = useGlobalToast();

  const minInitialDepositCents = userContext.organization?.minInitialDepositCents ?? 100;
  const minInvestAmount = minInitialDepositCents / 100;
  const hasContributedEnough = (minInitialDepositCents - subAccountContributionsAmount) <= 0;

  const setDepositTransferData = (data: { transferBetweenSubAccounts: { transfer: { id: string, state: string, } } }): void => {
    const { id, state } = data.transferBetweenSubAccounts.transfer;
    console.log({ event: 'TRANSFER_CREATED', id, state });
    showToast({ message: t('toastMessages:investmentMadeSuccess.h2'), severity: 'success', title: t('toastMessages:investmentMadeSuccess.h1') });
    if (onSuccessTransfer) onSuccessTransfer();
    setLoading(false);
    onClose();
  };

  const setDepositFullTransferData = (data: { transferAllBetweenSubAccounts: { transfer: { id: string, state: string, } } }): void => {
    const { id, state } = data.transferAllBetweenSubAccounts.transfer;
    console.log({ event: 'TRANSFER_CREATED', id, state });
    showToast({ message: t('toastMessages:investmentMadeSuccess.h2'), severity: 'success', title: t('toastMessages:investmentMadeSuccess.h1') });
    if (onSuccessTransfer) onSuccessTransfer();
    setLoading(false);
    onClose();
  };

  const [transferBetweenSubAccounts] = useMutation(TRANSFER_BETWEEN_SUBACCOUNTS, {
    variables: {
      input: {
        amountCents: Math.round(Number(amount) * 100),
        toSubAccountId: toPortfolio,
        fromSubAccountId: fromPortfolio,
        requestId: generateUniqueRequestId(),
      },
    },
    onCompleted: (e) => setDepositTransferData(e),
    onError: () => setLoading(false),
  });

  const [fullTransferBetweenSubAccounts] = useMutation(FULL_TRANSFER_BETWEEN_SUBACCOUNTS, {
    variables: {
      input: {
        toSubAccountId: toPortfolio,
        fromSubAccountId: fromPortfolio,
        requestId: generateUniqueRequestId(),
      },
    },
    onCompleted: (e) => setDepositFullTransferData(e),
    onError: () => setLoading(false),
  });
  const setAllSubAccountsData = (data: { me: { user: { subAccounts: SubAccount[] } } }): void => {
    setAllSubAccounts(data.me.user.subAccounts);
  };

  const [fetchSelectedSubAccountContributions] = useLazyQuery(FETCH_SUB_ACCOUNT_NET_CONTRIBUTIONS, {
    variables: {
      subAccountId: toPortfolio,
    },
    onCompleted(data) {
      const netContributions = data.fetchSubAccount.subAccount.netContributionsHistoryCents;
      const totalContributionsAmountCents = netContributions.reduce((acc: number, cur: { amountCents: number }) => acc + cur.amountCents, 0);
      setSubAccountContributionsAmount(totalContributionsAmountCents);
    },
  });

  const [fetchSubAccount] = useLazyQuery(FETCH_SUBACCOUNTS_AVAILABLE_FUNDS, {
    variables: {},
    onCompleted: (e) => setAllSubAccountsData(e),
    onError: () => setLoading(false),
  });

  useEffect(() => {
    if (!isMounted.current) {
      let goalId = selectedGoalId;
      if (!selectedGoalId && userContext.goals && userContext.goals.length === 1) {
        goalId = userContext.goals[0].id;
        setToGoal(goalId);
      }
      const subAccounts = userContext?.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalId);
      if (subAccounts && subAccounts.length === 1) {
        setToPortfolio(subAccounts[0].id);
      }
      isMounted.current = true;
    }
  }, [userContext, selectedGoalId]);

  useEffect(() => {
    if (
      fromPortfolio
      && toGoal
      && toPortfolio
      && amount
      && Number(amount) > 0
      && (hasContributedEnough || minInvestAmount <= (Number(amount) || 0))
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [fromPortfolio, toGoal, toPortfolio, amount, hasContributedEnough, minInvestAmount]);

  useEffect(() => {
    if (toPortfolio) {
      fetchSelectedSubAccountContributions();
    }
  }, [toPortfolio, fetchSelectedSubAccountContributions]);

  const onFromGoalChanged = (goalid: string): void => {
    setFromGoal(goalid);
    const subAccounts = allSubAccounts.filter((item) => (item?.goal?.id ?? '') === goalid);
    if (subAccounts && subAccounts.length === 1) {
      setFromPortfolio(subAccounts[0].id);
    }
  };

  const onGoalChanged = (goalid: string): void => {
    setToGoal(goalid);
    const subAccounts = userContext?.subAccounts?.filter((item) => (item?.goal?.id ?? '') === goalid);
    if (subAccounts && subAccounts.length === 1) {
      setToPortfolio(subAccounts[0].id);
    }
  };

  const determineConfirmExistingPortfolioTransferJourney = (): void => {
    sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.investPageConfirmExistingPortfolio).then();
    if (userContext.path === '/') {
      sendAnalyticsEvent(ovAnalyticsEvents.homePageInvestSelectToInvestPageConfirmExistingPortfolio).then();
    } else if (userContext.path === '/goal') {
      sendAnalyticsEvent(ovAnalyticsEvents.goalPageInvestSelectToInvestPageConfirmExistingPortfolio).then();
    } else if (userContext.path === '/account') {
      sendAnalyticsEvent(ovAnalyticsEvents.accountPageInvestSelectToInvestPageConfirmExistingPortfolio).then();
    }
  };

  const isFromResp = (): boolean => {
    const fromSubAccount = allSubAccounts.find((item) => fromPortfolio === item.id);
    return (fromSubAccount && ['RESP', 'RESP_SINGLE', 'RESP_FAMILY', 'RESP_ADULT'].includes(fromSubAccount?.account?.type ?? '')) ?? false;
  };
  const isFullDeposit = (): boolean => {
    const foundSubAccount = allSubAccounts?.find((subAccount) => subAccount.id === fromPortfolio);
    if (foundSubAccount) {
      const availableFundsToWithdrawCents = foundSubAccount.statistics?.availableFundsToWithdrawCents ?? 0;
      return (Number(amount) * 100) >= (availableFundsToWithdrawCents * 0.98);
    }
    return false;
  };
  const makeDeposit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (isFromResp()) {
      showToast({ message: t('toastMessages:respPortfolioTransferError.h2'), severity: 'error', title: t('toastMessages:respPortfolioTransferError.h1') });
      return;
    }
    determineConfirmExistingPortfolioTransferJourney();
    setLoading(true);
    if (isFullDeposit()) {
      fullTransferBetweenSubAccounts().then();
    } else {
      transferBetweenSubAccounts().then();
    }
  };

  useEffect(() => {
    fetchSubAccount().then();
  }, [fetchSubAccount]);

  const handleAmountChange = (value: string): void => {
    setAmount(value);
  };

  const getAccountType = (subAccountId: string): AccountTypes => allSubAccounts.find(
    (subAccount) => subAccount.id === subAccountId,
  )?.account?.type as AccountTypes;

  const validateSubAccountTransfer = (fromPortfolioId: string): boolean => {
    const fromAccountType = getAccountType(fromPortfolioId);
    if (!fromAccountType) return true;
    // Locked in Accounts cannot transfer out (this line below validates this)
    if (lockedInAccount.some((x) => [fromAccountType].includes(x))) {
      // Set the value to empty, so the form button would be disabled
      setFromPortfolio('');
      setShowModal(true);
      return false;
    }
    // Set the value to the portfolio ID after validation has passed.
    setFromPortfolio(fromPortfolioId);
    return true;
  };

  return (
    <OvForm buttonText={t('transfer:existingPortfolioDeposit.submitButton')} onSubmit={(e) => makeDeposit(e)} loading={loading} disableButton={disableButton}>
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0, marginTop: 33 }}>{t('transfer:existingPortfolioDeposit.fromGoal')}</Typography>
      <GoalDropDown
        dataTestId="fromGoal-dropdown"
        selectedGoalId=""
        value={fromGoal ?? ''}
        onGoalChanged={(e) => {
          onFromGoalChanged(e);
        }}
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:existingPortfolioDeposit.fromPortfolio')}</Typography>
      <PortfolioDropdown
        onChange={(e) => validateSubAccountTransfer(e.id)}
        value={fromPortfolio}
        subAccounts={allSubAccounts?.filter((subAccount) => subAccount!.goal!.id === fromGoal && subAccount.id !== toPortfolio) ?? []}
        dataTestId="fromPortfolio-dropdown"
        goalId={fromGoal ?? ''}
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:existingPortfolioDeposit.toGoal')}</Typography>
      <GoalDropDown
        dataTestId="toGoal-dropdown"
        selectedGoalId={selectedGoalId}
        value={toGoal ?? ''}
        onGoalChanged={(e) => {
          onGoalChanged(e);
        }}
        setSubAccounts={(values) => {
          setGoalSubAccounts(values);
        }}
        showOnlySelectedGoalAsOption={showOnlySelectedGoalAsOption}
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:existingPortfolioDeposit.toPortfolio')}</Typography>
      <PortfolioDropdown
        forDeposit
        onChange={(e) => {
          if (validateSubAccountTransfer(fromPortfolio)) {
            setToPortfolio(e.id);
          }
        }}
        subAccounts={goalSubAccounts}
        value={toPortfolio}
        dataTestId="toPortfolio-dropdown"
        goalId={toGoal ?? ''}
        selectedSubAccountId={selectedSubAccountId}
        showOnlySelectedSubAccountAsOption={showOnlySelectedSubAccountAsOption}
      />
      <Box style={{ marginBottom: 24 }} />
      <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('transfer:existingPortfolioDeposit.amount')}</Typography>
      <SimpleInput
        testId="amount"
        style={{ height: '50px', marginTop: '-12px' }}
        value={amount}
        onChange={(e) => handleAmountChange(e.target.value)}
        inputType={InputType.CURRENCY}
        inputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputprops: { value: amount },
        }}
        onClear={() => setAmount('')}
        label=""
      />
      {toPortfolio && !hasContributedEnough && minInvestAmount > (Number(amount) || 0) && <Typography variant="captionSectionerMedium" data-testid="error-min-initial-deposit" style={{ marginBottom: 0 }}>{t('transfer:errorMinInvestment', { minAmount: minInvestAmount.toFixed(2) })}</Typography>}
      {
        isFullDeposit() ? (
          <Typography variant="captionSectionerMedium" color={colors.error} sx={{ marginBottom: 0, marginTop: 0.8 }}>{t('transfer:existingPortfolioDeposit.fullTransferMessage')}</Typography>
        ) : undefined
      }
      <Box style={{ marginTop: 15, marginBottom: 36 }}>
        <Disclaimer text={t('transfer:existingPortfolioDeposit.disclaimer')} />
      </Box>
      <FeedbackModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        component={(
          <Box>
            <Typography fontWeight="bold" style={{ marginBottom: 5 }}>{t('transfer:withdrawal.errorBanner.title2')}</Typography>
            <Typography data-testid="error-banner" variant="paragraph3" style={{ marginBottom: 5 }}>
              <Markup content={t('transfer:withdrawal.errorBanner.internalTransferMessage', { linkUrl: orgSupportUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us/requests/new' })} />
            </Typography>
          </Box>
        )}
      />
    </OvForm>
  );
};

ExistingPortfolioDeposit.defaultProps = {
  selectedGoalId: '',
  selectedSubAccountId: '',
  onSuccessTransfer: undefined,
  analyticsEvent: undefined,
  showOnlySelectedGoalAsOption: false,
  showOnlySelectedSubAccountAsOption: false,
};

export default ExistingPortfolioDeposit;
