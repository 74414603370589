/* eslint-disable max-len */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  no-nested-ternary */
import {
  Typography,
  List,
  Checkbox,
  FormControlLabel,
  Link,
  ListItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  FormEvent, useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/system';
import { isEmpty, uniq } from 'lodash';
import { FetchResult } from '@apollo/client/link/core';
import ButtonListItem from '../common/lists/buttonListItem';
import DialogPopUp from './dialog';
import {
  FETCH_ALL_INCOMPLETE_FORM_AGREEMENT, FETCH_SUB_ACCOUNT_INCOMPLETE_FORMS,
  PREVIEW_FORM_AGREEMENT,
  SIGN_FORM_AGREMETNS,
  USER_LATEST_FORM_AGREEMENTS,
} from './graphql';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import OvForm from '../common/wrappers/ovForm';
import {
  AccountTypeAttributes,
  AccountTypes,
  getAccountTypeName,
  getFeatureFlagEnabledAccountTypes,
} from '../account/resources';
import {
  allAccountTypesVar, isLoggedInVar, isOnboardingCompleteVar, orgSupportUrlVar,
} from '../../utils/localVariables';
import { getBackendLanguage, translateBackend } from '../../assets/i18n/config';
import {
  Account,
  Goal,
  SubAccount,
  User,
} from '../../utils/commonGraphql';
import { UserContext } from '../../providers/userContextProvider';
import OvLoadingIndicator from '../common/loaders/ovLoadingIndicator';
import { useContextTheme } from '../../providers/contextThemeProvider';
import { delay } from '../../utils/commonMethods';

export interface FormTemplate {
  type: string,
  minVersion: number,
  translatedDisplayName?: { en?: string, fr?: string },
  digitalSignatureEnabled: boolean,
}
export interface SheduledTransfer {
  subAccount?: SubAccount,
  id: string,
  type: string,
}

enum ChangeTypes {
  NEW_SUB_ACCOUNT = 'NEW_SUB_ACCOUNT',
  PII_UPDATED = 'PII_UPDATED',
  PORTFOLIO_UPDATED = 'PORTFOLIO_UPDATED',
  PORTFOLIO_CHANGE = 'PORTFOLIO_CHANGE',
  AGREEMENT_CHANGE = 'AGREEMENT_CHANGE',
  SCHEDULED_TRANSFER_UPDATED = 'SCHEDULED_TRANSFER_UPDATED',
  USERS_REQUIRED_TO_SIGN_UPDATED = 'USERS_REQUIRED_TO_SIGN_UPDATED',
  FILE_DOCUMENT_DELETE = 'FILE_DOCUMENT_DELETE',
  AFFILIATE_ADDED = 'AFFILIATE_ADDED',
  AFFILIATE_CHANGED = 'AFFILIATE_CHANGED',
  OTHER = 'OTHER',
}

export interface AllIncompleteFormAgreement extends FormTemplate {
  account?: Account,
  subAccount?: SubAccount,
  templateUrl?: string,
  user?: Partial<User>,
  goal?: Goal,
  changeType: ChangeTypes,
  scheduledTransfer?: SheduledTransfer,
}

interface Props {
  onContinue?: () => void,
  subAccountId?: string,
  analyticsEvent?: string,
  delayTimeMilliseconds?: number,
}

const hasIPS = (form: FormTemplate): boolean => form.type === 'INVESTMENT_POLICY_STATEMENT';
const isSupported = (type: AccountTypes | undefined): boolean => getFeatureFlagEnabledAccountTypes().includes(type as AccountTypes);
export const filterAgreements = (allIncompleteFormAgreement: AllIncompleteFormAgreement[]): AllIncompleteFormAgreement[] => (allIncompleteFormAgreement.filter((agreement) => agreement.digitalSignatureEnabled && (!!agreement.scheduledTransfer || !!agreement.user || (!!agreement.account && (isSupported(agreement.account.type) || hasIPS(agreement))) || (!!agreement.subAccount && (isSupported(agreement.subAccount.account?.type) || hasIPS(agreement))) || (!!agreement.goal))));
export const filterSubAccountAgreements = (subAccount: SubAccount): AllIncompleteFormAgreement[] => {
  const agreements = subAccount.allIncompleteFormAgreements!.reduce((acc: AllIncompleteFormAgreement[], agreement: AllIncompleteFormAgreement) => {
    if (agreement.digitalSignatureEnabled) {
      if (agreement.scheduledTransfer) {
        acc.push({
          ...agreement,
          scheduledTransfer: {
            id: agreement.scheduledTransfer.id,
            type: agreement.scheduledTransfer.type,
            subAccount: {
              id: subAccount.id,
              account: {
                id: subAccount.account!.id,
                type: subAccount.account!.type,
              },
            },
          },
        });
      } else if (agreement.user) {
        acc.push({ ...agreement });
      } else if (!!agreement.account && (isSupported(subAccount.account?.type) || hasIPS(agreement))) {
        acc.push({
          ...agreement,
          account: {
            id: agreement.account.id,
            type: subAccount.account!.type,
            state: subAccount.account!.state,
          },
        });
      } else if (!!agreement.subAccount && (isSupported(subAccount.account?.type) || hasIPS(agreement))) {
        acc.push({
          ...agreement,
          subAccount: {
            id: agreement.subAccount.id,
            state: subAccount.state,
            account: {
              id: subAccount.account!.id,
              type: subAccount.account!.type,
            },
          },
        });
      } else if (agreement.goal) {
        acc.push({ ...agreement });
      }
    }

    return acc;
  }, []);

  return agreements;
};

const IncompleteAgreements = ({
  onContinue, subAccountId, analyticsEvent, delayTimeMilliseconds,
}: Props): JSX.Element => {
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const { colors } = useContextTheme();
  const [isFetchingAgreements, setIsFetchingAgreements] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userContext, setUserContext } = useContext(UserContext);
  const [allIncompleteFormAgreement, setAllIncompleteFormAgreement] = useState<AllIncompleteFormAgreement[]>([]);
  const [fetchAllIncompleteAgreement] = useLazyQuery(FETCH_ALL_INCOMPLETE_FORM_AGREEMENT, {
    variables: {
      input: {},
    },
  });
  const { t } = useTranslation(['base', 'user']);
  const client = useApolloClient();

  const [previewHtml, setPreviewHtml] = useState('');
  const openId = useRef<number>(1);
  const [check, setCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [hasUpdatedAgreements, setHasUpdatedAgreements] = useState(false);
  const isMounted = useRef(false);
  const [fetchUserLatestFormAgreements] = useLazyQuery(USER_LATEST_FORM_AGREEMENTS, {
    variables: {},
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setIsCreatingAccount(isEmpty(data?.me?.user?.latestFormAgreements ?? []));
    },
  });

  useEffect(() => {
    (async () => {
      if (isMounted.current) return;
      setIsFetchingAgreements(true);
      await delay(delayTimeMilliseconds ?? 2000);
      const agreements: AllIncompleteFormAgreement[] = [];
      if (!subAccountId) {
        const agreementResponse = await fetchAllIncompleteAgreement();
        agreements.push(...filterAgreements(agreementResponse.data.me.user.allIncompleteFormAgreements));
        setAllIncompleteFormAgreement(agreements);
        setIsFetchingAgreements(false);
      } else if (subAccountId) {
        const result = await client.query({
          query: FETCH_SUB_ACCOUNT_INCOMPLETE_FORMS,
          variables: { subAccountId },
        });
        agreements.push(...filterSubAccountAgreements(result.data.fetchSubAccount.subAccount));
        setAllIncompleteFormAgreement(agreements);
        console.log({ event: 'INCOMPLETE_FORM_AGREEMENTS_FETCHED', subAccountId });
        setIsFetchingAgreements(false);
      }
      isMounted.current = true;
      if (agreements.length === 0 && onContinue) {
        // No forms to sign
        onContinue();
      }
    })();

    fetchUserLatestFormAgreements();
  }, [
    client,
    onContinue,
    subAccountId,
    fetchAllIncompleteAgreement,
  ]);

  useEffect(() => {
    if (!open) { // Increment id each time modal closes
      openId.current += 1;
    }
  }, [open]);

  const getConsentStatement = (): string => {
    let consentStatement = t('user:agreements.consent');
    const tempFormTypes = [...allIncompleteFormAgreement.map((form) => translateBackend(form.translatedDisplayName))];
    tempFormTypes.forEach((value, index) => {
      consentStatement = `${consentStatement} (${index + 1}) ${value}, `;
    });
    consentStatement = `${consentStatement.replace(/(^\s*,)|(,\s*$)/g, '')}`;
    return consentStatement;
  };

  const handleClose = (): void => {
    setPreviewHtml('');
    setOpen(false);
  };

  const previewAgreementClick = async (type: string, agreementType: 'USER' | 'ACCOUNT' | 'SUBACCOUNT' | 'GOAL' | 'SCHEDULED_TRANSFER', objectId?: string): Promise<void> => {
    if (isLoggedInVar() && !isOnboardingCompleteVar()) {
      sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.onBoardingReviewAgreementsView).then();
    } else {
      sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.addGoalReviewYourAgreementView).then();
    }
    const result = await client.query({
      query: PREVIEW_FORM_AGREEMENT,
      variables: {
        input: {
          type,
          ...(agreementType === 'ACCOUNT' ? { accountId: objectId } : undefined),
          ...(agreementType === 'SUBACCOUNT' ? { subAccountId: objectId } : undefined),
          ...(agreementType === 'GOAL' ? { goalId: objectId } : undefined),
          ...(agreementType === 'SCHEDULED_TRANSFER' ? { scheduledTransferId: objectId } : undefined),
          language: getBackendLanguage().toUpperCase(),
        },
      },
    });
    if (result.data) {
      setPreviewHtml(result.data.previewFormAgreement.html);
      setOpen(true);
    }
  };

  const onContinueClick = async (event: FormEvent<HTMLFormElement> | undefined): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();
    setLoading(true);
    await Promise.all(allIncompleteFormAgreement.map(async (item): Promise<FetchResult> => client.mutate({
      mutation: SIGN_FORM_AGREMETNS,
      variables: {
        input: {
          subAccountId: item.subAccount ? item.subAccount.id : undefined,
          scheduledTransferId: item.scheduledTransfer ? item.scheduledTransfer.id : undefined,
          accountId: item.account ? item.account.id : undefined,
          goalId: item.goal ? item.goal.id : undefined,
          formAgreements: [{
            type: item.type,
            version: item.minVersion,
          }],
          language: getBackendLanguage().toUpperCase(),
        },
      },
    })));
    if (isLoggedInVar() && !isOnboardingCompleteVar()) {
      sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.onBoardingReviewAgreementsConfirm).then();
    } else {
      sendAnalyticsEvent(analyticsEvent ?? ovAnalyticsEvents.addGoalReviewYourAgreementConfirm).then();
    }
    setLoading(false);
    setUserContext({
      ...userContext,
      allIncompleteFormAgreements: [],
      accounts: userContext?.accounts?.map((a) => ({ ...a, incompleteFormAgreements: [] })),
      subAccounts: userContext?.subAccounts?.map((s) => ({ ...s, incompleteFormAgreements: [] })),
    });
    if (onContinue) onContinue();
  };

  const hasAccountWithType = (account: AccountTypeAttributes): boolean => {
    const filteredAgreement = allIncompleteFormAgreement.filter((agreement) => agreement.account && (agreement.account?.type === account.key) && agreement.account.state !== 'INACTIVE');
    return filteredAgreement.length > 0;
  };
  const hasSubAccountWithType = (account: AccountTypeAttributes): boolean => {
    const filteredAgreement = allIncompleteFormAgreement.filter((agreement) => agreement.subAccount && (agreement.subAccount?.account?.type === account.key) && agreement.subAccount.state !== 'INACTIVE');
    return filteredAgreement.length > 0;
  };
  const hasScheduleTransferWithAccountType = (account: AccountTypeAttributes): boolean => {
    const filteredAgreement = allIncompleteFormAgreement.filter((agreement) => agreement.scheduledTransfer && agreement.scheduledTransfer?.subAccount && (agreement.scheduledTransfer.subAccount?.account?.type === account.key) && agreement.scheduledTransfer.subAccount.state !== 'INACTIVE');
    return filteredAgreement.length > 0;
  };
  const getAgreementsChangeTypes = (): ChangeTypes[] => (
    allIncompleteFormAgreement.reduce((acc: ChangeTypes[], cur: AllIncompleteFormAgreement) => {
      acc.push(cur.changeType);
      return uniq(acc);
    }, [])
  );
  const checkIfHasUpdatedAgreements = () => {
    const updateAgreementChangeTypes = [
      ChangeTypes.PII_UPDATED,
      ChangeTypes.PORTFOLIO_UPDATED,
      ChangeTypes.PORTFOLIO_CHANGE,
      ChangeTypes.AGREEMENT_CHANGE,
      ChangeTypes.USERS_REQUIRED_TO_SIGN_UPDATED,
      ChangeTypes.OTHER,
      ChangeTypes.FILE_DOCUMENT_DELETE,
      ChangeTypes.AFFILIATE_ADDED,
      ChangeTypes.AFFILIATE_CHANGED,
    ];
    const agreementsChangeTypes = getAgreementsChangeTypes();
    return updateAgreementChangeTypes.some((elem) => agreementsChangeTypes.includes(elem));
  };

  useEffect(() => {
    setHasUpdatedAgreements(checkIfHasUpdatedAgreements());
  }, [allIncompleteFormAgreement, checkIfHasUpdatedAgreements]);

  if (open) {
    return (
      <DialogPopUp
        content={previewHtml}
        handleClose={handleClose}
        open={open}
      />
    );
  }
  if (isFetchingAgreements) {
    return (
      <OvLoadingIndicator />
    );
  }
  return (
    <>
      <OvForm disableButton={!check} onSubmit={onContinueClick} loading={loading}>
        <Typography sx={{ mb: 1 }} variant="heading2">{!isCreatingAccount ? hasUpdatedAgreements ? t('user:agreements.update.title') : t('user:agreements.new.title') : t('user:agreements.firstAgreements.title')}</Typography>
        <Typography sx={{ mb: 1 }} variant="paragraph2">{hasUpdatedAgreements && !isCreatingAccount ? t('user:agreements.update.subTitle') : t('user:agreements.new.subTitle')}</Typography>
        {hasUpdatedAgreements && !isCreatingAccount && (
          <List
            sx={{
              textAlign: 'left',
              listStyleType: 'disc',
              pl: 2,
              '& .MuiListItem-root': {
                display: 'list-item',
              },
            }}
          >
            {getAgreementsChangeTypes().map((changeType) => (
              <ListItem
                key={Math.random()}
                sx={{
                  padding: '0 0 4px 8px',
                }}
              >
                <Typography variant="paragraph2">{t(`user:agreements.changeTypes.${changeType}`)}</Typography>
              </ListItem>
            ))}
          </List>
        )}
        <List className="agreement">
          <>
            {allIncompleteFormAgreement.filter((agreement) => !!agreement.user).map((agreementItem: AllIncompleteFormAgreement) => (
              <ButtonListItem
                key={`user-${agreementItem.type}`}
                hasCheckField={false}
                suffixText={t('user:agreements.buttonText')}
                text={translateBackend(agreementItem.translatedDisplayName)}
                onClick={() => {
                  previewAgreementClick(agreementItem.type, 'USER').then();
                }}
              />
            ))}
            {allIncompleteFormAgreement.filter((agreement) => !!agreement.goal).map((agreementItem: AllIncompleteFormAgreement) => (
              <ButtonListItem
                key={`goal-${agreementItem.type}-${agreementItem.goal?.id}`}
                hasCheckField={false}
                suffixText={t('user:agreements.buttonText')}
                text={`${translateBackend(agreementItem.translatedDisplayName)} - ${agreementItem.goal?.name}`}
                onClick={() => {
                  previewAgreementClick(agreementItem.type, 'GOAL', agreementItem.goal?.id).then();
                }}
              />
            ))}
            {allAccountTypesVar().map((accountType) => (hasAccountWithType(accountType) || hasSubAccountWithType(accountType)
            || hasScheduleTransferWithAccountType(accountType) ? (
              <Box key={accountType.key}>
                <Box mt={1.5} />
                <Typography key={accountType.key} variant="bodyMain3" sx={{ marginBottom: 16, color: colors.neutral30 }}>{getAccountTypeName(accountType.key)}</Typography>
                <Box mt={1.5} />
                {
                  hasAccountWithType(accountType) && allIncompleteFormAgreement.filter((agreement) => !!agreement.account && (agreement.account?.type === accountType.key) && agreement.account.state !== 'INACTIVE').map((agreement: AllIncompleteFormAgreement) => (
                    <ButtonListItem
                      key={`${accountType.key}-${agreement.type}-${agreement?.account?.id}`}
                      hasCheckField={false}
                      suffixText={t('user:agreements.buttonText')}
                      text={translateBackend(agreement.translatedDisplayName)}
                      onClick={() => {
                        previewAgreementClick(agreement.type, 'ACCOUNT', agreement.account?.id).then();
                      }}
                    />
                  ))
                }
                {
                  hasSubAccountWithType(accountType) && allIncompleteFormAgreement.filter((agreement) => !!agreement.subAccount && (agreement.subAccount.account?.type === accountType.key) && agreement.subAccount.state !== 'INACTIVE').map((agreement) => (
                    <ButtonListItem
                      key={`${agreement.type}-${agreement.subAccount?.id}`}
                      hasCheckField={false}
                      suffixText={t('user:agreements.buttonText')}
                      text={translateBackend(agreement.translatedDisplayName)}
                      onClick={() => {
                        previewAgreementClick(agreement.type, 'SUBACCOUNT', agreement?.subAccount?.id).then();
                      }}
                    />
                  ))
                }
                {hasScheduleTransferWithAccountType(accountType) && allIncompleteFormAgreement.filter((agreement) => !!agreement.scheduledTransfer && (agreement.scheduledTransfer.subAccount?.account?.type === accountType.key)).map((agreementItem: AllIncompleteFormAgreement) => (
                  <ButtonListItem
                    key={`user-${agreementItem.type}-${agreementItem.scheduledTransfer?.id}`}
                    hasCheckField={false}
                    suffixText={t('user:agreements.buttonText')}
                    text={translateBackend(agreementItem.translatedDisplayName)}
                    onClick={() => {
                      previewAgreementClick(agreementItem.type, 'SCHEDULED_TRANSFER', agreementItem.scheduledTransfer?.id).then();
                    }}
                  />
                ))}
              </Box>
              ) : undefined))}
          </>
          <FormControlLabel
            className="agreement"
            sx={{ marginLeft: 0 }}
            control={
              (
                <Checkbox
                  data-testid="consent-check"
                  name="agreementCheck"
                  value="agreementCheckVal"
                  checked={check || false}
                  sx={{ marginRight: '15px' }}
                  onChange={(e) => {
                    setCheck(e.target.checked);
                  }}
                />
              )
            }
            label={<Typography variant="body2" sx={{ color: colors.agreementText }}>{getConsentStatement()}</Typography>}
          />
        </List>
      </OvForm>
      <Box sx={{ mt: 1, mb: 1 }}>
        <Typography variant="paragraph2">
          <span>{t('user:agreements.supportMessage')}</span>
          <Link sx={{ pl: '3px' }} href={orgSupportUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us/requests/new'} underline="always" target="_blank">{t('user:agreements.supportLink')}</Link>
        </Typography>
      </Box>
    </>
  );
};

IncompleteAgreements.defaultProps = {
  onContinue: undefined,
  subAccountId: undefined,
  analyticsEvent: undefined,
  delayTimeMilliseconds: 2000,
};

export default IncompleteAgreements;
