import find from 'lodash/find';
import { AccountTypes } from '@onevesthq/ov-enums';
import i18n, { getBackendLanguage, translateBackend } from '../../assets/i18n/config';
import CoreThemeSingleIcon from '../../assets/images/themes/core_single.svg';
import DisruptionThemeSingleIcon from '../../assets/images/themes/disruption_single.svg';
import SocialThemeSingleIcon from '../../assets/images/themes/social_single.svg';
import DefaultThemeIcon from '../../assets/images/themes/default.svg';
import DefaultThemeSingleIcon from '../../assets/images/themes/default_single.svg';
import CoreThemeIcon from '../../assets/images/themes/core.svg';
import DisruptionThemeIcon from '../../assets/images/themes/disruption.svg';
import SocialThemeIcon from '../../assets/images/themes/social.svg';
import { SuggestedHoldingResponse, ThemeResponse } from './graphql';
import { allAccountTypesVar, availableFeatureFlagsVar } from '../../utils/localVariables';
import { Colors } from '../../providers/contextThemeProvider';

export { AccountTypes };

export enum AffiliationRelations {
  SPOUSE = 'SPOUSE',
  COMMON_LAW = 'COMMON_LAW',
  CHILD = 'CHILD',
  PARENT = 'PARENT',
  SIBLING = 'SIBLING',
  GRANDPARENT = 'GRANDPARENT',
  GRANDCHILD = 'GRANDCHILD',
  NEPHEW_NIECE = 'NEPHEW_NIECE',
  GUARDIAN = 'GUARDIAN',
  PRIMARY_CAREGIVER = 'PRIMARY_CAREGIVER',
  OTHER = 'OTHER',
}

export enum TrustedContactPersonRelations {
  CHILD = 'CHILD',
  FRIEND = 'FRIEND',
  GRANDPARENT = 'GRANDPARENT',
  OTHER = 'OTHER',
  PARENT = 'PARENT',
  SIBLING = 'SIBLING',
  SPOUSE = 'SPOUSE',
}

export enum AffiliationTypes {
  PRIMARY_BENEFICIARY = 'PRIMARY_BENEFICIARY',
  CONTINGENT_BENEFICIARY = 'CONTINGENT_BENEFICIARY',
  SUCCESSOR = 'SUCCESSOR',
  JOINT = 'JOINT',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  TRUSTEE = 'TRUSTEE',
  SETTLOR = 'SETTLOR',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  OTHER = 'OTHER',
  AUTHORIZED_INDIVIDUAL = 'AUTHORIZED_INDIVIDUAL',
  CONTRIBUTOR = 'CONTRIBUTOR'
}

export enum FeatureFlagTypes {
  CASH_JOINT = 'CASH_JOINT',
  CORPORATE_ACCOUNTS = 'CORPORATE_ACCOUNTS',
  GRSP = 'GRSP',
  LIF = 'LIF',
  LIRA = 'LIRA',
  PRIF = 'PRIF',
  RDSP = 'RDSP',
  RESP = 'RESP',
  RIF_SPOUSAL = 'RIF_SPOUSAL',
  RRIF = 'RRIF',
  RRSP_SPOUSAL = 'RRSP_SPOUSAL',
  TAX_LOSS_HARVESTING = 'TAX_LOSS_HARVESTING',
  FIX_TRADING = 'FIX_TRADING',
  MANUALLY_ADD_BANK_ACCOUNT = 'MANUALLY_ADD_BANK_ACCOUNT',
  AUTOMATED_BANK_ACCOUNT_LINKING = 'AUTOMATED_BANK_ACCOUNT_LINKING',
  DEPOSITS = 'DEPOSITS',
  WITHDRAWALS = 'WITHDRAWALS',
  AUTO_INVESTMENTS = 'AUTO_INVESTMENTS',
  CREATE_NEW_GOALS = 'CREATE_NEW_GOALS',
  CREATE_NEW_SUB_ACCOUNTS = 'CREATE_NEW_SUB_ACCOUNTS',
  UPDATE_GOAL = 'UPDATE_GOAL',
  UPDATE_SUB_ACCOUNT = 'UPDATE_SUB_ACCOUNT',
  CLOSE_ACCOUNT = 'CLOSE_ACCOUNT',
  UPDATE_PERSONAL_DETAILS = 'UPDATE_PERSONAL_DETAILS',
  VIEW_HELP_CENTER = 'VIEW_HELP_CENTER',
  VIEW_STATEMENTS = 'VIEW_STATEMENTS',
  VIEW_TAX_DOCUMENTS = 'VIEW_TAX_DOCUMENTS',
  VIEW_AGREEMENTS = 'VIEW_AGREEMENTS',
  ID_VERIFICATION = 'ID_VERIFICATION',
  ACCOUNT_TO_ACCOUNT_DEPOSIT = 'ACCOUNT_TO_ACCOUNT_DEPOSIT',
  RESP_SINGLE_JOINT = 'RESP_SINGLE_JOINT',
  RESP_FAMILY_JOINT = 'RESP_FAMILY_JOINT',
  LRIF = 'LRIF',
  RLIF = 'RLIF',
  RLSP = 'RLSP',
  LRSP = 'LRSP',
  ANNUAL_INFORMATION_UPDATE = 'ANNUAL_INFORMATION_UPDATE',
  TFSA = 'TFSA',
  RRSP = 'RRSP',
  PERSONAL = 'RRSP',
  HOUSEHOLD = 'HOUSEHOLD',
  DOCUMENTS = 'DOCUMENTS',
  CUSTODIAN_STATEMENT = 'CUSTODIAN_STATEMENT',
  ONEVEST_STATEMENT = 'ONEVEST_STATEMENT',
  BYPASS_ID_VERIFICATION = 'BYPASS_ID_VERIFICATION',
  PAD_AGREEMENTS = 'PAD_AGREEMENTS',
}

export enum BinaryAnswer {
  YES = 'YES',
  NO = 'NO',
}

export enum ApplyForGovFunds {
  ADDITIONAL_CESG = 'ADDITIONAL_CESG',
  BASIC_CESG = 'BASIC_CESG',
  CLB = 'CLB',
}

export interface AccountTypeAttributes {
  key: AccountTypes,
  title: string,
  description: string,
  section?: 'CommonAccountTypes' | 'RetirementIncomeFunds' | 'RetirementSavingsPlans' | 'CorporateAccounts',
}

export interface TranslatedString {
  en?: string,
  fr?: string,
}

export interface AccountType {
  value: AccountTypes,
  translatedName: TranslatedString,
  translatedDescription: TranslatedString,
}

export interface AffiliationAttributes {
  key: AffiliationRelations,
  title: string,
}
export interface TrustedContactPersonAttributes {
  key: TrustedContactPersonRelations,
  title: string,
}

export interface AffiliationTypeAttributes {
  key: AffiliationTypes,
  title: string,
}

export interface GovFundsAttribute {
  key: ApplyForGovFunds,
  title: string,
  value: boolean,
  subtitle?: string,
  linkText?: string,
  linkUrl?: string,
}

export const accountTypes: AccountTypeAttributes[] = [
  {
    key: AccountTypes.RRSP,
    title: i18n.t('account:resources.typeTitle.RRSP'),
    description: i18n.t('account:resources.typeDescription.RRSP'),
  },
  {
    key: AccountTypes.TFSA,
    title: i18n.t('account:resources.typeTitle.TFSA'),
    description: i18n.t('account:resources.typeDescription.TFSA'),
  },
  {
    key: AccountTypes.PERSONAL,
    title: i18n.t('account:resources.typeTitle.PERSONAL'),
    description: i18n.t('account:resources.typeDescription.PERSONAL'),
  },
  {
    key: AccountTypes.RESP,
    title: i18n.t('account:resources.typeTitle.RESP'),
    description: i18n.t('account:resources.typeDescription.RESP'),
  },
  {
    key: AccountTypes.RRIF,
    title: i18n.t('account:resources.typeTitle.RRIF'),
    description: i18n.t('account:resources.typeDescription.RRIF'),
  },
  {
    key: AccountTypes.RIF_SPOUSAL,
    title: i18n.t('account:resources.typeTitle.RIF_SPOUSAL'),
    description: i18n.t('account:resources.typeDescription.RIF_SPOUSAL'),
  },
  {
    key: AccountTypes.LIRA,
    title: i18n.t('account:resources.typeTitle.LIRA'),
    description: i18n.t('account:resources.typeDescription.LIRA'),
  },
];

export const accountTypesRESP: AccountTypeAttributes[] = [
  {
    key: AccountTypes.RESP_SINGLE,
    title: i18n.t('account:resources.typeTitle.RESP_SINGLE'),
    description: i18n.t('account:resources.typeDescription.RESP_SINGLE'),
  },
  {
    key: AccountTypes.RESP_FAMILY,
    title: i18n.t('account:resources.typeTitle.RESP_FAMILY'),
    description: i18n.t('account:resources.typeDescription.RESP_FAMILY'),
  },
  {
    key: AccountTypes.RESP_ADULT,
    title: i18n.t('account:resources.typeTitle.RESP_ADULT'),
    description: i18n.t('account:resources.typeDescription.RESP_ADULT'),
  },
];

export const allAccountTypes: AccountTypeAttributes[] = [
  ...accountTypes,
  ...accountTypesRESP,
  {
    key: AccountTypes.CASH_JOINT,
    title: i18n.t('account:resources.typeTitle.CASH_JOINT'),
    description: i18n.t('account:resources.typeDescription.CASH_JOINT'),
  },
  {
    key: AccountTypes.CORPORATE_CASH,
    title: i18n.t('account:resources.typeTitle.CORPORATE_CASH'),
    description: i18n.t('account:resources.typeDescription.CORPORATE_CASH'),
  },
  {
    key: AccountTypes.CORPORATE_CHARITY,
    title: i18n.t('account:resources.typeTitle.CORPORATE_CHARITY'),
    description: i18n.t('account:resources.typeDescription.CORPORATE_CHARITY'),
  },
  {
    key: AccountTypes.CORPORATE_ESTATE,
    title: i18n.t('account:resources.typeTitle.CORPORATE_ESTATE'),
    description: i18n.t('account:resources.typeDescription.CORPORATE_ESTATE'),
  },
  {
    key: AccountTypes.CORPORATE_TRUST,
    title: i18n.t('account:resources.typeTitle.CORPORATE_TRUST'),
    description: i18n.t('account:resources.typeDescription.CORPORATE_TRUST'),
  },
  {
    key: AccountTypes.GRSP,
    title: i18n.t('account:resources.typeTitle.GRSP'),
    description: i18n.t('account:resources.typeDescription.GRSP'),
  },
  {
    key: AccountTypes.LIF,
    title: i18n.t('account:resources.typeTitle.LIF'),
    description: i18n.t('account:resources.typeDescription.LIF'),
  },
  {
    key: AccountTypes.LIRA,
    title: i18n.t('account:resources.typeTitle.LIRA'),
    description: i18n.t('account:resources.typeDescription.LIRA'),
  },
  {
    key: AccountTypes.PRIF,
    title: i18n.t('account:resources.typeTitle.PRIF'),
    description: i18n.t('account:resources.typeDescription.PRIF'),
  },
  {
    key: AccountTypes.RIF_SPOUSAL,
    title: i18n.t('account:resources.typeTitle.RIF_SPOUSAL'),
    description: i18n.t('account:resources.typeDescription.RIF_SPOUSAL'),
  },
  {
    key: AccountTypes.RRIF,
    title: i18n.t('account:resources.typeTitle.RRIF'),
    description: i18n.t('account:resources.typeDescription.RRIF'),
  },
  {
    key: AccountTypes.RRSP_SPOUSAL,
    title: i18n.t('account:resources.typeTitle.RRSP_SPOUSAL'),
    description: i18n.t('account:resources.typeDescription.RRSP_SPOUSAL'),
  },
  {
    key: AccountTypes.RDSP,
    title: i18n.t('account:resources.typeTitle.RDSP'),
    description: i18n.t('account:resources.typeDescription.RDSP'),
  },
];

export const affiliationRelations: AffiliationAttributes[] = [
  {
    key: AffiliationRelations.CHILD,
    title: i18n.t('account:affiliationRelations.CHILD'),
  },
  {
    key: AffiliationRelations.GRANDCHILD,
    title: i18n.t('account:affiliationRelations.GRANDCHILD'),
  },
  {
    key: AffiliationRelations.NEPHEW_NIECE,
    title: i18n.t('account:affiliationRelations.NEPHEW_NIECE'),
  },
  {
    key: AffiliationRelations.SIBLING,
    title: i18n.t('account:affiliationRelations.SIBLING'),
  },
  {
    key: AffiliationRelations.OTHER,
    title: i18n.t('account:affiliationRelations.OTHER'),
  },
];

export const affiliationTypes: AffiliationTypeAttributes[] = [
  {
    key: AffiliationTypes.PRIMARY_BENEFICIARY,
    title: i18n.t('account:affiliationTypes.PRIMARY_BENEFICIARY'),
  },
  {
    key: AffiliationTypes.CONTINGENT_BENEFICIARY,
    title: i18n.t('account:affiliationTypes.CONTINGENT_BENEFICIARY'),
  },
  {
    key: AffiliationTypes.AUTHORIZED_INDIVIDUAL,
    title: i18n.t('account:affiliationTypes.AUTHORIZED_INDIVIDUAL'),
  },
  {
    key: AffiliationTypes.SUCCESSOR,
    title: i18n.t('account:affiliationTypes.SUCCESSOR'),
  },
  {
    key: AffiliationTypes.JOINT,
    title: i18n.t('account:affiliationTypes.JOINT'),
  },
  {
    key: AffiliationTypes.POWER_OF_ATTORNEY,
    title: i18n.t('account:affiliationTypes.POWER_OF_ATTORNEY'),
  },
  {
    key: AffiliationTypes.TRUSTEE,
    title: i18n.t('account:affiliationTypes.TRUSTEE'),
  },
  {
    key: AffiliationTypes.SETTLOR,
    title: i18n.t('account:affiliationTypes.SETTLOR'),
  },
  {
    key: AffiliationTypes.BENEFICIAL_OWNER,
    title: i18n.t('account:affiliationTypes.BENEFICIAL_OWNER'),
  },
  {
    key: AffiliationTypes.OTHER,
    title: i18n.t('account:affiliationTypes.OTHER'),
  },
];

export const allAffiliationRelations: AffiliationAttributes[] = [
  ...affiliationRelations,
  {
    key: AffiliationRelations.SPOUSE,
    title: i18n.t('account:affiliationRelations.SPOUSE'),
  },
  {
    key: AffiliationRelations.COMMON_LAW,
    title: i18n.t('account:affiliationRelations.COMMON_LAW'),
  },
  {
    key: AffiliationRelations.PARENT,
    title: i18n.t('account:affiliationRelations.PARENT'),
  },
  {
    key: AffiliationRelations.GRANDPARENT,
    title: i18n.t('account:affiliationRelations.GRANDPARENT'),
  },
  {
    key: AffiliationRelations.GUARDIAN,
    title: i18n.t('account:affiliationRelations.GUARDIAN'),
  },
  {
    key: AffiliationRelations.PRIMARY_CAREGIVER,
    title: i18n.t('account:affiliationRelations.PRIMARY_CAREGIVER'),
  },
];

export const allTrustedContactPersonRelations: TrustedContactPersonAttributes[] = [
  {
    key: TrustedContactPersonRelations.SPOUSE,
    title: i18n.t('account:trustedContactPersonRelations.SPOUSE'),
  },
  {
    key: TrustedContactPersonRelations.FRIEND,
    title: i18n.t('account:trustedContactPersonRelations.FRIEND'),
  },
  {
    key: TrustedContactPersonRelations.PARENT,
    title: i18n.t('account:trustedContactPersonRelations.PARENT'),
  },
  {
    key: TrustedContactPersonRelations.GRANDPARENT,
    title: i18n.t('account:trustedContactPersonRelations.GRANDPARENT'),
  },
  {
    key: TrustedContactPersonRelations.SIBLING,
    title: i18n.t('account:trustedContactPersonRelations.SIBLING'),
  },
  {
    key: TrustedContactPersonRelations.CHILD,
    title: i18n.t('account:trustedContactPersonRelations.CHILD'),
  },
  {
    key: TrustedContactPersonRelations.OTHER,
    title: i18n.t('account:trustedContactPersonRelations.OTHER'),
  },
];

export const binaryAnswers: Record<string, string>[] = [
  {
    key: BinaryAnswer.YES,
    value: i18n.t('account:beneficiary.guardian.yes'),
  },
  {
    key: BinaryAnswer.NO,
    value: i18n.t('account:beneficiary.guardian.no'),
  },
];

export interface ThemeAttributes {
  key: string,
  id: string,
  name: string,
  shortDescription: string,
  description: string,
  singleIconPath?: string,
  iconPath?: string,
}

export interface SuggestedProductHoldingAttributes {
  key: string,
  hexColor: string,
  secondaryAssetClassName: string,
  secondaryAssetClassDescription: string,
  percentage: number,
}

const defaultGraphColors = (colors: Colors): string[] => ([
  colors.success20,
  colors.success30,
  colors.success,
  colors.success70,
  colors.secondary70,
  colors.secondary,
  colors.secondary30,
  colors.secondary20,
  colors.neutralBlack,
  colors.neutral20,
  colors.neutral30,
  colors.neutral70,
  colors.neutral80,
  colors.accentOne,
  colors.accentOne20,
  colors.accentOne30,
  colors.accentOne70,
  colors.error,
  colors.error20,
  colors.error30,
  colors.error70,
]);

const getIconKeyPath = (key: string, type: 'single' | 'full'): string => {
  const iconPostFix = type === 'single' ? `${key}_single.svg` : `${key}.svg`;
  return `${process.env.REACT_APP_ONEHUB_BASE_URL}themes/${iconPostFix.toLowerCase()}`;
};
export const getThemeIconPath = (key: string, type: 'single' | 'full', iconKey?: string): string | undefined => {
  if (iconKey) {
    return getIconKeyPath(iconKey, type);
  }
  if (key === 'CORE') return type === 'single' ? CoreThemeSingleIcon : CoreThemeIcon;
  if (key === 'DISRUPTION_AND_INNOVATION') return type === 'single' ? DisruptionThemeSingleIcon : DisruptionThemeIcon;
  if (key === 'CLIMATE_AND_SOCIAL_IMPACT') return type === 'single' ? SocialThemeSingleIcon : SocialThemeIcon;
  return type === 'single' ? DefaultThemeSingleIcon : DefaultThemeIcon;
};
export const mapThemeAttributes = (themes: ThemeResponse[]): ThemeAttributes[] => {
  const map = themes.map((t) => ({
    id: t.id,
    key: t.key,
    name: translateBackend(t.translatedName),
    shortDescription: translateBackend(t.translatedShortDescription),
    description: translateBackend(t.translatedDescription),
    singleIconPath: getThemeIconPath(t.key, 'single', t.iconKey),
    iconPath: getThemeIconPath(t.key, 'full', t.iconKey),
  }));
  const coreTheme = map.find((t) => t.key === 'CORE');
  return coreTheme ? [coreTheme, ...map.filter((t) => t.key !== 'CORE').sort((a, b) => (a.name > b.name ? 1 : -1))] : map;
};

export const mapSuggestedProductHoldingAttributes = (holdings: SuggestedHoldingResponse[], colors: Colors): SuggestedProductHoldingAttributes[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const holdingsMap = holdings.reduce((r: any, h: SuggestedHoldingResponse) => {
    const assetClassKey = h.financialProduct.secondaryAssetClass.key;
    const assetClass = r[assetClassKey];
    if (assetClass) {
      assetClass.percentage += h.percentage;
    } else {
      // eslint-disable-next-line no-param-reassign
      r[assetClassKey] = {
        secondaryAssetClassName: translateBackend(h.financialProduct.secondaryAssetClass.translatedName),
        secondaryAssetClassDescription: translateBackend(h.financialProduct.secondaryAssetClass.translatedDescription),
        percentage: Number(h.percentage.toFixed(1)),
      };
    }
    return r;
  }, {});
  const sortedHoldings = Object.keys(holdingsMap).map((k) => ({ key: k, ...holdingsMap[k] })).sort((a, b) => b.percentage - a.percentage);
  const graphColors = defaultGraphColors(colors);
  return sortedHoldings.map((h, i) => ({ hexColor: graphColors[i], ...h }));
};

export const getAccountTypes = (accountToExclude: AccountTypes[] = []): AccountTypeAttributes[] => {
  let accountTypesToDisplay = accountTypes;
  if (!availableFeatureFlagsVar().includes('TFSA')) {
    accountTypesToDisplay = accountTypesToDisplay.filter((x) => x.key !== AccountTypes.TFSA);
  }
  if (!availableFeatureFlagsVar().includes('RRSP')) {
    accountTypesToDisplay = accountTypesToDisplay.filter((x) => x.key !== AccountTypes.RRSP);
  }
  if (!availableFeatureFlagsVar().includes('PERSONAL')) {
    accountTypesToDisplay = accountTypesToDisplay.filter((x) => x.key !== AccountTypes.PERSONAL);
  }
  if (!availableFeatureFlagsVar().includes('RESP')) {
    accountTypesToDisplay = accountTypesToDisplay.filter((x) => x.key !== AccountTypes.RESP);
  }
  if (!availableFeatureFlagsVar().includes('RRIF')) {
    accountTypesToDisplay = accountTypesToDisplay.filter((x) => x.key !== AccountTypes.RRIF);
  }
  if (!availableFeatureFlagsVar().includes('RIF_SPOUSAL')) {
    accountTypesToDisplay = accountTypesToDisplay.filter((x) => x.key !== AccountTypes.RIF_SPOUSAL);
  }
  accountTypesToDisplay = accountTypesToDisplay.filter((x) => !accountToExclude.includes(x.key));
  return accountTypesToDisplay;
};

export const getFeatureFlagEnabledAccountTypes = (): AccountTypes[] => {
  const accountType = allAccountTypesVar().map((x) => x.key);
  const respAccounts = accountTypesRESP.map((x) => x.key);
  const enabledAccountTypes = [...accountType.filter((x) => availableFeatureFlagsVar().includes(x)), ...accountTypes.map((x) => x.key)];
  if (availableFeatureFlagsVar().includes('RESP')) return [...respAccounts, ...enabledAccountTypes];
  return enabledAccountTypes;
};

export const getSupportedAccountTypes = (): AccountTypes[] => {
  const respAccounts = accountTypesRESP.map((x) => x.key);
  if (availableFeatureFlagsVar().includes('RESP')) return [...accountTypes.map((x) => x.key), ...respAccounts];
  return accountTypes.filter((x) => x.key !== AccountTypes.RESP).map((x) => x.key);
};

export const isFeatureEnabled = (flag: string): boolean => availableFeatureFlagsVar().includes(flag);

const accountNameWithUserName = (accountName: string, firstName: string): string => {
  if (getBackendLanguage() === 'fr') return `${accountName} de ${firstName}`;
  return `${firstName}'s ${accountName}`;
};

export const getAccountTypeName = (accountType: string, firstName?: string): string => {
  const accountValues = find(allAccountTypesVar(), (o) => (o.key === accountType));
  if (accountValues) {
    if (firstName) return accountNameWithUserName(accountValues.title.toString(), firstName);
    return accountValues.title.toString();
  }
  return '';
};

export const getAccountTypeDescription = (accountType: string): string => {
  const accountValues = find(allAccountTypesVar(), (o) => (o.key === accountType));
  if (accountValues) {
    return accountValues.description.toString();
  }
  return '';
};

export const getSubAccountUniqueName = (name: string, subAccounts: { name?: string }[]): string => {
  const matchCount = subAccounts.filter((s) => s.name && s.name.includes(name)).length;
  return matchCount === 0 ? name : `${name} ${matchCount + 1}`;
};
