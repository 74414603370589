import { Typography, Box, Grid } from '@mui/material';
import * as yup from 'yup';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import OvForm from '../common/wrappers/ovForm';
import BankDetailsInfo from '../../assets/images/void_cheque.svg';
import SimpleInput from '../common/inputs/simpleInput';
import DroppableFileInput from './droppableFileInput';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { userIdVar } from '../../utils/localVariables';
import { FETCH_MY_BANK_ACCOUNTS } from '../transfers/graphql';
import {
  FileDocumentObjectTypes,
  FileDocumentTypes,
  fileUploader,
  FileUploaderProps,
} from '../../utils/fileUploader';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { UserContext } from '../../providers/userContextProvider';
import { FeatureFlagTypes } from '../account/resources';

export interface ValidateState {
  transitNumber: boolean
  bankAccountNumber: boolean,
  nameOfAccount: boolean,
  financialInstitutionNumber: boolean,
}

export interface CreateFileInput {
  objectType: FileDocumentObjectTypes,
  objectId: string,
  fileName: string,
  type: FileDocumentTypes,
  name?: string,
  mediaType?: string,
  sourceId?: string,
  sourceType?: string,
}

export const FETCH_FILE_UPLOAD_URL = gql`
  query fetchFileUploadUrl ($input:FetchFileUploadUrlInput!) {
    fetchFileUploadUrl(input:$input) {
      temporarySignedURL
    }
  }
`;

export const CREATE_FILE_DOCUMENT = gql`
  mutation createFileDocument ($input:CreateFileDocumentInput!) {
    createFileDocument(input:$input) {
      fileDocument {
        id name fileName s3Key type
      }
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation createBankAccount ($input:CreateBankAccountInput!) {
    createBankAccount(input:$input) {
      bankAccount {
        id
        name
        bankAccountNumber
      }
    }
  }
`;

interface Props {
  onContinue?: () => void,
  onContinueToFlinks?: () => void,
  retrievedBank?: (bank: { id: string; name: string; bankAccountNumber: string; }) => void,
}

const ManualBankAccount = ({ onContinue, onContinueToFlinks, retrievedBank }: Props): JSX.Element => {
  const { t } = useTranslation(['flinks', 'base']);
  const graphqlClient = useApolloClient();
  const { userContext } = useContext(UserContext);
  const [file, setFile] = useState<File>();
  const { showToast } = useGlobalToast();
  const [uploadPending, setUploadPending] = useState(false);
  const [createBankAccount, { loading: createbankAccountPending }] = useMutation(CREATE_BANK_ACCOUNT);
  const [validState, setValidState] = useState<ValidateState>({
    transitNumber: true,
    bankAccountNumber: true,
    nameOfAccount: false,
    financialInstitutionNumber: true,
  });

  const [name, setName] = useState('');
  const [state, updateState] = useState({
    transitNumber: '',
    bankAccountNumber: '',
    financialInstitutionNumber: '',
    nameOfAccount: '',
  });

  const checkFieldsValidity = async (): Promise<boolean> => {
    const transitNumberValidity = await yup.string()
      .required()
      .min(5)
      .max(5)
      .isValid(state.transitNumber);
    setValidState((prev) => ({ ...prev, transitNumber: transitNumberValidity }));

    const financialInstitutionNumberValidity = await yup.string()
      .required()
      .min(3)
      .max(3)
      .isValid(state.financialInstitutionNumber);
    setValidState((prev) => ({ ...prev, financialInstitutionNumber: financialInstitutionNumberValidity }));

    const bankAccountNumberValidity = await yup.string()
      .required()
      .min(7)
      .max(12)
      .isValid(state.bankAccountNumber);
    setValidState((prev) => ({ ...prev, bankAccountNumber: bankAccountNumberValidity }));

    return transitNumberValidity && financialInstitutionNumberValidity && bankAccountNumberValidity;
  };
  const clearTransitNumber = (): void => {
    updateState({ ...state, transitNumber: '' });
    setValidState({ ...validState, transitNumber: true });
  };
  const clearBankAccountNumber = (): void => {
    updateState({ ...state, bankAccountNumber: '' });
    setValidState({ ...validState, bankAccountNumber: true });
  };
  const clearNameOfAccount = (): void => {
    updateState({ ...state, nameOfAccount: '' });
    setValidState({ ...validState, nameOfAccount: true });
  };
  const clearFinancialInstitutionNumber = (): void => {
    updateState({ ...state, financialInstitutionNumber: '' });
    setValidState({ ...validState, financialInstitutionNumber: true });
  };

  useEffect(() => {
    if (file && (!name)) {
      setName(file.name);
    }
  }, [file, name, setName]);

  const onSuccessfulUpload = (): void => {
    showToast({ severity: 'success', message: t('account:manualBankAccount.toastMessages.succeessMessage'), title: t('account:manualBankAccount.toastTitles.documentUploaded') });
    if (onContinue) {
      onContinue();
    }
  };

  const nextStep = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    if (!uploadPending && !createbankAccountPending) {
      if (!await checkFieldsValidity()) return; // check fields
      setUploadPending(true);
      const { data: bankAccountData } = await createBankAccount({
        variables: {
          input: {
            bankAccountNumber: state.bankAccountNumber,
            institutionNumber: state.financialInstitutionNumber,
            name: state.nameOfAccount,
            transitNumber: state.transitNumber,
          },
        },
      });
      const bankAccountId = bankAccountData?.createBankAccount.bankAccount.id || '';
      if (retrievedBank) retrievedBank(bankAccountData?.createBankAccount.bankAccount);
      if (bankAccountId && file) {
        const fileUploaderInput: FileUploaderProps = {
          uploadingFile: file,
          createFileInput: {
            objectType: FileDocumentObjectTypes.USER,
            objectId: userIdVar(),
            fileName: file.name,
            type: FileDocumentTypes.BANKING,
            name,
            mediaType: file.type,
            sourceId: bankAccountId,
            sourceType: 'BANKING_SOURCE',
          },
          onSuccess: onSuccessfulUpload,
          refetchQueries: [FETCH_MY_BANK_ACCOUNTS],
          apolloClient: graphqlClient,
        };
        await fileUploader(fileUploaderInput);
        setUploadPending(false);
        sendAnalyticsEvent(ovAnalyticsEvents.addNewBankPageAddAccountSelect).then();
      }
    }
  };

  const disableForm = (): boolean => (!name
    || !(state.transitNumber.length === 5)
    || !(state.financialInstitutionNumber.length === 3)
    || !(state.bankAccountNumber.length <= 17 && state.bankAccountNumber.length >= 5)
  );
  return (
    <Box padding="0px 0px" height="100%">
      <OvForm
        loading={uploadPending || createbankAccountPending}
        onSubmit={nextStep}
        disableButton={disableForm()}
        buttonText={t('base:button.continue')}
        suffixButton={userContext.availableFeatureFlags?.includes(FeatureFlagTypes.AUTOMATED_BANK_ACCOUNT_LINKING) ? {
          onClick: () => {
            if (onContinueToFlinks) {
              sendAnalyticsEvent(ovAnalyticsEvents.addNewBankPageAddAccountAutomaticallySelect).then();
              onContinueToFlinks();
            }
          },
          text: t('account:manualBankAccount.suffixText'),
          variant: 'secondary-large',
          dataTestId: 'manuallyAddAccountLink',
          type: 'text',
        } : undefined}
      >
        <Typography variant="heading2" data-testid="title">{t('account:manualBankAccount.title')}</Typography>
        <Typography variant="paragraph3" style={{ marginBottom: 32 }}>{t('account:manualBankAccount.paragraphOne')}</Typography>
        <Box>
          <img src={BankDetailsInfo} alt="bank_account_details" />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <SimpleInput
              testId="transitNumber-input"
              label={t('account:manualBankAccount.transitNumber')}
              value={state?.transitNumber || ''}
              subtitle={(state.transitNumber.length === 0 || state.transitNumber.length === 5) ? '' : t('account:manualBankAccount.transitNumberError')}
              error={(state.transitNumber.length !== 0 && state.transitNumber.length !== 5)}
              onChange={(event) => {
                updateState((prev) => ({ ...prev, transitNumber: event.target.value }));
              }}
              onClear={clearTransitNumber}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleInput
              testId="financialInstitutionNumber-input"
              label={t('account:manualBankAccount.financialInstitutionNumber')}
              subtitle={(state.financialInstitutionNumber.length === 0 || state.financialInstitutionNumber.length === 3) ? '' : t('account:manualBankAccount.financialInstitutionNumberError')}
              error={(state.financialInstitutionNumber.length !== 0 && state.financialInstitutionNumber.length !== 3)}
              value={state?.financialInstitutionNumber || ''}
              onChange={(event) => updateState((prev) => ({ ...prev, financialInstitutionNumber: event.target.value }))}
              onClear={clearFinancialInstitutionNumber}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleInput
              testId="bankAccountNumber-input"
              label={t('account:manualBankAccount.bankAccountNumber')}
              value={state?.bankAccountNumber || ''}
              subtitle={(state.bankAccountNumber.length >= 5 && state.bankAccountNumber.length <= 17) || state.bankAccountNumber.length === 0 ? '' : t('account:manualBankAccount.bankAccountNumberError')}
              error={!(state.bankAccountNumber.length >= 5 && state.bankAccountNumber.length <= 17) && state.bankAccountNumber.length !== 0}
              onChange={(event) => updateState((prev) => ({ ...prev, bankAccountNumber: event.target.value }))}
              onClear={clearBankAccountNumber}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <SimpleInput
              testId="nameOfAccount-input"
              label={t('account:manualBankAccount.nameOfAccount')}
              value={state?.nameOfAccount || ''}
              onChange={async (event) => updateState((prev) => ({ ...prev, nameOfAccount: event.target.value }))}
              onClear={clearNameOfAccount}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="paragraph3" style={{ marginBottom: 16 }}>{t('account:manualBankAccount.voidCheque')}</Typography>
            <DroppableFileInput
              onFileChosen={(uploadedFile: File) => {
                setFile(uploadedFile);
              }}
            />
          </Grid>
        </Grid>
      </OvForm>
    </Box>
  );
};

ManualBankAccount.defaultProps = {
  onContinue: undefined,
  onContinueToFlinks: undefined,
  retrievedBank: undefined,
};

export default ManualBankAccount;
