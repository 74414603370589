import {
  Typography,
  List,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getAffiliationRelationByAccount } from '../../pages/affiliates';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import ButtonListItem from '../common/lists/buttonListItem';
import OvForm from '../common/wrappers/ovForm';
import { AffiliateUser, Affiliation } from './createAccountAuxiliaryWizard';
import {
  AccountTypes, AffiliationAttributes, AffiliationRelations, affiliationRelations, AffiliationTypes, allAffiliationRelations,
} from './resources';

export interface UserState {
  affiliation?: Affiliation,
}

interface Props {
  beneficiaryName?: string,
  user: AffiliateUser,
  accountType?: AccountTypes,
  showBeneficiaryError?: boolean,
  updateBeneficiaryList: (user: AffiliateUser) => Promise<boolean | void>,
  onContinue?: () => void,
}

const BeneficiaryRelationsip = ({ user, accountType, ...props }: Props): JSX.Element => {
  const { t } = useTranslation(['account']);

  const handleAnalytics = async (item: AffiliationAttributes): Promise<void> => {
    if (item.key === AffiliationRelations.CHILD) {
      sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryRelationChildButtonSelect).then();
    }
    if (item.key === AffiliationRelations.GRANDCHILD) {
      sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryRelationGrandchildButtonSelect).then();
    }
    if (item.key === AffiliationRelations.SIBLING) {
      sendAnalyticsEvent(ovAnalyticsEvents.addBeneficiaryRelationSiblingButtonSelect).then();
    }
  };

  const onItemSelected = async (item: AffiliationAttributes): Promise<void> => {
    const type = user.affiliation?.type ?? AffiliationTypes.PRIMARY_BENEFICIARY;
    const affiliateUser: AffiliateUser = {
      ...user,
      affiliation: {
        ...user.affiliation, type, signatureRequired: false, relation: item.key,
      },
    };
    await handleAnalytics(item);
    const isUpdated = await props.updateBeneficiaryList(affiliateUser) ?? true;
    if (isUpdated && props.onContinue) props.onContinue();
  };

  const affiliationRelationList: AffiliationAttributes[] = accountType ? allAffiliationRelations.filter(
    (x) => getAffiliationRelationByAccount(accountType, user).includes(x.key),
  ) : affiliationRelations;

  return (
    <OvForm loading={false} hasButton={false}>
      <Typography variant="heading2">{t('beneficiary.relationship.title', { firstName: props.beneficiaryName })}</Typography>
      <Typography variant="paragraph3">{t('beneficiary.relationship.subtitle')}</Typography>
      <List>
        {affiliationRelationList.map(
          (item: AffiliationAttributes) => (
            <Box key={item.title}>
              <ButtonListItem
                key={item.title}
                text={item.title}
                onClick={() => {
                  if (item.key) onItemSelected(item);
                }}
                isSelected={(user?.affiliation?.relation && item.key === user.affiliation.relation) || false}
              />
              <Box mb={1} />
            </Box>
          ),
        )}
      </List>
      {props.showBeneficiaryError && <Typography color="error" variant="paragraph3" sx={{ mt: 1 }}>{t('account:affiliates.relationError')}</Typography>}
    </OvForm>
  );
};

BeneficiaryRelationsip.defaultProps = {
  onContinue: undefined,
  accountType: undefined,
  beneficiaryName: undefined,
  showBeneficiaryError: false,
};

export default BeneficiaryRelationsip;
