/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ThemeOptions } from '@mui/material';
import { getTheme, getWindowInnerHeight } from '../utils/platformUtils';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'primary-large': true;
    'primary-small': true;
    'secondary-large': true;
    'secondary-small': true;
    'secondary-gradient-large': true;
    'error-large': true;
    'error-small': true;
    'disabled-large': true;
    'disabled-small': true;
    'text-large': true;
    'text-small': true;
    'button-list-item': true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headingMain: true;
    heading2: true;
    heading3: true;
    heading4: true;
    heading5: true;
    bodyMain1: true;
    bodyMain2: true;
    bodyMain3: true;
    bodySub1: true;
    bodySub2: true;
    bodySub3: true;
    captionSectionerBold: true;
    captionSectionerMedium: true;
    captionDescriptorBold: true;
    captionDescriptorMedium: true;
    paragraphMain: true;
    paragraph2: true;
    paragraph3: true;
    countryCode: true;
  }
}

const theme = getTheme();
const fontTheme: { [key: string]: { [key: string]: string } } = {
  rise: {
    h1: 'Source Serif Pro, "Trebuchet MS", Helvetica, sans-serif',
    h2: 'Source Serif Pro, "Trebuchet MS", Helvetica, sans-serif',
    h3: 'Opens Sans, "Trebuchet MS", Helvetica, sans-serif',
    h4: 'Opens Sans, "Trebuchet MS", Helvetica, sans-serif',
    base: 'Opens Sans, "Trebuchet MS", Helvetica, sans-serif',
  },
  nuula: {
    h1: 'Sofia-Pro, "Trebuchet MS", Helvetica, sans-serif',
    h2: 'Sofia-Pro, "Trebuchet MS", Helvetica, sans-serif',
    h3: 'Sofia-Pro, "Trebuchet MS", Helvetica, sans-serif',
    h4: 'Sofia-Pro, "Trebuchet MS", Helvetica, sans-serif',
    base: 'Sofia-Pro, "Trebuchet MS", Helvetica, sans-serif',
  },
  manzil: {
    h1: 'Sofia Pro, "Trebuchet MS", Helvetica, sans-serif',
    h2: 'Sofia Pro, "Trebuchet MS", Helvetica, sans-serif',
    h3: 'Sofia Pro, "Trebuchet MS", Helvetica, sans-serif',
    h4: 'Sofia Pro, "Trebuchet MS", Helvetica, sans-serif',
    base: 'Sofia Pro, "Trebuchet MS", Helvetica, sans-serif',
  },
  desjardins: {
    h1: 'Arial, Helvetica, sans-serif',
    h2: 'Arial, Helvetica, sans-serif',
    h3: 'Arial, Helvetica, sans-serif',
    h4: 'Arial, Helvetica, sans-serif',
    base: 'Arial, Helvetica, sans-serif',
  },
  atb: {
    h1: 'ATB TT Norms, Inter, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
    h2: 'ATB TT Norms, Inter, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
    h3: 'ATB TT Norms, Inter, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
    h4: 'ATB TT Norms, Inter, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
    base: 'ATB TT Norms, Inter, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
  },
  pmam: {
    h1: 'DIN, "Trebuchet MS", Helvetica, sans-serif',
    h2: 'DIN, "Trebuchet MS", Helvetica, sans-serif',
    h3: 'DIN, "Trebuchet MS", Helvetica, sans-serif',
    h4: 'DIN, "Trebuchet MS", Helvetica, sans-serif',
    base: 'DIN, "Trebuchet MS", Helvetica, sans-serif',
  },
  peoplestrust: {
    h1: 'Calibri, "Trebuchet MS", Helvetica, sans-serif',
    h2: 'Calibri, "Trebuchet MS", Helvetica, sans-serif',
    h3: 'Calibri, "Trebuchet MS", Helvetica, sans-serif',
    h4: 'Calibri, "Trebuchet MS", Helvetica, sans-serif',
    base: 'Calibri, "Trebuchet MS", Helvetica, sans-serif',
  },
  simplii: {
    h1: '"Causten Round", Helvetica, sans-serif',
    h2: '"Causten Round", Helvetica, sans-serif',
    h3: '"Causten Round", Helvetica, sans-serif',
    h4: '"Causten Round", Helvetica, sans-serif',
    base: '"Causten Round", Helvetica, sans-serif',
  },
};

const ovFonts = {
  h1: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  h2: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  h3: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  h4: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
  base: 'Interphases Pro, "Causten Round", Helvetica, sans-serif',
};

const defaultFonts = {
  ...ovFonts,
  ...(fontTheme[theme || ''] || {}),
};

export const buildThemeOptions = (colors: any, fonts = defaultFonts): ThemeOptions => ({
  typography: {
    fontFamily: fonts.base,
    allVariants: {
      color: colors.baseText,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
      borderRadius: 6,
    },
    h1: {
      fontFamily: fonts.h1,
    },
    h2: {
      fontSize: 28,
      fontFamily: fonts.h2,
    },
    h3: {
      fontFamily: fonts.h3,
    },
    h4: {
      fontFamily: fonts.h4,
    },
  },
  palette: {
    primary: {
      main: colors.success,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    action: {
      disabledBackground: colors.neutral70,
      disabled: colors.neutral30,
    },
  },
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          '.breadcrumb': {
            fontWeight: 600,
            margin: '15px 0px',
            display: 'flex',
            alignItems: 'center',
          },
        },
        separator: {
          margin: '0px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.agreement': {
            fontSize: '8px',
            marginTop: '16px',
            marginBottom: '16px',
            '@media (max-width:600px)': {
              marginLeft: '1px',
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          padding: '0px',
          '&.assumption': {
            '@media (max-width:600px)': {
              maxHeight: 'none',
              '> div:last-child': {
                marginBottom: '10px',
              },
            },
          },
          '&.on-boarding-list': {
            backgroundColor: colors.neutral80,
            padding: '0px 10px',
            height: '250px',
            overflowY: 'auto',
            marginBottom: '16px',
            '@media (max-width:600px)': {
              height: `calc(${getWindowInnerHeight()}px - 400px)`,
            },
          },
          '&.no-scrollbar': {
            scrollbarWidth: 'none', /* Firefox */
            msOverflowStyle: 'none', /* Internet Explorer 10+ */
            '&::-webkit-scrollbar': {
              width: '0px',
              height: '0px',
            },
          },
          '&.investment-knowledge-list': {
            maxHeight: '500px',
            overflowY: 'auto',
            '@media (max-width:600px)': {
              maxHeight: 'none',
              '> div:last-child': {
                marginBottom: '50px',
              },
            },
          },
          '&.risk-question-2-list': {
            minHeight: '300px',
            overflowY: 'auto',
            '@media (max-width:600px)': {
              maxHeight: 'none',
              '> div:last-child': {
                marginBottom: '20px',
              },
            },
          },
          '&.theme-select-list': {
            width: '100%',
            marginBottom: '32px',
            overflowY: 'auto',
            cursor: 'pointer',
            '@media (max-width:600px)': {
              maxHeight: 'none)',
              '> div:last-child': {
                marginBottom: '50px',
              },
            },
          },
          '&.theme-description-list': {
            marginBottom: '32px',
            overflowY: 'auto',
            '@media (max-width:600px)': {
              margin: '10px 0px 0px 0px',
              maxHeight: 'none)',
              '> hr:last-child': {
                marginBottom: '10px',
              },
            },
          },
          '&.agreement': {
            maxHeight: '500px',
            overflowY: 'auto',
            '@media (max-width:600px)': {
              overflowY: 'auto',
              maxHeight: 'none',
              '> label': {
                marginBottom: '10px',
              },
            },
          },
          '&.goal-list': {
            maxHeight: '100%',
            cursor: 'pointer',
            '@media (max-width:600px)': {
              maxHeight: 'calc(100vh - 250px)',
              overflowY: 'auto',
              '> div:last-child': {
                marginBottom: '50px',
              },
            },
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.preliminary': {
            marginTop: 100,
            '@media (max-width:600px)': {
              height: '100%',
              width: '100%',
              padding: 0,
              margin: 0,
              border: 0,
              '&.ov-header': {
                position: 'absolute',
                top: '80px',
                height: 'calc(100% - 60px)',
              },
            },
          },
          '$home-page': {
            padding: '0px !important',
          },
          '&.home-page-class': {
            marginTop: '80px',
            maxWidth: '960px',
            padding: 0,
            '@media (max-width:960px)': {
              height: 'calc(100% - 2px)',
              width: '100%',
              maxWidth: '600px',
              padding: 0,
              border: 0,
            },
            '@media (max-width:600px)': {
              height: 'calc(100% - 2px)',
              width: '100%',
              padding: 0,
              margin: 0,
              border: 0,
            },
          },
          '&.deposit-page-class': {
            backgroundColor: colors.greyBackground,
            '@media (max-width:600px)': {
              backgroundColor: colors.ovWhite,
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          '&.ov-header': {
            height: '60px',
            position: 'fixed',
            boxShadow: '0px 0px 14px 0px #0000000d',
            top: '0px',
            width: '100%',
          },
          '&.ov-activity-header': {
            backgroundColor: colors.transparent,
            boxShadow: 'none',
            position: 'relative',
            zIndex: 0,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          // '@media (max-width:600px)': {
          //   overflowY: 'auto',
          //   maxHeight: '54vh',
          //   '@media (max-height:750px)': {
          //     maxHeight: '48vh',
          //   },
          // },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.button-disclaimer-responsive': {
            display: 'block',
            textAlign: 'center',
            marginBottom: '16px',
          },
        },
      },
      variants: [
        {
          props: { variant: 'headingMain' },
          style: {
            fontSize: 32,
            fontWeight: 600,
            fontFamily: fonts.h1,
            lineHeight: '40px',
            fontStyle: 'normal',
            display: 'block',
          },
        },
        {
          props: { variant: 'heading2' },
          style: {
            fontSize: 28,
            fontWeight: 600,
            fontFamily: fonts.h2,
            lineHeight: '34px',
            fontStyle: 'normal',
            flex: 'none',
            order: 0,
            flexGrow: 0,
            display: 'block',
            marginBottom: '16px',
            '@media (max-width:600px)': {
              fontSize: '6.5vw',
            },
          },
        },
        {
          props: { variant: 'heading3' },
          style: {
            fontSize: 20,
            fontWeight: 600,
            fontFamily: fonts.h3,
            lineHeight: '30px',
            fontStyle: 'normal',
            display: 'block',
          },
        },
        {
          props: { variant: 'heading4' },
          style: {
            fontSize: 16,
            fontWeight: 600,
            fontFamily: fonts.h4,
            lineHeight: '24px',
            fontStyle: 'normal',
            display: 'block',
          },
        },
        {
          props: { variant: 'heading5' },
          style: {
            fontSize: 14,
            fontWeight: 600,
            fontFamily: fonts.base,
            lineHeight: '16.8px',
            marginBottom: 16,
            fontStyle: 'normal',
            display: 'block',
          },
        },
        {
          props: { variant: 'bodyMain1' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 18,
          },
        },
        {
          props: { variant: 'bodyMain2' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            lineHeight: '24px',
          },
        },
        {
          props: { variant: 'bodyMain3' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            lineHeight: '24px',
          },
        },
        {
          props: { variant: 'bodySub1' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '20px',
          },
        },
        {
          props: { variant: 'bodySub2' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '20px',
          },
        },
        {
          props: { variant: 'bodySub3' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '20px',
          },
        },
        {
          props: { variant: 'captionSectionerBold' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 14,
          },
        },
        {
          props: { variant: 'captionSectionerMedium' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '18px',
            marginBottom: '16px',
            color: colors.neutral20,
            display: 'block',
          },
        },
        {
          props: { variant: 'captionDescriptorBold' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 12,
            lineHeight: '16px',
          },
        },
        {
          props: { variant: 'captionDescriptorMedium' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '16px',
          },
        },
        {
          props: { variant: 'paragraphMain' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '24px',
            display: 'block',
          },
        },
        {
          props: { variant: 'paragraph2' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '18px',
            display: 'block',
          },
        },
        {
          props: { variant: 'paragraph3' },
          style: {
            fontFamily: fonts.base,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '18px',
            display: 'block',
            marginBottom: '32px',
          },
        },
        {
          props: { variant: 'countryCode' },
          style: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '24px',
            color: colors.neutral30,
            display: 'block',
            marginBottom: '0px',
          },
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 0px 14px rgba(122, 122, 122, 0.15) !important',
          transitionDuration: '0s !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.ovWhite,
          '&.responsive': {
            '@media (max-width:600px)': {
              position: 'absolute',
              bottom: '32px',
              left: 0,
            },
          },
          '&.responsive-14': {
            '@media (max-width:600px)': {
              position: 'absolute',
              bottom: '75px',
              left: 0,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: colors.disabledBackground,
            color: colors.disabledText,
            width: '100%',
            ':disabled': {
              backgroundColor: colors.disabledBackground,
            },
            ':hover': {
              backgroundColor: colors.disabledBackground,
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'primary-large' },
          style: {
            backgroundColor: colors.primary,
            color: colors.ovWhite,
            width: '100%',
            height: '42px',
            ':hover': {
              backgroundColor: colors.primary,
            },
          },
        },
        {
          props: { variant: 'secondary-large' },
          style: {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: colors.primary,
            color: colors.primary,
            borderRadius: '6px',
            padding: '9px 10px',
            boxSizing: 'border-box',
            height: '42px',
            width: '100%',
            backgroundColor: colors.baseComponentBackground,
            ':hover': {
              backgroundColor: colors.baseComponentBackground,
            },
          },
        },
        {
          props: { variant: 'secondary-gradient-large' },
          style: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6.5px',
            padding: '5px 16px',
            background: colors.secondaryGradient,
            borderRadius: '6px',
            color: colors.primary,
            width: '100%',
            height: '31px',
            ':hover': {
              backgroundColor: colors.primary,
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1rem',
            },
          },
        },
        {
          props: { variant: 'error-large' },
          style: {
            backgroundColor: colors.error,
            color: colors.neutralWhite,
            width: '100%',
            ':hover': {
              backgroundColor: colors.error,
            },
          },
        },
        {
          props: { variant: 'disabled-large' },
          style: {
            backgroundColor: colors.neutral70,
            color: colors.neutral30,
            width: '100%',
            ':disabled': {
              backgroundColor: colors.neutral70,
            },
            ':hover': {
              backgroundColor: colors.neutral70,
            },
          },
        },
        {
          props: { variant: 'text-large' },
          style: {
            backgroundColor: `${colors.transparent} !important`,
            color: colors.primary,
            width: '100%',
            ':hover': {
              backgroundColor: colors.transparent,
            },
          },
        },
        {
          props: { variant: 'primary-small' },
          style: {
            backgroundColor: colors.primary,
            color: colors.neutralWhite,
            ':hover': {
              backgroundColor: colors.primary,
            },
          },
        },
        {
          props: { variant: 'secondary-small' },
          style: {
            borderStyle: 'solid',
            borderWidth: '2px',
            width: '100%',
            marginBottom: '16px',
            borderColor: colors.primary,
            color: colors.primary,
            ':hover': {
              backgroundColor: colors.neutralWhite,
            },
          },
        },
        {
          props: { variant: 'error-small' },
          style: {
            backgroundColor: colors.error,
            color: colors.neutralWhite,
            ':hover': {
              backgroundColor: colors.error,
            },
          },
        },
        {
          props: { variant: 'disabled-small' },
          style: {
            backgroundColor: colors.neutral70,
            color: colors.neutral30,
            ':disabled': {
              backgroundColor: colors.neutral70,
            },
            ':hover': {
              backgroundColor: colors.neutral70,
            },
          },
        },
        {
          props: { variant: 'text-small' },
          style: {
            backgroundColor: colors.transparent,
            color: colors.primary,
            ':hover': {
              backgroundColor: colors.transparent,
            },
          },
        },
        {
          props: { variant: 'button-list-item' },
          style: {
            width: '100%',
            minHeight: 52,
            padding: '0px 18px',
            backgroundColor: colors.baseComponentBackground,
            border: `2px solid ${colors.buttonItemBorder}`,
            display: 'flex',
            borderRadius: 6,
            justifyContent: 'space-between',
            '@media (max-width:600px)': {
              '&:active': {
                backgroundColor: `${colors.buttonItemBackground} !important`,
                border: `2px solid ${colors.buttonItemHoverBorder}`,
              },
            },
            '@media (min-width:600px)': {
              '&:hover': {
                backgroundColor: `${colors.buttonItemBackground} !important`,
                border: `2px solid ${colors.buttonItemHoverBorder}`,
              },
            },
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 10px rgba(234, 236, 239, 0.5)',
          marginBottom: '32px',
          '&.preliminary': {
            backgroundColor: colors.applicationCard,
            '@media (max-width:600px)': {
              minHeight: '100%',
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              paddingBottom: 0,
              paddingTop: 0,
            },
          },
          '&.home-page-card': {
            padding: '0px !important',
          },
          '&.deposit-page-class': {
            backgroundColor: colors.depositPageMinWidth600Background,
            border: 'none',
            boxShadow: 'none',
            '@media (max-width:600px)': {
              backgroundColor: colors.depositPageMaxWidth600Background,
            },
          },
          '&.goal-flow': {
            boxShadow: 'none !important',
            marginBottom: '0px !important',
            minWidth: '300px',
          },
          '&.ov-activity-flow': {
            boxShadow: 'none !important',
            marginBottom: '0px !important',
            '@media (min-width:600px)': {
              minWidth: '450px',
            },
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&.preliminary': {
            boxSizing: 'border-box',
            '@media (max-width:600px)': {
              padding: '16px',
            },
          },
          '> div': {
            height: '100%',
            '&.ov-step-wizard': {
              '@media (max-width:600px)': {
                display: 'flex',
                flexDirection: 'column',
              },
            },
            '> .rsw_2Y': {
              height: '100%',
              '> .rsw_2f': {
                height: '100%',
                '> div': {
                  height: '100%',
                  '&.ov-step-wizard': {
                    '@media (max-width:600px)': {
                      display: 'flex',
                      flexDirection: 'column',
                    },
                  },
                  '> div': {
                    height: '100%',
                    '> div': {
                      height: '100%',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: colors.neutral70,
            '& .MuiSlider-track': {
              color: colors.neutral70,
            },
          },
        },
        thumb: {
          color: colors.neutralWhite,
          '&.Mui-disabled': {
            color: colors.neutral70,
            '&:before': {
              boxShadow: 'none',
            },
          },
        },
        track: {
          color: colors.neutralBlack,
          '&.Mui-disabled': {
            color: colors.neutral70,
          },
        },
        rail: {
          color: colors.neutral70,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: colors.success,
          '&.black': {
            color: colors.neutralBlack,
          },
        },
        colorDisabled: {
          color: colors.neutral80,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.radio,
          padding: 0,
          '&.Mui-disabled': {
            color: colors.accentOne20,
          },
          '.MuiSvgIcon-root': {
            color: colors.radio,
          },
        },
        // disabled: {
        //   color: colors.accentOne20,
        // },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          display: 'none',
        },
        root: {
          fontWeight: 500,
          fontSize: '0.85rem',
          color: colors.neutralBlack,
          top: 4,
          '&.blackInputLabel': {
            top: 0,
            marginBottom: 8,
            color: colors.neutralBlack,
          },
          '&.Mui-focused': {
            color: colors.neutralBlack,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ':after': {
            borderBottom: `2px solid ${colors.neutralBlack}`,
          },
        },
        focused: {},
        error: {},
        input: {},
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          width: 15,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          justifyContent: 'space-between',
        },
        indicator: {
          backgroundColor: colors.primary,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: `${colors.neutral30} !important`,
          '&.Mui-selected': {
            color: `${colors.primary} !important`,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: 8,
          '&.pinEmpty': {
            '& fieldset': {
              borderColor: colors.transparent,
              borderStyle: 'solid',
              borderWidth: '2px',
              borderRadius: '4px !important',
              // backgroundColor: colors.neutral80,
              color: colors.ovBlack,
              textAlign: 'center',
            },
            '& .MuiInputBase-input:hover + fieldset': {
              borderStyle: 'solid',
              borderWidth: '2px',
              borderRadius: '4px',
              borderColor: colors.secondary20,
              textAlign: 'center',
              // backgroundColor: colors.neutral80,
            },
            '& .MuiInputBase-input:focus + fieldset': {
              borderStyle: 'solid',
              borderWidth: '2px',
              borderRadius: '4px',
              borderColor: colors.secondary20,
              // backgroundColor: colors.neutral80,
            },
          },
          '&.pinStandard': {
            '& fieldset': {
              borderColor: colors.ovBlack,
              borderStyle: 'solid',
              borderWidth: '2px',
              borderRadius: '4px',
              color: colors.ovBlack,
              textAlign: 'center',
            },
            '& .MuiInputBase-input:hover + fieldset': {
              borderStyle: 'solid',
              borderWidth: '2px',
              borderRadius: '4px',
              borderColor: colors.secondary20,
              textAlign: 'center',
            },
            '& .MuiInputBase-input:focus + fieldset': {
              borderStyle: 'solid',
              borderWidth: '2px',
              borderRadius: '4px',
              borderColor: colors.secondary20,
            },
          },
          '&.pinError': {
            '& fieldset': {
              borderColor: colors.error,
              borderStyle: 'solid',
              borderWidth: '2px',
              borderRadius: '4px',
              // backgroundColor: colors.neutral80,
              color: colors.error,
              textAlign: 'center',
            },
            '& .MuiInputBase-input:hover + fieldset': {
              borderStyle: 'solid',
              borderWidth: '2px',
              borderRadius: '4px',
              borderColor: colors.error,
              textAlign: 'center',
              color: colors.error,
              // backgroundColor: colors.neutral80,
            },
            '& .MuiInputBase-input:focus + fieldset': {
              borderStyle: 'solid',
              borderWidth: '2px',
              borderRadius: '4px',
              borderColor: colors.error,
              color: colors.error,
              // backgroundColor: colors.neutral80,
            },
          },
          '&.amountActive': {
            '& fieldset': {
              borderColor: colors.transparent,
            },
            '& .MuiInputBase-input:hover + fieldset': {
              borderColor: colors.transparent,
            },
            '& .MuiInputBase-input:focus + fieldset': {
              borderColor: colors.transparent,
            },
            '& .MuiInputBase-input:disabled + fieldset': {
              borderColor: colors.transparent,
            },
          },
        },
      },
    },
    MuiModal: {
      defaultProps: {
        onClick: (e) => e.stopPropagation(),
        onBackdropClick: (e) => e.stopPropagation(),
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: { onClick: (e) => e.stopPropagation() },
        onBackdropClick: (e) => e.stopPropagation(),
      },
      styleOverrides: {
        root: {
          height: 'auto',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0) !important',
          backdropFilter: 'blur(5px) !important',
          '::backdrop': 'blur(5px) !important',
          WebkitBackdropFilter: 'blur(5px) !important',
          '::MsBackdrop': 'blur(5px) !important',
          filter: 'blur(5px) !important',
          position: 'absolute',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
          width: '100vw',
          height: '100vh',
          '&.account-menu-class': {
            backdropFilter: 'none !importsnt',
            position: 'relative',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(0px) !important',
          '::backdrop': 'blur(0px) !important',
          WebkitBackdropFilter: 'blur(0px) !important',
          '::MsBackdrop': 'blur(0px) !important',
          filter: 'blur(0px) !important',
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        autoHideDuration: 5000,
      },
      styleOverrides: {
        root: {
          marginTop: '32px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          '&.listItemCount': {
            width: 24,
            height: 24,
            backgroundColor: colors.secondary,
            fontFamily: 'Interphases Pro',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 14,
          },
          '&.personaStepListItemCount': {
            width: 24,
            height: 24,
            backgroundColor: colors.neutral90,
            color: colors.secondary,
            fontFamily: 'Interphases Pro',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 14,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0px',
          '.MuiSvgIcon-root': {
            color: `${colors.radio} !important`,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.dividerColor,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          '&.loader': {
            color: colors.neutralWhite,
            width: '20px !important',
            height: '20px !important',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: colors.loadingBackground,
        },
        bar: {
          backgroundColor: colors.loading,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.link,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          backgroundColor: colors.body,
        },
        body: {
          backgroundColor: colors.body,
        },
      },
    },
  },
});
