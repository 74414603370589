/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, InputAdornment, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { FormEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import OvForm from '../common/wrappers/ovForm';
import { PhysicalAddress } from '../user/address/resources';
import SimpleInput from '../common/inputs/simpleInput';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../utils/firebase';
import { formatDate, generateAddressString } from '../../utils/commonMethods';
import { UPDATE_USER } from './graphql';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { useContextTheme } from '../../providers/contextThemeProvider';

export interface UserState {
  id?: string,
  firstName?: string,
  middleName?: string,
  lastName?: string,
  primaryEmail?: string,
  physicalAddress?: Partial<PhysicalAddress>,
  sin?: string,
  employmentStatus?: string,
  studentAreaOfStudy?: string,
  jobTitle?: string,
  dateOfBirth?: Date,
  companyType?: string,
}

export interface Props {
  user: UserState,
  onContinue?: () => void,
  goToNamedStep?: (step: string) => void,
  onEditMode?: (edit: boolean) => void,
  isSpousalOnly?: boolean,
  proceedToNextStep?: () => void,
}

const style = {
  container: {
    marginBottom: '32px',
    minHeight: '200px',
    maxHeight: '550px',
  },
};

const ConfirmSpousalAffiliateDetails = ({
  user, onContinue, goToNamedStep, onEditMode, isSpousalOnly, proceedToNextStep,
}: Props): JSX.Element => {
  if (onEditMode) {
    onEditMode(false);
  }
  const { colors } = useContextTheme();
  const { t } = useTranslation(['base', 'account', 'user']);
  const { showToast } = useGlobalToast();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [updateSpousalInformation] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        spouseFirstName: user.firstName,
        spouseMiddleName: user.middleName,
        spouseLastName: user.lastName,
        spouseCompanyType: user.companyType,
        spouseJobTitle: user.jobTitle,
        spouseDateOfBirth: (user.dateOfBirth && user.dateOfBirth !== undefined) ? formatDate(user.dateOfBirth) : '',
        spouseSin: (user.sin && user.sin !== undefined) ? user.sin : '',
        spouseAddress: user.physicalAddress ?? undefined,
        spouseEmail: user.primaryEmail,
        spouseEmploymentType: user.employmentStatus,
      },
    },
    onCompleted: () => {
      if (onContinue) {
        onContinue();
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    onError: (res: any) => {
      setDisableSubmit(true);
      showToast({ message: res?.graphQLErrors[0]?.message, severity: 'error', title: t('toastMessages:genericError.h1') });
    },
  });
  const continueToNextStep = (event: FormEvent<HTMLFormElement> | undefined): void => {
    event?.preventDefault();
    setLoading(true);
    if (isSpousalOnly) {
      updateSpousalInformation();
    } else {
      sendAnalyticsEvent(ovAnalyticsEvents.rrifSpouseReviewInformationScreenContinueButtonSelect).then();
      if (onContinue) {
        onContinue();
        setLoading(false);
      }
    }
    setCount(1);
  };

  useEffect(() => {
    if (count === 1) {
      if (proceedToNextStep) proceedToNextStep();
    }
  }, [count]);
  const endAdornment = (onClick: () => void): JSX.Element => (
    <InputAdornment position="end">
      <IconButton
        aria-label="clear input field"
        disableRipple
        onClick={() => {
          if (onEditMode) {
            onEditMode(true);
          }
          onClick();
        }}
        edge="end"
      >
        <Typography variant="heading5" color={colors.success} style={{ marginBottom: 'px', textAlign: 'left' }}>
          {t('suitabilityReview:editText')}
        </Typography>
      </IconButton>
    </InputAdornment>
  );
  const parseUserName = (userObject: Partial<UserState>): string => {
    if (!userObject) return '';
    return [userObject.firstName, userObject.middleName, userObject.lastName].filter(Boolean).join(' ');
  };
  const getParsedAddress = (): string => {
    if (user.physicalAddress) return generateAddressString(user.physicalAddress);
    return '';
  };

  const getOccupation = (): string => {
    if (user.employmentStatus === 'UNEMPLOYED') {
      return t('user:employementOptions.unemployed');
    }
    return user.jobTitle || user.studentAreaOfStudy || '';
  };

  return (
    <OvForm
      hasButton
      buttonText={t('base:button.continue')}
      onSubmit={continueToNextStep}
      loading={loading}
      disableButton={disableSubmit}
    >
      <Box display="flex" flexDirection="column" style={style.container}>
        <Typography variant="headingMain" textAlign="left" style={{ marginBottom: '20px' }}>{t('account:rrifSteps.confirmationPage.title')}</Typography>
        <SimpleInput
          label={t('account:rrifSteps.confirmationPage.legalName')}
          value={parseUserName(user)}
          style={{ marginBottom: 16 }}
          testId="legal-name-input"
          suffixAdornment={endAdornment(
            () => {
              if (goToNamedStep) {
                goToNamedStep('rrifSpousalName');
              }
            },
          )}
        />
        <SimpleInput
          label={t('account:rrifSteps.confirmationPage.dateOfBirth')}
          value={user.dateOfBirth ? dayjs(user.dateOfBirth).format('YYYY-MM-DD') : undefined}
          style={{ marginBottom: 16 }}
          testId="legal-name-input"
          suffixAdornment={endAdornment(
            () => {
              if (goToNamedStep) {
                goToNamedStep('rrifSpousalDOB');
              }
            },
          )}
        />
        <SimpleInput
          label={t('account:rrifSteps.confirmationPage.email')}
          value={`${user.primaryEmail}`}
          style={{ marginBottom: 16 }}
          testId="email-input"
          suffixAdornment={endAdornment(
            () => {
              if (goToNamedStep) {
                goToNamedStep('rrifSpousalEmail');
              }
            },
          )}
        />
        <SimpleInput
          label={t('account:rrifSteps.confirmationPage.occupation')}
          value={getOccupation()}
          style={{ marginBottom: 16 }}
          testId="email-input"
          suffixAdornment={endAdornment(
            () => {
              if (goToNamedStep) {
                goToNamedStep('rrifSpousalEmploymentStatus');
              }
            },
          )}
        />
        <SimpleInput
          label={t('account:rrifSteps.confirmationPage.address')}
          value={getParsedAddress()}
          style={{ marginBottom: 16 }}
          testId="address-input"
          suffixAdornment={endAdornment(
            () => {
              if (goToNamedStep) {
                goToNamedStep('rrifSpousalPhysicalAddress');
              }
            },
          )}
        />
      </Box>
    </OvForm>
  );
};

ConfirmSpousalAffiliateDetails.defaultProps = {
  onContinue: undefined,
  goToNamedStep: undefined,
  onEditMode: undefined,
  isSpousalOnly: undefined,
  proceedToNextStep: undefined,
};

export default ConfirmSpousalAffiliateDetails;
