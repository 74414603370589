import VeryHighRiskIcon from '../../assets/images/portfolio/risk_very_high.svg';
import HighRiskIcon from '../../assets/images/portfolio/risk_high.svg';
import MediumRiskIcon from '../../assets/images/portfolio/risk_medium.svg';
import LowRiskIcon from '../../assets/images/portfolio/risk_low.svg';
import VeryLowRiskIcon from '../../assets/images/portfolio/risk_very_low.svg';
import i18n from '../../assets/i18n/config';

export interface RiskReturnLevel {
  iconPath: string,
  value: number,
  name: string,
  description: string,
}
export interface ThemeObject {
  description: string,
  key: string,
  name: string,
  iconPath: string,
}
export const generateRiskLevel = (val: number): RiskReturnLevel => {
  let riskReturnObject = {
    iconPath: VeryLowRiskIcon,
    name: '',
    value: 0,
    description: '',
  };
  if (val <= 100 && val > 80) {
    riskReturnObject = {
      iconPath: VeryHighRiskIcon,
      name: i18n.t('portfolio:theme.veryHighRisk.title'),
      value: val,
      description: i18n.t('portfolio:theme.veryHighRisk.text'),
    };
  }
  if (val <= 80 && val > 60) {
    riskReturnObject = {
      iconPath: HighRiskIcon,
      name: i18n.t('portfolio:theme.highRisk.title'),
      value: val,
      description: i18n.t('portfolio:theme.highRisk.text'),
    };
  }
  if (val <= 60 && val > 40) {
    riskReturnObject = {
      iconPath: MediumRiskIcon,
      name: i18n.t('portfolio:theme.mediumRisk.title'),
      value: val,
      description: i18n.t('portfolio:theme.mediumRisk.text'),
    };
  }
  if (val <= 40 && val > 20) {
    riskReturnObject = {
      iconPath: LowRiskIcon,
      name: i18n.t('portfolio:theme.lowRisk.title'),
      value: val,
      description: i18n.t('portfolio:theme.lowRisk.text'),
    };
  }
  if (val <= 20 && val > 0) {
    riskReturnObject = {
      iconPath: VeryLowRiskIcon,
      name: i18n.t('portfolio:theme.veryLowRisk.title'),
      value: val,
      description: i18n.t('portfolio:theme.veryLowRisk.text'),
    };
  }
  return riskReturnObject;
};
