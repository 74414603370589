/* eslint-disable prefer-arrow-callback */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import {
  InputAdornment,
  IconButton,
  Input,
  FormControl,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import NumberFormat from 'react-number-format';
import CloseIcon from '../../../assets/images/close-icon.svg';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface Props {
  label: string,
  value?: string,
  subtitle?: string,
  onContinue?: () => void,
  required?: boolean,
  error?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
  onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  style?: React.CSSProperties,
  testId?:string,
  inputProps?: InputBaseComponentProps,
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<string>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="### ### ###"
        isNumericString
      />
    );
  },
);

const SinInput = ({ ...props }: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const onClear = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (props.onClear) props.onClear(event);
  };

  return (
    <Stack spacing={2} style={props.style}>
      <FormControl fullWidth variant="standard">
        <InputLabel required={props.required}>{ props.label }</InputLabel>
        <Input
          data-testid={props.testId}
          fullWidth
          style={{ width: '100%' }}
          required={props.required}
          error={props.error}
          value={props.value}
          onChange={(event) => {
            if (props.onChange) props.onChange(event);
          }}
          inputProps={props.inputProps}
          inputComponent={NumberFormatCustom as any}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="clear input field"
                disableRipple
                onClick={(event) => onClear(event)}
                edge="end"
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </InputAdornment>
          )}
        />
      </FormControl>
      <Typography
        variant="captionSectionerMedium"
        style={{ marginBottom: 16, marginTop: 8, color: props.error ? colors.error : colors.neutralBlack }}
      >
        {props.subtitle}
      </Typography>
    </Stack>
  );
};

SinInput.defaultProps = {
  value: '',
  required: false,
  subtitle: '',
  error: false,
  onContinue: undefined,
  onChange: undefined,
  onClear: undefined,
  style: undefined,
  testId: '',
  inputProps: undefined,
};

export default SinInput;
