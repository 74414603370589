import { Box } from '@mui/system';
import IdVerificationCheckerWizard, { UserState } from './idVerificationCheckerWizard';
import { StepProps, StepWizardTransitions } from '../common/wrappers/withStepWizard';
import { decideNextStep } from '../checker';

interface Props {
  user: UserState,
  onClose?: () => void,
  journey: number[],
  updateJourney: (data: number[]) => void,
  goToNamedStep?: (step: string) => void,
  stepProps?: StepProps,
  stepTitle?: string,
  transitions?: StepWizardTransitions,
}

const IdVerificationChecker = ({ user, ...props }: Props): JSX.Element => {
  const proceedToNext = (): void => decideNextStep(1, props.journey, props.updateJourney, props.goToNamedStep);
  return (
    <Box>
      <IdVerificationCheckerWizard
        onCompleted={() => {
          proceedToNext();
        }}
        continueLater={() => {
          proceedToNext();
        }}
        user={user}
      />
    </Box>
  );
};

IdVerificationChecker.defaultProps = {
  onClose: undefined,
  stepTitle: undefined,
  stepProps: undefined,
  goToNamedStep: undefined,
  transitions: undefined,
};

export default IdVerificationChecker;
