/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import intersection from 'lodash/intersection';
import WithStepWizard, { StepProps } from '../../common/wrappers/withStepWizard';
import { PhysicalAddress } from '../address/resources';
import TrackingBar from '../../common/headers/trackingBar';
import Name from '../name';
import DateOfBirth from '../dateOfBirth';
import Sin from '../sin';
import { ForeignTaxInformation } from '../assumptions/foreignTax';
import CompleteAssumptionsWizard from '../assumptions/completeAssumptionsWizard';
import UpdatePhysicalAddressWizard from '../address/updatePhysicalAddressWizard';
import UpdateEmployementStatusWizard from '../employment/updateEmployementStatusWizard';
import Email from '../../signUp/email';

const EmailStep = WithStepWizard(Email);
const NameStep = WithStepWizard(Name);
const DateOfBirthStep = WithStepWizard(DateOfBirth);
const UpdatePhysicalAddressWizardStep = WithStepWizard(UpdatePhysicalAddressWizard);
const UpdateEmploymentStatusWizardStep = WithStepWizard(UpdateEmployementStatusWizard);
const SinStep = WithStepWizard(Sin);
const CompleteAssumptionsWizardStep = WithStepWizard(CompleteAssumptionsWizard);
export interface UserState {
  firstName?: string,
  middleName?: string,
  lastName?: string,
  primaryEmail?: string,
  phone?: string,
  physicalAddress?: Partial<PhysicalAddress>,
  sin?: string,
  dateOfBirth?: Date,
  employmentStatus?: string,
  studentAreaOfStudy?: string,
  jobTitle?: string,
  companyType?: string,
  citizenships?: string[],
  foreignTaxInformation?: ForeignTaxInformation[],
  powerOfAttorneyGranted?: boolean,
  isMemberOfIiroc?: boolean,
  isOfficerOfPublicCompany?: boolean,
  isOwnerOfPublicCompany?: boolean,
  politicallyExposedDomesticPerson?: boolean,
  headOfInternationalOrganization?: boolean,
  politicallyExposedForeignPerson?: boolean,
  closeAssociateOfPEP?: boolean,
  incompleteFields?: string[],
  employerName?: string,
}

interface Props {
  user: UserState,
  updateUserState: (att: UserState) => void,
  onContinue?: () => void,
  goBack?: () => void,
  stepTitle?: string,
  showStepper?: boolean,
  stepProps?: StepProps,
}
const defineInitialStatus = (user: UserState): number => {
  if (user.incompleteFields?.includes('primaryEmail')) {
    return 1;
  }
  if (intersection(user.incompleteFields, ['firstName', 'lastName']).length > 0) {
    return 2;
  }
  if (user.incompleteFields?.includes('physicalAddress')) {
    return 3;
  }
  if (user.incompleteFields?.includes('dateOfBirth')) {
    return 4;
  }
  if (intersection(user.incompleteFields, ['employmentStatus', 'companyType', 'jobTitle', 'studentAreaOfStudy']).length > 0) {
    return 5;
  }
  if (intersection(user.incompleteFields, ['isMemberOfIiroc', 'isOfficerOfPublicCompany', 'isOwnerOfPublicCompany', 'politicallyExposedDomesticPerson', 'headOfInternationalOrganization', 'politicallyExposedForeignPerson', 'closeAssociateOfPEP', 'citizenships']).length > 0) {
    return 6;
  }
  if (user.incompleteFields?.includes('sin')) {
    return 7;
  }
  return 2;
};
const AboutYouWizard = ({
  user, updateUserState, onContinue, goBack, showStepper, stepTitle, stepProps,
}: Props): JSX.Element => {
  const absoluteTotalSteps = 13;
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const steps: JSX.Element[] = [];
  steps.push(<EmailStep stepProps={{ absoluteTotalSteps }} user={user} updateUserState={updateUserState} stepName="Email" shouldSaveUpdate key="Email" goBackAction={goBack} />);
  steps.push(<NameStep stepProps={{ absoluteTotalSteps }} stepName="Name" key="Name" user={user} updateUserState={updateUserState} shouldSaveUpdate stepTitle={stepTitle} goBackAction={goBack} />);
  steps.push(<UpdatePhysicalAddressWizardStep stepProps={{ absoluteTotalSteps, currentStep: 3 }} totalSteps={2} key="physicalAddress" user={user} updateUserState={updateUserState} stepTitle={stepTitle} disableTracker />);
  steps.push(<DateOfBirthStep key="DateOfBirth" stepProps={{ absoluteTotalSteps, currentStep: 5 }} user={user} updateUserState={updateUserState} stepTitle={stepTitle} />);
  steps.push(<UpdateEmploymentStatusWizardStep stepProps={{ absoluteTotalSteps, currentStep: 6 }} totalSteps={3} key="updateEmployement" user={user} updateUserState={updateUserState} stepTitle={stepTitle} disableTracker />);
  steps.push(<CompleteAssumptionsWizardStep stepProps={{ absoluteTotalSteps, currentStep: 11 }} totalSteps={4} key="Assumptions" user={user} updateUserState={updateUserState} stepTitle={stepTitle} disableTracker />);
  steps.push(<SinStep stepName="Sin" key="Sin" stepProps={{ absoluteTotalSteps, currentStep: 12 }} user={user} updateUserState={updateUserState} stepTitle={stepTitle} action={onContinue} />);

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      initialStep={defineInitialStatus(user)}
      className="ov-step-wizard"
      transitions={{}}
      nav={(
        <TrackingBar steps={steps} baselineStepProps={stepProps} showStepper={showStepper} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

AboutYouWizard.defaultProps = {
  onContinue: undefined,
  goBack: undefined,
  stepTitle: undefined,
  showStepper: true,
  stepProps: undefined,
};

export default AboutYouWizard;
