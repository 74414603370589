import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import intersection from 'lodash/intersection';
import { useContext, useState } from 'react';
import isNil from 'lodash/isNil';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../graphql';
import OvForm from '../../common/wrappers/ovForm';
import GetToKnowYouItem from './getToKnowYouItem';
import { cachedGetToKnowYouImages } from '../../../utils/imageFactory';
import PrivacyPolicyLink from '../../signUp/privacyPolicyLink';
import { UserContext } from '../../../providers/userContextProvider';
import { ovAnalyticsEvents, sendAnalyticsEvent } from '../../../utils/firebase';
import { FeatureFlagTypes, isFeatureEnabled } from '../../account/resources';

export interface GetToKnowYouProps {
  completedSteps?: string[],
  onContinue?: () => void,
  onCompleted?: () => void,
  goToNamedStep?: (stepName: string) => void,
}
const GetToKnowYou = ({ ...props }: GetToKnowYouProps): JSX.Element => {
  const { userContext, setUserContext } = useContext(UserContext);
  const { t } = useTranslation(['base', 'user']);
  const [loading, setLoading] = useState(false);
  const isCompletOnboardingSections = (): boolean => (intersection(props.completedSteps, ['YOUR_GOALS', 'ABOUT_YOU', 'HOW_TO_INVEST']).length === 3);
  const [updateUserCheckPolicy] = useMutation(UPDATE_USER, {
    variables: {
      input: {
        hasCheckedPolicyAndCondition: true,
      },
    },
    onCompleted: () => {
      setUserContext({ ...userContext, hasCheckedPolicyAndCondition: true });
      setLoading(false);
    },
    onError: () => setLoading(false),
  });
  return (
    <OvForm
      buttonText={t('base:button.continue')}
      onSubmit={async (e) => {
        e.preventDefault();
        if (userContext.hasCheckedPolicyAndCondition !== true) {
          setLoading(true);
          await updateUserCheckPolicy();
        }
        if (props.onContinue && props.goToNamedStep) {
          if (intersection(props.completedSteps, ['YOUR_GOALS', 'ABOUT_YOU', 'HOW_TO_INVEST']).length === 3) {
            if (props.onCompleted && isFeatureEnabled(FeatureFlagTypes.ID_VERIFICATION)) {
              props.onCompleted();
            } else {
              sendAnalyticsEvent(ovAnalyticsEvents.onBoardingLetsGetToKnowYouPage4).then();
              props.goToNamedStep('PersonaWizardStep');
            }
          } else if (props.completedSteps?.includes('HOW_TO_INVEST') && props.completedSteps?.includes('ABOUT_YOU')) {
            sendAnalyticsEvent(ovAnalyticsEvents.onBoardingLetsGetToKnowYouPage3).then();
            let nextStep = '';
            if (isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS)) {
              nextStep = 'YourGoalsWizardStep';
            } else if (isFeatureEnabled(FeatureFlagTypes.ID_VERIFICATION)) {
              nextStep = 'PersonaWizardStep';
            } else {
              nextStep = 'Agreement';
            }
            props.goToNamedStep(nextStep);
          } else if (props.completedSteps?.includes('ABOUT_YOU')) {
            sendAnalyticsEvent(ovAnalyticsEvents.onBoardingLetsGetToKnowYouPage2).then();
            props.goToNamedStep('HowToInvestWizardStep');
          } else if (intersection(props.completedSteps, ['YOUR_GOALS', 'ABOUT_YOU', 'HOW_TO_INVEST']).length === 0) {
            sendAnalyticsEvent(ovAnalyticsEvents.onBoardingLetsGetToKnowYouPage1).then();
            props.goToNamedStep('AboutYouWizardStep');
          }
        }
      }}
      loading={loading}>
      <Typography variant="heading2">{t('user:getToKnowYou.title')}</Typography>
      <Typography variant="paragraph3">{t('user:getToKnowYou.subTitle')}</Typography>
      <GetToKnowYouItem
        header={t('user:getToKnowYou.sections.aboutYou.title')}
        subTitle={t('user:getToKnowYou.sections.aboutYou.subTitle')}
        prefixIcon={cachedGetToKnowYouImages.get('AboutYouIcon').src}
        checked={props.completedSteps?.includes('ABOUT_YOU')}
        onClick={() => {
          if (props.goToNamedStep && !isCompletOnboardingSections() && props.completedSteps?.includes('ABOUT_YOU')) props.goToNamedStep('AboutYouWizardStep');
        }}
      />
      <GetToKnowYouItem
        header={t('user:getToKnowYou.sections.howToInvest.title')}
        subTitle={t('user:getToKnowYou.sections.howToInvest.subTitle')}
        prefixIcon={cachedGetToKnowYouImages.get('HowToInvest').src}
        checked={props.completedSteps?.includes('HOW_TO_INVEST')}
        onClick={() => {
          if (props.goToNamedStep && !isCompletOnboardingSections() && props.completedSteps?.includes('HOW_TO_INVEST')) props.goToNamedStep('HowToInvestWizardStep');
        }}
      />
      {
        isFeatureEnabled(FeatureFlagTypes.CREATE_NEW_GOALS) && (
          <GetToKnowYouItem
            header={t('user:getToKnowYou.sections.yourGoals.title').toString()}
            subTitle={t('user:getToKnowYou.sections.yourGoals.subTitle')}
            prefixIcon={cachedGetToKnowYouImages.get('YourGoalsIcon').src}
            checked={props.completedSteps?.includes('YOUR_GOALS')}
            onClick={() => {
              if (props.goToNamedStep && !isCompletOnboardingSections() && props.completedSteps?.includes('YOUR_GOALS')) props.goToNamedStep('YourGoalsWizardStep');
            }}
          />
        )
      }
      {
        isFeatureEnabled(FeatureFlagTypes.ID_VERIFICATION) && (
          <GetToKnowYouItem
            header={t('user:getToKnowYou.sections.IdCheck.title')}
            subTitle={t('user:getToKnowYou.sections.IdCheck.subTitle')}
            prefixIcon={cachedGetToKnowYouImages.get('IdCheckIcon').src}
            checked={props.completedSteps?.includes('ID_CHECK')}
            onClick={() => {
              if (props.onContinue && isCompletOnboardingSections()) props.onContinue();
            }}
          />
        )
      }
      {!userContext.hasCheckedPolicyAndCondition && !isNil(userContext.partnerId) ? <PrivacyPolicyLink /> : null}
    </OvForm>
  );
};

GetToKnowYou.defaultProps = {
  completedSteps: [],
  onContinue: undefined,
  onCompleted: undefined,
  goToNamedStep: undefined,
};

export default GetToKnowYou;
