import { colors } from '../../theme/colors';

export const styles = {
  documentsContainer: {
    width: '100%',
    typography: 'body1',
    padding: '0px !important',
    boxShadow: 'none !important',
  },
  mainWrapper: {
    minHeight: '80vh',
    backgroundColor: colors.menuBar,
    padding: '0 24px',
  },
};
