import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'dayjs/locale/fr';
import LanguageDetector from 'i18next-browser-languagedetector';
import isNil from 'lodash/isNil';
import { frCA, enCA } from 'date-fns/locale';
import en, { namespaces, defaultNS } from './en';
import fr from './fr';

export const resources = {
  en,
  fr,
} as const;

type LanguagesSupported = 'en' | 'fr';

i18n.use(LanguageDetector).init();
i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  ns: namespaces,
  defaultNS,
  interpolation: { escapeValue: false },
  resources,
});

export const getBackendLanguage = (): LanguagesSupported => {
  if (i18n.language.includes('fr')) return 'fr';
  return 'en';
};

export const getLocale = (): Locale => {
  if (i18n.language.includes('fr')) return frCA;
  return enCA;
};

export const getLanguageForPersona = (): string => {
  if (i18n.language.includes('fr')) return 'fr';
  return 'en-US';
};

export const translateBackend = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translatedObject: any = {},
): string => (isNil(translatedObject) ? '' : (translatedObject[getBackendLanguage()] ?? translatedObject?.en ?? ''));

export const translatedUrl = (url: string, translationUrl?: string): string => {
  if (i18n.language.includes('en')) return url;
  if (!i18n.language.includes('en') && translationUrl) return translationUrl;
  return `https://translate.google.com/translate?hl=fr&sl=en&u=${url}`;
};
export default i18n;
