import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Box, Container } from '@mui/material';
import { styles } from './style';
import OvBreadCrumb from '../../components/common/headers/ovBreadCrumb';
import { navigate } from '../../utils/router';
import { UserContext } from '../../providers/userContextProvider';
import Documents from '../../components/documents';

const DocumentsPage = (): JSX.Element => {
  const { setUserContext } = useContext(UserContext);
  const { t } = useTranslation(['document']);

  return (
    <Container className="home-page" sx={styles.documentsContainer}>
      <Box sx={styles.mainWrapper}>
        <Box>
          <OvBreadCrumb breadcrumbItems={[
            { title: t('base:breadcrumb.home'), onClick: () => navigate(setUserContext, '/') },
            { title: t('document:header') },
          ]} />
        </Box>
        {/* eslint-disable-next-line */}
        <Documents onClose={() => {}} />
      </Box>
    </Container>
  );
};

export default DocumentsPage;
