import { Link, Typography } from '@mui/material';
import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';
import OvForm from '../../common/wrappers/ovForm';
import { useContextTheme } from '../../../providers/contextThemeProvider';

interface Props {
  setEnableTLH: (value: boolean) => void,
  onContinue?: () => void,
  loading?: boolean,
}

const EnableTLH = ({
  setEnableTLH, loading, onContinue,
}: Props): JSX.Element => {
  const { colors } = useContextTheme();
  const { t } = useTranslation(['account']);
  return (
    <OvForm
      loading={loading}
      buttonText={t('account:enableTLH.buttonText')}
      onSubmit={(e) => {
        e.preventDefault();
        setEnableTLH(true);
        if (onContinue) {
          onContinue();
        }
      }}
      suffixButton={{
        onClick: () => {
          setEnableTLH(false);
          if (onContinue) {
            onContinue();
          }
        },
        text: t('account:enableTLH.suffixButtonText'),
        variant: 'secondary-large',
        dataTestId: '',
      }}
    >
      <Typography variant="heading2" data-testid="title">{t('account:enableTLH.title')}</Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 32 }}>
        <Markup content={t('account:enableTLH.subTitle1', { link: colors.link })} />
      </Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 20 }}>
        <span data-testid="subTitle2">{t('account:enableTLH.subTitle2', { link: colors.link })}</span>
        <Link sx={{ padding: '0px 2px' }} href="https://onevest.zendesk.com/hc/en-us/articles/12415791977108-What-is-Tax-Loss-Harvesting-" underline="always" target="_blank">{t('account:enableTLH.link')}</Link>
        <span data-testid="subTitle3">{t('account:enableTLH.subTitle3', { link: colors.link })}</span>
      </Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 20 }}>
        {t('account:enableTLH.subTitle4', { link: colors.link })}
      </Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 20 }}>
        {t('account:enableTLH.subTitle5', { link: colors.link })}
      </Typography>
      <Typography variant="paragraph3" style={{ marginBottom: 20 }}>
        <Link sx={{ paddingRight: '2px' }} href="https://onevest.zendesk.com/hc/en-us/articles/12415791977108-What-is-Tax-Loss-Harvesting-" underline="always" target="_blank">{t('account:enableTLH.link')}</Link>
        <span data-testid="subTitle6">{t('account:enableTLH.subTitle6', { link: colors.link })}</span>
      </Typography>
    </OvForm>
  );
};

EnableTLH.defaultProps = {
  onContinue: undefined,
  loading: false,
};

export default EnableTLH;
