import { useState } from 'react';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import WithStepWizard from '../common/wrappers/withStepWizard';
import Phone from './phone';
import EmailVerification from './emailVerification';
import PhoneVerification from './phoneVerification';
import { START_PASSWORDLESS_UPDATE, VERIFY_AND_UPDATE_PASSWORDLESS, TokenResponse } from './graphql';
import TrackingBar from '../common/headers/trackingBar';

const PhoneStep = WithStepWizard(Phone);
const EmailVerificationStep = WithStepWizard(EmailVerification);
const PhoneVerificationStep = WithStepWizard(PhoneVerification);

interface Props {
  goBack?: () => void,
  onContinue?: () => void,
  updateState?: (user: { phone: string }) => void,
}

const UpdatePhoneWizard = ({
  goBack,
  onContinue,
  updateState,
} : Props): JSX.Element => {
  const [stepWizard, updateStepWizard] = useState({
    SW: {} as StepWizardProps,
  });
  const [user, updateUser] = useState({
    primaryEmail: '',
    phone: '',
    verificationToken: '',
    token: {} as TokenResponse,
  });
  const [loading, setLoading] = useState(false);
  const graphqlClient = useApolloClient();

  const setInstance = (SW: StepWizardProps): void => updateStepWizard({ ...stepWizard, SW });
  const setUserState = (att: Partial<typeof user>): void => updateUser({ ...user, ...att });

  const onError = (): void => {
    setLoading(false);
    if (goBack) goBack();
  };

  const onPhoneContinue = async (callback: () => void): Promise<void> => {
    setLoading(true);
    try {
      const resp = await graphqlClient.mutate({
        mutation: START_PASSWORDLESS_UPDATE,
        variables: { input: { username: user.phone, connection: 'sms' } },
      });
      const primaryEmail = resp.data.startPasswordlessUpdate.username;
      console.log({ event: 'PHONE_UPDATE_STARTED', phone: user.phone, primaryEmail });
      setUserState({ ...user, primaryEmail });
      setLoading(false);
      callback();
    } catch (e) {
      setLoading(false);
    }
  };

  const onEmailValidated = async (callback: () => void): Promise<void> => {
    setLoading(true);
    try {
      const step1 = await graphqlClient.mutate({
        mutation: VERIFY_AND_UPDATE_PASSWORDLESS,
        variables: { input: { username: user.primaryEmail, connection: 'email', verificationToken: user.verificationToken } },
      });
      console.log({ event: 'EMAIL_VALIDATED', primaryEmail: user.primaryEmail });
      const { mfaToken } = step1.data.verifyAndUpdatePasswordless;
      setUserState({ ...user, token: { ...user.token, mfaToken } });

      const step2 = await graphqlClient.mutate({
        mutation: START_PASSWORDLESS_UPDATE,
        variables: { input: { username: user.phone, connection: 'sms', mfaToken } },
      });
      console.log({ event: 'PHONE_VALIDATION_STARTED', phone: user.phone });
      const { oobCode } = step2.data.startPasswordlessUpdate;
      setUserState({ ...user, token: { ...user.token, mfaToken, oobCode } });
      setLoading(false);
      callback();
    } catch (e) {
      onError();
    }
  };

  const onPhoneValidated = async (): Promise<void> => {
    setLoading(true);
    try {
      await graphqlClient.mutate({
        mutation: VERIFY_AND_UPDATE_PASSWORDLESS,
        variables: {
          input: {
            username: user.phone, connection: 'sms', mfaToken: user.token.mfaToken, oobCode: user.token.oobCode, verificationToken: user.verificationToken,
          },
        },
      });
      console.log({ event: 'PHONE_UPDATED', phone: user.phone });
      setLoading(false);
      if (updateState) updateState({ phone: user.phone });
      if (onContinue) onContinue();
    } catch (e) {
      onError();
    }
  };

  const { t } = useTranslation(['signUp']);
  const steps: JSX.Element[] = [
    <PhoneStep key="1" stepName="phone" user={user} updateUserState={setUserState} action={onPhoneContinue} isLoading={loading} goBackAction={goBack} texts={{ title: t('signUp:updatePhone.phoneTitle'), subTitle: t('signUp:updatePhone.phoneSubTitle') }} />,
    <EmailVerificationStep key="2" stepName="emailVerification" user={user} updateUserState={setUserState} skipOtpMutations action={onEmailValidated} isLoading={loading} displayHelpLink />,
    <PhoneVerificationStep key="3" stepName="phoneVerification" user={user} updateUserState={setUserState} skipOtpMutations action={onPhoneValidated} isLoading={loading} onContinue={onContinue} goBackToNamedStep={() => 'phone'} displayHelpLink />,
  ];

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      className="ov-step-wizard"
      transitions={{}}
      nav={(
        <TrackingBar steps={steps} showStepper={false} />
      )}
    >
      {steps}
    </StepWizard>
  );
};

UpdatePhoneWizard.defaultProps = {
  goBack: undefined,
  onContinue: undefined,
  updateState: undefined,
};

export default UpdatePhoneWizard;
