import * as React from 'react';
import BaseInfoCard, { ITEM } from './baseInfoCard';

interface Props {
  accountType: string,
  gainsAndLossesValueCent: number,
  contributionValueCent: number,
  allocationValue: number,
}

const getItem = (accountType: string): ITEM => {
  const itemValueArray = Object.values(ITEM);
  return itemValueArray.find((x) => x.toString() === accountType) ?? ITEM.PERSONAL;
};

const ContributionsCard = ({
  accountType, gainsAndLossesValueCent, contributionValueCent, allocationValue,
}: Props): JSX.Element => {
  const defineRateOfReturnOptions = (): { item: ITEM, value: number, isPercent: boolean }[] => {
    const returnOptions: { item: ITEM, value: number, isPercent: boolean }[] = [];
    returnOptions.push({ item: getItem(accountType), value: (accountType === 'PERSONAL' ? gainsAndLossesValueCent : contributionValueCent), isPercent: false });
    returnOptions.push({ item: ITEM.allocation, value: allocationValue, isPercent: true });
    return returnOptions;
  };
  return (<BaseInfoCard headerTextSize="30px" options={defineRateOfReturnOptions()} />);
};

export default ContributionsCard;
